import React,{useEffect} from "react";
import { Navigate } from "react-router-dom";
import { TABS } from "../../constants";
import { changeTab } from "../../redux/actions";
import { useRedux } from "../../hooks";

const Index = (props: any) => {
  const { dispatch } = useRedux();
  useEffect(() => {
      dispatch(changeTab(TABS.CHAT))
  }, [])
  
  return (
    <div>
      <Navigate to="/dashboard" />
    </div>
  );
};

export default Index;
