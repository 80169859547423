import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { UpdateSubUserPwdActionTypes } from "./types";
import {
  updateSubUserPwdApiResponse,
  UpdateSubUserPwdApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postUpdateSubUserPassword,
  changePassword as changePasswordApi,
} from "../../../api/index";
import { showErrorNotification } from "../../../helpers/notifications";

const fireBaseBackend: any = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* updateSubUserPassword({ payload: data }: any) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postUpdateSubUserPassword,data );
      yield put(
        updateSubUserPwdApiResponse(
          UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD,
          response
        )
      );
    }
  } catch (error: any) {
    showErrorNotification(error?.data?.error?.error)
    yield put(
      UpdateSubUserPwdApiResponseError(
        UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD,
        error
      )
    );
  }
}

export function* watchSubUserUpdatePassword() {
  yield takeEvery(UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD, updateSubUserPassword);
}

// export function* watchUserChangePassword() {
//   yield takeEvery(UpdateSubUserPwdActionTypes.CHANGE_PASSWORD, changePassword);
// }

function* updateSubUserPasswordSaga() {
  yield all([fork(watchSubUserUpdatePassword)]);
}

export default updateSubUserPasswordSaga;
