import React, { useState } from 'react'
import moment from 'moment'
import { Pagination, Table } from 'react-bootstrap'
import { Button } from 'reactstrap'
interface InvoiceTableType {
    invoiceData?: any
    downloadInvoice: (item: any) => void;
    payInvoice?: (item: any) => void;
}
const InvoiceTable = ({ invoiceData, downloadInvoice,payInvoice }: InvoiceTableType) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = invoiceData.slice(indexOfFirstItem, indexOfLastItem);
    const totalItems = invoiceData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const download = (invoiceData: any) => {
        downloadInvoice(invoiceData)
    }

    const payNow = (invoiceData: any) => {
        if(payInvoice && typeof payInvoice === 'function'){
            payInvoice(invoiceData)
        }
    }
    return (
        <div>
            <Table striped bordered hover className="w-100 text-center">
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Invoice Number</th>
                        <th>Invoice Status</th>
                        <th>Invoice Amount</th>
                        <th>Invoice Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item: any, index: number) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.invoiceId}</td>
                            <td>{item.status === "paid" ? "Paid" : "Un-Paid"}</td>
                            <td>$ {item.sub_total}</td>
                            <td>{moment(item.date).format("DD MMM, YYYY")}</td>
                            <td className='d-flex justify-content-center'>
                                {item.status === "not_paid" && <Button className="btn me-2" color="primary" onClick={() => payNow(item)}>
                                    Pay
                                </Button>}
                                <Button className="btn " color="primary" outline onClick={() => download(item)}>
                                    <i className="bx bxs-download"></i>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {invoiceData.length ? <Pagination className='invoiceTablePagination'>
                <Pagination.Prev
                    onClick={() =>
                        setCurrentPage((prevPage) => prevPage - 1)
                    }
                    disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }).map((_, index) => (
                    <Pagination.Item
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        active={index + 1 === currentPage}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() =>
                        setCurrentPage((prevPage) => prevPage + 1)
                    }
                    disabled={currentPage === totalPages}
                />
            </Pagination> : ""}
        </div>
    )
}

export default InvoiceTable