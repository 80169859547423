import { ChangePwdActionTypes } from "./types";

// common success
export const changePwdApiResponseSuccess = (
  actionType: string,
  data: any
) => {
  return({
  type: ChangePwdActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
})}
// common error
export const changePwdApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: ChangePwdActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const changePassword = (data: any) => {
  return {
    type: ChangePwdActionTypes.CHANGE_USER_PASSWORD,
    payload: data,
  };
};
