import { IntegrationsActionTypes, IntegrationsState } from "./types";

export const INIT_STATE: IntegrationsState = {
    error: "",
    loading: false,
    isIntegration: {},
    integrations:[],
    removeIntegraation:{}
};


const Integrations = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case IntegrationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case IntegrationsActionTypes.SAVE_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                        isIntegration:action.payload.data
                    };
                }
                case IntegrationsActionTypes.GET_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                        integrations:action.payload.data.data.integrations
                    };
                }
                case IntegrationsActionTypes.REMOVE_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                        removeIntegraation:action.payload.data,
                        integrations:action.payload.data.data.integrations
                    };
                }
                case IntegrationsActionTypes.REMOVE_SLACK_CHANNEL: {
                    return {
                        ...state,
                        loading: false,
                        removeIntegraation:action.payload.data,
                        integrations:action.payload.data.data.integrations
                    };
                }
                case IntegrationsActionTypes.ADD_BOARD_ID: {
                    return {
                        ...state,
                        loading: false,
                        removeIntegraation:action.payload.data,
                        integrationData:action.payload.data
                    };
                }
                case IntegrationsActionTypes.CHECK_URL: {
                    return {
                        ...state,
                        loading: false,
                        checkUrlPayload:action.payload.data
                    };
                }
                case IntegrationsActionTypes.UPDATE_INTEGRATION :{
                    return {
                        ...state,
                        loading: false,
                        uploadPayload:action.payload.data,
                        checkUrlPayload:{}
                    }
                }
                default:
                    return { ...state };
            }

        case IntegrationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IntegrationsActionTypes.SAVE_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case IntegrationsActionTypes.GET_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case IntegrationsActionTypes.REMOVE_INTEGRATIONS: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case IntegrationsActionTypes.REMOVE_SLACK_CHANNEL: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case IntegrationsActionTypes.ADD_BOARD_ID: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case IntegrationsActionTypes.CHECK_URL: {
                    return {
                        ...state,
                        loading: false,
                        checkUrlPayload:{}
                    };
                }
                case IntegrationsActionTypes.UPDATE_INTEGRATION :{
                    return {
                        ...state,
                        loading: false,
                        uploadPayload:{},
                        checkUrlPayload:{}
                    }
                }
                default:
                    return { ...state };
            }

        case IntegrationsActionTypes.SAVE_INTEGRATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case IntegrationsActionTypes.GET_INTEGRATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case IntegrationsActionTypes.REMOVE_INTEGRATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case IntegrationsActionTypes.REMOVE_SLACK_CHANNEL: {
            return {
                ...state,
                loading: true,
            };
        }
        case IntegrationsActionTypes.ADD_BOARD_ID: {
            return {
                ...state,
                loading: true,
            };
        }
        case IntegrationsActionTypes.CHECK_URL: {
            return {
                ...state,
                loading: true,
                checkUrlPayload:{}
            };
        }
        case IntegrationsActionTypes.UPDATE_INTEGRATION :{
            return {
                ...state,
                loading: true,
                uploadPayload:{},
                checkUrlPayload:{}
            }
        }
        case IntegrationsActionTypes.CLEAR_STATE: {
            return {
                ...state ,
                loading:false,
                uploadPayload:{},
                checkUrlPayload:{},
                isIntegration:{}
            }
        }
        default:
            return { ...state };
    }
}

export default Integrations