import slackIcon from "../assets/images/slack.png";
import webhooksIcon  from "../assets/images/webhooks.svg";
import chromeExtension  from "../assets/images/chrome_extension.svg";
import MondayIcon from "../assets/images/availableOnReqInt/Monday.svg"
import zohoIcon from "../assets/images/icons/zoho_crm_icon.svg"
import bitrixIcon from "../assets/images/icons/bitrix_24_icons.svg"
import salesForceIcon from "../assets/images/salesforce-2.svg"

interface Integration {
  displayName: string;
  name: string;
  category: string;
  assignNumber: string[];
  status: string;
  type: string;
  setting: boolean;
  assignSetting: boolean;
  iconSrc: string;
  setUpGuide: string;
  getHelp: string;
  popupData: {
    logo: string;
  };
  btnText: string;
  showSettingModal: boolean;
  showDetailModal: boolean;
  showNewSettingModal: boolean;
  iconValue: string;
  isPopular: boolean;
}
interface IntegrationsList {
  [key: string]: Integration;
}
export type { IntegrationsList, Integration };

let integrationList: IntegrationsList = {
  "hubspot": {
    "displayName": "HubSpot",
    "name": "hubspot",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": " https://d1x9dsge91xf6g.cloudfront.net/callhippo/images/chsiteimages/hubspot-new-min.png",
    "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000057561-how-to-integrate-hubspot-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "pipDrive": {
    "displayName": "PipeDrive",
    "name": "pipedrive",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": "https://d1x9dsge91xf6g.cloudfront.net/callhippo/images/chsiteimages/pipedrive-new-min.png",
    "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000057601-how-to-integrate-pipedrive-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "salesforce": {
    "displayName": "Salesforce",
    "name": "salesforce",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": salesForceIcon,
    "setUpGuide": "https://support.controlhippo.com/en/support/solutions/articles/1060000079049-how-to-integrate-salesforce-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "zoho": {
    "displayName": "Zoho",
    "name": "zoho",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": zohoIcon,
    "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000057602-how-to-integrate-zoho-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  }, 
  "monday": {
    "displayName": "monday.com",
    "name": "monday",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": MondayIcon,
    "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000073549-how-to-integrate-monday-com-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "slack": {
    "displayName": "Slack",
    "name": "slack",
    "category" : "Communication",
    "assignNumber": [],
    "status": "integrate",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": slackIcon,
    "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000057603-how-to-integrate-slack-with-controlhippo-",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
        "logo": slackIcon
    },
    "btnText" : "Integrated",
    "showSettingModal": false,
    "showDetailModal" : false,
    "showNewSettingModal" : false,
    "iconValue" : "check_circle",
    "isPopular" : true
},
 "bitrix": {
  "displayName": "Bitrix24",
  "name": "bitrix",
  "category": "Marketing Automation",
  "assignNumber": [],
  "status": "integrate",
  "type": "native",
  "setting": true,
  "assignSetting": true,
  "iconSrc": bitrixIcon,
  "setUpGuide": "https://support.controlhippo.com/en/support/solutions/articles/1060000078718-how-to-integrate-bitrix24-with-controlhippo-",
  "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
  "popupData": {
    "logo": "hubspotpopup"
  },
  "btnText": "Integrated",
  "showSettingModal": false,
  "showDetailModal": false,
  "showNewSettingModal": false,
  "iconValue": "check_circle",
  "isPopular": true
},

"webhook": {
  "displayName": "Webhook",
  "name": "webhook",
  "category" : "Communication",
  "assignNumber": [],
  "status": "integrate",
  "type": "other",
  "setting": true,
  "assignSetting": true,
  "iconSrc": webhooksIcon,
  "setUpGuide": "https://support.controlhippo.com/support/solutions/articles/1060000057641-how-to-integrate-webhook-with-controlhippo-",
  "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
  "popupData": {
      "logo": webhooksIcon
  },
  "btnText" : "Integrated",
  "showSettingModal": false,
  "showDetailModal" : false,
  "showNewSettingModal" : false,
  "iconValue" : "check_circle",
  "isPopular" : true
},
"chromeExtension": {
  "displayName": "Extension",
  "name": "chromeExtension",
  "category" : "Communication",
  "assignNumber": [],
  "status": "integrate",
  "type": "other",
  "setting": true,
  "assignSetting": true,
  "iconSrc": chromeExtension,
  "setUpGuide": "",
  "getHelp": "",
  "popupData": {
      "logo": chromeExtension
  },
  "btnText" : "Integrated",
  "showSettingModal": false,
  "showDetailModal" : false,
  "showNewSettingModal" : false,
  "iconValue" : "check_circle",
  "isPopular" : true
},
};

export { integrationList};
