// types
import { WebsocketActionTypes, WebsocketState } from "./types";

export const INIT_STATE: WebsocketState = {
  connected: false,
  isSessionResyncing: false,
  errorResponse:{}
};

const Websocket = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case WebsocketActionTypes.SOCKET_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case WebsocketActionTypes.NOTICE_ERROR: {
          return {
            ...state,
            errorResponse:action.payload.data
          };
        }
        default:
          return { ...state };
      }
    case WebsocketActionTypes.DISCONNECTED:
      return {
        ...state,
        connected: false
      }

    case WebsocketActionTypes.CONNECTED:
      console.log('Socket connected')
      return {
        ...state,
        connected: true
      }

    case WebsocketActionTypes.SESSION_RESYNCING:
      return {
        ...state,
        isSessionResyncing: true
      }

    case WebsocketActionTypes.SESSION_RESYNCING_COMPLETED:
      return {
        ...state,
        isSessionResyncing: false
      }
    case WebsocketActionTypes.CLEAR_SOCKET_RESPONSE_ERROR:
      return {
        ...state,
        errorResponse:{}
      }
    default:
      return { ...state };
  }
};

export default Websocket;
