import React, { useEffect, useState } from 'react';
import { Alert as ReactstrapAlert, Button } from 'reactstrap';

type AlertProps = {
    type: "success" | "info" | "warning" | "danger" | string;
    message: string;
    dismissible?: boolean;
};

const AlertComponent: React.FC<AlertProps> = ({ type, message, dismissible }) => {
    const [isOpen, setIsOpen] = useState(true);

    const closeAlert = () => {
        setIsOpen(false);
    };

    return isOpen ? (
        <ReactstrapAlert color={type} isOpen={isOpen} toggle={dismissible ? closeAlert : undefined}>
            {message}
            {dismissible && (
                <Button close onClick={closeAlert} />
            )}
        </ReactstrapAlert>
    ) : null;
};

export default AlertComponent;
