import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { UpdateTourVisitedActionTypes } from "./types";
import { updateTourVisitedApiResponse, updateTourVisitedApiResponseError } from "./actions";

import { updateTourVisited as postUpdateTourVisitedApi } from "../../api/index";

function* updateTourVisited({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(postUpdateTourVisitedApi,data);
    yield put(
      updateTourVisitedApiResponse(
        UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED,
        response
      )
    );
  } catch (error: any) {
    yield put(
      updateTourVisitedApiResponseError(UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED, error)
    );
  }
}

export function* watchUpdateTourVisited() {
  yield takeEvery(UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED, updateTourVisited);
}

function* postUpdateTourVistedSaga() {
  yield all([fork(watchUpdateTourVisited)]);
}

export default postUpdateTourVistedSaga;
