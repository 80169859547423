import { Middleware } from "redux";
import { warmupConfirmationModalOpen } from "../actions";

const warmupCheckMiddleWare: Middleware = store => next => action => {

    if (action?.payload?.data?.showWarmupConfrmation) {
        store.dispatch(warmupConfirmationModalOpen(true))
    }
    return next(action);
};
export { warmupCheckMiddleWare }