import { UpdatePasswordActionTypes } from "./types";

// common success
export const updatePasswordApiResponse = (
  actionType: string,
  data: any
) => ({
  type: UpdatePasswordActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const UpdatePasswordApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: UpdatePasswordActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updatePassword = (data: any) => {
  return {
    type: UpdatePasswordActionTypes.UPDATE_PASSWORD,
    payload: data,
  };
};

