export enum ChangePwdActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/changeUserPwd/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/changeUserPwd/API_RESPONSE_ERROR",

  CHANGE_USER_PASSWORD = "@@auth/changeUserPwd/CHANGE_USER_PASSWORD",
  CHANGE_PASSWORD = "@@auth/forgetPass/CHANGE_PASSWORD",
}
export interface AuthForgetPassState {
  loading:boolean;
  changePwdSuccessMsg: string;
  changePwdErrorMsg: string;
}
