import { AuthLoginActionTypes, AuthLoginState } from "./types";

export const INIT_STATE: AuthLoginState = {
  error: "",
  loading: false,
  isUserLogin: false,
  isUserLogout: false,
  user:{}
};

const Login = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AuthLoginActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          return {
            ...state,
            user: action.payload.data.data,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
          };
        case AuthLoginActionTypes.LOGIN_WITH_GOOGLE:
          return {
            ...state,
            user: action.payload.data.data,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
          };
        case AuthLoginActionTypes.POST_FCM_TOEKN:
          return {
            ...state,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          return {
            ...state,
            user:{},
            loading: false,
            isUserLogout: true,
          };
        case AuthLoginActionTypes.SYSTEM_LOGOUT_USER:
          return {
            ...state,
            user:{},
            loading: false,
            isUserLogout: true,
            isUserLogin: false,
          };
        case AuthLoginActionTypes.USERDATA_UPDATE:
            let data :any =  action.payload.data.authData
            let authData: any = localStorage.getItem("authData")
            authData =  authData ? JSON.parse(authData):null
            authData = {
              ...authData, 
              ...data
            };
            localStorage.setItem("authData",JSON.stringify(authData))
            return { 
              ...state,
              user:authData,
            };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          return {
            ...state,
            error: "Username or password is incorrect.",
            isUserLogin: false,
            loading: false,
          };
        case AuthLoginActionTypes.LOGIN_WITH_GOOGLE:
          return {
            ...state,
            error: "Username or password is incorrect.",
            isUserLogin: false,
            loading: false,
          };
        case AuthLoginActionTypes.POST_FCM_TOEKN:
          return {
            ...state,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          return {
            ...state,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
          };
        case AuthLoginActionTypes.SYSTEM_LOGOUT_USER:
          return {
            ...state,
            loading: false,
            isUserLogin: false,
            isUserLogout: true,
          };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.LOGIN_USER: {
      return {
        ...state,
        loading: true,
        isUserLogin: false,
      };
    }
    case AuthLoginActionTypes.LOGIN_WITH_GOOGLE: {
      return {
        ...state,
        loading: true,
        isUserLogin: false,
      };
    }
    case AuthLoginActionTypes.POST_FCM_TOEKN: {
      return {
        ...state,
        loading: true,
        isUserLogin: false,
      };
    }
    case AuthLoginActionTypes.SET_USER_DATA: {
      return {
        ...state,
        user:action.payload,
        loading: false,
        isUserLogin: true,
        isUserLogout:false
      };
    }

    case AuthLoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        loading: false,
        isUserLogout: false,
      };
    case AuthLoginActionTypes.SYSTEM_LOGOUT_USER:
      return {
        ...state,
        loading: true,
        isUserLogout: false,
      };
    default:
      return { ...state };
  }
};

export default Login;
