import { changeEmailActionTypes } from "./types";

// common success
export const changeEmailApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: changeEmailActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const changeEmailApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: changeEmailActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const postChangeEmail = (data: any) => {
  return {
    type: changeEmailActionTypes.CHANGE_USER_EMAIL,
    payload: data,
  };
};
