import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useProfile, useContacts, useRedux } from "../../hooks";
import SelectMenu from "../../components/Select";
import { checkPlanAndFeature } from '../../helpers/common.service';
import { showErrorNotification } from '../../helpers/notifications';
type ConnectButtonProps = {
    integrationData: any;
    onConnectClick: (name: string, data?: any, type?: string) => void;
};


const ConnectButton = ({ integrationData, onConnectClick }: ConnectButtonProps) => {
    const navigate = useNavigate();
    const { userProfile } = useProfile();
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    // const [synSDestination, setSyncDestination] = useState(options[0])

    const toggleModal = () => {
        if (checkPlanAndFeature({featureName:"integration",planDetails: userProfile?.planDetails,featureDisplayName:"Integration"}))
            setIsOpen(!isOpen);
    };

    const handleConnect = () => {
        setIsOpen(false);
    };

    const onClick = () => {
        if (["salesforce", "bitrix"].indexOf(integrationData?.name) != -1) {
            let modifiedInputValue = inputValue.replace(/^http:\/\//, 'https://');
            modifiedInputValue = modifiedInputValue.replace(/\/$/, '');
            const regex = /^https:\/\/(?:[\w\d-]+\.)+[\w\d-]+(?:\/[^\/]*)?$/;
            if (regex.test(modifiedInputValue)) {
                onConnectClick(integrationData.name, false, modifiedInputValue);
            } else {
                showErrorNotification("Please enter a valid URL starting with 'https://' and without trailing slashes after the endpoint.");
            }
        } else {
            onConnectClick(integrationData.name, false);
        }
    }

    

    const handleInputChange = (value: any) => {
        setInputValue(value)
    }

    return (
        <>
            {/* <Button className='btn btn-primary' onClick={toggleModal}>Connect</Button> */}
            {/* Integration button  */}
            <Button className='btn btn-primary font-size-13' onClick={toggleModal}>Integrate</Button >
            <Modal isOpen={isOpen} tabIndex={-1} toggle={toggleModal} centered>
                <ModalHeader className="modal-title-custom" toggle={toggleModal}>Integration Setup</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center p-4">
                    <div className="container-fluid ">
                        <div className="row justify-content-center">
                            <div className="text-center">
                                <img src={integrationData.iconSrc} alt="" height={100} width={100} />
                            </div>
                            <div className="col-md-5 mt-3 text-center">{integrationData.displayName}</div>
                            <div className="col-12 mt-4">
                               {(integrationData?.name == "bitrix" || integrationData?.name == "salesforce")  &&
                                    <Input onChange={(e: any) => { handleInputChange(e.target.value); }} placeholder="Enter Url" />
                                }
                                {integrationData?.name == "salesforce" &&
                                    <div className='mt-3'>
                                        <a target='_blank' href="https://d1x9dsge91xf6g.cloudfront.net/controlhippo/images/image.png">
                                            <span style={{ textDecoration: 'underline' }}>Click here</span>
                                        </a> for the Salesforce URL reference.
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
                
                <ModalFooter className='justify-content-end' style={{ "alignItems": "center" }}>
                    {/* {integrationData.name == "zoho" && <div className='d-flex justify-content-start'>
                        <div className="row">
                            <div className="col-4 font-size-12">
                                <label>Sync Destination</label>
                            </div>
                            <div className="col-8">
                                <SelectMenu value={synSDestination} isMulti={false} options={options} onChange={selectOption}/>
                            </div>
                        </div>
                    </div>} 
                    <div className='d-flex justify-content-end'>*/}
                    <Button type="button" color="link" className="btn" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button type="button" className="add_to_slack d-flex align-items-center justify-content-center" onClick={onClick}>
                        <img src={integrationData.iconSrc} alt="" height={30} width={30} /> Add to <b>{integrationData.displayName}</b>
                    </Button>
                    {/* </div>
                    <Button type="button" className="btn btn-primary" onClick={onClick}>
                        Connect
                    </Button> */}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ConnectButton;
