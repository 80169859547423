export enum UpdateProfileDataTypes {
  API_RESPONSE_SUCCESS = "@@auth/updateProfile/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/updateProfile/API_RESPONSE_ERROR",

  UPDATE_PROFILE_DATA = "@@auth/updateProfile/UPDATE_PROFILE_DATA",
}

export interface updateProfileState {
  registrationError: any;
  message: string;
  loading: boolean;
  user: object | null;
}
