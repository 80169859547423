import React, { useEffect, useState } from "react";

import { Collapse, Card, CardBody, Button } from "reactstrap";


// hooks
import { useProfile } from "../../../../hooks";
import ForwardModal from "../../../../components/ForwardModal";

interface ReplyProps {
    forwardDataArray: any;
    chatUserDetails?: any;
    onSend?: any;
    handleClose?: any;
    dropdownSelectedClientId?:any
}
const SendForwardMessage = ({ forwardDataArray, chatUserDetails, onSend, handleClose ,dropdownSelectedClientId}: ReplyProps) => {
    const [isOpenForward, setIsOpenForward] = useState<boolean>(false);

    const onCloseForward = () => {
        setIsOpenForward(false);
        handleClose()
    };

    const handleForwardClick = () => {
        setIsOpenForward(true)
    }

    const handleCloseClk = () => {
        setIsOpenForward(false);
        handleClose()
    }

    return (
        <> <div style={{ display: 'flex', alignItems: 'center',height:66 }} className="selectedItemsBlock">
            <i onClick={handleCloseClk} className="ri-close-line font-size-16"></i>
            <h4 className="selectedTitle" style={{ marginBottom: '0px', flex: '1' }}>Selected items {forwardDataArray?.length}</h4>
            {forwardDataArray?.length ? <Button onClick={handleForwardClick} color="primary" className="bx-share-btn"><i className="bx bx-share"></i></Button> : ""}
        </div>
            {isOpenForward && (
                <ForwardModal
                    isOpen={isOpenForward}
                    onClose={onCloseForward}
                    forwardData={forwardDataArray}
                    chatUserDetails={chatUserDetails}
                    //   onForward={onForwardMessage}
                    onSend={onSend}
                    dropdownSelectedClientId={dropdownSelectedClientId}
                />
            )}
        </>
    );
};

export default SendForwardMessage;

