import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import { ReportsActionTypes } from "./types";
import { reportsApiResponseSuccess, reportsApiResponseError } from "./actions";

import {
    getReports as getReportsApi
} from "../../api/index";

// Helper
import {
    showSuccessNotification,
} from "../../helpers/notifications";

function* getReports({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getReportsApi, data);
        yield call(showSuccessNotification, response);
        yield put(
            reportsApiResponseSuccess(ReportsActionTypes.GET_REPORTS, response)
        );
    } catch (error: any) {
        yield put(
            reportsApiResponseError(ReportsActionTypes.GET_REPORTS, error)
        );
    }
}

export function* watchGetReports() {
    yield takeEvery(ReportsActionTypes.GET_REPORTS, getReports);
}

function* integrationsSaga() {
    yield all([
        fork(watchGetReports),
    ])
}

export default integrationsSaga;
