import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Pagination, Card, Popconfirm, Modal, Anchor } from 'antd';
import { Button ,UncontrolledTooltip,Row,Col} from "reactstrap";
import type { ColumnsType } from 'antd/es/table';
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style';

import { useRedux, useProfile } from "../../hooks/index";
// import { getUserList } from "../../redux/userList/actions";
import { getUserList,userDelete } from "../../redux/actions";
// import { userDelete } from "../../redux/deleteUser/actions";


import {useNavigate} from "react-router-dom";
import { showErrorNotification, showInfoNotification, showSuccessNotification, showWarningNotification } from '../../helpers/notifications';

interface UserList {
  id?: number;
  fullName: string;
  email: string;
  userActive: boolean;
  Status: string;
  Availability: string;
}

const UserList: React.FC = () => {
  const { dispatch, useAppSelector } = useRedux();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { userProfile } = useProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [okClicked, setOkClicked] = useState(false);

  const showModal = (__: any, data: any) => {
    setIsModalOpen(true);
    setDeleteId(__)
    setOkClicked(false);
  };

  const handleOk = (deleteId:any) => {
    if (!okClicked && deleteId.parentId && userProfile?.userId !== deleteId._id) {
      const deleteData = (userId:any) => {
        dispatch(userDelete(userId));
      };
      deleteData(deleteId._id);
      setOkClicked(true);
    }
  };

  const handleCancel = () => {
    setOkClicked(false);
    setIsModalOpen(false);
  };


  useEffect(() => {
    dispatch(getUserList());
  }, []);

  const { getUserListData, userDeleteSuccess } = useAppSelector((state) => {
    return {
      getUserListData: state?.User?.profileDetails,
      userDeleteSuccess: state?.User?.userDeleteSuccess,
    };
  });


  useEffect(() => {
   if(userDeleteSuccess){
    setIsModalOpen(false);
    dispatch(getUserList());
   }
  }, [userDeleteSuccess])
  
  
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    pageSize && setPageSize(pageSize);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: getUserListData?.length,
    onChange: handlePageChange,
    showSizeChanger: true,
    onShowSizeChange: handlePageChange,
  };
  let navigate = useNavigate();

  const userAddHandleClick = () => {
    navigate("/user-add")
  }
  const slicedData = getUserListData?.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  );

  const columns: ColumnsType<UserList> = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Status',
      dataIndex: 'emailVerified',
      render: (emailVerified: boolean) => {
        return <span>{emailVerified ? "Active" : "Inactive"}</span>;
      },
    },
  ];

  if (!userProfile?.parentId) {
    columns.push({
      title: 'Action',
      render: (__: any, index: UserList) => {
        return (
          <>
           <div className='d-flex btn_spacing_ui'>
            {(!userProfile?.parentId || (userProfile?.parentId == userProfile?.userId)) && 
            <div>
                <Link color="primary" id={`userEdit-${__._id}`} className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2' to={`/user-edit/${__._id}`} state={{ itemIndex: index }}>
                  <i className='mdi mdi-pencil'></i>
                </Link>
                <UncontrolledTooltip target={`userEdit-${__._id}`} placement="left">
                  Allocated Number Update
                </UncontrolledTooltip>
              </div>
            }
              {(!userProfile?.parentId || (userProfile?.parentId == userProfile?.userId)) && __.parentId && userProfile?.userId !== __._id && <div>
                <Button color="danger" id={`userDelete-${__._id}`} outline type="button" onClick={() => showModal(__, __._id)} className="btn btn-outline-danger btnDanger d-flex align-items-center justify-content-center">
                <i className='mdi mdi-delete-outline'></i>
                </Button>
                <UncontrolledTooltip target={`userDelete-${__._id}`} placement="right">
                  Remove
                </UncontrolledTooltip>
              </div>}
              <Modal className='centerPopupModel' title="User Delete" open={isModalOpen} onOk={() => handleOk(deleteId)} onCancel={handleCancel} okButtonProps={{ disabled: okClicked }}>
                <p>Are you sure you want to delete this User ? </p>
              </Modal>
            </div>
          </>
        );
      },
    });
  }
  const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;
  return (
    <TableListingPagesWrapper className='settingTabComponent'>

      <div className='tableListingMain bg-white user-chat overflow-hidden user-chat-show h-100'>
        {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
            <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0">Users</h5>
                <div>
                  {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && <Button className='py-1 px-2 btn btn-primary font-size-13' onClick={userAddHandleClick} type="button">Add User</Button>}
                  </div> 
            </div>
        </Card> */}
        <div className='userListCardMain h-100'>
          <Card className='userListCard h-100' 
            title={
              <div className='d-flex flex-column py-2'>
                <h2 className='mb-1'>Users</h2>
                <p className='mb-0'>
                  All the users added to the account are shown below. You can also make changes to a user by clicking on the edit icon.
                  <a href='https://support.controlhippo.com/support/solutions/articles/1060000057394-how-to-invite-users-in-controlhippo-' className='ms-1 text-decoration-underline' target='_blank'>Need Help?</a>
                </p>
              </div>
            }  
            extra={(!userProfile?.parentId || (userProfile?.parentId == userProfile?.userId)) && <Button className='py-1 px-2 btn btn-primary font-size-13 tableCreateBtn' onClick={userAddHandleClick} type="button">Add User</Button>}
          >
            <Table
              //rowSelection={rowSelection}
              columns={columns}
              dataSource={(slicedData || []).map((item: any, index: number) => ({ ...item, key: index }))}
              pagination={false}
            />
            <div className='tablePaginationPos'>
              <Pagination {...paginationConfig} />
            </div>
          </Card>
        </div>
      </div>
    </TableListingPagesWrapper>
  );
};

export default UserList;
