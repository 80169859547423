import styled from 'styled-components';
import {
  
} from "@ant-design/icons";

const AccountCancleWrapper = styled.div`
.cancleReasonsDiv{
  .ant-radio-wrapper{
    max-width:450px;
    margin:0px;
    .ant-radio{
      display: none;
    }
    span{
      &:last-child{
        padding:0px !important;
      }
    }
    .cancleReasonsCard{
      border-radius:12px !important;
      border-color:#E0E0E0;
      cursor:pointer;  
      .ant-card-body{
        display: flex;
        flex-direction: column;
        padding:18px;
      }
    }
    .optionsDesc{
      color: #8E8E8E !important;
    }
  }
  .ant-radio-wrapper-checked{
    .cancleReasonsCard{
      border-color:#0029c7 !important;
      position: relative;
      &::before{
        position: absolute;
        content: "";
        top: 7px;
        right: 7px;
        height: 16px;
        width: 16px;
        background-image: url('https://images.controlhippo.com/svg/check_cir_ctrlh.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
      }
    }
  }
}
.AccountCancleCardContainer{
  max-width: 950px;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancleLastCard{
  max-width: 620px !important;
}
.AccountCancleMainCard{
  min-height:calc(100vh - 104px);
  .ant-card-head {
    padding: 0px 15px;
  }
  .pageBackBtn {
    border-radius: 5px!important;
    background: #EFEFEF!important;
    border-color: #EFEFEF!important;
    color: #495057!important;
  }
  position: relative;
  >.ant-card-body{
    min-height:calc(100vh - 161px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .cancleAcActions{
      margin-top:auto !important;
      .cancleButtonActive{
        background: #0029c7 !important;
        color: #fff !important;
        border-color: #0029c7 !important;
      }
    }
  }
  .cardTopdesc,.agreeCheckBox span:last-child{
    color:#495057;
    font-weight:400 !important;
  }
  .ant-card{
    border-radius:12px !important;
    border-color:#E0E0E0;
  }
  .cancleAcForm{
    .ant-checkbox-wrapper{
      &:hover{
        .ant-checkbox-inner{
          border-color: #0029c7 !important;
        }
      }
      .ant-checkbox-checked{
        .ant-checkbox-inner{
          background-color: #0029c7 !important;
          border-color: #0029c7 !important;
        }
      } 
    }
  }
}
`;
export { AccountCancleWrapper };