
import React,{ useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useRedux } from '../hooks';
import { clearError } from '../redux/actions';
import UpGradePlanPopup from '../pages/PlanBilling/UpGradePlanPopup';

interface userDataType {
    chargebeeDetails:any
}
interface ConfirmationModalProps {
    type: string;
    isOpen: boolean;
    showUpgreadButton: boolean;
    message?: string;
    onConfirm?: (valye:any,data?:any) => void;
    onCancel: (value:boolean) => void;
    isRedirect?: boolean;
    isCancelled?:boolean
}

const ConfirmationModal = ({type, isOpen, message,showUpgreadButton, onConfirm, onCancel,isRedirect, isCancelled }: ConfirmationModalProps) => {
    if(isRedirect == undefined)isRedirect = true    
    const navigate = useNavigate();
    const { dispatch } = useRedux();
    const [modal, setModal] = useState(isOpen);
    const [footerButtonText, setFooterButtonText] = useState<string|null>("Confirm")
    const [from, setfrom] = useState<any>(null)
    useEffect(() => {
        switch (type) {
            case "Info":
                setFooterButtonText("Ok")
                break;
            case "Number Exist Same Account":
                setFooterButtonText("Ok")
                break;
            case "Number Exist Another Account":
                setfrom("numberExistInAnotherAccount")
                setFooterButtonText("Confirm")
                break;
            case "Ok":
                setFooterButtonText(null)
                break;
            default:
                setFooterButtonText("Confirm")
                break;
        }
    }, [type])
    
    const toggle = () => {
        setModal(!modal);
        if(modal)onCancel(true);
    }

    const handleConfirm = () => {
        if(onConfirm)onConfirm(true,{isCancelled})
        dispatch(clearError())
        toggle();
        if(isRedirect)navigate("/welcome")
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel(true);
        }
        toggle(); // Close the modal
    };
    const firstModalResponse = (data:any) => {
        if(data.success)toggle();
    }
    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='justify-content-end' toggle={toggle} close={true}>
                <div >
                    <Button close    onClick={toggle} />
                </div>
            </ModalHeader>
            <ModalBody className='mb-3'>
                <div>
                    <span>{message || 'Are you sure you want to proceed?'}</span>
                </div>
            </ModalBody>
            <ModalFooter className={`d-flex ${footerButtonText ?"justify-content-center": ""}`}>
                {showUpgreadButton? <UpGradePlanPopup from={from} className={"btn btn-primary"} lable={"Upgrade Plan"} onConfirmation={firstModalResponse}/>:footerButtonText && <Button color="primary" onClick={handleConfirm} size='sm' >{footerButtonText}</Button>}
                {!showUpgreadButton && !footerButtonText && <div><Button color="primary" onClick={handleConfirm} size='sm' >{"Ok"}</Button>  <Button  onClick={handleCancel} color="primary" outline size='sm' >{"Cancel"}</Button></div>}
            </ModalFooter>
        </Modal>
    );
};
interface ShowConfirmationModalTypes {
    isOpen:boolean;
    message:string;
}

export default ConfirmationModal ;