export enum AccountSettingActionTypes {
    API_RESPONSE_SUCCESS = "@@planBilling/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@planBilling/API_RESPONSE_ERROR",
    LABEL_SAVE = "@@planBilling/LABEL_SAVE",
    UPDATE_ACCOUNT_SETTING = "@@planBilling/UPDATE_ACCOUNT_SETTING",
  }
  

  export interface AccountSettingState {
    error: string;
    loading: boolean;
    invoices: [];
    plans:[],
    labelResponse: any
  }