import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { AccountSettingActionTypes } from "./types";
import { accountSettingApiResponseError, accountSettingApiResponseSuccess } from "./actions";

import { 
    labelSave as labelSaveApi,
    updateAccountSetting as updateAccountSettingApi,
 } from "../../api/index";
import {
    showSuccessNotification,
    showErrorNotification,
} from "../../helpers/notifications";
import { authLoginApiResponseSuccess } from "../actions";
import { AuthLoginActionTypes } from "../types";

function* labelSave({ payload :{payload}}: any) {
    try {
        const response: Promise<any> = yield call(labelSaveApi, payload);
        yield put(accountSettingApiResponseSuccess(
                AccountSettingActionTypes.LABEL_SAVE,
                response
            )
        );
        showSuccessNotification(response)
    } catch (error: any) {
        yield put(
            accountSettingApiResponseError(AccountSettingActionTypes.LABEL_SAVE, error)
        );
    }
}
function* updateAccountSetting({ payload :{payload} , userId}: any) {
    try {
        const response: Promise<any> = yield call(updateAccountSettingApi, payload, userId);
        yield put(accountSettingApiResponseSuccess(
                AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING,
                response
            )
        );
        yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(
            accountSettingApiResponseError(AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING, error)
        );
    }
}

export function* watchLabelSave() {
    yield takeEvery(AccountSettingActionTypes.LABEL_SAVE, labelSave);
}
export function* watchUpdateAccountSetting() {
    yield takeEvery(AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING, updateAccountSetting);
}

function* accountSetting() {
    yield all([
        fork(watchLabelSave),
        fork(watchUpdateAccountSetting),
    ]);
}

export default accountSetting;
