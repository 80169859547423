import { ReportsActionTypes, ReportsState } from "./types";

export const INIT_STATE: ReportsState = {
    error: "",
    loading: false,
};


const Reports = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ReportsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReportsActionTypes.GET_REPORTS: {
                    return {
                        ...state,
                        loading: false,
                        reportsData:action.payload.data
                    };
                }
                default:
                    return { ...state };
            }

        case ReportsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ReportsActionTypes.GET_REPORTS: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ReportsActionTypes.GET_REPORTS: {
            return {
                ...state,
                loading: true,
            };
        }
        default:
            return { ...state };
    }
}

export default Reports