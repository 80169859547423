import styled from 'styled-components';

const IntegrationWrapper = styled.div`
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9f9f9f;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-track {
        background: #e8e8e8;
    }
    .integrationMainCardCntnr{
        border-radius: 8px !important;
        .card-header{
            border-bottom: 1px solid #f0f0f0 !important;
            background-color: #fff !important;
            border-radius: 8px 8px 0px 0px !important;
            .page_discription{
                font-size: 12px !important;
                color: #aaaaaa;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.5px;
            }
        }
        .card-body{
            border-radius:0px !important;
        }
        .conversation-name {
            font-size: 15px;
        }
    }
    .integration_main{
        .integrationCardMain{
            padding-bottom:10px;
            @media (max-width:480px) {
                padding-bottom:20px;
            }
            .integration_card{
                box-shadow: 0 2px 2px 0 rgba(45,40,77,0.2),0 0 0 1px rgba(45,40,77,0.07);
                border-radius: 8px;
            }
        }
        .integrationCardMain:last-child{
            @media (max-width:480px) {
                padding-bottom:0 !important;
            }
        }
        .extenstionIntegrationCard{
            @media (max-width:991.98px) {
                padding-bottom:80px;
            }
        }
        .divForIntegrationBtnCtnr{
            button,a{
                padding: 6px 7px !important;
                width: 100%;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                font-size: 12px !important;
                margin: 0px auto !important;
            }
        }

    }
    .setUpGuide {
        position: absolute;
        right: 6px;
        top: 6px;
        font-size: 22px;
        color: #686868;
        &:hover{
            color: #0029c7 !important;  
        }
        .setUpGuide1 {
          font-size: 22px;
          cursor: pointer;
        }
      } 
`;

export { IntegrationWrapper };