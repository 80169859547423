import React, { useEffect, useRef, useCallback, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// hooks
import { useProfile } from "../../../hooks";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
import { ConversationWrapper } from './ConversationWrapper.style';
// import Day from "./Day";

// interface
import { MessagesTypes,conversationOnlyTypes } from "../../../data/messages";
import ForwardModal from "../../../components/ForwardModal";

// actions
import { forwardMessage, deleteImage ,updateConverSation,getChatUserConversations } from "../../../redux/actions";
import { Checkbox } from "antd";


const synRotateIcon  = "https://images.controlhippo.com/svg/rorate_icon_new.svg";
interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number,option:string,clientId:string) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isChannel: boolean;
  onScrollChange : (data:any,createdAt:any)=>void;
  onSend?: any;
  handleForward?: any;
  onClickForwardBtn?: any;
  handleClose?: any;
}
const Conversation = ({
  chatUserDetails,
  chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
  onScrollChange,
  onSend,
  handleForward,
  onClickForwardBtn,
  handleClose
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const[ conversation, setConversation ] = useState< null | conversationOnlyTypes | undefined >();
  const[ messageIndexId, setMessageIndexId ] = useState<any>();
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [checkedItems, setCheckedItems] = useState<Option["conversationId"][]>([]);
  const { userProfile } = useProfile();
  const [oldMessageSyncing, setOldMessageSyncing] = useState<Boolean>(false)
  const { getUserConversationsLoading, isMessageForwarded ,clientId, selectedChat,chatUserConversationsData,getConversationsCreatedAt,oldMessageSync} = useAppSelector(
    (state: any) => ({
      getUserConversationsLoading: state.Chats.getUserConversationsLoading,
      oldMessageSync: state.Chats.oldMessageSyncing,
      isMessageForwarded: state.Chats.isMessageForwarded,
      clientId: state.Chats.clientId,
      selectedChat : state.Chats.selectedChat,
      chatUserConversationsData : state.Chats.chatUserConversations,
      getConversationsCreatedAt : state.Chats.getConversationsCreatedAt,
    })
    );

    useEffect(() => {
      if(selectedChat){
        setForwardData(null)
        setSelectedValues(null)
        setCheckedItems([])
      }
    }, [selectedChat])

    
useEffect(() => {
  setOldMessageSyncing(oldMessageSync)
}, [oldMessageSync])

  const messages =chatUserConversations.length?chatUserConversations:[]
    // chatUserConversations.messages && chatUserConversations.messages.length
    //   ? chatUserConversations.messages
    //   : [];

  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle :any= document.getElementById("chat-conversation-list");
      const divHeight = listEle.clientHeight;
      let offsetHeight = 0;
      if (listEle) {
        if(getConversationsCreatedAt){
          offsetHeight = 2480 - window.innerHeight + 250 ;
          // offsetHeight = window.innerHeight*2;
        }else{
          offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
        }
      }
      if (offsetHeight) {
        if (!getConversationsCreatedAt) {
          ref.current
            .getScrollElement()
            .scrollTo({ top: offsetHeight, });
        } else {
          ref.current
            .getScrollElement()
            .scrollTo({ top: offsetHeight, behavior: "auto" });
        }
      }
    }
  }, [ref,getConversationsCreatedAt]);


  const handleScroll = () => {
    const scrollElement = ref.current.getScrollElement();
    const scrollTop = scrollElement.scrollTop;
    if (scrollTop === 0) {
      if (chatUserConversations.length > 0) {
        const createdAt: string | null | undefined = chatUserConversations[0]?.timestamp ? chatUserConversations[0]?.timestamp : chatUserConversations[0]?.createdAt;
        onScrollChange(true, createdAt);
      }
    }
  }
  useEffect(() => {
    if (ref && ref.current) {
      ref?.current?.recalculate();
      ref?.current?.getScrollElement().addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref?.current) {
          ref?.current?.getScrollElement().removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatUserConversations]);
  useEffect(() => {
    if (chatUserConversations && chatUserConversations.length > 0) {
      scrollElement();
    }
  }, [chatUserConversations, scrollElement]);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<
  null | MessagesTypes | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessagesTypes) => {
    message.isForward = true
    setForwardData(message);
    onClickForwardBtn(true)
    // setIsOpenForward(true);
  };


  useEffect(() => {
    if(handleClose == false){
      setForwardData(null)
      setSelectedValues(null)
      setCheckedItems([])
    }
  }, [handleClose == false])

  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number
  ) => {
    dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
  };
  const handleReplyScroll = (quotedStanzaID: any) => {
    if (quotedStanzaID) {
      const messageIndex = messages.findIndex((message: any) => message.conversationId === quotedStanzaID);
      setMessageIndexId("-1")
      setMessageIndexId(messageIndex)
      setTimeout(() => {
        setMessageIndexId("=1")
      }, 1500);
    }

  }

  type Option = {
    conversationId:any;
    serializedId : any;
    chatId: any;
  };
  
  const options = messages
  .map((data: any) => {
    return {
      conversationId: data?.conversationId,
      serializedId: data?.id?._serialized,
    };
  });


  useEffect(() => {
    if(checkedItems){
      handleForward(selectedValues, forwardData)

    }
  }, [checkedItems])
  
  

  const handleCheckBox = (item: Option) => {
    const updatedCheckedItems: Option["conversationId"][] = checkedItems.includes(item.conversationId)
    ? checkedItems.filter((checkedItem) => checkedItem !== item.conversationId)
    : [...checkedItems, item.conversationId];
    setSelectedValues(options.filter((option: Option) => updatedCheckedItems.includes(option.conversationId)));

    setCheckedItems(updatedCheckedItems);
    // handleCrossClear(forwardData)

  };
  return (
    <ConversationWrapper>
      <AppSimpleBar
        scrollRef={ref}
        className={`chat-conversation p-3 p-lg-4 positin-relative ${true ? 'paddingNoneForsync' : ''}`}
        scrollableNodeProps={ref}
      >
        {oldMessageSyncing && <><div className="sycOldConvDiv d-flex align-items-center justify-content-center w-100">
          <img src={synRotateIcon} width={24} height={24}/>
          <p className="mb-0 font-size-13">Syncing your old conversations</p>
        </div> <Loader /></>}
        <ul
          className={`list-unstyled chat-conversation-list pb-0 ${forwardData?.isForward ? "showBgHover" : ""}`}
          id="chat-conversation-list"
        >
        {(messages || []).map((message: MessagesTypes, key: number) => {
          // const isFromMe = message.meta.sender + "" === userProfile.uid + "";
          const isFromMe =message && message.fromMe;
          const indexKey =key;
          return (
            <Message
              message={message}
              key={key}
              indexKey={indexKey}
              chatUserDetails={chatUserDetails}
              onDelete={onDelete}
              onSetReplyData={onSetReplyData}
              isFromMe={isFromMe}
              onOpenForward={onOpenForward}
              forwardData={forwardData}
              isChannel={isChannel}
              onDeleteImage={onDeleteImage}
              deleteForEveryOne = {message.isDelete_everyone}
              handleReplyScroll={handleReplyScroll}
              messageIndexId={messageIndexId}
              messages={messages}
              handleCheckBox={handleCheckBox}
              handleClose={handleClose}
            />
          );
        })}
        {/*  <Day /> */}
      </ul>
      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          chatUserDetails={chatUserDetails}
          onForward={onForwardMessage}
          onSend={onSend}
          handleClose={handleClose}
        />
      )}
    </AppSimpleBar>
    </ConversationWrapper>
  );
};

export default Conversation;
