export enum UserListActionTypes {
    API_RESPONSE_SUCCESS = "@@profile/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@profile/API_RESPONSE_ERROR",
  
    GET_USER_LIST = "@@profile/GET_USER_LIST",
    GET_ALLOCATED_USER_LIST = "@@profile/GET_ALLOCATED_USER_LIST",
    POST_USER_ADD = "@@profile/POST_USER_ADD",
    GET_USER_DETAILS = "@@profile/GET_USER_DETAILS",
    USER_UPDATE = "@@profile/USER_UPDATE",
    DELETE_USER = "@@profile/DELETE_USER",
  }
  export interface ProfileState {
    profileDetails: object;
    isProfileFetched:boolean;
    getProfileLoading:boolean;
    isInvited:boolean;
    isUpdateUser:boolean;
    userDetails:any;
  }
  