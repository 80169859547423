import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { UserListActionTypes } from "./types";
import { getUserListApiResponse, getUserListApiResponseError } from "./actions";
import { 
    getUserList as getUserListApi ,
    getAllocatedUserList as getAllocatedUserListApi ,
    getUserDetails as getUserDetailsApi ,
    userAdd as getUserAddApi,
    updateUserDetails as updateUserDetailsApi,
    userDelete as userDeleteApi
} from "../../api/index";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getUserList() {
  try {
    const response: Promise<any> = yield call(getUserListApi);
    yield put(
      getUserListApiResponse(
        UserListActionTypes.GET_USER_LIST,
        response
      )
    );
  } catch (error: any) {
    yield put(
      getUserListApiResponseError(UserListActionTypes.GET_USER_LIST, error)
    );
  }
}
function* getAllocatedUserList({ payload: id ,callback}: any) {
  try {
    const response: Promise<any> = yield call(getAllocatedUserListApi, id);

    if(callback && typeof callback === "function" ){
      callback(response)
    }
    yield put(
      getUserListApiResponse(
        UserListActionTypes.GET_ALLOCATED_USER_LIST,
        response
      )
    );
  } catch (error: any) {
    yield put(
      getUserListApiResponseError(UserListActionTypes.GET_ALLOCATED_USER_LIST, error)
    );
  }
}

function* userAdd({ payload: data }: any): Generator<any, void, any> {
  try {
    const response = yield call(getUserAddApi, data); // Make an API call using getUserAddApi function
    if (response.success) {
      yield call(showSuccessNotification, response);
    } else if(response.success == false && !response?.reInvite){
      yield call(showErrorNotification, response);
    }
    yield put(
      getUserListApiResponse(UserListActionTypes.POST_USER_ADD, response)
    );
  } catch (error: any) {
    yield put(
      getUserListApiResponseError(UserListActionTypes.POST_USER_ADD, error)
    );
  }
}

function* getUserDetails({ payload: id}:any) {
  try {
    const response: Promise<any> = yield call(getUserDetailsApi, id);
    yield put(
      getUserListApiResponse(UserListActionTypes.GET_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      getUserListApiResponseError(UserListActionTypes.GET_USER_DETAILS, error)
    );
  }
}
function* updateUserDetails({ payload:{ id,data}}:any) {
  try {
    const response: Promise<any> = yield call(updateUserDetailsApi,id,data);
    yield call(showSuccessNotification, response);
    yield put(
      getUserListApiResponse(UserListActionTypes.USER_UPDATE, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, "An unexpected error has occurred!");
    yield put(
      getUserListApiResponseError(UserListActionTypes.USER_UPDATE, error)
    );
  }
}

function* userDelete(data:any) {
  try {
    const response: Promise<any> = yield call(userDeleteApi,data.data.data);
    yield put(
      getUserListApiResponse(
        UserListActionTypes.DELETE_USER,
        response
      )
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(
      getUserListApiResponseError(UserListActionTypes.DELETE_USER, error)
    );
  }
}

export function* watchGetUserList() {
  yield takeEvery(UserListActionTypes.GET_USER_LIST, getUserList);
}
export function* watchGetAllocatedUserList() {
  yield takeEvery(UserListActionTypes.GET_ALLOCATED_USER_LIST, getAllocatedUserList);
}
export function* watchAddUserList() {
  yield takeEvery(UserListActionTypes.POST_USER_ADD, userAdd);
}
export function* watchGetUserDetails() {
  yield takeEvery(UserListActionTypes.GET_USER_DETAILS, getUserDetails);
}
export function* watchUpdateUserDetails() {
  yield takeEvery(UserListActionTypes.USER_UPDATE, updateUserDetails);
}
export function* watchDeleteUser() {
  yield takeEvery(UserListActionTypes.DELETE_USER, userDelete);
}
function* getUserListSaga() {
  yield all([
    fork(watchGetUserList),
    fork(watchGetAllocatedUserList),
    fork(watchAddUserList),
    fork(watchGetUserDetails),
    fork(watchUpdateUserDetails),
    fork(watchDeleteUser)
  ]);
}

export default getUserListSaga;
