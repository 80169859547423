import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const labelSave = (payload: string) => {
    return api.create(url.LABEL_SAVE_URL, payload);
};
const updateAccountSetting = (payload: any, userId:  string) => {
    return api.create(url.UPDATE_ACCOUNT_SETTING_URL+ "/" +userId, payload);
};

export {
    labelSave,
    updateAccountSetting
};
