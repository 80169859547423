import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import InviteContactModal from '../../../components/InviteContactModal';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';
import { downloadChat } from "../../../redux/actions";


//images
import imagePlaceholder from "../../../assets/images/users/profile-placeholder.png";

// constants
import { STATUS_TYPES } from "../../../constants";
import { DownloadOutlined } from "@ant-design/icons";
import { useProfile, useRedux } from "../../../hooks";
interface ProfileUserProps {
  onCloseUserDetails: () => any;
  chatUserDetails: any;
  onOpenVideo: () => void;
  onOpenAudio: () => void;
}
const ProfileUser = ({
  onCloseUserDetails,
  chatUserDetails,
  onOpenAudio,
  onOpenVideo,
}: ProfileUserProps) => {
  const { userProfile } = useProfile();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const [editNumberDetails, setEditNumberDetails] = useState("");
  const [dowanloadDisable, setDowanloadDisable] = useState(true);
  const { dispatch, useAppSelector } = useRedux();
  const { downloadChatResponse, clientId } = useAppSelector(state => ({
    downloadChatResponse: state.Chats.downloadChatResponse,
    clientId: state.Chats.clientId,
  }));

  useEffect(() => {
    if(downloadChatResponse == "DATA"){
      setDowanloadDisable(true)
    }
  }, [downloadChatResponse])
  
  const profile = chatUserDetails && chatUserDetails?.profilePic
    ? chatUserDetails.profilePic
    : imagePlaceholder;
  const fullName = chatUserDetails && chatUserDetails?.verifiedName? chatUserDetails?.verifiedName :  chatUserDetails?.firstName &&(chatUserDetails?.firstName === undefined)
      ? chatUserDetails.pushname
        ? `${chatUserDetails.pushname}`
        : "-"
      :chatUserDetails ?.firstName ;

  const replaceImage = (error: any) => {
    error.target.src = imagePlaceholder
  }

  const showEditForm = (__ : any, data : any) => {
    setIsOpenAddContact(true)
    setEditNumberDetails(__)
}

const closeAddContactModal = () => {
  setIsOpenAddContact(false);
};
const onInviteContact = () => {
};
const phoneNumberUtil = PhoneNumberUtil.getInstance();
const formatPhoneNumber = (phoneNumber: PhoneNumber): string => {
  const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
  return formattedNumber.replace(/ /g, ' '); // Add space after each digit
};
const phoneNumber = "+" + chatUserDetails?.user;

function stringsMatch(str1: any, str2: any) {
  if (str1 == null || str2 == null) {
    return false;
  }
  if (str1 !== str2) {
    return str1 === `+${str2}`
  }
  return str1 === str2;
}
const isStringMatch = stringsMatch(fullName, chatUserDetails?.user)
let decodedNumber: string | null = null;
let number: PhoneNumber | undefined;
  if (phoneNumber && !chatUserDetails?.isGroup) {
    try {
      number = phoneNumberUtil.parse(phoneNumber);
      decodedNumber = formatPhoneNumber(number);
    } catch (e) {
      console.error('Error parsing phone number:3', phoneNumber);
    decodedNumber = phoneNumber
  }
}
const handleDownloadChat = (data: any) => {
  setDowanloadDisable(false)
  dispatch(downloadChat(data,clientId,userProfile?.authToken, userProfile?.timezone))
}
  return (
    <>
    <div className="p-3 pb-1 border-bottom">
      <div className="user-profile-img text-center">
        <div className="d-flex w-100">
          <div className="flex-grow-1 d-flex justify-content-between align-items-center flex-row-reverse">
            <Button id={`downloadChat-profileUser`} disabled={!dowanloadDisable} className="btn btn-outline-light btn-light d-flex align-items-center justify-content-center py-1 px-1 ms-2 profileEditBtn btn btn-secondary"><DownloadOutlined onClick={()=>{handleDownloadChat(chatUserDetails)}} className="font-size-12" style={{color:"#0029c7"}}/></Button> 
            <UncontrolledTooltip target={`downloadChat-profileUser`} placement="right">
              Download Chat
            </UncontrolledTooltip>
            <Button
              color="none"
              type="button"
              className="btn nav-btn text-white user-profile-show d-none d-lg-block p-0"
              onClick={onCloseUserDetails}
            >
              <i className="bx bx-x text-dark font-size-24"></i>
            </Button>
            {/* <Button
              type="button"
              color="none"
              className="btn nav-btn text-white user-profile-show d-block d-lg-none p-0"
              onClick={onCloseUserDetails}
            >
              <i className="bx bx-left-arrow-alt text-dark font-size-24"></i>
            </Button> */}
          </div>
        </div>
        <img src={profile} className="profile-img rounded" width={240} alt="" onError={replaceImage} />
        {/* <div className="overlay-content rounded userProfileImgMain"> */}
        <div className="rounded userProfileImgMain">
          {/* <div className="user-chat-nav p-2">
            <div className="d-flex w-100">
              <div className="flex-shrink-0">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    color="none"
                    className="btn nav-btn text-white"
                    type="button"
                  >
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
                      to="#"
                    >
                      View Profile <i className="bx bx-user text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none"
                      to="#"
                      onClick={onOpenAudio}
                    >
                      Audio <i className="bx bxs-phone-call text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center d-lg-none"
                      to="#"
                      onClick={onOpenVideo}
                    >
                      Video <i className="bx bx-video text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                    >
                      Archive <i className="bx bx-archive text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                    >
                      Muted <i className="bx bx-microphone-off text-muted"></i>
                    </DropdownItem>
                    <DropdownItem
                      className="d-flex justify-content-between align-items-center"
                      to="#"
                    >
                      Delete <i className="bx bx-trash text-muted"></i>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div> */}
          <div className="py-2 px-3 userProfileNameTab d-flex align-items-center justify-content-center">
            <h5 className="user-name mb-0 text-truncate lh-1">{!isStringMatch || (isStringMatch && !decodedNumber) ? fullName : decodedNumber}</h5>
            <Button id={`editButton-profileUser`} onClick={() => showEditForm(chatUserDetails, chatUserDetails._id)} className="btn btn-outline-light btn-light d-flex align-items-center justify-content-center py-0 px-1 ms-2 profileEditBtn">
              <span className="mdi mdi-pencil text-primary font-size-14"></span>
            </Button>
            <UncontrolledTooltip target={`editButton-profileUser`} placement="right">
                Edit
            </UncontrolledTooltip>
            
            { chatUserDetails?.status && (
              <p className="font-size-14 text-truncate mb-0">
                <i
                  className={classnames(
                    "bx",
                    "bxs-circle",
                    "font-size-10",
                    "me-1",
                    "ms-0",
                    {
                      "text-success":
                        chatUserDetails.status === STATUS_TYPES.ACTIVE,
                    },
                    {
                      "text-warning":
                        chatUserDetails.status === STATUS_TYPES.AWAY,
                    },
                    {
                      "text-danger":
                        chatUserDetails.status === STATUS_TYPES.DO_NOT_DISTURB,
                    }
                  )}
                ></i>{" "}
                {chatUserDetails.status}
              </p>
            )}
          </div>
          <div className="userProfilePhoneNumber">{decodedNumber && decodedNumber}</div>
        </div>
      </div>
    </div>
      {isOpenAddContact && (
        <InviteContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onInvite={onInviteContact}
          editContactName={true}
          editNumberDetails={editNumberDetails}
        />
      )}     
    </>
    
  );
};

export default ProfileUser;
