export enum ReportsActionTypes {
    API_RESPONSE_SUCCESS = "@@integrations/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@integrations/API_RESPONSE_ERROR",
    GET_REPORTS = "@@integrations/GET_REPORTS",

}
export interface ReportsState {
    error: string;
    loading: boolean;
}
