import styled from 'styled-components';
import ArrowDown from '../../../assets/images/icons/arrow_down.svg';
import QrCodeScannerIcon from '../../../assets/images/icons/qr_code_scaning.svg';
import AddUserIconWhite from '../../../assets/images/icons/add_user_white.svg';
import AddUserIcon from '../../../assets/images/icons/add_user.svg';
import BxPlusIcon from '../../../assets/images/icons/bx_plus_icon.svg';
import BxsCameraIcon from '../../../assets/images/icons/bxs_camera_icon.svg';
import BxsChevronIcon from '../../../assets/images/icons/bx_chevron_right.svg';
import BxsFileIcon from '../../../assets/images/icons/bxs_file_icon.svg';
import BxsVideoIcon from '../../../assets/images/icons/bxs_video_icon.svg';
import BxsMusicIcon from '../../../assets/images/icons/bxs_music_icon.svg';
import BxsEditAltIcon from '../../../assets/images/icons/bxs_edit_alt_icon.svg';
import BxsUserAddIcon from '../../../assets/images/icons/bxs_user_plus_icon.svg';
import BxsUserAddIconWhite from '../../../assets/images/icons/bxs_user_plus_icon_white.svg';
import BxSearchBlueIcon from '../../../assets/images/icons/bx_search_blue_icon.svg';
import BxCaretDownIcon from '../../../assets/images/icons/bx-caret-down-icon.svg';
import QrScanIcon from '../../../assets/images/icons/qr-scan-icon.svg';
import searchIconGrey from '../../../assets/images/icons/search_icon_grey.svg';
import createChatIcon from '../../../assets/images/icons/create_chat_icon.svg';
import filterChatIcon from '../../../assets/images/icons/filter_new_icon.svg';

const ChatIndex = styled.div`
  height: calc(100vh - 55px);
  @media (min-width:500px) and (max-width: 991.98px){
    height: 100vh !important;
  }
  @media(max-width: 499px){
    background-color: white;
    height: calc(100vh - 109px) !important;
  }
  .logged_out_main, .logged_in_main{
    background-color: #F8D7DA;
    .circle_status_icon{
      background-color: #fff;
      border-radius: 50px;
      opacity: 1;
      span{
        font-size: 22px!important;
        font-weight: 500;
        line-height: normal !important;
        vertical-align: middle;
        background-image: url(${BxPlusIcon}) !important;
        width: 24px !important;
        height: 24px !important;
        min-width: 24px !important;
      }
      .qr-scan-icon {
        background-image: url(${QrScanIcon}) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
      }
    }
    .width_connection_main{
      @media(min-width:600px) and (max-width:991.98px){
        max-width:500px !important;
      }
      @media(max-width:991.98px){
        width: 100% !important;
      }
      .logged_out_text, .offline_status{
        font-size: 12px; 
        color: #721C24;
      }
      .logged_in_text, .online_status{
        font-size: 14px;
        color: #155724;
        margin-left: 4px;
        a{
          font-size:12px;
          margin-left: 2px
        }
      }
      .connection_status{
        color: #111b21;
        font-size:13px;
        font-weight: 500;
      }
      .colorRed a{
        font-weight:500;
        color: #4285f4;
        text-decoration:underline !important;
      }
      }
      .colorRed{
        color:red !important;
      }
    }
  }
  .logged_in_main{
    i{
      color: #0029c7 !important;
    }
  }
  .logged_out_main{
    i{
      color: #c70000 !important;
    }
  }
  .chatRoomListMain .chatMsgListMain .chat-user-list li a{
    padding: 10px 12px 10px 9px!important;  
  }
  .chat-user-list li .mdi-sync-off, .chat-user-list li .mdi-sync-off::before {
    font-size: 14px;
  }
  .logged_out_main, .logged_in_main{
    padding: 2px 2px 7px !important;
  }
  chatRoomListMain .chat-user-img .avatar-xs,
  .logged_in_main{
    background-color: #ffffff !important;
  }
  .chatRoomListMain .chat-user-img .avatar-xs,
  .circle_status_icon{
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
    // margin-right: 8px !important;
    @media (max-width:480px) {
      height: 20px !important;
      width: 20px !important;
      min-width: 20px !important;
    }
  }
  .chatRoomListMain{
    .chatMsgListMain .chat-user-list li .unread-msg-user .badge{
      background-color: #25d366 !important;
      color: white !important;
      border-radius: 50px !important;
      width: 14px;
      height: 14px;
      font-size: 7.7px !important;
      line-height: normal;
    }
    .lastMsgText{
      max-width:62% !important;
      color:#667781;
      line-height:normal !important;
      .bxs-camera-icon,
      .bxs-file-icon,
      .bxs-video-icon,
      .bxs-music-icon,
      .bxs-edit-alt-icon {
        font-size: 12px!important;
        font-weight: 500;
        line-height: normal !important;
        vertical-align: middle;
        width: 12px !important;
        height: 12px !important;
        min-width: 12px !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      .bxs-camera-icon {
        background-image: url(${BxsCameraIcon}) !important;
      }
      .bxs-file-icon {
        background-image: url(${BxsFileIcon}) !important;
      }
      .bxs-video-icon {
        background-image: url(${BxsVideoIcon}) !important;
      }
      .bxs-music-icon {
        background-image: url(${BxsMusicIcon}) !important;
      }
      .bxs-edit-alt-icon {
        background-image: url(${BxsEditAltIcon}) !important;
      }
    }
    .chat-user-list li.active{
      .chatUsrName{
        color: #000 !important;
      }
    }
    .chatMsgListMain{
      .chatUsrName{
        max-width: 94px !important;
        font-size: 14px !important;
        color: #333333 !important;
        font-weight: 500;
      }
      .chatAssinedUsrName{
        max-width: 48px !important;
        color: #667781;
      }
      .chatListLableMain,
      .chatListLableMain i{
        line-height:normal !important;
      }
      .bx_chevron_right {
        background-image: url(${BxsChevronIcon}) !important;
        width: 16px !important;
        height: 16px !important;
        min-width: 16px !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .no-chat-user {
      text-align: center;
      padding: 110px 50px;
      .no-chat-title {
        font-size: 21px;
        font-weight: 500;
        margin-top: 20px;
        color: #495057;
        line-height: 1.3em;
      }
       p {
          color: #797C8C;
          line-height: 1.6em;
      }
    }
  }
  .newAcChatRadio{
    border-radius: 50px;
    margin-left: 20px;
    background-color: white;
    display: block;
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bx-plus{
      font-size: 24px !important;
      color: #686868!important;
    }
  }
  .chatTopDropDownDiv{
    flex:1;
  }
.userNewDropdownMain{
  @media (min-width:992px) {
    min-width:158px !important;
    max-width:158px !important;
  }
  @media (max-width:991.98px) {
    width:100% !important;
  }
  @media (max-width:480px) {
    margin-left: 6px;
  }
  @media (max-width:320px) {
    min-width:246px !important;
  }
  .userNewDropdown{
    .wpLinkedUserName{
      @media (min-width:992px) {
        max-width:108px;
      }
      @media (max-width:991.98px) {
        max-width:300px;
      }
      @media (max-width:480px) {
        max-width:136px !important;
      }
      @media (max-width:320px) {
        max-width:96px !important;
      }
    }
    .dropdown-item .dropItemUserName{
      @media (min-width:992px) {
        max-width:200px !important;
      }
      @media (max-width:991.98px) {
        max-width:300px;
      }
      @media (max-width:480px) {
        max-width:196px !important;
      }
      @media (max-width:320px) {
        max-width:98px !important;
      }
    }
    .dropdown-toggle,.dropdown-menu{
      @media (min-width:500px) and (max-width:991.98px){
        min-width:400px !important;
      }
    }
    .dropdown-menu{
      width: 280px !important;
    }
    .dropdown-toggle::after{
      position: absolute !important;
      content: "";
      top: 7px;
      right: 2px;
      background-image: url(${ArrowDown});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center !important;
      display: block;
      height: 18px !important;
      width: 18px !important;
    }
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle:active,
    .dropdown-toggle:focus,{
      border-radius: 8px !important;
      // box-shadow: 0px 1px 7px #ddd;
    }
    .dropdown-toggle,
    .dropdown-toggle:hover,
    .dropdown-toggle:active,
    .dropdown-toggle:focus,
    .dropdown-toggle.show {
      position: relative;
      background-color: #F4F5FA !important;
      border-color: #F4F5FA !important;
      color: #495057 !important;
      font-size: 12px !important;
      padding: 6px 20px 6px 12px !important;
      text-align: left;
      height: 30px !important;
    }
    .dropdown-toggle.show {
      border-bottom-left-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }
    .dropdown-menu{
      padding: 0!important;
      transform: translate3d(0px, 33px, 0px)!important;
      border-radius: unset !important;
      max-height: 410px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 14px;
      }
      &::-webkit-scrollbar-track {
        background: #e8e8e8;
    }
      .dropdown-item:hover{
        background-color: #EBEFFF;
      }
      .dropdown-item{ 
        font-size: 12px !important;
        text-align: left;
        padding-left: 10px !important;
        padding-right: 10px !important;
        color: #495057;
        position: relative;
        white-space: pre-wrap;
      }
    }
  }
  .newQrBtn{
    background-color: #DAD6FF !important;
    border-color: #DAD6FF !important;
    padding:5px 8px !important;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    height: 32px !important;
  }
}
.mentionChat,  
.moreTab > span:last-child{
  position:relative !important;
}
.mentionChat::before{
  left: unset !important;
  top: 50% !important;
  right: 8px !important;
  transform: translateY(-50%);
} 

// .mentionChat::before,
// .moreTab > span:last-child::before

.mentionChat::before {
  background-color: #ef476f;
  content: '';
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  left: -12px;
  top: 10px;
}
.messagesStatusText {
    text-align: center;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    width: 100%;
    position: relative;
}

.messagesStatusText:before,
.messagesStatusText:after {
    content: '';
    position: absolute;
    // width: 100%;
    height: 1px;
    background-color: #F1F3F9;
    top: 50%; /* Center the line vertically */
    transform: translateY(-50%);
    left: 0;
}

.messagesStatusText span {
    font-size: 9px;
    font-weight: 510;
    line-height: 11px;
    color: #1C1D22;
    padding: 6px 12px;
    border-radius: 20px;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    background-color: #f4f5fa;
    display: inline-block;
}

.messagesStatusText:before {
    left: 0;
    right: calc(25% + 50px);
}

.messagesStatusText:after {
    right: 0;
    left: calc(25% + 50px); 
}

.mobStickyChatSidebar{
  // box-shadow: 1px 1px 12px #ddd !important;
  // border-bottom: 1px solid #eeeeee;
  @media (max-width:500px) {
    position: fixed;
    top: 0;
    z-index: 5;
    background-color: white;
    width: 100% !important;
  }
  .userDropDownInStickyChat{
    display:none;
    @media(max-width:991.98px){
      display:block !important;
    }
    .userProfileDropdown {
      list-style:none !important;
      .dropdown-menu{
        transform: translate3d(-5px, 31px, 0px) !important;
        font-size: 13px !important;
        padding: 4px 0px !important;
        .dropdown-item{
          padding: 5px 14px !important;
          width:auto !important;
          i{
            font-size: 15px !important;
          }
        }
      }
    }
    .chatMobUserDrop{
      // border-radius: 50px;
      // border: 1px solid #ddd;
      // background-color: white;
      // box-shadow: 0px 2px 6px 0px #ddd;
      height: auto !important;
      width:auto !important;
    }
  }
  
  .chatAddScanIconMobHide{
    @media(max-width:991.98px){
      display:none !important;
    }
  }
  .chatSearchMain{
    #serachChatUser{
      padding: 10px !important;
      height: 47px !important;
      font-size: 13px !important;
      background-color: #fff !important;
    }
  }
  .chatFilterTabs{
    .chatfilterTab{
      span:last-child{
        display: flex !important;
        align-items: center !important;
      }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled,
    .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:hover{
      color:#a1a1a1 !important;
      background-color:white !important
    }
    .ant-radio-button-wrapper{
      &:first-child{
        padding-left: 10px !important;
      }
      &:last-child{
        padding:7.3px 0px 7.3px 2px  !important;  
        margin-right: 8px !important;
      }
      border:unset !important;
      border-radius:unset !important;
      font-size: 12px;
      font-weight: 500;
      padding:7.3px 4px !important;
      height:auto !important;
      border-bottom:2px solid transparent  !important;
      &:hover{
        color: #0029C7 !important;  
      }
      &:before{
        content:unset !important;
      }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border-color:#0029C7 !important;
      color: #0029C7 !important;
    }
  }
}
.secondaryBorder.primaryBorder{
  border-color:#CFD3D4 !important;
  color: #9B9FA3 !important;
  i{
    color: #9B9FA3 !important
  }
}
.primaryBorder{
  border:1px solid #CFD3D4 !important;
  border-radius:4px;
  width: 22px;
  height: 22px;
  cursor:pointer;
  i{
    font-weight: 600 !important;
    color: #0029C7 !important;
  }
}
#add-contact{
  &:has(.chatUserAdd) .btn{
    padding: 0 !important;
  }
  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
    background-color: #0029c7 !important;
    color: #fff !important;
  }
  .btn:focus .chatUserAdd,
  .btn:hover .chatUserAdd{
    background-image: url(${AddUserIconWhite}) !important;
  }
  .chatUserAdd{
    background-image: url(${AddUserIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center!important;
    display: block;
    height: 28px !important;
    width: 28px !important;
  }
  .userAddButton{
    height: 30px !important;
    width: 30px !important;
    display:flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
    padding: 4px;
    background: #ffffff;
    trasitions: 0.5s;
    &:hover {
      background: #0029c7 !important;
    }
    .userAddIcon{
      font-size:20px !important;
      vertical-align:middle !important;
      line-height:normal !important;
    }
    .bxs-user-plus-icon {
      background-image: url(${createChatIcon}) !important;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
    &:hover .bxs-user-plus-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(74%) saturate(92%) hue-rotate(185deg) brightness(119%) contrast(100%);
    }
  }
}
.filterTabBlock{
  background: #fff;
  position: relative;
  &:hover {
    background: #0029c7 !important;
  }
  &:hover .filterIcon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(74%) saturate(92%) hue-rotate(185deg) brightness(119%) contrast(100%);
  }
}
.filterIcon {
  background-image: url(${filterChatIcon});
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center !important;
    display: block;
    height: 100% !important;
    width: 100% !important;
    border-radius: 4px;
}
.add_group_tabs_menus{
  height: 0px !important;
  width: 0px !important;
  .dropdown-toggle{
    background-color: transparent !important;
    border: unset !important;
    cursor: unset !important;
  }
  .dropdown-menu.show{
    transform: translate3d(25px, 38px, 0px)!important;
  }
}
.chatFilterDrpDwn,.add_group_tabs_menus{
  .dropdown-menu{
    padding: 0 !important;
    max-height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 14px;
    }
    &::-webkit-scrollbar-track {
      background: #e8e8e8;
    }
    .dropdown-item{
      font-size:13px !important;
      font-weight:500 !important;
      padding: 10px 16px !important;
      &:hover{
        background-color: #EBEFFF !important;
      }
    }
    .dropdown-item.activeFilterItem{
      background-color:#D6DEFF !important;
    }
  }
}
.chatFilterDrpDwn{
  height: 0px !important;
  width: 0px !important;
  .dropdown-toggle{
    background-color: transparent !important;
    border: unset !important;
    cursor: unset !important;
  }
  .dropdown-menu.show{
    transform: translate3d(54px, 26px, 0px)!important;
  }
  .dropdown-item {
    width: 232px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.addUserIcon_search {
  span {
    transition: 0.5s;
  }
  .primaryBorder {
    width: 30px !important;
    height: 30px !important;
    border-radius: 8px;
  }
  .bx-search {
    font-size: 16px !important;
  }
  .bx-search-icon {
    background-image: url(${BxSearchBlueIcon}) !important;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .search-icon-grey {
    background-image: url(${searchIconGrey}) !important;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .createChatIcon {
    background-image: url(${createChatIcon}) !important;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .search-icon-span:hover {
    background-color: #0029c7;
  }
  span:hover i {
    filter: brightness(0) saturate(100%) invert(100%) sepia(74%) saturate(92%) hue-rotate(185deg) brightness(119%) contrast(100%);
  }
}
.bx-caret-down-icon {
  background-image: url(${BxCaretDownIcon}) !important;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}
.chatfilterTab {
  &:hover .bx-caret-down-icon, &:active .bx-caret-down-icon, &:focus .bx-caret-down-icon {
    filter: invert(9%) sepia(91%) saturate(6702%) hue-rotate(234deg) brightness(87%) contrast(106%);
  }
}
.unreadMsgDRpDwnTop{
  width: 16px !important;
  min-width: 16px !important;
  height: 16px !important;
  display: flex!important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin-left: auto !important;
  margin-right: 2px !important;
  font-size: 8px;
  span{
    line-height:normal !important;
  }
}
.unReadDotChat{
  border-radius: 50px !important;
  width: 12px;
  height: 12px;
  color: #25d366;
  background-color: #25d366;
}

  .liveClDot {
    display: block;
    position: absolute;
    top: -3px;
    right: -3px;
    // transform: translateY(-50%);
    &::after {
      content: "";
      background-color: #ef476f;
      width: 6px;
      height: 6px;
      border-radius: 50px !important;
      position: absolute;
      display: block;
      top: 1px;
      left: 1px;
      -webkit-animation: dot-pulse 1s linear infinite;
      -moz-animation: dot-pulse 1s linear infinite;
      -o-animation: dot-pulse 1s linear infinite;
      animation: dot-pulse 1s linear infinite
    }
    .liveClOuterDot {
      margin: 1px;
    }
    .liveClInnerDot,
    .liveClOuterDot,
    .liveClInnerDot:after {
      display: block;
      opacity: 1;
      background-color: rgb(255 0 0 / 52%);
      width: 6px;
      height: 6px;
      border-radius: 50px !important;
      -webkit-animation: bubble-pulse 1s linear infinite;
      -moz-animation: bubble-pulse s linear infinite;
      -o-animation: bubble-pulse 1s linear infinite;
      animation: bubble-pulse 1s linear infinite
    }
    .liveClInnerDot:after {
      content: "";
    }
  }
  @keyframes bubble-pulse {
    0%,25% {
      transform: scale(1);
      opacity: 0.75;
    }
    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes dot-pulse {
    0%{
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;

export { ChatIndex };