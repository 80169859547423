import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import UpGradePlanPopup from '../pages/PlanBilling/UpGradePlanPopup'
import { useProfile, useRedux } from '../hooks'
import { changeSubscription, getProfileDetails } from '../redux/actions'
import { openChargebeePortal } from '../helpers/common.service'
import ConfirmationModal from './confirmationPopup'
import { checkEstimate, quantityCheck } from '../helpers/checkEstimate'
import { showErrorNotification } from '../helpers/notifications'

interface ChargebeeCheckProps {
    numberData?:any
    userDetails: any
    from?: string
    buttonText?: string
    onConfirm: (response: any) => void
}
interface UserDetailsType {
    email: string,
    chargebeeDetails: any;
    parentId: string
    userId: string
    phoneNumber: string,
    allPlans?: [any],
    allAddons?: [any],
    allUsers?: [any],
    numberDetails?: [any]
}

const ChargebeeCheck = ({numberData, buttonText, onConfirm, from }: ChargebeeCheckProps) => {
    const { userProfile } = useProfile()
    const { dispatch, useAppSelector } = useRedux()
    const [userDetails, setUserDetails] = useState<UserDetailsType>()
    const [loading, setLoading] = useState(false)
    const [isConfirmationPop, setIsConfirmationPop] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState("")
    const [confirmationType, setConfirmationType] = useState("")
    const [isRedirect, setIsRedirect] = useState(true)
    const [chargebeeDetails, setChargebeeDetails] = useState(userProfile?.chargebeeDetails)
    const [addonQuantity, setAddonQuantity] = useState<any>(null)
    const parentId = userProfile?.parentId?._id ? userProfile?.parentId?._id : userProfile?.parentId ? userProfile.parentId : userProfile?.userId
    const {
        plans,
        userData
    } = useAppSelector((state) => ({
        plans: state.PlanBilling.plans,
        userData: state.Login.user
    }))
    useEffect(() => {
        setUserDetails(userData)
    }, [])
    useEffect(() => {
        setUserDetails(userData)
        setChargebeeDetails(userData.chargebeeDetails)
    }, [userData])

    useEffect(() => {
        const fetchAddonQuantity = async () => {
            if (chargebeeDetails && chargebeeDetails.addons) {
                let addonName = from === "WABA" ? "waba" : "scan_whatsapp_number"
                const userData1 = userDetails ? userDetails : userProfile
                const result = await quantityCheck(userData1, addonName)
                setAddonQuantity(result)
            }
        }
        fetchAddonQuantity()
    }, [chargebeeDetails, from, userDetails, userProfile])
    
    const onConnect = async() => {
        setLoading(true)
        if(numberData){
            setLoading(false)
            return onConfirm({ success: true })
        }else if (((chargebeeDetails && chargebeeDetails?.status === "active" || chargebeeDetails?.status === "non_renewing" )) || (!chargebeeDetails && from !== "WABA") || (from !== "WABA" && chargebeeDetails && !chargebeeDetails?.customer_id)) {
            setLoading(false)
            onConfirm({ success: true })
        } 
        // else if (chargebeeDetails?.status === "in_trial" && chargebeeDetails?.card_status === "no_card" && !chargebeeDetails?.plan_name.includes("gold") &&  addonQuantity && !addonQuantity?.quantityEsist) {
        else if (chargebeeDetails?.status === "in_trial" ) {
            // setLoading(false)
            // setIsConfirmationPop(true)
            // setConfirmationMessage(`This will end your free trial and activate your subscription immediately. Please click "OK" to confirm.6666`)
            // setConfirmationType("Ok")
            // setIsRedirect(false)
            setLoading(false)
            return onConfirm({ success: true })
        } else if (chargebeeDetails?.status === "in_trial" && chargebeeDetails?.card_status === "valid") {
            setLoading(false)
            const objData = {
                item: "addon",
                eventType: "update_subscription_for_items",
                name: from?.toLowerCase(),
                userDetails,
                dispatch
            }
            let checkData: any = await checkEstimate(objData)
            // return 
            setIsConfirmationPop(true)
            setConfirmationMessage(`Adding a new channel will cost you ${checkData.currency_sign}${checkData.proratedCharge}.This will end your free trial and activate your subscription immediately. Please click "OK" to confirm.`)
            setConfirmationType("Ok")
            setIsRedirect(false)
        }else if(chargebeeDetails?.status === "in_trial" && addonQuantity && addonQuantity?.quantityEsist ){
            setLoading(false)
            return onConfirm({ success: true , skipEstimate : addonQuantity?.quantityEsist })
        }
        
        // else if( chargebeeDetails?.status === "non_renewing"  ){
        //     setLoading(false)

        //     const objData = {
        //         item: "addon",
        //         eventType: "update_subscription_for_items",
        //         name: from?.toLowerCase(),
        //         userDetails,
        //         dispatch
        //     }
        //     await checkEstimate(objData)
        // }
    }
    const onCancel = (value: any) => {
        setLoading(false)
        setIsConfirmationPop(false)
    }
    const onConfirmationModal = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            checkPlanDetails({ success: true, skipEstimate: true })
        }, 5000);
    }

    // Chek Plan & Details 
    const checkPlanDetails = async (response: any) => {
        if (!loading && chargebeeDetails &&((chargebeeDetails?.card_status === "no_card") || (chargebeeDetails?.status === "cancelled"))) {
            setLoading(true)
            const result: any = await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
            if (result.success) {
                if( chargebeeDetails?.status === "cancelled"){
                    onConfirm({ success: false ,skipEstimate: false})
                    setLoading(false)
                    setTimeout(() => {
                    }, 5000);
                    return
                }
                let res; 
                if (chargebeeDetails?.customer_id && chargebeeDetails.status === "in_trial") {
                    if (from !== "WABA" && (userDetails?.numberDetails && userDetails?.numberDetails.length >= 1)) {
                        const data = {
                            trial_end: 0
                        }
                        dispatch(changeSubscription({ eventType: "trial_end", data }, parentId, (res: any) => {
                            if(!res.success && res.error.api_error_code == "payment_processing_failed"){
                                res.message = 'Payment for additional scan numbers could not be processed. Please review payment details in the plan and billing sections.'
                                showErrorNotification(res)
                            }
                            response = res
                            return
                        }))
                    } else if (from == "WABA") {

                        let updateObject: any = {
                            subscription_items: []
                        }
                        const data = {
                            item_price_id: 'waba-USD-Monthly',
                            quantity: 1,
                        };
                        updateObject["trial_end"] = 0
                        updateObject.subscription_items.push(data)
                        if (from === "WABA") dispatch(changeSubscription({ eventType: "addon_add", updateObject }, parentId, (res: any) => {
                            if(!res.success && res.error.api_error_code == "payment_processing_failed"){
                                res.message = 'Payment for additional scan numbers could not be processed. Please review payment details in the plan and billing sections.'
                                showErrorNotification(res)
                            }
                            response = res
                            return
                        }))
                    }
                }
                dispatch(getProfileDetails(userDetails?.userId))
                setTimeout(() => {
                    if(response.success){
                        onConfirm({ success: true ,skipEstimate: true})
                    }else {
                        onConfirm({ success: false ,skipEstimate: false})
                    }
                    setLoading(false)
                }, 10000);
            }
        } else {
            setLoading(true)
            if (!response.checkout && (from === "WABA" && !chargebeeDetails || (chargebeeDetails && !chargebeeDetails.customer_id) || ( from === "WABA" && chargebeeDetails && chargebeeDetails?.status === "in_trial") || ( chargebeeDetails && !chargebeeDetails?.status  && chargebeeDetails.card_status === "valid") )){
                let updateObject: any = {
                    subscription_items: []
                }
                let eventType = "addon_add";
                if (chargebeeDetails && !chargebeeDetails?.status && chargebeeDetails.card_status === "valid" && response?.planDetails && response?.activeTab) {
                    eventType = "plan_add"
                    const planName = `${response?.planDetails?.name}-${response?.planDetails?.price_currency[0].currency.toUpperCase()}-${response?.activeTab.tabKey.charAt(0).toUpperCase() + response?.activeTab.tabKey.slice(1)}`
                    const quantity = userDetails?.allUsers?.length
                    const planObject = {
                        item_price_id: planName,
                        quantity: quantity,
                    }
                    updateObject.subscription_items.push(planObject)
                    if(from === "WABA")updateObject["trial_end"] = 0
                }
                if(chargebeeDetails?.status === "in_trial" && chargebeeDetails?.card_status === "valid")updateObject["trial_end"]=0
                const data = {
                    item_price_id: 'waba-USD-Monthly',
                    quantity: 1,
                };
                if (from === "WABA") updateObject.subscription_items.push(data)
                await dispatch(changeSubscription({ eventType: eventType, updateObject }, parentId,async(res:any)=>{
                    if(res.success){
                        response = res
                        response["skipEstimate"] = true
                        setTimeout(() => {
                            onConfirm(response)
                            setLoading(false)
                        }, 10000);
                    }else {
                        response = {success:false , message :"due to insufficent balance."}
                        if(!res.success && res.error.api_error_code == "payment_processing_failed"){
                            res.message = chargebeeDetails && !chargebeeDetails?.status && chargebeeDetails.card_status === "valid"? "due to insufficent balance, please update your card." : 'Payment for additional scan numbers could not be processed. Please review payment details in the plan and billing sections.'
                            showErrorNotification(res)
                            setLoading(false)
                            if(res?.error?.error_code === 'payment_error_during_create'){
                                await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
                            }
                        }
                    }
                }))
                await dispatch(getProfileDetails(userDetails?.userId))
            }
        }
    }

    const upgreadPlanPopupConfirmation = (response: any) => {
        if (response.success) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                checkPlanDetails({ success: true, checkout: response.checkout, skipEstimate: response?.skipEstimate, planDetails :response?.planDetails, activeTab : response?.activeTab})
            }, 5000);
        }
    }
    // First button show
    const checkButton = () => {        
        const isInTrialWithGoldPlanAndNoCard = chargebeeDetails?.status === "in_trial" && addonQuantity && !addonQuantity?.quantityEsist && chargebeeDetails?.card_status === "no_card" && chargebeeDetails?.plan_name.includes("gold");
        const isCancelled = chargebeeDetails?.status === "cancelled";
        const wabaWithTrial = chargebeeDetails?.status === "in_trial"  && from === "WABA"
        const isWabaWithoutCustomerId = from === "WABA" && (!chargebeeDetails || (chargebeeDetails && !chargebeeDetails.customer_id));
        const withoutSubscriptionId = chargebeeDetails && chargebeeDetails.customer_id  && !chargebeeDetails.subscription_id ;
        // if ((!numberData && (isInTrialWithGoldPlanAndNoCard || isWabaWithoutCustomerId)) || isCancelled) {
        // if (!numberData && ((chargebeeDetails?.status === "in_trial" && addonQuantity && !addonQuantity?.quantityEsist && chargebeeDetails?.card_status === "no_card" && chargebeeDetails?.plan_name.includes("gold")) || (chargebeeDetails?.status === "cancelled")|| ((from == "WABA" && !chargebeeDetails)  || (from == "WABA" && chargebeeDetails && !chargebeeDetails.customer_id )) )) {
        if ((!numberData && (isWabaWithoutCustomerId)) || isCancelled || wabaWithTrial || withoutSubscriptionId) {
            return (<UpGradePlanPopup isOpen={true} className="text-center btn btn-primary py-2 px-5 " from={from} lable={buttonText ? buttonText : "Connect"} onConfirmation={upgreadPlanPopupConfirmation} />)
        } else {
            return (
                <Button onClick={onConnect} disabled={loading} className="text-center btn btn-primary py-2 px-5">
                    {buttonText ? buttonText : "Connect"}
                </Button>)
        }
    }
    return (<>
        {checkButton()}
        {isConfirmationPop && <ConfirmationModal isOpen={true} message={confirmationMessage} type={confirmationType ? confirmationType : ""} showUpgreadButton={false} onCancel={onCancel} isRedirect={isRedirect} onConfirm={onConfirmationModal} />}
        {loading && <div className='loader-overlay'>
            <div className="loader-container">
                <div className="status">
                    <Spinner color="primary" />
                </div>
            </div>
            <h4 className="mt-3 text-muted">Loading please wait...</h4>
        </div>}
    </>)
}

export default ChargebeeCheck