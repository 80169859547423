import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input, Spinner } from "reactstrap";
import { List, Avatar, Checkbox as AntdCheckbox } from 'antd';

import classnames from "classnames";

// hooks
import { useRedux } from "../hooks/index";

// components
import AppSimpleBar from "./AppSimpleBar";

// interfaces
import { MessagesTypes, ContactTypes } from "../data";

// hooks
import { useProfile, useContacts } from "../hooks";

//utils
import { DivideByKeyResultTypes, divideByKey } from "../utils";
import EmptyStateContacts from "./EmptyStateResult";

import avatar1 from "../../src/assets/images/user_theme.svg";
import { getDirectMessages } from "../redux/actions";
import { debounce } from "redux-saga/effects";

interface ForwardMessageProps {
  forwardData: null | MessagesTypes | undefined;
  chatUserDetails: any;
}

interface ListItem {
  firstName: string;
  label: string;
  // Add other properties if needed
}
const ForwardMessage = ({
  forwardData,
  chatUserDetails,
}: ForwardMessageProps) => {
  const { userProfile } = useProfile();
  const replyUserName = chatUserDetails.firstName
    ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
    : "-";
  const isReplyFromMe =
    forwardData && forwardData?.meta?.sender + "" === userProfile?.uid + "";

  return (
    <div className="replymessage-block mb-2">
      <h5 className="conversation-name">
        {isReplyFromMe ? "You" : replyUserName}
      </h5>
      {forwardData?.text && <p className="mb-0">{forwardData?.text}</p>}

      {(forwardData?.image || forwardData?.attachments) && (
        <p className="mb-0">
          {forwardData?.attachments &&
            !forwardData?.image &&
            `${forwardData?.attachments.length} Files`}
          {forwardData?.image &&
            !forwardData?.attachments &&
            `${forwardData?.image.length} Images`}
          {forwardData?.image &&
            forwardData?.attachments &&
            `${forwardData?.attachments.length} Files & ${forwardData?.image.length} Images`}
        </p>
      )}
    </div>
  );
};

interface ContactItemProps {
  contact: ContactTypes;
  selected: boolean;
  onSelectContact: (id: string | number, selected: boolean) => void;
  onSend: (contact: Array<number | string>) => void;
}
const ContactItem = ({
  contact,
  selected,
  onSelectContact,
  onSend,
}: ContactItemProps) => {
  const fullName = `${contact.firstName} ${contact.lastName}`;
  const onClick = () => {
    onSelectContact(contact.id, !selected);
    if (!selected) {
      onSend([contact.id]);
    }
  };

  return (
    <li>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <h5 className="font-size-14 m-0">{fullName}</h5>
        </div>
        <div className="flex-shrink-0">
          {!selected ? (
            <Button size="sm" color="primary" type="button" onClick={onClick}>
              Send
            </Button>
          ) : (
            <Button size="sm" color="light" type="button" onClick={onClick}>
              Undo
            </Button>
          )}
        </div>
      </div>
    </li>
  );
};
interface CharacterItemProps {
  letterContacts: DivideByKeyResultTypes;
  index: number;
  totalContacts: number;
  selectedContacts: Array<number | string>;
  onSelectContact: (id: string | number, selected: boolean) => void;
  onSend: (contact: Array<number | string>) => void;
}

const CharacterItem = ({
  letterContacts,
  index,
  totalContacts,
  selectedContacts,
  onSelectContact,
  onSend,
}: CharacterItemProps) => {
  return (
    <div className={classnames({ "mt-3": index !== 0 })}>
      <div className="contact-list-title">{letterContacts.letter}</div>

      <ul
        className={classnames("list-unstyled", "contact-list", {
          "mb-0": index + 1 === totalContacts,
        })}
      >
        {(letterContacts.data || []).map((contact: any, key: number) => {
          const selected: boolean = selectedContacts.includes(contact.id);
          return (
            <ContactItem
              contact={contact}
              key={key}
              selected={selected}
              onSelectContact={onSelectContact}
              onSend={onSend}
            />
          );
        })}
      </ul>
    </div>
  );
};

interface ForwardModalProps {
  isOpen: boolean;
  onClose: () => void;
  forwardData: null | MessagesTypes | undefined;
  chatUserDetails: any;
  onForward?: (data: any) => void;
  onSend?: any;
  handleClose?: any;
  dropdownSelectedClientId?: any;
}

type Option = {
  value: string; // or the appropriate type for the value property
  label: string;
  chatId: any;
};
const ForwardModal = ({
  isOpen,
  onClose,
  forwardData,
  chatUserDetails,
  onForward,
  onSend,
  handleClose,
  dropdownSelectedClientId
}: ForwardModalProps) => {
  // global store
  const { userProfile } = useProfile();
  const { dispatch, useAppSelector } = useRedux();
  const ref = useRef<any>();
  const [loading, setLoading] = useState(false);

  const { contactsList, getChatList, getChatList1, directMessages, clientId } = useAppSelector(state => ({
    contactsList: state.Contacts.contacts,
    getChatList: state.Chats.directMessages,
    getChatList1: state.Chats.skipChatUserList,
    directMessages: state.Chats.directMessages,
    clientId: state.Chats.clientId,

  }));

  const allResults = getChatList || [];
  const options = allResults
  /*
  contacts hook
  */
  const { categorizedContacts, totalContacts } = useContacts();
  const [contacts, setContacts] = useState<any>([]);
  const [forwardDataArray, setForwardDataArray] = useState<any>(forwardData);
  useEffect(() => {
    setContacts(categorizedContacts);
  }, [categorizedContacts]);

  useEffect(() => {
    if(forwardData){
      setForwardDataArray(forwardData)
    }
  }, [forwardData])
  
  /*
  contact search
  */
  const [search, setSearch] = useState("");
  const onChangeSearch = (value: string) => {
    setSearch(value);
    let modifiedContacts = [...contactsList];
    let filteredContacts = (modifiedContacts || []).filter((c: any) =>
      c["firstName"].toLowerCase().includes(value.toLowerCase())
    );
    const formattedContacts = divideByKey("firstName", filteredContacts);
    setContacts(formattedContacts);
  };

  const totalC = (contacts || []).length;
  
  /*
  select contacts
  */
  const [selectedContacts, setSelectedContacts] = useState<
    Array<string | number>
  >([]);
  const onSelectContact = (id: string | number, selected: boolean) => {
    let modifiedList: Array<string | number> = [...selectedContacts];
    if (selected) {
      modifiedList = [...modifiedList, id];
    } else {
      modifiedList = modifiedList.filter(m => m + "" !== id + "");
    }
    setSelectedContacts(modifiedList);
  };

  /*
  share all
  */
  

  /*
  message
  */

  const [chatUserList, setChatuserList] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");

  const [message, setMessage] = useState<string | null | undefined>();
  const onChangeMessage = (value: string) => {
    setMessage(value);
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [groupName, setGroupName] = useState('');
  const [checkedItems, setCheckedItems] = useState<Option["value"][]>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [validationMessage, setValidationMessage] = useState("")

  const handleCheckboxChange = (item: Option) => {
    const updatedCheckedItems: Option["value"][] = checkedItems.includes(item?.chatId)
      ? checkedItems.filter((checkedItem) => checkedItem !== item?.chatId)
      : [...checkedItems, item?.chatId];
    
    if (updatedCheckedItems.length <= 5) {
      setSelectedValues(options.filter((option: Option) => updatedCheckedItems.includes(option.chatId)));
      setCheckedItems(updatedCheckedItems);
      setValidationMessage(""); 
    }else{
      setValidationMessage("Maximum 5 selections allowed for forwarding.");

    }
  };
  


//  useEffect(() => {
//    if(forwardData){
//     setForwardDataArray(forwardData)
//    }
//  }, [forwardData])
 
  const onSend1 = () => {
    const data = {
      selectedChatIds: checkedItems,
      forwardDataArray: forwardDataArray,
      // conversationId: forwardData?.conversationId,
      type: "forwardMsg",
      clientId: dropdownSelectedClientId? dropdownSelectedClientId : clientId
      // serializedId: forwardData?.id?._serialized
    };
    // onForward(data);
    onSend(data)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      onClose()
    }, 2000);
  };


  const onScrollChange = (data: any) => {
    if (data) {
      if (getChatList1.hasNext) {
        dispatch(getDirectMessages(userProfile.clientId, getChatList1.skip + 1, 50, "All", 0))
      }
    }
  }


  const handleScroll = () => {
    const scrollElement = ref.current.getScrollElement();
    const tolerance = 3; // Adjust tolerance as needed
    const scrollTop = scrollElement.scrollTop;
    const scrollHeight = scrollElement.scrollHeight;
    const clientHeight = scrollElement.clientHeight;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= tolerance;
    if (bottom) {
      if (chatUserList.length > 0) {
        onScrollChange(true);
      }
    }
  };
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
      ref.current.getScrollElement().addEventListener("scroll", handleScroll); // Remove parentheses here
    }
    return () => {
      if (ref.current) {
        ref.current.getScrollElement().removeEventListener("scroll", handleScroll); // Remove parentheses here
      }
    };
  }, [chatUserList]);

  useEffect(() => {
    if (directMessages?.length > 0) {
      setChatuserList(directMessages)
    }
  }, [directMessages])

  function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number) {
    let timeoutID: number | undefined;
  
    const debounced = (...args: Args) => {
      clearTimeout(timeoutID);
      timeoutID = window.setTimeout(() => fn(...args), delay);
    };
  
    return debounced;
  }


  const searchUsers = () => {
    var li, a, i, txtValue: any;
    const inputValue: any = document.getElementById("searchInModal");
    setSearchInputValue(inputValue)
    if(clientId !== "all_numbers"){
      dispatch(getDirectMessages(clientId, 0, 50, "All", 0, inputValue.value))
    }else{
      dispatch(getDirectMessages("all_numbers", 0, 50, "All", 0, inputValue.value))
    }
  };
  const debouncedSearch = debounce(searchUsers, 700);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      className="forwardModal"
      tabIndex={-1}
    >
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        Forward message to
      </ModalHeader>
      <ModalBody className="py-2 px-3 shareModalList">
      {validationMessage && <p className="text-danger">{validationMessage}</p>}
        <Input
          onKeyUp={debouncedSearch}
          id="searchInModal"
          type="text"
          className="form-control bg-light border-0 pe-0"
          placeholder="Search here.."
          autoFocus
        />
        <div className="input-group-prepend">
          <AppSimpleBar className={`chat-room-list chatRoomListMain forwordMsgSrollBar`} scrollableNodeProps={ref} scrollRef={ref}>

            <List
              id="contact_group_list"
              className="contact_group_list"
              dataSource={options.filter((item: ListItem) =>
                item?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item?.label?.toLowerCase().includes(searchQuery.toLowerCase())
              )}
              renderItem={(item: any) => (
                <List.Item>
                  <AntdCheckbox
                    onChange={() => handleCheckboxChange(item)}
                    checked={checkedItems.includes(item?.chatId)}
                  />
                  <List.Item.Meta
                    avatar={<Avatar src={item?.profilePic ? item?.profilePic : avatar1} />}
                    title={
                      <div className="create_group_lable">
                        {item?.firstName ? item.firstName : item?.label}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </AppSimpleBar>
        </div>
        <>
          <div className="d-flex align-items-center px-0 justify-content-end">
            <div className="flex-shrink-0">
              <Button
                size="sm"
                color="primary"
                type="button"
                className="px-3"
                onClick={onSend1}
                disabled={checkedItems?.length <= 0 && true}
              >
                Send {loading && <Spinner size="sm">
                                    Loading...
                                  </Spinner>}
              </Button>
            </div>
          </div>
          <AppSimpleBar style={{ maxHeight: "150px" }} className="mx-n4 px-1">
            {(contacts || []).map(
              (letterContacts: DivideByKeyResultTypes, key: number) => (
                <CharacterItem
                  letterContacts={letterContacts}
                  key={key}
                  index={key}
                  totalContacts={totalContacts}
                  selectedContacts={selectedContacts}
                  onSelectContact={onSelectContact}
                  onSend={onSend}
                />
              )
            )}
          </AppSimpleBar>
        </>

      </ModalBody>
    </Modal>
  );
};

export default ForwardModal;
