import styled from 'styled-components';
import bgImage from '../../assets/images/bg-pattern/pattern-05.png'
import subscriptiondetailwhite from '../../assets/images//icons/subscriptiondetailwhite.svg'

const PlanBillingComponent = styled.div`
  width: 100%;
  .planBilling{
    background: url(${bgImage});
    width: 100%;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9f9f9f;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-track {
        background: #e8e8e8;
    }
    .creditCardSection {
      .credit-card-box {
        width: 100%;
        max-width: 420px !important;
        height: 250px;
        position: relative;
        .front {
          width: 100%;
          max-width: 420px !important;
          height: 240px;
          border-radius: 15px;
          backface-visibility: hidden;
          background: linear-gradient(135deg, #bd6772, #53223f);
          position: absolute;
          color: #fff;
          font-family: Inconsolata;
          text-shadow: 0 1px 1px hsla(0, 0, 0, 0.3);
          box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url('http://cdn.flaticon.com/svg/44/44386.svg') no-repeat center;
            background-size: cover;
            opacity: .05;
          }
        }

        .chip {
          position: absolute;
          width: 60px;
          height: 45px;
          top: 20px;
          left: 20px;
          background: linear-gradient(135deg, hsl(269, 54%, 87%) 0%, hsl(200, 64%, 89%) 44%, hsl(18, 55%, 94%) 100%);
          ;
          border-radius: 8px;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: 4px solid hsla(0, 0, 50, .1);
            width: 80%;
            height: 70%;
            border-radius: 5px;
          }
        }

        .number {
          position: absolute;
          margin: 0 auto;
          top: 100px;
          left: 15px;
          font-size: 26px;
        }

        label {
          font-size: 10px;
          letter-spacing: 1px;
          text-shadow: none;
          text-transform: uppercase;
          font-weight: normal;
          opacity: 0.5;
          display: block;
          margin-bottom: 3px;
        }

        .card-holder,
        .card-expiration-date {
          position: absolute;
          margin: 0 auto;
          top: 170px;
          left: 19px;
          font-size: 18px;
          text-transform: capitalize;
        }

        .card-expiration-date {
          text-align: right;
          left: auto;
          right: 20px;
        }

        .logo {
          position: absolute;
          top: 9px;
          right: 20px;
          width: 60px;

          svg {
            width: 100%;
            height: auto;
            fill: #fff;
          }
        }

        .front {
          z-index: 2;
          transform: rotateY(0deg);
        }
      }
    }
    .ant-affix{
        z-index: 5px !important;
    }
    .card{
        border: 1px solid #e8e8e8;
        border-radius: 10px !important;
        .card-footer{
            border-bottom-right-radius:10px !important;
            border-bottom-left-radius: 10px !important;
        }
    }
    .creaditsCardBody{
      padding:1.5rem !important;
      @media (max-width:991.98px){
        padding:0.75rem !important;
      }
    }
    .topSecHead{
        padding: 15px 15px !important;
        border-bottom: 1px solid #e8e8e8;
        background-color: #fff;
        border-top-right-radius:10px !important;
        border-top-left-radius: 10px !important;
    }
    .messageCreditTitle {
      display: flex;
      align-items: center;
      gap: 5px;
      line-height: normal;
    }
    .planFstCardsTitles{
        padding: 6px 0px;
        margin-top: -16px !important;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        width: 88px;
        margin: 0 auto;
        background-color: #fff;
        color: #6b6b6b !important;
        font-size: 13px;
        line-height: normal !important;
    }
    .panelnumbername_switch {
        background-position: center;
        background-size:contain;
        background-color: #0029c7;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative !important;
        width: 32px !important;
        height: 32px !important;
        border-radius: 50% !important;
        color: white !important;
        background-repeat: no-repeat !important;
    }
    .icon_cir{
        border: 1px solid #ddd;
        padding: 4px;
        border-radius: 50px;
        box-shadow: 0px 2px 12px 0px #ddd !important;
    }
    .active_span {
        //  background-color: rgb(44, 206, 44);
        // background-color: #2cce2c;
        color: white;
        border-radius: 20px;
        font-size: 10px;
        padding: 3px 6px 2px;
        margin-left: 3px;
        font-weight: 500;
    }
    .panelnumbername_switch{
        font-size: 20px !important;
    }
    .plan_current_div i{
        cursor: pointer;
    }
    .subscriptiondetailsection {
        background-image: url(${subscriptiondetailwhite});
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
        margin-top: 3px;
    }
    .ctrlhPlanBillingTabs{
        border-bottom: unset !important;
        .nav-item{
            &:first-child .nav-link{
                border-top-right-radius: unset !important;
                border-bottom-right-radius: unset !important;
                border-right:unset !important;
            }
            &:last-child .nav-link{
                border-top-left-radius: unset !important;
                border-bottom-left-radius: unset !important;
                border-left:unset !important;
            }
            .nav-link{
                border-radius: 10px !important;
                background: rgb(255, 255, 255) !important;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px !important;
                height: 48px !important;
                font-weight: 500 !important;
                overflow: hidden !important;
                color: #000 !important;
            } 
            .nav-link.active{
                background-color: #0029c7 !important;
                color: white !important;
                overflow: hidden !important;
                border-color: #0029c7 !important;
            }
        }
    }
    .invoiceTabsMain{
        overflow-x: auto !important;
        .invoiceTabsBody{
            @media (max-width:991.98px) {
                width:850px !important;
            }
        }
    }
  }
  @media (max-width:480px) {
    .planBilling{
      .creditCardSection{
        .credit-card-box{
          height: 180px !important;
          .front{
            height: 180px !important;
          }
          .chip{
            width: 50px!important;
            height: 32px!important;
            top: 20px!important;
            left: 15px!important;
          }
          .logo {
            right: 20px!important;
            width: 50px!important;
          }
          .number {
            top: 67px!important;
            left: 15px!important;
            font-size: 20px!important;
          }
          .card-holder,.card-expiration-date{
            top: 118px!important;
            left: 15px!important;
            font-size: 15px!important;
          }
        }
      }
    }
  }
  .sidebarMenuAnchor{
    top: 55px !important;
    position: fixed;
    width: 15%;
    background-color: #EBEFFF;
    height: 101vh;
    max-height: 101vh !important;
    border-right: 1px solid #e3e3e3;
    @media (max-width:991.98px){
      display: none !important;
    }
    .ant-anchor::before{
        content: unset !important;
        position: unset !important;
    }
    .ant-anchor-ink{
        display: none !important;
    }
    .ant-anchor-link-active{
        background-color: #D6DEFF;
    }
    .ant-anchor-link
    {
        &:hover{
            background-color: #0029c7 !important;
            .moduleSidebarSpan,.moduleSidebarSpan i{
                color: #fff !important;
            }
        }
        .ant-anchor-link-title {
            padding: 14px 16px !important;
            .moduleSidebarSpan {
                color: rgb(70, 70, 70);
                font-weight: 500;
                display: flex;
                align-items: center;
                font-size: 13px !important;
                i{
                    font-size: 20px !important;
                    margin-right: 5px !important;
                }
            }
        }
    }
  }
  #sectionWrapper{
    width: 83.5% !important;
    margin-left: auto;
    @media (max-width:991.98px){
      width: 96% !important;
      margin:0 auto !important;
    }
  }
  @media (max-width:991.98px) {
    .creditCardSectionRow{
        flex-direction: column;
        gap: 10px;
    }
  }
  @media (max-width:480px) {
    .mobflexcolumn,.planDetailsHead{
        flex-direction: column;
    }
    .planDetailsHead{
        align-items: flex-start !important;
        gap: 10px;
    }
    .userInvoiceCount{
        margin-bottom: 16px !important;
    }
  }
  .invoiceTablePagination{
    float: right;
    margin-top: 16px;
  }
  .planBillingBackMain{
    @media (max-width:991.98px){
      display: flex !important;
    }
    .pageBackBtn {
      border-radius: 5px!important;
      background: #EFEFEF!important;
      border-color: #333!important;
      color: #333!important;
    }
  }
  .usersPlanText{
    font-size:50px;
  }
  .cancleAcButton{
    color:#fff !important;
    background-color:#D9534F !important;
    border-color: #D9534F !important;
    padding-left: 26px !important;
    padding-right: 26px !important;
    border-radius: 6px!important;
  }
  .creditCardSection.card, #billingContacts .card, #billingAddress .card {
    margin-bottom: 0px;
  }
`;
export { PlanBillingComponent };