// types
import { UpdateUserProfileActionTypes, ProfileState } from "./types";

export const INIT_STATE: ProfileState = {
  profileDetails: {},
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UpdateUserProfileActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE:
          return {
            ...state,
            successMsg:action.payload.data.data,
            mailSent:action.payload.data.success,
            userDetails: action.payload.data,
            fullName:action.payload.data.fullName,
            companyName:action.payload.data.companyName,
            phoneNumber:action.payload.data.phoneNumber,
            isProfileUpdated: true,
            getProfileLoading: false,
          };
        default:
          return { ...state };
      }

    case UpdateUserProfileActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
          };

        default:
          return { ...state };
      }

    case UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
      };
    }

    default:
      return { ...state };
  }
};

export default Profile;
