import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface ProgressBarProps {
    value: number;
    label?: string | any;
    className?: string | any |null;
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
    striped?: boolean;
    animated?: boolean;
    visuallyHidden?: boolean;
}

const ProgressBarComponent: React.FC<ProgressBarProps> = ({
    value,
    label,
    className,
    variant,
    striped,
    animated,
    visuallyHidden,
}) => {
    return (
        <div className="progressBar-container">
            <div className="status">
                <ProgressBar 
                    className={className}
                    now={value}
                    visuallyHidden={visuallyHidden}
                    label={label}
                    variant={variant}
                    striped={striped}
                    animated={animated}
                />
            </div>
        </div>
    );
};

export default ProgressBarComponent;
