import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { PlanBillingActionTypes } from "./types";
import { planBillingApiResponseError, planBillingApiResponseSuccess } from "./actions";

import { 
    getCustomerPortal as getCustomerPortalApi,
    getInvoices as getInvoicesApi,
    invoiceDownload as invoiceDownloadApi,
    getPlans as getPlansApi,
    changeSubscription as changeSubscriptionApi,
    chargebeeProcess as chargebeeProcessApi,
    addCredit as addCreditApi,
    checkEstimateCost as checkEstimateCostApi,
    getInvoicePaymentUrl as getInvoicePaymentUrlApi,
    cancelAccount as cancelAccountApi
 } from "../../api/index";
import {
    showSuccessNotification,
    showErrorNotification,
} from "../../helpers/notifications";

function* getCustomerPortal({ payload :{user},callback}: any) {
    try {
        const response: Promise<any> = yield call(getCustomerPortalApi, user);
        if(typeof callback === "function"){
            callback(response)
        }
        yield put(planBillingApiResponseSuccess(
                PlanBillingActionTypes.GET_CUSTOMER_PORTAL,
                response
            )
        );
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.GET_CUSTOMER_PORTAL, error)
        );
    }
}

function* getInvoices({ payload :{status}}: any) {
    try {
        const response: Promise<any> = yield call(getInvoicesApi, status);
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.GET_INVOICES,response));
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.GET_INVOICES, error)
        );
    }
}

function* cancelAccount({payload :{event, title, inputText, checkBoxValue },callback}:any, ) {
    try {
      const response: Promise<any> = yield call(cancelAccountApi,{event, title, inputText, checkBoxValue });
      if (typeof callback === 'function') {
          callback(response);
        }
        yield put(
            planBillingApiResponseSuccess(PlanBillingActionTypes.CANCEL_ACCOUNT,response)
        );
        yield call(showSuccessNotification, response);
    } catch (error: any) {
      yield put(
        planBillingApiResponseError(PlanBillingActionTypes.CANCEL_ACCOUNT, error)
      );
    }
  }

function* invoiceDownload({ payload :{id}}: any) {
    try {
        const response: Promise<any> = yield call(invoiceDownloadApi, id);
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.INVOICE_DOWNLOAD,response));
        yield call(showSuccessNotification, response);
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.INVOICE_DOWNLOAD, error)
        );
    }
}

function* getPlans() {
    try {
        const response: Promise<any> = yield call(getPlansApi);
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.GET_PLANS,response));
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.GET_PLANS, error)
        );
    }
}

function* changeSubscription ({payload :{data, parentId },callback}:any){
    try {
        const response: Promise<any> = yield call(changeSubscriptionApi,data,parentId);
        if (typeof callback === 'function') {
            callback(response);
          }
        // yield call(showSuccessNotification, response);
        yield put(
            planBillingApiResponseSuccess(PlanBillingActionTypes.CHANGE_SUBSCRIPTION, response)
        );
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.CHANGE_SUBSCRIPTION, error)
        );
    }
}
function* chargebeeProcess ({payload :{data, parentId },callback}:any){
    try {
        const response: Promise<any> = yield call(chargebeeProcessApi,data,parentId);
        if (typeof callback === 'function') {
            callback(response);
        }
        yield call(showSuccessNotification, response);
        yield put(
            planBillingApiResponseSuccess(PlanBillingActionTypes.CHARGEBEE_PROCESS, response)
        );
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.CHARGEBEE_PROCESS, error)
        );
    }
}
function* addCredit ({payload :{data },callback}:any){
    try {
        const response : Promise<any> = yield call(addCreditApi,data);
        // yield call(showSuccessNotification, response);
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.ADD_CREDIT, response));
        if (typeof callback === 'function') {
            callback(response);
        }
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.ADD_CREDIT, error)
        );
    }
}
function* checkEstimateCost ({payload :{data ,id},callback}:any){
    try {
        const response : Promise<any> = yield call(checkEstimateCostApi,data,id);
        // yield call(showSuccessNotification, response);
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.CHECK_ESTIMATE_COST, response));
        if (typeof callback === 'function') {
            callback(response);
        }
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.CHECK_ESTIMATE_COST, error)
        );
    }
}
function* getInvoicePaymentUrl ({payload,callback}:any){
    try {
        const response : Promise<any> = yield call(getInvoicePaymentUrlApi,payload);
        if (typeof callback === 'function') {
            callback(response);
        }
        yield put(planBillingApiResponseSuccess(PlanBillingActionTypes.GET_INVOICE_PAYMENT_URL, response));
    } catch (error: any) {
        yield put(
            planBillingApiResponseError(PlanBillingActionTypes.GET_INVOICE_PAYMENT_URL, error)
        );
    }
}
export function* watchGetCustomerPortal() {
    yield takeEvery(PlanBillingActionTypes.GET_CUSTOMER_PORTAL, getCustomerPortal);
}

export function* watchGetInvoces() {
    yield takeEvery(PlanBillingActionTypes.GET_INVOICES, getInvoices);
}
export function* watchCancelAccount() {
    yield takeEvery(PlanBillingActionTypes.CANCEL_ACCOUNT, cancelAccount);
}

export function* watchInvoiceDownload() {
    yield takeEvery(PlanBillingActionTypes.INVOICE_DOWNLOAD, invoiceDownload);
}

export function* watchGetPlans() {
    yield takeEvery(PlanBillingActionTypes.GET_PLANS, getPlans);
}
export function* watchChangeSubscription() {
    yield takeEvery(PlanBillingActionTypes.CHANGE_SUBSCRIPTION, changeSubscription);
}
export function* watchChargebeeProcess() {
    yield takeEvery(PlanBillingActionTypes.CHARGEBEE_PROCESS, chargebeeProcess);
}
export function* watchAddCredit() {
    yield takeEvery(PlanBillingActionTypes.ADD_CREDIT, addCredit);
}
export function* watchCheckEstimateCost() {
    yield takeEvery(PlanBillingActionTypes.CHECK_ESTIMATE_COST, checkEstimateCost);
}
export function* watchGetInvoicePaymentUrl() {
    yield takeEvery(PlanBillingActionTypes.GET_INVOICE_PAYMENT_URL, getInvoicePaymentUrl);
}
function* planBillingSaga() {
    yield all([
        fork(watchGetCustomerPortal),
        fork(watchGetInvoces),
        fork(watchCancelAccount),
        fork(watchInvoiceDownload),
        fork(watchGetPlans),
        fork(watchChangeSubscription),
        fork(watchChargebeeProcess),
        fork(watchAddCredit),
        fork(watchCheckEstimateCost),
        fork(watchGetInvoicePaymentUrl)
    ]);
}

export default planBillingSaga;
