import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { TemplateCreateActionTypes } from "./types";
import { postTemplateCreateApiResponse, postTemplateCreateApiResponseError } from "./actions";

import {
  templateCreate as postTemplateCreateApi,
  getTemplateList as getTemplateListApi,
  getTemplateDetails as getTemplateDetailsApi,
  templateUpdate as postTemplateUpdateApi,
  templateDelete as postTemplateDeleteApi
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* templateCreate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(postTemplateCreateApi, data);
    yield put(postTemplateCreateApiResponse(TemplateCreateActionTypes.POST_TEMPLATE_CREATE,response));
    yield call(showSuccessNotification, {message : "Template created successfully"});

  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(postTemplateCreateApiResponseError(TemplateCreateActionTypes.POST_TEMPLATE_CREATE, error));
  }
}

function* templateUpdate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(postTemplateUpdateApi, data);
    yield put(
      postTemplateCreateApiResponse(
        TemplateCreateActionTypes.POST_TEMPLATE_UPDATE,
        response
      )
    );
    yield call(showSuccessNotification, {message : "Template updated successfully"});
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      postTemplateCreateApiResponseError(TemplateCreateActionTypes.POST_TEMPLATE_UPDATE, error)
    );
  }
}

function* templateDelete({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(postTemplateDeleteApi, data);
    yield put(
      postTemplateCreateApiResponse(
        TemplateCreateActionTypes.POST_TEMPLATE_DELETE,
        response
      )
    );
    yield call(showSuccessNotification, {message : "Template deleted successfully"});
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      postTemplateCreateApiResponseError(TemplateCreateActionTypes.POST_TEMPLATE_DELETE, error)
    );
  }
}

function* getTemplateList({payload:{data}}:any) {
  try {
    const response: Promise<any> = yield call(getTemplateListApi,data);
    yield put(
      postTemplateCreateApiResponse(TemplateCreateActionTypes.GET_TEMPLATE_LIST, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      postTemplateCreateApiResponseError(TemplateCreateActionTypes.GET_TEMPLATE_LIST, error)
    );
  }
}

function* getTemplateDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getTemplateDetailsApi, id);
    yield put(
      postTemplateCreateApiResponse(TemplateCreateActionTypes.GET_TEMPLATE_DETAILS, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      postTemplateCreateApiResponseError(TemplateCreateActionTypes.GET_TEMPLATE_DETAILS, error)
    );
  }
}

export function* watchAddUserList() {
  yield takeEvery(TemplateCreateActionTypes.POST_TEMPLATE_CREATE, templateCreate);
}
export function* watchTemplateUpdate() {
  yield takeEvery(TemplateCreateActionTypes.POST_TEMPLATE_UPDATE, templateUpdate);
}
export function* watchTemplateDelete() {
  yield takeEvery(TemplateCreateActionTypes.POST_TEMPLATE_DELETE, templateDelete);
}
export function* watchGetTemplateList() {
  yield takeEvery(TemplateCreateActionTypes.GET_TEMPLATE_LIST, getTemplateList);
}
export function* watchGetTemplateDetails() {
  yield takeEvery(TemplateCreateActionTypes.GET_TEMPLATE_DETAILS, getTemplateDetails);
}

function* getAddListSaga() {
  yield all([
    fork(watchAddUserList),
    fork(watchGetTemplateList),
    fork(watchGetTemplateDetails),
    fork(watchTemplateUpdate),
    fork(watchTemplateDelete),
  ]);
}
export default getAddListSaga

