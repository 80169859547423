import axios from "axios";
import config from "../config";

interface AuthData {
  email: string;
  authToken: string;
}
const storedData = localStorage.getItem("authData");
const authData: AuthData | null = storedData ? JSON.parse(storedData) : null;
const authToken = authData?.authToken ?? '';
// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Authorization'] = authToken;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 400:
        message = error.message || error;
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject({message,status:error?.response?.status,data:error?.response?.data});
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return axios.delete(url, { ...config });
  };

  /*
  file upload update method
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);
  };

  /*
  file upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      // formData.append(k, data[k]);
      if(k !== "attachments" && k !== "image" && k !== "audios" && k !== "fileData"){
        formData.append(k, data[k]);
      }else{
        if(k == "attachments" && data[k]){
          let attachments = data[k]
          attachments.forEach((element:any) => {
            formData.append(k, element);
          });
        }else if(k == "image" && data[k]){
          let images = data[k]
          images.forEach((element:any) => {
            formData.append(k, element);
          });
        }else if(k == "fileData" && data[k]){
          let fileDatas = [data.fileData]
          fileDatas.forEach((element:any) => {
            formData.append(k, element.originFileObj);
          });
        }else if(k == "audios" && data[k]){
          let audios = data[k]
          audios.forEach((element:any) => {
            formData.append(k, element);
          });
        }
      }
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };
}

const getLoggedinUser = () => {
  const user :any = localStorage.getItem("authData");
  if(user?.authToken){ 
    const setToken = setAuthorization(JSON.parse(user)?.authToken) 
  }
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
