import { call, put, takeEvery, all,fork } from "redux-saga/effects";

// Login Redux States
import { QrCodeActionTypes } from "./types";
import { qrCodeApiResponseSuccess, qrCodeApiResponseError} from "./actions";

import {
  getQrCodeData as getQrCodeDataApi,
  connectWhatsAppBusiness as connectWhatsAppBusinessApi,
  numberDelete as deleteNumberApi,
  channelCreate as channelCreateApi,
  getMessengerPageList as getMessengerPageListApi,
  updateClientDeatils as updateClientDeatilsApi,
  connectTelegram as connectTelegramApi,
  connectMessenger as connectMessengerApi,
  connectInstagram as connectInstagramApi,
  connectChatbot as connectChatbotApi,
  getOldClientId as getOldClientIdApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getQrCode({ payload: id, privacyValue }: any): Generator<any, void, any> {
  try {
    const response = yield call(getQrCodeDataApi, id, privacyValue);
    if (!response.success)  yield call(showErrorNotification, response); 
    yield put(
      qrCodeApiResponseSuccess(QrCodeActionTypes.GET_QR_CODE, response)
    );
  } catch (error: any) {
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.GET_QR_CODE, error)
    );
  }
}
function* connectWhatsAppBusiness({ payload: id }: any): Generator<any, void, any>{
  try {
    const response = yield call(connectWhatsAppBusinessApi,id);
    if(!response?.success){
      yield call(showErrorNotification, response);
    }else{
      yield call(showSuccessNotification, response);
    }
    yield put(
      qrCodeApiResponseSuccess(QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS, response)
    );
  } catch (error:any) {
    yield call(showErrorNotification, "Something went wrong.");
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS, error)
    );
  }
}
function* numberDelete({payload}:any) {
  try {
    const response: Promise<any> = yield call(deleteNumberApi,payload.data,payload.option);
    yield put(
      qrCodeApiResponseSuccess(
        QrCodeActionTypes.DELETE_NUMBER,
        response
      )
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.DELETE_NUMBER, error)
    );
  }
}

function* channelCreate({payload}:any) {
  try {
    const response: Promise<any> = yield call(channelCreateApi,payload);
    yield put(
      qrCodeApiResponseSuccess(
        QrCodeActionTypes.CHANNEL_CREATE,
        response
      )
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.CHANNEL_CREATE, error)
    );
  }
}

function* getMessengerPageList({payload, callback}:any) {
  try {
    const response: Promise<any> = yield call(getMessengerPageListApi,payload);
    if (typeof callback === 'function') {
      callback(response);
    }
  } catch (error: any) {
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.GET_MESSENGER_PAGE_LIST, error)
    );
  }
}
function* updateClientDeatils({payload, callback}:any) {
  try {
    const response: Promise<any> = yield call(updateClientDeatilsApi,payload);
    if (typeof callback === 'function') {
      callback(response);
    }
  } catch (error: any) {
    yield put(
      qrCodeApiResponseError(QrCodeActionTypes.UPDATE_CLIENT_DETILS, error)
    );
  }
}

function* connectMessenger({ payload: { data }, callback }: any): Generator<any, void, any> {
  try {
    const response = yield call(connectMessengerApi, data);
    if (!response.success)  yield call(showErrorNotification, response);
    if (typeof callback === 'function') {
      callback(response);
    }
  } catch (error: any) {
    yield put(qrCodeApiResponseError(QrCodeActionTypes.MESSENGER_CONNECT, error));
  }
}
function* connectInstagram({ payload: { data }, callback }: any): Generator<any, void, any> {
  try {
    const response = yield call(connectInstagramApi, data);
    if (!response.success)  yield call(showErrorNotification, response);
    if (typeof callback === 'function') {
      callback(response);
    }

  } catch (error: any) {
    yield put(qrCodeApiResponseError(QrCodeActionTypes.INSTRGRAM_CONNECT, error));
  }
}
function* connectChatbot({ payload: { data }, callback }: any): Generator<any, void, any> {
  try {
    const response = yield call(connectChatbotApi, data);
    if (!response.success)  yield call(showErrorNotification, response);
    if (typeof callback === 'function') {
      callback(response);
    }

  } catch (error: any) {
    yield put(qrCodeApiResponseError(QrCodeActionTypes.CHATBOT_CONNECT, error));
  }
}

function* getOldClientId({payload,callback }: any): Generator<any, void, any> {
  try {
    const response = yield call(getOldClientIdApi, payload?.payloadData);
    if (!response.success)  yield call(showErrorNotification, response);
    if (typeof callback === 'function') {
      callback(response);
    }
  } catch (error: any) {
    yield put(qrCodeApiResponseError(QrCodeActionTypes.GET_OLD_CLIENT_ID, error));
  }
}

function* connectTelegram({ payload: { data }, callback }: any): Generator<any, void, any> {
  try {
    const response = yield call(connectTelegramApi, data);
    if (!response.success)  yield call(showErrorNotification, response);
    if (typeof callback === 'function') {
      callback(response);
    }
    // yield call(showSuccessNotification, response);
    // yield put(qrCodeApiResponseSuccess(QrCodeActionTypes.TELEGRAM_CONNECT,response));

  } catch (error: any) {
    yield put(qrCodeApiResponseError(QrCodeActionTypes.TELEGRAM_CONNECT, error));
  }
}


export function* watchGetQrCodeList() {
  yield takeEvery(QrCodeActionTypes.GET_QR_CODE, getQrCode);
}
export function* watchConnecWhatsAppBusiness() {
  yield takeEvery(QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS, connectWhatsAppBusiness);
}
export function* watchDeleteUserList() {
  yield takeEvery(QrCodeActionTypes.DELETE_NUMBER, numberDelete);
}
export function* watchCreateChannel() {
  yield takeEvery(QrCodeActionTypes.CHANNEL_CREATE, channelCreate);
}
export function* watchGetMessengerPageList() {
  yield takeEvery(QrCodeActionTypes.GET_MESSENGER_PAGE_LIST, getMessengerPageList);
}
export function* watchUpdateClientDeatils() {
  yield takeEvery(QrCodeActionTypes.UPDATE_CLIENT_DETILS, updateClientDeatils);
}
export function* watchConnectTelegram() {
  yield takeEvery(QrCodeActionTypes.TELEGRAM_CONNECT, connectTelegram);
}
export function* watchConnectMessenger() {
  yield takeEvery(QrCodeActionTypes.MESSENGER_CONNECT, connectMessenger);
}
export function* watchConnectInstagram() {
  yield takeEvery(QrCodeActionTypes.INSTRGRAM_CONNECT, connectInstagram);
}
export function* watchConnectChatbot() {
  yield takeEvery(QrCodeActionTypes.CHATBOT_CONNECT, connectChatbot);
}
export function* watchGetOldClientId() {
  yield takeEvery(QrCodeActionTypes.GET_OLD_CLIENT_ID, getOldClientId);
}
function* qrCodeSaga() {
  yield all([
    fork(watchGetQrCodeList),
    fork(watchConnecWhatsAppBusiness),
    fork(watchDeleteUserList),
    fork(watchCreateChannel),
    fork(watchGetMessengerPageList),
    fork(watchUpdateClientDeatils),
    fork(watchConnectTelegram),
    fork(watchConnectMessenger),
    fork(watchConnectInstagram),
    fork(watchConnectChatbot),
    fork(watchGetOldClientId),
  ]);
}

export default qrCodeSaga;
