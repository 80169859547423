import styled from 'styled-components';
import ArrowDown from '../../../../assets/images/icons/arrow_down.svg';
import BxSmileIcon from '../../../../assets/images/icons/bx-smile-icon.svg';
import BxPaperClipIcon from '../../../../assets/images/icons/bx-paperclip-icon.svg';
import BxMicrophoneIcon from '../../../../assets/images/icons/bxs-microphone-icon.svg';
import SlashForwardIcon from '../../../../assets/images/icons/slash-forward-icon.svg';

const ChatInputSectionWrapper = styled.div`
  .chat-input-section{
    background-color:white !important;
    // border-bottom: 3.5px solid #5969a8;
    .selected-media{
        @media (max-width:500px) {
            left: 30% !important;
        } 
    }
    .chatSendInputMain{
        position: relative;
        @media (max-width:500px) {
            flex-direction: column;
        }
        .chatInputLinksMain {
            gap:8px;
            margin-left:-3px;
        }
        .scanNbListInput{
            position: absolute;
            right: 0px;
            top: 0px;
        }
        .ant-tabs-top >.ant-tabs-nav::before{
          border:none !important;
        }
        .ant-tabs{
            .ant-tabs-tab{
                padding:5px 0px !important;
                .ant-tabs-tab-btn{
                    color:#000 !important;
                    font-size:13px !important;
                    line-height:1.3;
                }
            }
            .ant-tabs-tab.ant-tabs-tab-active {
                .ant-tabs-tab-btn{
                    color:#0029c7 !important;
                }
            }
        }
        .ant-tabs-ink-bar{
            background-color:#0029c7 !important;
        }
        .ant-tabs-nav{
            margin-bottom:7px !important;
        }
        .ant-tabs{
            margin-bottom:0px !important;
        }
        .chatInputInner{
            position: relative;
            .chatInputInnerButton{
              position: absolute;
              left: 0px;
              right: 0;
              bottom: 20px;
              z-index: 1000;
              background-color: transparent;
              padding: 0px !important;
              width: 98% !important;
              margin: 0 auto !important;
            }
            .chatInputInnerButtondiv {
              padding-left: 2px;
            }
            
            .mainSendMsgTextArea{
                margin-bottom:30px !important;
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                padding-left:0px;
                padding-top:5px !important;
                resize: none;   
                // border-color:#c6c7ca !important;
                border:none;
                &:focus{
                    border-color:#c6c7ca !important;
                }
            }
        }
    }
    .textbox_container{
        flex: 1;
        &:has(.voiceRecorderPopover){
            .mainSendMsgTextArea{
                min-height: 150px !important;
                max-height: 150px !important;
            }
        }
        .voiceRecorderPopover{
            bottom: 2%;
            left: 1%;
            height: 58px;
            .popover{
                transform:unset !important;
                .popover-body{
                    padding: 5px 5px 0px !important;
                }
            }
            @media (min-width:1079px) {
                &:hover{
                    .coloseBtnDivCtntr{
                        display: flex !important;
                    }
                }
                .coloseBtnDivCtntr{
                    display: none !important;
                }
            }
        }
    }
    .chatInputLinksMain{
        position: relative;
        .voiceRecorderPopover{
            top: -105px;
            left: 0;
            @media (max-width:500px) {
                left: -44% !important;
            }
        }
        .bx-smile-icon, .bx-paperclip-icon, .bxs-microphone-icon {
          width: 18px;
          height: 18px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .bx-smile-icon {
          background-image: url(${BxSmileIcon}) !important;
        }
        .bx-paperclip-icon {
          background-image: url(${BxPaperClipIcon}) !important;
        }
        .bxs-microphone-icon {
          background-image: url(${BxMicrophoneIcon}) !important;
        }
        .slash-forward-icon {
          background-image: url(${SlashForwardIcon}) !important;
        }
        .linksListItemNew button:hover span {
          filter: invert(52%) sepia(6%) saturate(824%) hue-rotate(193deg) brightness(92%) contrast(92%);
        }
    }
}
.msgSendUserDropdownMain{
    @media (min-width:1080px){
      max-width: 265px !important;
    }
    @media (min-width:600px) AND (max-width:1079px){
      max-width: 250px !important;
    }
    @media (max-width:500px){   
      max-width: 60% !important;
    }
    .userNewDropdown{
      &.show{
        .dropdown-toggle{
          &::after{
            transform: rotate(180deg) !important;
          }
        }
      }
      display: flex;
      justify-content:end;
      .wpLinkedUserName{
        @media (min-width:992px) {
          max-width: 80px;
          font-size: 10px;
          color: #1C1D22;
        }
        @media (max-width:991.98px) {
          max-width:300px;
        }
        @media (max-width:480px) {
          max-width:136px !important;
        }
        @media (max-width:320px) {
          max-width:96px !important;
        }
      }
    
      .dropdown-item .dropItemUserName{
        @media (min-width:992px) {
          max-width:196px !important;
        }
        @media (max-width:991.98px) {
          max-width:300px;
        }
        @media (max-width:480px) {
          max-width:196px !important;
        }
        @media (max-width:320px) {
          max-width:33px !important;
        }
      }
      .dropdown-toggle::after{
        position: absolute !important;
        content: "";
        top: 7px;
        right: 2px;
        background-image: url(${ArrowDown});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center !important;
        display: block;
        height: 18px !important;
        width: 18px !important;
      }
      .dropdown-toggle,
      .dropdown-toggle:hover,
      .dropdown-toggle:active,
      .dropdown-toggle:focus,{
        border-radius: 8px !important;
        // border: 1px solid #ddd;
        border:none;
      }
      .dropdown-toggle,
      .dropdown-toggle:hover,
      .dropdown-toggle:active,
      .dropdown-toggle:focus,
      .dropdown-toggle.show {
        position: relative;
        background-color: #F4F5FA !important;
        border-color: #fff !important;
        color: #495057 !important;
        font-size: 12px !important;
        padding: 4px 20px 4px 12px !important;
        text-align: left;
        height: 30px !important;
      }
      .dropdown-toggle.show {
        border-bottom-left-radius: unset !important;
        border-bottom-right-radius: unset !important;
      }
      .dropdown-menu{
        padding: 0 !important;
        border-radius: unset !important;
        max-height: 410px;
        overflow-y: auto;
        width: 260px !important;
        left: -5% !important;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
          border-radius: 14px;
        }
        &::-webkit-scrollbar-track {
          background: #e8e8e8;
      }
        .dropdown-item:hover{
          background-color: #EBEFFF;
        }
        .dropdown-item{ 
          font-size: 12px !important;
          text-align: left;
          padding-left: 10px !important;
          padding-right: 10px !important;
          color: #495057;
          position: relative;
          white-space: pre-wrap;
        }
      }
    }
  
`;
export { ChatInputSectionWrapper };