import { AccountSettingActionTypes } from "./types";

// common success
export const accountSettingApiResponseSuccess = (actionType: string, data: any) => ({
    type: AccountSettingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const accountSettingApiResponseError = (
    actionType: string,
    error: string
) => ({
    type: AccountSettingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});


export const labelSave = (payload: any) => {
    return {
        type: AccountSettingActionTypes.LABEL_SAVE,
        payload: { payload },
    };
};

export const updateAccountSetting = (payload: any, userId: string) => {
    return {
        type: AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING,
        payload: { payload }, userId,
    };
};



