import { ReportsActionTypes } from "./types";

// common success
export const reportsApiResponseSuccess = (actionType: string, data: any) => ({
    type: ReportsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const reportsApiResponseError = (
    actionType: string,
    error: string
) => ({
    type: ReportsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Get Reports
export const getReports = (data:any) => {
    return ({
        type: ReportsActionTypes.GET_REPORTS,
        payload:data
    })
};