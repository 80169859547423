import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { UpdateUserProfileActionTypes } from "./types";
import { updateUserProfileApiResponse, updateUserProfileApiResponseError } from "./actions";

import { updateUserProfile as getUserAddApi } from "../../api/index";
import { showSuccessNotification } from "../../helpers/notifications";

function* updateUserProfile({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getUserAddApi,data);
    yield put(
      updateUserProfileApiResponse(
        UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE,
        response
      )
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield put(
      updateUserProfileApiResponseError(UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE, error)
    );
  }
}

export function* watchAddUserList() {
  yield takeEvery(UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE, updateUserProfile);
}

function* getAddListSaga() {
  yield all([fork(watchAddUserList)]);
}

export default getAddListSaga;
