// types
import { CampaignActionTypes, CampaignState } from "./types";

export const INIT_STATE: CampaignState = {
  profileDetails: {},
  getCampaignDetails:[],
  campaignList:[],
  loading : false
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CampaignActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CampaignActionTypes.CAMPAIGN_CREATE:
          return {
            ...state,
            campaign_create:action.payload.data,
            loading : false
          };
        case CampaignActionTypes.CAMPAIGN_UPDATE:
          return {
            ...state,
            CampaignCreate:action.payload.data,
            loading: false        
          };
        case CampaignActionTypes.CAMPAIGN_DELETE:
          return {
            ...state,
            campaign_delete :action.payload.data,
            loading: false
          };
          case CampaignActionTypes.CAMPAIGN_LIST:
            return {
            ...state,
            campaignList : action.payload.data.data,
            loading: false
        
          };
        case CampaignActionTypes.CAMPAIGN_DETAILS:
          return {
            ...state,
            getCampaignDetails:action.payload.data.data,
            loading: false
          };
        default:
          return { ...state };
      }

    case CampaignActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CampaignActionTypes.CAMPAIGN_CREATE:
          return {
            ...state,
            loading: false
          };
        case CampaignActionTypes.CAMPAIGN_UPDATE:
          return {
            ...state,
            loading: false
          };
        case CampaignActionTypes.CAMPAIGN_DELETE:
          return {
            ...state,
            loading: false
          };
        case CampaignActionTypes.CAMPAIGN_LIST:
          return {
            ...state,
            loading: false
          };
        case CampaignActionTypes.CAMPAIGN_DETAILS:
          return {
            ...state,
            loading: false
          };

        default:
          return { ...state };
      }

    case CampaignActionTypes.CAMPAIGN_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    
    case CampaignActionTypes.CAMPAIGN_UPDATE: {
      return {
        ...state,
        loading: true
      };
    }

    case CampaignActionTypes.CAMPAIGN_DELETE: {
      return {
        ...state,
        loading: true
      };
    }

    case CampaignActionTypes.CAMPAIGN_LIST: {
      return {
        ...state,
        loading: true
      };
    }

    case CampaignActionTypes.CAMPAIGN_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }

    default:
      return { ...state };
  }
};

export default Profile;
