import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { Form, Input, message, Space, Checkbox, Row, Col, Select, Radio, Popover, Card,Upload,TimePicker,DatePicker  } from 'antd';
import { Dayjs } from 'dayjs';
import { Button } from "reactstrap";
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from "react-router-dom";
import { getCampaignLists, getTemplateList, templateUpdate, templateCreate, setClientId, getDirectMessages, campaignCreate, getCampaignDetails, campaignUpdate } from '../../redux/actions';
import { useRedux, useProfile } from "../../hooks";
import { UserAddFormWrapper } from '../UserAddFormWrapper.style';
import { addMixpanelEvent } from "../../helpers/mixpanel";
import { InfoCircleFilled } from '@ant-design/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import whatsappLogo from "../../assets/images/whatsapp_logo.png";
import whatsappBusinessLogo from "../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../assets/images/channelListIcon/telegram_icon.png";
import type { RadioChangeEvent } from 'antd';
import type { UploadProps } from 'antd';
import { Link } from 'react-router-dom';
import { stat } from 'fs';
import { responsiveArray } from 'antd/es/_util/responsiveObserver';
import { showErrorNotification, showSuccessNotification } from '../../helpers/notifications';
import { Days } from '../../data';
const { Option } = Select;

interface FormValues {
  clientId?: string;
  campaignId?: string;
  name: string;
  scheduleType?: string;
  whenSend?: string;
  messageType?: string;
  message?: string | any;
  templateId?: string;
  attachments?: [File ]| any;
  file?: File | any;
  period?: string;
  days?: string;
  time?: string | any;
  date?:string | any;
  fromNumber?: string;
  template?: any;
  clientDetails?: any;
  schedualDetails?: any;
}

interface CampaignProps {
  campaignId: any;
  editCampaignState?: any;
  viewCampaignState?: any;
}
const CreateCampaign = ({ viewCampaignState, editCampaignState, campaignId }: CampaignProps) => {
  const { userProfile } = useProfile()
  const navigate = useNavigate();  
  const [form] = useForm<FormValues>();

  const { dispatch, useAppSelector } = useRedux();
  const {
    campaignLists,
    userDetails,
    client_id,
    getTemplateDetails,
    templateList,
    load
  } = useAppSelector((state) => {
    return {
      userDetails: state.Login?.User,
      campaignLists: state.Chats?.campaignLists,
      client_id: state.Chats.clientId,
      load: state.Campaign.loading,
      campaign_create : state.Campaign.campaign_create,
      getTemplateDetails: state.Templates.getTemplateDetails,
      templateList: state.Templates.getTemplateList,
    };
  });
  const [clients, setClients] = useState<[any]>(campaignLists)
  const [templates, setTemplates] = useState<any>("")
  const [campaignName, setCampaignName] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [selectedClientIdValue, setClientIdValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  const [showDynamicInputs, setShowDynamicInputs] = useState([false, false, false]);
  const [whenSendValue, setWhenSendValue] = useState('now');
  const [campaignType, setCampaignType] = useState<any>("dynamic");
  const [selectedFile, setSelectedFile] = useState(null);
  const [pireodsValue, setPireodsValue] = useState("daily")
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState(load)
  const [selectedTemplate, setSelectedTemplate] = useState("")
  const timeZone = userDetails?.timezone ? userDetails?.timezone : userProfile?.timezone;
  // const defaultTimeValue = dayjs((moment(moment().tz(timeZone).add(30, 'minutes'),'HH:mm')).toDate()) 
  const defaultTimeValue = dayjs((moment(moment().tz(timeZone).add(10, 'minutes'),'HH:mm')).toDate()) 
  const defaultDateValue = dayjs((moment(moment(),'DD/MM/YYYY')).toDate()) 
  const initialValues: FormValues = {
    name: `Campaign-${moment().tz(timeZone).format("DD-MMM-YYYY")}  ${moment(moment()).tz(timeZone).format('HH:mm')}`,
    period: `daily`,
    date: defaultDateValue,
    time: defaultTimeValue,
    days : (moment().format('dddd')).toLowerCase()
  };
  useEffect(() => {
    setLoading(load)
  }, [load])
  
  useEffect(() => {
    if(templateList?.length){
      setTemplates(templateList)
      setClientIdValue(userProfile?.clientId)
    }
  }, [templateList])

  useEffect(() => {
    dispatch(getTemplateList());
    dispatch(getCampaignLists("campaign"))
    // setClients(campaignLists)
    // setTemplates(templateList)
  }, [])
  
  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaignDetails(campaignId,(respose : any)=>{
        if(respose.success){
          const campainDetails = respose.data 
          const data : FormValues = {
            clientId : campainDetails?.clientId,
            name: campainDetails?.name,
            whenSend: campainDetails?.scheduleType,
            messageType: campainDetails?.messageType,
            templateId: campainDetails?.templateId?._id,
          }
          if(campainDetails?.schedualDetails?.interval)data['period'] = campainDetails?.schedualDetails?.interval;
          // if(campainDetails?.schedualDetails?.startDate)data['date'] = moment(campainDetails?.schedualDetails?.startDate, 'DD/MM/YYYY').tz(timeZone);
          // if(campainDetails?.schedualDetails?.day)data['days'] = campainDetails?.schedualDetails?.day;
          const dateTimeStr = campainDetails?.schedualDetails?.startDate + ' ' + campainDetails?.schedualDetails?.startTime;
          const utcDateTime = moment.utc(dateTimeStr, 'DD/MM/YYYY HH:mm');
          const timeZoneDate = utcDateTime.tz(timeZone);
          // if(campainDetails?.schedualDetails?.day)data['days'] = moment(timeZoneDate.toString()).format('dddd');
          if(campainDetails?.schedualDetails?.day)data['days'] = campainDetails?.schedualDetails?.orignalDay;
          if(campainDetails?.schedualDetails?.startDate)data['date'] = moment(moment(timeZoneDate.toString()).format('DD/MM/YYYY'),"DD/MM/YYYY");
          // if(campainDetails?.schedualDetails?.startTime)data['time'] = dayjs(moment(moment.tz(moment.utc(campainDetails?.schedualDetails?.startTime,"HH:mm"),timeZone).format()).toDate());
          // if(campainDetails?.schedualDetails?.startTime)data['time'] = dayjs(moment.tz(campainDetails?.schedualDetails?.startTime,"HH:mm",timeZone).toDate());
          if(campainDetails?.schedualDetails?.startTime)data['time'] = dayjs(moment.utc(campainDetails?.schedualDetails?.startTime, 'HH:mm').tz(timeZone).toDate());
          if(campainDetails?.templateId)setSelectedTemplate(campainDetails?.templateId)
          if(campainDetails?.schedualDetails?.interval)setPireodsValue(campainDetails.schedualDetails.interval)
          if(campainDetails?.message) data['message'] = campainDetails.message;
          setCampaignName(campainDetails?.name)
          setCampaignType(campainDetails?.messageType)
          setWhenSendValue(campainDetails?.scheduleType)
          setClientIdValue(campainDetails?.clientId)
          form.setFieldsValue(data)
        }
      }))
    }
  }, [campaignId])

  useEffect(() => {
    if (getTemplateDetails?.length > 0 && campaignId) {
      const fetchedData = {
        categories: getTemplateDetails[0]?.tempCategory,
        name: getTemplateDetails[0]?.tempName,
        format: getTemplateDetails[0]?.tempFormat,
      };
      form.setFieldsValue(fetchedData);
    }
  }, [getTemplateDetails])

  const handleCampaignNameChange = (e: any) => {
    const lowercaseValue = e.target.value.toLowerCase();
    setCampaignName(lowercaseValue);
  };

  const navigateTo = () => {
    navigate("/campaign-list")
  }

  useEffect(() => {
    setClients(campaignLists)
    if (campaignLists?.length) {
      let listName = campaignLists?.findIndex((item: any) => (item.clientId === selectedClientIdValue && item.type === "business_whatsapp"))
      setSelectedNumber(campaignLists[listName]?.me?.user)
    }
  }, [campaignLists])
  const getFromNumber = async (number: string) => {
    if(number){
      number = await number.split("@")[0]
      return `+${number}`
    }
  }
  const onFinish = async (values: FormValues) => {
    if(!selectedClientIdValue){

    }
    const payLoad :FormValues = {
      name: campaignName ? campaignName : values.name,
      scheduleType :whenSendValue,
      clientId: selectedClientIdValue,
      messageType : campaignType
    }
    delete payLoad.file
    if(selectedFile)payLoad['attachments'] = [selectedFile];
    if(campaignId && editCampaignState) payLoad["campaignId"] = campaignId;
    if(payLoad.clientId){
      const clientIdIndex = await clients.findIndex((item:any) => item.clientId === payLoad.clientId)
      payLoad["clientDetails"] = clients[clientIdIndex]
      const number = clients[clientIdIndex]?.whatsappId ? clients[clientIdIndex].whatsappId : clients[clientIdIndex]?.me?._serialized ?? clients[clientIdIndex]?.me?._serialized
      payLoad["fromNumber"] = await getFromNumber(number)
    }
    if(campaignType === "dynamic" && values.templateId){
      payLoad["templateId"] = values.templateId
      const templateIndex = await templates.findIndex((item: any) => item._id === payLoad.templateId)
      payLoad["template"] = templates[templateIndex]
    }else if(campaignType === "static" && values.message){
      payLoad["message"] = values.message
    }
    if (whenSendValue === "intervals" || whenSendValue === "scheduled_date_time") {
      payLoad["schedualDetails"] = {
        enabled: false,
      }
      if (pireodsValue && whenSendValue === "intervals") payLoad["schedualDetails"]['interval'] = pireodsValue;
      if (pireodsValue === "weekly" && whenSendValue === "intervals") payLoad["schedualDetails"]["day"] = values.days
      // let dateMoment;
      // if (values?.date) {
      //   // payLoad["schedualDetails"]['startDate'] = moment((values.date)?.toString()).format("DD/MM/YYYY")
      //   // dateMoment = moment(values.date.toString());
      //   dateMoment = moment(values.date.toString());
      // } else {
      //   dateMoment = moment()
      //   // payLoad["schedualDetails"]['startDate'] = moment().format("DD/MM/YYYY")
      // }
      let dateMoment = values?.date ? new Date(values?.date).toString() : new Date().toString();
      const timeMoment = values?.time ? new Date(values?.time).toString() : new Date().toString();
      if(values?.time){
        let newdateMoment = new Date(dateMoment)
        let newtimeMoment = new Date(timeMoment)
        newdateMoment.setHours(newtimeMoment.getHours())
        newdateMoment.setMinutes(newtimeMoment.getMinutes())
        dateMoment = newdateMoment.toString()
      }
      // const timeMoment = values?.time ? new Date(values?.time).toString() : new Date().toString();
      // if (values?.time) payLoad["schedualDetails"]["startTime"] = moment((values.time)?.toString()).utc().format("HH:mm")
      // const timeMoment = values?.time ? moment(values.time.toString()) : moment();
      // const combinedDateTime = dateMoment.set({
      //   hour: timeMoment.hours(),
      //   minute: timeMoment.minutes(),
      //   second: timeMoment.seconds(),
      // });
      // payLoad["schedualDetails"]['startDate'] = combinedDateTime.format("DD/MM/YYYY")
      // payLoad["schedualDetails"]["startTime"] = combinedDateTime.format("HH:mm")
      payLoad["schedualDetails"]['startDate'] = dateMoment
      payLoad["schedualDetails"]["startTime"] = timeMoment
      // if (pireodsValue === "weekly" && whenSendValue === "intervals") {
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ values?.time.toString():", values?.time,"///////////",values?.time.toString())

      //   const utcDate = moment.utc(moment(values?.time));
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ utcDate: 1111", utcDate)
      //   const currentDate = moment(values?.time);
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ currentDate:2222", currentDate)

      //   // Parse the date strings into Moment objects
      //   const momentUtcDate = moment(utcDate).format("DD/MM/YYYY");
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ momentUtcDate: 3333", momentUtcDate)
      //   const momentCurrentDate = moment(currentDate).format("DD/MM/YYYY");
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ momentCurrentDate: 4444", momentCurrentDate)

      //   // Calculate the difference in days
      //   const dayDiff = moment( momentCurrentDate,"DD/MM/YYYY").diff(moment(momentUtcDate,"DD/MM/YYYY"), 'days');
      //   console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ dayDiff: 5555", dayDiff)

      //   if (dayDiff === 1) {
      //       console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ dayDiff: 6666", dayDiff)
      //       const dayIndex =  Days.findIndex((item:any)=>item.value === values.days)
      //       values.days = Days[dayIndex-1].value
      //     }
      //     console.log("🅰️ ~ file: createCampaign.tsx:243 ~ onFinish ~ values.days: 7777", values.days)
      //     payLoad["schedualDetails"]["day"] =  values.days
      //     // return false
      // }
    } else if (whenSendValue === "now") {
      // const currentDate = moment().utc()
      const currentDate = new Date()
      payLoad["schedualDetails"] = {
        enabled: false,
        startDate: currentDate,
        startTime: currentDate.setMinutes(currentDate.getMinutes() + 10),
        // startDate: currentDate.format("DD/MM/YYYY"),
        // startTime: currentDate.format("HH:mm"),
      }
    }
    payLoad["schedualDetails"] = JSON.stringify(payLoad.schedualDetails)
    payLoad["clientDetails"] = JSON.stringify(payLoad.clientDetails)
    payLoad["template"] = JSON.stringify(payLoad.template)
    if(!loading){
      if (campaignId && typeof (campaignId) === "string" && editCampaignState) {
        dispatch(campaignUpdate(payLoad,(response :any)=>{
          if(response.success){
            showSuccessNotification(response)
            navigateTo()
          }else{
            form.resetFields()
            showErrorNotification(response.error)
            setIsSubmitted(false)
          }
        }))
        setIsSubmitted(true)
      } else {
        dispatch(campaignCreate(payLoad,(response :any)=>{
          if(response.success){
            showSuccessNotification(response)
            navigateTo()
          }else {
            showErrorNotification(response.error)
            form.resetFields()
            setIsSubmitted(false)
          }
        }))
        setIsSubmitted(true)
      }
    }
  };
  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const content = (
    <div>
      <p>Utility: Helping with requests, updates on transactions etc.</p>
      <p>Marketing: Promotional or informative updates, as well as invitations for customers to take action.</p>
      <p>Authentication: Authenticate users using one-time passcodes, which may be required in a multi-step login </p>
    </div>
  )

  const getName = (campaignLists: any, selectedValue: string) => {
    let listName = campaignLists?.findIndex((item: any) => (item.clientId === selectedValue))
    if (campaignLists[listName]?.me?.user != undefined) {
      // setSelectedNumber(campaignLists[listName]?.me?.user)
    }
    if (listName !== -1) {
      return (
        <>
          <div className='d-flex align-items-center'>
            <span className="text-truncate wpLinkedUserName me-1">
              {campaignLists[listName]?.pushname}
            </span>
            <span className="text-muted wpLinkedUserNumber">
              ( +{campaignLists[listName]?.me?.user} )
            </span>
          </div>
        </>
      )
    }else if(listName === -1){
      return (
        <>
          <div className='d-flex align-items-center'>
            <span className="text-muted wpLinkedUserName me-1">
              Select WhatsApp Business API Number
            </span>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if(userProfile?.clientId !== "all_numbers"){
      let clientIndex = campaignLists?.findIndex((item: any) => (item.clientId === userProfile?.clientId && item.type === "business_whatsapp"))
      if(clientIndex !== -1){
        setClientIdValue(userProfile?.clientId)
        form.setFieldsValue({
          clientId: userProfile?.clientId,
        });
      }
    }
  }, [])

  const handleInputChange = (value: any) => {
    if (value !== client_id) {
      form.setFieldsValue({
        clientId: value,
      });
      setClientIdValue(value);
    }
  };

  const onFormatInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const showInputs = [false, false, false];
    for (let i = 0; i < 3; i++) {
      if (inputValue.includes(`{{${i + 1}}}`)) {
        showInputs[i] = true;
      }
    }
    setShowDynamicInputs(showInputs);
  };

  const goToMainPage =() =>{
    window.history.back();
  }

  const handleWhenSendCheck = (e: RadioChangeEvent) => {
    setWhenSendValue(e.target.value); 
  };
  
  const handleChangeCampaignType = (value:any) => {
    setCampaignType(value);
  };

  const handleChangePireods = (value:any) => {
    setPireodsValue(value)
  }
  const handleAddTempClick = () => {
    navigate("/template-Create")
  };


  const handleChange = (info: any) => {
    let newFile = info.fileList[0];
    setFileList(newFile);
  };

  const fileUploadProps = {
    beforeUpload:(file:any) => {
      setFileList(file);
      return false;
    },
    onChange: handleChange,
    multiple: false,
  };
  const handleFileChange = (event:any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const changeTemplate = (value: string) => {
    setSelectedTemplate(value);
  };

  const checkPlaceholdersExist = (formatString: string) => {
    const placeholderPattern = /\{\{\d+\}\}/g;
    const matches = formatString.match(placeholderPattern);
    if (matches) {
      return false;
    } else {
      return true;
    }
  }

  const onTimeChange = (time: Dayjs | null, timeString: string | string[])=> {
    console.log(time, timeString);
  };


  const disabledDate = (current:any) => {
    const todayInTimezone = moment().tz(timeZone).startOf('day');
    return current && current < todayInTimezone;
  };

  const disabledHours = () => {
    const currentHour = moment().tz(timeZone).hour();
    return Array.from({ length: currentHour }, (v, k) => k);
  };
  
  // Function to check if a minute is before the current minute in the specified timezone
  const disabledMinutes = (selectedHour :any) => {
    const currentHour = moment().tz(timeZone).hour();
    const currentMinute = moment().tz(timeZone).minute();
  
    if (selectedHour === currentHour) {
      return Array.from({ length: currentMinute }, (v, k) => k);
    }
    return [];
  };
  
  return (
    <>
      <UserAddFormWrapper className='add_templates_form user-chat user-chat-show pb-4'>
        <div className='userAddFormContainer'>
          <div className="layout-wrapper d-lg-flex MainEmailForm">
            <div className='userAddFormMain'>
              <Form
                disabled={viewCampaignState}
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className='EmailFormAdd addtemplet_form createEditCampForm'
                requiredMark={false}
                initialValues={initialValues}
              >
                {/* <h2 className='temp_page_title'>Templates</h2> */}
                <div className='d-flex align-items-center gap-2 mb-4 px-4 ms-1'>
                  <Button 
                  className='btn btn-sm p-1 d-flex align-items-center justify-content-center btn-secondory pageBackBtn'
                  onClick={goToMainPage}><i className='bx bx-chevron-left font-size-20'></i></Button>
                  <h3 className='text-center mb-0 font-size-22 text-dark'>{viewCampaignState ? "View" :editCampaignState ? "Edit" : "Create" } Campaign</h3>
                </div>
                <Row>
                  <Col md={24} lg={12} xl={12} className='form_col_span'>
                    <Form.Item
                      label="Campaign Name"
                      name="name"
                      rules={[
                        { required: true, message: 'Please enter campain name!' }
                      ]}
                    >
                      <Input
                        disabled={viewCampaignState}
                        className='temp_name_input'
                        placeholder="Enter campain name..."
                        value={campaignName}
                        onInput={handleCampaignNameChange}
                        // disabled={campaignId && typeof campaignId === 'string'}
                      />
                    </Form.Item>
                    <Form.Item
                    label="From Number"
                    name="clientId"
                    // rules={[
                    //   { required: true, message: 'Please select business whatsapp number!' }
                    // ]}
                    >
                      <Dropdown  className='userNewDropdown createTemplateNbListDrpDwp w-100' onSelect={handleInputChange}>
                        <Dropdown.Toggle disabled={viewCampaignState} variant="success" className='text_color_dropdown text-truncate bg-white w-100' id="dropdown-basic">
                          {getName(clients, selectedClientIdValue)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {/* <span className='dotactive'></span> */}
                          {clients.map((item: any) => {
                            if(item.type === "business_whatsapp"){
                              return (
                                <Dropdown.Item key={item.clientId} eventKey={item.clientId} className={"custom-option"}>
                                <div className='d-flex align-items-center'>
                                  <span className={`mx-2 mb-1 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                    {/* <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo'/> */}
                                    {item.type === "business_whatsapp" ? <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' /> :item.type === "whatsapp" ? <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo'/>: item.type === "telegram" && <img src={telegramLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' /> }
                                  </span>
                                  <span className={`text-truncate dropItemUserName me-1`}>
                                    {item?.pushname}
                                  </span>
                                  <span className="text-muted dropItemUserNumber">
                                    ( +{item.me?.user} )
                                  </span>
                                </div>
                              </Dropdown.Item>
                              )
                            }})
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Item>
                    <Form.Item
                      label="Type"
                      name="messageType"
                      className='temp_category_element'
                      // rules={[{ required: true, message: 'Please select message categories!' }]}
                      // tooltip={{ title: content, placement: 'right' }}
                    >

                      <Select
                        disabled={true}
                        // disabled={viewCampaignState}
                        placeholder="Select type of campaign"
                        className='temp_category_dropdown'
                        value={campaignType}
                        defaultValue={campaignType}
                        onChange={handleChangeCampaignType}
                        options={[
                          {
                            value: 'dynamic',
                            label: 'Template',
                          },
                        ]}>
                      </Select>
                      {/* <Input disabled defaultValue={"Template"}/> */}
                    </Form.Item>
                    {campaignType && campaignType === 'text' &&
                      (<Form.Item
                      name="message"
                      label="Custom Message"
                      rules={[{ required: true, message: 'Please input Intro' }]}
                    >
                      <Input.TextArea
                        disabled={viewCampaignState}
                        onChange={(e:any) => onFormatInputChange(e)}
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        showCount
                        maxLength={1024}
                        placeholder="Enter your message here..."
                      />
                    </Form.Item>)}
                    {campaignType && campaignType === 'dynamic' &&
                    ( <Form.Item
                      label={<div className='w-100 d-flex align-items-center justify-content-between'>Select Template Message <div  onClick={handleAddTempClick} className='d-flex align-items-center addNewTempBtn'><i className="ri-add-line"></i>Add New Template</div></div>}
                      name="templateId"
                      className='tempSelectFormInput'
                      rules={[{ required: true, message: 'Please select message categories!' }]}
                      // tooltip={{ title: content, placement: 'right' }}
                    >

                      <Select 
                        disabled={viewCampaignState}
                        showSearch
                        placeholder="Select Template Message"
                        className='temp_category_dropdown'
                        optionFilterProp="children"
                        // defaultValue={selectedTemplate}
                        // onChange={changeTemplate}
                        // value={selectedTemplate}
                        filterOption={(input, option) => (option?.tempName)?.includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.tempName ?? '').toLowerCase().localeCompare((optionB?.tempName ?? '').toLowerCase())
                        }
                      >
                        {templates?.length && templates?.map((item: any) => {
                          // const isNotMultipleValue = checkPlaceholdersExist(item.tempFormat)
                          if(item.status === "APPROVED" && item.clientId === selectedClientIdValue){
                            return (
                              <Option value={item._id} tempName={item.tempName} label={item.tempName} key={item._id}>
                                {item.tempName}
                              </Option>
                            )
                          }
                        })}
                      </Select>

                    </Form.Item>)
                    }
                  </Col>
                  <Col md={24} lg={12} xl={12} xxl={10} className='form_col_span'>
                    {!editCampaignState&& <Form.Item
                    name="file"
                    rules={[{ required: true, message: 'Please select contact file!' }]}  
                    label={ <div className='d-flex align-items-center'>Upload Contact:
                    <Popover overlayClassName='tooltip_category' content={<div style={{width:200}}>Uploading is limited to a maximum of 2000 contacts.</div>}>
                      <i className='bx bxs-info-circle lh-sm ms-1 font-size-14'></i>
                    </Popover>
                    <a href="https://controlhippo-whatsapp-web-sessions.s3.ap-south-1.amazonaws.com/media/sample_contact.xls" className="downloadCampaign ms-2 fw-normal">Download Sample File</a>
                    </div>
                  }
                    className="import_contact_form">
                      <div className="d-flex align-items-center gap-3 impoContactBtn">
                          {/* <Upload {...fileUploadProps} className="powerCsvUpload" accept=".csv,.xlsx">
                              <Button color="primary" className="uploadbtnctm" outline={true}>
                                  Import Contact
                              </Button>
                          </Upload> */}
                          <Input disabled={viewCampaignState} type='file' onChange={handleFileChange} accept=".xls,.xlsx,.csv"/>
                      </div>
                    </Form.Item>}
                    <Form.Item name="whenSend" label="When do you want to send it?" className='mb-2 whenSendCampDiv'>
                      <Radio.Group disabled={viewCampaignState} onChange={handleWhenSendCheck} value={whenSendValue} defaultValue={`now`}>
                        <Radio className='mb-1' value={`now`}>Send now</Radio>
                        <Radio className='mb-1' value={`intervals`}>Send on intervals</Radio>
                        <Radio value={`scheduled_date_time`}>Schedule for a specific time</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {whenSendValue === 'intervals' &&
                    <div className='d-flex align-items-center gap-3 whenSendCampDiv'>
                        <Form.Item name="period" className='subLableDiv' label="Period" rules={[{required:true,message:"Please select period"}]}>
                            <Select
                              disabled={viewCampaignState}
                              placeholder="Select Period"
                              className='w151'
                              defaultValue={pireodsValue}
                              value={pireodsValue}
                              onChange={handleChangePireods}
                              options={[
                                {
                                  value: 'daily',
                                  label: 'Daily',
                                },
                                {
                                  value: 'weekly',
                                  label: 'Weekly',
                                },
                                {
                                  value: 'monthly',
                                  label: 'Monthly',
                                },
                              ]}
                            />
                        </Form.Item>
                        {pireodsValue === "monthly" ? <Form.Item name="date" className='subLableDiv' label="Date" rules={[{required:true,message:"Please select date"}]}>
                          <DatePicker disabled={viewCampaignState} disabledDate={disabledDate} className='dateTimePickerCampCreate w151' format={`DD/MM/YYYY`} />
                        </Form.Item> :pireodsValue === "weekly" &&
                          <Form.Item name="days" className='subLableDiv' label="Day" rules={[{ required: true, message: "Please select day" }]}>
                            <Select
                              disabled={viewCampaignState}
                              placeholder="Select Day"
                              className='w151'
                              options={Days}
                            />
                          </Form.Item> }
                        <Form.Item name="time" className='subLableDiv' label='Time'rules={[{required:true,message:"Please select time"}]}> 
                            <TimePicker 
                            disabled={viewCampaignState}                             
                            // disabledHours={disabledHours}
                            // disabledMinutes={disabledMinutes} 
                            className='dateTimePickerCampCreate w151' use12Hours/>
                        </Form.Item>
                    </div>
                    }
                    {whenSendValue === 'scheduled_date_time' &&
                    <div className='d-flex align-items-center gap-3 whenSendCampDiv'>
                        <Form.Item name="date" className='subLableDiv' label="Date" rules={[{required:true,message:"Please select date"}]}>
                          <DatePicker disabled={viewCampaignState}  disabledDate={disabledDate} className='dateTimePickerCampCreate w151' format={`DD/MM/YYYY`} />
                        </Form.Item>
                        <Form.Item name="time"  className='subLableDiv' label='Time' rules={[{required:true,message:"Please select time"}]}> 
                          <TimePicker disabled={viewCampaignState} 
                            onChange={onTimeChange}
                            // disabledHours={disabledHours}
                            // disabledMinutes={disabledMinutes}
                            className='dateTimePickerCampCreate w151' use12Hours/>
                        </Form.Item>
                    </div>
                    }
                  </Col>
                  {!viewCampaignState && <Col span={24}>
                    <Form.Item style={{ textAlign: 'center' }}>
                      <Space>
                        <Button className='submitbuttonTemp' color='primary' type="submit" disabled={isSubmitted || loading} loading={loading}>
                          {loading ? 'Loading': isSubmitted  ? 'Submitted' : 'Submit'}
                        </Button>
                        <Button className='canclebtnTemp' color='danger' outline onClick={() => navigateTo()}>
                          Cancel
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>}
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </UserAddFormWrapper>
    </>
  );
};

export default CreateCampaign;
