import React , {useEffect, useState} from 'react';
import { Form, Input, message, Space,Checkbox,Row,Col, Modal } from 'antd';
import { Button,Card} from "reactstrap";
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userAdd,userUpdate,getAllocationList,getUserDetails, changeTab } from '../../redux/actions';
import { useRedux, useProfile } from "../../hooks";
import { UserAddFormWrapper } from '../UserAddFormWrapper.style';
import { addMixpanelEvent } from "../../helpers/mixpanel";
import { TABS } from '../../constants';
import { checkEstimate } from '../../helpers/checkEstimate';
import EstimatePopup from '../../components/EstimatePopup';
interface FormValues {
  email: string;
  allocatedNumber:any
}
interface UserAddProps {
  editUserId : string | undefined |boolean,
  editUserState?: any
}
const UserAdd = ({editUserState,editUserId}:UserAddProps) => {
  const [form] = useForm<FormValues>();
  const { dispatch, useAppSelector } = useRedux();
  const { 
    isInvited,
    allocationList,
    userDetails,
    isUpdateUser  } = useAppSelector((state) => {
    return {
      isInvited: state.User?.isInvited,
      userDetails: state.User?.userDetails,
      isUpdateUser: state.User?.isUpdateUser,
      allocationList: state.Chats?.allocationList,
    };
  });
  const [userData, setUserData] = useState({})
  const [allocatedNumberList , setAllocatedNumberList] =useState<[string]>([allocationList[0]?.clientId])
  const [conformationPopupData, setConformationPopupData] = useState<{isOpen:boolean,message?:string,from?:any, data?:any}>({isOpen:false})
  const [clients,setClients] = useState<any>()
  const [valid, setValid] = useState(true);
  const [modalOpen, setModalOpen] = useState<any>();
  const [okClicked, setOkClicked] = useState(false);
  const [inputEmailValue, setInputEmailValue] = useState("");
  const {userProfile} = useProfile()
  const navigate = useNavigate();
  useEffect(() => {
    if (editUserId && typeof(editUserId) == "string") {
      dispatch(getUserDetails(editUserId))
    }
  }, [editUserId])

  useEffect(() => {
    if(userDetails && Object.keys(userDetails)?.length > 0 && editUserId){
      setUserData(userDetails)
      const clientIdArray = userDetails?.clientsData?userDetails.clientsData.map((item:any) => item.clientId):allocationList[0].clientId;
      const fetchedData = {
        email: userDetails.email, 
        allocatedNumber: clientIdArray
      };
      form.setFieldsValue(fetchedData);
      setAllocatedNumberList(clientIdArray)
    }
  }, [userDetails])
  
  


  const navigateTo = ()=>{
    navigate("/setting/user-list")
  }

  useEffect(()=>{
    dispatch(changeTab(TABS.USER))
    dispatch(getAllocationList("allocation"))
  },[])
  
  useEffect(()=>{
    if(allocationList.length){
      setClients(allocationList)
      setAllocatedNumberList([allocationList[0].clientId])
    }
  },[allocationList])

  useEffect(() => {
    if(isInvited.success){
      navigateTo()
    }else if(isInvited.success == false && isInvited?.reInvite){
      setValid(true)
      setModalOpen(true)
    }
  }, [isInvited])

  useEffect(() => {
    if(isUpdateUser){
      navigateTo()
    }
  }, [isUpdateUser])
  
  const onFinish = async(values: FormValues) => {
    addMixpanelEvent(`Invite User`,{distinct_id:userProfile?.userId, isInvited:values.email})
    if(editUserId && typeof(editUserId) == "string"){
      dispatch(userUpdate(editUserId,values));
    }else{
      setValid(false)
      // dispatch(userAdd(values));
      const objData = {
        name:userProfile?.chargebeeDetails?.plan_name,
        item: "plan",
        eventType: "update_subscription_for_items",
        userDetails:userProfile?userProfile:{},
        dispatch
      }
      if(userProfile?.chargebeeDetails?.status == "in_trial"){
        dispatch(userAdd(values));
      }else {
      const checkData: any = await checkEstimate(objData)
      if (checkData?.success ) {
        if(checkData?.message){
          if( userProfile.chargebeeDetails.status !== "in_trial"){
            return setConformationPopupData({
              isOpen:true,
              message:checkData?.message,
              from : "create_user",
              data:values
            })
          }
        }
        dispatch(userAdd(values));
      }
    }
    }
  };
  const onCancel = ()=>{
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : ""
    })
  }
  const onConfirm = (data:any) => {
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : "",
      data:null
    })
    dispatch(userAdd(data.data));
  }
  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const validateCheckboxGroup = (_: any, value: string[], callback: (error?: string) => void) => {
    return new Promise<string>((resolve:any, reject:any) => {
      if (!value || value.length === 0) {
        reject('Please select at least one number.');
      } else {
        resolve();
      }
    });
  };
  const allocateDeallocateNumber = (id :string) => {
    setValid(true)
    let allocatedNumberList =  form.getFieldValue("allocatedNumber")
    if(allocatedNumberList.includes(id)){
      const indexNo:number =    allocatedNumberList.indexOf(id)
      if (indexNo > -1) { 
        allocatedNumberList.splice(indexNo, 1);
      }
    }else {
      allocatedNumberList.push(id)
    }
    form.setFieldsValue({allocatedNumber: allocatedNumberList});
    setAllocatedNumberList(allocatedNumberList)
  }

  const goToMainPage =() =>{
    window.history.back();
  }

  const handleEmailChange = (event: any) => {
    setValid(true)
    setInputEmailValue(event?.target?.value)
  }

  const handleOk = () => {
    const payloadData:any = {
      userInviteConfirm : true,
      email: inputEmailValue,
      allocatedNumberList: allocatedNumberList  
    }
    dispatch(userAdd(payloadData));
    setModalOpen(false);
  }

  const handleCancel = () => {
    setOkClicked(false);
    setModalOpen(false);
};
  return (
    <>
      <UserAddFormWrapper className='user-chat user-chat-show pb-4'>
      {/* <Card className="pt-3 pb-0 px-lg-4 px-3 topBreadcrumb">
            <div className="justify-content-between d-flex align-items-center w-100">
               <h5 className="mb-0">Users</h5> 
              <div className="d-flex align-items-center mt-1">
                <span className="d-flex align-items-center channeleBack font-size-13 text-primary" onClick={goToMainPage}> Users List</span>
                <i className='bx bx-chevron-right font-size-20 ln-normal text-muted'></i>
                <span className="text-muted font-size-13">{editUserState ? "Edit" : "Create" } User</span>
              </div>
            </div>
        </Card> */}
        <div className='d-lg-flex align-items-center justify-content-center userAddFormContainer'>
          <div className="layout-wrapper d-lg-flex MainEmailForm">
            <div className='userAddFormMain'>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='EmailFormAdd'
                initialValues={{allocatedNumber: [allocationList[0]?.clientId]}}
                requiredMark={false}
              >
                <div className='d-flex align-items-center gap-2 mb-4'>
                  <Button 
                  className='btn btn-sm p-1 d-flex align-items-center justify-content-center btn-secondory pageBackBtn'
                  onClick={goToMainPage}><i className='bx bx-chevron-left font-size-24'></i></Button>
                  <h3 className='text-start text-dark font-size-18 mb-0'>{editUserState ? "Edit" : "Create" } User</h3>
                </div>
                <Form.Item
                  name="email"
                  label="Enter Your Email"
                  rules={[
                    { required: true, message: 'Field is required' },
                    { type: 'email', message: 'Invalid email format' }
                  ]}
                  normalize={(value) => (value ? value.trim().toLowerCase() : '')}
                >
                  <Input onChange={handleEmailChange} placeholder="Enter Email" disabled={editUserId ? true : false} />
                </Form.Item>
                <Form.Item
                  className='mb-5'
                  name="allocatedNumber"
                  label="Allocate Number"
                  // rules={[
                  //   { required: true, validator: validateCheckboxGroup },
                  // ]}
                >
                  {clients && clients.length ?
                    <Checkbox.Group style={{ width: '100%' }} >
                      <div className='allocateNumber_main'>
                        <div className='row'>
                          {clients.map((item: any, index: number) => {
                            let clientId:string = item.clientId;
                            let allocated = form.getFieldValue("allocatedNumber").includes(clientId) 
                            return (
                              <div key={index} className='col-12 col-md-6 col-lg-3' onClick={(e)=>{
                                e.preventDefault()
                                allocateDeallocateNumber(item.clientId)

                              }}>
                                <div className={`allocate_number_div_main ${allocated ? "allocate_active" : " "}`}>
                                  <div className='row'>
                                    <div className='col-3 d-flex align-items-center justify-content-center pe-0'>
                                      {item.type.includes("whatsapp") ? (
                                        <i className="bx bxl-whatsapp"></i>
                                      ) : item.type === "telegram" ? (
                                        <i className="bx bxl-telegram"></i>
                                      ) : item.type === "messenger" ? (
                                        <i className="bx bxl-messenger"></i>
                                      ) : item.type === "instagram" ? (
                                        <i className="bx bxl-instagram"></i>
                                      ) : null}
                                      <Checkbox className='allocate_mun_checkbox d-none' value={item.clientId}>
                                      </Checkbox>
                                    </div>
                                    <div className='col-9'>
                                      <p className='country_heading'>{`(${item.pushname})`}</p>
                                      <div className='phone_number_main'><p>{`+${item?.me?.user}`}</p></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Checkbox.Group> : ""}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                  <Space>
                    <Button disabled={!valid} color='primary' type="submit">
                      Submit
                    </Button>
                    <Button color='danger' outline onClick={() => navigateTo()}>
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </UserAddFormWrapper>
      <Modal className="warningPopupModel centerPopupModel" title={<div className='d-flex align-items-center'><img src="https://images.controlhippo.com/svg/warning_icon.svg" width={22} height={22} alt="warningIcon" style={{ marginRight: 8 }} />Warning</div>
      } open={modalOpen} onOk={() => { handleOk() }} onCancel={handleCancel} okButtonProps={{ disabled: okClicked }}>
        <p>{isInvited?.message}</p>
      </Modal>
      {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm}/>}
    </>
  );
};

export default UserAdd;
