import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const saveIntegration = (data: string | number | any) => {
    return api.get(url.SAVE_INTEGRATION, { params: data });
};
const getIntegrations = (id: string) => {
    return api.get(url.GET_INTEGRATIONS+"/"+id);
};
const removeIntegraation = (id: string,data:string) => {
    return api.update(url.REMOVE_INTEGRATIONS+"/"+id,data);
};
const removeSlackChannel = (data:any) => {
    return api.update(url.REMOVE_SLACK_CHANNEL,data);
};
const addBoardId = (data:any) => {
    return api.update(url.ADD_BOARD_ID,data);
};
const checkUrl = (urlString:any) => {
    return api.create(url.CHECK_URL,{url:urlString});
};
const updateIntegration = (data:any) => {
    return api.create(url.UPDATE_INTEGRATION+"/"+data.userId,data);
};

export {
    saveIntegration,
    getIntegrations,
    removeIntegraation,
    removeSlackChannel,
    addBoardId,
    checkUrl,
    updateIntegration
};
