import React from "react";

interface AddButtonProps {
  onClick: () => void;
  clientDisconnected?: Boolean;
  selectedClient?: any;
}
const AddButton = ({ onClick, clientDisconnected, selectedClient }: AddButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="primaryBorder userAddButton"
      disabled={(clientDisconnected || selectedClient?.type == "messenger" || selectedClient?.type == "instagram")? true : false}
    >
      <i className='bxs-user-plus-icon userAddIcon'></i>
    </button>
  );
};

export default AddButton;
