import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import React, { useEffect, useState } from "react";
import { Card, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { changeTab, getClientsList, getOldClientId, setClientId, updateClientDeatils, connectInstagram, connectChatbot } from '../../../redux/actions';
import { Form, Input, Button, Upload, Popover, Row, Col, Typography, Select, ColorPicker, notification } from 'antd';
import { checkPlanAndFeature } from "../../../helpers/common.service";
import { checkEstimate } from "../../../helpers/checkEstimate";
import EstimatePopup from "../../../components/EstimatePopup";
import { TABS } from "../../../constants";
import { FacebookFilled } from '@ant-design/icons';
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
import { log } from "console";
import { QrCodeWrapper } from '../QrCodeWrapper.style';
import { UploadOutlined } from '@ant-design/icons';

const { Title } = Typography;

const { Option } = Select;
declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}
interface AddonsTypes {
    name: string;
    isActive: boolean;
}

interface IndexProps {
    editNumber?: string | Number | any;
    numberData: any | null
}

interface UserDetailsTypes {
    email?: string;
    userId?: string;
    clientId?: string;
    chargebeeDetails?: any;
    planDetails?: any;
    fullName?: string;
    phoneNumber?: string;
    userActive?: boolean,
    allAddons?: [AddonsTypes]
    country_code?: string;
}

const Index = (props: IndexProps) => {
    const { userProfile } = useProfile(); // Move this hook inside the component
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();
    const [rescanNumberParam, setRescanNumberParam] = useState("");
    const [pageListData, setPageListData] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState();
    const [tempClientId, setTempClientId] = useState("");
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [channelClientId, setChannelClientId] = useState();
    const [chatbotScript, setChatbotScript] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [conformationPopupData, setConformationPopupData] = useState<{ isOpen: boolean, message?: string, from?: any, type?: String }>({ isOpen: false })
    const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
    const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
    const [show, setShow] = useState(false);
    const [pageResponse, setPageResponse] = useState<any>("");
    const [authAccesstoken, setAuthAccesstoken] = useState<any>("");
    const initialMessengerHandleState = { step: 1, btnText: "Authentication With Facebbok" };
    const [messengerHandle, setMessengerHandle] = useState(initialMessengerHandleState)
    const [color, setColor] = useState('#1890ff'); // Default color
    const reconnectClientId = props?.numberData?.clientId
    const [fileData, setFileData] = useState<any>()

    const [formValues, setFormValues] = useState({
        channelName: '',
        color: color,
        chatIcon: null,
    });
    const { clientList, userData } = useAppSelector((state) => {
        return {
            clientList: state.Chats?.clientList,
            userData: state.Login.user,

        };
    });


    useEffect(() => {
        setUserDetails(userData)
        if (userData?.chargebeeDetails) setChargebeeDetails(userData.chargebeeDetails)
    }, [userData])

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });
        };

        (function (d, s, id) {
            var js: HTMLScriptElement, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement; js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        const checkFbInit = setInterval(() => {
            if (window.FB) {
                clearInterval(checkFbInit);
            }
        }, 100);

        return () => {
            clearInterval(checkFbInit);
        };
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const rescanNumber = searchParams.get('rescan');
        setRescanNumberParam(rescanNumber || "");
        dispatch(getClientsList());
    }, []);

    const handleFacebookConnect = async (e: any, response: any) => {
        // e.preventDefault()
        const objData = {
            item: "addon",
            eventType: "update_subscription_for_items",
            name: "scan_whatsapp_number",
            userDetails,
            dispatch
        }
        if ((!userDetails?.chargebeeDetails) || (response && response.skipEstimate) || (props?.numberData)) {
            onConfirm({ from: "scan_whatsapp_number", type: "chatbot" })
            return
        }

        let checkData: any
        checkData = (messengerHandle.step == 1) ? await checkEstimate(objData) : { success: true, message: "Are you sure you want to rescan whatsapp number?" }
        if (checkData?.success) {
            if (checkData?.message) {
                setConformationPopupData({
                    isOpen: true,
                    message: checkData?.message,
                    from: "scan_whatsapp_number",
                    type: "chatbot"
                })
            } else {
                onConfirm({ from: "scan_whatsapp_number", type: "chatbot" })
            }
        } else {
            return (false)
        }
    };

    const onConfirm = (data: any) => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: "",
            type: ""
        })

        if (userProfile) {
            const clientId = `${userProfile?.parentId ? userProfile?.parentId : userProfile?.userId}_${Math.floor(100000 + Math.random() * 900000)}`;
            formValues.color = color
            const payloadData = {
                formValues: JSON.stringify(formValues),
                clientId: clientId,
                fileData: fileData
            }
            dispatch(connectChatbot(payloadData, (response: any) => {
                console.log("Response:", response);
                if (response.success && response?.clientId) {
                    setChannelClientId(response.clientId)
                    const chatScript = `<script src="${process.env.REACT_APP_CHATBOT_PREFIX}/chatbot.js?API_KEY=${response.clientId}"></script>`
                    setChatbotScript(chatScript)
                    showSuccessNotification(response?.message)
                    // navigate("/dashboard")
                    localStorage.setItem("selectedChatFilter", "All");
                    dispatch(changeTab(TABS.CHAT));
                    dispatch(setClientId(response.clientId));
                    const data = {
                        clientId: response.clientId
                    };
                    updateAuthData(data);
                }
            }));

        }
    }

    const onCancel = () => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: ""
        })
        if (loading) setLoading(false)
        if (show) setShow(false)
    }
    const handleBackButton = () => {
        navigate(-1);
    }

    const buttonClicked = async (response: any) => {
        try {
            const values = await form.validateFields();
            if (response.success) {
                const syntheticEvent = {} as React.FormEvent<HTMLFormElement>;
                return handleFacebookConnect(syntheticEvent, response)
            }
        }
        catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    }

    const rgbaToHex = (r: number, g: number, b: number, a: number) => {
        const toHex = (c: number) => c.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}${Math.round(a * 255).toString(16).padStart(2, '0')}`;
    };

    const handleColorChange = (color: any) => {
        const { r, g, b, a } = color.metaColor;
        const hexColor = rgbaToHex(r, g, b, a);
        console.log('Hex Color:', hexColor);

        setColor(hexColor);
        form.setFieldsValue({ color: hexColor });
    };

    const handleCopy = () => {
        const textToCopy = document.getElementById('chatbotScript')?.innerText;
        if (typeof textToCopy === 'string') {
            navigator.clipboard.writeText(textToCopy)
                .then(() => notification.success({ message: 'Copied to clipboard!', duration: 1.2 }))
                .catch(err => {
                    console.error('Failed to copy:', err);
                    notification.error({ message: 'Failed to copy!' });
                });
        }
    };
    return (
        <>
            <QrCodeWrapper className="QrCodeWrapper">
                <Card className="qrCodePageCardTitleMain p-3 w-100 h-100 mb-0">
                    <div className="d-flex gap-2">
                        <div className="ms-1 float-left">
                            <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                        </div>
                        <div>
                            <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                                <b> Connect Chatbot {rescanNumberParam && `[ +${rescanNumberParam} ] `}</b>
                                <a id="whatsppcard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/en/support/solutions/articles/1060000087961-how-to-integrate-chatbot-with-controlhippo-" target="_blank">
                                    <i className="bx bxs-info-circle font-size-17 lh-sm"></i>
                                </a>
                                <UncontrolledTooltip
                                    target={`whatsppcard`}
                                    placement="right"
                                >
                                    Click here to know more..
                                </UncontrolledTooltip>
                            </h2>
                            <p className="font-size-13 text-muted">Controlhippo now integrates with Chatbot, allowing your team to manage customer queries directly within the platform.</p>
                        </div>
                    </div>
                    <Row className="qrmainrow mt-2">
                        <Col md={12} lg={12} xl={12} className="contetCol">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{ color }}
                                style={{ maxWidth: 400 }}
                                requiredMark={false}
                                onValuesChange={(changedValues, allValues) => {
                                    setFormValues(allValues);
                                }}
                            >
                                <Form.Item
                                    name="channelName"
                                    label="Channel Name"
                                    rules={[{ required: true, message: 'Please enter a channel name!' }]}
                                >
                                    <Input placeholder="Website Chat" />
                                </Form.Item>

                                <Form.Item
                                    name="color"
                                    label="Chatbot theme color"
                                >
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', padding: '3px 8px', border: '1px solid #d9d9d9', borderRadius: '4px', backgroundColor: '#fff' }}
                                    >
                                        <span style={{ flex: 1, paddingRight: '8px' }}>{color}</span>
                                        <ColorPicker value={color} onChange={handleColorChange} />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    name="chatIcon"
                                    label="Select an icon for the Widget"
                                >
                                    <Upload
                                        action="https://api.controlhippo.com/server-webhook"
                                        maxCount={1}
                                        listType="picture"
                                        accept={"image/png, image/jpeg ,image/jpg, image/svg+xml"}
                                        className="tempUpload"
                                        onChange={(info) => { setFileData(info.fileList[0]) }}
                                    >
                                        <Button className='antBotton' icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </Form.Item>
                                {!channelClientId &&
                                    <div className="mt-3"> <ChargebeeCheck numberData={props.numberData} userDetails={userProfile ? userProfile : userData} buttonText={"Save Changes"} onConfirm={buttonClicked} from="chatbot" /></div>
                                    // }
                                    // {
                                    //     chatbotScript &&
                                    //     <div id="chatbotScript">{chatbotScript}<i className="ri-file-copy-line font-size-11 lh-sm" onClick={handleCopy}></i></div>
                                }
                            </Form>
                        </Col>
                        <Col md={12} lg={12} xl={12} className="d-flex flex-column align-items-center">
                            <img height={120} width={120} src={`https://images.controlhippo.com/svg/chatbot_icon.svg`} className="img_radius_8 rounded-4" alt="Whatsapp Logo - Controlhippo" />
                            <h4 className="lh-sm mb-0 mt-3 font-size-17">Chatbot</h4>
                            {chatbotScript && (<>
                            <p className="m-3 mt-4 w-75"><strong>Note: </strong>To enable the chatbot on your website, insert the script just before the closing {'</body>'} tag in your HTML file.</p>
                                <div id="chatbotScript" className="mt-2 w-75 d-flex justify-content-between align-items-center p-3 border bg-success Chat-bot-clipboard">
                                    <span className="flex-grow-1  fs-6 ">{chatbotScript}</span>
                                    <i className="ri-file-copy-line fs-3 lh-sm ml-2 cursor-pointer" onClick={handleCopy}></i>
                                </div>
                                </>
                            )}
                        </Col>
                    </Row>
                    <div className="stickyBottomLine">Please refer to our <a href="https://support.controlhippo.com/en/support/solutions/articles/1060000087961-how-to-integrate-chatbot-with-controlhippo-" target="_blank">support article </a>to gain a better understanding of Instagram integration.</div>
                </Card>
                {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm} />}
            </QrCodeWrapper>
        </>
    );
};

export default Index;
