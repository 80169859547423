import { all } from "redux-saga/effects";

//auth
import registerSaga from "./auth/register/saga";
import updateProfileSaga from "./auth/updateProfileData/saga";
import loginSaga from "./auth/login/saga";
import forgetPasswordSaga from "./auth/forgetpwd/saga";
import confirmAccountSaga from "./auth/confirmUserData/saga";
import updatePasswordSaga from "./auth/updatePassword/saga";
import changePasswordSaga from "./auth/changePassword/saga";
import profileSaga from "./profile/saga";
import LayoutSaga from "./layout/saga";
import contactsSaga from "./contacts/saga";
import callsSaga from "./calls/saga";
import bookmarksSaga from "./bookmarks/saga";
import settingsSaga from "./settings/saga";
import chatsSaga from "./chats/saga";
import qrCodeSaga from "./clients/saga";
import integrationsSaga from "./integrations/saga";
import getUserListSaga from "./userList/saga"
import postTemplateCreateSaga from "./templateCreate/saga"
import updateUserProfileSaga from "./updateUserProfile/saga"
import setSubUserPwd from "./auth/subUserSetPwd/saga"
import postChangeEmail from "./auth/changeEmail/saga"
import updateSubUserPwd from "./auth/updateSubUSerPwd/saga"
import socketSaga from "./websocket/saga"
import TourVisitedSaga from "./TourVisted/saga"
import PlanBillingSaga from "./PlanBilling/saga";
import AccountSettingSaga from "./accountSetting/saga";
import ReportsSaga from "./reports/saga";
import CampaignSaga from "./campaign/saga";

export default function* rootSaga() {
  yield all([
    registerSaga(),
    loginSaga(),
    forgetPasswordSaga(),
    confirmAccountSaga(),
    updatePasswordSaga(),
    updateProfileSaga(),
    profileSaga(),
    getUserListSaga(),
    postTemplateCreateSaga(),
    LayoutSaga(),
    contactsSaga(),
    callsSaga(),
    AccountSettingSaga(),
    bookmarksSaga(),
    settingsSaga(),
    chatsSaga(),
    qrCodeSaga(),
    integrationsSaga(),
    socketSaga(),
    changePasswordSaga(),
    setSubUserPwd(),
    postChangeEmail(),
    updateSubUserPwd(),
    updateUserProfileSaga(),
    TourVisitedSaga(),
    PlanBillingSaga(),
    ReportsSaga(),
    CampaignSaga()
  ]);
}
