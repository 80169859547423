export enum UpdateSubUserPwdActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/updatePassword/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/updatePassword/API_RESPONSE_ERROR",
  
    UPDATE_SUB_USER_PWD = "@@auth/updatePassword/UPDATE_SUB_USER_PWD",
    // CHANGE_PASSWORD = "@@auth/confirmData/CHANGE_PASSWORD",
  }
  export interface AuthConfirmDataState {
    authData: any;
    confirmaccountError: any;
    userSuccess: any;
  }
  