import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form } from "reactstrap";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
// import cookie from "react-cookies";
import { isValidPhoneNumber } from "libphonenumber-js";


import { useRedux } from "../../hooks/index";
import { useParams,Navigate,useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { userConfirmData } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

// images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { showErrorNotification } from "../../helpers/notifications";
let now = new Date();

interface ConfirmAccountProps { }
const Confirmaccount : React.FC<ConfirmAccountProps> = (props) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [loading, setLoading] = useState(false);
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  const userCountry = "in";
  const navigate = useNavigate();

  const { authToken, emailSended, confirmAccountLoading, confirmaccountError } = useAppSelector(
    (state) => {
      return {
        authToken: state?.confirmAccount?.authData,
        emailSended: state?.confirmAccount?.emailSended,
        confirmAccountLoading: state?.confirmAccount?.loading,
        confirmaccountError: state?.confirmAccount?.confirmaccountError,
      };
    }
  );
  const { hash, i, c } = useParams();

  useEffect(() => {
    if(confirmaccountError){
      showErrorNotification(confirmaccountError)
      navigate("/auth-login", { state: { error: confirmaccountError?.message } });
    }
  }, [confirmaccountError])
  
  useEffect(() => {
    let data = {
      hash: hash,
      i: i,
      c: c,
      userLoginTime: now,
    };
    setLoading(true)
    dispatch(userConfirmData(data));
  }, [dispatch, hash, i, c]);
  useEffect(() => {
   if(authToken){
    setLoading(false)
    navigate("/update-profile",{
      state: {
        authToken
      }
    });
   }
  }, [authToken]);

  const onSubmitForm = (values: object) => { };

  return (
    <NonAuthLayoutWrapper>
      {loading && <Loader />}
    </NonAuthLayoutWrapper>
  );
};

export default Confirmaccount;


