import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const getCustomerPortal = (userId: string) => {
    return api.get(url.CHARGEBEE+"/getCustomerPortal/"+userId);
};

const getInvoices = (status:string) => {
    return api.get(url.CHARGEBEE+"/invoices/"+status)
}

const invoiceDownload = (id:string) => {
    return api.get(url.CHARGEBEE+"/invoices/download/"+id)
}
const getPlans = () => {
    return api.get(url.BILLING_PLANS+'/plans')
}

const changeSubscription = (data:any,id:string) =>{
    return api.create(url.CHARGEBEE+'/changeSubscription/'+id,data)
}
const chargebeeProcess = (data:any,id:string) =>{
    return api.create(url.CHARGEBEE+'/chargebeeProcess/'+id,data)
}
const addCredit = (data:any) => {
    return api.create(url.CHARGEBEE+'/addCredit',data)
}
const checkEstimateCost = (data:any,id:string) => {
    return api.create(url.CHARGEBEE+'/estimate/'+id,data)
}
const getInvoicePaymentUrl = (id:string) => {
    return api.get(url.CHARGEBEE+'/invoice/'+id)
}

const cancelAccount = (data: any) => {
    return api.create(url.CANCEL_ACCOUNT, data)
  } 

export {
    getCustomerPortal,
    getInvoices,
    invoiceDownload,
    getPlans,
    changeSubscription,
    chargebeeProcess,
    addCredit,
    checkEstimateCost,
    getInvoicePaymentUrl,
    cancelAccount
};
