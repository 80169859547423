import React, {useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { TABS } from "../../constants/index";
import { useRedux,useConversationUserType,useProfile } from "../../hooks/index";
import { connectSocket, getClientsList, createUser, setClientId,changeTab,postFcmToken, removeSelectedChat, clearChatSyncingReducer } from '../../redux/actions';
import { isMobile } from 'react-device-detect';

import { Alert, Space } from 'antd';
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";

import { initializeApp } from "firebase/app";
import { getToken,getMessaging } from "firebase/messaging";
import { showErrorNotification } from "../../helpers/notifications";
import Cookies from "js-cookie";
import { Button } from "reactstrap";

interface IndexProps {}
const Index = (props: IndexProps) => {
  const { dispatch,useAppSelector } = useRedux();
  const navigate = useNavigate();
  const { userProfile } = useProfile();
  const [urlValue,setUrlValue] = useState("")
  const [userDetails, setUserDetails] = useState<any>()
  const [clientData, setclientData] = useState<any>({})
  const [selectedClientIdState,setSelectedClientIdState] = useState<any>([])
  const [clientDisconnected, setClientDisconnected] = useState(false)

  const {
    selectedChat,
    clientId,
    clientAuthenticated,
    clientReady,
    clientList,
    userData
  } = useAppSelector((state) => {
    return {
      selectedChat: state.Chats.selectedChat,
      clientList: state.Chats.clientList,
      clientId: state.Chats.clientId,
      clientAuthenticated:state.QrCode.clientAuthenticated,
      clientReady:state.QrCode.clientReady,
      userData: state.Login.user,
    };
  })

  useEffect(() => {
    if(selectedClientIdState?.length && !selectedClientIdState[0]?.isReady && !selectedClientIdState[0]?.isSessionSaved){
      dispatch(clearChatSyncingReducer())
    }
  }, [selectedClientIdState])
  

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        dispatch(removeSelectedChat())
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if(clientList?.length){
      const selectedClientId = clientList?.filter((data:any)=>data.clientId == clientId)
      setSelectedClientIdState(selectedClientId)
    }
  }, [clientList])
  
  useEffect(() => {
    setUserDetails(userData)
  }, [userData])

  useEffect(() => {
    let clientlistData: any;
    if (clientList && clientId) {
      clientlistData = clientList.find((item: any) => item.clientId === clientId);
    }
    if (clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)) {
      setClientDisconnected(false)
    } else if (clientlistData) {
      setClientDisconnected(true)
    }

    if(clientlistData){
      setclientData(clientlistData)
    }else{
      setclientData({})

    }
  }, [clientList])

  const [fcmToken, setFcmToken] = useState("")
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 24 * 30 * 12 * 60 * 60 * 1000); // 30 days from now
  Cookies.set('userId', userProfile?.userId, { expires: expirationDate });
  Cookies.set('ch2email', userProfile?.email, { expires: expirationDate });


  const firebaseConfig = {
    apiKey: "AIzaSyD4iMtV7an0t-JtnXfuWLC0zisasTPFpuo",
    authDomain: "controlhippo-e05c0.firebaseapp.com",
    projectId: "controlhippo-e05c0",
    storageBucket: "controlhippo-e05c0.appspot.com",
    messagingSenderId: "1004121531901",
    appId: "1:1004121531901:web:e9454b37ca5bb2ca22e594",
    measurementId: "G-S2JBQTGKHJ"
};
const isDeviceMobile = isMobile;
var messaging : any
if (!isDeviceMobile) {
   const app = initializeApp(firebaseConfig);
   messaging = getMessaging(app);
}

    async function requestPermissionWithDelay() {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const permissionResult = await Notification.requestPermission();        
      if (permissionResult === "granted") {
        try {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          const token:any = await getToken(messaging, {
            vapidKey: "BBs_RUJOXpEkKK6mezivYQisQA_7vumPOTQkzZBs5dTsNwkXVteYRC5GoQl1JyXVnciXOy8AzhQJ3hrCFzzTEAs",
          });
          setFcmToken(token);
          if(token){
          dispatch(postFcmToken({token}));
          }else{
            console.log("Token not generated")
          }
        } catch (error) {
          console.error("Error generating token:", error);
        }
      }
    }

  useEffect(() => {
    getBackForwardCacheURL()
    if (!isDeviceMobile) {
      requestPermissionWithDelay()
    }
    dispatch(getClientsList("dropdown"))
    // dispatch(changeTab(TABS.CHAT))
    if (!clientId && userProfile?.clientId)
      dispatch(setClientId(userProfile?.clientId))

  }, [])
  useEffect(() => {
    if(clientAuthenticated || clientReady.success){
      setTimeout(() => {
        dispatch(getClientsList("dropdown"))
      }, 3000);
    }
  }, [clientAuthenticated,clientReady])

  function getBackForwardCacheURL() {
    var currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    const toValue: any = params.get("to");
    const contactIdValue = params.get("contactId");
    const logoClick = params.get('logoClick');
    if (toValue || contactIdValue || logoClick) {
        const trimmedValue = toValue?.trim();
        const trimmedValue1 = contactIdValue?.trim();
        setUrlValue(trimmedValue)
        navigate("/dashboard")
        setTimeout(() => {
            dispatch(changeTab(TABS.CHAT));
            if (!logoClick) {
                dispatch(createUser({ chatId: trimmedValue + "@c.us", clientId: userProfile?.clientId, contactId: trimmedValue1 }, (response) => {
                    if (!response?.success) {
                        showErrorNotification(response.message)
                    }
                }))
            }
        }, 500);
    }
}

  const { isChannel } = useConversationUserType();
  useEffect(() => {
    if(!userProfile?.userId) {
      navigate("/welcome");
    }
  }, [])

  const handleRescan = () => {
    const index = clientList.find((item: any) => item.clientId === userDetails.clientId);
    const fullPath = `/channels/reconnect-${index?.type}/${index?._id}`;
    if (index && (!index.isReady || !index.isSessionSaved)) {
      navigate(fullPath , { state: { numberData: clientData } });
    }
};
  
  return (
    <>
      <Leftbar clientList={clientList} />
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", "userChatContainer", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        {selectedClientIdState?.length && !selectedClientIdState[0]?.isReady && !selectedClientIdState[0]?.isSessionSaved && clientId !== "all_numbers" ?
          <Alert className="disconnectTagLine w-100 border-0 rounded-0" message={
            selectedClientIdState[0]?.type == "whatsapp" ?
              <p className="w-100 d-inline-block text-center tagLineBadge gap-1 mb-0 lh-sm font-size-12"> 
                Your number has been disconnected. Please re-scan your number to continue message synchronization in Controlhippo. <Button className="reconnectBtn" color="danger" onClick={handleRescan}>Reconnect</Button>
              </p> :
              selectedClientIdState[0]?.type == "telegram" ?
                <p className="w-100 d-inline-block text-center tagLineBadge gap-1 mb-0 lh-sm">
                  Your Telegram account has been disconnected. Please re-authenticate your account to continue message synchronization in Controlhippo. <Button className="reconnectBtn" color="danger" onClick={handleRescan}>Reconnect</Button>
                </p> :
                selectedClientIdState[0]?.type == "instagram" ?
                  <p className="w-100 d-inline-block text-center tagLineBadge gap-1 mb-0 lh-sm">
                    Your Instagram account has been disconnected. Please re-authenticate your account to continue message synchronization in Controlhippo. <Button className="reconnectBtn" color="danger" onClick={handleRescan}>Reconnect</Button>
                  </p> :
                  selectedClientIdState[0]?.type == "messenger" ?
                    <p className="w-100 d-inline-block text-center tagLineBadge gap-1 mb-0 lh-sm">
                      Your Messenger account has been disconnected. Please re-authenticate your account to continue message synchronization in Controlhippo. <Button className="reconnectBtn" color="danger" onClick={handleRescan}>Reconnect</Button>
                    </p> : ""
          }
            type="warning"  closable /> : ""}
        {selectedChat !== null ? (
          <div className="chat-content userChatContent d-lg-flex">
            <div className="w-100 overflow-hidden position-relative userChatContentBlock">
              <ConversationUser isChannel={isChannel} />
            </div>
            <UserProfileDetails isChannel={isChannel} />
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};

export default Index;
