import React, { useEffect, useState } from 'react';
import { Form, Input, message, Space, Checkbox, Row, Col, Select, Radio, Popover, Card, Upload, Button as AntButton } from 'antd';
import { Button } from "reactstrap";
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userAdd, userUpdate, getClientsList, getTemplateDetails, templateUpdate, templateCreate, setClientId, getDirectMessages, getTemplateClientList } from '../../redux/actions';
import { useRedux, useProfile } from "../../hooks";
import { UserAddFormWrapper } from '../UserAddFormWrapper.style';
import { addMixpanelEvent } from "../../helpers/mixpanel";
import { InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import whatsappLogo from "../../assets/images/whatsapp_logo.png";
import whatsappBusinessLogo from "../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../assets/images/channelListIcon/telegram_icon.png";
import messengerLogo from "../../assets/images/messenger_1.jpg";
import instagramLogo from "../../assets/images/Instagram_icon.webp";
import templatePreview from "../../assets/images/temp_preview.webp";
import type { RadioChangeEvent } from 'antd';
import { showErrorNotification } from '../../helpers/notifications';
import type { UploadProps } from 'antd';
import Loader from '../../components/Loader';


const { Option } = Select;

interface FormValues {
  categories: string;
  format: string;
  name: string;
  email: string;
  allocatedNumber: any
  dynamicValue1: any
  dynamicValue2: any
  dynamicValue3: any
}

interface TemplateCreateProps {
  templateId: any,
  editTempltState?: any
  viewTempltState?: any
}
const UserAdd = ({ viewTempltState, editTempltState, templateId }: TemplateCreateProps) => {

  const [form] = useForm<FormValues>();
  const { dispatch, useAppSelector } = useRedux();
  const [loading1, setLoading1] = useState(false);
  const {
    isInvited,
    tempClientlist,
    isUpdateUser,
    client_id,
    loading,
    templateCreateSuccess,
    getTemplateDetails1
  } = useAppSelector((state) => {
    return {
      isInvited: state.User?.isInvited,
      tempClientlist: state.Chats?.tempClientlist,
      isUpdateUser: state.User?.isUpdateUser,
      client_id: state.Chats.clientId,
      loading: state.Templates.loading,
      templateCreateSuccess: state.Templates.templateCreateSuccess,
      getTemplateDetails1: state.Templates.getTemplateDetails,
    };
  });
  const MAX_FILE_SIZE_BYTES = 15 * 1024 * 1024; // 15 MB
  const [userData, setUserData] = useState({})
  const [allocatedNumberList, setAllocatedNumberList] = useState<[string]>([tempClientlist[0]?.clientId])
  const [selectedNumber, setSelectedNumber] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [value, setValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  const [showDynamicInputs, setShowDynamicInputs] = useState([false, false, false]);
  const [templateText, setTemplateText] = useState<string>("")
  const [mediaTypeValue, setMediaTypeValue] = useState<string>("image")
  const [tempType, setTempType] = useState<string>("")
  const [fileData, setFileData] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>("https://images.controlhippo.com/wp-content/uploads/2023/11/23050819/connect_seamlessly_image.png")
  const [videoUrl, setVideoUrl] = useState<any>("https://images.controlhippo.com/svg/video_player_new.svg")
  const [tempSelectedNumber, setTempSelectedNumber] = useState<any>("")
  const [dynamicTemplateValues, setDynamicTemplateValues] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<any>("")
  const [quickReplies, setQuickReplies] = useState<any>([{
    "type": "QUICK_REPLY",
    "text": ""
  }, {
    "type": "QUICK_REPLY",
    "text": ""
  }, {
    "type": "QUICK_REPLY",
    "text": ""
  }])
  useEffect(() => {
    if (templateId) {
      dispatch(getTemplateDetails(templateId))
    }
  }, [templateId])
  useEffect(() => {
    setLoading1(loading)
  }, [loading])
  
  useEffect(() => {
    if (fileData?.thumbUrl?.length) {
      setImageUrl(fileData?.thumbUrl)
    } else if (fileData?.type == "video/mp4") {
      setVideoUrl("https://images.controlhippo.com/svg/video_player_new.svg")
    }
  }, [fileData?.thumbUrl])


  useEffect(() => {
    if (getTemplateDetails1?.length > 0 && templateId) {
      const showInputs = [false, false, false];
      for (let i = 0; i < 3; i++) {
        if (templateText.includes(`{{${i + 1}}}`)) {
          showInputs[i] = true;
        }
      }
      setShowDynamicInputs(showInputs);
    }
  }, [templateText])


  useEffect(() => {
    if (getTemplateDetails1?.length > 0 && templateId) {
      setUserData(getTemplateDetails1)
      const fetchedData: any = {
        categories: getTemplateDetails1[0]?.tempCategory,
        selectNumber: getTemplateDetails1[0]?.selectedNumber,
        name: getTemplateDetails1[0]?.tempName,
        format: getTemplateDetails1[0]?.tempFormat,
        tempType: getTemplateDetails1[0]?.tempType,
      };
      const data: any = {}
      setTemplateText(getTemplateDetails1[0]?.tempFormat)
      const dynamicValue = getTemplateDetails1[0]?.dynamicValues
      for (let index = 0; index < dynamicValue.length; index++) {
        const element = dynamicValue[index];
        data[`${index + 1}`] = element
        fetchedData[`dynamicValue${index + 1}`] = element
      }
      setDynamicTemplateValues(data)
      const quickReplieData = quickReplies
      const buttons = getTemplateDetails1[0]?.buttons?.buttons?.length ? getTemplateDetails1[0]?.buttons["buttons"] : []
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index].text;
        fetchedData[`inputButton${index + 1}`] = element
        quickReplieData[`${index}`].text = element
        setQuickReplies(quickReplieData)
      }
      form.setFieldsValue(fetchedData);
    }
  }, [getTemplateDetails1])


  useEffect(() => {
    if (templateCreateSuccess) {
      navigate("/templates")
    }
  }, [templateCreateSuccess])


  const plainOptions = ['None', 'Call to Actions', 'Quick Replies'];


  const options = [
    { label: 'None', value: 'None' },
    { label: 'Call to Actions', value: 'Call to Actions' },
    { label: 'Quick Replies', value: 'Quick Replies' },
  ];

  const handleInputChange1 = (e: any) => {
    const lowercaseValue = e.target.value
    setValue(lowercaseValue);
  };

  const [clients, setClients] = useState<any>()
  const { userProfile } = useProfile()
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/templates")
  }

  useEffect(() => {
    dispatch(getTemplateClientList("dropdown"));
    }, [])

  useEffect(() => {
    if (tempClientlist.length) {
      setClients(tempClientlist)
      let listName = tempClientlist?.findIndex((item: any) => (item.clientId == selectedValue))
      setSelectedNumber(tempClientlist[listName]?.me?.user)
      setAllocatedNumberList([tempClientlist[0].clientId])
    }
  }, [tempClientlist])

  useEffect(() => {
    if (isInvited) {
      navigateTo()
    }
  }, [isInvited])

  useEffect(() => {
    if (isUpdateUser) {
      navigateTo()
    }
  }, [isUpdateUser])

  const onFinish = (values: FormValues) => {
    const payLoad: any = {
      tempCategory: values.categories,
      tempFormat: values.format,
      tempName: value ? value : values.name,
      parentId: userProfile?.parentId ? userProfile?.parentId : userProfile?.userId,
      clientId: selectedValue ? selectedValue : clients[0]?.clientId,
      fileData: fileData,
      selectedType: tempType,
      selectedNumber: selectedNumber,
      editTempId: templateId,
      dynamicValue1: values.dynamicValue1,
      dynamicValue2: values.dynamicValue2,
      dynamicValue3: values.dynamicValue3
    };

    const arrayOfButtons = quickReplies.filter((reply: any) => reply.text.trim().length > 0);
    if (arrayOfButtons.length) {
      payLoad.buttons = JSON.stringify({ type: "BUTTONS", buttons: (arrayOfButtons) }); // Add buttons directly to the payload
    }

    if (templateId && typeof templateId === "string") {
      dispatch(templateUpdate(payLoad));
      setIsSubmitted(true);
    } else {
      if (tempType === "media") {
        if (!fileData) {
          showErrorNotification("Please upload media/image");
        } else if (fileData.size >= 1024 * 1024 * 15) {
          showErrorNotification("File size should be less than 15 MB");
        } else if (fileData.type === "video/avi") {
          showErrorNotification("File type should be mp4");
        } else {
          dispatch(templateCreate(payLoad));
          setIsSubmitted(true);
        }
      } else {
        dispatch(templateCreate(payLoad));
        setIsSubmitted(true);
      }
    }
  };


  const onFinishFailed = ({ errorFields }: any) => {
    const failedField = errorFields[0].name[0];
    const fieldErrors = form.getFieldsError([failedField]);
    const errorMessage = fieldErrors[0].errors[0];
    showErrorNotification(errorMessage);
  };
  const content = (
    <div>
      <p>Utility: Helping with requests, updates on transactions etc.</p>
      <p>Marketing: Promotional or informative updates, as well as invitations for customers to take action.</p>
      <p>Authentication: Authenticate users using one-time passcodes, which may be required in a multi-step login </p>
    </div>
  )

  useEffect(() => {
    if (selectedValue && clients?.length > 0) {
      let listName = clients?.findIndex((item: any) => (item.clientId == selectedValue))
      if(listName !== -1 && clients){
        setTempSelectedNumber(clients[listName])
      }
    }
  }, [clients?.length])

  const getName = (tempClientlist: any, selectedValue: string) => {
    let listName = tempClientlist?.findIndex((item: any) => (item.clientId == selectedValue))
    if (tempClientlist[listName]?.me?.user != undefined) {
      // setSelectedNumber(tempClientlist[listName]?.me?.user)
    }
    if (listName !== -1) {
      return (
        <>
          <div className='d-flex align-items-center'>
            <span className="text-truncate wpLinkedUserName me-1">
              {tempClientlist[listName]?.pushname}
            </span>
            <span className="text-muted wpLinkedUserNumber">
              ( +{tempClientlist[listName]?.me?.user} )
            </span>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='d-flex align-items-center'>
            <span className="text-truncate wpLinkedUserName me-1">
              {tempClientlist[0]?.pushname}
            </span>
            <span className="text-muted wpLinkedUserNumber">
              ( +{tempClientlist[0]?.me?.user} )
            </span>
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (userProfile?.clientId !== "all_numbers") setSelectedValue(userProfile?.clientId)
  }, [])

  const handleInputChange = (value: any) => {
    if (value !== client_id) {
      setSelectedValue(value);
      if (!loading) dispatch(setClientId(value))
      dispatch(getDirectMessages(value, 0, 50, "Unresolved Chats", null))
      dispatch(getTemplateClientList("dropdown"));
    }
  };

  const onFormatInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setTemplateText(inputValue)
    const showInputs = [false, false, false];
    for (let i = 0; i < 3; i++) {
      if (inputValue.includes(`{{${i + 1}}}`)) {
        showInputs[i] = true;
      }
    }
    setShowDynamicInputs(showInputs);
  };

  const goToMainPage = () => {
    window.history.back();
  }

  const formatWithDynamicValue = (templateText: string | any, values: any) => {
    values = Object.values(values)
    let formattedTempFormatCopy = templateText;
    values.length && values.forEach((value: any, index: number) => {
      const placeholder = `{{${index + 1}}}`;
      formattedTempFormatCopy = formattedTempFormatCopy.replace(placeholder, value);
    });
    return formattedTempFormatCopy
  }

  const setDynamicValueInObject = (text: string | any, key: number | any) => {
    const data = { ...dynamicTemplateValues }
    data[`${key}`] = text
    setDynamicTemplateValues(data)
  }

  const changeButtonText = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setQuickReplies((prevState: any) => {
      let newData = [...prevState];

      switch (field) {
        case "button-1":
          newData[0] = { ...newData[0], text: event.target.value };
          break;
        case "button-2":
          newData[1] = { ...newData[1], text: event.target.value };
          break;
        case "button-3":
          newData[2] = { ...newData[2], text: event.target.value };
          break;
        default:
          break;
      }
      return newData;
    });
  };
  const validateInput = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      // Validation failed
    }
  };

  const handleMediaChange = (e: RadioChangeEvent) => {
    setMediaTypeValue(e.target.value);
    setImageUrl(null)
    setVideoUrl(null)
    setFileData(null)
  }

  const handleTempTypeChange = (value: string) => {
    setTempType(value)
  };

  const beforeUpload = (file: any) => {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      setErrorMessage('Maximum file size allowed is 15 MB.')
      return false;
    } else {
      setErrorMessage("")
      return true;
    }
  };

  const handleRemove = () => {
    setImageUrl(null);
    setVideoUrl(null);
  }

  return (
    <>
      <UserAddFormWrapper className='add_templates_form user-chat user-chat-show pb-4'>
        <div className='userAddFormContainer'>
          <div className="layout-wrapper d-lg-flex MainEmailForm">
            <div className='userAddFormMain'>
              <Form
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className='EmailFormAdd addtemplet_form'
                requiredMark={false}
                disabled={viewTempltState}
              >
                {/* <h2 className='temp_page_title'>Templates</h2> */}
                <div className='d-flex align-items-center gap-2 mb-4 px-4 ms-1'>
                  <Button
                    className='btn btn-sm p-1 d-flex align-items-center justify-content-center btn-secondory pageBackBtn'
                    onClick={goToMainPage}><i className='bx bx-chevron-left font-size-20'></i></Button>
                  <h3 className='text-center mb-0 font-size-18 text-dark'>{editTempltState ? "Edit" : viewTempltState ? "View" : "Create"} Template</h3>
                </div>
                <Row>
                  <Col md={24} lg={16} xl={16} className='form_col_span'>
                    <Form.Item
                      label="Select Number"
                      name="selectNumber"
                    >
                      <Dropdown className='userNewDropdown createTemplateNbListDrpDwp w-100' onSelect={handleInputChange}>
                        <Dropdown.Toggle disabled={viewTempltState} variant="success" className='text_color_dropdown text-truncate bg-white w-100' id="dropdown-basic">
                          {getName(tempClientlist, selectedValue)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {/* <span className='dotactive'></span> */}
                          {tempClientlist.map((item: any) => (
                            <Dropdown.Item key={item.clientId} eventKey={item.clientId} className={"custom-option"}>
                              <div className='d-flex align-items-center'>
                                <span className={`mx-2 mb-1 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                  {/* <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo'/> */}
                                  {item.type === "business_whatsapp" ? (
                                    <img src={whatsappBusinessLogo} width={16} height={16} alt="controllhippo Whatsapp Business Logo" />
                                  ) : item.type === "whatsapp" ? (
                                    <img src={whatsappLogo} width={16} height={16} alt="controllhippo Whatsapp Logo" />
                                  ) : item.type === "telegram" ? (
                                    <img src={telegramLogo} width={16} height={16} alt="controllhippo Telegram Logo" />
                                  ) : item.type === "messenger" ? (
                                    <img src={messengerLogo} width={16} height={16} alt="controllhippo Messenger Logo" />
                                  ) : item.type === "instagram" ? (
                                    <img src={instagramLogo} width={16} height={16} alt="controllhippo Instagram Logo" />
                                  ) : null}

                                </span>
                                <span className={`text-truncate dropItemUserName me-1`}>
                                  {item?.pushname}
                                </span>
                                <span className="text-muted dropItemUserNumber">
                                  ( +{item.me?.user} )
                                </span>
                              </div>
                            </Dropdown.Item>
                          ))
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Item>
                    <Form.Item
                      label={<Popover content={content} placement="right" className="my-popover-class" overlayClassName='tooltip_category'>
                        Template Category <i className="bx bxs-info-circle"></i>
                      </Popover>}
                      name="categories"
                      className='temp_category_element'
                      rules={[{ required: true, message: 'Please select message categories!' }]}
                      // tooltip={{ title: content, placement: 'right' }}
                      help={<div className='mb-3 mt-2 font-size-13'>Your template should fall under one of these categories.</div>}
                    >

                      <Select placeholder="Select message categories" className='temp_category_dropdown'>
                        <Option value="utility">UTILITY</Option>
                        <Option value="marketing">MARKETING</Option>
                      </Select>
                    </Form.Item>
                    {tempSelectedNumber.isWhatsappBusiness &&
                      <Form.Item
                        label="Template Type"
                        name="tempType"
                        className='temp_type_element'
                        rules={[{ required: true, message: 'Please select Template type!' }]}
                      // tooltip={{ title: content, placement: 'right' }}
                      // help={<div className='mb-3 mt-2 font-size-13'>Your template type should fall under one of these types.</div>}
                      >
                        <Select placeholder="Select Tempalte Type" onChange={handleTempTypeChange} className='temp_type_dropdown'>
                          <Option value="text">Text</Option>
                          <Option value="media">Media</Option>
                        </Select>
                      </Form.Item>
                    }
                    {
                      tempType == 'media' &&
                      <>
                        <Radio.Group onChange={handleMediaChange} value={mediaTypeValue} className='mb-2 ps-3 formRadioGroup'>
                          <Radio value={'image'}>Image</Radio>
                          <Radio value={'video'}>Video</Radio>
                        </Radio.Group>
                        <Form.Item
                          label=""
                          name="tempTypeMedia"
                          className='temp_type_media_element ps-3'
                          // rules={[{ required: true, message: 'Please Upload Template Media!' }]}
                        >
                          <Upload
                            action="https://api.controlhippo.com/server-webhook"
                            maxCount={1}
                            onRemove={handleRemove}
                            beforeUpload={beforeUpload}
                            listType="picture"
                            accept={mediaTypeValue === 'image' ? "image/png, image/jpeg ,image/jpg" : "video/*"}
                            className="tempUpload"
                            onChange={(info) => { setFileData(info.fileList[0]) }}
                          >
                            <AntButton className='antBotton' icon={<UploadOutlined />}>Upload</AntButton>
                          </Upload>
                          <div className='mt-1 font-size-13 customError'>{errorMessage ? errorMessage : ""}</div>
                        </Form.Item>
                      </>
                    }
                    <Form.Item
                      label="Template Name"
                      name="name"
                      rules={[
                        { required: true, message: 'Please enter template name!' },
                        {
                          pattern: /^[a-z0-9_]+$/,
                          message: 'Name can only contain lowercase alphanumeric characters and underscores. Special characters and white-space not allowed e.g. - app_verification_code',
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.includes(' ')) {
                              return Promise.reject('Spaces between words are not allowed.');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      help={
                        <div className='mb-3 mt-1 font-size-13'>
                          Name can only be in lowercase alphanumeric characters and underscores. Special characters and white-space are not allowed <br /> e.g., - app_verification_code
                        </div>
                      }
                    >
                      <Input
                        className='temp_name_input'
                        placeholder="Enter name here"
                        value={value}
                        onInput={handleInputChange1}
                        disabled={templateId && typeof templateId === 'string'}
                      />
                    </Form.Item>
                    <Form.Item
                      name="format"
                      label="Template Format"
                      rules={[{ required: true, message: 'Please input Template Format' }]}
                      // help={
                      // <div className='font-size-13 mt-3 mb-4'>Use text formatting - *bold* , _italic_ & ~strikethrough~
                      //   Your message content. Upto 1024 characters are allowed.
                      //   <div>e.g. -  Hello {`{{1}}`}, your code will expire in {`{{2}}`} mins.</div></div>}
                      help={
                        <div className='font-size-13 mt-1 mb-3'>e.g. -  Hello {`{{1}}`}, your code will expire in {`{{2}}`} mins.</div>}
                    >
                      <Input.TextArea
                        onChange={(e: any) => onFormatInputChange(e)}
                        autoSize={{ minRows: 3, maxRows: 6 }}
                        showCount
                        maxLength={1000}
                        placeholder="Enter your message here..."
                      />
                    </Form.Item>
                    {showDynamicInputs.map((showInput, index) =>
                      showInput && (
                        <Form.Item
                          key={index}
                          name={`dynamicValue${index + 1}`}
                          label={`Dynamic Input ${index + 1}`}
                          rules={[{ required: true, message: `Please enter dynamic input ${index + 1}` }]}
                        >
                          <Input placeholder={`Enter dynamic input ${index + 1}...`} onChange={(e: any) => setDynamicValueInObject(e.target.value, index + 1)} />
                        </Form.Item>
                      )
                    )}
                    {selectedValue && (tempClientlist || []).find((item: any) => item.clientId == selectedValue)?.type == "business_whatsapp" && <div className='templatebUttons'>
                      <label><strong className='tempBtnLbl'>Buttons</strong><span className='text-secondary fw-normal'> (Optional)</span></label>
                      <div className='d-flex gap-md-3 align-items-center w-100 templatebUttonsBody'>
                        <Form.Item
                          className=''
                          name="inputButton1"
                          rules={[{ max: 20, message: 'Input must be at most 20 characters' }]}
                          help={<div className='font-size-13 mt-1 mb-4'>Input must be at most 20 characters. <br />e.g. -  Confirm</div>}
                        >
                          <Input placeholder='Enter button text 1' onChange={(event) => changeButtonText(event, "button-1")} />
                        </Form.Item>
                        <Form.Item
                          className=''
                          name="inputButton2"
                          rules={[{ max: 20, message: 'Input must be at most 20 characters' }]}
                          help={<div className='font-size-13 mt-1 mb-4'>Input must be at most 20 characters. <br />e.g. -  Cancel</div>}
                        >
                          <Input placeholder='Enter button text 3' onChange={(event) => changeButtonText(event, "button-2")} />
                        </Form.Item>
                        <Form.Item
                          className=''
                          name="inputButton3"
                          rules={[{ max: 20, message: 'Input must be at most 20 characters' }]}
                          help={<div className='font-size-13 mt-1 mb-4'>Input must be at most 20 characters. <br />e.g. -  Not Sure</div>}
                        >
                          <Input placeholder='Enter button text 3' onChange={(event) => changeButtonText(event, "button-3")} />
                        </Form.Item>
                      </div>
                    </div>}
                    {!viewTempltState && <div className='d-flex align-items-center justify-content-lg-end justify-content-center mt-2 me-lg-5'>
                      <Form.Item className='mb-0'>
                        <Space>
                          <Button className='submitbuttonTemp' color='primary' onClick={validateInput} type="submit" disabled={isSubmitted}>
                            {isSubmitted ? 'Submitted' : 'Submit'}
                          </Button>
                          <Button className='canclebtnTemp' color='danger' outline onClick={() => navigateTo()}>
                            Cancel
                          </Button>
                        </Space>
                      </Form.Item>
                    </div>}
                  </Col>
                  <Col md={24} lg={8} xl={8} className='form_col_span'>
                    <h4 className='text-center previewTitle mb-3 font-size-17 text-dark'>Preview</h4>
                    <div className='tempPreviewDiv'>
                      <img className='previewImg' src={templatePreview} alt='Template Preview - Controllhippo' height={`100%`} width={`100%`} />
                      <div className='tempPreviewBody'>
                        <div className='previewTextMain'>
                          <div className='previewTextBody'>
                            {tempType == 'media' &&
                              <div className='text-center'>
                                {mediaTypeValue === 'image' && imageUrl && <img src={imageUrl} height={120} className='my-1' width={`100%`} />}
                                {mediaTypeValue === 'video' && videoUrl && (
                                  <video controls width={200} height={110} className='my-2'>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                            }
                            <p className='previewText text-break'>
                              {templateText !== "" ? formatWithDynamicValue(templateText, dynamicTemplateValues) : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it."}
                            </p>
                            <div className='tempPrevBtnsMain d-flex flex-wrap align-items-center gap-2'>
                              {quickReplies.map((item: any) => {
                                if (item?.text?.length) {
                                  const capitalizeFirstLetter = (str: string) => {
                                    return str.charAt(0).toUpperCase() + str.slice(1);
                                  };
                                  return (<Button type='button' className='tempPrevBtns text-truncate bg-light p-2 font-size-14'>
                                    {capitalizeFirstLetter(item.text)}
                                  </Button>)
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </UserAddFormWrapper>
      {loading1 && <Loader />}
    </>
  );
};

export default UserAdd;
