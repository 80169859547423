import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ProfileActionTypes } from "./types";
import { profileApiResponseSuccess, profileApiResponseError } from "./actions";

import { getProfileDetails as getProfileDetailsApi } from "../../api/index";
import { authLoginApiResponseSuccess } from "../actions";
import { AuthLoginActionTypes } from "../types";

function* getProfileDetails(userId:any): Generator<any, void, any> {
  try {
    const response: any = yield call(getProfileDetailsApi,userId);
    yield put(
      profileApiResponseSuccess(
        ProfileActionTypes.GET_PROFILE_DETAILS,
        response
      )
    );
    const authData = response?.response;
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, {authData}));
  } catch (error: any) {
    yield put(
      profileApiResponseError(ProfileActionTypes.GET_PROFILE_DETAILS, error)
    );
  }
}

export function* watchGetProfileDetails() {
  yield takeEvery(ProfileActionTypes.GET_PROFILE_DETAILS, getProfileDetails);
}

function* profileSaga() {
  yield all([fork(watchGetProfileDetails)]);
}

export default profileSaga;
