import React from 'react';
import CreatableSelect from 'react-select/creatable';

interface CustomSelectProps {
    value: any;
    onChange: (newValue: any, actionMeta: any) => void;
    options: { name: string; displayName: string;_id: string }[];
    isMulti?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ value, onChange, options, isMulti = false }) => {
    return (
        <CreatableSelect
            options={options}
            value={value}
            onChange={onChange}
            isMulti={isMulti}
            placeholder={"Select Label"}
        // You can add other props and styles as needed
        />
    );
};

export default CustomSelect;
