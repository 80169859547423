/*
pages
*/
//root
import Root from "../pages/Root/index";
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import RecoverPassword from "../pages/Authentication/RecoverPassword";
import LockScreen from "../pages/Authentication/LockScreen";
import Confirmaccount from "../pages/Authentication/ConfirmAccount";
import ResetPassword from "../pages/Authentication/ResetPassword";
import UpdateProfile from "../pages/Authentication/UpdateProfile";
import SubUserSetPwd from "../pages/Authentication/subUserSetPassword";
import ChangeEmail from "../pages/Authentication/changeEmail";

// dashboard
import Dashboard from "../pages/Dashboard/index";
import StarterPage from "../pages/StarterPage/index";
import Channels from "../pages/Channels/index";
import Whatsapp from "../pages/Channels/Whatsapp/Whatsapp";
import BusinessWhatsappApi from "../pages/Channels/BusinessWhatsapp/BusinessWhatsappApi";
import Telegram from "../pages/Channels/Telegram/Telegram";
import Messenger from "../pages/Channels/Messenger/Messenger";
import Email from "../pages/Channels/Email/Email";
import Instagram from "../pages/Channels/Instagram/Instagram";
import Chatbot from "../pages/Channels/Chatbot/Chatbot";
import Users from "../pages/Users/userList";
import Template from "../pages/Templates/tempLates";
import TeamplateCreate from "../pages/Templates/createTemplate";
import TeamplateView from "../pages/Templates/viewTemplate";
import Campaign from "../pages/Campaign";
import CampaignCreate from "../pages/Campaign/createCampaign";
import CampaignEdit from "../pages/Campaign/editCampaign";
import CampaignView from "../pages/Campaign/viewCampaign";
import UserAdd from "../pages/Users/addUser";
import UserEdit from "../pages/Users/editUser";
import NumberEdit from "../pages/Numbers/editNumber";
import IntegrationsPage from "../pages/Integrations/index";
import NumbersPage from "../pages/Numbers/index";
import DummyDashboard from "../pages/Dashboard/DummyDashboard";
import PlanBilling from "../pages/PlanBilling/index";
import AccountSetting from "../pages/AccountSetting/index";
import Reports from "../pages/Reports/index";
import Contacts from "../pages/Contacts";
import AccountCancle from "../pages/PlanBilling/AccountCancle";
import Profile from "../pages/Profile/index";
import ReConnectTelegram from "../pages/Channels/Telegram/ReConnectTelegram";
import ReConnectWhatsapp from "../pages/Channels/Whatsapp/ReConnectWhatsapp";
import ReConnectMessenger from "../pages/Channels/Messenger/ReConnectMessenger";
import ReConnectInstagram from "../pages/Channels/Instagram/ReConnectInstagram";
import SettingProfile from "../components/Settingpage/index";
import PersonalInformation from "../pages/Profile/PersonalInformation";
import ChangePassword from "../pages/Profile/ChangePassword";
interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Login /> },
  { path: "/auth-register", component: <Register /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  // { path: "/logout", component: <Logout /> },
  { path: "/confirmaccount/:hash/:i/:c", component: <Confirmaccount /> },
  { path: "/reset/:hash/:i/:c", component: <ResetPassword /> },
  { path: "/changeemail/:hash/:i/:c", component: <ChangeEmail /> },
  { path: "/register/:hash/:i/:c", component: <SubUserSetPwd /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/user-add", component: <UserAdd editUserId={false}/> },
  { path: "/template-Create", component: <TeamplateCreate templateId={false}/> },
  { path: "/update-profile", component: <UpdateProfile /> },
  // { path: "/template-edit/:id", component: <TeamplateEdit /> },
  { path: "/template-view/:id", component: <TeamplateView /> },
  { path: "/user-edit/:id", component: <UserEdit /> },
  { path: "/number-edit/:id", component: <NumberEdit /> },
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/welcome", component: <Channels /> },
  { path: "/channels/messenger", component: <Messenger editNumber={false} numberData={null}/> },
  { path: "/channels/email", component: <Email editNumber={false} numberData={null}/> },
  { path: "/channels/reconnect-messenger/:id", component: <ReConnectMessenger /> },
  { path: "/channels/instagram", component: <Instagram editNumber={false} numberData={null}/> },
  { path: "/channels/reconnect-instagram/:id", component: <ReConnectInstagram /> },
  { path: "/channels/chatbot", component: <Chatbot editNumber={false} numberData={null}/> },
  { path: "/channels/whatsapp", component: <Whatsapp editNumber={null} numberData={null}/> },
  { path: "/channels/reconnect-whatsapp/:id", component: <ReConnectWhatsapp /> },
  { path: "/channels/business-whatsapp-api", component: <BusinessWhatsappApi /> },
  { path: "/channels/telegram", component: <Telegram editNumber={false} numberData={null} /> },
  { path: "/channels/reconnect-telegram/:id", component: <ReConnectTelegram /> },
  { path: "/user-list", component: <Users /> },
  { path: "/templates", component: <Template /> },
  { path: "/campaign-list", component: <Campaign /> },
  { path: "/campaign/:id", component: <CampaignView /> },
  { path: "/campaign-create", component: <CampaignCreate campaignId={false}/> },
  { path: "/campaign-edit/:id", component: <CampaignEdit /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/integrations", component: <IntegrationsPage /> },
  { path: "/numbers", component: <NumbersPage /> },
  {path : "/plan-billing", component: <PlanBilling />},
  {path : "/profile", component: <Profile />},
  {path : "/account-setting", component: <AccountSetting />},
  {path : "/personal-information", component: <PersonalInformation />},
  {path : "/change-password", component: <ChangePassword />},
  {path : "/reports", component: <Reports />},
  {path : "/contacts", component: <Contacts />},
  {path: "/dummyDashboard",component: <DummyDashboard /> },
  {path: "/cancle-account",component: <AccountCancle /> },
  {path : "/setting/*", component: <SettingProfile />},
  { path: "/",  component: <Root /> },
];


export { publicRoutes, privateRoutes };
