import React, { useState,useEffect,useRef } from 'react';
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,Alert, Input, UncontrolledTooltip } from 'reactstrap';
import { Tabs,Tab } from "react-bootstrap";
import { Switch} from "antd";
import { useRedux, useProfile } from "../../hooks";
import { removeSlackChannel, addBoardId } from "../../redux/actions";
import { PrivacyTypes } from '../../data/settings';
import SelectMenu from "../../components/Select";
import { Link, useNavigate } from 'react-router-dom';
interface SettingPopupProps {
    integrationName:any;
    slackData?:any;
    userDetails:any;
    onConnectClick:(name:string)=>void;
    onChangeSettings: (field: string, value: any) => void;
    changeIntegrationSetting : (name:string,data: any) => void;
    buttonRef?:any;
    integrationData?:any;
}



const SettingPopup = ({buttonRef,slackData,onConnectClick ,integrationName,onChangeSettings,integrationData,changeIntegrationSetting}:SettingPopupProps) => {
    const { dispatch, useAppSelector } = useRedux();
    const navigate = useNavigate();
    const {
        userData,
    } = useAppSelector(state => ({
        userData: state.Login.user,
    }));
    const [modal, setModal] = useState<boolean>(false);
    const [conformationModal, setConformationModal] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [removeChannelData, setRemoveChannelData] = useState<any>(false);
    const [userDetails, setUserDetails] = useState(userData)
    const [privacy, setPrivacy] = useState(userDetails.privacy)
    const toggle = (event: any) => {
         setModal(!modal)
        };
    const toggle2 = () => setConformationModal(!conformationModal);
    const [activeTab, setActiveTab] = useState<string | null>("functionalities");
    const [inputValueState, setInputValueState] = useState('');
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [integrationDetails, setIntegrationDetails] = useState(integrationData)
    const {
        integrationUpdate
    } = useAppSelector(state => ({
        integrationUpdate: state.Integrations.uploadPayload,
    }));
    
    useEffect(() => {
        if (integrationUpdate?.success && integrationUpdate?.integrations  ) {
            const integrationsList = integrationUpdate?.integrations
            setIntegrationDetails((integrationsList|| []).filter((integration: any) => integration.name == integrationName)[0])
        } 
    }, [integrationUpdate])
    useEffect(() => {
        setUserDetails(userData)
        setPrivacy(userData.privacy)
    }, [userData])
    
    const handleTabChange = (key: string | null) => {
        setActiveTab(key);
    };
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    // const handleDropdownSelect = (value: string) => {
    //     setSelectedValue(value);
    // };
    // const addChannel = () => {
    //     if (!selectedChannels.includes(selectedValue)) {
    //         setSelectedChannels((prevChannels) => [...prevChannels, selectedValue]);
    //     }
    // };

    const handleInputChange = (event:any, integrationName: any) => {
        const inputValue = event.target.value;
        setInputValueState(inputValue)
        if(integrationName == "zoho"){
            setIntegrationDetails({
                ...integrationDetails,
                advance_integration_custom_field: inputValue
              });
              setSaveEnabled(inputValue.trim() !== '');
        }
        if(integrationName == "monday"){
            setIntegrationDetails({
                ...integrationDetails,
                boardId: inputValue
              });
              setSaveEnabled(inputValue.trim() !== '');
        }
       

      };

      const handleSaveClick = (integrationName:any) => {
        var payLoadData
        if(integrationName == "zoho"){
            payLoadData = {
                advance_integration_custom_field: inputValueState,
                integrationName: integrationName
            }  
        }
        if(integrationName == "monday"){
            payLoadData = {
                boardId: inputValueState,
                integrationName: integrationName
            }
        }
        dispatch(addBoardId(payLoadData,(response :any)=>{
            if(response.success && response.integrationName == "monday"){
                window.location.href = "https://monday.com";
            }
          }))
        setModal(false)
        navigate("/setting/integrations")
      };
    const conformRemoveChannel = (channel: any,index:number) => {
        toggle2()
        if(integrationData && integrationData?.slack_webhook_list && integrationData?.slack_webhook_list.length){
            const teamData :any =   integrationData?.slack_webhook_list[index]
            const data:any  = {
                "channel": channel.channel,
                "channel_id": channel.channel_id,
                "team_id": teamData.team_id,
                "team_name": teamData.team_name
            }
            setRemoveChannelData(data)
        }
    };

    const removeChannel=()=>{
        if(removeChannelData){
            dispatch(removeSlackChannel(removeChannelData))
            setTimeout(() => {                
                toggle2()
                setRemoveChannelData(false)
            }, 1000);
        }
    }
    const addSlackChanel = () =>{

    }
    const dropdownItems = ['All', 'Incoming', 'Outgoing'];
    const [data, setData] = useState<PrivacyTypes>({
        isDisablePersonalChatSync: false,
    });
    const onChangeData = (
        field:
            | "isDisablePersonalChatSync"
            | "displayprofilePhoto"
            | "displayLastSeen"
            | "displayStatus"
            | "readReceipts"
            | "displayGroups",
        value: string | boolean
    ) => {
        let modifiedData: any = { ...data };
        modifiedData[field] = value;
        setData(modifiedData);
        onChangeSettings("privacy", modifiedData);
    };

    const changeIntegration = (integrationName:string, event:string,value:boolean, event2: string, advance_integration?: any) => {
        let data :any= {functionality:{}}
        if(advance_integration == undefined){
            data.functionality[event] = value
            data.functionality[event2] = !value
        }else{
            data.functionality[advance_integration] = value
        }
        changeIntegrationSetting(integrationName, data);
    }
    return (
        <>
            <div className='integrationSetting'>
                <a ref={buttonRef} className='settingButton' onClick={toggle}>
                    <i className='bx bxs-cog'></i>
                </a>
            </div>

            <Modal className='integration_setting_modal' isOpen={modal} size='md' toggle={(e: any)=>{toggle(e)}} centered>
                <ModalHeader toggle={toggle}>Integration Settings</ModalHeader>
                <ModalBody>
                    <Tabs
                        defaultActiveKey={(integrationName == "hubspot" || integrationName == "slack" || integrationName == "zoho" || integrationName == "monday" || integrationName == "pipedrive" || integrationName == "bitrix" || integrationName == "salesforce") ? "functionalities" : "customization"}
                        // activeKey={activeTab}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={handleTabChange}
                    >
                        {(integrationName == "hubspot" || integrationName == "slack" || integrationName == "zoho" || integrationName == "monday" || integrationName == "pipedrive" || integrationName == "bitrix" || integrationName == "salesforce") &&
                            <Tab eventKey="functionalities" title="Functionalities">
                                <div>
                                    {(integrationName == "hubspot" || integrationName == "zoho" || integrationName == "pipedrive") &&
                                        <div className="align-items-center settingIntegrationDropdown mt-2">
                                            <div className="d-flex gap-2 align-items-center mb-1">
                                                <Switch
                                                    className="ch_toggle_switch"
                                                    checked={integrationDetails?.functionality?.contactSync}
                                                    onChange={(checked: boolean) => {
                                                        changeIntegration(integrationName, 'contactSync', checked, '');
                                                    }}
                                                />
                                                <h6 className="mb-0 font-size-13 text-dark">Create new contact automatically</h6>
                                            </div>
                                        </div>}


                                        {integrationName == "hubspot" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When the toggle is enabled, new contacts will be automatically created in HubSpot, with their activities synced. Additionally, existing contacts in HubSpot will continue to have their activities synced.</li>
                                            <li>When the toggle is disabled, new contacts will not be automatically created in HubSpot. However, activities will continue to be synced with existing contacts in HubSpot.</li>
                                        </ul>
                                    </div>}
                                        {integrationName == "pipedrive" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new contacts in Pipedrive, while continuing to sync activities for existing contacts.</li>
                                            <li>When disabled, new contacts won't be automatically created, but activities will still sync for existing contacts.</li>
                                        </ul>
                                    </div>}
                                        {integrationName == "zoho" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new contacts in Zoho, while continuing to sync activities for existing contacts.</li>
                                            <li>When disabled, new contacts won't be automatically created, but activities will still sync for existing contacts.</li>
                                        </ul>
                                    </div>}

                                    {(integrationName == "pipedrive" || integrationName == "bitrix") &&
                                        <div className="align-items-center settingIntegrationDropdown mt-3">
                                            <div className="d-flex gap-2 align-items-center mb-1">
                                                <Switch
                                                    className="ch_toggle_switch"
                                                    checked={integrationDetails?.functionality?.dealSync}
                                                    onChange={(checked: boolean) => {
                                                        changeIntegration(integrationName, 'dealSync', checked, integrationName !== "pipedrive" ? 'leadSync' : "");
                                                    }}
                                                />
                                                <h6 className="mb-0 font-size-13 text-dark">Create new deal automatically</h6>
                                            </div>
                                        </div>
                                    }

                                    {integrationName == "pipedrive" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new Deals in Pipedrive, while continuing to sync activities for existing contacts.</li>
                                            <li>When disabled, new Deals won't be automatically created, but activities will still sync for existing contacts.</li>
                                        </ul>
                                    </div>}
                                    {integrationName == "bitrix" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new  Deals in Bitrix24 along with contact, while continuing to sync activities for existing Deals.</li>
                                            <li>When disabled, new Deals won't be automatically created, but activities will still sync for existing Deals.</li>
                                        </ul>
                                    </div>}
                                    {(integrationName == "zoho" || integrationName == "salesforce" || integrationName == "bitrix" || integrationName == "pipedrive") &&
                                        <div className="align-items-center settingIntegrationDropdown mt-3">
                                            <div className="d-flex gap-2 align-items-center mb-1">
                                                <Switch
                                                    className="ch_toggle_switch"
                                                    checked={integrationDetails?.functionality?.leadSync}
                                                    onChange={(checked: boolean) => {
                                                        changeIntegration(integrationName, 'leadSync', checked, integrationName == "bitrix" ? 'dealSync' : '');
                                                    }}
                                                />
                                                <h6 className="mb-0 font-size-13 text-dark">Create new lead automatically</h6>
                                            </div>
                                        </div>
                                    }
                                    {integrationName == "zoho" && <div>
                                        <ul className='listSettingPopup mb-3'>
                                            <li>When enabled, the toggle automatically creates and syncs new Leads in Zoho, while continuing to sync activities for existing contacts.</li>
                                            <li>When disabled, new Leads won't be automatically created, but activities will still sync for existing contacts.</li>
                                        </ul>
                                    </div>}
                                    {integrationName == "bitrix" && <div>
                                        <ul className='listSettingPopup mb-3'>
                                            <li>When enabled, the toggle automatically creates and syncs new Leads in Bitrix24, while continuing to sync activities for existing Leads.</li>
                                            <li>When disabled, new Leads won't be automatically created, but activities will still sync for existing Leads.</li>
                                        </ul>
                                    </div>}
                                    {integrationName == "salesforce" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new Leads in Salesforce, while continuing to sync activities for existing Leads.</li>
                                            <li>When disabled, new Leads won't be automatically created, but activities will still sync for existing Leads.</li>
                                        </ul>
                                    </div>}
                                    {integrationName == "pipedrive" && <div>
                                        <ul className='listSettingPopup mb-3'>
                                            <li>When enabled, the toggle automatically creates and syncs new Leads in Pipedrive, while continuing to sync activities for existing contacts.</li>
                                            <li>When disabled, new Leads won't be automatically created, but activities will still sync for existing contacts.</li>
                                        </ul>
                                    </div>}

                                    {(integrationName == "salesforce") &&
                                        <div className="align-items-center settingIntegrationDropdown mt-3">
                                            <div className="d-flex gap-2 align-items-center mb-1">
                                                <Switch
                                                    className="ch_toggle_switch"
                                                    checked={integrationDetails?.functionality?.accountSetting}
                                                    onChange={(checked: boolean) => {
                                                        changeIntegration(integrationName, 'accountSetting', checked, '');
                                                    }}
                                                />
                                                <h6 className="mb-0 font-size-13 text-dark">Create new Account automatically</h6>
                                            </div>
                                        </div>
                                    }

                                    {integrationName == "salesforce" && <div>
                                        <ul className='listSettingPopup mb-1'>
                                            <li>When enabled, the toggle automatically creates and syncs new Accounts in Salesforce, while continuing to sync activities for existing Account.</li>
                                            <li>When disabled, new Accounts won't be automatically created, but activities will still sync for existing Account.</li>
                                        </ul>
                                    </div>}
                                    {integrationName == "zoho" &&
                                        <div className="d-flex gap-2 align-items-center mb-1">
                                            <Switch
                                                className="ch_toggle_switch"
                                                checked={integrationDetails?.functionality?.advance_integration}
                                                onChange={(checked: boolean) => {
                                                    changeIntegration(integrationName, "", checked, "", "advance_integration");
                                                }}
                                            />
                                            <div id="numberPrivacy1" className="bd-highlight d-flex align-items-center gap-1">
                                                <h6 className="mb-0 font-size-13 text-dark">{`Advance integration`}</h6>
                                                <i className="bx bxs-info-circle font-size-16 lh-sm text-secondary"></i>
                                                <UncontrolledTooltip target={`numberPrivacy1`} placement="right">
                                                    Upon linking multiple WhatsApp numbers, activities are automatically synchronized with their respective WhatsApp numbers in Zoho.
                                                </UncontrolledTooltip>
                                            </div>

                                        </div>
                                    }

                                    {integrationDetails?.functionality?.advance_integration && integrationName == "zoho" &&
                                        <div className="p-0 bd-highlight ms-5">
                                            <p className="mb-1 font-size-13 text-dark">{`Custom field where Whatsapp Number Sync in Zoho : `}</p>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <Input
                                                    placeholder='e.g. :- Department'
                                                    className='flex-1 border border-secondary'
                                                    value={integrationDetails?.advance_integration_custom_field}
                                                    onChange={(e) => { handleInputChange(e, "zoho") }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    
                                    {integrationName == "monday" && <div className="align-items-center settingIntegrationDropdown mt-2">
                                        <div className="d-flex justify-content-between">
                                            <div className="p-2 bd-highlight w-100">
                                                <p className="mb-1 font-size-14 text-dark lh-sm">{`Enter Your Board Id : `}</p>
                                                <Input
                                                    value={integrationDetails?.boardId}
                                                    onChange={(e) => { handleInputChange(e, "monday") }}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    {integrationName == "slack" && <div className='integration_setting_div'>
                                        {integrationDetails?.slack_webhook_list?.length ? integrationDetails.slack_webhook_list.map((channel: any, index: number) => {
                                            return (channel?.incoming_webhook?.length ? channel?.incoming_webhook.map((item: any) => {
                                                return (
                                                    <div>
                                                        <Alert color='primary' key={index} style={{ flex: '0 0 calc(100% / 6)', margin: '5px' }}>
                                                            {(item.channel)}{" "}
                                                            <span
                                                                className='alert-remove icon_cursor'
                                                                onClick={() => conformRemoveChannel(item, index)}
                                                                aria-hidden='true'
                                                            >
                                                                &times;
                                                            </span>
                                                        </Alert>
                                                    </div>)
                                            }) : "")
                                        }) : <Alert color="warning" className='alert_full_width'>
                                            Select channel to get message in slack
                                        </Alert>
                                        }
                                    </div>}
                                </div>
                            </Tab>
                        }
                        <Tab eventKey="customization" title="Customization">
                            <ul className='mb-2 customization_content cstmListSettingPopup'>
                                <li className='mb-2'>Your active service will show up here.</li>
                                <li className='mb-2'>No special customization enabled for your account.</li>
                                <li><a href="mailto:support@controlhippo.com">Talk to our professional services team</a> to share anything that can be achieved via ControlHippo APIs.
                                    QW Specialize in API setup, ControlHippo Automations, custom workflows, complete end-to-end enhancement and a lot more.
                                    We will evaluate your request with our team and get back to you.</li>
                            </ul>
                        </Tab>
                    </Tabs>

                </ModalBody>
               {activeTab == "functionalities" ? <ModalFooter className='d-flex justify-content-end'>
                    {integrationDetails?.functionality?.advance_integration && integrationName == "zoho" || integrationName == "monday" ?
                        <div className="d-flex btnalign-items-center gap-2">
                            <Button outline={true} className='text-dark btn-sm cancleBntonHover' onClick={() => {
                                setModal(false);
                                setActiveTab("functionalities");
                                navigate("/setting/integrations")
                            }}>
                                Cancel
                            </Button>
                            {integrationName == "zoho" &&
                                <Button color='primary' className='btn btn-sm' onClick={()=>{handleSaveClick("zoho")}} disabled={!isSaveEnabled}>
                                    Save
                                </Button>
                            }
                            {integrationName == "monday" &&
                                <Button color='primary' className='btn btn-sm' onClick={() => { handleSaveClick("monday") }} disabled={!isSaveEnabled}>Save</Button>
                            }
                        </div>
                        :
                        <div>
                            <Button className='btn btn-sm px-3' color='primary' onClick={() => {
                                setModal(false);
                                setActiveTab("functionalities");
                                navigate("/setting/integrations")
                            }}>Save</Button>
                        </div>
                    }
                    
                    {integrationName == "slack" && <Button color='primary' onClick={()=>onConnectClick("slack")}>
                        Add Slack Channel
                    </Button>}
                </ModalFooter>:<ModalFooter className='d-flex justify-content-end'>
                <Button className='btn btn-sm px-3' color='primary' onClick={() => {
                        setModal(false);
                        setActiveTab("functionalities");
                        navigate("/setting/integrations")
                    }}>Save</Button>
                    </ModalFooter>}
            </Modal>
            {/* Conformation Popup */}
            <Modal isOpen={conformationModal} toggle={toggle2} centered>
                <ModalBody className='mt-5'>
                    <h6>Are you sure you want to remove this Slack channel?</h6>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" outline onClick={()=>removeChannel()}>
                        Yes
                    </Button>{' '}
                    <Button color="primary" onClick={toggle2}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default SettingPopup;
