import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { useProfile, useRedux } from '../../hooks';
import config from '../../config';
import { clearCustomerPortal, getCustomerPortal } from '../../redux/actions';

interface ChargebeePopupType {
    lable?: string;
    type?: string;
    section: string;
    className?: string | undefined;
    args?:{}
}
const ChargebeePopup = ({ section, className, type, lable ,args }: ChargebeePopupType) => {
    const [loading, setLoading] = useState(false);
    const [sectionAgain, setSectionAgain] = useState("")
    const { userProfile } = useProfile();
    const { dispatch, useAppSelector } = useRedux()
    const parentId = userProfile?.parentId ? userProfile?.parentId : userProfile?.userId;
    const {
        sessionPortal,
    } = useAppSelector(state => ({
        sessionPortal: state.PlanBilling.sessionPortal,
    }))

    useEffect(() => {
        if (sessionPortal?.success) {
            let sessionPortalData = sessionPortal.sessionPortal
            const Chargebee = window.Chargebee;
            const cbInstance = Chargebee.init({
                site: config.CHARGEBEE_SITE_KEY,
                isItemsModel: true,
            })

            // const cbInstance = Chargebee.getInstance();
            const cbPortalInstance = cbInstance.createChargebeePortal();
            // var chargebeePortalInstance = cbInstance.createChargebeePortal();
            cbInstance.setPortalSession(function () {
                return new Promise(function (resolve, reject) {
                    var sampleResponse = {
                        "id": sessionPortalData.id,
                        "token": sessionPortalData.token,
                        "access_url": sessionPortalData.access_url,
                        "status": sessionPortalData.status,
                        "created_at": sessionPortalData.created_at,
                        "expires_at": sessionPortalData.expires_at,
                        "object": sessionPortalData.object,
                        "customer_id": sessionPortalData.customer_id
                    };
                    resolve(sampleResponse);
                });
            });
            if (sectionAgain === "updateCreditCard") {
                setLoading(false)
                console.log("section ::: updateCreditCard")
                cbPortalInstance.open({
                    close: () => {
                        cbInstance.logout();
                        cbInstance.closeAll();
                        window.location.reload();
                    },
                    paymentSourceAdd: () => {
                        cbInstance.logout();
                        cbInstance.closeAll();
                        dispatch(clearCustomerPortal())
                        window.location.reload();
                    },
                    paymentSourceUpdate: () => {
                        cbInstance.logout();
                        cbInstance.closeAll();
                        dispatch(clearCustomerPortal())
                        window.location.reload();
                    },
                }, {
                    sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES
                })
            }
            else if (sectionAgain === "AddbillingAddress") {
                // setAddressGetLoading(true)
                cbPortalInstance.open({
                    close: () => {
                        cbInstance.logout();
                        cbInstance.closeAll();
                        window.location.reload();
                    },
                }, {
                    sectionType: Chargebee.getPortalSections().ADDRESS
                })
            }
        }
    }, [sessionPortal])

    const chargebeeInit = (userId: string) => {
        if (section === "updateCreditCard") setLoading(!loading)
        // else if (section === "AddbillingAddress") setAddressGetLoading(!addressGetLoading)
        // setSection(section)
        setSectionAgain(section)
        dispatch(getCustomerPortal(userId))
    };
    return (
        <>
            {type == "button" ?
                <Button {...args} className={className} onClick={() => chargebeeInit(parentId)} disabled={loading}>
                    {lable}
                </Button> :
                <a href="javascript:void(0);" className={className} onClick={() => chargebeeInit(parentId)}>{lable}</a>
            }
        </>
    )
}

export default ChargebeePopup