import { combineReducers } from "redux";

import ForgetPassword from "./auth/forgetpwd/reducer";
import confirmAccount from "./auth/confirmUserData/reducer";
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import UpdateProfile from "./auth/updateProfileData/reducer";
import Layout from "./layout/reducer";
import Profile from "./profile/reducer";
import Contacts from "./contacts/reducer";
import Calls from "./calls/reducer";
import Bookmarks from "./bookmarks/reducer";
import Settings from "./settings/reducer";
import Chats from "./chats/reducer";
import QrCode from "./clients/reducer";
import updatePassword from "./auth/updatePassword/reducer";
import Integrations from "./integrations/reducer";
import TourVisted from "./TourVisted/reducer";
import changePassword from "./auth/changePassword/reducer";

import User from './userList/reducer'
import Templates from './templateCreate/reducer'
import UpdateUserProfile from "./updateUserProfile/reducer"
import SetSubUserPwd from "./auth/subUserSetPwd/reducer"
import UpdateSubUserPwd from "./auth/updateSubUSerPwd/reducer"
import ChangeEmail from "./auth/changeEmail/reducer"
import PlanBilling from "./PlanBilling/reducer";
import AccountSetting from "./accountSetting/reducer";
import WebSocket from "./websocket/reducer";
import Reports from "./reports/reducer";
import Campaign from  "./campaign/reducer"
export default combineReducers({
  ForgetPassword,
  confirmAccount,
  updatePassword,
  UpdateSubUserPwd,
  Login,
  Register,
  UpdateProfile,
  Layout,
  Profile,
  User,
  Templates,
  Contacts,
  AccountSetting,
  Calls,
  Bookmarks,
  Settings,
  Chats,
  QrCode,
  Integrations,
  changePassword,
  SetSubUserPwd,
  UpdateUserProfile,
  ChangeEmail,
  WebSocket,
  // NumberDelete,
  TourVisted,
  PlanBilling,
  Reports,
  Campaign 
});
