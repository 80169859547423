import { fork, all, call, put, take, select, takeEvery } from "redux-saga/effects";

import { connectSocketAction } from './actions';
import { WebsocketActionTypes } from "./types";

import { io } from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { 
    authLoginApiResponseSuccess,
    chatsApiResponseSuccess,
    socketConnected, 
    qrCodeReceived, 
    qrCodeApiResponseSuccess,
    qrCodeDestroyed, 
    qrCodeApiResponseError,
    socketResponseError,
} from "../actions";

// Types 
import { 
    ChatsActionTypes,
    QrCodeActionTypes,
    AuthLoginActionTypes 
} from "../types";
import config from "../../config";
import { chatUserValidate, newMessageRecived } from "../chats/chat.service";
import { updateAuthData } from "../../hooks";

type SocketEvent = 'connect' | 'disconnect' | 'qr' | 'ready' | 'userStatus' | 'message_create' | 'old_message_sync_complete' | 'old_message_sync_start' |'client_disconnected' | "message_ack" | "update_number_allocation" | 'sync_chat_user' | 'resolved_chat' | 'assign_chat' | 'chat_syncing_start' | 'authenticated' | 'QRCodeDestroy' | 'message_unread' | 'session_resyncing' | 'chargebeeDetails' | 'message_revoke_everyone' | 'message_revoke_me' | 'client_details' | 'error_message' | 'chat_user_details_validate' | 'message_send_complete';
const socketOptions :any = {};

const getSocketConnected = (state: any) => state.WebSocket.connected;

function* handleSocketEvents(data: any): any {

    const isConnected = yield select(getSocketConnected)
    if (window?.socket?.connected || isConnected ) {
        console.log('Socket connection already established');
        return;
    }

    socketOptions.query = `userId=${data.userId}&parentId=${data.parentId}&browserUid=${data.browserUid}`
    const socket = io(config.API_URL, socketOptions);
    window.socket = socket

    const channel = eventChannel((emit) => {
        const eventHandler = (event: SocketEvent, payload: any) => {
            console.log("event, payload :",event, payload)
            emit({ event, payload });
        };
        // Register event listeners
        socket.on('connect', () => eventHandler('connect', null));
        socket.on('disconnect', () => eventHandler('disconnect', null));
        socket.on('qr', (payload: any) => eventHandler('qr', payload));
        socket.on('QRCodeDestroy', (payload: any) => eventHandler('QRCodeDestroy', payload));
        socket.on('authenticated', (payload: any) => { eventHandler('authenticated', payload) });
        socket.on('ready', (payload: any) => { eventHandler('ready', payload) });
        socket.on('chat_syncing_start', (payload: any) => { eventHandler('chat_syncing_start', payload) });
        socket.on('message_create', (payload: any) => { eventHandler('message_create', payload) })
        socket.on('old_message_sync_complete', (payload: any) => { eventHandler('old_message_sync_complete', payload) })
        socket.on('message_send_complete', (payload: any) => { eventHandler('message_send_complete', payload) })
        socket.on('old_message_sync_start', (payload: any) => { eventHandler('old_message_sync_start', payload) })
        socket.on('client_disconnected', (payload: any) => { eventHandler('client_disconnected', payload) })
        socket.on('message_ack', (payload: any) => { eventHandler('message_ack', payload) })
        socket.on('update_number_allocation', (payload: any) => { eventHandler('update_number_allocation', payload) })
        socket.on('sync_chat_user', (payload: any) => { eventHandler('sync_chat_user', payload) })
        socket.on('resolved_chat', (payload: any) => { eventHandler('resolved_chat', payload) })
        socket.on('assign_chat', (payload: any) => { eventHandler('assign_chat', payload) })
        // socket.on('message_unread', (payload: any) => { eventHandler('message_unread', payload) })
        socket.on('session_resyncing', (payload: any) => { eventHandler('session_resyncing', payload) })
        socket.on('message_revoke_everyone', (payload: any) => { eventHandler('message_revoke_everyone', payload) })
        socket.on('message_revoke_me', (payload: any) => { eventHandler('message_revoke_me', payload) })
        socket.on('chargebeeDetails', (payload: any) => { eventHandler('chargebeeDetails', payload) })
        socket.on('chat_user_details_validate', (payload: any) => { eventHandler('chat_user_details_validate', payload) })
        socket.on('error_message', (payload: any) => { eventHandler('error_message', payload) })
        socket.on('ping', (payload :any) => {
            console.log('Received ping from server');
            eventHandler('client_details', payload)
            socket.emit('pong'); // Client responds with a pong
          });
        // Cleanup function
        return () => {
            // Remove event listeners
            socket?.off('connect', () => eventHandler);
            socket?.off('disconnect', () => eventHandler);
            socket?.off('qr', () => eventHandler);
            socket?.off('QRCodeDestroy', () => eventHandler);
            socket?.off('authenticated', () => eventHandler);
            socket?.off('ready', () => eventHandler);
            socket?.off('chat_syncing_start', () => eventHandler);
            socket?.off('message_create', () => eventHandler)
            socket?.off('old_message_sync_complete', () => eventHandler)
            socket?.off('message_send_complete', () => eventHandler)
            socket?.off('old_message_sync_start', () => eventHandler)
            socket?.off('client_disconnected', () => eventHandler)
            socket?.off('message_ack', () => eventHandler)
            socket?.off('update_number_allocation', () => eventHandler)
            socket?.off('sync_chat_user', () => eventHandler)
            socket?.off('resolved_chat', () => eventHandler)
            socket?.off('assign_chat', () => eventHandler)
            // socket?.off('message_unread', () => eventHandler)
            socket?.off('session_resyncing', () => eventHandler)
            socket?.off('message_revoke_everyone', () => eventHandler)
            socket?.off('message_revoke_me', () => eventHandler)
            socket?.off('chargebeeDetails', () => eventHandler)
            socket?.off('chat_user_details_validate', () => eventHandler)
            socket?.off('error_message', () => eventHandler)
        };
    });

    try {
        while (true) {
            const { event, payload } = yield take(channel);
            let currentChatStateData;
            let processedData;
            // Dispatch actions based on socket events

            // console.log('##2 event, payload::: ', event, payload);
            switch (event) {
                case 'connect':
                    yield put(socketConnected({ type: 'SOCKET_CONNECT_SUCCESS' }));
                    yield put(({ type: WebsocketActionTypes.CONNECTED }))
                    break;
                case 'disconnect':
                    yield put(({ type: WebsocketActionTypes.DISCONNECTED }));
                    break;
                case 'qr':
                    yield put(qrCodeReceived(payload));
                    yield put(({ type: WebsocketActionTypes.SESSION_RESYNCING_COMPLETED }))
                    break;
                case 'QRCodeDestroy':
                    yield put(qrCodeDestroyed(payload));
                    break;
                case 'authenticated':
                    yield put(qrCodeApiResponseSuccess(QrCodeActionTypes.CLIENT_AUTHENTICATED, payload));
                    yield put(({ type: WebsocketActionTypes.SESSION_RESYNCING_COMPLETED }))
                    break;
                case 'ready':
                    yield put(qrCodeApiResponseSuccess(QrCodeActionTypes.QR_CODE_READY, payload));
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.SYNC_CHAT_START, null));
                    yield put(({ type: WebsocketActionTypes.SESSION_RESYNCING_COMPLETED }))
                    break;
                case 'chat_syncing_start':
                    const data = {
                        clientId: payload?.clientId
                    };
                    updateAuthData(data);
                    yield put(qrCodeApiResponseSuccess(QrCodeActionTypes.CHAT_SYNCING_START, payload));
                    break;
                case 'message_create':
                    console.log("message_create ~ payload:", Math.floor(new Date().getTime() / 1000))
                    currentChatStateData = yield select(state => state.Chats);
                    processedData = yield call(newMessageRecived, currentChatStateData, payload);
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.NEW_MESSAGES_RECEIVED, processedData));
                    // yield put(chatsApiResponseSuccess(ChatsActionTypes.NEW_MESSAGES_RECEIVED, payload));
                    break;
                case 'old_message_sync_complete':
                    console.log("old_message_sync_complete ~ payload:")
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.OLD_MESSAGE_SYNC_COMPLETE, payload));
                    break;
                case 'message_send_complete':
                    console.log("message_send_complete ~ payload:")
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.MESSAGE_SEND_COMPLETE, payload));
                    break;
                case 'old_message_sync_start':
                    console.log("old_message_sync_start ~ payload:")
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.OLD_MESSAGE_SYNC_START, payload));
                    break;
                case 'client_disconnected':
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.CLIENT_DISCONNECTED, payload));
                    break;
                case 'session_resyncing':
                    yield put(({ type: WebsocketActionTypes.SESSION_RESYNCING }))
                    break;
                case 'message_ack':
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, payload));
                    break;
                case 'update_number_allocation':
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.UPDATE_NUMBER_ALLOCATION, payload));
                    break;
                case 'sync_chat_user':
                    currentChatStateData = yield select(state => state.Chats);
                    processedData = yield call(chatUserValidate, currentChatStateData, payload);
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.UPDATE_CHAT_USER_LIST, processedData));
                    break;
                case 'chat_user_details_validate':
                    console.log(`chat_user_details_validate ::`)
                    currentChatStateData = yield select(state => state.Chats);
                    processedData = yield call(chatUserValidate, currentChatStateData, payload);
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.UPDATE_CHAT_USER_LIST, processedData));
                    break;
                case 'message_revoke_everyone':
                    console.log("websocket: saga.ts: ~ message_revoke_everyone:",)
                    // yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, payload));
                    break;
                case 'message_revoke_me':
                    console.log("websocket: saga.ts: ~ message_revoke_me:")
                    // yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, payload));
                    break;
                case 'chargebeeDetails':
                    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, payload));
                    // yield put (profileApiResponseSuccess(ProfileActionTypes.GET_PROFILE_DETAILS,{data:{response :{chargebeeDetails : payload}}}))
                    break;
                case "client_details" :
                    let totalUnreadCount = 0;
                    for (const item of payload.data) {
                        totalUnreadCount += item.unReadCount;
                    }
                    payload.totalUnreadMessage = totalUnreadCount;
                    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CLIENTS_LIST, payload))
                    break;
                case 'error_message':
                    
                    const error = payload.error
                    yield put(qrCodeApiResponseError(QrCodeActionTypes.CLIENT_AUTHENTICATED, error))
                    yield put(socketResponseError(WebsocketActionTypes.NOTICE_ERROR, error,payload))
                    break;
                default:
                    console.log('##4::default: ',event, payload );
                    break;
            }
        }
    } finally {
      //  channel.close();
    }
}

function* handleConnectSocket(action: connectSocketAction) {
    yield call(handleSocketEvents, { url: action.payload.url, userId: action.payload.userId, parentId: action.payload.parentId,browserUid:action.payload.browserUid });
}

function* watchSocketActions() {
    yield takeEvery(WebsocketActionTypes.SOCKET_CONNECT, handleConnectSocket);
}

function* SocketSaga() {
    yield all([
        fork(watchSocketActions)
    ]);
}

export default SocketSaga;
