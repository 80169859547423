import { UserListActionTypes } from "./types";

// common success
export const getUserListApiResponse = (actionType: string, data: any) => ({
  type: UserListActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const getUserListApiResponseError = (actionType: string, error: string) => {
  return ({
  type: UserListActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
})};

export const getUserList = () => {
  return {
    type: UserListActionTypes.GET_USER_LIST,
  };
};
export const allocatedUserList = (id:any,callback?:(response:any)=>void) => {
  return {
    type: UserListActionTypes.GET_ALLOCATED_USER_LIST,
    payload:id,
    callback
  };
};
export const getUserDetails= (id:string) => {
  return {
    type: UserListActionTypes.GET_USER_DETAILS,
    payload:id
  };
};

export const userAdd = (data:any) => {
  return {
    type: UserListActionTypes.POST_USER_ADD,
    payload: data,
  };
};
export const userUpdate = (id:string,data:any) => {
  return {
    type: UserListActionTypes.USER_UPDATE,
    payload:{ data,id}
  };
};


export const userDelete = (data:any) => {
  return {
    type: UserListActionTypes.DELETE_USER,
    data:{data}
  };
};
