import { QrCodeActionTypes } from "./types";

// common success
export const qrCodeApiResponseSuccess = (actionType: string, data: any) => ({
  type: QrCodeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const qrCodeApiResponseError = (
  actionType: string,
  error: string
) =>({
  type: QrCodeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getQrCode = (selectedChat: string | number | null, privacyValue?: any) => {
  return({
  type: QrCodeActionTypes.GET_QR_CODE,
  payload: selectedChat,privacyValue
})};

export const qrCodeReceived = (payload: string | number | object |null) => {
  return({
  type: QrCodeActionTypes.GET_CLIENT_ID_DETAILS,
  payload: payload,
})};
export const qrCodeDestroyed = (payload: string | number | object |null) => {
  return({
  type: QrCodeActionTypes.DESTROYED_QR_CODE,
  payload: payload,
})};
export const socketConnected = (payload: string | number | object |null) => {
  return({
  type: QrCodeActionTypes.SOCKET_CONNECT_SUCCESS,
  payload: payload,
})};
export const storeClientList = (payload: any) => {
  return({
  type: QrCodeActionTypes.STORE_CLIENT_ID,
  payload: payload,
})};

export const resetClientProcess = () => {
  return({
    type: QrCodeActionTypes.RESET_CLIENT_PROCESS
  
})}

export const connectWhatsAppBusiness = (id: string) => {
  return{
  type: QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS,
  payload: id,
}}

export const clearChatSyncingReducer = () => {
  return{
  type: QrCodeActionTypes.CLEAR_CHATSYNCING_REDUCER,
}}

export const numberDelete = (data:any,option:string) => {
  return {
    type: QrCodeActionTypes.DELETE_NUMBER,
    payload:{data,option}
  };
};

export const channelCreate = (payload: any) => {
  return({
  type: QrCodeActionTypes.CHANNEL_CREATE,
  payload: payload,
})};

export const getMessengerPageList = (clientId: any,callback:(response:any)=>void) => {
  return({
  type: QrCodeActionTypes.GET_MESSENGER_PAGE_LIST,
  payload: clientId,
  callback
})};
export const updateClientDeatils = (payload: any,callback:(response:any)=>void) => {
  return({
  type: QrCodeActionTypes.UPDATE_CLIENT_DETILS,
  payload: payload,
  callback
})};

export const connectTelegram = (data:any,callback:(response:any)=>void) => {
  return {
    type: QrCodeActionTypes.TELEGRAM_CONNECT,
    payload:{data},
    callback
  };
};

export const connectMessenger = (data:any,callback:(response:any)=>void) => {
  return {
    type: QrCodeActionTypes.MESSENGER_CONNECT,
    payload:{data},
    callback
  };
};
export const connectInstagram = (data:any,callback:(response:any)=>void) => {
  return {
    type: QrCodeActionTypes.INSTRGRAM_CONNECT,
    payload:{data},
    callback
  };
};
export const connectChatbot = (data:any,callback:(response:any)=>void) => {
  return {
    type: QrCodeActionTypes.CHATBOT_CONNECT,
    payload:{data},
    callback
  };
};

export const getOldClientId = ({payloadData}:any,callback:(response:any)=>void) => {
  return {
    type: QrCodeActionTypes.GET_OLD_CLIENT_ID,
    payload:{payloadData},
    callback
  };
};