import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import { IntegrationsActionTypes } from "./types";
import { integrationsApiResponseSuccess, integrationsApiResponseError } from "./actions";

import {
    saveIntegration as saveIntegrationsApi,
    getIntegrations as getIntegrationsApi,
    removeIntegraation as removeIntegraationApi,
    removeSlackChannel as removeSlackChannelApi,
    addBoardId as addBoardIdApi,
    checkUrl as checkUrlApi,
    updateIntegration as updateIntegrationApi,
} from "../../api/index";

// Helper
import {
    showSuccessNotification,
} from "../../helpers/notifications";

function* saveIntegrations({ payload: data ,callback}: any) {
    try {
        const response: Promise<any> = yield call(saveIntegrationsApi, data);
        if (typeof callback === 'function') {
            callback(response);
        }
        yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.SAVE_INTEGRATIONS, response)
        );
    } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.SAVE_INTEGRATIONS, error)
        );
    }
}
function* getIntegrations({ payload: id }: any) {
    try {
        const response: Promise<any> = yield call(getIntegrationsApi,id);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.GET_INTEGRATIONS, response)
            );
        } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.GET_INTEGRATIONS, error)
        );
    }
}
function* removeIntegraation({ payload: {id,data} }: any) {
    try {
        const response: Promise<any> = yield call(removeIntegraationApi,id,data);
        yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.REMOVE_INTEGRATIONS, response)
        );
    } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.REMOVE_INTEGRATIONS, error)
        );
    }
}

function* removeSlackChannel({ payload: {data} }: any) {
    try {
        const response: Promise<any> = yield call(removeSlackChannelApi,data);
        yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.REMOVE_SLACK_CHANNEL, response)
        );
    } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.REMOVE_SLACK_CHANNEL, error)
        );
    }
}
function* addBoardId({ payload: {data},callback }: any) {
    try {
        const response: Promise<any> = yield call(addBoardIdApi,data);
        if (typeof callback === 'function') {
            callback(response);
          }
        yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.ADD_BOARD_ID, response)
        );
    } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.ADD_BOARD_ID, error)
        );
    }
}

function* checkUrl({ payload: { url} }: any) {
    try {
        const response: Promise<any> = yield call(checkUrlApi,url);
        // yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.CHECK_URL, response)
        );
    } catch (error: any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.CHECK_URL, error)
        );
    }
}

function* updateIntegration({payload : {integrationName,data,userId},callback} : any) {
    try {
        const response: Promise<any> = yield call(updateIntegrationApi,{integrationName,data,userId});
        if (typeof callback === 'function') {
            callback(response);
        }
        // yield call(showSuccessNotification, response);
        yield put(
            integrationsApiResponseSuccess(IntegrationsActionTypes.UPDATE_INTEGRATION, response)
        );
    } catch (error : any) {
        yield put(
            integrationsApiResponseError(IntegrationsActionTypes.UPDATE_INTEGRATION, error)
        );
    }    
}

export function* watchSaveIntegrations() {
    yield takeEvery(IntegrationsActionTypes.SAVE_INTEGRATIONS, saveIntegrations);
}
export function* watchGetIntegrations() {
    yield takeEvery(IntegrationsActionTypes.GET_INTEGRATIONS, getIntegrations);
}
export function* watchRemoveIntegraation() {
    yield takeEvery(IntegrationsActionTypes.REMOVE_INTEGRATIONS, removeIntegraation);
}
export function* watchRemoveSlackChannel() {
    yield takeEvery(IntegrationsActionTypes.REMOVE_SLACK_CHANNEL, removeSlackChannel);
}
export function* watchAddBoardId() {
    yield takeEvery(IntegrationsActionTypes.ADD_BOARD_ID, addBoardId);
}
export function* watchCheckUrl() {
    yield takeEvery(IntegrationsActionTypes.CHECK_URL, checkUrl);
}
export function* watchUpdateIntegration() {
    yield takeEvery(IntegrationsActionTypes.UPDATE_INTEGRATION, updateIntegration);
}
function* integrationsSaga() {
    yield all([
        fork(watchSaveIntegrations),
        fork(watchGetIntegrations),
        fork(watchRemoveIntegraation),
        fork(watchRemoveSlackChannel),
        fork(watchAddBoardId),
        fork(watchCheckUrl),
        fork(watchUpdateIntegration)
    ])
}

export default integrationsSaga;
