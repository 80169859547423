import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface CustomPaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    className?:string
}

const CustomPagination = ({currentPage,totalPages,onPageChange,className}: CustomPaginationProps) => {
    const items = [];
    if (totalPages <= 1) {
        return null;
    }
    
    items.push(
        <Pagination.First
            key="first"
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
        />
    );
    items.push(
        <Pagination.Prev
            key="prev"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
        />
    );
    
    if (currentPage > 2) {
        items.push(<Pagination.Ellipsis key="ellipsis-prev" />);
    }
    
    for (let i = Math.max(currentPage - 2, 1); i <= Math.min(currentPage + 2, totalPages); i++) {
        items.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => onPageChange(i)}
            >
                {i}
            </Pagination.Item>
        );
    }
    
    if (currentPage + 2 < totalPages) {
        items.push(<Pagination.Ellipsis key="ellipsis-next" />);
    }
    
    items.push(
        <Pagination.Next
            key="next"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
        />
    );
    items.push(
        <Pagination.Last
            key="last"
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage === totalPages}
        />
    );
    return <Pagination className={className}>{items}</Pagination>;
};

export default CustomPagination;
