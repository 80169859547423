import styled from 'styled-components';
import QrCodesvg from '../../assets/images/qrcode.svg'
import CheckLockIcon from '../../assets/images/icons/check_lock.svg'
import SwitchRoundIcon from '../../assets/images/icons/switch_rounbd_icon.svg'
import SwitchRoundIconCheked from '../../assets/images/icons/switch_rounbd_icon_checked.svg'

const QrCodeWrapper = styled.div`
width:100% !important;
.steps_title{
    color: #000 !important;
}
.dummyQrCode{
    background-image: url(${QrCodesvg});
    object-fit: scale-down;
    background-size: contain;
    height: 264px;
    width: 264px;
}
.iostext{
    margin-top: 10px;
}
.qrmainrow {
    .telegramFormCard{
        box-shadow: 0px 1px 8px #eee !important;
        border:1px solid #ddd !important;
    }
    .qrGenerateSteps{
        h5{
            color: #333 !important;
            font-size:15px !important;
        }
        ol{
            padding-left: 24px !important; 
            li{
                font-size: 13px !important;
                .ctrlhSettingOption{
                    background-color: #eee !important;
                    border-radius: 2px;
                    line-height: normal;
                    font-weight: 800;
                    padding: 2px !important;
                    margin: 0px 2px!important;
                    color: #998fa4 !important;
                }
            }
        }
    }
}
.whenCurruntStepZero{
    // border-radius: 16px !important;
    // @media (min-width: 992px) {
    //     max-width: 832px;
    //     margin: 0 auto !important;
    // }
    .hstack{
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    // &:has(.integrationCardMain){
    //     @media (min-width: 992px) {
    //         max-width: 950px !important;
    //     }
    //     @media (min-width: 1366px) {
    //         max-width: 1080px !important;
    //     }
    // }
}
.telegramFormCardMain{
    @media (min-width:1200px){
        width:75% !important;
    }
}
.integrationCardMain{
    margin-bottom:0 !important;
}
.wpQrScanCard{
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10);
    position: relative;
    overflow: hidden;
    z-index:6;
    width: 100%;
    &:hover{
        border-color: #0029c7 !important;
    }
    @media (max-width:500px){
       margin-bottom: 0px !important;        
    }
    p{
        font-size: 13px;
        color: #898989;
        margin-bottom: 0 !important;
        line-height: 1.7;
    }
    .chnl_img_div{
        height:64px !important;
        width:64px !important;
        margin:0 auto;
    } 
    .wpQrScanCardBody{
        gap: 22px;
        .channleConnectBtn{
            font-size:13px !important;
            background-color: white;
            color: #000 !important;
            box-shadow: 0px 1px 6px #eee;
            font-weight: 500 !important;
            width: 110px;
            border-radius: 4px;
            margin-top: 10px;
            &:hover {
                border-color: #0029c7 !important;
                color: #fff !important;
                background-color: #0029c7 !important;
            }
        }
        button.channleConnectBtn{
            &:is(:hover,:focus,:active){
                border-color: #0029c7 !important;
                color: #fff !important;
                background-color:#0029c7 !important;
            }
        }
        h4{
            font-size: 15px!important;
            font-weight: 600;
            color:#000 !important;
            margin: 0px !important;
        }
        .img_radius_8{
            border-radius: 8px !important;
        }
        .wpQrScanCardBodyTxt {
            gap: 10px;
        }
        .wpQrScanCardNote {
            margin-top: 10px !important;
            min-height: 68px;
        }
    }
}
.badgeblock {
  position: relative;
  &::before {
    position: absolute;
    top: 44px;
    right: 14px;
    content: '';
    background: #FF5722;
    height: 26px;
    width: 26px;
    transform: rotate(45deg);
  }
  &.green::before {
    background: #008B62;
  }
  .badgeBlockInnr {
    position: absolute;
    top: 32px;
    right: 8px;
    padding: 5px 10px 5px 10px;
    background: #FF5722;
    font-size: 13px;
    line-height: normal;
    color: white;
    text-align: center;
    box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
    z-index: 999;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.badgeblock.green .badgeBlockInnr {
    background: #008B62;
}
.videoTutorialRow{
    .tutorialVideoDiv,.videoTutorialTitle{
        margin-left: -16px;
        margin-right: -16px;
        @media (max-width:991.98px) {
            margin-left: -8px !important;
            margin-right: -8px !important;
        }
    }
    h3,h5{
        font-weight: 700;
        line-height: normal;
        color: #131313 !important;
    }
    .videoTutorialTitle{
        border-top: 1px solid rgba(0, 41, 199, 0.18);
        border-bottom: 1px solid rgba(0, 41, 199, 0.18);
        background: #FFF;
        h3{
            font-size: 22px;
            letter-spacing: 0.6px;
        }
    }
    h5{
        font-size: 15px;
        letter-spacing: 0.36px;
    }
    
    video{
        border-radius: 16px;
        cursor: pointer;
        @media (max-width:768px) {
            width: 100% !important;
            height: 100% !important;   
            border-radius: 10px !important;
        }
    }
}
.wpQrScanCardMain{
    margin-bottom: 0px !important;
    border-radius: 0px !important;
    border-radius: 8px !important;
    @media (max-width:480px){
        padding:15px !important;
    }
    .qrCodePageCardTitleMain{
        .wpCardInfoLink{
            i{color:#686868 !important;}
            &:hover{
                i{color:#0029c7 !important;}
            }
        }
    }
}
.privacyDivQr {
    .list-group-item{
        border-radius: 7px !important;
        // background: linear-gradient(172deg, #0E31BA 1.36%, #001154 168.55%);
        // box-shadow: 0 2px 12px 0 rgba(45,40,77,0.2), 0 0 0 1px rgba(45,40,77,0.07);
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 2px 0.5px;
        color: #333 !important;
        padding: 18px 22px !important;
        position: relative;
        h5{
            color: #212529 !important;
        }
        .toggle_btn_main{
            .form-switch{
                .form-check-input{
                    width: 3em !important;
                    height: 1.5em !important;
                    background-image: url(${SwitchRoundIcon});
                }
                .form-check-input:checked{
                    // background-color: #29B6F6 !important;
                    background-color: #0029c7 !important;
                    background-image: url(${SwitchRoundIconCheked});
                }
            }
        }
        .lockCheckCir{
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50px;
            background-color: #EBEFFF;
            // background-color: #fff !important;
            .lockCheckIcon{
              background-image: url(${CheckLockIcon});
              height: 20px;
              width: 20px;
              min-width: 20px;
              margin-left: 2px;
              display: block;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
        }
        .hidden-checkbox {
            display: none;
        }
        /* Style the toggle button label */
        .toggle-button {
          cursor: pointer;
        }
        /* Initially hide the content */
        .content {
            display: none;
            border-top: 1px solid #ddd;
            padding-top: 20px;
            padding-bottom: 40px;
        }
        /* When the checkbox is checked, display the content */
        .hidden-checkbox:checked + .toggle-button .dropdown_arrow{
            transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
        }
        .hidden-checkbox:checked + .toggle-button + .content {
            display: block;
        }
        .drop_arrow_main{
            display: flex;
            align-items : center;
            justify-content : center;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            .dropdown_arrow{
                border: solid #212529;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 5px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                position: absolute;
                bottom: 20px;
                right: 0;
                left: 0;
            }
        }
    }
}

@media (max-width:480px) {
    .privacyDivQr {
        .list-group-item{
            padding: 12px !important;
            .mobileview_div_main{
                flex-direction: column;
            }
            h5{
                font-size: 16px !important;
            }
            .toggle_btn_main{
                padding-top: 10px;
                display: flex;
                width: 100%;
                justify-content: end;
            }
        }
    }
}
.afterScanProggressDiv{
    margin-top: 80px !important;
}
.Chat-bot-clipboard{
    border-radius: 8px;
    border: 1px solid #d6d2d2 !important;
    background-color: #f4f4f4 !important;
}
  .Chat-bot-clipboard i{
   background: #f4f4f4;
    padding: 3px;
    border-radius: 4px;
    // border: 1px solid #adb6bf87;

} 
.modal-dialog-end {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}

.modal-dialog-side {
  margin: 0;
}
.modal-header{

    padding: 0px;
    }
/* Add this CSS to your stylesheet */
.custom-close-button {
    background-color: #FFF; /* Red background */
    color: #000; /* Green "X" */
    border-radius: 50%; /* Full round shape */
    border: none; /* Remove default border */
    width: 30px; /* Width of the button */
    height: 30px; /* Height of the button */
    display: flex; /* Center the "X" */
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Pointer cursor on hover */
    border: 1px solid #FFF;
}


.custom-close-button:focus {
    outline: none; /* Remove default focus outline */
}
.modal-header-1{
position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
    
`;

export { QrCodeWrapper };