import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, FormFeedback, FormText } from 'reactstrap';
import { showErrorNotification, showSuccessNotification } from '../../helpers/notifications';
import { useProfile, useRedux } from '../../hooks';
import { checkUrl,stateClear } from "../../redux/actions";
import UserProfile from '../Dashboard/Settings/UserProfile';
import AlertComponent from '../../components/Alert';
interface FormValue {
    webhookName: string;
    webhookUrl: string;
    state?:string;
    parentId?:string;
    isEdited?:boolean;
}
interface WebhookConnectPopupTypes {
    webhookIntegrationSave:(name:string,data?:any)=>void;
    isEdited ?: boolean ;
    data?:any;
    buttonRef?:any;
}
interface AlertType {
    isShow:boolean | null;
    message:"";
    type:any
}
const WebhookConnectPopup = ({isEdited,webhookIntegrationSave,data,buttonRef}:WebhookConnectPopupTypes) => {
    const { dispatch, useAppSelector } = useRedux()
    const { userProfile } = useProfile()
    const {
        loading,
        checkUrlPayload,
        integrationUpdate,
        isIntegration
    } = useAppSelector(state => ({
        loading: state.Integrations.loading,
        checkUrlPayload: state.Integrations.checkUrlPayload,
        integrationUpdate: state.Integrations.uploadPayload,
        isIntegration: state.Integrations.isIntegration,
    }));
    const { control, handleSubmit, getValues,setValue, formState: { errors } } = useForm<FormValue>();
    const [modal, setModal] = useState(false);
    const [showAlert, setShowAlert] = useState<AlertType>()
    const toggle = () => setModal(!modal);
    const [saveButtonDisable, setSaveButtonDisable] = useState(true)
    useEffect(() => {
        dispatch(stateClear())
    }, [])
    
    const testUrl = () => {
        const webhookUrl = getValues('webhookUrl');
        dispatch(checkUrl(webhookUrl))
    };
    
    useEffect(() => {
        if(isIntegration.integrationName == "webhook"){
            if(!isIntegration?.success){
                setShowAlert({isShow:true,message:isIntegration?.error?isIntegration?.error:isIntegration?.message,type:"danger"})
            }else {
                toggle();
            }
        }
    }, [isIntegration])
    
    useEffect(() => {
        if(isEdited){
            if(data?.restApiWebhook_list?.length)
            setValue("webhookName",data?.restApiWebhook_list[0]?.api_name);
            setValue("webhookUrl",data?.restApiWebhook_list[0]?.url);
        }
    }, [isEdited,data])
    
    useEffect(() => {
        if (checkUrlPayload?.success) {
            setSaveButtonDisable(false)
            setShowAlert({isShow:true,message:checkUrlPayload?.message,type:"success"})
        }else  if (checkUrlPayload && checkUrlPayload?.message){
            setShowAlert({isShow:true,message:checkUrlPayload?.message,type:"danger"})
            // showErrorNotification({message:checkUrlPayload.message})
            setSaveButtonDisable(true)
        }
    }, [checkUrlPayload])
    useEffect(() => {
        if(integrationUpdate?.success && integrationUpdate?.response?.integrationName == "Webhook" ){
            showSuccessNotification(integrationUpdate?.response)
            toggle();
        }else if(integrationUpdate && !integrationUpdate?.success && integrationUpdate?.response?.integrationName == "Webhook") {
            setSaveButtonDisable(true)
            showErrorNotification({error:integrationUpdate?.response?.message})
        }
    }, [integrationUpdate])
    
    const onSubmit = (data: FormValue) => {
        data.state = "webhook"
        data.parentId = userProfile?.parentId?userProfile?.parentId:userProfile?.userId
        data.isEdited = isEdited;
        webhookIntegrationSave("webhook",data)
        // toggle();
    };

    return (
        <>
            {!isEdited ? <Button className='btn btn-primary font-size-13' onClick={toggle}>
                Connect
            </Button> :
            <div className='integrationSetting'>
                <a ref={buttonRef}  className='settingButton' onClick={toggle}>
                    <i className='bx bxs-cog'></i>
                </a>
            </div>}
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader className="modal-title-custom" toggle={toggle}>Webhook Setup</ModalHeader>
                <ModalBody className='p-4'>
                    <div>
                        { showAlert?.isShow  !== null && showAlert?.isShow && <AlertComponent type={showAlert?.type} message={showAlert?.message} dismissible={false} />}
                        <Form autoComplete="off"  >
                            <FormGroup row>
                                <Label for="webhookName">Name</Label>
                                <Col sm={8}>
                                    <Controller
                                        name="webhookName"
                                        control={control}
                                        rules={{
                                            minLength: 5,
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <Input
                                                    {...field}
                                                    id="webhookName"
                                                    placeholder="Enter webhook name"
                                                    type="text"
                                                />
                                                {errors.webhookName && (
                                                    <p style={{ color: 'red' }}>Webhook name must have at least 5 characters</p>
                                                )}
                                            </>
                                        )}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="webhookUrl">URL</Label>
                                <Col sm={8}>
                                    <Controller
                                        name="webhookUrl"
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: /^(ftp|http|https):\/\/[^ "]+$/,
                                                message: 'Please enter a valid URL (e.g., http://example.com)',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <Input
                                                    {...field}
                                                    id="webhookUrl"
                                                    placeholder="Enter webhook URL"
                                                    type="url"
                                                // pattern="^((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$"
                                                />
                                                {errors.webhookUrl && (
                                                    <p  style={{ color: 'red' }}>{errors.webhookUrl.message}</p>
                                                )}
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Button className='btn btn-success' disabled={(loading)} onClick={testUrl}>Test Url</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className='btn btn-outline-danger' outline onClick={toggle}>
                        Cancel
                    </Button>{' '}
                    <Button type='submit' className='btn btn-primary d-flex align-items-center justify-content-center' onClick={handleSubmit(onSubmit)} disabled={saveButtonDisable}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default WebhookConnectPopup;
