import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, Spinner} from 'reactstrap'
import { useProfile, useRedux } from '../../hooks';
import { getPlans, getProfileDetails, changeSubscription,chargebeeProccess,clearStateData, channelCreate } from '../../redux/actions';
import config from '../../config';
import classnames from "classnames";
import { showErrorNotification, showInfoNotification, showSuccessNotification } from '../../helpers/notifications';
import moment from 'moment';
import ConfirmationModal from '../../components/confirmationPopup';
import { openChargebeePortal } from '../../helpers/common.service';
interface UpGradePlanPopupType {
    lable?: string;
    type?: string;
    className?: string | undefined;
    args?: {},
    from?:string
    onConfirmation?:(response:any)=>void;
    isOpen?:boolean;
}

interface UserDetailsType {
    email:string,
    chargebeeDetails:any;
    parentId:string
    userId:string
    phoneNumber:string,
    allPlans?:[any],
    allAddons?:[any],
    allUsers?:[any],
    numberDetails?:[any]
}
const UpGradePlanPopup = ({isOpen, className, lable,from ,onConfirmation}: UpGradePlanPopupType) => {
    var Chargebee = window.Chargebee;
    const [modal, setModal] = useState(false);
    const { userProfile } = useProfile()
    const { dispatch, useAppSelector } = useRedux()
    const [userDetails, setUserDetails] = useState<UserDetailsType>(userProfile)
    const [loading, setLoading] = useState(false);
    const [plansList, setPlansList] = useState<[any]>()
    const [activeTab, setActiveTab] = useState<{tabId:string;tabKey:string}>({tabId:"1",tabKey:"monthly"});
    const [isConfirmationPop, setIsConfirmationPop] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState("")
    const [confirmationType, setConfirmationType] = useState("")
    const [isRedirect, setIsRedirect] = useState(true)
    const toggleTab = (tab:any) => {
        if (activeTab !== tab.tabId) {
            setActiveTab({tabId:tab.tabId,tabKey:tab.tabKey});
        }
    };
    const {
        plans,
        userData
    } = useAppSelector((state) => ({
        plans: state.PlanBilling.plans,
        sessionPortal: state.PlanBilling.sessionPortal,
        changeSubscriptionData: state.PlanBilling.changeSubscription,
        chargebeeProccessAction: state.PlanBilling.chargebeeProccess,
        userData:state.Login.user
    }))
    
    const parentId : string = userDetails?.parentId ? userDetails?.parentId : userDetails.userId;
    const [planData, setPlanDetails] = useState<any>({})
    useEffect(() => {
        setPlansList(plans)
    }, [plans])
    
    useEffect(() => {
        const planList = plans?.length ? plans : userData.allPlans ? userData.allPlans : userProfile?.allPlans ? userProfile?.allPlans : []
        setPlansList(planList)
        setUserDetails(userData)
        if (!loading && planList.length <= 0) {
            setLoading(!loading)
            dispatch(getPlans())
        }
        // if(isOpen){
        //     toggle()
        // }
    }, [])
 
    useEffect(() => {
        setUserDetails(userData)
        const planList = plans?.length ? plans : userData.allPlans ? userData.allPlans : []
        setPlansList(planList)
    }, [userData])
    const changeTrial = () =>{
        setTimeout(() => {
            if(userDetails?.chargebeeDetails?.customer_id &&  userDetails?.chargebeeDetails.status === "in_trial"){
                const data = {
                    trial_end: 0
                }
                // if(from == "WABA" ||(userDetails?.numberDetails &&  userDetails.numberDetails.length >= 1) ) dispatch(changeSubscription({ eventType: "trial_end", data }, parentId))
                dispatch(changeSubscription({ eventType: "trial_end", data }, parentId, async (response: any) => {
                    if (response?.success) {

                        let updateObject: any = {
                            subscription_items: []
                        }
                        const data = {
                            item_price_id: 'waba-USD-Monthly',
                            quantity: 1,
                        };
                        updateObject.subscription_items.push(data)
                        if (from === "WABA") dispatch(changeSubscription({ eventType: "addon_add", updateObject }, parentId))
                        dispatch(getProfileDetails(userDetails?.userId))
                    } else {
                        if (response.error.api_error_code === "payment_processing_failed") {
                            showErrorNotification("Payment failed. Please add a valid card and try again.")
                        } else {
                            showErrorNotification(response.error)
                        }
                    }
                }))
            }
            let updateObject:any = {
                subscription_items:[]
            }
            const data = {
                item_price_id: 'waba-USD-Monthly',
                quantity: 1,
            };
            updateObject.subscription_items.push(data)
            if(from === "WABA")dispatch(changeSubscription({ eventType: "addon_add", updateObject }, parentId))
            dispatch(getProfileDetails(userDetails?.userId))
        }, 5000);
    }
    // useEffect(() => {
    //     if (sessionPortal?.success) {
    //         let sessionPortalData = sessionPortal.sessionPortal
    //         const Chargebee = window.Chargebee;
    //         const cbInstance = Chargebee.init({
    //             site: config.CHARGEBEE_SITE_KEY,
    //             isItemsModel: true,
    //         })

    //         // const cbInstance = Chargebee.getInstance();
    //         const cbPortalInstance = cbInstance.createChargebeePortal();
    //         // var chargebeePortalInstance = cbInstance.createChargebeePortal();
    //         cbInstance.setPortalSession(function () {
    //             return new Promise(function (resolve, reject) {
    //                 var sampleResponse = {
    //                     "id": sessionPortalData.id,
    //                     "token": sessionPortalData.token,
    //                     "access_url": sessionPortalData.access_url,
    //                     "status": sessionPortalData.status,
    //                     "created_at": sessionPortalData.created_at,
    //                     "expires_at": sessionPortalData.expires_at,
    //                     "object": sessionPortalData.object,
    //                     "customer_id": sessionPortalData.customer_id
    //                 };
    //                 resolve(sampleResponse);
    //             });
    //         });
    //         if (sectionAgain === "updateCreditCard") {
    //             setLoading(false)
    //             console.log("section ::: updateCreditCard")
    //             cbPortalInstance.open({
    //                 close: () => {
    //                     console.log("section ::: updateCreditCard :::: close");
                        
    //                     cbInstance.logout();
    //                     cbInstance.closeAll();
    //                     window.location.reload();
    //                 },
    //                 paymentSourceAdd: () => {
    //                     console.log("section ::: updateCreditCard :::: paymentSourceAdd");
    //                     cbInstance.logout();
    //                     cbInstance.closeAll();
    //                     dispatch(clearCustomerPortal())
    //                     changeTrial()
    //                     if(onConfirmation && typeof onConfirmation == "function" )onConfirmation({success:true,message:"Please Add Second Number"})
    //                     // toggle()
    //                     // setTimeout(() => {
    //                     // }, 3000);
    //                     // window.location.reload();
    //                 },
    //                 paymentSourceUpdate: () => {
    //                     console.log("section ::: updateCreditCard :::: paymentSourceUpdate");
    //                     cbInstance.logout();
    //                     cbInstance.closeAll();
    //                     dispatch(clearCustomerPortal())
    //                     if(onConfirmation && typeof onConfirmation == "function" )onConfirmation({success:true,message:"Please Add Second Number" })

    //                     changeTrial()
    //                     // setTimeout(() => {
    //                     //     dispatch(getProfileDetails(userDetails?.userId))
    //                     // }, 3000);
    //                     // window.location.reload();
    //                     // toggle()
    //                 },
    //             }, {
    //                 sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES
    //             })
                
    //         }
    //         else if (sectionAgain === "AddbillingAddress") {
    //             console.log("section ::: AddbillingAddress")
    //             cbPortalInstance.open({
    //                 close: () => {
    //                     cbInstance.logout();
    //                     cbInstance.closeAll();
    //                     window.location.reload();
    //                 },
    //             }, {
    //                 sectionType: Chargebee.getPortalSections().ADDRESS
    //             })
    //         }
            
    //     }
    // }, [sessionPortal])

    // useEffect(() => {
    //     if (changeSubscriptionData && changeSubscriptionData?.success) {
    //         setModal(false)
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 4000);
    //     }
    // }, [changeSubscriptionData])

    // useEffect(() => {
    //     if(chargebeeProccessAction?.success){
    //         showSuccessNotification({message:"Plan Upgrade Successful!"})
    //         dispatch(clearStateData())
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 2000);
    //     }
    // }, [chargebeeProccessAction])

    const checkoutPlan = async (plan: any) => {
        await Chargebee.init({
            site: config.CHARGEBEE_SITE_KEY,
            isItemsModel: true,
        });
        var cbInstance = await  Chargebee.getInstance();
        await cbInstance.setCheckoutCallbacks((cart: any) => {
            return {
                success: (hostedPageId: any) => {
                    cbInstance.logout();
                    cbInstance.closeAll();
                    dispatch(chargebeeProccess({ hostedPageId, eventType: "create_customer_by_hosted_page" }, parentId, (response: any) => {
                        if (response?.success) {
                            showSuccessNotification({ message: "Plan Upgrade Successful!" })
                            const data = {
                                trial_end: 0
                            }
                            dispatch(clearStateData())
                            setTimeout(() => {
                                dispatch(changeSubscription({ eventType: "trial_end", data }, parentId))
                                if(onConfirmation && typeof onConfirmation ===  "function")onConfirmation({sucess:true,message:"Plan Upgrade Successfull!",checkout:true,skipEstimate: true})
                            //     window.location.reload()
                            }, 5000);
                        }
                    }))
                },
                error: (err: any) => {
                    console.log('Error during checkout:', err);
                    cbInstance.logout();
                    cbInstance.closeAll();
                }
            };
        });
        const planName = `${plan?.name}-${plan?.price_currency[0].currency.toUpperCase()}-${activeTab.tabKey.charAt(0).toUpperCase() + activeTab.tabKey.slice(1)}`;
        const cart = await  cbInstance.getCart();
        const utf8Array = encodeURIComponent(userDetails.email);
        const customer :any = {
            email: utf8Array,
            phone: userDetails?.phoneNumber ? `+${userDetails.phoneNumber}` : '',
        };
        if (from === "WABA")customer["cf_whatsapp_business"] = "YES"
        await cart.setCustomer(customer);
        const product = await cbInstance.initializeProduct(planName);
        if(from === "WABA")product.addAddon({
            id: 'waba-USD-Monthly',
            quantity: 1,
        });
        await product.setPlanQuantity(1);
        await cart.replaceProduct(product);
        await cart.proceedToCheckout();
        setLoading(false)
    }
    const onCheckoutPlan = async(planDetails: any) => {
        toggle()

        if(planDetails.isAvailableOnRequest){
            const payloadData = {
                selectuseCase : (planDetails.name).toUpperCase(),
                useCaseReason : `${(planDetails.name).toUpperCase()} Plan Featuers`,
                planName : `${(planDetails.name).toUpperCase()} Plan`
            }
            dispatch(channelCreate(payloadData))
            setConfirmationMessage("Thank you for your interest in our Gold Plan; our sales team will reach out to you promptly.")
            setConfirmationType("Info")
            setIsConfirmationPop(true)
            if(onConfirmation && typeof onConfirmation ===  "function")onConfirmation({sucess:true,message:"Thank you for your interest in our Gold Plan; our sales team will reach out to you promptly."})
            return
        }
        const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
        if(isCancelled){
            cancelledReactive(planDetails)
            setPlanDetails(planDetails)
            return
        }
        if (!userDetails?.chargebeeDetails?.customer_id) {
            setPlanDetails(planDetails)
            const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
            handleConfirmationMessage({planDetails,isCancelled, from});
            return     
        } else if (userDetails?.chargebeeDetails?.customer_id && (userDetails?.chargebeeDetails?.card_status === "no_card" || userDetails?.chargebeeDetails?.card_status === null || userDetails?.chargebeeDetails?.payment_fail || (!userDetails?.chargebeeDetails?.payment_fail &&  userDetails?.chargebeeDetails?.status === "in_trial" &&  userDetails?.chargebeeDetails?.card_status === "valid" ) )) {
            if(!planDetails.isAvailableOnRequest) {
                const planName = `${planDetails?.name}-${planDetails?.price_currency[0].currency.toUpperCase()}-${activeTab.tabKey.charAt(0).toUpperCase() + activeTab.tabKey.slice(1)}`
                const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
                if(isCancelled){
                    cancelledReactive(planDetails)
                    return
                }
                let updateObject:any = {
                    subscription_items:[]
                }
                const quantity = userDetails?.allUsers &&  userDetails?.allUsers?.length ? userDetails?.allUsers?.length : 1
                const data = {
                    item_price_id: planName,
                    quantity: quantity,
                };
                updateObject.subscription_items.push(data)
                // updateObject.auto_collection = "off"
                const trial_end = moment.utc(userDetails.chargebeeDetails.trial_end)
                updateObject.trial_end = moment(trial_end).unix()
                const plan_name = userDetails?.chargebeeDetails?.status === "cancelled" ? userDetails?.chargebeeDetails?.prev_plan_name.split("-")[0]:userDetails?.chargebeeDetails?.plan_name.split("-")[0]
                if(planDetails.name !== plan_name ){
                    if(isCancelled){
                        const date = moment().add(50, 's');
                        updateObject.auto_collection = "on"
                        updateObject.trial_end =  moment(date).unix()
                    }
                    dispatch(changeSubscription({ eventType: "plan_update", updateObject }, parentId,async(response:any)=>{
                        if(response.success){
                            showSuccessNotification({message:"Plan Upgrade Successful!"})
                            dispatch(clearStateData())
                            if (!from) {
                                await openChargebeePortal({ parentId, section: 'updateCreditCard', dispatch });
                                return;
                            }
                            handleConfirmationMessage({planDetails,isCancelled, from});
                            
                            // setSectionAgain("updateCreditCard")
                            // dispatch(getCustomerPortal(parentId))
                        }
                    }))
                    return 
                }else{
                    if (!from) {
                        await openChargebeePortal({ parentId, section: 'updateCreditCard', dispatch });
                        return;
                    }
                    showSuccessNotification({ message: 'Plan Upgrade Successful!' });
                    dispatch(clearStateData());
                    handleConfirmationMessage({planDetails,isCancelled, from});
                    return
                }
            }else {
                if(!from){
                    await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
                    return
                }
                showSuccessNotification({message:"Plan Upgrade Successful!"})
                dispatch(clearStateData())
                if(userDetails?.chargebeeDetails?.status !== "cancelled"){
                    setPlanDetails(planDetails)
                    const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
                    handleConfirmationMessage({planDetails,isCancelled, from});
                }else{
                    onConfirm(true)
                }
                // setSectionAgain("updateCreditCard")
                // dispatch(getCustomerPortal(parentId))
                return
            }
        } else if (userDetails?.chargebeeDetails?.customer_id && !userDetails?.chargebeeDetails?.subscription_id  && userDetails?.chargebeeDetails?.card_status === "valid" ){
            let message = `This will end your free trial and activate your subscription immediately. Please click "OK" to confirm.`;
            if(from === "WABA"){
                message =  "WABA services are not included in the Free trial. To access WABA functionality, please activate your subscription."
            }else {
                setPlanDetails(planDetails)
                onConfirm(true, planDetails)
                return; 
            }
            setIsConfirmationPop(true)
            setConfirmationMessage(message)
            setPlanDetails(planDetails)
            setConfirmationType("Ok")
            setIsRedirect(false)
        }
        if(userDetails.chargebeeDetails.card_status === "valid" && userDetails.chargebeeDetails.status === "cancelled" ){
            await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
            return
        }
    }
    const toggle = () => setModal(!modal);
    const onCancel = (value :any) => {
        setIsConfirmationPop(false)
        if(onConfirmation && typeof onConfirmation === "function" )onConfirmation({success:false,message:"Clicked on cancel"})
    } 

    const handleConfirmationMessage = ({planDetails,isCancelled, from}:any) => {
        let message = 'This will end your free trial and activate your subscription immediately. Please click "OK" to confirm.';
        if (from === 'WABA') {
            message = 'WABA services are not included in the Free trial. To access WABA functionality, please activate your subscription.';
        }

        if (isCancelled) {
            setPlanDetails(planDetails);
            setConfirmationMessage('Activate your subscription immediately. Please click "OK" to continue.');
        } else {
            setConfirmationMessage(message);
        }

        setIsConfirmationPop(true);
        setConfirmationType('Ok');
        setIsRedirect(false);
    };

    const cancelledReactive = (planDetails?:any)=>{
        if(userDetails?.chargebeeDetails?.status === "cancelled"){
            const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
            handleConfirmationMessage({ planDetails, isCancelled, from });
        }
    }
    const onConfirm = async(value:any, planDetails?:any) => {
        if (planDetails.isCancelled) {
            const planName = `${planData?.name}-${planData?.price_currency[0].currency.toUpperCase()}-${activeTab.tabKey.charAt(0).toUpperCase() + activeTab.tabKey.slice(1)}`
            const isCancelled = userDetails?.chargebeeDetails?.status === 'cancelled';
            let updateObject:any = {
                subscription_items:[]
            }
            const quantity = userDetails?.allUsers &&  userDetails?.allUsers?.length ? userDetails?.allUsers?.length : 1
            const data = {
                item_price_id: planName,
                quantity: quantity,
            };
            updateObject.subscription_items.push(data)
            const trial_end = moment.utc(userDetails.chargebeeDetails.trial_end)
            // updateObject.trial_end = moment(trial_end).unix()
            // if (isCancelled) {
                // const date = moment().add(50, 's');
                // updateObject.auto_collection = "off"
                // updateObject.trial_end = moment(date).unix()
            // }

            // showInfoNotification("We have start your reactivation process,Please wait for while. ")
            await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch, newPlanDetails :updateObject,  isCancelled:planDetails.isCancelled })
            return
        } else {
            if ((from === "numberExistInAnotherAccount" && !userDetails?.chargebeeDetails?.status) || userDetails?.chargebeeDetails?.status === "cancelled") {
                if (userDetails && ((userDetails.chargebeeDetails && !userDetails.chargebeeDetails.customer_id) || !userDetails.chargebeeDetails)) {
                    return checkoutPlan(planData)
                }
            } else if ((from === "numberExistInAnotherAccount" || from === "WABA") && userDetails?.chargebeeDetails?.status === "in_trial") {
                setLoading(false)
                const result: any = await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
                if (result.success) {
                    changeTrial()
                }
                return;
            } else if (from === "WABA" && userDetails?.chargebeeDetails?.customer_id && !userDetails?.chargebeeDetails?.subscription_id && userDetails?.chargebeeDetails?.card_status === "valid") {
                setIsConfirmationPop(false)
                if (onConfirmation && typeof onConfirmation === "function") onConfirmation({ success: true, message: "Plan Upgrade Successfull!", checkout: false, planDetails: planData, activeTab })
                return;
            }

            if (value && from && from !== "WABA") {
                // openChargebeePortal({parentId ,section:"updateCreditCard"})
                // setSectionAgain("updateCreditCard")
                // dispatch(getCustomerPortal(parentId))
                let data: any = {
                    success: true,
                    message: "Please Add Second Number"
                }
                if (userDetails?.chargebeeDetails?.customer_id && !userDetails?.chargebeeDetails?.subscription_id && userDetails?.chargebeeDetails?.card_status === "valid") {
                    data["checkout"] = false
                    data["planDetails"] = planDetails
                    data["activeTab"] = activeTab
                }
                setTimeout(() => {
                    if (onConfirmation && typeof onConfirmation === "function") onConfirmation(data)
                    setLoading(false)
                }, 3000);
            } else if (value && from === "WABA") {
                if (userDetails && ((userDetails.chargebeeDetails && !userDetails.chargebeeDetails.customer_id) || !userDetails.chargebeeDetails)) {
                    return checkoutPlan(planData)
                }
            } else if (!from) {
                setLoading(false)
                await openChargebeePortal({ parentId, section: "updateCreditCard", dispatch })
                // if (result.success) {
                //     changeTrial()
                // }
            }
        }
    }
    return (
        <>
            {<Button className={className} onClick={toggle}>
                {lable}
            </Button>}
            <Modal className='upgradePlanModalMain' isOpen={modal} toggle={toggle} centered size='xl'>
                <ModalHeader toggle={toggle}>
                    Plans &amp; Pricing
                </ModalHeader>
                <ModalBody>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center mb-4">
                                <p className="text-muted mb-2 font-size-15 upgradModalDesc">{`In order to enjoy uninterrupted service and access all core features, upgrading your plan is necessary.`}</p>
                                <div className="d-flex justify-content-center">
                                    <Nav className="nav-pills plan-nav mb-3 p-1" id="pills-tab" role="tablist">
                                        <NavItem className='border-primary pricePlanCardBtns'>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab.tabId === '1' })}
                                                onClick={() => { toggleTab({tabId:"1",tabKey:"monthly"}); }}
                                            >
                                                Monthly
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className='border-primary pricePlanCardBtns'>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab.tabId === '2' })}
                                                onClick={() => { toggleTab({tabId:"2",tabKey:"yearly"}); }}
                                            >Annually <span >20% Off</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center flex-wrap'>
                        {(plansList || []).map((item: any, index: number) => {
                            if ((item.isActive && item.isDisplayed)  || (item.isActive && item.isDisplayed && from === "ConfirmPopUp" && !item.isWhatsAppBusiness)) {
                                return (
                                <div className="col-lg-4 col-sm-6 col-12 d-flex" id={`plan-${index}`}>
                                    <div className="card pricing-box mb-0 w-100">
                                        <div className="card-body bg-light mx-2 mt-1 mb-2 py-3 px-4 pricingCardBody">
                                            <div className='text-center'>
                                                <h5 className="mb-1 fw-semibold">{item.displayName}</h5>
                                            </div>
                                            <div className="ms-auto text-center">
                                                {<>
                                                        <h2 className="month mb-1" style={{ "display": "block;" }}>{item.price_currency[0].currency_sign}{item.price_currency[0][activeTab.tabKey]}</h2>
                                                        <p className='mb-0'><small className="fs-13 text-muted">User/ Month</small></p>
                                                    </>}
                                            </div>
                                            <div className="mt-2 mb-4 text-center">
                                                <Button className="btn btn-primary pricingUpgradeNowBtn" onClick={(e) => {
                                                    e.preventDefault()
                                                    onCheckoutPlan(item)
                                                }}>{item.name.includes("gold")?"Contact to Sales":'Upgrade Now'}</Button>
                                            </div>
                                            <div className='row m-2'>
                                                {item.planTag ? <h6 className='text-center'>{`${item.planTag} +`}</h6>:<h6>{" "}</h6>}
                                            </div>
                                            <div className='row my-2'>
                                                <ul className="list-unstyled vstack gap-2 mb-2">
                                                    {(item.planPopUpFeatures || []).map((featureName: string, indexNo: number) => {
                                                        return (
                                                            <li id={`features-${indexNo}`} key={`feature-${indexNo}`}>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 text-success me-1">
                                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        {featureName}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        })}
                    </div>
                </ModalBody>
            </Modal>
            {isConfirmationPop && <ConfirmationModal isCancelled={userDetails?.chargebeeDetails?.status === "cancelled"}isOpen={true}  message={confirmationMessage} type={confirmationType?confirmationType:""} showUpgreadButton={false} onCancel={onCancel} isRedirect={isRedirect} onConfirm={onConfirm}/>}
        </>
    )
}
interface ShowUpGradePlanPopupType {
    className?:string;
    lable?:string;
    from?:string;
    isOpen?:boolean;
}
const showUpGradePlanPopup = ({isOpen,className,lable,from}:ShowUpGradePlanPopupType) => {
    return (isOpen && <UpGradePlanPopup isOpen={isOpen} className={className} lable={lable} from={from}/>)
}
export {showUpGradePlanPopup}
export default UpGradePlanPopup;