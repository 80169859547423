import React, { useEffect, useState } from "react";

//import 'react-select/dist/react-select.css';
import Select from 'react-select';

// import { Select, Space } from 'antd';

import { Row, Col, Form, Button } from "reactstrap";
import axios from "axios";

import config from "../../config";

// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, useNavigate, useLocation } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";

// hooks
import { useProfile } from "../../hooks";

//actions
import { systemLogoutUser, updateProfileData } from "../../redux/actions";
// Helper
import { addMixpanelEvent } from "../../helpers/mixpanel";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import PasswordChecklist from "react-password-checklist";

const countryData = require('country-data');

interface RegisterProps { }

const UpdateProfile = (props: RegisterProps) => {
    // global store
    const { dispatch, useAppSelector } = useRedux();
    const [selectedCountryCode, setSelectedCountryCode] = useState({ value: '91', label: '+91 (IN)' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [noEmployee, setNoEmployee] = useState();
    const [selectUsecase, setSelectUsecase] = useState();
    const [otherUseCase, setOtherUseCase] = useState('');

    const [valid, setValid] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [isValidationPopupOpen, setValidationPopupOpen] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const { userSuccess, userData} = useAppSelector(state => {
        return {
            userSuccess: state?.UpdateProfile?.user?.success,
            userData: state?.confirmAccount?.authData
        };
    });

    let navigate = useNavigate();

    const { userProfile } = useProfile();
    const authToken = userProfile?.authToken ?? '';
    const userId = userProfile?.userId
    axios.defaults.baseURL = config.API_URL;
    axios.defaults.headers.common['Authorization'] = authToken;

    useEffect(() => {
        if(!userProfile?.isOnboardingPending){
          navigate("/dashboard")
        }
      }, [userProfile])
      
    useEffect(() => {
        try {
            const timeoutId = setTimeout(() => {
                if (window.clarity) {
                    let clarityEmail = userProfile?.email || "no_email"
                    window.clarity("set", "email", clarityEmail);
                    window.clarity("identify", "email", clarityEmail);
                    window.clarity("set", "userId", clarityEmail);
                }
            }, 3000);
            return () => clearTimeout(timeoutId);
        } catch (err) {
            console.log("UpdateProfile try catch error ======>", err)
        }
    }, [])

    const resolver = yupResolver(
        yup.object().shape({
            firstName: yup
                .string()
                .required("Please enter First Name")
                .min(2, "First Name must be at least 5 characters")
                .test("notBlankSpaces", "First Name cannot be blank spaces", (value) => {
                    return value.trim() !== "";
                }),
            lastName: yup
                .string()
                .required("Please enter Last Name")
                .min(2, "Last Name must be at least 5 characters")
                .test("notBlankSpaces", "Last Name cannot be blank spaces", (value) => {
                    return value.trim() !== "";
                }),
            phoneNumber: yup
                .string()
                .required("Please enter Phone Number")
                .matches(/^[0-9]{8,13}$/, {
                    message: "Phone Number must be between 8 and 13 digits",
                    excludeEmptyString: true,
                }),
            companyName: yup.string().required("Please enter Company Name"),
            // employeeNo: yup.string().required("Please enter employee No"),
            // password: yup
            //     .string()
            //     .required("Please enter Password.")
            //     .min(8, "Password must be at least 8 characters")
            //     .max(22, "Password can have maximum 22 characters")
            //     .test("passwordRequirements", "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character", (value) => {
            //       // Regex pattern for at least one uppercase, one lowercase, one digit, and one special character
            //       const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
            //       return passwordRegex.test(value);
            //     })
            //     .test("notBlankSpaces", "Password cannot be blank spaces", (value) => {
            //         return value.trim() !== "";
            //     }),
        })
    );
    const countryCodes = countryData.callingCountries.all.map((country: any) => {
        const dialCode: string = country.countryCallingCodes[0].replace('+', '');
        const label: string = `+${dialCode} (${country.alpha2})`;
        return { value: dialCode, label };
    });
    const defaultValues: any = {};

    const methods = useForm({ defaultValues, resolver });
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = methods;

    const onSubmitForm = (values: any) => {
        setValid(false)
        const phoneNumber = selectedCountryCode?.value + values.phoneNumber;
        const fullName = values.firstName + " " + values.lastName;
        const payLoadData = {
            companyName: values.companyName,
            employeeNo: noEmployee,
            useCase: selectUsecase !== "Other" ? selectUsecase : otherUseCase,
            firstName: values.firstName,
            formStatus: true,
            fullName: fullName,
            lastName: values.lastName,
            password: password,
            phoneNumber: phoneNumber,
            country_code :selectedCountryCode?.value,
            _id: userId
        }
        addMixpanelEvent("Verify Email", { distinct_id: userId, "$email": userProfile?.email, "$phoneNumber": phoneNumber, "$name": fullName, })
        dispatch(updateProfileData(payLoadData, userId));
    };

    const handleCancel = () => {
        navigate("/auth-register")
    }

    // if(!userData?.isOnboardingPending &&  userData?.authToken){
    //     navigate("/welcome?addNumber=true")
    // }

    useEffect(() => {
      if(userSuccess){
        navigate("/welcome")
        window.location.reload()
      }
    }, [userSuccess])
    

    const handleCountryCodeChange = (selectedOption: any) => {
        setSelectedCountryCode(selectedOption);
    };

    const handleNoEmployee = (value: any) => {
        setNoEmployee(value.value)
    };

    const handleUsecase = (value: any) => {
        setSelectUsecase(value.value)
        if (value.value !== 'Other') {
            setOtherUseCase('');
        }
    };

    const handleOtherReasonChange = (e: any) => {
        setOtherUseCase(e.target.value);
    };

    const handleValidation = (rules: any) => {
      setValidationPopupOpen(rules);
    };

    const handlePasswordChange = (value: any) => {
        setPassword(value)
        setValidationPopupOpen(false)
      }

      const logOut = () =>{
        const payLoadData = {
             buttonLogout : true
        }
        dispatch(systemLogoutUser(payLoadData))
        navigate("/auth-login")
        addMixpanelEvent("Logout Account",{distinct_id:userProfile?.userId})
      }
    return (
        <NonAuthLayoutWrapper>

            <Row className="justify-content-center my-auto py-4">
                <Col className="col-12">
                    <div className="headtitle mb-4 d-flex align-items-center justify-content-between">
                        <h3 className="headTitleHeading mb-1">Update <span className="headTitleHeadingColored">Profile</span></h3>
                        <Link
                       to="/auth-login"
                       className="fw-medium text-decoration-underline"
                       onClick={logOut}
                     >
                       Log out
                     </Link>
                    </div>
                    {/* <a href="#" onClick={logOut}>Log out</a> */}
                </Col>
                <Col className="col-12">
                    <Form onSubmit={handleSubmit(onSubmitForm)} className="position-relative">
                        <div className="mb-3">
                            <FormInput
                                type="text"
                                name="firstName"
                                register={register}
                                errors={errors}
                                control={control}
                                labelClassName="form-label"
                                placeholder="Enter First Name"
                                className="form-control inputField"
                            />
                        </div>
                        <div className="mb-3">
                            <FormInput
                                type="text"
                                name="lastName"
                                register={register}
                                errors={errors}
                                control={control}
                                labelClassName="form-label"
                                placeholder="Enter Last Name"
                                className="form-control inputField"
                            />
                        </div>
                        <div className="mb-3 selectPhoneNbDrpDwnDiv">
                            {/* <label htmlFor="phoneNumber">Phone Number</label> */}
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <Select
                                        options={countryCodes}
                                        defaultValue={countryCodes[104]}
                                        classNamePrefix="select"
                                        className="input-group-text"
                                        onChange={handleCountryCodeChange}
                                        // menuIsOpen={true}
                                    />
                                </div>
                                <FormInput
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    register={register}
                                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                    placeholder="Phone Number"
                                />
                            </div>
                            {errors?.phoneNumber && (
                                <div className="invalid-feedback">{String(errors.phoneNumber.message)}</div>
                            )}
                        </div>
                        <div className="mb-3">
                            <Select
                                name="employeeNo"
                                placeholder={"How many seats do you need?"}
                                onChange={handleNoEmployee}
                                className="employeeNo"
                                options={[
                                    { value: '1-3', label: '1-3' },
                                    { value: '3-5', label: '3-5' },
                                    { value: '6-10', label: '6-10' },
                                    { value: '11-15', label: '11-15' },
                                    { value: '16-25', label: '16-25' },
                                    { value: '25+', label: '25+' },
                                ]}
                            />
                        </div>

                        <div>
                            <div className="mb-3">
                                <Select
                                    name="useCase"
                                    //   value={selectedOption}
                                    className="useCase"
                                    placeholder="What is your specific use case? "
                                    onChange={handleUsecase}
                                    options={[
                                        { value: 'Sales', label: 'Sales' },
                                        { value: 'Support', label: 'Support' },
                                        { value: 'Marketing & Promotion', label: 'Marketing & Promotion' },
                                        { value: 'Other', label: 'Other' },
                                    ]}
                                />
                            </div>

                            {selectUsecase && selectUsecase === 'Other' && (
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        name="otherUseCase"
                                        value={otherUseCase}
                                        onChange={handleOtherReasonChange}
                                        placeholder="Enter Other Reason"
                                        className="form-control inputField"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="mb-3">
                            <FormInput
                                type="text"
                                name="companyName"
                                register={register}
                                errors={errors}
                                control={control}
                                labelClassName="form-label"
                                placeholder="Enter Company Name"
                                className="form-control inputField"
                            />
                        </div>

                        <div className="mb-3 inputPassMainDiv">
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="Enter New Password"
                    className="form-control inputField newPassInput"
                    onChange={(e) => handlePasswordChange(e.target.value)}
                  />
                  <i className={`eyeIconPass ${!showPassword ? "ri-eye-fill align-middle" : "crossEyeIcon"}`} onClick={togglePasswordVisibility}></i>
                </div>
                <div className="passwordChecklist">
                  {password.length && !isValidationPopupOpen ? (
                    <PasswordChecklist
                      rules={["minLength", "maxLength", "specialChar", "number", "capital" ]}
                      minLength={8}
                      maxLength={22}
                      value={password}
                      messages={{
                        minLength: "Minimum 8 Characters",
                        maxLength: "Maximum 22 Characters",
                        specialChar: "Contain at least 1 Special Character",
                        number: "Contain at least 1 number",
                        capital: "Contain at least 1 Uppercase Letter",
                      }}
                      onChange={(rules) => handleValidation(rules)}
                    />
                  ):""}
                </div>
              </div>

                        <div className="text-center mt-4 mb-2">
                            <div className="row">
                                <div className="col-6">
                                    <button disabled={!valid || !isValidationPopupOpen} className="btn btn-primary w-100 authButtons font-size-16" type="submit">
                                        Save
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button onClick={handleCancel} className="btn btn-light w-100 authButtons font-size-16" type="button">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Col>
            </Row>

        </NonAuthLayoutWrapper>
    );
};

export default UpdateProfile;
