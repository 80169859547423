import styled from 'styled-components';
import ArrowDown from '../assets/images/icons/arrow_down.svg';
import DateRangeIcon from '../assets/images/icons/date_range.svg';

const TableListingPagesWrapper = styled.div`
    width: 100%;
    .tableListingMain{
        // width: 83.2% !important;
        // margin-left: auto;
        @media (max-width: 991.98px) {
            padding: 1rem !important;
        }
        .userListCardMain {
            .userListCard .ant-card-head .ant-card-head-title{
                white-space: unset !important;
            }
            .userListCard .ant-card-head .ant-card-head-title h2,
            .reportListCard .card-header h2{
                font-size: 17px !important;
                color: #333 !important;
                font-weight: 500 !important;
            }
            .userListCard .ant-card-head .ant-card-head-title p,
            .reportListCard .card-header p{
                font-size: 12px !important;
                color: #aaaaaa;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.5px;
            }
            .userListCard.ant-card,
            .userListCard .ant-card-head{
                border-radius: 0px !important;
            }
            .card-body{
                table{
                    tbody{
                        td{
                            border:none !important;
                        }
                    }
                }
            }
            .userListCard{
                background-color: white !important;
                border-top:unset !important;
                .ant-table-content{
                    @media (max-width: 991px) {
                        overflow-x: scroll;
                    }
                    .form-switch.numberPageSwitch{
                        .form-check-input:checked {
                            background-color: #0029c7 !important;
                        }
                    }
                    .numberPrivacyLink{
                        i{color:#686868 !important;}
                        &:hover{
                            i{color:#0029c7 !important;}
                        }
                    }
                }
                .ant-card-head{
                    padding-top: 6px !important;
                    padding-bottom: 6px !important;
                    .ant-card-head-wrapper{
                        @media(max-width:500px){
                            flex-direction: column;
                        }
                    }
                }
                .tableCreateBtn{
                    padding:8px 16px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        line-height:normal !important;
                    }
                }
                .ant-table{
                    font-size: 13px !important;
                    .ant-table-thead{
                        > tr {
                            position: sticky !important;
                            top: 0 !important;
                            background: white !important;
                            z-index: 10 !important;
                        }
                        tr{
                            th:first-child{
                                padding-left: 24px !important;
                            }
                            th:first-child{
                                padding-right: 24px !important;
                            }
                        }
                    }
                    .ant-table-tbody{
                        tr{
                            td:first-child{
                                padding-left: 24px !important;
                            }
                            td:first-child{
                                padding-right: 24px !important;
                            }
                        }
                    }
                }
                .tablePaginationPos{
                    position:fixed;
                    right:24px;
                    bottom:24px;
                }
                
                .btn-primary,
                .btn-primary:hover{
                    background-color: #0029c7 !important;
                    border-color: #0029c7 !important;
                }
                .ant-table-tbody > tr:last-child,.ant-table-tbody > tr:last-child td{
                    border-bottom: unset !important;
                }
                .ant-table-tbody >tr >td{
                    padding: 6px 16px !important;
                    border-bottom:none !important;
                }
                .ant-card-body{
                    overflow-y: auto;
                    border-top: 1px solid #f0f0f0;
                    padding: 0px!important;
                    height: calc(100vh - 204px);
                    &::-webkit-scrollbar-thumb {
                        background-color: #c1c1c1;
                        outline: unset!important;
                        border-radius: 14px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1!important;
                    }
                    &::-webkit-scrollbar {
                        width: 0.4em;
                    }
                    .statusOffline{
                        color: red !important;
                    }
                    .statusOnline{
                        color: green !important;
                    }
                }
            }
            .reportListCard{
                border-radius:8px !important;
                .card-header{
                    border-radius:0px !important;
                    border-bottom:1px solid #f0f0f0 !important;
                    background-color:#fff !important;
                    .reportUserDropdown{
                        min-width: 240px !important;
                        max-width: 300px !important;
                        .dropdown-toggle {
                          height: 32px !important;
                          padding: 0px 20px 0px 8px !important;
                          font-size: 14px !important;
                          border: 1px solid #d1d1d1 !important;
                          position: relative !important;
                          &::after{
                            position: absolute !important;
                            content: "";
                            top: 7px;
                            right: 2px;
                            background-image: url(${ArrowDown});
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center !important;
                            display: block;
                            height: 18px !important;
                            width: 18px !important;
                          }
                          .wpLinkedUserName ,.wpLinkedUserNumber{
                            font-size: 13px !important;
                          }
                        }
                        .dropdown-item{
                          padding: 4px 10px 4px 16px !important;
                          font-size: 13px !important;
                          .dropItemUserNumber,.dropItemUserName {
                            font-size: 12px !important;
                          }
                        }
                    }
                    .datePickerClass{
                        display: flex;
                        justify-content: between;
                      }
                      .ctrlhDatePicker{
                        position: relative !important;
                        .react-datepicker-wrapper{
                          .react-datepicker__input-container{
                            input{
                              margin: 2px;
                              height: 32px;
                              color: #464646 !important;
                              border: 1px solid #d1d1d1 !important;
                              outline: unset !important;
                              padding-left: 8px !important;
                              text-align: center;
                              border-radius: 4px !important;
                              font-size: 13px !important;
                              padding-right: 20px !important;
                            }
                            button{
                              display: none !important;
                            }
                          }
                        }
                        .datePickerIcon{
                          position: absolute !important;
                          content: "";
                          top: 7px;
                          right: 5px;
                          background-image: url(${DateRangeIcon});
                          background-size: contain;
                          background-repeat: no-repeat;
                          background-position: center !important;
                          display: block;
                          height: 16px !important;
                          width: 16px !important;
                          margin-top: 3px !important;
                          line-height: 1 !important;
                          pointer-events: none !important;
                          opacity: 0.5;
                        }
                    }
                }
                .card-body{
                    border-radius:0px 0px 8px 8px !important;
                    padding: 0 !important;
                    .table-responsive {
                        max-height: calc(100vh - 180px) !important;
                        position: relative !important;
                        &: :-webkit-scrollbar {
                            width: 8px;
                        }
                        &: :-webkit-scrollbar-thumb {
                            background-color: #9f9f9f;
                            border-radius: 14px;
                        }
                        &: :-webkit-scrollbar-track {
                            background: #e8e8e8;
                        }
                        .table{
                            font-size:13px !important;
                            thead {
                                position: sticky;
                                top: 0;
                                background-color: white !important;
                                th{
                                    padding:16px !important;
                                    border-bottom:1px solid #f0f0f0 !important;
                                    background-color: #fafafa !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .btnEdit,.btnDanger,.scanBtn {
        height: 26px !important;
        width: 26px !important;
        max-width: 26px !important;
        min-width: 26px !important;
        padding: 0 !important;
        i{
            font-size: 16px!important;
        }
    }
    .noTableDataImgWrapper{
        @media (max-width:768px) {
            width: 90% !important;
        }
    }
    .noTableDataImg{
        max-width: 425px;
        @media (max-width:500px) {
            max-width: 100% !important;
        }
    }
`;

export { TableListingPagesWrapper};