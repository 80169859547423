import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form, Button, UncontrolledTooltip } from "reactstrap";
import { addMixpanelEvent } from "../../helpers/mixpanel";
// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, Navigate, useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// hooks
import { useProfile } from "../../hooks";

//actions
import { registerUser, clearRegisterReducer } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";

//images
import ControlHippoBlueLogo from "../../assets/images/auth-images/ControlHippo_blue_logo.svg";
import GreenMailLockIcon from "../../assets/images/green_mail_lock.svg";
import ColoredEmailIcon from "../../assets/images/colored_email_icon.svg";

const recaptchaRef: any = React.createRef();
const recaptcha_site_key: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

interface RegisterProps { }
const Register = (props: RegisterProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const navigate = useNavigate();
  const { userProfile } = useProfile();
  const [registerPage, setRegisterPage] = useState(false)
  const { user, registrationError, regLoading, userSuccess } = useAppSelector(state => ({
    user: state?.Register?.user,
    userSuccess: state?.Register?.userSuccess,
    registrationError: state.Register.registrationError,
    regLoading: state.Register.loading,
    isUserRegistered: state.Register.isUserRegistered,
  }));
  const userEmail = user?.user?.email
  const verificationLink = user?.verificationLink

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .transform((value) => value.trim()) // Trim any whitespace
        .email("This value should be a valid email.")
        .required("Please enter an email."),
    })
  );

  const defaultValues: any = {};
  useEffect(() => {
    if (user?.user?._id && user?.user?.email) {
      const email = user?.user?.email?.toLowerCase();
      addMixpanelEvent("SignUp", { distinct_id: user?.user?._id, "$email": email })
    }
  }, [user])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(userProfile?.authToken){
  //       navigate('/dashboard')
  //      } 
  //   }, 1000);
  //  }, [])

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = async (values: any) => {
    const token = await recaptchaRef.current.executeAsync();
    const updatedValues = {
      ...values,
      recaptcha: token
    };
    // const email = values.email?.toLowerCase();
    // addMixpanelEvent("SignUp", { distinct_id: email, "$email": email })
    dispatch(registerUser(updatedValues));
  };

  useEffect(() => {
    dispatch(clearRegisterReducer())
  }, [])

  useEffect(() => {
    setRegisterPage(userSuccess ? true : false);
  }, [userSuccess]);

  const handleChangeState = () => {
    setRegisterPage(false)
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col className="pt-0 pb-0">
          {!registerPage &&
            <>
              {/* <div className="signupLoginHeader m-0 row mb-5 pt-lg-3">
                <div className="signupLoginInnrHeader col-md-12 p-0 align-items-center d-flex justify-content-between">
                  <img src={ControlHippoBlueLogo} alt="ControlHippo" />
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link
                      to="/auth-login"
                      className="fw-medium text-decoration-underline"
                    >
                      {" "}
                      Login
                    </Link>
                  </p>
                </div>
              </div> */}
              <div className="pt-3 pt-md-5">
                <div className="headtitle mb-4">
                  <h3 className="headTitleHeading">Start Your <span className="headTitleHeadingColored">Free Trial!</span></h3>
                </div>

                {user?.message != undefined ? (
                  <Alert color="primary">{user?.message}</Alert>
                ) : null}

                <Form
                  onSubmit={handleSubmit(onSubmitForm)}
                  className="position-relative registerSignForm"
                >
                  {regLoading && <Loader />}
                  <div className="mb-3">
                    <FormInput
                      type="text"
                      name="email"
                      register={register}
                      errors={errors}
                      control={control}
                      labelClassName="form-label"
                      placeholder="Enter Email"
                      className="form-control inputField"
                    />
                  </div>

                  <div className="pt-2">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={recaptcha_site_key}
                    />
                  </div>

                  <div className="text-center mb-5">
                    <Button
                      color="primary"
                      className="w-100  waves-effect waves-light inputFieldButton font-size-16"
                      type="submit"
                    >
                      Start Free Trial
                    </Button>
                  </div>

                  <div className="mb-4">
                    <p className="mb-0 text-center">
                      By signing up, you agree to our {" "}
                      <Link target="_blank" to="https://controlhippo.com/terms">
                        Terms
                      </Link>
                      {" "} & {" "}
                      <Link target="_blank" to="https://controlhippo.com/privacy">
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </>
          }

          {
            registerPage &&
            <div className="thankyouMainBlock mb-2 d-block">
              <div className="thankyouBlockInnerWr text-center p-4 px-5">
                <img src={GreenMailLockIcon} alt="Mail Lock" className="mailLockIcon mb-2" />
                <h4 className="mb-3">Check your Inbox</h4>
                <p className="mb-1">We've Sent an email to</p>
                <p className="mb-1"><strong>{userEmail}</strong></p>
                <p>with a link to complete your account setup.</p>
                <Link className="openGmailBtn p-2 mb-3" target="_blank" to="https://mail.google.com/mail/u/0/#search/from%3A(notifications%40controlhippo.com)+in%3Aanywhere+newer_than%3A100h">
                  <img className="openGmailBtnImg" src={ColoredEmailIcon} alt="Gmail Icon" /> Open Gmail <i className="mdi mdi-open-in-new"></i>
                </Link>
                <div className="thankyouTrouble d-flex align-items-center justify-content-center gap-1">
                  <span className="thankyouTroubleText">Having trouble?</span>
                  {/* <Link className="thankyouTroubleText" target="_blank" to="#">Resend email</Link> */}
                  {/* <span className="thankyouTroubleText">or</span> */}
                  <Link onClick={handleChangeState} className="thankyouTroubleText" to="/auth-register">Try a differnt email  </Link>
                </div>
              </div>
            </div>
          }
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
