import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Form, Input, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Radio } from 'antd';
import { ChatIndex } from './ChatIndex.style';
import { toast } from "react-toastify";
import MultipleNumberFilter from "./multipleNumberFilter";
import { useRedux, useProfile, updateAuthData } from "../../../hooks/index";
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications";
import { FilterSideMenu } from './FilterDrawer';
import { LabelSideMenu } from './LabelDrawer';

import {
  inviteContact,
  resetContacts,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  changeSelectedChat,
  getChatUserDetails,
  getChatUserConversations,
  getChannelDetails,
  updateClientSession,
  CreateChannelPostData,
  createUser,
  getLabels,
  sendSeenApi,
  clearReducerDataAction,
  createTelegramUser
} from "../../../redux/actions";

import AppSimpleBar from "../../../components/AppSimpleBar";
import AddGroupModal from "../../../components/AddGroupModal";
import InviteContactModal from "../../../components/InviteContactModal";
import ContactModal from "../../../components/ContactModal";
import AddButton from "../../../components/AddButton";
import DirectMessages from "./DirectMessages";
import { CHATS_TABS } from "../../../constants";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
import { useNavigate } from "react-router";
import { logoutUser } from "../../../redux/actions";
import { TABS } from "../../../constants/index";
import avatar1 from "../../../assets/images/icons/user_profile_icon.svg";
import NoChatUserIcon from '../../../assets/images/no_chat_user_icon.svg';

import { Link } from "react-router-dom";

interface IndexProps { }
interface AuthData {
  email: string;
  authToken: string;
  clientId: string;
}
const Index = (props: IndexProps) => {
  const storedData = localStorage.getItem("authData");
  const authData: AuthData | null = storedData ? JSON.parse(storedData) : null;
  const { dispatch, useAppSelector } = useRedux();
  const [chatUserList, setChatuserList] = useState([]);
  const [limit] = useState(50);
  const { userProfile } = useProfile();
  const [clientDisconnected, setClientDisconnected] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openNewDropdown, setOpenNewDropdown] = useState<boolean>(false);
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const localStorageChatFilter = localStorage.getItem('selectedChatFilter');
  const localStoragelabelChatId = localStorage.getItem("labelChatId")
  const [selctedFilter, setSelctedFilter] = useState<any>(localStorageChatFilter ? localStorageChatFilter : "All");
  // localStorage.setItem("selectedChatFilter", selctedFilter);
  const [showSearchBarInput, setshowSearchBarInput] = useState(false);
  const [chatCount, setChatCount] = useState<any>();
  const [labelItemValue, setLabelItemValue] = useState<any>(localStoragelabelChatId);
  const [filterChatCount, setFilterChatCount] = useState<any>();
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Specify the type explicitly
  const [userdropdownOpen, setUserDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedClient, setSelectedClient] = useState<any>("");
  const [dynamicValue, setDynamicValue] = useState<any>((localStorageChatFilter == "Resolved Chats" || localStorageChatFilter == "Unread Chats" || localStorageChatFilter == "Unassigned Chats" || localStorageChatFilter == "DND Chats" || localStorageChatFilter == "Label Chats") ? "more" : localStorageChatFilter);
  const toggle = () => setUserDropdownOpen(!userdropdownOpen);
  const ref = useRef<any>();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logOut = () => {
    dispatch(logoutUser())
    addMixpanelEvent("Logout Account", { distinct_id: userProfile?.userId })
  }

  const handleCreateContact = () => {
    openAddContactModal()
  };

  const handleCreateGroup = () => {
    openCreateChannelModal()
  };

  let {
    isContactInvited,
    directMessages,
    createdUser,
    isChannelCreated,
    selectedChat,
    clientId,
    receivedMessage,
    // clientDisconnected,
    clientList,
    isChatUsersSyncing,
    getChatList,
    errorMsg,
    unReadBadge,
    getUserConversationsLoading,
    oldMessageSyncComplete,
    clientsPayload,
    labels,
    chatListCount,
    responseKey,
    userData
  } = useAppSelector(state => {
    return ({
      isContactInvited: state.Contacts.isContactInvited,
      directMessages: state.Chats.directMessages,
      chatListCount: state.Chats.chatListCount,
      responseKey: state.Chats.responseKey,
      createdUser: state.Chats.createdUser,
      errorMsg: state.Chats.errorMsg,
      getChatList: state.Chats.skipChatUserList,
      isChannelCreated: state.Chats.isChannelCreated,
      selectedChat: state.Chats.selectedChat,
      receivedMessage: state.Chats.receivedMessage,
      clientId: state.Chats.clientId,
      // clientDisconnected: state.Chats.clientDisconnected,
      clientList: state.Chats.clientList,
      clientsPayload: state.Chats.clientsPayload,
      isChatUsersSyncing: state.Chats.isChatUsersSyncing,
      clientName: state.Chats.clientName,
      // clienPhoneNumber: state.Chats.clienPhoneNumber,
      unReadBadge: state.Chats.unReadBadge,
      getUserConversationsLoading: state.Chats.getUserConversationsLoading,
      oldMessageSyncComplete: state.Chats.oldMessageSyncComplete,
      labels: state.Chats.labels,
      userData: state.Login.user,
    })
  });
  const [userDetails, setUserDetails] = useState<any>()
  const [clientInfo, setClientInfo] = useState<string>(clientId)

  useEffect(() => {
    dispatch(getLabels());
    setUserDetails(userData)
    setClientInfo(clientId)
  }, [])
  useEffect(() => {
    setUserDetails(userData)
  }, [userData])

  useEffect(() => {
    setIsDisabled(getUserConversationsLoading)
  }, [getUserConversationsLoading])


  useEffect(() => {
    if (directMessages?.length > 99) {
      setChatCount("99+")
    } else {
      setChatCount(directMessages?.length)
    }

  }, [directMessages])

  useEffect(() => {
    if (createdUser && createdUser?.id) {
      if (!createdUser?.dndStart) {
        onSelectChat(createdUser?.id)
        if (isOpenAddContact) {
          closeModal()
          closeAddContactModal()
          setTimeout(() => {
            showSuccessNotification({ message: "Contact creation successful." })
          }, 1000);
        }
      }
    }
  }, [createdUser])

  useEffect(() => {
    if (userProfile?.clientId !== "all_numbers") {
      if (!directMessages?.length) {
        dispatch(getDirectMessages(userProfile?.clientId, 0, limit, localStorageChatFilter ? localStorageChatFilter : "All", labelItemValue));
      }
      dispatch(updateClientSession(userProfile?.clientId));
    } else {
      dispatch(getDirectMessages("all_numbers", 0, 50, localStorageChatFilter ? localStorageChatFilter : "All", localStoragelabelChatId));
    }
  }, []);


  useEffect(() => {
    let clientlistData: any;
    if (clientList && userProfile?.clientId) {
      clientlistData = clientList.find((item: any) => item.clientId === userProfile?.clientId);
    }
    if (clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)) {
      setClientDisconnected(false)
    } else if (clientlistData) {
      setClientDisconnected(true)
    }
  }, [clientList, userProfile?.clientId])
  useEffect(() => {
    if (clientsPayload?.success) {
      updateAuthData(clientsPayload.authData)
    }
  }, [clientsPayload])

  // useEffect(()=>{
  //   if(getChatList.hasNext)onScrollGetChatList(true)

  // },[getChatList])

  useEffect(() => {
    if (directMessages) {
      setChatuserList(directMessages)
    }
  }, [dispatch, directMessages]);

  useEffect(() => {
    if (receivedMessage && receivedMessage?.data?.type !== "banner" && (receivedMessage.clientId == clientId)) {
      // dispatch(getQrCode(inputText));
      console.log(`receivedMessage called`)
      // return
      const chatExist = receivedMessage.data && receivedMessage?.data?.chatId && directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage?.data?.chatId)));
      if (chatExist !== -1 && receivedMessage?.data?.chatId && (receivedMessage.clientId == clientId) && directMessages?.length) {
        const itemToShift = directMessages[chatExist];
        directMessages.splice(chatExist, 1); // Remove the item from its current position
        directMessages.unshift(itemToShift); // Add the item to the first position

        if ((selectedChat !== receivedMessage?.data?.chatId) && !receivedMessage.data.fromMe) {
          const selectChatExist = directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage?.data?.chatId)));
          if (selectChatExist !== -1) {

            if (!directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 1 };
            } else {
              directMessages[selectChatExist].meta.unRead = directMessages[selectChatExist].meta.unRead ? directMessages[selectChatExist].meta.unRead + 1 : 1;
            }
            directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
            directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
            directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
            directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId
          }
        } else if (!receivedMessage.data.fromMe && directMessages?.length) {
          setTimeout(() => {
            (receivedMessage?.data?.channelType == "whatsapp" || receivedMessage?.data?.channelType == "messenger") && dispatch(sendSeenApi(receivedMessage?.data?.chatId, clientId))
          }, 2000);
          const selectChatExist = directMessages?.findIndex((item: any) => item?.chatId === receivedMessage?.data?.chatId);
          directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
          directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
          directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
          directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId

          if (selectChatExist !== -1) {
            if (directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 0 };
            }
          }
        }
        else {
          const selectChatExist = directMessages?.length && directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage?.data?.chatId)));
          directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
          directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
          directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
          directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId
        }
        setChatuserList(directMessages)
      }
      dispatch(clearReducerDataAction());
    }
  }, [receivedMessage]);
  useEffect(() => {
    if (unReadBadge) {
      console.log(`receivedMessage called :: unReadBadge`)
      const chatExist = unReadBadge?.data && unReadBadge?.data?.chatId && directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === unReadBadge?.data?.chatId)));
      if (chatExist !== -1 && unReadBadge?.data && unReadBadge?.data?.chatId && unReadBadge?.data?.chatId && ((userProfile?.clientId !== "all_numbers" && unReadBadge.clientId == userProfile?.clientId) || (unReadBadge.data.chatId == directMessages[chatExist]?.chatId))) {
        // if ( (selectedChat !== unReadBadge.data.chatId)&& (!unReadBadge.data.fromMe )) {
        if (((selectedChat !== unReadBadge?.data?.chatId) || (selectedChat == unReadBadge?.data?.chatId)) && (!unReadBadge.data.fromMe) && directMessages?.length) {
          const selectChatExist = directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === unReadBadge?.data?.chatId)));
          if (selectChatExist !== -1) {
            if (directMessages[selectChatExist].meta && directMessages[selectChatExist].meta.unRead) {
              directMessages[selectChatExist].meta.unRead = 0;
            }
          }
        }
        setChatuserList(directMessages)
        dispatch(clearReducerDataAction());
      }
    }
  }, [unReadBadge]);


  useEffect(() => {
    if (receivedMessage && receivedMessage?.data?.type !== "banner" && clientId == "all_numbers" && directMessages?.length) {
      const chatExist = receivedMessage.data && receivedMessage?.data?.chatId && directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage?.data?.chatId)));
      if (chatExist !== -1 && receivedMessage?.data?.chatId) {
        const itemToShift = directMessages[chatExist];
        directMessages.splice(chatExist, 1); // Remove the item from its current position
        directMessages.unshift(itemToShift); // Add the item to the first position

        if ((selectedChat !== receivedMessage?.data?.chatId) && !receivedMessage.data.fromMe) {
          const selectChatExist = directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage?.data?.chatId)));
          if (selectChatExist !== -1) {

            if (!directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 1 };
            } else {
              directMessages[selectChatExist].meta.unRead = directMessages[selectChatExist].meta.unRead ? directMessages[selectChatExist].meta.unRead + 1 : 1;
            }
            directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
            directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
            directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
            directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId
          }
        } else if (!receivedMessage.data.fromMe) {
          setTimeout(() => {
            (receivedMessage?.data?.channelType == "whatsapp" || receivedMessage?.data?.channelType == "messenger") && dispatch(sendSeenApi(receivedMessage?.data?.chatId, clientId = receivedMessage?.clientId))
          }, 2000);
          const selectChatExist = directMessages?.findIndex((item: any) => item?.chatId === receivedMessage?.data?.chatId);
          if (selectChatExist !== -1) {
            directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
            directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
            directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
            directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId
            if (directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 0 };
            }
          }
        }
        else {
          const selectChatExist = directMessages?.findIndex((item: any) => (item?.chatId && (item?.chatId === receivedMessage.data.chatId)));
          if (selectChatExist !== -1) {
            directMessages[selectChatExist].lastMessageText = receivedMessage?.data?.text ? receivedMessage?.data?.text : receivedMessage?.data?.caption
            directMessages[selectChatExist].lastMessageType = receivedMessage?.data?.type
            directMessages[selectChatExist].lastMessageAt = receivedMessage?.data?.createdAt
            directMessages[selectChatExist].lastMessageId = receivedMessage?.data?.conversationId
          }
        }
        setChatuserList(directMessages)
      }
    }
  }, [receivedMessage || clientId == "all_numbers"])



  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const openModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const openModal2 = () => {
    setIsOpen2(!openNewDropdown);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data: any, clientId: string) => {
    var clientId1 = clientId !== "all_numbers" ? clientId : clientList[0]?.clientId
    const clientDetails = clientList.find((item: any) => item.clientId === clientId1)
    if (clientDetails.type === "telegram") {
      dispatch(createTelegramUser({ chatId: data.countryCode + data.phoneNumber + "@c.us", clientId: clientId1, data }, (response: any) => {
        if (!response?.success) {
          showErrorNotification(response.message)
        }
      }))
    } else {
      dispatch(createUser({ chatId: data.countryCode + data.phoneNumber + "@c.us", clientId: clientId1, data }, (response: any) => {
        if (!response?.success) {
          showErrorNotification(response.message)
        }
      }))
      // dispatch(inviteContact(data,clientId));
    };
  }
  useEffect(() => {
    if (isContactInvited) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetContacts("isContactInvited", false));
      }, 1000);
    }
  }, [dispatch, isContactInvited]);

  /*
  contact add handeling
  */
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const [isResponseSuccess, setResponseSuccess] = useState(true);
  const openAddContactModal = () => {
    setIsOpenAddContact(true);
  };


  const handleFilterTab = async (filterType: any) => {
    try {
      if (clientId !== "all_numbers") {
        await dispatch(getDirectMessages(clientId, 0, limit, filterType, labelItemValue));
        setResponseSuccess(true);
      } else {
        if (!searchInputValue)
          await dispatch(getDirectMessages("all_numbers", 0, 50, filterType, localStoragelabelChatId))
        setResponseSuccess(true);
      }
    } catch (error) {
      setResponseSuccess(false);
    }
    setSelctedFilter(filterType);
  };

  // useEffect(() => {
  //   if(localStorageChatFilter && responseKey)
  //   handleFilterTab(localStorageChatFilter)
  // }, [responseKey])

  const handleDropdownItemClick = (item: any) => {
    setOpenNewDropdown(!openNewDropdown);
    dispatch(getDirectMessages(clientId, 0, limit, "Label Chats", item.value))
    setLabelItemValue(item.value)
    localStorage.setItem("labelChatId", item.value)
    setSelctedFilter("Label Chats");

  }
  const handleLabelChat = () => {
    setOpenNewDropdown(!openNewDropdown)
    setSelctedFilter("Label Chats");
  };



  const closeAddContactModal = () => {
    setIsOpenAddContact(false);
  };
  const onAddContact = (contacts: Array<string | number>) => {
    dispatch(addContacts(contacts));
  };
  useEffect(() => {
    // if (isContactsAdded) {
    //   setIsOpenAddContact(false);
    //   dispatch(getDirectMessages(clientId));
    // }
    let clientlistData: any;
    if (clientList && clientId) {
      clientlistData = clientList.find((item: any) => item.clientId === clientId);
    }
    if (clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)) {
      setClientDisconnected(false)
    } else if (clientlistData) {
      setClientDisconnected(true)
    }
    setClientInfo(clientId)
  }, [clientId]);

  useEffect(() => {
    let authData: any = localStorage.getItem("authData")
    authData = authData ? JSON.parse(authData) : null
    if (clientList?.length) {
      const clientlistData = clientList.find((item: any) => item.clientId === authData?.clientId);
      setSelectedClient(clientlistData)
    }
  }, [clientList])


  /*
  channel creation handeling
  */
  const [isOpenCreateChannel, setIsOpenCreateChannel] =
    useState<boolean>(false);
  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };
  const closeCreateChannelModal = () => {
    dispatch(getDirectMessages(userProfile?.clientId, 0, 50, selctedFilter, 0))
    setIsOpenCreateChannel(false);
  };
  const onCreateChannel = (channelData: CreateChannelPostData) => {
    dispatch(createChannel(channelData));
  };
  useEffect(() => {
    if (isChannelCreated) {
      setIsOpenCreateChannel(false);
      dispatch(getDirectMessages(userProfile?.clientId, 0, 50, selctedFilter, 0))
      //dispatch(getChannels());
    }
  }, [dispatch, isChannelCreated]);

  /*
  select chat handeling :
    get conversations
    get chat user details
  */
  useEffect(() => {
    if (oldMessageSyncComplete && oldMessageSyncComplete?.data?.chatId && oldMessageSyncComplete?.data?.clientId) {
      const clientId = oldMessageSyncComplete.data.clientId
      const chatId = oldMessageSyncComplete.data.chatId
      if (chatId == selectedChat) {
        dispatch(getChatUserConversations(chatId, clientId));
      }

    }
  }, [oldMessageSyncComplete])

  const onSelectChat = async (id: string | number, clientId?: string | number, isChannel?: boolean) => {
    clientId = clientInfo ? clientInfo : clientId ? clientId : userProfile?.clientId
    // if (isChannel) {
    //   dispatch(getChannelDetails(id));
    // } else 
    var aaa = await directMessages?.findIndex((item: any) => (item.clientId && (item.clientId === clientId)))
    if (id !== selectedChat || (aaa !== -1 && directMessages[aaa].clientId === clientId)) {
      dispatch(getChatUserDetails(id, clientId));
      dispatch(getLabels());
      dispatch(changeSelectedChat(id));
      dispatch(getChatUserConversations(id, clientId));
      dispatch(clearReducerDataAction());
    }
    // dispatch(readConversation(id));
    addMixpanelEvent("LastSeenAt", { distinct_id: userProfile?.userId, LastSeenAt: new Date })
    const selectChatExist = await directMessages?.length && directMessages?.findIndex((item: any) => (item?.chatId === id));
    const clients = directMessages[selectChatExist].clients
    const clientDetails = await (clients || []).find((item: any) => item.clientId == clientId)
    if (selectChatExist !== -1 && (clientDetails?.clientId == clientId || clientId == "all_numbers")) {
      if (directMessages[selectChatExist].meta) {
        directMessages[selectChatExist].meta = { unRead: 0 };
      }
    }
    setChatuserList(directMessages)
  };

  /*
  tab handeling
  */
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const onChangeTab = (tab: CHATS_TABS) => {
    setActive(tab);
  };

  //serach recent user

  function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number) {
    let timeoutID: number | undefined;

    const debounced = (...args: Args) => {
      clearTimeout(timeoutID);
      timeoutID = window.setTimeout(() => fn(...args), delay);
    };

    return debounced;
  }

  const searchUsers = (event: any) => {
    event.preventDefault();
    let inputValue: string = event?.target?.value;
    // Trim leading and trailing spaces
    inputValue = inputValue.trim();

    const containsNumbersOrPlus = /[\d+]/.test(inputValue);
    if (containsNumbersOrPlus) {
      inputValue = inputValue.replace(/\+|\s/g, '');
    }
    setSearchInputValue(inputValue);
    if (clientId !== "all_numbers") {
      dispatch(getDirectMessages(clientId, 0, limit, localStorageChatFilter ? localStorageChatFilter : "All", labelItemValue, inputValue));
    } else {
      dispatch(getDirectMessages("all_numbers", 0, 50, localStorageChatFilter ? localStorageChatFilter : "All", localStoragelabelChatId, inputValue));
    }
  };

  const debouncedSearch = debounce(searchUsers, 700);


  // const onScrollGetChatList = (value:any) => {
  //   if(!isChatUsersSyncing)dispatch(getDirectMessages(clientId,getChatList.skip+1,limit,selctedFilter, null))
  // }
  const chatListRef = useRef(null);
  const setClientConnection = (value: any) => {
    setClientDisconnected(value)
  }
  // const setClientPhone= (value :any) => {
  //   setclientPhoneNumber(value)
  // }

  useEffect(() => {
    if (clientList && clientId) {
      let clientlistData: any = clientList.find((item: any) => item.clientId === clientId);
      if (clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)) {
        setClientDisconnected(false)
      } else if (clientlistData) {
        setClientDisconnected(true)
      }
    }
  }, [clientList, clientId])

  const navigate = useNavigate()

  const handleClick = () => {
    const path = '/welcome';
    addMixpanelEvent("Add Channel Number", { distinct_id: userProfile?.userId })
    const queryParams: any = {
      addNumber: true
    };
    const index = clientList?.findIndex((obj: any) => obj.clientId === clientId);
    const queryString = new URLSearchParams(queryParams).toString();
    const fullPath = `${path}`;
    let sessionExist = clientList[index]
    if ((clientList[index]?.isReady || clientList[index]?.isSessionSaved) || (clientList.length <= 0)) {
      navigate(fullPath);
    } else {
      navigate(`${path}`);
    }
  };

  const handleClickAddNumber = () => {
    const path = '/welcome';
    addMixpanelEvent("Add Channel Number", { distinct_id: userProfile?.userId })
    const queryParams: any = {
      addNumber: true
    };
    const queryString = new URLSearchParams(queryParams).toString();
    const fullPath = `${path}`;
    navigate(fullPath);
  };


  const closeDropdown = () => {
    setOpenNewDropdown(false);
  };

  const handleClickOutside = (event: Event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (openNewDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openNewDropdown]);

  const handleSerachCancle = () => {
    setshowSearchBarInput(!showSearchBarInput);
    setSearchInputValue("")
    if (showSearchBarInput) {
      // dispatch(getDirectMessages(userProfile.clientId, 0, limit,selctedFilter, labelItemValue))
      dispatch(getDirectMessages(clientId, 0, limit, localStorageChatFilter ? localStorageChatFilter : "All", labelItemValue))
    }
  };

  const handleRescan = async () => {
    const index = await clientList.find((item: any) => item.clientId === clientId);
    const fullPath = `/channels/reconnect-${index.type}/${index._id}`;
    if (index && (!index.isReady || !index.isSessionSaved)) {
      navigate(fullPath, { state: { numberData: index } });
    }
  };

  useEffect(() => {
    if (clientList.length == 0) {
      const data = {
        clientId: null
      };
      updateAuthData(data);
    }
  }, [clientList])



  const onScrollChange = (data: any) => {
    if (data) {
      if (getChatList.hasNext) {
        // dispatch(getDirectMessages(userProfile.clientId, getChatList.skip + 1, limit, selctedFilter, labelItemValue))
        dispatch(getDirectMessages(clientId, getChatList.skip + 1, limit, selctedFilter, labelItemValue))
      }
    }
  }


  const handleScroll = () => {
    const scrollElement = ref.current.getScrollElement();
    const tolerance = 3; // Adjust tolerance as needed
    const scrollTop = scrollElement.scrollTop;
    const scrollHeight = scrollElement.scrollHeight;
    const clientHeight = scrollElement.clientHeight;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= tolerance;
    if (bottom) {
      if (chatUserList.length > 0) {
        onScrollChange(true);
      }
    }
  }
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
      ref.current.getScrollElement().addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref.current) {
        ref.current.getScrollElement().removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatUserList]);


  useEffect(() => {
    if (localStorageChatFilter == "Resolved Chats" || localStorageChatFilter == "Unread Chats" || localStorageChatFilter == "Unassigned Chats" || localStorageChatFilter == "DND Chats" || localStorageChatFilter == "Label Chats") {
      setDynamicValue("more")
    } else {
      setDynamicValue(localStorageChatFilter)
    }
  }, [localStorageChatFilter])

  const handleClientChange = () => {
    setshowSearchBarInput(false)
  }

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [isLabelDrawerOpen, setLabelDrawerOpen] = useState(false);

  const openFilterDrawer = () => {
    setLabelDrawerOpen(false); // Close the label drawer if it's open
    setFilterDrawerOpen(true);
  };

  const closeFilterDrawer = () => {
    setFilterDrawerOpen(false);
  };

  const openLabelDrawer = () => {
    setFilterDrawerOpen(false); // Close the filter drawer if it's open
    setLabelDrawerOpen(true);
  };

  const closeLabelDrawer = () => {
    setLabelDrawerOpen(false);
  };

  function capitalizeFirstChar(name: string | null): string {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }
  return (
    <>
      <ChatIndex>
        <div className="mobStickyChatSidebar">
          <div className={`d-flex align-items-center ${clientId ? false ? 'logged_out_main' : 'logged_in_main' : 'logged_out_main logged_in_main'}`}>
            {clientList.length && clientId ? <>
              {false && clientDisconnected && clientId != "all_numbers" ?
                <div className="d-flex align-items-center chatTopDropDownDiv gap-2">
                  <div className="d-flex align-items-center justify-content-center circle_status_icon">
                    <a href="javascript:void(0)" onClick={handleRescan} id="welcomeLink" className="d-flex align-items-center justify-content-center chatAddScanIconMobHide">
                      <i className="ri-qr-scan-2-line"></i>
                    </a>
                  </div>
                  <div className="width_connection_main">
                    <div className="mb-1">
                      <a className="online_status colorRed"> Disconnected <a href='javascript:void(0)' onClick={handleRescan}>(Reconnect?)</a> </a>
                    </div>
                    <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId} handleClientChange={handleClientChange} />
                  </div>
                </div>
                :
                <div className="d-flex align-items-center chatTopDropDownDiv gap-2">
                  <div className="width_connection_main d-flex align-items-center gap-2">
                    <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId} handleClientChange={handleClientChange} />
                    {/* <Button  className="circle_status_icon d-flex align-items-center justify-content-center chatAddScanIconMobHide" disabled={isChatUsersSyncing } color="light" type="button" id="addWhatsApp" onClick={handleClick}>
                        <i className="bx bx-plus align-middle"></i>
                    </Button>
                    <UncontrolledTooltip target="addWhatsApp" placement="right">
                    Add Channel
                    </UncontrolledTooltip> */}
                    {/* <span className="connection_status">Connection Status:</span> */}
                    {/* <a className="online_status"> Connected</a> */}
                    {/* <div className="d-flex align-items-center justify-content-between"><a href="/welcome" className="logged_in_text">{clientPhoneNumber}</a></div> */}


                    <div className="d-flex align-items-center gap-2 addUserIcon_search">
                      <span className={`primaryBorder d-flex align-items-center justify-content-center search-icon-span ${showSearchBarInput ? 'secondaryBorder' : ''}`} onClick={handleSerachCancle}>
                        {!showSearchBarInput ?
                          <i className='search-icon-grey font-size-14'></i>
                          :
                          <i className="ri-close-line font-size-16"></i>
                        }
                      </span>
                      {/* {!showSearchBarInput && */}
                      <div className="flex-shrink-0 d-flex">
                        <div id="add-contact">
                          {/* Button trigger modal  */}
                          <AddButton onClick={openModal} clientDisconnected={clientDisconnected} selectedClient={selectedClient} />
                        </div>
                        <UncontrolledTooltip target="add-contact" placement="left">
                          {(clientDisconnected) ? 'Scan whatsapp first' : (selectedClient?.type == "messenger" || selectedClient?.type == "instagram") ? "No option for Create chat" : 'Create Chat'}
                        </UncontrolledTooltip>
                        {/* add contact modal  */}
                        {isOpen && (
                          <Dropdown isOpen={isOpen} toggle={openModal} className="add_group_tabs_menus">
                            <DropdownToggle caret>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={handleCreateContact}>Create Contact</DropdownItem>
                              <DropdownItem onClick={handleCreateGroup}>Create Group</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </div>
                      {/* } */}
                      {/* <Radio.Button value="more" onClick={openModal1} className={`chatfilterTab ${userProfile?.isMention ? 'moreTab' : ''}`}> 
                    {userProfile?.isMention &&
                    <div className="liveClDot mgright5">
                    <span className="liveClOuterDot">
                      <span className="liveClInnerDot"></span>
                    </span>
                  </div>} More {filterChatCount !== undefined ? `(${filterChatCount})` : ""}{userProfile?.isMention}
                  <i className='bx bx-caret-down font-size-18'></i>
                  </Radio.Button> */}
                      <div className={`filterTabBlock primaryBorder d-flex align-items-center justify-content-center ${userProfile?.isMention ? 'mentionDot' : ''}`} onClick={openFilterDrawer}>
                        <span className="filterIcon"></span>
                        {userProfile?.isMention &&
                          <div className="liveClDot mgright5">
                            <span className="liveClOuterDot">
                              <span className="liveClInnerDot"></span>
                            </span>
                          </div>
                        }
                      </div>
                      <FilterSideMenu open={isFilterDrawerOpen} onClose={closeFilterDrawer} onLabelClick={openLabelDrawer} />
                      <LabelSideMenu open={isLabelDrawerOpen} onClose={closeLabelDrawer} onBackToFilterClick={openFilterDrawer} labels={labels} clientId={clientId} />
                    </div>

                  </div>
                </div>
              }
            </> : <> <div className="d-flex align-items-center chatTopDropDownDiv gap-2">
              <div className="d-flex align-items-center justify-content-center">
                <Button className='newAcChatRadio d-flex align-items-center justify-content-center chatAddScanIconMobHide' disabled={isChatUsersSyncing} color="light" type="button" id="addWhatsApp" onClick={handleClickAddNumber}>
                  <i className="bx bx-plus align-middle"></i>
                </Button>
                <UncontrolledTooltip target="addWhatsApp" placement="right">
                  Add Channel
                </UncontrolledTooltip>
                {clientList.length ? <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId} handleClientChange={handleClientChange} /> : ""}
              </div>
              <div className="d-flex align-items-center justify-content-between chatAddScanIconMobHide"><span>&nbsp;&nbsp;&nbsp;&nbsp;Add Channel</span>
              </div>
            </div></>}
            <div className="userDropDownInStickyChat">
              <Dropdown
                nav
                isOpen={userdropdownOpen}
                className="profile-user-dropdown userProfileDropdown"
                toggle={toggle}
              >
                <DropdownToggle nav className="bg-transparent d-flex align-items-start userProfileDropdownLink gap-1">
                  <div className="d-flex align-items-center justify-content-center chatMobUserDrop">
                    {/* <img src={avatar1} alt="" className="profile-user" height={24} width={24} /> */}
                    <i className='bx bx-dots-vertical-rounded font-size-24'></i>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem
                    className="d-flex align-items-center justify-content-between"
                    href="/changepassword"
                  >
                    Profile <i className="bx bx-user ms-1"></i>
                  </DropdownItem> */}
                  <DropdownItem
                    className="d-flex align-items-center justify-content-between"
                    href="/plan-billing"
                  >
                    Plan & Billing  <i className="bx bx-file text-muted ms-1"></i>
                  </DropdownItem>
                  <DropdownItem
                    className="d-flex align-items-center justify-content-between"
                    href="/changepassword"
                  >
                    Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
                  </DropdownItem>
                  <DropdownItem
                    className="d-flex align-items-center justify-content-between"
                    tag="a"
                    href="/auth-login"
                    onClick={() => logOut()}
                  >
                    Log out <i className="bx bx-log-out-circle text-muted ms-1"></i>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <div className="bg-white">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 d-flex ms-2"></div>
            </div>
            {clientList.length > 0 && (
              <div className="align-items-center justify-content-between w-100 pe-2 mb-2">
                {showSearchBarInput && (
                  <div className="d-flex w-100">
                    <Form className="w-100" onSubmit={(event) => event.preventDefault()}>
                      <div className="input-group align-center chatSearchMain">
                        <Input
                          onKeyUp={debouncedSearch}
                          id="serachChatUser"
                          type="text"
                          className="form-control bg-light border-0 pe-0 mb-1"
                          placeholder={localStorageChatFilter !== "All" ? `Search ${localStorageChatFilter}` : `Search`}
                          autoFocus
                        />
                      </div>
                    </Form>
                  </div>
                )}
                <div className="messagesStatusText">
                  <span style={{ cursor: "pointer" }} onClick={openFilterDrawer}>
                    {localStorageChatFilter ? localStorageChatFilter : "All"} ({chatCount})
                  </span>
                </div>
                {isOpen1 && (
                  <Dropdown isOpen={isOpen1} toggle={openModal1} className="chatFilterDrpDwn">
                    <DropdownToggle caret></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={`${localStorageChatFilter === "Unassigned Chats" && "activeFilterItem"}`}
                        onClick={() => {
                          handleFilterTab("Unassigned Chats");
                        }}
                      >
                        Unassigned Chats
                      </DropdownItem>
                      <DropdownItem
                        className={`${localStorageChatFilter === "Unread Chats" && "activeFilterItem"}`}
                        onClick={() => {
                          handleFilterTab("Unread Chats");
                        }}
                      >
                        Unread Chats
                      </DropdownItem>
                      <DropdownItem
                        className={`${localStorageChatFilter === "Resolved Chats" && "activeFilterItem"}`}
                        onClick={() => {
                          handleFilterTab("Resolved Chats");
                        }}
                      >
                        Resolved Chats
                      </DropdownItem>
                      <DropdownItem
                        className={`${localStorageChatFilter === "DND Chats" && "activeFilterItem"}`}
                        onClick={() => {
                          handleFilterTab("DND Chats");
                        }}
                      >
                        DND Chats
                      </DropdownItem>
                      <DropdownItem
                        className={`${localStorageChatFilter === "Mention Chats" && "activeFilterItem"} ${userProfile?.isMention ? "Mention Chats" : ""
                          }`}
                        onClick={() => {
                          handleFilterTab("Mention Chats");
                        }}
                      >
                        Mention Chats
                      </DropdownItem>
                      <DropdownItem
                        className={`${localStorageChatFilter === "Label Chats" && "activeFilterItem"} d-flex align-items-center justify-content-between`}
                        onClick={handleLabelChat}
                      >
                        Labels<i className="bx bx-chevron-right lh-sm font-size-18"></i>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
                {openNewDropdown && (
                  <div ref={dropdownRef}>
                    <Dropdown isOpen={openNewDropdown} toggle={openModal2} className="chatFilterDrpDwn">
                      <DropdownToggle caret></DropdownToggle>
                      <DropdownMenu>
                        {labels?.length
                          ? labels?.map((item: any) => (
                            <DropdownItem key={item.value} onClick={() => handleDropdownItemClick(item)}>
                              {item.label}
                            </DropdownItem>
                          ))
                          : ""}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              </div>
            )}
          </div>


        </div>

        <AppSimpleBar className={`chat-room-list chatRoomListMain ${clientDisconnected ? 'clntDisChatRoomList' : ''} ${showSearchBarInput ? 'mobileMarginCollapse' : ''}  `} scrollableNodeProps={ref} scrollRef={ref}>
          {/* <AppSimpleBar style={{ maxHeight: 700 }} className="chat-room-list" scrollRef={chatListRef} onScrollGetEvent={onScrollGetChatList}> */}
          {/* Start chat-message-list */}
          {active === CHATS_TABS.DEFAULT && (
            <>
              {/* favourite */}
              {/* <Favourites
                users={favourites}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              /> */}

              {/* direct messages */}
              {clientList?.length > 0 ?
                <DirectMessages
                  users={chatUserList}
                  clientId={clientInfo}
                  openAddContact={openAddContactModal}
                  selectedChat={selectedChat}
                  onSelectChat={onSelectChat}
                  isChatUsersSyncing={isChatUsersSyncing}
                  clientDisconnected={clientDisconnected}
                  isDisabled={isDisabled}
                // onScrollGetChatList={onScrollGetChatList}
                /> : (
                  <div className="no-chat-user">
                    <img src={NoChatUserIcon} alt="No Chat User Icon" width={159} height={127} />
                    <div className="no-chat-title">No Chats...</div>
                    <p>Discover new connections through our chat Box.</p>
                  </div>
                )
              }
              {/* <InviteContactModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    onInvite={onInviteContact}
                  /> */}

              {/* channels list */}
              {/* <Chanels
                channels={channels}
                openCreateChannel={openCreateChannelModal}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              /> */}
              {/* <h5 className="text-center mb-2">
                <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                >
                  Archived Contacts{" "}
                  <i className="bx bxs-archive-in align-middle" />
                </Link>
              </h5> */}
            </>
          )}
          {/* {active === CHATS_TABS.ARCHIVE && (
            <>
              <Archive
                archiveContacts={archiveContacts}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
              <h5 className="text-center mb-2">
                <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                >
                  Chats <i className="bx bxs-archive-out align-middle" />
                </Link>
              </h5>
            </>
          )} */}

          {/* End chat-message-list */}
        </AppSimpleBar>
      </ChatIndex>
      {/* add group Modal */}
      {isOpenCreateChannel && (
        <AddGroupModal
          isOpen={isOpenCreateChannel}
          onClose={closeCreateChannelModal}
          onCreateChannel={onCreateChannel}
          diffModal={"createGroup"}
          groupId=""

        />
      )}
      {isOpenAddContact && (
        <InviteContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onInvite={onInviteContact}
        />
      )}
    </>
  );
};

export default Index;
