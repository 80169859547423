import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ChangePwdActionTypes } from "./types";
import {
  changePwdApiResponseSuccess,
  changePwdApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  changePassword as changePasswordApi,
} from "../../../api/index";

const fireBaseBackend: any = getFirebaseBackend();

function* changePassword({ payload: data }: any): Generator<any, void, any> {
  try {
    const response: Promise<any> = yield call(changePasswordApi, data);
    if (response) {
      yield put(
        changePwdApiResponseSuccess(
          ChangePwdActionTypes.CHANGE_USER_PASSWORD,
          response
        )
      );
    }
  } catch (error: any) {
    yield put(
      changePwdApiResponseError(
        ChangePwdActionTypes.CHANGE_USER_PASSWORD,
        error
      )
    );
  }
}


export function* watchUserChangePassword() {
  yield takeEvery(ChangePwdActionTypes.CHANGE_USER_PASSWORD, changePassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserChangePassword)]);
}

export default forgetPasswordSaga;
