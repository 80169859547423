import { AccountSettingActionTypes, AccountSettingState} from "./types";

export const INIT_STATE: AccountSettingState = {
    error: "",
    loading: false,
    invoices: [],
    plans:[],
    labelResponse: false
};

const AccountSetting = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case AccountSettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AccountSettingActionTypes.LABEL_SAVE:
                    const sessionPortal = action.payload.data
                    return { 
                        ...state, 
                        loading: false,
                        sessionPortal,
                        labelResponse:action.payload.data.success
                    };
                case AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING:
                    const acoountDeatilsData = action.payload.data
                    return { 
                        ...state, 
                        loading: false,
                        acoountDeatilsData,
                        labelResponse:action.payload.data.success
                    };
               
                default:
                    return { ...state };
            };
        case AccountSettingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AccountSettingActionTypes.LABEL_SAVE:

                    return { 
                        ...state, 
                        loading: false
                    };
                case AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING:

                    return { 
                        ...state, 
                        loading: false
                    };
                
                default:
                    return { ...state };
            }
        case AccountSettingActionTypes.LABEL_SAVE:
            return { 
                ...state, 
                loading: true,
                labelResponse: false
            };
        case AccountSettingActionTypes.UPDATE_ACCOUNT_SETTING:
            return { 
                ...state, 
                loading: true,
                labelResponse: false
            };
       
        default:
            return { ...state };
    }
}

export default AccountSetting