import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

// const getContacts = (filters?: object) => {
//   return api.get(url.GET_CONTACTS, filters);
// };
const getContacts = (id: string | number,skip:number,limit:number,key: any, value: any, search: any) => {
  return api.create(url.GET_DIRECT_MESSAGES + "/" + id, { id,skip,limit,key, value, search});
};
const inviteContact = (data: object,clientId:string) => {
  return api.create(url.INVITE_CONTACT+"/"+clientId, data);
};
const importContacts = (data: object,clientId:string) => {
  return api.createWithFile(url.IMPORT_CONTACTS+"/"+clientId, data);
};

export { 
  getContacts, 
  inviteContact, 
  importContacts 
};
