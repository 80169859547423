export enum AuthConfirmDataActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/confirmData/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/confirmData/API_RESPONSE_ERROR",
  
    CONFIRM_DATA = "@@auth/confirmData/CONFIRM_DATA",
    // CHANGE_PASSWORD = "@@auth/confirmData/CHANGE_PASSWORD",
  }
  export interface AuthConfirmDataState {
    authData: any;
    confirmaccountError: any;
  }
  