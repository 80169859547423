import React, { useState, useEffect, useRef } from 'react'
import { Anchor,Switch } from 'antd';
import { updateAuthData, useProfile, useRedux } from '../../hooks';
import { getInvoice, getProfileDetails, getLabels, labelSave, updateAccountSetting } from '../../redux/actions';
import { Input, UncontrolledTooltip ,Button,Card} from 'reactstrap';
import { PlanBillingComponent } from "../PlanBilling/PlanBillingComponent.style";
import { AccountSettingWrapper } from "./AccountSettingWrapper.style";
interface ChargebeeInit {
    userId: string;
    section: string;
}
interface Option {
    name: string;
    credit: number;
}
interface LabelData {
    value: string;
    label: string;
}
const AccountSetting = () => {
    const { userProfile } = useProfile()
    const { dispatch, useAppSelector } = useRedux()
    const [showInput, setShowInput] = useState<any>(false)
    const [addLabel, setAddLabel] = useState<any>()
    const [labelsData, setLabelsData] = useState<any>([])
    const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
    const [profileDetails, setProfileDetails] = useState<any>({})
    const [accountDeatilsData, setAccountDeatilsData] = useState<any>();

    const topRef = React.useRef<HTMLDivElement>(null);

    const {
        profileData,
        chargebeeData,
        invoiceList,
        downloadInvoiceData,
        labels,
        labelResponse,
        acoountDeatilsData,
        user
    } = useAppSelector(state => {
        return ({
            invoiceList: state.PlanBilling.invoices,
            downloadInvoiceData: state.PlanBilling.downloadInvoice,
            acoountDeatilsData: state.AccountSetting.acoountDeatilsData,
            labelResponse: state.AccountSetting.labelResponse,
            chargebeeData: state.Profile.chargebeeDetails,
            profileData: state.Profile.profileDetails,
            labels: state.Chats.labels,
            user: state.Login.user,
        })
    })
    useEffect(() => {
        if (userProfile?.userId) dispatch(getProfileDetails(userProfile?.userId))
        dispatch(getInvoice('unpaid-invoice'))
    }, [])

    useEffect(() => {
        if (labelResponse) {
            dispatch(getLabels());
        }
    }, [labelResponse])

    useEffect(() => {
        if (labels.length > 0) {
            setLabelsData(labels)
        }
        if (labels.length == 0) {
            setLabelsData([])
        }
    }, [labels])


    useEffect(() => {
        dispatch(getLabels());
    }, [])

    useEffect(() => {
      if(acoountDeatilsData){
        setAccountDeatilsData(acoountDeatilsData?.accountDeatils)
      }
    }, [acoountDeatilsData])

    useEffect(() => {
        if (downloadInvoiceData?.download_url) {
            const para = document.createElement("a");
            para.href = downloadInvoiceData?.download_url;
            para.click()
        }
    }, [downloadInvoiceData])

    useEffect(() => {
        setProfileDetails(profileData);
        setAccountDeatilsData(profileData?.accountDeatils)
        updateAuthData(profileData);
    }, [profileData, chargebeeData])

    let userPlanCurruncyDetails = profileDetails?.planDetails?.price_currency.findIndex((item: any) => item.currency.toLowerCase() == profileDetails?.chargebeeDetails?.currency_code?.toLowerCase())
    userPlanCurruncyDetails = profileDetails?.planDetails?.price_currency[userPlanCurruncyDetails]

    const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;

    const addLabels = () => {
        setShowInput(true)
    }
    const handleClose = () => {
        setShowInput(false)
    }

    const editSubLabels = (value: string) => {
        setEditMode((prevEditMode) => ({ ...prevEditMode, [value]: true }));
    };
    const handleDeleteLabel = (value: string) => {
        const payLoad = {
            value: value,
            isDeleted: true
        }
        dispatch(labelSave(payLoad))
    };

    const handleLabelAdd = () => {
        const payLoad = {
            label: addLabel
        }
        dispatch(labelSave(payLoad))
        setShowInput(false)
    }
    const handleLabelSave = (value: any, index: any) => {
        const payLoad = {
            label: labelsData[index].label,
            value: labelsData[index].value
        }
        dispatch(labelSave(payLoad))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedLabelsData = [...labelsData];
        updatedLabelsData[index].label = e.target.value;
        setLabelsData(updatedLabelsData);
    };
    const handleInputAddLabel = (e: any) => {
        setAddLabel(e.target.value)
    }

    const handleSwitchChange = (checked: any, key: any) => {
        const userId = userProfile?.userId;
        const payloadData = {
            [key]: checked // Using dynamic key in object
        };
        dispatch(updateAccountSetting(payloadData, userId));
    };
    
    return (
        <>
            <PlanBillingComponent>
                <AccountSettingWrapper>
                    <div className='planBilling'>
                        {/* <div>
                            <Anchor
                                className="sidebarMenuAnchor"
                                targetOffset={20}
                                getContainer={getContainer}
                                items={[
                                    {
                                        key: 'labels',
                                        href: '#labels',
                                        title: (<span className='moduleSidebarSpan'><i className='mdi mdi-label-multiple'></i> Labels</span>),
                                    },
                                    {
                                        key: 'access_module',
                                        href: '#access_module',
                                        // title: (<span className='moduleSidebarSpan'><i className='mdi mdi-label-multiple'></i> Access Module</span>),
                                        title: (<span className='moduleSidebarSpan'><i className='accessModuleIcon'></i> Access Module</span>),
                                    },

                                ]}
                            />
                        </div> */}
                        <div id="sectionWrapper">
                            <section id='labels' ref={topRef} className="">
                                <Card className='mb-0'>
                                    <div className='p-3'>
                                        <h3 className="dpstrtitle dpstrtitlefs font-size-18">Labels</h3>
                                        <div style={{ display: "flex" }} className='justify-content-between align-items-center desAddContainer'>
                                            <p className="deparmentname_description m-0">Labels help in sorting and arranging chats by adding custom labels, making it easier to efficiently manage your conversations.</p>
                                            {!showInput &&
                                                <div className='mx-2 addButtondiv'>
                                                    <Button className='addButton' size={"sm"} onClick={() => addLabels()}><i className='bx bx-plus' ></i> Add </Button>
                                                </div>
                                            }

                                        </div>

                                        <div className='TagBoxInnerDiv'>
                                            {labelsData?.length > 0 &&
                                                <div style={{ display: "flex" }} className="labelTagBoxWrapper">
                                                    {labelsData?.length && labelsData?.map((item: any, index: any) => (
                                                        <React.Fragment key={index}>
                                                            <div className="labelTagBox">
                                                                {!editMode[item?.value] && <span>{item.label}</span>}
                                                                {editMode[item?.value] &&
                                                                    <>
                                                                        <input
                                                                            onChange={(e) => handleInputChange(e, index)}
                                                                            value={item.label}
                                                                        />
                                                                    </>
                                                                }
                                                                {item?.parentId &&
                                                                    <>
                                                                        <Button className='labelBoxBtn labelBoxEditBtn' size={"sm"} onClick={() => editSubLabels(item.value)}>
                                                                            {editMode[item?.value] ? <i onClick={() => handleLabelSave(item.value, index)} className='bx bx-save'></i> : <i className='mdi mdi-pencil'></i>}
                                                                        </Button>

                                                                        <Button className='labelBoxBtn labelBoxSaveBtn' size={"sm"} onClick={() => handleDeleteLabel(item.value)}>
                                                                            <i className='mdi mdi-delete-outline'></i>
                                                                        </Button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            }

                                            {showInput &&
                                                <div style={{ display: "flex" }} className='inputLabelAddDiv justify-content-center align-items-center mt-3'>
                                                    <div>
                                                        <span className='addLabelText'>Add Label:</span>
                                                    </div>
                                                    <div>
                                                        <Input onChange={handleInputAddLabel}></Input>
                                                    </div>
                                                    <div>
                                                        <Button className='inputSaveBtn' size={"sm"} onClick={() => handleLabelAdd()}><i className='bx bx-save'></i></Button>
                                                    </div>
                                                    <div>
                                                        <Button className='inputCloseBtn' size={"sm"} onClick={handleClose}><i className='bx bx-x'></i></Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </section>

                            <section id='access_module' ref={topRef} className="mt-3">
                                <Card className='mb-0'>
                                    <div className='p-3'>
                                        <h3 className="dpstrtitle dpstrtitlefs font-size-18">Access Module</h3>
                                        <div style={{ display: "flex" }} className='justify-content-between align-items-center desAddContainer'>
                                            <p className="deparmentname_description m-0">The administrator can manage module accessibility for sub-users.</p>
                                        </div>
                                        <div className='TagBoxInnerDiv align-items-center px-2 py-3'>
                                            <div className='col-md-12'>
                                                <div className='d-flex gap-2 align-items-start'>
                                                    {/* <div className="form-check form-switch p-0">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input synchronizeToCRMCls"
                                                            id="synchronizeToCRM"
                                                            checked={userProfile.parentId ? userProfile?.accountDeatils?.restrictedChannel : accountDeatilsData?.restrictedChannel}
                                                            onChange={(e: any) => {
                                                                if (!userProfile?.parentId) {
                                                                    handleSwitchChange(e.target.checked, "restrictedChannel");
                                                                }
                                                            }}
                                                            disabled={!!userProfile?.parentId}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="synchronizeToCRM"
                                                        ></label>
                                                    </div> */}
                                                    <Switch
                                                        className="ch_toggle_switch mgtop4" 
                                                        disabled={!!userProfile?.parentId}
                                                        checked={userProfile?.parentId ? userProfile?.accountDeatils?.restrictedChannel : accountDeatilsData?.restrictedChannel}
                                                        onChange={(checked: boolean) => {
                                                            if (!userProfile?.parentId) {
                                                                handleSwitchChange(checked, "restrictedChannel");
                                                            }
                                                        }} />
                                                        <div>
                                                            <h6 className="align_colon_content font-size-15 d-flex mb-0 align-items-center gap-1 lh-sm">
                                                                Restrict channel Access
                                                                {/* <a id="restrict_channel">
                                                                    <i className="bx bxs-info-circle font-size-16 lh-sm"></i>
                                                                </a>
                                                                <UncontrolledTooltip
                                                                    target={`restrict_channel`}
                                                                    placement="right"
                                                                >
                                                            </UncontrolledTooltip> */}
                                                            </h6>
                                                            <p className='font-size-13 descColor'>Sub-users will be unable to add new channels to the account.</p>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='d-flex gap-2 align-items-start'>
                                                    {/* <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input synchronizeToCRMCls"
                                                            id="synchronizeToCRM"
                                                            checked={userProfile.parentId ? userProfile?.accountDeatils?.secureChats : accountDeatilsData?.secureChats}
                                                            onChange={(e: any) => {
                                                                if (!userProfile?.parentId) {
                                                                    handleSwitchChange(e.target.checked, "secureChats");
                                                                }
                                                            }}
                                                            disabled={!!userProfile?.parentId}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="synchronizeToCRM"
                                                        ></label>
                                                    </div> */}
                                                    <Switch
                                                        className="ch_toggle_switch mgtop4" 
                                                        checked={userProfile?.parentId ? userProfile?.accountDeatils?.secureChats : accountDeatilsData?.secureChats}
                                                        onChange={(checked: boolean) => {
                                                            if (!userProfile?.parentId) {
                                                                handleSwitchChange(checked, "secureChats");
                                                            }
                                                        }}
                                                        disabled={!!userProfile?.parentId}
                                                    />
                                                    <div>
                                                        <h6 className="align_colon_content mb-0 font-size-15 d-flex align-items-center gap-1 lh-sm">
                                                            Secure Chats
                                                            {/* <a id="secure_chats">
                                                                <i className="bx bxs-info-circle font-size-16 lh-sm"></i>
                                                            </a>
                                                            <UncontrolledTooltip
                                                                target={`secure_chats`}
                                                                placement="right"
                                                            >
                                                            </UncontrolledTooltip> */}
                                                            </h6>
                                                        <p className='font-size-13 descColor'>Sub-users are limited to viewing only their assigned chats of their allocated numbers.</p>  
                                                    </div>
                                                </div>                                                    
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='d-flex gap-2 align-items-start'>
                                                    {/* <div className="form-check form-switch p-0">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input synchronizeToCRMCls"
                                                            id="synchronizeToCRM"
                                                            checked={userProfile.parentId ? userProfile?.accountDeatils?.groupSync : accountDeatilsData?.groupSync}
                                                            onChange={(e: any) => {
                                                                if (!userProfile?.parentId) {
                                                                    handleSwitchChange(e.target.checked, "groupSync");
                                                                }
                                                            }}
                                                            disabled={!!userProfile?.parentId}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="synchronizeToCRM"
                                                        ></label>
                                                    </div> */}
                                                    <Switch
                                                        className="ch_toggle_switch mgtop4" 
                                                        checked={userProfile?.parentId ? userProfile?.accountDeatils?.groupSync : accountDeatilsData?.groupSync}
                                                        onChange={(checked: boolean) => {
                                                            if (!userProfile?.parentId) {
                                                                handleSwitchChange(checked, "groupSync");
                                                            }
                                                        }}
                                                        disabled={!!userProfile?.parentId}
                                                    />
                                                    <div>
                                                        <h6 className="mb-0 align_colon_content font-size-15 d-flex align-items-center gap-1 lh-sm">
                                                        Group Sync
                                                        {/* <a id="secure_chats">
                                                            <i className="bx bxs-info-circle font-size-16 lh-sm"></i>
                                                        </a>
                                                        <UncontrolledTooltip
                                                            target={`secure_chats`}
                                                            placement="right"
                                                        >
                                                        </UncontrolledTooltip> */}
                                                        </h6>
                                                        <p className='font-size-13 mb-0 descColor'>Disabling this toggle will prevent group chat activities from syncing in Controlhippo and CRMs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </section>

                            <section id='access_module' ref={topRef} className="mt-3 pb-3">
                                <Card >
                                    <div className='p-3'>
                                        <h3 className="dpstrtitle dpstrtitlefs font-size-18">Notification for Assigned Chats</h3>
                                        <div className='TagBoxInnerDiv px-2 py-3'>
                                            <div className='d-flex align-items-start gap-2'>
                                                {/* <div className="form-check form-switch">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="synchronizeToCRM"
                                                        checked={userProfile.parentId ? userProfile?.accountDeatils?.assignChatNotification : accountDeatilsData?.assignChatNotification}
                                                        onChange={(e: any) => {
                                                            if (!userProfile?.parentId) {
                                                                handleSwitchChange(e.target.checked, "assignChatNotification");
                                                            }
                                                        }}
                                                        disabled={!!userProfile?.parentId}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="synchronizeToCRM"
                                                    ></label>
                                                </div> */}
                                                <Switch
                                                    className="ch_toggle_switch mgtop4" 
                                                    checked={userProfile?.parentId ? userProfile?.accountDeatils?.assignChatNotification : accountDeatilsData?.assignChatNotification}
                                                    onChange={(checked: boolean) => {
                                                        if (!userProfile?.parentId) {
                                                            handleSwitchChange(checked, "assignChatNotification");
                                                        }
                                                    }}
                                                    disabled={!!userProfile?.parentId}
                                                />
                                                <div className='justify-content-between align-items-center desAddContainer'>
                                                    <h6 className="align_colon_content font-size-15 mb-0"> Send Notification </h6>
                                                    {/* <br/> */}
                                                    <p className="deparmentname_description m-0 descColor font-size-13">Upon enabling the toggle, users will receive email notifications whenever a chat is assigned to them.</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </section>
                        </div>
                    </div>
                </AccountSettingWrapper>
            </PlanBillingComponent>
        </>
    )
}

export default AccountSetting