import React, { useState, useEffect, useInsertionEffect } from 'react';
import { Button, Form, Input, Image, Select } from 'antd';
import { BasicDetailsTypes } from "../../../data/myProfile";
import img1 from "../../../assets/images/user_profile.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../../redux/actions";
import { useProfile } from "../../../../src/hooks/UserHooks";
import { toast } from "react-toastify";
import { EditOutlined } from '@ant-design/icons';
import { MyProfileComponent } from './MyProfileComponent.style';
import { addMixpanelEvent } from '../../../helpers/mixpanel';
import { TimeZone } from "../../../data";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 6 },
};

interface MyProfileProps {
  basicDetails: BasicDetailsTypes;
}

const MyProfile: React.FC<MyProfileProps> = ({ basicDetails }) => {
  const dispatch = useDispatch();
  const { isProfileUpdated, successMsg, mailSent, getProfileLoading, profileDetails,userDetails} = useSelector((state: any) => ({
      isProfileUpdated: state.UpdateUserProfile.isProfileUpdated,
      successMsg: state.UpdateUserProfile.successMsg,
      mailSent: state.UpdateUserProfile.mailSent,
      getProfileLoading: state.UpdateUserProfile.getProfileLoading,
      profileDetails: state.Profile.profileDetails,
      userDetails: state.UpdateUserProfile.userDetails
    }));
  useEffect(() => {
    if (getProfileLoading == false) {
      toast.success(successMsg)
    }
  }, [successMsg, getProfileLoading]);
  
  const { userProfile } = useProfile();
  const [form] = Form.useForm();
  const [isModified, setIsModified] = useState(false);
  const [newInputEmail, setNewInputEmail] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [userData, setUserData] = useState<any>(profileDetails)

  useEffect(() => {
    if(userDetails?.success){
      addMixpanelEvent("Email Changed Request",{distinct_id:userProfile?.userId,email:userProfile?.email})
    }
  }, [userDetails])
  useEffect(() => {
    if(profileDetails)setUserData(profileDetails)
  }, [profileDetails])
  
  const onFinish = (values: any) => {
    values.userId = userProfile?.userId;
    dispatch(updateUserProfile(values));
    setIsModified(!isModified);
  };
  const handleValuesChange = () => {
    setIsModified(true);
  };

  const editEmail = () => {
    setNewInputEmail(true)
  }
  if (!basicDetails?.fullName) {
    return null;
  }
  
  return (
    <MyProfileComponent>
      <Form
        {...layout}
        form={form}
        name="nest-messages"
        className='profileFormMain'
        onFinish={onFinish}
        initialValues={{
          fullName: basicDetails?.fullName,
          phoneNumber: basicDetails?.phoneNumber,
          companyName: basicDetails?.companyName,
          email: basicDetails?.email,
          timezone: basicDetails?.timezone
        }}
        onValuesChange={handleValuesChange}
      >
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          <div className='profileImgContainer'>
            <Image width={156} src={img1} />
          </div>
        </Image.PreviewGroup>
        <div className='profileFormInputsContainer'>
          <Form.Item
            name="fullName"
            label="Name"
            className='profileFormFeild'
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>
          <div className='profileEmailContainer d-flex'>
            <Form.Item
              name="email"
              className='profileFormFeild'
              label={(
                <div className='d-flex align-items-center'>
                  Email
                  <Button className='d-flex align-items-center justify-content-center profileEmailAddNewBtn' onClick={editEmail}><svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16"><path d="M794-666 666-794l42-42q17-17 42.5-16.5T793-835l43 43q17 17 17 42t-17 42l-42 42Zm-42 42L248-120H120v-128l504-504 128 128Z" /></svg></Button>
                </div>
              )}
              rules={[{ type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input readOnly />
            </Form.Item>
          </div>
          {newInputEmail &&
            <Form.Item
              name="newEmail"
              label="New Email"
              className='profileFormFeild'
              rules={[{ type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input />
            </Form.Item>
          }
          {userData?.parentUser?.email && <Form.Item
              name="parentEmail"
              className='profileFormFeild'
              label={(
                <div className='d-flex align-items-center'>
                  Owner Email
                </div>
              )}
            >
              <Input defaultValue={userData.parentUser.email} readOnly  disabled/>
            </Form.Item>}
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            className='profileFormFeild'
            rules={[{ required: true, message: 'Please enter your phone number' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="companyName"
            label="Company Name"
            className='profileFormFeild'
            rules={[{ required: true, message: 'Please enter your company name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="timezone"
            label="Timezone"
            className="profileFormFeild"
            rules={[{ required: true, message: 'Please select your timezone' }]}
          >
            <Select>
              {TimeZone.map((timezone) => (
                <Select.Option key={timezone} value={timezone}>
                  {timezone}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout} className='userProfileSubmitContainer'>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isModified}
              className='btn-primary'
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </MyProfileComponent>
  );
};

export default MyProfile;
