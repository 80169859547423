import styled from 'styled-components';
import ArrowDown from '../assets/images/icons/arrow_down.svg';

const ConversationWrapper = styled.div`
.chat-conversation{
    .chat-conversation-list{
        .ctextWrapContent:has(.SndrRcvrName){
            padding: 8px 8px 8px 8px !important;
        }
        .ctextWrapContent{
            padding: 8px 16px 8px 16px;
            min-width:120px;
        }
        .wpContentMsg{
            font-size:12px !important;
            line-height:1.5em !important;
            color: #333 !important;
        }
        .chatTimeTxt{
            font-size:10px !important;
            line-height:normal !important;
            color: rgba(0, 0, 0, 0.45);
        }
        .doubleCheckedIcon{
            font-size:16px !important;
            vertical-align:middle !important;
        }
        .doubleCheckedIconBlue{
            color:#0073da !important;
        }
        .chatMp3AudionMain{
            audio{
                height:38px !important;
            }
        }
        .afterDownloadImg{
            img{
                opacity:1 !important;
            }
        }
    }
}
.notMsgMain{
    .dropdown{
        margin-left:-44px;
    }
    .paddingNoneForsync{
        .simplebar-content{
            padding-top:0 !important;
        }
    }
}
.sycOldConvDiv{
    position: sticky;
    top: 1px;
    z-index: 13;
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 6px !important;
    background-color: #cfe2ff;  
    border-radius: 20px;
    left: 0;
    padding: 1px;
    right: 0;
    gap:4px;
    img{
      margin-left: -3px;
    }
    p{
      letter-spacing: 0.3px;
    }
}
.notMsgMain .ctext-wrap-content{
    background-color:#FFF3DB !important;
    .notMsgNameMain{
        background-color: #FFD789!important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .notMsgName{
            font-weight: 500;
        }
    }
    .ctext-wrap{
        justify-content:end !important;
        .ctext-wrap-content{
            margin-right:44px;
            background-color:#FFF3DB !important;
            .notMsgNameMain{
                background-color: #FFD789!important;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                .notMsgName{
                    font-weight: 500;
                }
            }
            .otherBodyTextNote{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px; 
            }
        }
    }
}
.chatMenuMain{
    position: absolute;
    right: 0px;
    top: 0px;
    z-index:100;
    .btn,
    .btn:is(:focus-visible,:active,:focus,:hover){
        font-size: 22px!important;
        border: none!important;
        outline: none!important;
        z-index: 10;
        height: auto;
        width: auto;
        border-radius: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: radial-gradient(at top right, #efefef94 60%, #202c3300 80%);
        opacity: 0.6;
        i{
            line-height: normal !important;
        }
    }
}
.SndrRcvrName{
    font-weight:500;
    color:#000;
}
.SndrRcvrNameWnHasImg{
    margin-top:-3px !important;
}

.chat-conversation .chat-conversation-list .ctextWrapContent:has(.attachmentDiv) {
    background-color: #ffffff;
    padding: 8px 8px;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:has(.attachmentDiv) .wpContentMsg {
    color: #000 !important;
}
.chat-conversation .right .conversation-list.conversationListHasIMg .ctext-wrap .ctext-wrap-content .SndrRcvrName {
    color: #000000 !important;
}
.chat-conversation .right .conversation-list.conversationListHasIMg .ctext-wrap .ctext-wrap-content .formMeMenuBtn .text-secondary {
    color: rgb(121 124 140) !important;
}
.chat-conversation .right .conversation-list.conversationListHasIMg .ctext-wrap .ctext-wrap-content .wpContentMsg {
    color: #000000 !important;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:has(.attached-file) .SndrRcvrName {
    margin-bottom: 0.25rem;
}
`;
export { ConversationWrapper };