import { UpdateSubUserPwdActionTypes } from "./types";

// common success
export const updateSubUserPwdApiResponse = (
  actionType: string,
  data: any
) => ({
  type: UpdateSubUserPwdActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const UpdateSubUserPwdApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: UpdateSubUserPwdActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateSubUserPwd = (data: any) => {
  return {
    type: UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD,
    payload: data,
  };
};

