import config from "../config";
import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const getQrCodeData = (id: string | number, privacyValue  :any) => {
  return api.get(url.GET_QR_CODE + "/" + id, { params: { id, privacyValue } });
};

const connectWhatsAppBusiness = (id: string)=>{
  return api.get(url.CONNECT_WHATSAPP_BUSINESS+"/"+id);
}

const numberDelete = (userId:any ,option :string) => {
  return api.update(url.DELETE_NUMBER+option+"/"+userId);
};
const channelCreate = (payload:any) => {
  return api.create(url.CREATE_CHANEL,payload);
};
const getMessengerPageList = (payload:any) => {
  return api.create(url.GET_MESSENGER_PAGE_LIST_URL+"/"+ payload);
};
const updateClientDeatils = (payload:any) => {
  return api.create(payload?.channel + url.UPDATE_CLIENT_DETAILS_URL+"/"+payload?.clientId,payload);
};
const connectTelegram = (data:any) => {
  return api.create(`${config.TELEGRAM_URL}${url.CONNECT_TELEGRAM}/createSession/${data.clientId}`,data);
};
const connectMessenger = (data:any) => {
  return api.create(`${url.CONNECT_MESSENGER}/createSession`,data);
};
const connectInstagram = (data:any) => {
  return api.create(`${url.CONNECT_INSTAGRAM}/createSession`,data);
};
const connectChatbot = (data:any) => {
  return api.createWithFile(`${url.CONNECT_CHATBOT}/createSession`,data);
};
const getOldClientId = (data: any) => {
  return api.create(`${data?.type}${url.GET_OLD_CLIENT_ID_URL}`,data);
};
export {
  getQrCodeData,
  connectWhatsAppBusiness,
  numberDelete,
  channelCreate,
  getMessengerPageList,
  updateClientDeatils,
  connectTelegram,
  connectMessenger,
  connectInstagram,
  connectChatbot,
  getOldClientId
};
