import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form } from "reactstrap";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
// import cookie from "react-cookies";
import { isValidPhoneNumber } from "libphonenumber-js";


import { useRedux } from "../../hooks/index";
import { useParams,Navigate,useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { updatePassword,userChangePassword } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import PasswordChecklist from "react-password-checklist";

// images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";
let now = new Date();

interface ConfirmAccountProps { }
const ResetPassword : React.FC<ConfirmAccountProps> = (props) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [loading, setLoading] = useState(false);
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const userCountry = "in";
  const navigate = useNavigate();

  const {authToken, confirmaccountError } = useAppSelector(
    (state) => {
      return {
         authToken: state?.updatePassword?.authData?.authToken,
         confirmaccountError: state?.updatePassword?.confirmaccountError
      };
    }
  );

  const resolver = yupResolver(
    yup.object().shape({
      // password: yup
      // .string()
      // .required("Please enter Password.")
      // .min(8, "Password must be at least 8 characters")
      // .max(22, "Password can have maximum 22 characters")
      // .test("passwordRequirements", "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character", (value) => {
      //   // Regex pattern for at least one uppercase, one lowercase, one digit, and one special character
      //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
      //   return passwordRegex.test(value);
      // })
      // .test("notBlankSpaces", "Password cannot be blank spaces", (value) => {
      //   return value.trim() !== "";
      // }),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;
  const { hash, i, c } = useParams();
  useEffect(() => {
    setLoading(true)
    let data = {
      hash: hash,
      i: i,
      c: c,
      userLoginTime: now,
    };
    // dispatch(userChangePassword(data));
    setLoading(false)
  }, [dispatch, hash, i, c]);
  useEffect(() => {
   if(authToken){
    setLoading(false)
    navigate("/welcome",{
      state: {
        authToken
      }
    });
   }
  }, [authToken]);

  const onSubmitForm = (values: any) => { 
  let data = {
    password:password,
    hash: hash,
    i: i,
    c: c,
    userLoginTime: now,
  };
  dispatch(updatePassword(data));
};
const [isValidationPopupOpen, setValidationPopupOpen] = useState(false);
const handleValidation = (rules: any) => {
  setValidationPopupOpen(rules);
};

const handlePasswordChange = (value: any) => {
  setPassword(value)
  setValidationPopupOpen(false)
}
  return (
   
    <NonAuthLayoutWrapper>
        
      <Row className=" justify-content-center my-auto">
        <Col className="col-12">
          <div className="py-md-5 py-4">
            {/* <AuthHeader title="Forgot Password" /> */}
            <div className="headtitle mb-4">
              <h3 className="headTitleHeading mb-1">Forgot <span className="headTitleHeadingColored">Password</span></h3>
              {/* <span className="text-muted font-size-14">Reset Password with Controlhippo.</span> */}
            </div>
            {confirmaccountError && confirmaccountError ? (
              <Alert color="danger">{confirmaccountError}</Alert>
            ) : null}
            {/* {passwordChanged ? (
              <Alert color="success">Your Password is changed</Alert>
            ) : null} */}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {/* {changePassLoading && <Loader />} */}
             
              <div className="mb-3 inputPassMainDiv">
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="Enter New Password"
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    className="form-control inputField newPassInput"
                  />
                  <i className={`eyeIconPass ${!showPassword ? "ri-eye-fill align-middle" : "crossEyeIcon"}`} onClick={togglePasswordVisibility}></i>
                </div>
                <div className="passwordChecklist">
                  {password.length && !isValidationPopupOpen ? (
                    <PasswordChecklist
                      rules={["minLength", "maxLength", "specialChar", "number", "capital" ]}
                      minLength={8}
                      maxLength={22}
                      value={password}
                      messages={{
                        minLength: "Minimum 8 Characters",
                        maxLength: "Maximum 22 Characters",
                        specialChar: "Contain at least 1 Special Character",
                        number: "Contain at least 1 number",
                        capital: "Contain at least 1 Uppercase Letter",
                      }}
                      onChange={(rules) => handleValidation(rules)}
                    />
                  ):""}
                </div>
              </div>
              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button disabled={!isValidationPopupOpen} className="btn btn-primary w-100 authButtons" type="submit">
                      Save
                    </button>
                  </div>
                  <div className="col-6">
                    <Link to="/auth-login">
                      <button className="btn btn-light w-100 authButtons" type="button">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    
    </NonAuthLayoutWrapper>
  );
};

export default ResetPassword;


