import styled from 'styled-components';
import bgImage from '../../assets/images/bg-pattern/pattern-05.png'
import subscriptiondetailwhite from '../../assets/images//icons/subscriptiondetailwhite.svg'

const UserProfileComponent = styled.div`
  width: 100%;
  .UserProfMain{
    // background: url(${bgImage});
    width: 100%;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9f9f9f;
        border-radius: 14px;
    }
    &::-webkit-scrollbar-track {
        background: #e8e8e8;
    }
    .ant-affix{
        z-index: 5px !important;
    }
    .card{
        border: 1px solid #e8e8e8;
        border-radius: 10px !important;
        .card-footer{
            border-bottom-right-radius:10px !important;
            border-bottom-left-radius: 10px !important;
        }
    }
    .subscriptiondetailsection {
        background-image: url(${subscriptiondetailwhite});
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        display: block;
        margin-top: 3px;
    }
  }
  .sidebarMenuAnchor{
      position: fixed;
      width: 15%;
      background-color: #FFF;
      height: 89%;
      max-height: 89% !important;
      border-right: 0px;
      padding-top: 0px;
      border-radius: 8px;
      top: 63px;
      .ant-anchor, .ant-anchor-link {
        padding: 0px;
      }
      .ant-menu-title-content {
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 6px;
        color: rgb(51, 51, 51) !important;
        font-size: 12px !important;
        line-height: normal !important;
    }
    .ant-menu-item-disabled {
      border-radius: 12px !important;
      cursor: default !important;
    }
    ul li.ant-menu-item:hover {
      background: #F4F5FA !important;
    }
    ul li.ant-menu-item.ant-menu-item-selected {
      background: #DBE2FF !important;
    }
    ul li.ant-menu-item.ant-menu-item-disabled:hover {
      background: #ffffff !important;
    }
    @media (max-width:991.98px){
      display: none !important;
    }
    .ant-anchor::before{
        content: unset !important;
        position: unset !important;
    }
    .ant-anchor-ink{
        display: none !important;
    }
    .ant-anchor-link-active{
        background-color: #DBE2FF;
    }
    .ant-anchor-link
    {
        &:hover{
            background-color: #F4F5FA !important;
            .moduleSidebarSpan,.moduleSidebarSpan i{
                // color: #fff !important;
            }
        }
        .ant-anchor-link-title {
            padding: 14px 16px !important;
            .moduleSidebarSpan {
                color: #333333 !important;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-size: 12px !important;
                line-height: normal !important;
                gap: 6px;
                i{
                    font-size: 18px !important;
                    margin-right: 5px !important;
                }
            }
        }
    }
  }
.nonClickableText {
    font-size: 12px;
    font-weight: 500;
    color: #858585;
    line-height: 21px;
}
.ant-anchor-link:has(.nonClickableText) a, .ant-anchor-link:has(.nonClickableText) {
    pointer-events: none;
    background: transparent !important;
}
  #sectionWrapper{
    width: 83.5% !important;
    margin-left: auto;
    @media (max-width:991.98px){
      width: 96% !important;
      margin:0 auto !important;
    }
  }
  .UserProfBack{
    @media (max-width:991.98px){
      display: flex !important;
    }
    .pageBackBtn {
      border-radius: 5px!important;
      background: #EFEFEF!important;
      border-color: #333!important;
      color: #333!important;
    }
  }
  .userProfCardMain{
    .personalInfoForm{
      .ant-select{
        min-height:43px !important;
      }
    }
    .ant-form{
      .ant-form-item-label{
        padding-bottom:3px !important;
      }
      .ant-form-item-control-input,
      .ant-select{
        input::placeholder{
          color:rgba(0, 0, 0, 0.25) !important;
        }
      }
      .ant-input-affix-wrapper{
        padding-top:0px !important;
        padding-bottom:0px !important;
      }
      .ant-form-item-control-input{
        input{
          min-height:43px !important;
        }
      }
      .ant-btn.emailEditButton,
      .ant-btn.emailEditButton:is(:hover,:focus,:focus-visible,:active){
        background-color: #fff !important;
        border-color: #000 !important;
        color: #000 !important;
        padding: 0 !important;
        height:16px !important;
        width: 16px !important;
        border-radius: 2px !important;
        font-size: 12px !important;
        margin-left: 2px !important;
      }
      .ant-btn,
      .ant-btn:is(:hover,:focus,:focus-visible,:active){
        padding:8px 26px !important;
        background-color: #0029c7 !important;
        color:#fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-card-head{
      border-bottom: unset !important;
      padding-top: 22px !important;
    }
    .ant-card-head-title,.ant-card-head-title h2{
      font-size: 21px;
      font-weight: 400;
      color:#000;
    }
    .cardDesc{
      color:#AAAAAA !important;
      font-size:12px !important;
    }
  }
  .changePassCard{
    .ant-card-body{
      padding-top: 10px !important;
    }
  }
  .userProfCardMain {
    box-shadow: none !important;
  }
  .sidebarMenuAnchor ul {
    border-radius: 8px;
    li {
      margin: 0px;
      padding: 14px 16px !important;
      width: 100%;
      border-radius: 0px;
    }
  }
`;
export { UserProfileComponent };