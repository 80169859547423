import React, { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";
import { Collapse, Card, CardBody, Input, Label, Button } from "reactstrap";
import { Popover } from "antd";
import WebCamModal from "./WebCamModal";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { showErrorNotification } from "../../../../helpers/notifications";
import AddGroupModal from "../../../../components/AddGroupModal";
import { CreateChannelPostData, getDirectMessages } from "../../../../redux/actions";
import { useProfile, useRedux } from "../../../../hooks";


interface MoreMenuProps {
  isOpen: boolean;
  onSelectImages: (images: Array<any>) => void;
  onToggle: () => any;
  onSelectFiles: (files: Array<any>) => void;
  onSelectAudio: (audios: Array<any>) => void;
  chatUserDetails?:any;
  onSend?: any;
}
const MoreMenu = ({
  isOpen,
  onSelectImages,
  onToggle,
  onSelectFiles,
  onSelectAudio,
  chatUserDetails,
  onSend
}: MoreMenuProps) => {

  const [isOpenCreateChannel, setIsOpenCreateChannel] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopOver, setShowPopOver] = useState<boolean>(false)

  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const localStorageChatFilter = localStorage.getItem("selectedChatFilter")
  const [selctedFilter, setSelctedFilter] = useState<any>(localStorageChatFilter);
  useEffect(() => {
    setShowPopOver(isOpen)
}, [isOpen])
  const onSelect = (e: any) => {
    const files = [...e.target.files];
    let fileSizeLimitExist:boolean = false;
    let totalSize = files.reduce((acc, item) => {
    if(((parseInt(item.size)/(1024)) >= (40 * 1024))){ fileSizeLimitExist = true}
      
    return acc + item.size
    }, 0);
    totalSize = totalSize/1024
    const maxFiles = 10;
    const maxAllowedSize = 100 * 1024; 
    if(fileSizeLimitExist){
      return showErrorNotification({ message: "File size exceeds the 40 MB limit. Please choose a smaller file." })
    }
    if (files && files.length <= maxFiles) {
      if (totalSize <= maxAllowedSize) {
        const updatedFiles = files.map((file) => ({
          id: Math.random().toString(36).substring(2, 15),
          file: file,
        }));
        onSelectImages(updatedFiles);
        onToggle();
      } else {
        showErrorNotification({ message: "You can upload a maximum of 100 mb at once" })
      }
    }else {
      showErrorNotification({message:"You can upload a maximum of 10 files at once"})
    }
  };

  const onSelectF = (e: any) => {
    const files = [...e.target.files];
    let fileSizeLimitExist:boolean = false;
    let totalSize = files.reduce((acc, item) => {
      if((parseInt(item.size)/(1024)) >= (40 * 1024)){ fileSizeLimitExist = true}
      return acc + item.size
    }, 0);
    totalSize = totalSize/1024
    const maxFiles = 10;
    const maxAllowedSize = 100 * 1024; 
    if(fileSizeLimitExist){
      return showErrorNotification({ message: "File size exceeds the 40 MB limit. Please choose a smaller file." })
    }
    const audiFileType = ["audio/ac3","audio/aac","audio/ogg","audio/wav"];
    if ( files &&  (files.length <=  maxFiles) ) {
      if( totalSize <= maxAllowedSize){
        const updatedFiles:any = []
        const updatedAudios :any = []
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          if (element.type.includes("audio") &&  !audiFileType.includes(element.type) ) {
            const data = {
              id: Math.random().toString(36).substring(2, 15),
              file: element,
            }
            updatedAudios.push(data)
          } else {
            const data = {
              id: Math.random().toString(36).substring(2, 15),
              file: element,
            }
            updatedFiles.push(data)
          }
        }
        onSelectAudio(updatedAudios);
        onSelectFiles(updatedFiles);
        onToggle();
      }else{
        showErrorNotification({message:"You can upload a maximum of 100 mb at once"})
      }
    }else {
      showErrorNotification({message:"You can upload a maximum of 10 files at once"})
    }
  };
  const onSelectA = (e: any) => {
    const audios = [...e.target.files];
    if (audios) {
      onSelectAudio(audios);
      onToggle();
    }
  };

  const closeCreateChannelModal = () => {
    setIsOpenCreateChannel(false);
    dispatch(getDirectMessages(userProfile?.clientId, 0, 50,selctedFilter, 0))
  };

  const onCreateChannel = (channelData: CreateChannelPostData) => {
    // dispatch(createChannel(channelData));
  };

  const handleParticipantsClick = () => {
    openCreateChannelModal();
    setCurrentPage(1); // Reset to the first page when the Participants button is clicked
  };

  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };

  const onSendData = (data: any) => {
    onSend(data)
  }
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setShowPopOver(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div ref={popoverRef}>
      <Popover
        open={showPopOver}
        className="chat-input-collapse"
        id="chatinputmorecollapse"
        arrow={false}
        placement="topLeft"
        getPopupContainer={(trigger:any) => trigger.parentNode}
        overlayClassName="moreMenuAttachments"
        content={
          <Card className="mb-0">
            <CardBody className="p-1">
              <div className="attachmentMenuMain d-flex flex-column">
              {/* <Swiper
                observer
                observeParents
                freeMode
                watchSlidesProgress
                watchSlidesVisibility
                slidesPerView={3}
                spaceBetween={30}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 10,
                  },
                }}
                className="chatinput-links chatSwiperMain"
              > */}
                {/* <SwiperSlide>
                <div className="text-center px-2 position-relative">*/}
                  <div className="d-flex align-items-center gap-2 attachmentMenus p-2"> 
                    <div className="lh-sm">
                      <Input
                        id="attachedfile-input"
                        type="file"
                        className="d-none"
                        onChange={(e: any) => onSelectF(e)}
                        multiple
                        onClick={(event:any)=> { 
                          event.currentTarget.value = null
                        }}
                      />
                      <Label
                        htmlFor="attachedfile-input"
                        className="mx-auto stretched-link cursor-pointer mb-0"
                      >
                        <span className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                          <i className="bx bx-paperclip"></i>
                        </span>
                      </Label>
                    </div>
                    <h5 className="font-size-11 text-uppercase mb-0 text-body text-truncate">Attach</h5>
                  </div>
                {/* </div> */}
              {/* </SwiperSlide> */}
              {/* <SwiperSlide> */}
                {/* <WebCamModal/> */}
                {/* <div className="text-center px-2">
                  <div className="avatar-sm mx-auto">
                    <div className="avatar-title font-size-18 bg-transparent text-primary rounded-circle" >
                      <i className="bx bxs-camera"></i>
                    </div>
                  </div>
                  <h5 className="font-size-11 text-uppercase text-truncate mt-3 mb-0">
                    <Link to="#" className="text-body stretched-link">
                      Camera
                    </Link>
                  </h5>
                </div> */}
              {/* </SwiperSlide> */}
              {/* <SwiperSlide>
                <div className="text-center px-2 position-relative"> */}
                  <div className="d-flex align-items-center gap-2 attachmentMenus p-2">
                    <div className="lh-sm">
                      <Input
                        id="attached-image-input"
                        type="file"
                        className="d-none"
                        accept="image/png, image/jpeg ,image/jpg"
                        onChange={(e: any) => onSelect(e)}
                        multiple
                        onClick={(event:any)=> { 
                          event.currentTarget.value = null
                        }}
                      />
                      <Label
                        htmlFor="attached-image-input"
                        className="mx-auto stretched-link cursor-pointer mb-0"
                      >
                        <span className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                          <i className="bx bx-images"></i>
                        </span>
                      </Label>
                    </div>
                    <h5 className="font-size-11 text-uppercase mb-0 text-body text-truncate">
                      Gallery
                    </h5>
                  </div>
                {/* </div>
              </SwiperSlide> */}

              {/* <SwiperSlide >
                <div className="text-center px-2 position-relative"> */}
                  <div className="d-flex align-items-center gap-2 attachmentMenus p-2">
                    {/* <Label
                      htmlFor="attached-image-input"
                      className="avatar-sm mx-auto stretched-link cursor-pointer mb-0"
                    >
                      <span className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                      
                      </span>
                    </Label> */}
                    <Button onClick={handleParticipantsClick} className="contactAddBtn mx-auto stretched-link cursor-pointer mb-0 bg-transparent">
                      <span className="d-flex align-items-center justify-content-center lh-sm"> <i className="bx bxs-user-circle text-primary font-size-20 lh-md"></i></span>
                    </Button>
                    <h5 className="font-size-11 text-uppercase mb-0 text-body text-truncate">
                      Contacts
                    </h5>
                  </div>
                {/* </div>
              </SwiperSlide> */}

              {isOpenCreateChannel && (
                <AddGroupModal
                  isOpen={isOpenCreateChannel}
                  onClose={closeCreateChannelModal}
                  onCreateChannel={onCreateChannel}
                  diffModal={"contact"}
                  groupId={chatUserDetails?.chatId}
                  onSend={onSendData}
                  chatUserDetails={chatUserDetails}
                />
              )}
              {/* <SwiperSlide>
                <div className="text-center px-2">
                  <div className="d-flex align-items-center gap-2">
                    <Input
                      id="attached-audio-input"
                      type="file"
                      className="d-none"
                      accept="audio/mp3, audio/aac, audio/m4a, audio/wav, audio/ogg"
                      onChange={(e: any) => onSelectA(e)}
                      // multiple
                    />
                    <Label
                      htmlFor="attached-audio-input"
                      className="avatar-sm mx-auto stretched-link cursor-pointer"
                    >
                      <span className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                        <i className="bx bx-headphone"></i>
                      </span>
                    </Label>
                  </div>
                  {/* <div className="avatar-sm mx-auto">
                    <div className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                      <i className="bx bx-headphone"></i>
                    </div>
                  </div> */}

                  {/* <h5 className="font-size-11 text-uppercase text-truncate mt-3 mb-0"> */}
                    {/* <Link to="#" className="text-body stretched-link"> */}
                      {/* Audio */}
                    {/* </Link> */}
                  {/* </h5>
                </div> */}
              {/* </SwiperSlide> */}
              {/* <SwiperSlide>
                <div className="text-center px-2">
                  <div className="avatar-sm mx-auto">
                    <div className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                      <i className="bx bx-current-location"></i>
                    </div>
                  </div>

                  <h5 className="font-size-11 text-uppercase text-truncate mt-3 mb-0">
                    <Link to="#" className="text-body stretched-link">
                      Location
                    </Link>
                  </h5>
                </div>
              </SwiperSlide> */}
              {/* <SwiperSlide>
                <div className="text-center px-2">
                  <div className="avatar-sm mx-auto">
                    <div className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                      <i className="bx bxs-user-circle"></i>
                    </div>
                  </div>
                  <h5 className="font-size-11 text-uppercase text-truncate mt-3 mb-0">
                    <Link
                      to="#"
                      className="text-body stretched-link"
                      data-bs-toggle="modal"
                      data-bs-target=".contactModal"
                    >
                      Contacts
                    </Link>
                  </h5>
                </div>
              </SwiperSlide> */}

              {/* <SwiperSlide className="swiper-slide d-block d-sm-none">
                <div className="text-center px-2">
                  <div className="avatar-sm mx-auto">
                    <div className="avatar-title font-size-18 bg-transparent text-primary rounded-circle">
                      <i className="bx bx-microphone"></i>
                    </div>
                  </div>
                  <h5 className="font-size-11 text-uppercase text-truncate mt-3 mb-0">
                    <Link to="#" className="text-body stretched-link">
                      Audio
                    </Link>
                  </h5>
                </div>
              </SwiperSlide> */}
            {/* </Swiper> */}
            </div>
          </CardBody>
        </Card>
        }
      >
        
      </Popover>
    </div>
  );
};

export default MoreMenu;
