import config from "../config";
import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getFavourites = () => {
  return api.get(url.GET_FAVOURITES);
};

const getDirectMessages = (id: string | number,skip:number,limit:number,key: any, value: any, search: any) => {
  return api.create(url.GET_DIRECT_MESSAGES + "/" + id, { id,skip,limit,key, value, search});
};
const getChannels = () => {
  return api.get(url.GET_CHANNELS);
};

const addContacts = (contacts: Array<string | number>) => {
  return api.create(url.ADD_CONTACTS, contacts);
};

const createChannel = (data: any) => {
  return api.create(url.CREATE_CHANNEL+"/"+data.clientId, data);
};
const updatePrivacySetting = (data: any) => {
  return api.create(url.CHANGE_PRIVACY_SETTING_URL , data);
};
const addParticipants = (data: any) => {
  return api.create(url.ADD_PARTICIPANTS, data);
};
const removeParticipants = (data: any) => {
  return api.create(url.REMOVE_PARTICIPANTS, data);
};
const syncingStopToCrm = (data: any) => {
  return api.create(url.SYNCING_STOP_TO_CRM, data);
};
const makeAsAdmin = (data: any) => {
  return api.create(url.MAKE_AS_ADMIN, data);
};
const dismissAsAdmin = (data: any) => {
  return api.create(url.DISMISS_AS_ADMIN, data);
};
const getChatUserDetails = (id: string | number, clientId :string | number) => {
  return api.get(url.GET_CHAT_USER_DETAILS + "/" + clientId, { params: { chatId:id } });
};
const changeAssignUser = (data:any) => {
  return api.create(url.CHANGE_ASSIGN_USER, data);
};

const downloadChat = async (data: any) => {
  try {
    const {chatId, parentId } = data?.payLoadData;
    const clientId = data?.clientId
    const downloadUrl = `${process.env.REACT_APP_API_URL}${url.DOWNLOAD_CHAT}/${clientId}/${chatId}/${parentId}?authorization=${data?.authToken}&timezone=${data.timeZone}`;
    window.location.href = downloadUrl;
    return "DATA"
  } catch (error) {
    console.error('Error downloading the chat file:', error);
  }
};

const getChatUserConversations = (id: string | number, clientId :string | number,createdAt ?:Date|string ) => {
  return api.get(url.GET_CHAT_USER_CONVERSATIONS + "/" + clientId, { params: { chatId:id,createdAt } });
};

const sendMessage = (data: any | string, clientId: number | string) => {
  if(data?.type == "vCard" || data?.type == "forwardMsg")
  return api.create(url.SEND_MESSAGE+"/"+clientId, data);
  else
  return api.createWithFile(url.SEND_MESSAGE+"/"+clientId, data);
};
const sendTelegramMessage = (data: object | string, clientId: number | string) => {
  return api.createWithFile(`${config.TELEGRAM_URL}${url.SEND_TELEGRAM_MESSAGE}/${clientId}`, data);
};

const sendChatBotMessage = (data: object | string, clientId: number | string) => {
  return api.createWithFile(`${config.CHATBOT_URL}${url.SEND_CHATBOT_MESSAGE}/${clientId}`, data);
};
const sendMessengerMessage = (data: any, clientId: number | string) => {
  return api.createWithFile(data.clientType + url.SEND_MESSENGER_MESSAGE+"/"+clientId, data);
};

const receiveMessage = (id: string | number) => {
  return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const receiveMessageFromUser = (id: string | number) => {
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
    params: { id },
  });
};

const deleteMessage = (chatUserDetails: any, messageId: number | string,option:string) => {
  return api.update(url.DELETE_MESSAGE + "/" + chatUserDetails.clientId, { chatUserDetails, messageId,option:option });
};

const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

const getChannelDetails = (id: string | number) => {
  return api.get(url.GET_CHANNEL_DETAILS + "/" + id, { params: { id } });
};

const toggleFavouriteContact = (id: string | number) => {
  return api.update(url.TOGGLE_FAVOURITE_CONTACT + "/" + id, {
    params: { id },
  });
};

/*
archive
*/
const getArchiveContact = () => {
  return api.get(url.GET_ARCHIVE_CONTACT);
};

const toggleArchiveContact = (id: string | number) => {
  return api.update(url.TOGGLE_ARCHIVE_CONTACT + "/" + id, { params: { id } });
};

const readConversation = (id: string | number) => {
  return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
};

const getClientDetails = (id: string | number) => {
  return api.get(url.GET_CLIENT_DETAILS + "/" + id);
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

const createUser = (data: Array<string | number>) => {
  return api.create(url.CREATE_USER, data);
};
const createTelegramUser = (data: Array<string | number>) => {
  // return api.create(url.CREATE_USER, data);
  return api.create(`${config.TELEGRAM_URL}${url.CONNECT_TELEGRAM}/userCreate`,data);
};
const sendSeenApi = (data: Array<string | number>) => {
  return api.create(url.SEND_SEEN_API_URL, data);
};

const editWhatsappNumber = (data:any) => {
  return api.update(url.EDIT_WHATSAPP_NUMBER, data);
};

const getClientsList = (type : any) => {
  return api.get(url.GET_CLIENT_LIST, {params : {type}});
};
const getChannelList = (type : any) => {
  return api.get(url.GET_CHANNEL_LIST, {params : {type}});
};
const getTemplateClientList = (type : any) => {
  return api.get(url.GET_TEMPCLIENT_LIST, {params : {type}});
};
const getReportChannelList = (type : any) => {
  return api.get(url.GET_REPORT_LIST, {params : {type}});
};
const getCampaignLists = (type : any) => {
  return api.get(url.GET_CAMPAIGN_LIST, {params : {type}});
};
const getAllocationList = (type : any) => {
  return api.get(url.GET_ALLOCATION_LIST, {params : {type}});
};
const getDownloadData = (
  conversationId: string,
  chatId: string,
  clientId: string,
  reponseType: any) => {
  if (reponseType == undefined) {
    return api.get(url.GET_DOWNLOAD_DATA + "/" + clientId + "/" + chatId + "/" + conversationId, {
      responseType: 'blob'
    })
  }else if(reponseType?.isLocalDownload){
    return api.get(url.GET_DOWNLOAD_DATA + "/" + clientId + "/" + chatId + "/" + conversationId, {
      responseType: 'blob'
    })
  } else {
    return api.get(url.GET_DOWNLOAD_DATA + "/" + clientId + "/" + chatId + "/" + conversationId)
  }
};
const getLabels = (data:any) => {
  return api.get(url.CHATS+"/getLabels",data);
};
const updateChatUserDetails = (id?:string,clientId?:string | null,chatId?:string,eventType?:string,data?:any, chatName?: any) => {
  return api.update(url.CHATS+"/update/"+id,{clientId,chatId,eventType,data, chatName});
};
const warmupLogicConfirm = (clientId?:string | null) => {
  return api.update(url.CHATS+"/update-warmup-analytics/"+clientId,{clientId});
};
 const syncCipherTextMessage = (data:any,clientId:string|null) =>{
  return api.create(url.WHATSAPP + "/syncCipherTextMessage/" + clientId,data)
 }

export {
  getFavourites,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  updatePrivacySetting,
  addParticipants,
  removeParticipants,
  syncingStopToCrm,
  makeAsAdmin,
  dismissAsAdmin,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  sendTelegramMessage,
  sendChatBotMessage,
  sendMessengerMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  getChannelDetails,
  toggleFavouriteContact,
  getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
  getClientDetails,
  createUser,
  createTelegramUser,
  sendSeenApi,
  getClientsList,
  getChannelList,
  getReportChannelList,
  getCampaignLists,
  getTemplateClientList,
  getAllocationList,
  getDownloadData,
  editWhatsappNumber,
  changeAssignUser,
  downloadChat,
  getLabels,
  updateChatUserDetails,
  warmupLogicConfirm,
  syncCipherTextMessage
};
