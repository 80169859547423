export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}
export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  USERS_PROFILE = "pills-user",
  CHAT = "pills-chat",
  CHANNELS = "pills-channels",
  CONTACTS = "pills-contacts",
  CALLS = "pills-calls",
  BOOKMARK = "pills-bookmark",
  SETTINGS = "pills-setting",
  USER = "pills-user-list",
  INTEGRATIONS = "pills-integrations",
  TEMPLATES = "pills-templates",
  NUMBER = "pills-numbers",
  CALENDER = "pills-calendar",
  CALLHIPPO = "pills-callhippo",
  CAMPAIGN = "pills-campaign",
  PLAN_BILLING = "pills-plan-billing",
  REPORTS = "pills-reports"
}