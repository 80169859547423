import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Col, Card, Button } from 'antd';
import { useProfile, useRedux } from '../../hooks';
import { getProfileDetails, changePassword } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { userProfile } = useProfile()
  const { dispatch, useAppSelector } = useRedux()
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isValidationPopupOpen, setValidationPopupOpen] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();


  const handlePasswordChange = (value: any) => {
    setPassword(value)
    setValidationPopupOpen(false)
  }

  const handleValidation = (rules: any) => {
    setValidationPopupOpen(rules);
  };
  useEffect(() => {
    if (userProfile?.userId) dispatch(getProfileDetails(userProfile?.userId));
  }, []);


  const {
    profileData,
    profileDetails,
    changePwdSuccessMsg,
    changepasswordError,
    changepasswordSuccess,
  } = useAppSelector(state => {
    return {
      profileData: state.Login.user,
      profileDetails: state.Profile.profileDetails,
      changePwdSuccessMsg: state.changePassword.changePwdSuccessMsg?.message?.message,
      changepasswordError: state.changePassword.changePwdErrorMsg?.error?.error,
      changepasswordSuccess: state.changePassword.changepasswordSuccess,
    };
  });

  useEffect(() => {
    if (profileDetails && Object.keys(profileDetails).length > 0) {
      form.setFieldsValue({
        fullName: profileDetails.fullName || '',
        phoneNumber: profileDetails.phoneNumber || '',
        companyName: profileDetails.companyName || '',
        email: profileDetails.email || '',
        timezone: profileDetails.timezone || '',
        country_code: profileDetails.country_code || ''
      });
    }
  }, [profileDetails]);

  useEffect(() => {
    if (changePwdSuccessMsg || changepasswordError) {
      if (changePwdSuccessMsg) {
        toast.success(changePwdSuccessMsg);
        const toastDismissTimer = setTimeout(() => {
          toast.dismiss();
          navigate("/welcome");
        }, 2000);
        return () => clearTimeout(toastDismissTimer);
      } else {
        toast.error(changepasswordError);
      }
    }
  }, [changePwdSuccessMsg, changepasswordError]);


  const onFinishPwd = (values: any) => {
    console.log("onSubmitForm ~ values:")
    const data = {
      oldPassword: values.oldPassword,
      password: values.newPassword,
    };
    if(data && isValidationPopupOpen){
      dispatch(changePassword(data));
    }
  }

  return (
    <>
        <div className="UserProfMain">
          <div id="sectionWrapper">
            <section id="changePassword" className="">
              <Card className="mb-0 userProfCardMain changePassCard" title="Change Password" bordered={false}>
                <Form
                  name="basic"
                  form={form1}
                  onFinish={onFinishPwd}
                  // onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="EmailFormAdd addtemplet_form"
                  requiredMark={false}
                // disabled={viewTempltState}
                >
                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item label="Enter Old Password" name="oldPassword" rules={[{ required: true, message: 'Please enter current password' }]}>
                      <Input.Password
                        className="temp_name_input"
                        placeholder="Enter Old Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item label="Enter New Password" name="newPassword" rules={[{ required: true, message: 'Please enter new password' }]}>
                      <Input.Password
                        // type={showPassword ? "text" : "password"}
                        className="temp_name_input"
                        placeholder="Enter New Password"
                        onChange={(e) => handlePasswordChange(e.target.value)}
                      />
                    </Form.Item>
                    <div className="passwordChecklist">
                  {password.length && !isValidationPopupOpen ? (
                    <PasswordChecklist
                      rules={["minLength", "maxLength", "specialChar", "number", "capital" ]}
                      minLength={8}
                      maxLength={22}
                      value={password}
                      messages={{
                        minLength: "Minimum 8 Characters",
                        maxLength: "Maximum 22 Characters",
                        specialChar: "Contain at least 1 Special Character",
                        number: "Contain at least 1 number",
                        capital: "Contain at least 1 Uppercase Letter",
                      }}
                      onChange={(rules) => handleValidation(rules)}
                    />
                  ):""}
                </div>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item className='d-flex align-items-center justify-content-center mb-0' >
                      <Button
                        type="primary"
                        htmlType="submit"
                        // disabled={!isModified}
                        className='btn-primary profileSubmitButton m-auto'
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </section>
          </div>
        </div>
    </>
  );
}

export default ChangePassword