import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';

const NonAuthLayoutComponent = styled.div`
    h1, h2, h3, h4, h5, h6, p, span, div, a{
        font-family: 'Poppins', sans-serif !important;
    }
    .containerlogin-main-wr {
        position: relative;
    }
    .containerlogin-main-wr::before {
        position: absolute;
        content: '';
        background-color: #F4F7FF;
        width: 50%;
        height: 100vh;
        top: 0;
        left: 0;
        @media (max-width: 991.98px) {
            content: unset !important;
        }
    }
    .authWrpr {
        width: 100%;
        margin: 0 auto !important;
        max-width: 1400px !important;
        height: 100vh;
        .authLeftBlock {
          @media (max-width:991.98px) {
            display: none !important; 
          }
          background-color: #F4F7FF;
          position: relative;
          padding: 0 90px;
          .authAuthorDesc{
            line-height: 30px;
            font-weight: 500;
            color: #121212;
          }
          .authAuthorName{
            font-weight: 300;
            .authAuthorBold {
              font-weight: 600;
              color: #000000;
            }
          }
          .authLeftTrustedWrpr{
            margin-top: 34px;
            .authLeftTrustedTitle{
              color: #959DB9;
              font-weight: 500;
              line-height: 21px;
            }
          }
        }
        .authRightBlock {
          @media (min-width:1300px) {
            padding-left: 5rem !important;
          }
          @media (min-width:992px) and (max-width:1299px) {
            padding-left: 2rem !important;
          }
          background-color: #ffffff;
          .headtitle {
            .headTitleHeading {
              color: #000000;
              font-size: 32px;
              font-weight: 600;
              @media (max-width:579px) {
                text-align: center;
                font-size: 30px;
              }
            }
            .headTitleHeadingColored {
              color: #0029C7;
            }
          }
          .inputField {
            padding: 15px 16px;
          }
          .employeeNo.css-b62m3t-container,
          .useCase.css-b62m3t-container{
            .css-13cymwt-control,
            .css-t3ipsp-control{
              height:55.6px !important;
            }
          }
          .authButtons,.inputFieldButton{
            padding: 14.2px;
          }
          .inputFieldButton {
            text-transform: uppercase;
          }
          .inputPassMainDiv{
            position: relative;
            .newPassInput{
              padding-right:32px !important;
            }
            .eyeIconPass{
              position: absolute;
              right: 1rem;
              top:19px;
              line-height:normal !important;
              color:#797c8c !important;
              &.crossEyeIcon{
                background-image: url('https://images.controlhippo.com/svg/eye-off-fill.svg');
                height: 15px !important;
                width: 15px !important;
                background-size: contain !important;
                background-position: center !important;
                background-repeat: repeat !important;
                display: inline-block !important;
                filter: brightness(0) saturate(100%) invert(51%) sepia(6%) saturate(849%) hue-rotate(193deg) brightness(95%) contrast(89%) !important;
              }
            }
            button {
              margin-top: 6px;
            }
          } 
          .authentication-page-content {
            background-color: $white;
            @media (min-width:1300px) {
              max-width: 520px !important;
            }
            @media (min-width:992px) and (max-width:1299px) {
              max-width: 496px !important;
            }
            @media (max-width:991.98px) {
              max-width: 100% !important;
              padding: 0px 5% !important;
              margin: 0 auto !important;
            }
          }
        }
      }
      
      .thankyouMainBlock {
        .thankyouBlockInnerWr {
          border: 2px solid #e6ebf5;
          border-radius: 10px;
          box-shadow: 1px 1px 4px #e6ebf5!important;
          @media (max-width:579px) {
            padding: 20px !important;
          }
        }
        .openGmailBtn {
          border: 2px solid #9da9c1;
          border-radius: 10px;
          box-shadow: 1px 1px 4px #F4F7FF !important;
          display: block;
          background-color: #F4F7FF;
          color: #495057;
          font-weight: 500;
        }
        .openGmailBtnImg {
          margin-top: -4px;
          width: 24px;
        }
        .mailLockIcon {
          width: 120px;
        }
    }
    .selectPhoneNbDrpDwnDiv{
        .select__menu-list{
            max-height: 200px !important; 
            &::-webkit-scrollbar{
                width: 6px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #9f9f9f;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track{
                background: #e8e8e8;
            }
        }
        .select__option,
        .select__option.select__option--is-focused{
            text-align: left !important;
            font-size: 13px !important;
            padding: 0.3rem 0.5rem !important;
        }
        .select__indicator.select__dropdown-indicator{
            svg{
                height: 16px !important;
                width: 16px !important;
            }
        }
    }
    @media (max-width:579px) {
        .authWrpr { 
            padding-top: 1.5rem !important;
        }
        .signupLoginHeader {
            flex-direction: column;
            text-align: center !important;
            margin-bottom: 1.2rem !important;
        }
        .signupLoginInnrHeader {
            flex-direction: column;
            gap: 18px;
        }
        .loginSignFootBottom {
            padding: 0 !important;
        }
        .thankyouTroubleText {
            font-size: 14px;
        }
    }
    @media (max-width:479px) {
        .thankyouTrouble {
            flex-direction: column;
        }
    }
    .passwordChecklist{
      width:100%;
      position:relative !important;
      ul{
        width:100%;
        margin: 18px auto 0 !important;
        background-color: rgb(255, 255, 255);
        background-clip: padding-box;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
        z-index: 1;
        padding: 12px 16px !important;
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 18px;
            height: 18px;
            background: 0px 0px;
            border-style: solid;
            border-width: 4.24264px;
            top: -20px;
            border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255) transparent;
            box-shadow: rgba(0, 0, 0, 0.07) 3px 3px 7px;
            transform: translateY(67%) rotate(-135deg);
            left: 50%;
        }
        .valid{
          &:before{
            background-image: url('https://images.controlhippo.com/svg/checkcircle-green.svg');
          }
        }
        .invalid{
          &:before{
            background-image: url('https://images.controlhippo.com/svg/cancelicon-red.svg');
          }
        }
        li{
          position: relative !important;
          padding-left:20px !important;
          margin-bottom:6px !important;
          &:last-child{
            margin-bottom:0px !important;
          }
          &:before{
            position: absolute !important;
            left: -3px !important;
            top: 3px !important;
            content: "" !important;
            height: 18px !important;
            width: 18px !important;
            background-size: contain !important;
            background-position: center !important;
            background-repeat: repeat !important;
          }
          svg{
            display:none !important;
          }
        }
      }
    }
    .cnhlogin__divider {
      height: 1px;
      background: #dcdce1;
      position: relative;
      margin: 30px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .chlogin_dividertext {
        background: rgb(255, 255, 255);
        padding: 0px 6px;
        position: absolute;
        color: #808086;
        font-size: 12px;
        font-family: Outfit, sans-serif;
      }
    }
    .googleWithSignBtn{
      div[aria-labelledby = button-label]{
        height: 50px !important;
        > div > div{
          width: 28px !important;
          height: 28px !important;;
          min-width: 28px !important;;
          margin-left: 0 !important;;
        }
      }
    } 
    .forgotPassDiv{
      @media(max-width:500px){
        margin-top: 1rem !important;
      }
    }
`;
export { NonAuthLayoutComponent };
