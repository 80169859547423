import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CampaignActionTypes } from "./types";
import { campaignApiResponseSuccess, campaignApiResponseError } from "./actions";

import {
  campaignCreate as campaignCreateApi,
  campaignList as campaignListApi,
  campaignDetails as campaignDetailsApi,
  campaignUpdate as campaignUpdateApi,
  campaignDelete as campaignDeleteApi
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* campaignCreate({ payload: data,callback }: any) {
  try {
    const response: Promise<any> = yield call(campaignCreateApi, data);
    if (typeof callback === 'function') {
      callback(response);
    }
    yield put(campaignApiResponseSuccess(CampaignActionTypes.CAMPAIGN_CREATE,response));
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(campaignApiResponseError(CampaignActionTypes.CAMPAIGN_CREATE, error));
  }
}

function* campaignUpdate({ payload: data ,callback}: any) {
  try {
    const response: Promise<any> = yield call(campaignUpdateApi, data);
    if (typeof callback === 'function') {
      callback(response);
    }
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      campaignApiResponseError(CampaignActionTypes.CAMPAIGN_UPDATE, error)
    );
  }
}

function* campaignDelete({ payload: data,callback }: any) {
  try {
    const response: Promise<any> = yield call(campaignDeleteApi, data);
    if (typeof callback === 'function') {
      callback(response);
    }
    yield put( campaignApiResponseSuccess(CampaignActionTypes.CAMPAIGN_DELETE, response) );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      campaignApiResponseError(CampaignActionTypes.CAMPAIGN_DELETE, error)
    );
  }
}

function* campaignList() {
  try {
    const response: Promise<any> = yield call(campaignListApi);
    yield put(
      campaignApiResponseSuccess(CampaignActionTypes.CAMPAIGN_LIST, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      campaignApiResponseError(CampaignActionTypes.CAMPAIGN_LIST, error)
    );
  }
}

function* campaignDetails({ payload: id ,callback}: any) {
  try {
    const response: Promise<any> = yield call(campaignDetailsApi, id);
    if (typeof callback === 'function') {
      callback(response);
    }
    yield put(
      campaignApiResponseSuccess(CampaignActionTypes.CAMPAIGN_DETAILS, response)
    );
  } catch (error: any) {
    yield call(showErrorNotification, "Something went wrong!!!");
    yield put(
      campaignApiResponseError(CampaignActionTypes.CAMPAIGN_DETAILS, error)
    );
  }
}

export function* watchAddUserList() {
  yield takeEvery(CampaignActionTypes.CAMPAIGN_CREATE, campaignCreate);
}
export function* watchCampaignUpdate() {
  yield takeEvery(CampaignActionTypes.CAMPAIGN_UPDATE, campaignUpdate);
}
export function* watchCampaignDelete() {
  yield takeEvery(CampaignActionTypes.CAMPAIGN_DELETE, campaignDelete);
}
export function* watchGetCampaignList() {
  yield takeEvery(CampaignActionTypes.CAMPAIGN_LIST, campaignList);
}
export function* watchGetCampaignDetails() {
  yield takeEvery(CampaignActionTypes.CAMPAIGN_DETAILS, campaignDetails);
}

function* getAddListSaga() {
  yield all([
    fork(watchAddUserList),
    fork(watchGetCampaignList),
    fork(watchGetCampaignDetails),
    fork(watchCampaignUpdate),
    fork(watchCampaignDelete),
  ]);
}
export default getAddListSaga

