import React, { useState, useEffect, useRef } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, UncontrolledTooltip, Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip,
} from "reactstrap";

import ReactAudioPlayer from 'react-audio-player';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';

import { List, Popover, Checkbox as AntdCheckbox, Checkbox } from 'antd'; // Assuming you're using Ant Design

import classnames from "classnames";
import { Link } from "react-router-dom";
import Linkify from 'react-linkify';
import copy from 'clipboard-copy';
import moment from "moment";

// components
import LightBox from "../../../components/LightBox";

//images
import imagePlaceholder from "../../../assets/images/users/profile-placeholder.png";
import excelIcon from "../../../assets/images/sheets.png";
import pdfIcon from "../../../assets/images/pdf.png";
import mp4Icon from "../../../assets/images/mp4_icon.png";
import mp3Icon from "../../../assets/images/mp3_icon.png";
import docsIcon from "../../../assets/images/docs_icon.png";
import pptIcon from "../../../assets/images/ppt_icon.png";
import zipIcon from "../../../assets/images/zip_icon.png";
import pngIcon from "../../../assets/images/PNG.svg";
import jpgIcon from "../../../assets/images/JPG.svg";
import jpegIcon from "../../../assets/images/JPEG.svg";
import webmIcon from "../../../assets/images/Webm.svg";
import userImageIcn from "../../../assets/images/user_profile.svg";

// interface
import {
  MessagesTypes,
  ImageTypes,
  AttachmentTypes,
} from "../../../data/messages";

// hooks
import { useProfile, useRedux } from "../../../hooks";

// utils
import { formateDate } from "../../../utils";
import RepliedMessage from "./RepliedMessage";

import { downloadFile, createUser, syncCipherTextMessage } from "../../../redux/actions";
import { showErrorNotification } from "../../../helpers/notifications";
import { prepareGroupText } from "../../../data";


const TelegramIconMsg  = "https://images.controlhippo.com/svg/telegram-chnl.svg";
const WhastappIconMsg  = "https://images.controlhippo.com/svg/whatsapp-chnl.svg";
const WhastappBusinessIconMsg  = "https://images.controlhippo.com/svg/whatsapp-business-chnl.svg";


let lastDisplayedDate: string | null = null;
interface MenuProps {
  message: MessagesTypes;
  onDelete: (value: any) => any;
  onReply: () => any;
  onDownload : any;
  onForward: () => void;
  isFromMe?: boolean;
  isReplyDisplay?: boolean;
  clientId?: any;
}

type Option = {
  conversationId:any;
  serializedId : any;
  chatId: any;
};

const Menu = ({ message, onDelete, onReply, onForward, isFromMe, onDownload, isReplyDisplay, clientId }: MenuProps) => {
  const text: string | undefined | any = message?.text
  const [deleteMessageModalShow, setDeleteMessageModalShow] = useState<boolean>(false);
  const [replyModalShow, setReplyModalShow] = useState<boolean>(false);

  const toggle = () => setDeleteMessageModalShow(!deleteMessageModalShow);
  const deleteMessageToggal = () => {
    toggle()
  }
  const deleteMessage = (value: any) => {
    onDelete({ option: value, message })
    toggle()
  }
  const todayDate : any = new Date()
  const createdAtDate : any = message?.timestamp ? new Date(moment.unix(parseInt(message?.timestamp)/1000).toString()) :new Date(message?.createdAt) ;
  const timeDifference = todayDate - createdAtDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle className={`btn btn-toggle p-0 lh-sm ${isFromMe && "formMeMenuBtn" }`} role="button" tag={"a"}>
        {/* <i className="ri-more-2-fill"></i> */}
        <i className='bx bx-chevron-down lh-sm align-top text-secondary'></i>
      </DropdownToggle>
      <DropdownMenu>{isReplyDisplay && message?.type != "internal-note" && clientId !== "all_numbers" &&
         <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onReply}
        >
          Reply <i className="bx bx-share ms-2 text-muted"></i>
        </DropdownItem>
}
        {message.type !== "internal-note" && <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onForward}
        >
          Forward <i className="bx bx-share share-forward-icon ms-2 text-muted"></i>
        </DropdownItem>}
        {
          message.type == "chat" && 
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => copy(text)}
        >
          Copy <i className="bx bx-copy text-muted ms-2"></i>
        </DropdownItem>
        }
        {
          message.type !== "chat" && message.type !== "internal-note" && (!message?.filename?.includes("ogg") || !message?.fileName?.includes("ogg"))   && 
          <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onDownload(message.conversationId, message.chatId)}
        >
          Download <i className="bx bx-download text-muted ms-2"></i>
        </DropdownItem>
        }
        
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => replyMessage()}
        >
          Reply <i className="bx bx-copy text-muted ms-2"></i>
        </DropdownItem> */}
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
        </DropdownItem> */}
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >s
          Mark as Unread <i className="bx bx-message-error text-muted ms-2"></i>
        </DropdownItem> */}
        {((isFromMe &&  (daysDifference <= 0)) || message?.type =="internal-note") && <DropdownItem
          className="d-flex align-items-center justify-content-between delete-item"
          onClick={() => deleteMessageToggal()}
        >
          Delete <i className="bx bx-trash text-muted ms-2"></i>
        </DropdownItem>}
      </DropdownMenu>
      <Modal isOpen={deleteMessageModalShow} toggle={toggle} centered>
        <ModalHeader className="primary message_delete_heading" toggle={toggle} >{message.type !== "internal-note" ? "Delete Message ?" : "Delete Note ?"}</ModalHeader>
        <ModalBody>
          {message.type !== "internal-note" ? <div className="row align-items-left">
            <div className="col-12">
              <Button className="delete_btn" color="link" outline onClick={() => deleteMessage("DeleteForEveryOne")}>
                <span className="primary">Delete for everyone</span>
              </Button>
            </div>
            <div className="col-12">
              <Button color="link" className="primary delete_btn" outline onClick={() => deleteMessage("DeleteForMe")}>
                <span className="primary">Delete for me</span>
              </Button>
            </div>
          </div> :<div className="row align-items-left">
            <div className="col-12">
              <Button className="delete_btn" color="link" outline onClick={() => deleteMessage("DeleteForMe")}>
                <span className="primary">Delete</span>
              </Button>
            </div>
          </div> }
        </ModalBody>
        {/* <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
    </UncontrolledDropdown>
  );
};
interface ImageMoreMenuProps {
  imagelink: any,
  onReply: () => any;
  onDelete: () => void;
  onDownload: (value: boolean) => void;
}
const ImageMoreMenu = ({ imagelink, onReply, onDelete, onDownload }: ImageMoreMenuProps) => {
  return (
    <div className="message-img-link">
      <ul className="list-inline mb-0">
        <UncontrolledDropdown
          tag="li"
          color="none"
          className="list-inline-item dropdown"
        >
          <DropdownToggle tag="a" role="button" className="btn btn-toggle">
            <i className="bx bx-dots-horizontal-rounded"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="dropdown-item d-flex align-items-center justify-content-between"
              onClick={() => onDownload(true)}
            >
              Download <i className="bx bx-download ms-2 text-muted"></i>
            </DropdownItem>
            {/* <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
              onClick={onReply}
            >
              Reply <i className="bx bx-share ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
              data-bs-toggle="modal"
              data-bs-target=".forwardModal"
            >
              Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
            >
              Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between delete-item"
              href="#"
              onClick={onDelete}
            >
              Delete <i className="bx bx-trash ms-2 text-muted"></i>
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ul>
    </div>
  );
};

interface ImageProps {
  chatUserDetails:any
  message: MessagesTypes,
  image: ImageTypes;
  onImageClick: (id: number) => void;
  index: number;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg: (imageId: string | number) => void;
  deleteForEveryOne : boolean;
  indexKey: any;
}

const formatFileSize = (bytes: number) => {
  if (bytes >= 1024 * 1024) {
    // Convert to megabytes
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  } else {
    // Convert to kilobytes
    return (bytes / 1024).toFixed(2) + ' KB';
  }
}
const Image = ({ chatUserDetails,message, image, onImageClick, indexKey, onSetReplyData, onDeleteImg ,deleteForEveryOne}: ImageProps) => {
  const [hasText, sethasText] = useState<any>(false)
  const isGrouped = chatUserDetails && chatUserDetails.isGroup ? true : false;
  const { dispatch, useAppSelector } = useRedux();
  const [base64StringUrl, setBase64StringUrl] = useState<any>("");
  const [tempDisplayImgUrl, setTempDisplayImgUrl] = useState<any>("");
  const [displayImgUrl, setDisplayImgUrl] = useState<any>("");
  const {
    clientId,
    downloadFileData,
    loading,
    clientList
  } = useAppSelector((state) => ({
    clientId: state.Chats.clientId,
    loading: state.Chats.loading,
    downloadFileData: state.Chats.downloadFileData,
    clientList: state.Chats.clientList    
  }))
  useEffect(() => {
    const messageText : any = message.caption ? message.caption : message.text !== "" && message.text;
    if (isGrouped) {
      // Text replace with participate name
      prepareGroupText(messageText, message?.mentionedIds, chatUserDetails?.participants).then((result)=>{
        sethasText(result)
      })
    }else{
      sethasText(messageText != "undefined" ? messageText : "")
    }
  }, [message])

  useEffect(() => {
    if(displayImgUrl){
      setTempDisplayImgUrl(displayImgUrl)
    }
  }, [displayImgUrl])
  
  const openDatabase = (version = 3) => {
    return new Promise((resolve, reject) => {
      const dbRequest = indexedDB.open('MyDatabase', version);  
      dbRequest.onupgradeneeded = function (event: any) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('Media')) {
          db.createObjectStore('Media', { autoIncrement: true });
        }
        if (!db.objectStoreNames.contains('DraftText')) {
          db.createObjectStore('DraftText', { keyPath: 'chatId' });
        }
      };
  
      dbRequest.onsuccess = function (event: any) {
        resolve(event.target.result);
      };
  
      dbRequest.onerror = function (event) {
        reject(event);
      };
    });
  };

  const handleChat = async (conversationId: any) => {
    try {
      const db: any = await openDatabase();
      
      const fileData = downloadFileData?.data;
      if (fileData instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = function() {
          const base64String = reader.result;
  
          const transaction = db.transaction(["Media"], 'readwrite');
          const store = transaction.objectStore("Media");
          if (downloadFileData?.data?.conversationId == conversationId) {
            const createdDate = new Date();
            const downLoadUrl = {
              base64StringUrl: base64String,
              createdDate: createdDate.getTime(),
              conversationId: conversationId
            };
            const addRequest = store.put(downLoadUrl, conversationId);
          }
  
          setBase64StringUrl(base64String);
        };
        reader.readAsDataURL(fileData);
      }
  
      const transaction = db.transaction(["Media"], 'readwrite');
      const store = transaction.objectStore("Media");
      const getRequest = store.get(conversationId);
      getRequest.onsuccess = function (getEvent: any) {
        const retrievedFileData = getEvent.target.result;
        if (retrievedFileData) {
          const createdDate = retrievedFileData.createdDate;
          const currentDate = new Date().getTime();
          if (currentDate - createdDate >= 3 * 24 * 60 * 60 * 1000) {
            const deleteRequest = store.delete(conversationId);
            deleteRequest.onsuccess = function () {
            };
          }
        }
  
        if (retrievedFileData?.base64StringUrl.length > 0) {
          if (message.conversationId == retrievedFileData?.conversationId) {
            setDisplayImgUrl(retrievedFileData?.base64StringUrl);
          }
        }
      };
    } catch (error) {
      console.error('Error handling chat:', error);
    }
  };
  
  
  
  useEffect(() => {
    if (downloadFileData?.data || message?.chatId) {
      handleChat(message.conversationId);
    }
  }, [downloadFileData,message?.chatId]);
  const onDownload = (value: boolean) => {
    const conversationId: any = message.conversationId
    const chatId: any = message.chatId
    if (!loading) dispatch(downloadFile(conversationId, chatId, message?.clientId))
  }
  return (
    <React.Fragment>
      <div className={`${deleteForEveryOne ? "w-100" : "message-img-list w-100"}`}>
        <div>
          {/* <Link
            className="popup-img d-inline-block"
            to={"#"}
            onClick={() => onImageClick(index)}
          ></Link> */}
          {/* {message?.fromMe &&
                <div className="font-size-12 senderName me-2 text-start">
                  ~ {message.senderName}
                </div>
              } */}
          {deleteForEveryOne ? <div className="d-flex align-items-center gap-1 text-muted mb-1 wpContentMsg"><i className='bx bx-block'></i> <p className="mb-0 lh-sm">You deleted this message</p></div> : !displayImgUrl ?
            <div className="d-flex flex-column imgDownloadMain">
              <div className="d-flex flex-column imgDownloadBtnDiv">
                <img src={image.downloadLink} alt="" className="rounded border" id={"message_" + indexKey} />
                <Button
                  className="imgDownloadIcon p-1 py-0"
                  outline
                  onClick={() => onDownload(true)}
                // onClick={() => onImageClick(index)}
                >
                  <span className="d-flex align-items-center justify-content-center m-auto bg-dark gap-1">
                    <i className="bx bxs-download" id={"img" + message.conversationId}></i>
                    <p className="text-muted text-truncate font-size-13 mb-0">
                      {formatFileSize(parseInt(image?.desc))}
                      {/* {attachment.desc} */}
                    </p>
                  </span>
                </Button>
              </div>
              {hasText !== undefined ? <div className="ctext-wrap mb-0 w-100">
                <div className="ctext-wrap-content px-2 mt-1 w-100">
                  <p className="mb-0 ctext-content text-start text-break wpContentMsg lh-sm">{hasText}</p>
                </div>
              </div> : ""}
              <UncontrolledTooltip placement={message.fromMe ? "left" : "right"} target={"img" + message.conversationId}
                autohide={true}
                flip={true}
              >
                <small className={classnames("text-muted", "mb-0", "ms-2")}>{"Download"}</small>
              </UncontrolledTooltip>
            </div>
            :
            <div>
              <Link
                className="popup-img d-block afterDownloadImg"
                to={"#"}
                onClick={() => onImageClick(tempDisplayImgUrl)}
              >
                <div className="d-flex flex-column imgDownloadMain">
                  <img src={tempDisplayImgUrl} alt="" className="rounded border" id={"message_" + indexKey} />
                  {hasText !== undefined ? <div className="ctext-wrap mb-0 w-100">
                    <div className="ctext-wrap-content px-2 mt-1 w-100">
                      <p className="mb-0 ctext-content text-start text-break wpContentMsg lh-sm">{hasText}</p>
                    </div>
                  </div> : ""}
                </div>
              </Link>
            </div>
          }

          {/* </Link> */}
        </div>
        {/* <ImageMoreMenu imagelink={image.downloadLink}  onDownload={onDownload}/> */}
      </div>
    </React.Fragment>
  );
};
interface ImagesProps {
  message: MessagesTypes,
  images: ImageTypes[];
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg: (imageId: string | number) => void;
  indexKey: any;
  deleteForEveryOne : boolean;
  chatUserDetails:any
}
const Images = ({ chatUserDetails,message, images, onSetReplyData, onDeleteImg, indexKey,deleteForEveryOne }: ImagesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const onImageClick = (displayImgUrl: number) => {
    setSelected(displayImgUrl);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  
  return (
    <>
      <div className={`${deleteForEveryOne ? "mb-0":"message-img mb-0"}`}>
        {(images || []).map((image: ImageTypes, key: number) => (
            <Image
              chatUserDetails={chatUserDetails}
              message={message}
              image={image}
              key={key}
              index={key}
              onImageClick={onImageClick}
              onSetReplyData={onSetReplyData}
              onDeleteImg={onDeleteImg}
              deleteForEveryOne={deleteForEveryOne}
              indexKey={indexKey}
            />
        ))}
      </div>
      {isOpen && (
        <LightBox
          isOpen={isOpen}
          images={selected}
          onClose={onClose}
          defaultIdx={selected}
        />
      )}
    </>
  );
};

interface AttachmentsProps {
  attachments: AttachmentTypes[] | undefined;
  onDownload: (value1: any, value2: any, reponseType?: any) => void;
  message: MessagesTypes,
  indexKey: any
  deleteForEveryOne:boolean,
  chatUserDetails:any
}
interface ExtensionToImage {
  [key: string]: string;
}
const Attachments = ({ chatUserDetails,attachments, onDownload, message, indexKey,deleteForEveryOne }: AttachmentsProps) => {
  const [hasText, sethasText] = useState<any>(false)
  const isGrouped = chatUserDetails && chatUserDetails.isGroup ? true : false;
  const { dispatch, useAppSelector } = useRedux();
  const [audioUrl,setAudioUrl] = useState<any>("") 

  const {
    clientId,
    downloadFileData,
    loading
  } = useAppSelector((state) => ({
    clientId: state.Chats.clientId,
    loading: state.Chats.loading,
    downloadFileData: state.Chats.downloadFileData,
  }))

  useEffect(() => {
    const messageText :any= message.caption ? message.caption : message.text !== "" && message.text
    if (isGrouped) {
      // Text replace with participate name
      prepareGroupText(messageText, message?.mentionedIds, chatUserDetails?.participants).then((result)=>{
        sethasText(result)
      })
    } else {
      sethasText(messageText != "undefined" ? messageText : "")
    }
  }, [message])
  // const formatFileSize = (bytes:number) => {
  //   if (bytes >= 1024 * 1024) {
  //     // Convert to megabytes
  //     return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  //   } else {
  //     // Convert to kilobytes
  //     return (bytes / 1024).toFixed(2) + ' KB';
  //   }
  // }

  const handleChat = async (conversationId: any) => {
    try {
      const dbRequest = indexedDB.open('MyDatabase', 3);
  
      dbRequest.onupgradeneeded = function (event: any) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("Media")) {
          db.createObjectStore("Media", { autoIncrement: true });
        }
      };
  
      dbRequest.onsuccess = function (event: any) {
        if(downloadFileData?.data?.reponseType?.reponseType){
        const fileData = downloadFileData?.data;
        const db = event.target.result;
        const transaction = db.transaction(["Media"], 'readwrite');
        const store = transaction.objectStore("Media");
  
        if (fileData && fileData?.conversationId == conversationId) {
          const createdDate = new Date();
          fileData.createdDate = createdDate.getTime();
          const addRequest = store.put(fileData, conversationId);
          addRequest.onsuccess = function (addEvent: any) {
            // Handle success if needed
          };
        }
      }
        const db = event.target.result;
        const transaction = db.transaction(["Media"], 'readwrite');
        const store = transaction.objectStore("Media");
        if(conversationId){
          const getRequest =conversationId && store.get(conversationId);
          getRequest.onsuccess = function (getEvent: any) {
            const retrievedFileData = getEvent.target.result;
    
            if (retrievedFileData) {
              const createdDate = retrievedFileData.createdDate;
              const currentDate = new Date().getTime();
              if (currentDate - createdDate >= 3 * 24 * 60 * 60 * 1000) {
                const deleteRequest = store.delete(conversationId);
                deleteRequest.onsuccess = function () {
                  
                };
              }
            }
    
            if (retrievedFileData?.downLoadUrl?.length > 0) {
              setAudioUrl(retrievedFileData?.downLoadUrl);
            }
          };
        }
    }
    } catch (error) {
      
    }
  };
  
  
  useEffect(() => {
    if (downloadFileData?.data?.reponseType || message?.chatId) {
      handleChat(message.conversationId);
    }
  }, [downloadFileData,message?.chatId]);

  const onAttachmentClick = (value: number) => {
    const validExtensions = ["ogg", "mp3", "mpeg", "m4a", "flac", "wav", "wma", "aac"];
    const extension = getExtension(message.fileName || (message?.attachments && message?.attachments[0].name));
  
    if (validExtensions.includes(extension)) {
      onDownload(message.conversationId, message.chatId, { reponseType: true });
    } else {
      onDownload(message.conversationId, message.chatId, {isLocalDownload: true});
    }
  };
  // const onAttachmentClick= (value:number) => {
  //   onDownload(message.conversationId,message.chatId)
  // }
  
  const extensionToImage: ExtensionToImage = {
    pdf: pdfIcon,
    xlsx: excelIcon,
    xls: excelIcon,
    "ms-excel": excelIcon,
    sheet: excelIcon,
    mp4: mp4Icon,
    csv: excelIcon,
    mp3: mp3Icon,
    mpeg: mp3Icon,
    ogg: mp3Icon,
    m4a: mp3Icon,
    zip: zipIcon,
    ppt: pptIcon,
    pptx: pptIcon,
    docx: docsIcon,
    msword: docsIcon,
    doc: docsIcon,
    png: pngIcon,
    jpg: jpgIcon,
    jpeg: jpegIcon,
    webm: webmIcon,
  };
  const getExtension = (data: any): string => {
    const parts = data && data.split('.');
    if (parts?.length > 1) {
      let extension = parts[parts.length - 1].toLowerCase();
      if (extension === "ogg; codecs=opus") {
        extension = "ogg";
      }
      return extension in extensionToImage ? extension : 'docx';
    } else {
      return 'docx';
    }
  };

  const getCountryCode = (number: any, defaultRegion = 'US') => {
    return new Promise((resolve, reject) => {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const phoneNumber = phoneUtil.parseAndKeepRawInput(number, defaultRegion);
        let countryCode =  phoneNumber.getCountryCode();
        resolve(countryCode);
      } catch (error) {
        
        reject(error);
      }
    });
  };

  const handleUserCreate = async (phoneNumber: any, contactName: any, clientId1: any) => {
    let tempPhoneNumber = "+" + phoneNumber
    const countryCode = await getCountryCode(tempPhoneNumber)

    const data = {
      phoneNumber: phoneNumber,
      name: contactName,
      message: null,
      countryCode: countryCode
    }
    dispatch(createUser({ chatId: phoneNumber + "@c.us", clientId: clientId !== "all_numbers" ? clientId : clientId1, data }, (response: any) => {
      if (!response?.success) {
        showErrorNotification(response.message)
      }
    }))

  }

  const formattedNumber = (phoneNumber: any, contactName: any): any => {
    if (phoneNumber?.length) {
      let phoneNumberWithoutSpace = phoneNumber?.replace(/\s/g, '');
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const formatPhoneNumber = (countryPhoneNumber: PhoneNumber): string => {
        const formattedNumber = phoneNumberUtil.format(countryPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
        return formattedNumber.replace(/ /g, ' ');
      };
      let decodedNumber: string | null = null;
      let number: PhoneNumber | undefined;

      if (phoneNumberWithoutSpace.startsWith('+')) {
        phoneNumberWithoutSpace = phoneNumberWithoutSpace;
      } else {
        phoneNumberWithoutSpace = '+' + phoneNumberWithoutSpace;
      }
      if (phoneNumberWithoutSpace) {
        try {
          const phoneRegex = /^\+?[0-9]{1,3}[- ]?\(?[0-9]{3}\)?[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
          const isPhoneNumber = phoneRegex.test(phoneNumberWithoutSpace);
          if (isPhoneNumber) {
            number = phoneNumberUtil.parse(phoneNumberWithoutSpace);
            decodedNumber = formatPhoneNumber(number);
            return decodedNumber
          }
        } catch (e) {
          
        }
      }
    }
  }


  return (
    <>
      {(attachments || []).map((attachment: AttachmentTypes, key: number) => {
        var googleMapsUrl
        if(attachment?.locationObject){
          if (attachment?.locationObject?.description) {
            const phrase = attachment?.locationObject?.description;
            const words = phrase.split(' ');
            const searchQuery = words.join('%20');
            googleMapsUrl = `https://maps.google.com/maps/search/${searchQuery}/@${attachment?.locationObject.latitude
              },${attachment?.locationObject.longitude},17z?hl=en`;
          }else{
            googleMapsUrl = `https://maps.google.com/maps?q=${attachment?.locationObject?.latitude},${attachment?.locationObject?.longitude}&z=17&hl=en`; 
          }
        }
        return (
          <>
          {/* {message?.fromMe &&
            <div className="font-size-12 senderName me-2 text-start">
              ~ {message.senderName}
            </div>
          } */}
            <div id={"message_" + indexKey}
              key={key}
              className={classnames(`${deleteForEveryOne || message.isDelete_everyone ? "d-none":""}`,"attachmentDiv", "mb-0", "rounded-3",`${(attachment?.type == "vcard" || attachment?.type == "multi_vcard" ) && 'vCardMainDiv'}`, `${attachment?.type == "location" || attachment?.type == "vcard" || attachment?.type == "multi_vcard" ? 'p-0': 'p-2'}`,{
                "mt-2": key !== 0,
              })}
            >
              <div className="d-flex align-items-center attached-file mt-1">
              
                {(attachment?.type == "location") ?
                  <div>
                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                      <img
                        src={attachment?.downloadLink}
                        width={200}
                        height={"200"}
                        className="locationImg"
                      /></a>
                      {hasText ? <div className="ctext-wrap mb-0 w-100">
                      <div className="ctext-wrap-content px-2 chatTimeMain mt-1 w-100">
                        <p className="ctext-content text-start text-break wpContentMsg lh-sm locationImgText">{hasText}</p>
                      </div>
                    </div> : ""}

                  </div>

                  : 
                  
                  (attachment?.type !== "vcard" && attachment?.type !== "multi_vcard") &&
                  <div className="flex-shrink-0 me-3 ms-0 attached-file-avatar">
                    
                    <img
                      src={attachment.name ? extensionToImage[getExtension(attachment.name)] :"javascript:void(0)"}
                      width={32}
                      height={"auto"}
                    />
                  </div>
                  }
                {(attachment?.type == "vcard" || attachment?.type == "multi_vcard") &&
                  <div>
                    <div className="d-flex align-items-center gap-2 vCardUserIfo">
                    {true ?
                      <span className="userVCardIcon rounded-circle bg-light avatar-sm d-flex align-items-center justify-content-center me-1">
                        <i className="bx bx-user text-primary font-size-18 fw-bold"></i>
                      </span>
                      :
                      <img
                        src={userImageIcn}
                        width={32}
                        height={"auto"}
                        className="avatar-sm rounded-circle"
                      />
                    }
                      <div className="d-flex flex-column align-items-start">
                        <h6 className="font-size-12 text-dark mb-0 text-truncate">{attachment?.downloadLink}</h6>
                        <p className="font-size-11 mb-0 text-truncate">{formattedNumber(attachment?.phoneNumber, attachment?.downloadLink)}</p>
                      </div>
                    </div>
                    {/* <h6>{attachment?.phoneNumber}</h6> */}
                    <div className="w-100 text-center">
                      <Button className="vCardMsgBtn" onClick={() => handleUserCreate(attachment?.phoneNumber,attachment?.downloadLink,message?.clientId)}>Message</Button>
                    </div>
                  </div>
                }

                {!audioUrl && attachment?.type !== "location" && attachment?.type !== "vcard" && attachment?.type !== "multi_vcard" &&
                  <div className="flex-grow-1 overflow-hidden">
                    <div className="text-start">
                      <h5 className="font-size-13 mb-1 text-break">{attachment.name}</h5>
                      <p className="text-muted text-truncate font-size-13 mb-0">
                        {formatFileSize(parseInt(attachment.desc))}
                        {/* {attachment.desc} */}
                      </p>
                    </div>
                  </div>}

                {attachment?.type !== "location" && attachment?.type !== "vcard" && attachment?.type !== "multi_vcard" &&
                  <div className="flex-shrink-0">
                    <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                      {!audioUrl ?
                        <div className="ms-4">
                          <a
                            // href={attachment.downloadLink ? attachment.downloadLink : "#"}
                            href={"#"}
                            className="text-muted downloadAttachmentIcon d-flex align-items-center justify-content-center"
                            onClick={() => onAttachmentClick(key)}
                          >
                            <i className="bx bxs-download"></i>
                          </a>
                        </div>
                        :
                        <div className="chatMp3AudionMain d-flex">
                          <ReactAudioPlayer
                            src={audioUrl}
                            controls
                          // onPlay={()=>{handlePlay(message?.conversationId)}}
                          // onPause={handlePause}
                          />
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>

            </div>
            {hasText ? 
              <p className="mb-0 ctext-content text-start text-break wpContentMsg lh-sm font-size-14">{hasText}</p>
            : ""}
          </>
        )
      })}
      { message.isDelete_everyone ? <div className="d-flex align-items-center gap-1 text-muted mb-1 wpContentMsg"><i className='bx bx-block'></i> <p className="mb-0 lh-sm">You deleted this message</p></div>:""}
    </>
  );
};

const Typing = () => {
  return (
    <p className="mb-0">
      typing
      <span className="animate-typing">
        <span className="dot mx-1"></span>
        <span className="dot me-1"></span>
        <span className="dot"></span>
      </span>
    </p>
  );
};
interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  onDelete: (messageId: string | number, option: string,clientId : string) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isFromMe: boolean;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
  // onSetReplyImageData: () => void;
  indexKey: any;
  deleteForEveryOne: boolean;
  handleReplyScroll: any;
  messageIndexId: any;
  messages?: any;
  forwardData?: any;
  handleCheckBox?: any;
  handleClose?: any;
}
const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
  indexKey,
  deleteForEveryOne,
  handleReplyScroll,
  messageIndexId,
  messages,
  forwardData,
  handleCheckBox,
  handleClose
}: MessageProps) => {
  if(messageIndexId == indexKey){
    const messageElement = document.getElementById("message_" + indexKey);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth',
      block: 'end',
      inline: 'nearest' });
    }
  }
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const hasImages = message.image && message.image.length;
  const quotedMsg = message?.quotedMsg?.caption
  const quotedStanzaID = message?.quotedStanzaID
  const hasAttachments = message.attachments && message.attachments.length;
  const hasText = message?.caption ? message?.caption : message?.text !== "" && message?.text
  const hasCipherText = message?.type === "ciphertext"
  const isTyping = false;
  const chatUserFullName = chatUserDetails && chatUserDetails.pushname
    ? `${chatUserDetails.pushname}`
    : "-";
  const myProfile = userProfile && userProfile?.profileImage
    ? userProfile?.profileImage
    : imagePlaceholder;
  const channeluserProfile =
    message?.meta?.userData && message?.meta?.userData?.profileImage
      ? message?.meta?.userData?.profileImage
      : imagePlaceholder;
  const chatUserprofile = chatUserDetails && chatUserDetails.profilePic
    ? chatUserDetails.profilePic
    : imagePlaceholder;
  const profile = isChannel ? channeluserProfile : chatUserprofile;
  const date =message?.timestamp ? formateDate(new Date(moment.unix(parseInt(message?.timestamp)/1000).toString()), "hh:mmaaa") : formateDate(message?.createdAt, "hh:mmaaa");
  const fullDate = message?.timestamp ? formateDate(new Date(moment.unix(parseInt(message?.timestamp)/1000).toString()), "d MMM, yyyy"): formateDate(message?.createdAt, "d MMM, yyyy");
  // const fullDate1 = formateDate(message?.createdAt, "d MMM");
  const isSent = (message.ack == 1 || message.ack == 0) ? true : false;
  const isReceived = message.ack == 2 ? true : false;
  const isRead = message.ack == 3 ? true : false;
  const isGrouped = chatUserDetails && chatUserDetails.isGroup ? true : false;
  const isForwarded = message.isForwarded;
  const channdelSenderFullname = message?.meta?.userData
    ? `${message?.meta?.userData?.firstName} ${message?.meta?.userData?.lastName}`
    : "-";
  const fullName = isChannel ? channdelSenderFullname : chatUserFullName;

  const [checkedItems, setCheckedItems] = useState<Option["conversationId"][]>([]);
  const [userCreated, setUserCreated] = useState(false);
  const reciverNumber = message?.author?.split(/[^0-9]/)[0];

  useEffect(() => {
    if (isGrouped) {
      // Text replace with participate name
      prepareGroupText(message?.text, message?.mentionedIds, chatUserDetails?.participants).then((result)=>{
        message.text = result
      })
    }
  }, [message])
    
  useEffect(() => {
   if(handleClose == false){
    setCheckedItems([])
    if(forwardData)
    forwardData.isForward = false
  }
}, [handleClose])

  const {
    clientId,
    loading,
    clientList
  } = useAppSelector((state) => ({
    clientId: state.Chats.clientId,
    clientList: state.Chats.clientList,
    loading: state.Chats.loading,
  }))

  var isReplyDisplay
  var sessionSavedOnDate: any
  var clientMatch = clientList.filter((data: any) => data.clientId == message?.clientId)
  if (clientMatch) {
    clientMatch.map((data: any) => {
      sessionSavedOnDate = data?.sessionSavedOn
    })
    const unixDate =  moment(moment.unix(parseInt(message.timestamp)/1000), "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").toISOString();
    if (sessionSavedOnDate < unixDate) {
      isReplyDisplay = true
    } else {
      isReplyDisplay = false
    }
  }

  const onDeleteMessage = ({ option, message }: any) => {
    onDelete(message.conversationId, option,message.clientId);
  };

  const onClickReply = () => {
    onSetReplyData(message);
  };
  const isRepliedMessage = message.replyOf && message;

  const onForwardMessage = () => {
    onOpenForward(message);
  };

  const onDeleteImg = (imageId: number | string) => {
    onDeleteImage(message.mId, imageId);
  };

  const detectUrl = (content: any) => {
    return (
      <Linkify
        componentDecorator={(decoratedHref: any, decoratedText: any, key: any) => (
          <a target="_blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >{content}</Linkify>
    )
  }

  const onDownload = (value1: any, value2: any, reponseType: any) => {
    const conversationId: string = value1
    const chatId: string = value2
    const fileName = message?.filename ? message.filename : message.fileName
    const isAudioPlay = fileName?.includes('ogg');
    if (isAudioPlay) {
      if (!loading) dispatch(downloadFile(conversationId, chatId, clientId, reponseType))
    } else {
      if (!loading) dispatch(downloadFile(conversationId, chatId, clientId, { isLocalDownload: true }))
    }
  }

  const handleScroll = (quotedStanzaID: any) => {
    if(quotedStanzaID){
      handleReplyScroll(quotedStanzaID)
    }
  }

  const formatDateIfNeeded = (createdAt: string) => {
    const messageDate = new Date(createdAt);
    const formattedDate = formateDate(messageDate, 'd MMM, yyyy');
    const todayDate = formateDate(new Date(), 'd MMM, yyyy');
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayDate = formateDate(yesterday, 'd MMM, yyyy');

    switch (formattedDate) {
      case todayDate:
        if (formattedDate !== lastDisplayedDate) {
          lastDisplayedDate = formattedDate;
          return "Today";
        } else {
          return "";
        }
      case yesterdayDate:
        if (formattedDate !== lastDisplayedDate) {
          lastDisplayedDate = yesterdayDate;
          return "Yesterday";
        } else {
          return "";
        }
      default:
        if (formattedDate !== lastDisplayedDate) {
          lastDisplayedDate = formattedDate;
          return formattedDate;
        } else {
          return "";
        }
    }
  };

  var labelDate = message?.timestampForDate ? formatDateIfNeeded(message.timestampForDate) : formatDateIfNeeded(message?.createdAt);
 
let tempMessage : any = message

  const handleCheckboxChange = (item: Option) => {
    const updatedCheckedItems: Option["conversationId"][] = checkedItems.includes(item.conversationId)
      ? checkedItems.filter((checkedItem) => checkedItem !== item.conversationId)
      : [...checkedItems, item.conversationId];

    handleCheckBox(item)
    setCheckedItems(updatedCheckedItems);
  };

  const getName = (clientList: any, selectedClientValue: string , isFromMe: boolean) => {
    let listName
    if (clientList.length <= 1) {
      listName = 0
    } else {
      listName = clientList?.findIndex((item: any) => (item.clientId == selectedClientValue))
    }
    if (listName !== -1 && clientList.length > 0) {
      return (
        <>
          <div className="text-end msgSenderDtlsMeChat d-flex align-items-center gap-2">
            {isFromMe  && <div className="msgSenderIcon">
              {clientList[listName].type == "whatsapp" ?
                <img src={WhastappIconMsg} className="rounded" height={12} width={12} />
                : clientList[listName].type == "business_whatsapp" ? <img src={WhastappBusinessIconMsg} className="rounded" height={12} width={12} /> : clientList[listName].type == "telegram" && <img src={TelegramIconMsg}  className="rounded" height={17} width={17}/>}
            </div>}
            <div className="d-flex align-items-center gap-1">
              {!isFromMe && <div className="font-size-10 fw-bold">Rec'd</div>}
              {/* {!message?.viaSystem && isFromMe && <div className="font-size-10 fw-bold">Via</div>} */}
              {isFromMe && <div className="font-size-10 fw-bold">Via</div>}
              <div className="msgSenderNumber font-size-10">{clientList[listName]?.pushname} (+{clientList[listName]?.me?.user})</div>
              {/* <div className="font-size-12 fw-bold">By</div>
              <div className="msgSenderName font-size-12">{message?.senderName}</div> */}
            </div>
            {!isFromMe  && <div className="msgSenderIcon">
              {clientList[listName].type == "whatsapp" ?
                <img src={WhastappIconMsg} className="rounded" height={12} width={12} />
                : clientList[listName].type == "business_whatsapp" ? <img src={WhastappBusinessIconMsg} className="rounded" height={12} width={12} /> : clientList[listName].type == "telegram" && <img src={TelegramIconMsg}  className="rounded" height={17} width={17}/>}
            </div>}
          </div>
        </>
      )
    }
  }
  const replaceImage = (error: any) => {
    error.target.src = "https://controlhippo.s3.ca-central-1.amazonaws.com/logo/image+(26).png"
  }

  const getCountryCode = (number: any, defaultRegion = 'US') => {
    return new Promise((resolve, reject) => {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const phoneNumber = phoneUtil.parseAndKeepRawInput(number, defaultRegion);
        let countryCode =  phoneNumber.getCountryCode();
        resolve(countryCode);
      } catch (error) {
        
        reject(error);
      }
    });
  };

  const handleUserCreate = async (phoneNumber: any, contactName: any, clientId1: any) => {
    if (userCreated) return;
    setUserCreated(true);
    let tempPhoneNumber = "+" + phoneNumber;
    const countryCode = await getCountryCode(tempPhoneNumber);

    const data = {
      phoneNumber: phoneNumber,
      name: contactName,
      message: null,
      countryCode: countryCode,
      isGroup: chatUserDetails.isGroup
    };
    dispatch(createUser({ chatId: phoneNumber + "@c.us", clientId: clientId !== "all_numbers" ? clientId : clientId1, data }, (response) => {
      if (!response?.success) {
        showErrorNotification(response.message);
      }
    }));
  };

  const syncCipherText = (event:any) =>{
    event.preventDefault()
    const clientId:string = message?.clientId
    const data = {
      chatId: chatUserDetails.chatId,
      clientId,
      conversationId:message.conversationId
    }
    dispatch(syncCipherTextMessage(data,clientId))
  }
  return (
    <>
      
      {
        <div className="dayListingBadge">
          {labelDate && (
            <span>
              <p className="mb-0 font-size-10">{labelDate}</p>
            </span>
          )}
        </div>
      }
      {message?.type == "banner" &&
        <div className="resolvedMsgBadge mt-2 text-center">
          <p className="mb-0 resolvedMsgBadgeTxt font-size-11 lh-sm m-auto">{message?.text}</p>
        </div>
      }
      {/* {message?.type == "banner" &&
        <div className="resolvedMsgBadge mt-2 text-center">
          <p className="mb-0 resolvedMsgBadgeTxt font-size-12 lh-sm m-auto">{message?.text}</p>
        </div>
      } */}
      <li
        className={classnames(
          "chat-list",
          `${message?.conversationId && forwardData?.isForward && checkedItems.includes(tempMessage?.conversationId) ? 'forwordMsgActive' : ''}`,
          
          { right: isFromMe },
          { reply: isRepliedMessage }
        )}
      >
      {message?.conversationId && forwardData?.isForward && !message?.isDelete_everyone && <AntdCheckbox className="forwardCheckBox" checked={checkedItems.includes(tempMessage?.conversationId)} onChange={() => { handleCheckboxChange(tempMessage) }} />}

        {message?.type !== "banner" && message?.type !== "internal-note" &&
          <div className={`conversation-list align-items-start ${!hasText ? 'captionWithOut' : ''} ${hasImages ? 'conversationListHasIMg' : ''} ${deleteForEveryOne ? 'conversationGredientHide' : ''}`}>
            {/* {!forwardData?.isForward &&
              <div className="chat-avatar me-2 sender_name" id={"sender_name" + indexKey}>
                <img
                  src={isFromMe ? myProfile : profile}
                  alt=""
                  id="avatar-tooltip"
                  onError={replaceImage}
                />
              </div>
            }
            {forwardData?.isForward && message.fromMe &&
              <div className="chat-avatar me-2 sender_name" id={"sender_name" + indexKey}>
                <img
                  src={isFromMe ? myProfile : profile}
                  alt=""
                  id="avatar-tooltip"
                  onError={replaceImage}
                />
              </div>
            } */}

            <div className="user-chat-content w-100">
              {hasImages && message && message.text ? (
                <div className="ctext-wrap">
                  <div className="ctext-wrap-content p-2 w-auto">
                    <pre className="mb-0 ctext-content">{deleteForEveryOne ? <div className="d-flex align-items-center gap-1 text-muted mb-1"><i className='bx bx-block'></i> <p className="mb-0 lh-sm">You deleted this message</p></div> : detectUrl(message.text)}</pre>
                  </div>
                </div>
              ) : ""}
              {isForwarded && (
                <span
                  className={classnames(
                    "me-1",
                    "text-muted",
                    "font-size-13",
                    "mb-1",
                    "d-block"
                  )}
                >
                  <i
                    className={classnames(
                      "ri",
                      "ri-share-forward-line",
                      "align-middle",
                      "me-1"
                    )}
                  ></i>
                  Forwarded
                </span>
              )}

              <div className={`ctext-wrap ${hasImages ? "w-100" : ""}`}>
                {/* text message end */}

                {/* image message start */}
                  <div className={`d-flex flex-column ${isFromMe && "align-items-end"} ${hasImages ? "w-100" : ""}`}>
                    {clientId == "all_numbers" && getName(clientList,message?.clientId,isFromMe) }
                    <div className={`d-flex ${(hasImages && !deleteForEveryOne) ? "w-100" : ""}`}>
                      <div className={`ctext-wrap-content ctextWrapContent ${hasImages ? "w-100" : ""}`}>
                        {isRepliedMessage && (
                          <RepliedMessage
                            fullName={fullName}
                            message={message}
                            isFromMe={message?.fromMe}
                            onClick={handleScroll}
                          />
                        )}
                      {!isFromMe && isGrouped && (
                        <div className="font-size-12 bold me-2" style={{ cursor: 'pointer' }}>
                          <div className="d-flex align-items-center">
                            <p className={`font-size-10 bold me-2 mb-1 SndrRcvrName ${hasImages ? 'SndrRcvrNameWnHasImg':'' }`} onClick={() => handleUserCreate(reciverNumber, message?.groupParticipantName, message?.clientId)}>
                              ~ {message?.groupParticipantName ? message?.groupParticipantName : message?.authorName}
                            </p>
                          </div>
                        </div>
                      )}
                      {message?.fromMe && message.viaSystem &&
                              <div className={`font-size-10 SndrRcvrName me-2 text-start ${hasImages ? 'SndrRcvrNameWnHasImg mb-1':'' }`}>
                                ~ {message?.senderName}
                              </div>
                            }
                             {message?.fromMe && !message.viaSystem &&
                              <div className={`font-size-10 SndrRcvrName me-2 text-start ${hasImages ? 'SndrRcvrNameWnHasImg mb-1' : ''}`}>
                                ~ From Mobile
                              </div>
                            }
                        {/* files message start */}
                        {hasAttachments ? (
                          <>
                          
                          <Attachments chatUserDetails={chatUserDetails} attachments={message.attachments} onDownload={onDownload} message={message} indexKey={indexKey} deleteForEveryOne={deleteForEveryOne} /></>
                        ) : ""}
                        {/* files message end */}
                      {hasImages ?
                        <>
                          
                          <Images chatUserDetails={chatUserDetails} images={message.image!} message={message} onSetReplyData={onSetReplyData} onDeleteImg={onDeleteImg} indexKey={indexKey} deleteForEveryOne={deleteForEveryOne} /></>
                        : ""}
                        
                          {/* {quotedMsg && 
                        <p className="mb-0 ctext-content text-start text-break wpContentMsg">{deleteForEveryOne ? <div className="d-flex align-items-center gap-1 text-muted mb-1"><i className='bx bx-block'></i> <p className="mb-0 lh-sm">You deleted this message</p></div> : detectUrl(quotedMsg)}</p>
                        } */}

                        {hasText && !hasCipherText&& message.text &&(
                          <>
                            <div className="chatTimeMain">
                            {/* <div>{message.senderName}</div> */}
                            <p className="mb-0 ctext-content text-start text-break wpContentMsg" id={"message_" + indexKey}>{deleteForEveryOne ? <div className="d-flex align-items-center gap-1 text-muted mb-1 wpContentMsg"><i className='bx bx-block'></i> <p className="mb-0 lh-sm">You deleted this message</p></div> : detectUrl(message.text)}</p>
                            </div>
                          </>
                        )}
                        {hasText && hasCipherText && (
                          <>
                          <div className="chatTimeMain">
                            {/* <div>{message.senderName}</div> */}
                            <p className="mb-0 ctext-content text-start text-break wpContentMsg" id={"message_" + indexKey}><div className="d-flex align-items-center gap-1 text-muted mb-1 wpContentMsg"> <p className="mb-0 lh-sm"><i className='bx bx-block'></i> {detectUrl(message.text)}</p></div></p>
                            </div>
                          </>
                        )}

                        <div className="chatMenuMain">
                          {!message.isDelete_everyone && !hasCipherText && <Menu
                            message={message}
                            onForward={onForwardMessage}
                            onDelete={onDeleteMessage}
                            onReply={onClickReply}
                            onDownload={onDownload}
                            isFromMe={isFromMe}
                            isReplyDisplay={isReplyDisplay}
                            clientId={clientId}
                          />}
                        </div>
   
                        {/* typing start */}
                        {isTyping ? <Typing /> : ""}

                        {/* typing end */}
                        {/* files message start */}
                        {/* {hasAttachments ? (
                          <Attachments attachments={message.attachments} onDownload={onDownload} message={message} />
                        ):""} */}
                        {/* files message end */}

                      </div>
                      {hasCipherText && <>
                        <div className="d-flex align-items-center gap-2 syncMessageButton">
                          <Button className="circle_status_icon d-flex align-items-center justify-content-center syncMessageButton btn btn-light" onClick={syncCipherText}><i className='bx bx-refresh align-middle'></i></Button>
                        </div>
                        </>}
                    </div>

                    {isFromMe ? (
                            <div className="chatTime-right" id={"chatTime-right" + indexKey}>
                              <small className={classnames("text-muted", "mb-0", "me-1", "chatTimeTxt", "ms-2")}>
                                {date}
                              </small>
                              <UncontrolledTooltip placement="bottom" target={"chatTime-right" + indexKey}
                                autohide={true}
                                flip={true}
                              >
                                <small className={classnames("text-muted", "mb-0", "ms-2")}>{fullDate}</small>
                              </UncontrolledTooltip>
                              <span
                                className={classnames(
                                  "m-0 mt-1 align-middle",
                                  { "text-info": isRead },
                                  { "text-muted": (isSent || isReceived) && !isRead }
                                )}
                              >
                                <i
                                  className={classnames(
                                    "bx", "doubleCheckedIcon",{"doubleCheckedIconBlue":isRead},
                                    { "bx-check-double": isRead || isReceived },
                                    { "bx-check": isSent }
                                  )}
                                ></i>
                              </span>
                              {/* You */}
                            </div>
                          ) : (
                            <div className="chatTime-right" id={"chatTime-right" + indexKey}>
                              {/* {fullName} */}
                              <small className={classnames("text-muted", "mb-0", "ms-2", "chatTimeTxt")}>
                                {date}
                              </small>
                              <UncontrolledTooltip placement="bottom" target={"chatTime-right" + indexKey}
                                autohide={true}
                                flip={true}
                              >
                                <small className={classnames("text-muted", "mb-0", "ms-2")}>{fullDate}</small>
                              </UncontrolledTooltip>
                            </div>
                          )}

                  </div>

                {/* image message end */}
              </div>

              {/* <div className="conversation-name">
            {isFromMe ? (
              <>
                <span
                  className={classnames(
                    "me-1",
                    { "text-success": isRead },
                    { "text-muted": (isSent || isReceived) && !isRead }
                  )}
                >
                  <i
                    className={classnames(
                      "bx",
                      { "bx-check-double": isRead || isReceived },
                      { "bx-check": isSent }
                    )}
                  ></i>
                </span>
                <small className={classnames("text-muted", "mb-0", "me-2")}>
                  {date}
                </small>
              </>
            ) : (
              <>
                <small className={classnames("text-muted", "mb-0", "ms-2")}>
                  {date}
                </small>
              </>
            )}
          </div> */}
            </div>
          </div>
        }

        {message?.type == "internal-note" && <>
          <div className="conversation-list align-items-start captionWithOut notMsgMain">
            <div className="user-chat-content w-100">
              <div className="ctext-wrap">
                <div className="ctext-wrap-content">
                  <div className="notMsgNameMain p-1 px-2 text-start">
                    <small className="mb-0 notMsgName font-size-12 text-dark">{message?.senderName} left this private note</small>
                  </div>
                  <div className="otherBodyTextNote p-2 pb-3 text-dark mb-0">
                    <p className="mb-0 ctext-content text-start text-break wpContentMsg">{detectUrl(message.text)}</p>
                    <div className="chatTime-right" id={"chatTime-right" + indexKey}>
                      {/* {fullName} */}
                      <small className="text-muted mb-0 ms-2 chatTimeTxt notMsgDate">
                        {date}
                      </small>
                      <UncontrolledTooltip placement="bottom" target={"chatTime-right" + indexKey}
                        autohide={true}
                        flip={true}
                      >
                        <small className="text-muted mb-0 ms-2">{fullDate}</small>
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
                {!message.isDelete_everyone && <Menu
                  message={message}
                  onForward={onForwardMessage}
                  onDelete={onDeleteMessage}
                  onReply={onClickReply}
                  onDownload={onDownload}
                  isFromMe={isFromMe}
                  isReplyDisplay={isReplyDisplay}
                  clientId={clientId}
                />}
              </div>
            </div>
          </div>
        </>}
      </li>
    </>
  );
};

export default Message;
