import React, { useState, useEffect,useRef } from 'react'
import moment from "moment";
import Select from 'react-select';
import Leftbar from '../Dashboard/Leftbar'
import Welcome from '../Dashboard/ConversationUser/Welcome'
import { Alert, Card, Col, Container, Form, ListGroup, Row, Tooltip, OverlayTrigger, Tabs, Tab, Table ,Pagination} from 'react-bootstrap'
import { Anchor, Modal} from 'antd';
import { updateAuthData, useProfile, useRedux } from '../../hooks';
import { getUserDetails, getCustomerPortal,getInvoice,invoiceDownload, clearCustomerPortal, getProfileDetails, addCredit, systemLogoutUser, cancelAccount, getInvoicePaymentUrl, connectSocket, getClientsList, changeTab } from '../../redux/actions';
import config from '../../config';
import { Spinner, Button } from 'reactstrap';
import { PlanBillingComponent } from "./PlanBillingComponent.style";
import creditCardIcon from "../../assets/images/icons/visa.svg";
import InvoiceTable from './invoiceTable';
import ChargebeePopup from './chargebeePopup';
import { showErrorNotification, showSuccessNotification } from '../../helpers/notifications';
import UpGradePlanPopup from './UpGradePlanPopup';
import {useNavigate} from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TABS } from '../../constants';

interface ChargebeeInit {
    userId: string;
    section: string;
}
interface Option {
    name: string;
    credit: number;
}
const PlanBilling = () => {
    const { userProfile } = useProfile()
    const { dispatch, useAppSelector } = useRedux()
    const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
    const [profileDetails, setProfileDetails] = useState<any>({})
    const [billingDetails, setBillingDetails] = useState<any>({})
    const [statusClass, setStatusClass] = useState<string>("bg-success");
    const [activeTab, setActiveTab] = useState<string>('unpaid-invoice');
    const [data, setData] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [chargeableNumber, setChargeableNumber] = useState<{quantity : number, unitPrice : number}>({quantity : 0, unitPrice : 0})
    const [freeNumber, setFreeNumber] = useState<{quantity : number, unitPrice : number}>({quantity : 0, unitPrice : 0})
    const [wabaNumber, setWabaNumber] = useState<{quantity : number, unitPrice : number}>({quantity : 0, unitPrice : 0})
    const [clientDetails, setClientDetails] = useState<any>({})
    const topRef = React.useRef<HTMLDivElement>(null);

    const parentId = userProfile?.parentId ? userProfile?.parentId : userProfile?.userId;
    const {
        profileData,
        chargebeeData,
        invoiceList,
        downloadInvoiceData,
        userDetails,
        clientId,
        clientList
    } = useAppSelector(state => ({
        invoiceList: state.PlanBilling.invoices,
        downloadInvoiceData: state.PlanBilling.downloadInvoice,
        chargebeeData: state.Profile.chargebeeDetails,
        profileData: state.Login.user,
        userDetails: state.Profile.profileDetails,
        clientId: state.Chats.clientId,
        clientList: state.Chats.clientList,
    }))
    useEffect(() => {
        const clientDetails = (clientList || []).find((item: any) => {
            return item.type == "business_whatsapp"
        })
        if(clientDetails)setClientDetails(clientDetails)
        dispatch(getClientsList("dropdown"))
      }, [])
      useEffect(() => {
        const clientDetails = (clientList || []).find((item: any) => {
            return item.type == "business_whatsapp"
        })
        if(clientDetails)setClientDetails(clientDetails)
      }, [clientList])
    useEffect(() => {
        if(userProfile?.userId){
            dispatch(getProfileDetails(userProfile?.userId))
        }
        setChargebeeDetails(chargebeeData)
        const numberDetails =userDetails.numberDetails ?userDetails.numberDetails : profileData.numberDetails? profileData.numberDetails  : userProfile?.numberDetails
        updateNumberDetails(numberDetails,userProfile?.chargebeeDetails)
        dispatch(getInvoice('unpaid-invoice'))
    }, [])

    useEffect(() => {
        setData(invoiceList)
    }, [invoiceList])
    
    useEffect(() => {
        if (downloadInvoiceData?.download_url) {
            const para = document.createElement("a");
            para.href = downloadInvoiceData?.download_url;
            para.click()
        }
    }, [downloadInvoiceData])
    
    useEffect(() => {
        setChargebeeDetails(chargebeeData);
        setProfileDetails(profileData);
        if(profileData?.billingDetails)setBillingDetails(profileData.billingDetails);
        updateAuthData(profileData);
        if (profileData?.chargebeeDetails?.status) {
            let backgroundClassName = "bg-success"
            if (profileData?.chargebeeDetails?.status == "in_trial") backgroundClassName = "bg-warning"
            else if (profileData?.chargebeeDetails?.status == "active") backgroundClassName = "bg-success"
            else if (profileData?.chargebeeDetails?.status == "paused") backgroundClassName = "bg-warning"
            else if (profileData?.chargebeeDetails?.status == "cancelled") backgroundClassName = "bg-danger"
            setStatusClass(backgroundClassName)
        }
        const numberDetails = userDetails.numberDetails ? userDetails.numberDetails:profileData?.numberDetails
        updateNumberDetails(numberDetails,userProfile?.chargebeeDetails)
    }, [profileData, chargebeeData])

    useEffect(() => {
        if(userDetails?.billingDetails)setBillingDetails(userDetails?.billingDetails);
    }, [userDetails])
    
    {/* <Leftbar /> */ }
    const statusCheck = (status: string) => {
        if (status == "in_trial") return "In Trial";
        else if (status == "active") return "Active";
        else if (status == "cancelled") return "Cancelled";
        else if (status == "paused") return "Paused";
    }

    const handleTabChange = (tabKey: string) => {
        setActiveTab(tabKey);
        dispatch(getInvoice(tabKey))
    };
    const downloadInvoice =(data:any) => {
        dispatch(invoiceDownload(data.invoiceId))
    }

    let userPlanCurruncyDetails =  profileDetails?.planDetails?.price_currency.findIndex((item:any) => item.currency.toLowerCase() == profileDetails?.chargebeeDetails?.currency_code?.toLowerCase())
    userPlanCurruncyDetails = profileDetails?.planDetails?.price_currency[userPlanCurruncyDetails]

    const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;

    const priceList: Option[] = [
        { name: '10', credit: 10 },
        { name: '30', credit: 30 },
        { name: '50', credit: 50 },
        { name: '100', credit: 100 },
    ];
    const [selectedCredit, setSelectedCredit] = useState<Option>(priceList[0]);
    const [isLoading, setIsLoading] = useState(false)

    const handleSelectChange = (selectedOption: any) => {
        const indexNo = priceList.findIndex((item: Option) => selectedOption?.value == item.credit)
        if (indexNo !== -1) {
            setSelectedCredit(priceList[indexNo]);
        }
    };

    const addCredits = () => {
        if(!isLoading){
            setIsLoading(true)
            dispatch(addCredit(selectedCredit,(result:any)=>{
                if(result.success){
                    setIsLoading(false)
                    showSuccessNotification(result.message)
                    dispatch(getProfileDetails(userProfile?.userId))
                }else {
                    setIsLoading(false)
                    showErrorNotification(result.error)
                }                                
            }))
        }
    }

    const handleOk = () => {
        // dispatch(cancelAccount())
        // dispatch(systemLogoutUser())
        navigate('/cancle-account')
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleCancelAccount = () => {
        navigate('/cancle-account')
    }

    const getCredits = (a:any,b:any) => {
        if((a != undefined ||  a != null) && (b != undefined || b != null)) return (a - b).toFixed(2)
        else return 0   
    }

    const getAddonInfo = (addons : [any], addonItemId:string) => {
        if(addons){
            for (const addon of addons) {
                if (addon.item_price_id.includes(addonItemId)) {
                    return {
                        quantity: addon.quantity,
                        name: addon.item_price_id,
                        unitPrice : addon.unit_price/100
                    };
                }
            }
        }
        return null;
    };
    const updateNumberDetails = async (numbers: [{ type: string }], chargebeeDetails
        : any) => {
        let totalNumber: number = numbers?.length

        const wabaNumber = (numbers || []).filter((number:any) => number.type === "business_whatsapp").length;
        const nonWabaNumber = numbers?.length - wabaNumber;

        // if (numbers?.length) {
            const wabaInfo: { quantity: number, name: string, unitPrice: number } | null = chargebeeDetails?.addons?.length && await getAddonInfo(chargebeeDetails?.addons, "waba");
            if (wabaInfo?.quantity) {
                setWabaNumber({ quantity: wabaInfo?.quantity, unitPrice: wabaInfo?.unitPrice })
                totalNumber = totalNumber - wabaInfo.quantity
            }
            const addonInfo: { quantity: number, name: string, unitPrice: number } | null = chargebeeDetails?.addons?.length && await getAddonInfo(chargebeeDetails?.addons, "scan_whatsapp_number");
            if (addonInfo?.quantity) {
                setChargeableNumber({ quantity: addonInfo?.quantity, unitPrice: addonInfo?.unitPrice })
                // totalNumber = totalNumber - addonInfo.quantity
                totalNumber = nonWabaNumber - addonInfo.quantity
            }
            setFreeNumber({ quantity: totalNumber, unitPrice: 0 })
        // }
    }

    const openInvoiceHostedPage = (item : {invoiceId : string}) => {
        dispatch(getInvoicePaymentUrl(item.invoiceId, (response: any) => {
            if(response?.success){
                const data : any = response.data;
                if (data?.url) {
                    const url :string = data?.url
                    const para = document.createElement("a");
                    para.href = url;
                    para.click()
                    return true
                }
            } 
            return showErrorNotification({message :"Something went wrong."})
        }))
    }
    const sidebarItems = [
        {
            key: 'yourPlan',
            href: '#yourPlan',
            title: (<span className='moduleSidebarSpan'><i className='bx bx-briefcase'></i> Plan</span>),
        },
        {
            key: 'billingAndCard',
            href: '#billingAndCard',
            title: (<span className='moduleSidebarSpan'><i className='bx bx-credit-card' ></i> Billing & Cards</span>),
        },
        {
            key: 'billingContacts',
            href: '#billingContacts',
            title: (<span className='moduleSidebarSpan'><i className='bx bxs-envelope' ></i> Invoices</span>),
        },
        {
            key: 'billingAddress',
            href: '#billingAddress',
            title: (<span className='moduleSidebarSpan'><i className='bx bxs-file' ></i> Billing Address</span>),
        }
    ]
    
    if (clientDetails?.type == "business_whatsapp") {
        const data = {
            key: 'credit',
            href: '#credit',
            title: (<span className='moduleSidebarSpan'><i className='bx bx-dollar'></i> Messaging Credits</span>),
        }
        sidebarItems.unshift(data)
    }
    if (!userProfile?.parentId && (userProfile?.chargebeeDetails?.status === "active" || userProfile?.chargebeeDetails?.status === "in_trial") && !userProfile?.chargebeeDetails?.cancel_title) {
        const data: any = {
            key: 'cancelAccount',
            href: '#cancelAccount',
            title: (
                <span className='moduleSidebarSpan'>
                    <i className="ri-user-unfollow-fill"></i> Cancel Account
                </span>)
        }
        sidebarItems.push(data)
    }


    const handleSubmitButton = (event: any) => {
        const payLoadData = {
          event: event,
        }
        dispatch(cancelAccount(payLoadData, async (response: any) => {
          if (response?.success) {
            navigate("/dashboard")
            dispatch(changeTab(TABS.CHAT))
        }
        }));
      }
    const isInTrialWithNoCard = profileDetails?.chargebeeDetails?.status === "in_trial" && profileDetails?.chargebeeDetails?.card_status === "no_card";
    const isWithoutCustomerAndSubscription = !profileDetails?.chargebeeDetails?.customer_id && !profileDetails?.chargebeeDetails?.subscription_id;
    const isCancelledOrSubscribed = (profileDetails?.chargebeeDetails?.customer_id && profileDetails.chargebeeDetails?.subscription_id) || profileDetails?.chargebeeDetails?.status === "cancelled";

    const label = chargebeeDetails?.card?.last4 ? "Update Card" : "Add Card";
    return (
        <>
            <PlanBillingComponent>
                <div className='planBilling'>
                    {/* <div>
                    <Anchor
                    className="sidebarMenuAnchor"
                    targetOffset={20}
                    getContainer={getContainer}
                    items={sidebarItems}
                    />
                    </div> */}
                    <div id="sectionWrapper">
                        <div className="d-none align-items-center gap-2 mt-4 planBillingBackMain">
                            <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={()=>{navigate(-1)}}>
                                <i className='bx bx-chevron-left font-size-24'></i>
                            </Button>
                            <h2 className="font-size-20 text-dark text-center mb-0">Plan & Billing </h2>
                        </div>
                        { clientDetails?.type == "business_whatsapp" &&  <section id='credit' ref={topRef} className="mx-1 mx-lg-4 mt-lg-4 mt-3">
                            <Card className='mb-0'>
                                <Card.Header className='topSecHead'>
                                    <h4 className="mb-0 font-size-20 messageCreditTitle">Messaging Credits <OverlayTrigger overlay={<Tooltip>Messaging via WhatsApp Business API requires credits.</Tooltip>}>
                                        <i className='bx bxs-info-circle fs-4' ></i>
                                    </OverlayTrigger></h4>
                                    
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Card className='mb-0'>
                                        <Card.Header className='topSecHead'>
                                            <div className="d-flex align-items-center justify-content-between py-1 planDetailsHead">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className='icon_cir'>
                                                        <div className="panelnumbername_switch">
                                                            <i className='bx bx-dollar' ></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Card.Text className="d-flex align-items-center mb-0 plan_current_div gap-1">
                                                            <h5 className='mb-0 font-size-18'>{`$ ${getCredits(billingDetails?.available_credit,billingDetails?.used_credit)}`}</h5>
                                                            {/* <OverlayTrigger overlay={<Tooltip>This plan is tailor-made for businesses aiming to excel in today's competitive landscape.</Tooltip>}>
                                                                <i className='bx bxs-info-circle fs-4' ></i>
                                                            </OverlayTrigger> */}
                                                        </Card.Text>
                                                        <Card.Text className='mb-0'>
                                                            Available Credits
                                                        </Card.Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className='creaditsCardBody'>
                                            <div className="row text-center">
                                                <div className='col-12 col-md-6 d-flex '>
                                                    <label htmlFor="creditDropdown" className='mt-1 mr-5 font-size-18'>Credits : &nbsp;&nbsp;</label>
                                                    <div className='mx-2'>
                                                        <Select
                                                            onChange={handleSelectChange}
                                                            options={priceList.map((option) => ({
                                                                value: option.credit,
                                                                label: (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: '4px' }}>$</span>
                                                                        {option.name}
                                                                    </div>
                                                                ),
                                                            }))}
                                                            value={{
                                                                value: selectedCredit.credit,
                                                                label: (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: '4px' }}>$</span>
                                                                        {selectedCredit.name}
                                                                    </div>
                                                                ),
                                                            }}
                                                            defaultValue={{
                                                                value: selectedCredit.credit,
                                                                label: (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: '4px' }}>$</span>
                                                                        {selectedCredit.name}
                                                                    </div>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mx-2 mt-1'>
                                                        <Button size={"sm"} disabled={isLoading} onClick={()=>addCredits()}> Add </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </section>}
                        <section id='yourPlan' ref={topRef} className="">
                            <Card className='mb-0'>
                                <Card.Header className='topSecHead'>
                                    <h4 className="mb-0 font-size-20">Plan Details</h4>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Card className='mb-0'>
                                        <Card.Header className='topSecHead'>
                                            <div className="d-flex align-items-center justify-content-between py-1 planDetailsHead">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className='icon_cir'>
                                                        <div className="panelnumbername_switch">
                                                            <i className='bx bxs-briefcase-alt-2'></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Card.Text className="d-flex align-items-center mb-0 plan_current_div gap-1">
                                                            <h5 className='mb-0 font-size-18'>{profileDetails?.planDetails?.displayName}</h5>
                                                            {profileDetails?.chargebeeDetails?.status ? 
                                                            <span className={`active_span ${statusClass}`}>{statusCheck(profileDetails?.chargebeeDetails?.status)}</span> : ""}
                                                            <OverlayTrigger overlay={<Tooltip>This plan is tailor-made for businesses aiming to excel in today's competitive landscape.</Tooltip>}>
                                                                <i className='bx bxs-info-circle fs-4' ></i>
                                                            </OverlayTrigger>
                                                        </Card.Text>
                                                        <Card.Text className='mb-0'>
                                                            Current plan
                                                        </Card.Text>
                                                    </div>
                                                </div>
                                                <div>
                                                    {profileDetails?.chargebeeDetails?.next_billing_at ? 
                                                    <div className='font-size-14 text-dark'>
                                                        Next Billing Date : <span>{moment(profileDetails?.chargebeeDetails?.next_billing_at).format('Do MMMM,gggg')}</span>
                                                    </div> : ""}
                                                    {profileDetails?.chargebeeDetails?.next_billing_amount ? <div className='font-size-14 text-dark mt-1'>
                                                        Next Billing Amount is <span className='text-dark fw-bold ms-1'>{userPlanCurruncyDetails?.currency_sign} {" "} {profileDetails?.chargebeeDetails?.next_billing_amount}</span>
                                                    </div> :""}
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className='p-3 pt-5'>
                                            <div className="row align-items-center">
                                                <div className="col-md-12 d-flex mobflexcolumn">
                                                    <div className='col-12 col-md-6 col-lg-4 pe-md-4 userInvoiceCount d-flex flexx-wrap'>
                                                        <Card className='d-flex w-100'>
                                                            <div className='planFstCardsTitles text-center'>USERS</div>
                                                            <Card.Body className='d-flex align-items-center justify-content-center'>
                                                                <Card.Text className="text-center usersPlanText fw-light">
                                                                    {profileDetails?.allUsers?.length}
                                                                </Card.Text>
                                                            </Card.Body>
                                                            {profileDetails?.chargebeeDetails?.billing_period_unit ? 
                                                            <Card.Footer style={{ borderTop: "1px solid #e8e8e8" }} className="text-center fw-bold">{userPlanCurruncyDetails?.currency_sign} {profileDetails?.chargebeeDetails?.billing_period_unit == "month"?userPlanCurruncyDetails?.monthly :userPlanCurruncyDetails?.yearly} PER USER/{profileDetails?.chargebeeDetails?.billing_period_unit?.toUpperCase()}</Card.Footer> : ""}
                                                        </Card>
                                                    </div>
                                                    <div className='col-12 col-md-6 col-lg-8 pe-md-4'>
                                                        <Card>
                                                            <div className='planFstCardsTitles text-center'>CHANNELS</div>
                                                            <Card.Body className='pt-4'>
                                                                    {/* {profileDetails?.numberDetails?.length} */}
                                                                    <div className="row">
                                                                        <div className='col-12 col-md-6 col-lg-4'>
                                                                            <Card className='mb-lg-0'>
                                                                                <div className='planFstCardsTitles text-center'>FREE</div>
                                                                                <Card.Body>
                                                                                    <Card.Text className="text-center fs-1 fw-light">
                                                                                        {freeNumber?.quantity  > 0? freeNumber?.quantity : 0 }
                                                                                    </Card.Text>
                                                                                </Card.Body>
                                                                                <Card.Footer style={{ borderTop: "1px solid #e8e8e8" }} className="text-center fw-bold font-size-14">{userPlanCurruncyDetails?.currency_sign} {0} PER CHANNEL/MONTH</Card.Footer>
                                                                            </Card>
                                                                        </div>
                                                                        <div className='col-12 col-md-6 col-lg-4'>
                                                                            <Card className='mb-lg-0'>
                                                                                <div className='planFstCardsTitles text-center'>CHARGABLE</div>
                                                                                <Card.Body>
                                                                                    <Card.Text className="text-center fs-1 fw-light">
                                                                                        {chargeableNumber?.quantity}
                                                                                    </Card.Text>
                                                                                </Card.Body>
                                                                                <Card.Footer style={{ borderTop: "1px solid #e8e8e8" }} className="text-center fw-bold font-size-14">{userPlanCurruncyDetails?.currency_sign} {chargeableNumber?.unitPrice} PER CHANNEL/MONTH</Card.Footer>
                                                                            </Card>
                                                                        </div>
                                                                        <div className='col-12 col-md-6 col-lg-4'>
                                                                            <Card className='mb-lg-0'>
                                                                                <div className='planFstCardsTitles text-center'>WABA</div>
                                                                                <Card.Body>
                                                                                    <Card.Text className="text-center fs-1 fw-light">
                                                                                        {wabaNumber?.quantity}
                                                                                    </Card.Text>
                                                                                </Card.Body>
                                                                                <Card.Footer style={{ borderTop: "1px solid #e8e8e8" }} className="text-center fw-bold font-size-14">{userPlanCurruncyDetails?.currency_sign} {wabaNumber?.unitPrice} PER CHANNEL/MONTH</Card.Footer>
                                                                            </Card>
                                                                        </div>
                                                                    </div>
                                                            </Card.Body>
                                                            {/* <Card.Footer style={{ borderTop: "1px solid #e8e8e8" }} className="text-center fw-bold">$50 PER USER/MONTH</Card.Footer> */}
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <Form>
                                                    <div className="d-flex align-items-center">
                                                        <Form.Label column sm="2">
                                                            Select Plan:
                                                        </Form.Label>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group1"
                                                                label="Basic"
                                                                id="inline-radio-1"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group1"
                                                                label="Bronze"
                                                                id="inline-radio-2"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group1"
                                                                label="Silver"
                                                                id="inline-radio-3"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group1"
                                                                label="Platinum"
                                                                id="inline-radio-4"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Form.Label column sm="2">
                                                            Select Plan Period:
                                                        </Form.Label>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group2"
                                                                label="Monthly"
                                                                id="inline-radio-gp2-1"
                                                            />
                                                            <Form.Check
                                                                inline
                                                                type='radio'
                                                                name="group2"
                                                                label="Annually"
                                                                id="inline-radio-gp2-2"
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div> */}
                                            <p className='mt-2 mb-0'>
                                                Note: Compare our plans and features on the <a target='_blank' href="https://controlhippo.com/pricing/">Pricing Page</a>.
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                                {/* <div className="row g-3 align-items-center mb-2">
                                    <div className="col-auto">
                                        <label className="col-form-label">Select Plan</label>
                                    </div>
                                    <div className="col-auto">
                                        {['radio'].map((type: String) => (
                                            <div key={`inline-${type}`}>
                                                <Form.Check
                                                    inline
                                                    label="Free"
                                                    name="group1"
                                                    type={'radio'}
                                                    id={`inline-${type}-1`}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Professinal"
                                                    name="group1"
                                                    type={'radio'}
                                                    id={`inline-${type}-2`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center mb-2">
                                    <div className="col-auto">
                                        <label className="col-form-label">Select Plan Period</label>
                                    </div>
                                    <div className="col-auto">
                                        {['radio'].map((type: String) => (
                                            <div key={`inline-${type}`}>
                                                <Form.Check
                                                    inline
                                                    label="Monthly"
                                                    name="group1"
                                                    type={'radio'}
                                                    id={`inline-${type}-1`}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Annually"
                                                    name="group1"
                                                    type={'radio'}
                                                    id={`inline-${type}-2`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                            </Card>
                        </section>
                        <section id='billingAndCard'     className="mt-3">
                            <Card className='mb-0'>
                                <Card.Header className='topSecHead'>
                                    <div className="d-flex align-items-center gap-2">
                                        <h4 className="mb-0 font-size-20 ">Billing & Cards</h4>
                                        <OverlayTrigger overlay={<Tooltip>Add your credit card to continue the use of Controlhippo services without interruption.</Tooltip>}>
                                            <i className='bx bxs-info-circle fs-4' ></i>
                                        </OverlayTrigger>
                                    </div>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Card className='p-2 creditCardSection'>
                                        <div className="row p-lg-4 align-items-center creditCardSectionRow">
                                            <div className="col-md-12 col-lg-6 col-xl-5 pe-lg-0 me-lg-2">
                                                {chargebeeDetails?.card ?
                                                    <div className="credit-card-box">
                                                        <div className="flip">
                                                            <div className="front">
                                                                <div className="chip"></div>
                                                                <div className="logo">
                                                                    <img src={creditCardIcon} />
                                                                </div>
                                                                <div className="number">XXXX XXXX XXXX {chargebeeDetails?.card?.last4}</div>
                                                                <div className="card-holder">
                                                                    <label>Card holder</label>
                                                                    <div>{`${chargebeeDetails?.card?.first_name ? chargebeeDetails?.card?.first_name : 'XXXXX'} ${chargebeeDetails?.card?.last_name ? chargebeeDetails?.card?.last_name : 'XXXXX'}`}</div>
                                                                </div>
                                                                <div className="card-expiration-date">
                                                                    <label>Expires</label>
                                                                    <div className='font-size-18'>{chargebeeDetails?.card?.expiry_month}/{chargebeeDetails?.card?.expiry_year % 100}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <Alert key={"info"} variant={"info"}>
                                                            Card details are unavailable.
                                                        </Alert>
                                                    </>
                                                }
                                            </div>
                                            <div className="col-md-12 col-lg-6 col-xl-6">
                                                <div className="p-3 font-size-18">
                                                    {chargebeeDetails?.card?.billing_addr1?
                                                        <>
                                                            <h4 className="mb-2 fw-semibold d-block fs-4 text-dark">Visa Card</h4>
                                                            <div className='d-flex gap-1'>
                                                                <label className="form-check-label text-nowrap">Owner :</label>
                                                                <span className="fs-14 d-block">{chargebeeDetails?.card?.first_name} {chargebeeDetails?.card?.last_name}</span>
                                                            </div>
                                                            <div className='d-flex gap-1'>
                                                                <label className="form-check-label text-nowrap">Address :</label>
                                                                <div className='d-flex gap-1 flex-wrap'>
                                                                    <span className="text-muted fw-normal text-wrap d-block">{chargebeeDetails?.card?.billing_addr1},</span>
                                                                    <span className="text-muted fw-normal text-wrap d-block">{chargebeeDetails?.card?.billing_city}, {chargebeeDetails?.card?.billing_state}</span>
                                                                    <span className="text-muted fw-normal text-wrap d-block">{chargebeeDetails?.card?.billing_country}, {chargebeeDetails?.card?.billing_zip}</span>
                                                                </div>
                                                            </div>
                                                        </> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='text-center mb-3'>
                                            {(!userProfile.parentId || (userProfile.parentId === userProfile.userId)) && !profileDetails?.planDetails?.isAvailableOnRequest &&  profileDetails?.chargebeeDetails?.status !== "in_trial" ? 
                                                <ChargebeePopup type={"button"} className={"bg-primary"} section={"updateCreditCard"} lable={chargebeeDetails?.card?.last4 ?"Update Card":"Add Card"} />
                                            :((!userProfile.parentId || (userProfile.parentId === userProfile.userId) && ((profileDetails?.chargebeeDetails?.status == "in_trial") || profileDetails?.planDetails?.isAvailableOnRequest) )) ?<UpGradePlanPopup lable="Add Card" className='btn btn-primary' />: <></>}
                                        </div> */}
                                        <div className='text-center mb-3'>
                                            {profileDetails?.chargebeeDetails?.status === "in_trial" && profileDetails?.chargebeeDetails?.card_status === "no_card" ? (
                                                <UpGradePlanPopup lable="Add Card" className='btn btn-primary' />
                                            ) : profileDetails?.chargebeeDetails?.status !== "in_trial" && profileDetails?.chargebeeDetails?.card_status === "no_card" && (!userProfile?.parentId || (userProfile?.parentId === userProfile?.userId)) && !profileDetails?.planDetails?.isAvailableOnRequest  ? (
                                                <ChargebeePopup type={"button"} className={"bg-primary"} section={"updateCreditCard"} lable={chargebeeDetails?.card?.last4 ? "Update Card" : "Add Card"} />
                                            ) : profileDetails?.chargebeeDetails?.status === "in_trial" && profileDetails?.chargebeeDetails?.card_status !== "no_card" && (!userProfile?.parentId || (userProfile?.parentId === userProfile?.userId)) && !profileDetails?.planDetails?.isAvailableOnRequest ? (
                                                <ChargebeePopup type={"button"} className={"bg-primary"} section={"updateCreditCard"} lable={chargebeeDetails?.card?.last4 ? "Update Card" : "Add Card"} />
                                            ) : profileDetails?.chargebeeDetails?.status !== "in_trial" && profileDetails?.chargebeeDetails?.status !== "cancelled"&& profileDetails?.chargebeeDetails?.card_status !== "no_card" && (!userProfile?.parentId || (userProfile?.parentId === userProfile?.userId)) && !profileDetails?.planDetails?.isAvailableOnRequest ? (
                                                <ChargebeePopup type={"button"} className={"bg-primary"} section={"updateCreditCard"} lable={chargebeeDetails?.card?.last4 ? "Update Card" : "Add Card"} />
                                            ) : profileDetails?.chargebeeDetails?.status === "cancelled" ? <UpGradePlanPopup className='btn btn-primary' lable={chargebeeDetails?.card?.last4 ? "Update Card" : "Add Card"}/> : null}
                                            {/* {isInTrialWithNoCard || isWithoutCustomerAndSubscription ? (
                                                <UpGradePlanPopup lable={"Add Card"} className="btn btn-primary" />
                                            ) : isCancelledOrSubscribed && (
                                                <ChargebeePopup type="button" className="bg-primary" section="updateCreditCard" lable={label} />
                                            )} */}
                                        </div>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </section>
                        <section id='billingContacts' className="mt-3">
                            <Card className='mb-0'>
                                <Card.Header className='topSecHead'>
                                    <h4 className="mb-0 font-size-20">Invoices</h4>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    {/* <Card>
                                        <Card.Header className='topSecHead'>
                                                <div className="d-flex align-items-center justify-content-between py-1">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className='icon_cir'>
                                                            <div className="panelnumbername_switch">
                                                                <i className='bx bxs-envelope'></i>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex align-items-center mb-0 plan_current_div">
                                                                <h5 className='mb-0 fs-5'>Send invoices to Email Addresses</h5>
                                                            </div>
                                                            <Card.Text className='mb-0 text-muted'>
                                                                Enter the email addresses on which you want to receive invoices.
                                                            </Card.Text>
                                                        </div>
                                                    </div>
                                                </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div>
                                                Add email address to get invoices
                                            </div>
                                        </Card.Body>
                                    </Card> */}
                                    <Card>
                                        <Card.Header className='topSecHead'>
                                            <div className="d-flex align-items-center justify-content-between py-1">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className='icon_cir'>
                                                        <div className="panelnumbername_switch">
                                                            <i className='bx bxs-envelope'></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-center mb-0 plan_current_div">
                                                            <h5 className='mb-0 fs-5'>Invoices</h5>
                                                        </div>
                                                        <Card.Text className='mb-0 text-muted'>
                                                            Your complete billing history - Pending & Paid invoices
                                                        </Card.Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="invoiceTabsMain">
                                            <div className="invoiceTabsBody">
                                                <Tabs
                                                    activeKey={activeTab}
                                                    onSelect={(key) => handleTabChange(key as string)}
                                                    id="fill-tab-example"
                                                    className="mb-3 ctrlhPlanBillingTabs"
                                                    fill
                                                >
                                                    <Tab eventKey="unpaid-invoice" title="Pending Invoice">
                                                        <InvoiceTable invoiceData={data} downloadInvoice={downloadInvoice} payInvoice={openInvoiceHostedPage}/>
                                                    </Tab>
                                                    <Tab eventKey="paid-invoice" title="Paid Invoice">
                                                        <InvoiceTable invoiceData={data} downloadInvoice={downloadInvoice}/>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </section>
                        <section id='billingAddress' className="my-4 mt-3">
                            <Card className='mb-0'>
                                <Card.Header className='topSecHead'>
                                    <h4 className="mb-0 font-size-20">Billing Address</h4>
                                </Card.Header>
                                <Card.Body className="p-3">
                                    <Card>
                                        <Card.Header className='topSecHead'>
                                            <div className="d-flex align-items-center justify-content-between py-1">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className='icon_cir'>
                                                        <div className="panelnumbername_switch">
                                                            <i className='subscriptiondetailsection'></i>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-0 plan_current_div">
                                                        <h5 className='mb-0 fs-5'>Manage Billing Address</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {userProfile?.chargebeeDetails &&
                                                <div className='d-flex align-items-center gap-3 mobflexcolumn'>
                                                    <p className='mb-0'>To modify the billing address,<ChargebeePopup type={"link"} section={"AddbillingAddress"} lable={"Click here."} />
                                                    </p>
                                                    {/* <div className='text-center'> */}
                                                    {/* {!userProfile.parentId || (userProfile.parentId === userProfile.userId) ? <Button onClick={() => chargebeeInit(parentId, "AddbillingAddress")} disabled={addressGetLoading}>  {addressGetLoading ? <Spinner size="sm">
                                                        Loading...
                                                    </Spinner> : <><i className='bx bx-credit-card-alt'></i> Add Billing Address </>}  </Button> : <></>} */}

                                                    {/* </div> */}
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Card.Body>    
                            </Card>
                        </section>
                        <section id='cancelAccount' className="my-4 pb-4">
                        {!userProfile?.parentId && (userProfile?.chargebeeDetails?.status === "active" || userProfile?.chargebeeDetails?.status === "in_trial") && !userProfile?.chargebeeDetails?.cancel_title &&
                            <div className='d-flex align-items-center gap-3 mobflexcolumn pb-5 justify-content-center'>
                                <Button
                                    className='cancleAcButton d-flex align-items-center justify-content-center gap-1'
                                    onClick={handleCancelAccount}>
                                    <i className='bx bx-trash' />
                                    Cancel Account
                                </Button>
                            </div>
                        }
                            {userProfile?.chargebeeDetails?.cancel_title && userProfile?.chargebeeDetails?.cancellation_at  &&
                                <div className='text-center'>
                                    <div className="qrGenerateSteps">
                                        <div className="alert alert-info mb-3 py-2 d-flex m-auto" style={{ width: "fit-content" }} role="alert">{`We have received your cancellation request. Your account is scheduled to be cancelled on ${moment(userProfile?.chargebeeDetails?.cancellation_at).format('MMM DD, gggg')}. Until then, you can continue to use the service.`}
                                        </div>
                                    </div>
                                    <Button color='primary' onClick={() => { handleSubmitButton("withdraw_cancelation") }}>Withdraw Cancelation Request</Button>
                                </div>
                            }
                        </section>
                        {/* {userProfile?.email != "hasnen+mrf2@callhippo.com" && 
                    } */}
                       
                    </div>
                    {/* <div style={{ height: '250px', background: 'rgba(0,0,0,0.85)', position: 'fixed', top: 0, left: 0, width: '0%', color: '#FFF',opacity:0,zIndex:"-1px"}} ref={topRef}></div> */}
                </div>
            </PlanBillingComponent>
        </>
    )
}

export default PlanBilling