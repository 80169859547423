import { UpdateProfileDataTypes } from "./types";

// common success
export const updateProfileDataResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: UpdateProfileDataTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const updateProfileDataResponseError = (
  actionType: string,
  error: string
) => ({
  type: UpdateProfileDataTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateProfileData = (data: any,userId:string) => {
  return {
    type: UpdateProfileDataTypes.UPDATE_PROFILE_DATA,
    payload: { data,userId },
  };
};
