// types
import { TemplateCreateActionTypes, ProfileState } from "./types";

export const INIT_STATE: ProfileState = {
  profileDetails: {},
  templateCreateSuccess: false,
  getTemplateDetails:[],
  loading:false,
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case TemplateCreateActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TemplateCreateActionTypes.POST_TEMPLATE_CREATE:
          return {
            ...state,
            templateCreate:action.payload.data,
            templateCreateSuccess:action.payload.data.success,
            userDetails: action.payload.data.data,
            userAddMsg:action.payload.data.message,
            isProfileFetched: true,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.POST_TEMPLATE_UPDATE:
          return {
            ...state,
            templateCreate:action.payload.data,
            templateCreateSuccess:action.payload.data.success,
            userDetails: action.payload.data.data,
            userAddMsg:action.payload.data.message,
            isProfileFetched: true,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.POST_TEMPLATE_DELETE:
          return {
            ...state,
            templateCreate:action.payload.data,
            templateDeleteSuccess:action.payload.data.success,
            userDetails: action.payload.data.data,
            userAddMsg:action.payload.data.message,
            isProfileFetched: true,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.GET_TEMPLATE_LIST:
          return {
            ...state,
            templateDeleteSuccess:false,
            getTemplateList:action.payload.data.data,
            getTemplateDetails:[],
            templateCreateSuccess:false,
            userDetails: action.payload.data.data,
            userAddMsg:action.payload.data.message,
            isProfileFetched: true,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.GET_TEMPLATE_DETAILS:
          return {
            ...state,
            getTemplateDetails:action.payload.data.data,
            templateCreateSuccess:false,
            userDetails: action.payload.data.data,
            userAddMsg:action.payload.data.message,
            isProfileFetched: true,
            getProfileLoading: false,
            loading:false,
          };
        default:
          return { ...state };
      }

    case TemplateCreateActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TemplateCreateActionTypes.POST_TEMPLATE_CREATE:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.POST_TEMPLATE_UPDATE:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.POST_TEMPLATE_DELETE:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.GET_TEMPLATE_LIST:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            loading:false,
          };
        case TemplateCreateActionTypes.GET_TEMPLATE_DETAILS:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            loading:false,
          };

        default:
          return { ...state };
      }

    case TemplateCreateActionTypes.POST_TEMPLATE_CREATE: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        loading:true,
      };
    }
    
    case TemplateCreateActionTypes.POST_TEMPLATE_UPDATE: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        loading:true,
      };
    }

    case TemplateCreateActionTypes.POST_TEMPLATE_DELETE: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        loading:true,
      };
    }

    case TemplateCreateActionTypes.GET_TEMPLATE_LIST: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        loading:true,
      };
    }

    case TemplateCreateActionTypes.GET_TEMPLATE_DETAILS: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        loading:true,
      };
    }

    default:
      return { ...state };
  }
};

export default Profile;
