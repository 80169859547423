import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem
} from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';
import { useRedux, useProfile } from "../hooks/index";

import {
  inviteContact, editWhatsappNumber, getClientsList, getChatUserDetails, getChatUserConversations,
  updateChatUserDetails
} from "../../../client/src/redux/actions";

import { showErrorNotification, showSuccessNotification } from "../helpers/notifications";
const countryData = require('country-data');

interface DataTypes {
  phoneNumber: string | null;
  name: string | null;
  message: string | null;
  countryCode: string | null;
}
interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: any, clientId: string) => void;
  editModal?: boolean;
  editNumberDetails?: any;
  editContactName?: boolean
}
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
  editModal,
  editContactName,
  editNumberDetails
}: InviteContactModalProps) => {

  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState();

  const {
    createdUser, editWhatsappNumberSuccess, editChatUserDetails,userDetails,clientId
  } = useAppSelector((state) => {
    return {
      createdUser: state.Chats.createdUser,
      editWhatsappNumberSuccess: state.Chats.editWhatsappNumberSuccess,
      editChatUserDetails: state.Chats.editChatUserDetails,
      clientId: state.Chats.clientId,
      userDetails: state.Login.user
    };
  });
  const countryCodes = countryData.callingCountries.all.map((country: any) => {
    const dialCode: string = country.countryCallingCodes[0].replace('+', '');
    const label: string = `+${dialCode} (${country.alpha2})`;
    return { value: dialCode, label };
  });

  useEffect(() => {
    if (editWhatsappNumberSuccess) {
      onClose();
    }
  }, [editWhatsappNumberSuccess])

  const [selectedWAValue, setSelectedWAValue] = useState(userProfile?.clientId);
  const [data, setData] = useState<DataTypes>({
    phoneNumber: null,
    name: null,
    message: null,
    countryCode: null,
  });
  useEffect(() => {
    setData({
      phoneNumber: null,
      name: null,
      message: null,
      countryCode: countryCodes.find((item:any)=> (item.value).toString() ==  (userDetails.country_code).toString()).value,
    });
  }, []);
  
  const [editedName, setEditedName] = useState(editModal
    ? (editNumberDetails?.pushname || null)
    : (editContactName ? editNumberDetails?.pushname : data["name"] || null));



  const onChangeData = (field: "phoneNumber" | "name" | "message" | "countryCode", value: string, isEditing?: any) => {
    let modifiedData = { ...data };
    if (field === "name") {
      if (isEditing) {
        setEditedName(value); // Update the edited name state
      } else {
        modifiedData[field] = value !== "" ? value : null;
      }
    } else {
      modifiedData[field] = value !== "" ? value : null;
    }
    setData(modifiedData);
  };

  /*
  validation
  */
  const [valid, setValid] = useState<boolean>(false);
  useEffect(() => {
    if (editModal) {
      if (data.name !== null) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else if (editContactName) {
      if (editedName !== null) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      if (editedName !== null && data.phoneNumber !== null) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [data]);
  useEffect(() => {
    if (createdUser && !createdUser.success) {
      setValid(true)
    }
  }, [createdUser])

  const onSubmitData = (data: any, selectedWAValue: any) => {
    setValid(false);
    const payLoadData = {
      whatsappName: editedName ? editedName : editNumberDetails?.pushname,
      clientId: editNumberDetails?.clientId,
      userId: editNumberDetails?._id,
      chatId: editNumberDetails?.chatId
    }
    if (editModal) {
      // dispatch(editWhatsappNumber(payLoadData))
      // dispatch(getClientsList());

    } else if (editContactName) {
      const whatsappName = editedName ? editedName : editNumberDetails?.pushname
      dispatch(updateChatUserDetails({id:editNumberDetails._id,clientId:clientId,chatId:editNumberDetails?.chatId,eventType:"Edit_Name",data:{ verifiedName : whatsappName }}))
      onClose()
    } else {
      // data.countryCode = selectedCountryCode.value
      if(editedName){
        data.name = editedName
        onInvite(data, selectedWAValue)
      }else{
        showErrorNotification("You have to enter name first")
      }
    }
  }

  const handleCountryCodeChange = (selectedOption: any) => {
    onChangeData("countryCode",selectedOption.value)
    setSelectedCountryCode(selectedOption);
};

  return (
    <Modal className="inviteContactModel" isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        {editModal ? "Edit WhatsApp Name" : editContactName ? "Update Contact" : "Create Contact"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>

          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Name
            </Label>
            <Input
              type="text"
              className="form-control formInputField"
              id="AddContactModalname-input"
              placeholder="Enter Name"
              value={editedName} // Bind the edited name state to the input field
              onChange={(e) => {
                onChangeData("name", e.target.value, true); // Call onChangeData with isEditing true
              }}
            />
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Label htmlFor="AddContactModalemail-input" className="form-label">Phone Number</Label>
            </div>
            {(!editModal && !editContactName) ?
              <div className="input-group contctMdlInputGroup">
                <div className="input-group-prepend updtProfContryCodeDropDwn">
                  <Select
                    options={countryCodes}
                    defaultValue={countryCodes.find((item:any)=> (item.value).toString() ==  (userDetails.country_code).toString())}
                    classNamePrefix="select"
                    className="input-group-text"
                    onChange={handleCountryCodeChange}
                    // menuIsOpen={true}
                  />
                  {/* <SelectCountryCode /> */}
                </div>
                <Input  
                    type="text"
                    className="formInputField"
                    id="AddContactModalemail-input"
                    placeholder="Enter Phone Number"
                    value={
                      editModal
                        ? (editNumberDetails?.me?.user || "")
                        : (editContactName ? editNumberDetails?.user : data["phoneNumber"] || "")
                    }
                    pattern="[0-9]*"
                    disabled={editModal}
                    onChange={(e: any) => {
                      onChangeData("phoneNumber", e.target.value.replace(/\D/g, ''));
                    }}
                />
              </div>
              :
              <div className={`${editModal || editContactName ?'col-12':'col-10'}`}>
                <Input
                  type="text"
                  className="form-control formInputField"
                  id="AddContactModalemail-input"
                  placeholder="Enter Phone Number"
                  value={
                    editModal
                      ? (editNumberDetails?.me?.user || "")
                      : (editContactName ? editNumberDetails?.user : data["phoneNumber"] || "")
                  }
                  pattern="[0-9]*"
                  disabled={editModal}
                  onChange={(e: any) => {
                    onChangeData("phoneNumber", e.target.value.replace(/\D/g, ''));
                  }}
                />
              </div>
            }
          </div>
          {/* <div className="mb-3">
            <Label htmlFor="AddEmailModalname-input" className="form-label">
              Email
            </Label>
            <Input
              type="email"
              className="form-control formInputField"
              id="AddEmailModalname-input"
              placeholder="ameliawarner@gmail.com"
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              required
            />
          </div> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={!valid}
          onClick={() => onSubmitData(data, selectedWAValue)}
        >
          {!editModal ? "Create" : "Edit"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
