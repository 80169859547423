import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ChatsActionTypes } from "./types";
import { AuthLoginActionTypes } from "../auth/login/types";
import { chatsApiResponseSuccess, chatsApiResponseError, syncCipherTextMessage } from "./actions";
import {authLoginApiResponseSuccess} from "../auth/login/actions"
import {
  getFavourites as getFavouritesApi,
  getDirectMessages as getDirectMessagesApi,
  getChannels as getChannelsApi,
  addContacts as addContactsApi,
  createChannel as createChannelApi,
  updatePrivacySetting as updatePrivacySettingApi,
  addParticipants as addParticipantsApi,
  removeParticipants as removeParticipantsApi,
  syncingStopToCrm as syncingStopToCrmApi,
  makeAsAdmin as makeAsAdminApi,
  dismissAsAdmin as dismissAsAdminApi,
  getChatUserDetails as getChatUserDetailsApi,
  changeAssignUser as changeAssignUserApi,
  downloadChat as downloadChatApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  sendTelegramMessage,
  sendChatBotMessage,
  sendMessengerMessage,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  receiveMessageFromUser as receiveMessageFromUserApi,
  deleteMessage as deleteMessageApi,
  forwardMessage as forwardMessageApi,
  deleteUserMessages as deleteUserMessagesApi,
  getChannelDetails as getChannelDetailsApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  getArchiveContact as getArchiveContactApi,
  toggleArchiveContact as toggleArchiveContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
  getClientDetails as getClientDetailsAPI,
  createUser as createUserApi,
  createTelegramUser as createTelegramUserApi,
  sendSeenApi as sendSeenWpApi,
  editWhatsappNumber as editWhatsappNumberApi,
  getClientsList as getClientsListApi,
  getChannelList as getChannelListApi,
  getTemplateClientList as getTemplateClientListApi,
  getReportChannelList as getReportChannelListApi,
  getCampaignLists as getCampaignListApi,
  getAllocationList as getAllocationListApi,
  getDownloadData as getDownloadDataApi,
  getLabels as getLabelsApi,
  updateChatUserDetails as updateChatUserDetailsApi,
  warmupLogicConfirm as warmupLogicConfirmApi,
  syncCipherTextMessage as syncCipherTextMessageApi,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {
  getFavourites as getFavouritesAction,
  getChannels as getChannelsAction,
} from "./actions";

function* getFavourites() {
  try {
    const response: Promise<any> = yield call(getFavouritesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_FAVOURITES, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_FAVOURITES, error));
  }
}

function* getDirectMessages({ payload: {clientId,skip,limit,key,value,search} }: any) {
  try {
    const response: Promise<any> = yield call(getDirectMessagesApi,clientId,skip,limit,key,value,search);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DIRECT_MESSAGES, error)
    );
  }
}

function* getChannels() {
  try {
    const response: Promise<any> = yield call(getChannelsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNELS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHANNELS, error));
  }
}

function* addContacts({ payload: contacts }: any) {
  try {
    const response: Promise<any> = yield call(addContactsApi, contacts);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.ADD_CONTACTS, response));
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_CONTACTS, error));
  }
}
function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    var responsesData:any = response
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    if(responsesData?.success){
      yield call(showSuccessNotification, responsesData);
    }else{
      showErrorNotification(responsesData)
    }
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error));
  }
}
function* updatePrivacySetting({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(updatePrivacySettingApi, channelData);
    var responsesData:any = response
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CHANGE_PRIVACY_SETTING, response)
    );
    yield call(showSuccessNotification, responsesData);
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CHANGE_PRIVACY_SETTING, error));
  }
}
function* addParticipants({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(addParticipantsApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.ADD_PARTICIPANTS, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.ADD_PARTICIPANTS, error));
  }
}
function* removeParticipants({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(removeParticipantsApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.REMOVE_PARTICIPANTS, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.REMOVE_PARTICIPANTS, error));
  }
}
function* syncingStopToCrm({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(syncingStopToCrmApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.SYNCING_STOP_TO_CRM, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.SYNCING_STOP_TO_CRM, error));
  }
}
function* makeAsAdmin({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(makeAsAdminApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.MAKE_AS_ADMIN, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.MAKE_AS_ADMIN, error));
  }
}
function* dismissAsAdmin({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(dismissAsAdminApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.MAKE_AS_ADMIN, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.MAKE_AS_ADMIN, error));
  }
}

function* getChatUserDetails({ payload: {selectedChat, clientId} }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, selectedChat,clientId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* changeAssignUser( payload: any) {
  try {
    const response: Promise<any> = yield call(changeAssignUserApi, payload);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CHANGE_ASSIGN_USER, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.CHANGE_ASSIGN_USER, error)
    );
  }
}

function* downloadChat( {payload:payload}: any) {
  try {
    const response: Promise<any> = yield call(downloadChatApi, payload);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DOWNLOAD_CHAT, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.DOWNLOAD_CHAT, error)
    );
  }
}

function* getChatUserConversations({ payload:{ selectedChat, clientId,createdAt}}: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, selectedChat,clientId,createdAt);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* onSendMessage({ payload: { data, clientId } }: any): Generator {
  try {
    const response: any = yield call(sendMessage, data, clientId);

    if (!response.success) {
      yield call(showErrorNotification, response);
      yield put(chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response));
    } else {
      yield put(chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSAGE, response));
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSAGE, error));
  }
}
function* onSendTelegramMessage({ payload: { data, clientId } }: any): Generator {
  try {
    const response: any = yield call(sendTelegramMessage, data, clientId);

    if (!response.success) {
      yield call(showErrorNotification, response);
    } else {
      yield put(chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE, response));
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE, error));
  }
}

function* onSendChatBotMessage({ payload: { data, clientId } }: any): Generator {
  try {
    const response: any = yield call(sendChatBotMessage, data, clientId);

    if (!response.success) {
      yield call(showErrorNotification, response);
    } else {
      yield put(chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE, response));
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE, error));
  }
}

function* onSendMessngerMessage({ payload: { data, clientId } }: any): Generator {
  try {
    const response: any = yield call(sendMessengerMessage, data, clientId);

    if (!response.success) {
      yield call(showErrorNotification, response);
    } else {
      yield put(chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE, response));
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE, error));
  }
}


function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}

function* receiveMessageFromUser({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
    );
  }
}

function* deleteMessage({ payload: { chatUserDetails, messageId,option } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      chatUserDetails,
      messageId,
      option
    );
    const result :any = response
    if (result.success) {
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
      );
    } else {
      showErrorNotification(result.error)
    }
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error));
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error));
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_USER_MESSAGES, error)
    );
  }
}

function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error)
    );
  }
}

function* toggleFavouriteContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}

function* getArchiveContact() {
  try {
    const response: Promise<any> = yield call(getArchiveContactApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  }
}

function* toggleArchiveContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveContactApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    showSuccessNotification(response)
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    // yield put(getDirectMessagesAction(id));
    yield put(getFavouritesAction());
    yield put(getChannelsAction());
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

function* updateClientSession({ payload: clientId }: any) {
  try {

    const response: Promise<any> = yield call(getClientDetailsAPI, clientId );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.UPDATE_CLIENT_SESSION, response));
    
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.UPDATE_CLIENT_SESSION, error));
  }
}


// function* createUser(data:any,callback:any) {
function* createUser({payload :{data },callback}:any) {
  try {
    const response: Promise<any> = yield call(createUserApi,data);
    if (typeof callback === 'function') {
      callback(response);
    }
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.CREATE_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.CREATE_USER, error)
    );
  }
}
function* createTelegramUser({payload :{data },callback}:any) {
  try {
    const response: Promise<any> = yield call(createTelegramUserApi,data);
    if (typeof callback === 'function') {
      callback(response);
    }
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.CREATE_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.CREATE_USER, error)
    );
  }
}

function* sendSeenApi(data:any) {
  try {
    const response: Promise<any> = yield call(sendSeenWpApi,data);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.SEND_SEEN_API,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.SEND_SEEN_API, error)
    );
  }
}
function* editWhatsappNumber(data: any): Generator<any, void, any> {
  try {
    const response: any = yield call(editWhatsappNumberApi, data);
    
    if (response && response.message) {
      yield put(
        chatsApiResponseSuccess(
          ChatsActionTypes.EDIT_WHATSAPP_NUMBER,
          response
        )
      );
      showSuccessNotification(response)
    } else {
      yield put(
        chatsApiResponseError(
          ChatsActionTypes.EDIT_WHATSAPP_NUMBER,
          "Error message not found"
        )
      );
      yield call(showErrorNotification, "Error message not found");
    }

  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.EDIT_WHATSAPP_NUMBER, error)
    );
    yield call(showErrorNotification, error + "");
  }
}



function* getClientsListData({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getClientsListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CLIENTS_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CLIENTS_LIST, error)
    );
  }
}
function* getChannelList({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getChannelListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_LIST, error)
    );
  }
}
function* getTemplateClientList({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getTemplateClientListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_TEMP_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_TEMP_LIST, error)
    );
  }
}
function* getReportChannelList({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getReportChannelListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_REPORT_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_REPORT_LIST, error)
    );
  }
}
function* getCampaignLists({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getCampaignListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CAMPAIGN_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CAMPAIGN_LIST, error)
    );
  }
}
function* getAllocationList({ payload: type }: any) {
  try {
    const response: Promise<any> = yield call(getAllocationListApi, type);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_ALLOCATION_LIST, response));
    yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ALLOCATION_LIST, error)
    );
  }
}

function* getDownloadData({ payload: { conversationId, chatId, clietId, reponseType } }: any): Generator<any, void, any> {
  try {
    const response: any = yield call(getDownloadDataApi, conversationId, chatId, clietId, reponseType);

    if (response) {
      response.conversationId = conversationId;
      response.reponseType = reponseType
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.GET_DOWNLOAD_DATA, response)
      );
    } else {
      yield put(
        chatsApiResponseError(ChatsActionTypes.GET_DOWNLOAD_DATA, "Something went wrong")
      );
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DOWNLOAD_DATA, error)
    );
  }
}
function* getLabels({payload}:any) {
  try {
    const response: Promise<any> = yield call(getLabelsApi,payload?.clientId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_LABELS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_LABELS, error)
    );
  }
}
function* updateChatUserDetails({payload:{id,clientId,chatId,eventType,data, chatName},callback}:any) {
  try {
    const response: Promise<any> = yield call(updateChatUserDetailsApi,id,clientId,chatId,eventType,data, chatName);
    if (typeof callback == "function" ) {
      callback(response)
    }
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.UPDATE_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.UPDATE_CHAT_USER_DETAILS, error)
    );
  }
}
function* warmupLogicConfirm({payload:{clientId}}:any) {
  try {
    const response: Promise<any> = yield call(warmupLogicConfirmApi,clientId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.WARMUP_CONFIRMATION, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.WARMUP_CONFIRMATION, error)
    );
  }
}

function* syncCipherText({ payload: { data, clientId }, callback }: any) {
  try {
    const response: Promise<any> = yield call(syncCipherTextMessageApi, data, clientId);
    if (typeof callback === "function") callback(response)
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.SYNC_CIPHERTEXT_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.SYNC_CIPHERTEXT_MESSAGE, error)
    );
  }
}
export function* watchGetFavourites() {
  yield takeEvery(ChatsActionTypes.GET_FAVOURITES, getFavourites);
}

export function* watchGetDirectMessages() {
  yield takeEvery(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
}
export function* watchGetChannels() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getChannels);
}
export function* watchAddContacts() {
  yield takeEvery(ChatsActionTypes.ADD_CONTACTS, addContacts);
}
export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchUpdatePrivacySetting() {
  yield takeEvery(ChatsActionTypes.CHANGE_PRIVACY_SETTING, updatePrivacySetting);
}
export function* watchAddParticipants() {
  yield takeEvery(ChatsActionTypes.ADD_PARTICIPANTS, addParticipants);
}
export function* watchRemoveParticipants() {
  yield takeEvery(ChatsActionTypes.REMOVE_PARTICIPANTS, removeParticipants);
}
export function* watchSyncingStopToCrm() {
  yield takeEvery(ChatsActionTypes.SYNCING_STOP_TO_CRM, syncingStopToCrm);
}
export function* watchMakeAsAdmin() {
  yield takeEvery(ChatsActionTypes.MAKE_AS_ADMIN, makeAsAdmin);
}
export function* watchDismissAsAdmin() {
  yield takeEvery(ChatsActionTypes.DISMISS_AS_ADMIN, dismissAsAdmin);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchChangeAssignUser() {
  yield takeEvery(ChatsActionTypes.CHANGE_ASSIGN_USER, changeAssignUser);
}
export function* watchDownloadChat() {
  yield takeEvery(ChatsActionTypes.DOWNLOAD_CHAT, downloadChat);
}

export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchOnSendTelegramMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE, onSendTelegramMessage);
}
export function* watchOnSendChatBotMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE, onSendChatBotMessage);
}
export function* watchOnSendMessengerMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE, onSendMessngerMessage);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
export function* watchReceiveMessageFromUser() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
    receiveMessageFromUser
  );
}
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchGetArchiveContact() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVE_CONTACT, getArchiveContact);
}
export function* watchToggleArchiveContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}

export function* watchUpdateClientSession() {
  yield takeEvery(ChatsActionTypes.UPDATE_CLIENT_SESSION, updateClientSession);
}
export function* watchCreateUser() {
  yield takeEvery(ChatsActionTypes.CREATE_USER, createUser);
}
export function* watchCreateTelegramUser() {
  yield takeEvery(ChatsActionTypes.CREATE_TELEGRAM_USER, createTelegramUser);
}
export function* watchSendSeenApi() {
  yield takeEvery(ChatsActionTypes.SEND_SEEN_API, sendSeenApi);
}
export function* watchEditWhatsappNumber() {
  yield takeEvery(ChatsActionTypes.EDIT_WHATSAPP_NUMBER, editWhatsappNumber);
}
export function* watchGetClientList() {
    yield takeEvery(ChatsActionTypes.GET_CLIENTS_LIST, getClientsListData);
}
export function* watchGetChannelList() {
    yield takeEvery(ChatsActionTypes.GET_CHANNEL_LIST, getChannelList);
}
export function* watchGetTemplateClientList() {
  yield takeEvery(ChatsActionTypes.GET_TEMP_LIST, getTemplateClientList);
}
export function* watchGetReportChannelList() {
    yield takeEvery(ChatsActionTypes.GET_REPORT_LIST, getReportChannelList);
}
export function* watchGetCampaignList() {
    yield takeEvery(ChatsActionTypes.GET_CAMPAIGN_LIST, getCampaignLists);
}
export function* watchGetAllocationList() {
    yield takeEvery(ChatsActionTypes.GET_ALLOCATION_LIST, getAllocationList);
}

export function* watchGetDownloadData() {
    yield takeEvery(ChatsActionTypes.GET_DOWNLOAD_DATA, getDownloadData);
}
export function* watchGetLabels() {
    yield takeEvery(ChatsActionTypes.GET_LABELS, getLabels);
}
export function* watchUpdateChatUserDetails() {
    yield takeEvery(ChatsActionTypes.UPDATE_CHAT_USER_DETAILS, updateChatUserDetails);
}
export function* watchWarmupLogicConfirm() {
    yield takeEvery(ChatsActionTypes.WARMUP_CONFIRMATION, warmupLogicConfirm);
}
export function* watchSyncCipherTextMessage() {
  yield takeEvery(ChatsActionTypes.SYNC_CIPHERTEXT_MESSAGE, syncCipherText);
}
function* chatsSaga() {
  yield all([
    fork(watchGetFavourites),
    fork(watchGetDirectMessages),
    fork(watchGetChannels),
    fork(watchAddContacts),
    fork(watchCreateChannel),
    fork(watchUpdatePrivacySetting),
    fork(watchAddParticipants),
    fork(watchRemoveParticipants),
    fork(watchSyncingStopToCrm),
    fork(watchMakeAsAdmin),
    fork(watchDismissAsAdmin),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchOnSendMessage),
    fork(watchOnSendTelegramMessage),
    fork(watchOnSendChatBotMessage),
    fork(watchOnSendMessengerMessage),
    fork(watchReceiveMessage),
    fork(watchReadMessage),
    fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchGetChannelDetails),
    fork(watchToggleFavouriteContact),
    fork(watchGetArchiveContact),
    fork(watchToggleArchiveContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchUpdateClientSession),
    fork(watchCreateUser),
    fork(watchCreateTelegramUser),
    fork(watchGetClientList),
    fork(watchGetChannelList),
    fork(watchGetReportChannelList),
    fork(watchGetCampaignList),
    fork(watchGetAllocationList),
    fork(watchGetDownloadData),
    fork(watchEditWhatsappNumber),
    fork(watchChangeAssignUser),
    fork(watchDownloadChat),
    fork(watchGetLabels),
    fork(watchUpdateChatUserDetails),
    fork(watchGetTemplateClientList),
    fork(watchSendSeenApi),
    fork(watchWarmupLogicConfirm),
    fork(watchSyncCipherTextMessage),
  ]);
}

export default chatsSaga;
