import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useProfile, useRedux } from "../hooks/index";
import { changeTab, connectSocket, saveIntegration, setLoginData } from "../../../client/src/redux/actions";
import { setAuthorization } from "../../../client/src/api/apiCore";
import UpdateProfile from "../pages/Authentication/UpdateProfile";
import { TABS } from "../constants";

const AuthProtected = (props: any) => {
  const { useAppSelector, dispatch } = useRedux()
  const { userProfile, loading } = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isUserLogout,
    isUserLogin,
    socketConnected
  } = useAppSelector((state) => {
    return {
      isUserLogout: state.Login.isUserLogout,
      isUserLogin: state.Login.isUserLogin,
      socketConnected:state.WebSocket.connected
    };
  })
  const [isLogin, setIsLogin] = useState(isUserLogin)
  const [isLogout, setIsLogout] = useState(isUserLogin)
  useEffect(() => {
    setIsLogin(isUserLogin)
    setIsLogout(isUserLogout)
  }, [isUserLogin, isUserLogout])
  useEffect(() => {
    if(userProfile?.userId && !socketConnected) {
      dispatch(setLoginData(userProfile))
      dispatch(connectSocket({ userId: userProfile?.userId, parentId: userProfile?.parentId?._id ? userProfile?.parentId?._id : userProfile?.parentId ,browserUid: userProfile?.browserUid }));
    }
  }, [])

  useEffect(() => {
    if (location.pathname === "/integrations") {
      const queryString = location.search;
      const urlParams = new URLSearchParams(queryString);
      const codeValue = urlParams.get('code');
      const tokenValue = urlParams.get('token');
      const data = {
        code: codeValue && codeValue,
        token: tokenValue && tokenValue
      }
      if(tokenValue){
        dispatch(saveIntegration(data, (response: any) => {
          localStorage.setItem("authData", JSON.stringify(response?.data));
            if(response?.data?.phoneNumber){
              setAuthorization(response?.data?.authToken);
              dispatch(changeTab(TABS.CHAT))
              navigate("/dashboard")
            }else{
              navigate("/update-profile");
            }
        }))
      }
      if(codeValue && !userProfile) {
        dispatch(saveIntegration(data, (response: any) => {
          localStorage.setItem("authData", JSON.stringify(response?.data));
            if(response?.data?.phoneNumber){
              setAuthorization(response?.data?.authToken);
              navigate("/dashboard")
            }else{
              navigate("/update-profile");
            }
        }))
      }
    }

  }, [location,dispatch,userProfile,navigate]);

  /*
    redirect is un-auth access protected routes via url
  */
  if ((!userProfile || !isLogin || isLogout) && loading) {
    return (
      <Navigate to={{ pathname: "/auth-login" }} />
    );
  }

  if((userProfile?.isOnboardingPending)){
    return(
      <UpdateProfile />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
