import styled from 'styled-components';
import ArrowDown from '../assets/images/icons/arrow_down.svg';

const UserAddFormWrapper = styled.div`
//margin-top: 50px !important;
width:100%;
.userAddFormContainer{
    padding-top: 22px!important;
    .userAddFormMain {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        input,.ant-select,.antBotton{
            min-height:38.55px !important;
        }
        h3{ 
            font-weight: 600 !important;
            color: #333 !important;
        }
        .pageBackBtn{
            border-radius: 5px !important;
            background: #EFEFEF !important;
            border-color:#EFEFEF !important;
            color: #000  !important;
            i{
              font-style: normal !important;
              line-height: normal !important;
            }
        }
        .createTemplateNbListDrpDwp{
            .dropItemUserOffline::before ,
            .dropItemUserOnline::before{
              left: -14px;
              top: 8px;
            }
            .dropdown-toggle {
                position: relative !important;
                &::after{
                  position: absolute !important;
                  content: "";
                  top:8px;
                  right: 5px;
                  background-image: url(${ArrowDown});
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center !important;
                  display: block;
                  height: 22px !important;
                  width: 22px !important;
                }
              }
        }
        .EmailFormAdd {
            background-color: #fff;
            padding: 30px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            .allocate_mun_checkbox{
                position: absolute;
                top: 2px;
                right: 4px;
            }
            div.allocate_number_div_main:hover {
                border: 2px solid #0029c7;
            }
            .allocate_number_div_main:hover i {
                color: #0029c7;
            }
            .allocate_number_div_main:hover .country_heading {
                color: #0029c7;
            }
            .allocate_number_div_main i{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 38px;
            }
            .allocate_active .country_heading{
                color: #0029c7;
            }
            .allocate_active i{
                color: #0029c7;
            }
            .allocate_active{
                border: 2px solid #0029c7 !important;
            }
            .allocate_number_div_main{
                box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                border: 2px solid transparent;
                border-radius: 8px;
                padding: 10px;
                position: relative;
                cursor: pointer;
                margin-bottom: 15px;
                .country_heading{
                    margin-bottom: 3px;
                    max-width: 115px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .phone_number_main{
                    box-shadow: rgb(199, 199, 199) 0px 0px 0px 1px;
                    border-radius: 3px;
                    max-width:100% !important;
                    padding: 0px 2px !important;
                    p{
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                        white-space: nowrap !important;
                        font-size: 11px;
                        color: rgb(140, 140, 140);
                        padding: 2px 6px;
                        margin-bottom: 0;
                    }
                }
                .country_alias_main{
                    width: 40px;
                    height: 40px;
                    background-color: #0029c7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                    border: 4px solid #fff;
                    box-shadow: rgb(199, 199, 199) 0px 0px 0px 1px;
                    h6{
                        color: #fff;
                        text-align: center;
                        margin: 0;
                    }
                }
            }
            .allocateNumber_main{
                max-height: 243px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 2px;
                width: 100%;
                &::-webkit-scrollbar-thumb {
                    background-color: #c1c1c1;
                    outline: unset!important;
                    border-radius: 14px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1!important;
                }
                &::-webkit-scrollbar {
                    width: 0.4em;
                }
            }
            @media (min-width: 500px) {
                width: 800px;
            }
            @media (max-width: 991.98px) {
                // margin-top: 20px;
                width: 320px !important;
                padding: 26px !important;
                @media (max-width:991.98px){
                    max-height:calc(100vh - 44px) !important;
                    overflow-y: auto !important;
                    // padding: 16px 0px !important;
                }
            }
            .ant-input-data-count{
                font-size:13px !important;
            }
        }
        .MainEmailForm {
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: 100%;
        }
        .text_color_dropdown{
            border-color: #d9d9d9;
            min-height:38.55px !important;
            .wpLinkedUserName {
                color: #333 !important;
            }
        }
        .temp_category_dropdown .ant-select-selector {
            height: 40px !important;
            padding: 5px 11px !important;
        }
        .temp_name_input {
            height: 40px;
        }
        .addtemplet_form {
            width: 96% !important;
            border-radius: 5px;
        }
        .form_col_span {
            @media (min-width: 768px) {
                padding: 0 30px;
            }
        }
        .canclebtnTemp,
        .submitbuttonTemp {
            min-width: 120px;
        }
        .temp_category_element {
            label {
                span {
                    display: flex;
                    align-items: center;
                }
                i {
                    font-size: 18px;
                    margin-left: 4px;
                }
            }
        }
        .temp_page_title {
            border-bottom: 1px solid #f0f0f0;
            padding: 0 30px;
            font-size: 22px;
            margin-bottom: 22px;
            padding-bottom: 16px;
            color: #333333;
        }
    }
}
.createEditCampForm{
    .userNewDropdown {
        .dropdown-toggle.btn:disabled{
            background-color: whitesmoke !important;
            color: #b8b8b8 !important;
            cursor: not-allowed !important;
            opacity: 1 !important;
            .wpLinkedUserNumber,.wpLinkedUserName{
                color: #b8b8b8 !important;
            }
        }
    }
    .whenSendCampDiv{
        @media (max-width: 500px){
            flex-direction: column!important;
            gap: 0rem !important;
            .w151,  
            .ant-form-item {
                width:100% !important;
            }
        }
        .w151{
            width:151px !important;
        }
        .dateTimePickerCampCreate{
            height:32px !important;
        }
        .ant-radio-wrapper{
            @media (max-width: 500px){
                &:not(:last-child){
                    margin-bottom:10px !important;
                }
            }
            &:hover{
                .ant-radio-inner{
                    border-color:#1A3ECD !important;
                }        
            }
            .ant-radio-inner{
                border:2px solid #d9d9d9 !important;
            }
            .ant-radio-checked{
                .ant-radio-inner{
                    background-color: white !important;
                    border:2px solid #1A3ECD !important;
                    &::after{
                        background-color: #1A3ECD!important;
                        transform: scale(0.5) !important;
                    }
                }   
            }
        }
        .subLableDiv .ant-form-item-label > label,
        .ant-radio-wrapper span:last-child {
            font-weight:400 !important;
        }
    }
    .impoContactBtn{
        @media (max-width: 500px){
            flex-direction: column!important;
            align-items:flex-start !important;
            gap: 0.5rem !important;
            margin-top: 6px !important;
        }
        .powerCsvUpload{
            display:flex;
            align-items:center;
            gap:6px       
        }
        input{
            border:unset !important;
            padding-left:0px !important;
            cursor:pointer !important;
            &:is(:hover,:focus){
                box-shadow: unset !important;
            }
            &::file-selector-button {
                cursor:pointer !important;
                color: #000;
                padding: 0.5em;
                border: thin solid grey;
                border-radius: 3px;
                margin-right: 10px;
                &:hover{
                    background-color: #0029c7!important;
                    border-color: #0029c7!important;
                    color:#ffF !important;
                }
            }
        }
    }
    .downloadCampaign{
        text-decoration:underline !important;
    }
    .tempSelectFormInput label {
        width:100% !important;
    }
    .addNewTempBtn{
        color:#0029c7 !important;
        cursor:pointer !important;
    }
}
.previewTitle{
    @media (min-width:992px){
        margin-top: -30px !important;
    }
    @media (max-width:991.98px){
        margin-top:2rem !important;
    }
}
.tempPreviewDiv{
    max-height:420px !important;
    max-width:300px !important;
    margin:0 auto !important;
    display:flex !important;
    .previewImg{
        width:100% !important;
        height:100%!important;    
    }
    position:relative;
    .tempPreviewBody{
        position:absolute;
        z-index: 10;
        top: 20.3%;
        left: 12%;
        width:78%;
        @media (max-width:320px){
            top: 16.5% !important;
        }
        .tempPrevBtnsMain{
            margin-top:10px;
        }
        .tempPrevBtns{
            min-width:112px !important;
            border:none !important;
            color:#0029c7 !important;
            flex:auto !important;
            @media(max-width:500px){
                width:100% !important;
                min-width:100% !important;
            }
        }
    }
    .previewTextMain{
        padding:12px !important;
        border-radius: 7px;
        background-color:#fff !important;
        .previewTextBody{
            max-height:320px;
            overflow-y: auto !important;
            padding-right:10px;
            &,.previewText{
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #9f9f9f;
                    border-radius: 14px;
                }
                &::-webkit-scrollbar-track {
                    background: #e8e8e8;
                }
            }
        }
        &:before{
            position: absolute;
            content: '';
            top: -3px;
            left: -13px;
            height: 18px;
            width: 18px;
            background-image: url('https://images.controlhippo.com/svg/temp_vectore.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1000;
            overflow: visible !important;
        }
        .previewText{
            position:relative;
            font-size:13px !important;
            max-height:200px;
            overflow-y: auto !important;
            padding-right:4px;
            white-space: pre-wrap;
            margin-right:8px
            @media (max-width:500px){
                max-height:170px;
            }
        }
    }
}
.templatebUttons{
    .ant-form-item{
        width:100%;
        @media(max-width:500px){
            margin-bottom:14px !important;
        }
    }
    .tempBtnLbl{
        color: rgba(0, 0, 0, 0.88) !important;
        font-size: 14px !important;
    }   
    .ant-form-item-label{
        text-align: end !important;
        padding-bottom: 0px !important;
        label{
            color: gray;
            font-size: 14px;
            font-weight: 400;
            &::after{
                content:none !important;
            }
        }
    }
    .templatebUttonsBody{
        @media(max-width:500px){
            flex-direction: column;
            }
    }
}
.formRadioGroup{
    .ant-radio-wrapper{
        @media (max-width: 500px){
            &:not(:last-child){
                margin-bottom:10px !important;
            }
        }
        &:hover{
            .ant-radio-inner{
                border-color:#1A3ECD !important;
            }        
        }
        .ant-radio-inner{
            border:2px solid #d9d9d9 !important;
        }
        .ant-radio-checked{
            .ant-radio-inner{
                background-color: white !important;
                border:2px solid #1A3ECD !important;
                &::after{
                    background-color: #1A3ECD!important;
                    transform: scale(0.5) !important;
                }
            }   
        }
    }
    .ant-radio-wrapper span:last-child {
        font-weight:400 !important;
    }
}
.tempUpload{
    .ant-btn{
        &:hover{
            color:#0029c7 !important;
            border-color:#0029c7 !important;
        }
    }
}
    .Chat-bot-clipboard{
    border-radius: 8px;
    border: 1px solid #d6d2d2 !important;
    background-color: #f4f4f4 !important;
}
   .Chat-bot-clipboard i{
   background: #f4f4f4;
    padding: 3px;
    border-radius: 4px;
    // border: 1px solid #adb6bf87;

} 
`;
export { UserAddFormWrapper };