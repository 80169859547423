import React, { useEffect, useState } from 'react';
import { Button, Table, Pagination, Card, Popconfirm, Modal,Switch, Anchor } from 'antd';
import { UncontrolledTooltip,Col, Row, } from "reactstrap";
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';
import { useRedux, useProfile, updateAuthData } from "../../hooks/index";
import { changeTab, getChannelList, setClientId, numberDelete, updatePrivacySetting } from '../../redux/actions';

import {
    useNavigate,
} from "react-router-dom";
import { TABS } from '../../constants';
import InviteContactModal from '../../components/InviteContactModal';
import { checkPlanAndFeature } from '../../helpers/common.service';

interface AuthData {
    email: string;
    authToken: string;
    clientId: string;
}

interface UserList {
    id?: number;
    fullName: string;
    email: string;
    userActive: boolean;
    Status: string;
    Availability: string;
}

const NoChannelDataImg ="https://images.controlhippo.com/svg/nodata_channel_excited.svg"

const Index: React.FC = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { dispatch, useAppSelector } = useRedux();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { userProfile } = useProfile();
    var time_zone = userProfile?.timezone ? userProfile?.timezone : process.env.DEFAULT_TIMEZONE
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [disconnect, setDisconnect] = useState<boolean>(false);
    const [modalBodyText, setModalBodyText] = useState("")
    const [modalTitle, setModalTitle] = useState("")
    const [okClicked, setOkClicked] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
    const [editNumberDetails, setEditNumberDetails] = useState("");
    const [toggleValue, setToggleValue] = useState("");
    const [privacyId, setPrivacyId] = useState();

    const showModal = (__: any, data: any, option: string) => {
        if (option == "delete") {
            setDisconnect(false)
            setModalBodyText("Are you sure wish to delete this number? Doing so will halt the syncing of new messages.")
            setModalTitle("Number Delete")
        } else if (option == "disconnect") {
            setDisconnect(true)
            setModalBodyText("Are you sure wish to disconnect this number? Doing so will halt the syncing of new messages.")
            setModalTitle("Number Disconnect")
        } else if (option == "privacy") {
            // setDisconnect(true)
            setModalBodyText(data.isDisablePersonalChatSync ? "Disabling privacy mode syncs all device contacts to CRM and ControlHippo proceed?." : "Enabling privacy mode pauses syncing of Non-CRM contacts with ControlHippo and CRM proceed?.")
            setModalTitle("Privacy")
        }
        setIsModalOpen(true);
        setDeleteId(__)
        setPrivacyId(data)
        setOkClicked(false);


    };

    const showEditForm = (__: any, data: any) => {
        setIsOpenAddContact(true)
        setEditNumberDetails(__)
    }

    const handleOk = (deleteId: any) => {
        const storedData = localStorage.getItem("authData");
        const authData: AuthData | null = storedData ? JSON.parse(storedData) : null;
        if (authData && authData.clientId === deleteId.clientId) {
            const { clientId, ...newAuthData } = authData;
            localStorage.setItem("authData", JSON.stringify(newAuthData));
        }

        if (!okClicked) {
            const option = disconnect ? "disconnect" : "delete";
            const deleteData = (userId: any) => {
                dispatch(numberDelete(userId, option));
                setDeleteStatus(true)
            };
            deleteData(deleteId._id);
            setOkClicked(true);
        }
    };

    const handleCancel = () => {
        setOkClicked(false);
        setIsModalOpen(false);
    };
    useEffect(() => {
        dispatch(getChannelList("list"));
        // dispatch(changeTab(TABS.NUMBER))
    }, []);

    const { channelList, numberDeleteSuccess, clientsPayload, editWhatsappNumberSuccess,privacyChangeSuccess } = useAppSelector((state) => {
        return {
            channelList: state?.Chats?.channelList,
            numberDeleteSuccess: state?.QrCode?.numberDeleteSuccess,
            clientsPayload: state?.Chats?.clientsPayload,
            editWhatsappNumberSuccess: state.Chats.editWhatsappNumberSuccess,
            privacyChangeSuccess: state.Chats.privacyChangeSuccess,
            
        };
    });

    var mergedData: any
    if (channelList?.length > 0) {
        mergedData = channelList;
    }
    const handlePageChange = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);
    };

    useEffect(() => {
        if (editWhatsappNumberSuccess)
            setIsOpenAddContact(false)
    }, [editWhatsappNumberSuccess])

    useEffect(() => {
        if (numberDeleteSuccess) {
            setIsModalOpen(false);
            dispatch(getChannelList("list"));
        }
    }, [numberDeleteSuccess])

    useEffect(() => {
        if (privacyChangeSuccess) {
            setIsModalOpen(false);
            dispatch(getChannelList("list"));
        }
    }, [privacyChangeSuccess])

    useEffect(() => {
        if (clientsPayload?.success) {
            updateAuthData(clientsPayload.authData)
        }
    }, [clientsPayload])

    useEffect(() => {
        if (channelList?.length > 0 && deleteStatus) {
            dispatch(setClientId(channelList[0]?.clientId));
        }
    }, [channelList, deleteStatus])

    const paginationConfig = {
        current: currentPage,
        pageSize: pageSize,
        total: mergedData?.length,
        onChange: handlePageChange,
        showSizeChanger: true,
        onShowSizeChange: handlePageChange,
    };
    let navigate = useNavigate();

    const userAddHandleClick = () => {
        navigate("/welcome")
    }

    const handleRescan = (data: any) => {
        const path = '/welcome';
        const queryParams: any = {
            rescan: data?.me?.user
        };
        const index = mergedData.find((obj: any) => obj.clientId === data.clientId);
        const queryString = new URLSearchParams(queryParams).toString();
        const fullPath = `${path}?${queryString}`;
        if (index && (!index.isReady || !index.isSessionSaved)) {
            navigate(fullPath);
        }
    };

    const handleEmbededUrl = (data: any) => {
        if (data?.embeddedUrl) {
            window.open(data.embeddedUrl, '_blank');
        }
    };

    const onInviteContact = (data: any, clientId: string) => {

        //dispatch(createUser({chatId:data.countryCode+data.phoneNumber+"@c.us",clientId:clientId,data}))
        // dispatch(inviteContact(data,clientId));
    };

    const closeAddContactModal = () => {
        setIsOpenAddContact(false);
    };

    const onPrivacyChange = (data: any, value: any) => {
    setToggleValue(value)
    if(checkPlanAndFeature({featureName:"privacy",planDetails: userProfile?.planDetails,featureDisplayName:"Privacy"}))
    showModal(data._id,data, "privacy")
    }

    const handleOk1 = (privacyId:any) => {
        const payloadData = {
            clientId : privacyId.clientId,
            value : toggleValue
        }
          dispatch(updatePrivacySetting(payloadData))
    }

    // Slice the data based on the current page and page size
    const slicedData = mergedData?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
    );


    const formattedNumber = (phoneNumber: any): any => {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const formatPhoneNumber = (countryPhoneNumber: PhoneNumber): string => {
          const formattedNumber = phoneNumberUtil.format(countryPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
          return formattedNumber.replace(/ /g, ' ');
        };
        let decodedNumber: string | null = null;
        let number: PhoneNumber | undefined;
        let countryPhoneNumber = "+" + phoneNumber
        if (countryPhoneNumber) {
          try {
            const phoneRegex = /^\+?[0-9]{1,3}[- ]?\(?[0-9]{3}\)?[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
            const isPhoneNumber = phoneRegex.test(countryPhoneNumber);
            if (isPhoneNumber) {
              number = phoneNumberUtil.parse(countryPhoneNumber);
              decodedNumber = formatPhoneNumber(number);
              return decodedNumber
            }
          } catch (e) {
            console.log("error in formatPhoneNumber", e)
          }
        }
      }

    const columns: ColumnsType<UserList> = [
        {
            title: 'Name',
            dataIndex: 'pushname',
            render: (me: any, __: any) => {
                return <span>{__?.pushname ? __.pushname : "-"}</span>;
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'me',
            render: (me: any, __: any) => {
                return <span>{(__?.type == "messenger" || __?.type == "instagram" || __?.type == "chatbot") ? (me?.user ? me.user : "-") : (me?.user ? formattedNumber(me.user) : "-")}
                </span>;
            },
        }, {
            title: 'Channel Type',
            dataIndex: 'type',
            render: (type: any) => {
                return <span>{type?`${type.charAt(0).toUpperCase() + type.slice(1)}`  : "-"}</span>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'isSessionSaved',
            render: (isSessionSaved: boolean, __: any) => {
                if (__?.isWhatsappBusiness) {
                    return __?.whatsappVerificationStatus;
                } else {
                    return <span className={`${(isSessionSaved || __.isReady) ? "statusOnline" : "statusOffline"}`}>{(isSessionSaved || __.isReady) ? "Online" : "Offline"}</span>;
                }

            },
        },
        {
            title: 'Login',
            dataIndex: 'sessionSavedOn',
            render: (sessionSavedOn: any) => {
                const formattedTime = sessionSavedOn && moment(sessionSavedOn)?.tz(time_zone)?.format('DD-MM-YYYY HH:mm');
                return <span>{formattedTime}</span>;
            },
        },
        {
            title: 'Logout',
            dataIndex: 'sessionRemovedOn',
            render: (sessionRemovedOn: any, __: any) => {
                if (!__.isSessionSaved && !__.isReady) {
                    const formattedTime = sessionRemovedOn && moment(sessionRemovedOn)?.tz(time_zone)?.format('DD-MM-YYYY HH:mm');
                    return <span>{formattedTime}</span>;
                }
            },
        },
        {
            title: 'Owner',
            dataIndex: 'numberOwner',
            render: (numberOwner: any , __: any) => {
                return (<span>{__?.userId ? __.userId.fullName || __?.userId?._id?.fullName :  "-"}</span>);
            },
        },
        {
            // title: 'Privacy',
            title: <div className="d-flex align-items-center gap-1 font-size-14 text-dark lh-sm">
                Privacy
                <a id="numberPrivacy" className="lh-sm d-flex align-items-center justify-content-center numberPrivacyLink" href="https://support.controlhippo.com/support/solutions/articles/1060000057643-what-does-the-privacy-feature-do-in-controlhippo-" target="_blank">
                    <i className="bx bxs-info-circle font-size-16 lh-sm"></i>  
                </a>
            <UncontrolledTooltip target={`numberPrivacy`} placement="top">
                Click here to know more.
            </UncontrolledTooltip>
        </div> ,
            dataIndex: 'privacy',
            render: (privacy: any, __: any) => {
                return (
                <div className="flex-shrink-0 ms-2">
                {/* <div className="form-check form-switch numberPageSwitch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="privacy-lastseenSwitch"
                    checked={__.isDisablePersonalChatSync === true}
                    onChange={(e: any) => {
                        onPrivacyChange(__, e.target.checked);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="privacy-lastseenSwitch"
                  ></label>
                </div> */}
                <Switch
                    className="ch_toggle_switch" 
                    checked={userProfile?.planDetails?.features["privacy"] && __.isDisablePersonalChatSync == true}
                    onChange={(checked: boolean) => {
                        onPrivacyChange(__,checked);
                    }}
                />
              </div>
              
                )
            },
        },
        {
            title: 'Action',
            render: (__: any, channelList: any, index: any) => {
                return (
                    <>
                        <div className='d-flex'>

                            {(!channelList?.isSessionSaved && !__.isReady && !__.isWhatsappBusiness)  && (__?.type == "whatsapp" || __?.type == "telegram" ||  __?.type == "messenger" || __?.type == "instagram")&& <>
                                {/* <Button id={`rescanButton-${__._id}`}
                                    onClick={() => handleRescan(__)}
                                    className="btn btn-outline-secondary scanBtn d-flex align-items-center justify-content-center p-2 me-2"
                                >
                                    <i className='mdi mdi-qrcode-scan'></i>
                                </Button> */}
                                 <Link type="button" color="primary" id={`rescanButton-${__._id}`} className='btn btn-outline-secondary scanBtn d-flex align-items-center justify-content-center p-2 me-2' to={`/channels/reconnect-${__.type}/${__._id}`} state={{ itemIndex: index , numberData: __}}>
                                        <i className='mdi mdi-qrcode-scan font-size-14 lh-sm'></i>
                                    </Link>
                                <UncontrolledTooltip target={`rescanButton-${__._id}`} placement="bottom">
                                    Rescan
                                </UncontrolledTooltip>
                            </>
                            }

                            {(__?.isWhatsappBusiness) &&
                                <>
                                    <Button id={`embededLink-${__._id}`}
                                        onClick={() => handleEmbededUrl(__)}
                                        className="btn btn-outline-secondary scanBtn d-flex align-items-center justify-content-center me-2 p-2"
                                    >
                                        <i className='bx bx-link-alt'></i>
                                    </Button>
                                    <UncontrolledTooltip target={`embededLink-${__._id}`} placement="bottom">
                                        Registration Link
                                    </UncontrolledTooltip>
                                </>
                            }


                            {/* {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && <><Button id={`logoutButton-${__._id}`} onClick={() => showModal(__, __._id,"disconnect")} className="btn btn-outline-warning scanBtn d-flex align-items-center justify-content-center me-2 p-2">
                            <i className='mdi mdi-power-standby'></i>
                        </Button>
                        <UncontrolledTooltip target={`logoutButton-${__._id}`} placement="bottom">
                            Disconnect
                        </UncontrolledTooltip>
                        </>} */}

                            {((!userProfile?.parentId || (userProfile?.parentId == userProfile?.userId)) || (__?.userId._id == userProfile?.userId) )&&
                                <>
                                    <Link type="button" color="primary" id={`numberEdit-${__._id}`} className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2 btn-sm' to={`/number-edit/${__._id}`} state={{ itemIndex: index , numberData: __}}>
                                        <i className='mdi mdi-pencil font-size-14 lh-sm'></i>
                                    </Link>
                                    <UncontrolledTooltip target={`numberEdit-${__._id}`} placement="left">
                                        Allocated User Update
                                    </UncontrolledTooltip>
                                </>
                            }


                            {(!userProfile?.parentId || (userProfile?.parentId == userProfile?.userId)) && !__.isWhatsappBusiness && <><Button id={`deleteButton-${__._id}`} onClick={() => showModal(__, __._id, "delete")} className="btn btn-outline-danger btnDanger d-flex align-items-center justify-content-center p-2">
                                <i className='mdi mdi-delete-outline'></i>
                            </Button>
                                <UncontrolledTooltip target={`deleteButton-${__._id}`} placement="right">
                                    Delete
                                </UncontrolledTooltip>
                            </>}



                        </div>
                        <Modal className='centerPopupModel' title={modalTitle} open={isModalOpen} onOk={modalTitle == "Privacy" ?  () => handleOk1(privacyId) :  () => handleOk(deleteId)} onCancel={handleCancel} okButtonProps={{ disabled: okClicked }}>
                            <p>{modalBodyText}</p>
                        </Modal>
                        {isOpenAddContact && (
                            <InviteContactModal
                                isOpen={isOpenAddContact}
                                onClose={closeAddContactModal}
                                onInvite={onInviteContact}
                                editModal={true}
                                editNumberDetails={editNumberDetails}
                            />
                        )}
                    </>
                );
            },
        }
    ];
    const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;
    return (
        <TableListingPagesWrapper className='settingTabComponent'>
            <div className='tableListingMain user-chat overflow-hidden user-chat-show h-100'>
                {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">WhatsApp Numbers</h5>
                        <div>
                            { <Button className='py-1 px-2 btn btn-primary font-size-13 addWpBtn' onClick={userAddHandleClick} type="primary">Add WhatsApp</Button>}
                        </div>
                    </div>
                </Card> */}
                <div className='userListCardMain h-100'>
                    <Card 
                    className='userListCard h-100'  
                    title={
                        <div className='d-flex flex-column py-2'>
                            <h2 className='mb-1'>My Channels</h2>
                            <p className='mb-0'>
                            All the numbers that you have scanned and used in your account are shown below. You can also make changes to a number by clicking on the edit icon.
                            <a href='https://support.controlhippo.com/support/solutions/articles/1060000057395-how-to-allocate-numbers-to-the-users-in-controlhippo-' className='ms-1 text-decoration-underline' target='_blank'>Need Help?</a>
                            </p>
                        </div>
                    }
                    extra={ <Button className='py-1 px-2 btn btn-primary font-size-13 addWpBtn tableCreateBtn' onClick={userAddHandleClick} type="primary">Add Channel</Button>}>
                        <Table
                            //rowSelection={rowSelection}
                            columns={columns}
                            dataSource={(slicedData || []).map((item: any, index: number) => ({ ...item, key: index }))}
                            pagination={false}
                            style={{ width: '100%' }}
                            locale={{ emptyText: <div className="noTableDataImgWrapper w-50 m-auto py-5 mt-2 mb-2">
                                    <img src={NoChannelDataImg} className="noTableDataImg rounded-4" />
                                    <h5 className='mb-1 lh-sm mt-4 font-size-18 text-dark'>Add Your First Channel</h5>
                                    <p className='font-size-14 mb-0' style={{color:"#2c2c2c"}}>Start your Controlhippo journey by adding your first channel now! Click <a className='text-decoration-underline font-size-13 text-primary' onClick={userAddHandleClick}>'Add Channel' </a> to get started.</p>
                            </div> }} 
                        />
                        <div className='tablePaginationPos'>
                            <Pagination {...paginationConfig} />
                        </div>
                    </Card>
                </div>
            </div>
        </TableListingPagesWrapper>
    );
};

export default Index;
