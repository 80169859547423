import React, { useEffect } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import MyProfile from "./MyProfile";
import UserDescription from "./UserDescription";
import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";
import { useProfile } from "../../../hooks/UserHooks";
import { Link,useNavigate } from "react-router-dom";


// actions
import { getProfileDetails } from "../../../redux/actions";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const navigate = useNavigate();

  const { dispatch, useAppSelector } = useRedux();

  const { userProfile, loading } = useProfile();
  const { profileDetails, getProfileLoading, isProfileFetched } =
  useAppSelector(state => ({
    profileDetails: state.Profile.profileDetails,
    getProfileLoading: state.Profile.getProfileLoading,
    isProfileFetched: state.Profile.isProfileFetched,
  }));

  // get user profile details
  useEffect(() => {
    if(userProfile?.userId)dispatch(getProfileDetails(userProfile?.userId));
  }, [userProfile?.userId]);

  // useEffect(() => {
  //   if(userProfile.isOnboardingPending){
  //     navigate("/update-profile")
  //   }
  // }, [userProfile])
  

  return (
    <div className="position-relative">
      {getProfileLoading && !isProfileFetched && <Loader />}
      <MyProfile basicDetails={profileDetails} />

      {/* <AppSimpleBar className="p-4 profile-desc">
        <UserDescription basicDetails={profileDetails.basicDetails} />
        <hr className="my-4" />

        <Media media={profileDetails.media} limit={2} />

        <hr className="my-4" />

        <AttachedFiles attachedFiles={profileDetails.attachedFiles} />
      </AppSimpleBar> */}
    </div>
  );
};

export default Index;
