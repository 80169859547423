import { UpdateProfileDataTypes, updateProfileState } from "./types";

export const INIT_STATE: updateProfileState = {
  registrationError: null,
  message: "",
  loading: false,
  user: null,
};

const UpdateProfile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UpdateProfileDataTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UpdateProfileDataTypes.UPDATE_PROFILE_DATA:
          return {
            ...state,
            loading: false,
            user: action.payload.data,
            registrationError: null,
            isUserRegistered: true,
          };
        default:
          return { ...state };
      }

    case UpdateProfileDataTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UpdateProfileDataTypes.UPDATE_PROFILE_DATA:
          return {
            ...state,
            loading: false,
            registrationError: action.payload.error,
            isUserRegistered: false,
          };
        default:
          return { ...state };
      }

    case UpdateProfileDataTypes.UPDATE_PROFILE_DATA: {
      return {
        ...state,
        loading: true,
        isUserRegistered: false,
      };
    }
    default:
      return { ...state };
  }
};

export default UpdateProfile;
