import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { SubUserSetPwdActionTypes } from "./types";
import {
  SubUserSetPwdApiResponseSuccess,
  SubUserSetPwdApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  setSubUserPwd as setSubUserPwdApi,
} from "../../../api/index";

const fireBaseBackend: any = getFirebaseBackend();

function* setSubUserPwd({ payload: data }: any) {
    try {
    const response: Promise<any> = yield call(setSubUserPwdApi, data);
    yield put(
      SubUserSetPwdApiResponseSuccess(
        SubUserSetPwdActionTypes.SET_SUBUSER_PWD,
        response
      )
    );
  } catch (error: any) {
    yield put(
      SubUserSetPwdApiResponseError(
        SubUserSetPwdActionTypes.SET_SUBUSER_PWD,
        error
      )
    );
  }
}

export function* watchSetSubUserPwd() {
  yield takeEvery(SubUserSetPwdActionTypes.SET_SUBUSER_PWD, setSubUserPwd);
}

function* setSubUserPwdSaga() {
  yield all([fork(watchSetSubUserPwd)]);
}

export default setSubUserPwdSaga;
