import React,{useEffect,useState,Suspense} from "react";
import { Routes, Route,useNavigate  } from "react-router-dom";
import { useRedux } from "../hooks/index";
// layouts
import { AuthProtected } from "./AuthProtected";
import { publicRoutes, privateRoutes } from "./allRoutes";
import { changeTab } from "../redux/actions";
import { TABS } from "../constants";

const NonAuthLayout = React.lazy(() => import("../layouts/NonAuth/index"));
const DefaultLayout = React.lazy(() => import("../layouts/Default/index"));
const Loader = React.lazy(() => import("../components/Loader"));

const Index = (props: any) => {
  const { dispatch,useAppSelector } = useRedux();
  const navigate = useNavigate();
  const [isLogout, setLogout] = useState(false)
  const {
    isUserLogout,
    isUserLogin
  } = useAppSelector((state) => {
    return {
      isUserLogout: state.Login.isUserLogout,
      isUserLogin: state.Login.isUserLogin
    };
  })
  useEffect(() => {
    if(isUserLogout){
      navigate("/auth-login")
    }
  }, [isUserLogout,navigate])
  
  useEffect(() => {
    // if((isUserLogout))navigate("/auth-login")
    setLogout(isUserLogout)
  }, [isUserLogout,isUserLogin])
  

  const NotFound = (data:any) => {
    if (!data.isUserLogout) {
      dispatch(changeTab(TABS.CHAT))
      navigate('/dashboard')
    } else {
      navigate('/auth-login')
    }
    return <Loader/>;
  }
  return (
    <React.Fragment>
      {/* <Suspense> */}
        <Routes>
        <Route>
              {publicRoutes.map((route : any, idx :  number) => (
                <Route
                  path={route.path}
                  element={
                    <NonAuthLayout>
                      {route.component}
                    </NonAuthLayout>
                  }
                  key={idx}
                />
              ))}
        </Route>

        <Route>
          {privateRoutes.map((route, idx) =>{
            // console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRR",route.path)
            return(
              <Route
                path={route.path}
                element={
                  <AuthProtected>
                    <DefaultLayout>
                      {route.component}
                    </DefaultLayout>
                  </AuthProtected>
                }
                key={idx}
              // exact={true}
              />
            )
          } )}
        </Route>

        <Route
          path="*"
          element={<NotFound isUserLogout={isLogout} />}
        />
        </Routes>
      {/* </Suspense> */}
    </React.Fragment>
  );
};

export default Index;
