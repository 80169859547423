const config = {
  API_URL: process.env.REACT_APP_API_URL ?? "http://localhost:9098",
  CHATBOT_URL: process.env.REACT_APP_API_URL ?? "http://localhost:9098",
  TELEGRAM_URL:process.env.REACT_APP_DEFAULTAUTH ==="LOCAL" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_TELEGRAM_URL ?? "http://localhost:9096",
  AUTH_BACKEND: "Dummy",
  FIRE_BASE: {
    API_KEY: process.env.REACT_APP_APIKEY,
    AUTH_DOMAIN: process.env.REACT_APP_AUTHDOMAIN,
    DATABASEURL: process.env.REACT_APP_DATABASEURL,
    PROJECTID: process.env.REACT_APP_PROJECTID,
    STORAGEBUCKET: process.env.REACT_APP_STORAGEBUCKET,
    MESSAGINGSENDERID: process.env.REACT_APP_MESSAGINGSENDERID,
    APPID: process.env.REACT_APP_APPID,
    MEASUREMENTID: process.env.REACT_APP_MEASUREMENTID,
  },
  GOOGLE: {
    API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    SECRET: process.env.REACT_APP_GOOGLE_SECRET_KEY,
  },
  FACEBOOK: {
    APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  },
  MIXPANEL_KEY : process.env.REACT_APP_MIXPANEL_SECRATE_KEY,
  MIXPANEL_NEW_CHANGES_DATE : process.env.REACT_APP_MIXPANEL_NEW_CHANGES_DATE,
  CHARGEBEE_SITE_KEY : process.env.REACT_APP_CHARGEBEE_SITE_KEY
};

export default config;
