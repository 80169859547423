import React, { useState, useEffect, useRef } from "react";
import { Button, UncontrolledTooltip, PopoverBody, Popover } from "reactstrap";

// emoji picker
import Picker,{EmojiClickData} from "emoji-picker-react";
import TemplatePopup from "./TemplatePopup";
import AudioRecorderPopup from "./AudioRecorderPopup";
import { useRedux } from "../../../../hooks";

import templateSlashIcon from "../../../../assets/images/icons/template_slash_icon.svg";
import { getTemplateList } from "../../../../redux/actions";

interface StartButtonsProps {
  onToggle: () => void;
  onChange: (value: string) => void;
  onEmojiSelect: (value: string) => void;
  text: null | string;
  templateSend?: (value: any) => void;
  onSelectAudio: (files: Array<any> | null) => void;
  onSelectFiles: (files: Array<any> | null) => void;
  clientId?: string | null;
  onSubmit?: any
}

const StartButtons: React.FC<StartButtonsProps> = ({
  onToggle,
  onChange,
  text,
  templateSend,
  onSelectAudio,
  onSelectFiles,
  clientId,
  onEmojiSelect,
  onSubmit
}) => {
  const { dispatch, useAppSelector } = useRedux();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const [showRecorder, setShowRecorder] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false); // State to manage the expanded/collapsed state of the editor
  const popoverRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const templatePopupRef = useRef<HTMLDivElement>(null);
  const recorderPopoverRef = useRef<HTMLDivElement>(null);
  const { templateList } = useAppSelector((state) => {
    return {
      templateList: state.Templates.getTemplateList,
    };
  });

  useEffect(() => {
    dispatch(getTemplateList());
  }, [dispatch]);

  const onToggleEmoji = () => {
    setIsOpen(!isOpen);
    setShowTemplates(false); // Close TemplatePopup when opening the emoji picker
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    // const updatedText = text ? text + emojiObject.emoji : emojiObject.emoji;
    // onChange(updatedText);

    onEmojiSelect(emojiObject.emoji)
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (popoverRef.current && !popoverRef.current.contains(event.target as Node)) ||
        (pickerRef.current && !pickerRef.current.contains(event.target as Node))
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     const stopButton = document.getElementById('stop-button') as HTMLElement;
  //     const recordingPlayer = document.getElementById('recording-player') as HTMLElement;
  //     const msgSendBtn = document.getElementById('msgSendBtn') as HTMLElement;
  //     if (
  //       (recorderPopoverRef.current &&
  //         !recorderPopoverRef.current.contains(event.target as Node)) &&
  //       (stopButton && !stopButton.contains?.(event.target as Node) ||
  //         recordingPlayer && !recordingPlayer.contains?.(event.target as Node))
  //     ) {
  //       setShowRecorder(false);
  //       if(msgSendBtn && !msgSendBtn.contains?.(event.target as Node)){
  //         onSelectFiles(null)
  //       }
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [onSelectFiles]);

  const onChangeShowTemp = () => {
    setShowTemplates(!showTemplates);
  };

  const openVoiceRecorder = () => {
    setShowRecorder(!showRecorder);
  };

  const templateSends = async (value: any) => {
    // onChange(value?.formattedTempFormatCopy ? value.formattedTempFormatCopy : value.tempFormat);
    setShowTemplates(!showTemplates)
    if (templateSend) templateSend(value);
    // await new Promise(resolve => setTimeout(resolve, 1000))
    return onSubmit(value)

  };

  const onSelectAudioFiles = (audios: any) => {
    onSelectFiles(audios); // onSelectAudio(audios)
  };

  const expandEditor = () => {
    const editor = document.querySelector('.ql-editor') as HTMLElement;
    const chatConservationAddClass = document.querySelector('.chat-conversation') as HTMLElement;
    if (editor) {
      if (isExpanded) {
        editor.style.maxHeight = ''; // Remove the height style to collapse the editor
        editor.style.height = ''; // Remove the height style to collapse the editor
        chatConservationAddClass.classList.remove('expandBelowEditor');
      } else {
        editor.style.maxHeight = '100%'; // Set the height style to expand the editor
        editor.style.height = '350px'; // Remove the height style to collapse the editor
        chatConservationAddClass.classList.add('expandBelowEditor');
      }
      setIsExpanded(!isExpanded); // Toggle the expanded state 
    }
  };

  return (
    <div className="chat-input-links w-100 align-items-center chatInputLinksMain">
      <div className="links-list-item linksListItemNew" id="emoji">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect emoji-btn"
          id="emoji-btn"
          color="none"
          onClick={onToggleEmoji}
        >
          <span className="bx-smile-icon align-middle"></span>
        </Button>
      </div>
      <UncontrolledTooltip target="emoji" placement="top">
        Emoji
      </UncontrolledTooltip>

      <div className="links-list-item linksListItemNew" id="more-menu">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={onToggle}
          color="none"
        >
          <span className="bx-paperclip-icon align-middle"></span>
        </Button>
      </div>
      <UncontrolledTooltip target="more-menu" placement="top">
        More
      </UncontrolledTooltip>
      <div className="links-list-item linksListItemNew chatVoiceRecorderBtn" id="voice-recorder">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={openVoiceRecorder}
          color="none"
        >
          <span className="bxs-microphone-icon align-middle"></span>
        </Button>
      </div>
      <UncontrolledTooltip target="voice-recorder" placement="top">
        Voice Note
      </UncontrolledTooltip>

      <div className="links-list-item linksListItemNew" id="template">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={onChangeShowTemp}
          color="none"
        >
          <i className="mdi mdi-slash-forward align-middle slash_icon_chat"></i>
        </Button>
      </div>
      <UncontrolledTooltip target="template" placement="top">
        Template
      </UncontrolledTooltip>

      {/* <div className="links-list-item linksListItemNew" id="expandMore">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={expagitndEditor}
          color="none"
        >
          <i className="mdi align-middle mdi-arrow-expand"></i>
        </Button>
      </div> */}

      <Popover
        trigger="focus"
        placement="bottom"
        target="emoji-btn"
        isOpen={isOpen}
        toggle={onToggleEmoji}
        innerRef={popoverRef}
        className="emojiPopover"
      >
        <PopoverBody className="p-0">
          <div ref={pickerRef}>
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        </PopoverBody>
      </Popover>

      {showTemplates && (
        <TemplatePopup
          templateSend={templateSends}
          templateList={templateList}
          showTemplates={showTemplates}
          clientId={clientId}
          onChangeShowTemp = {onChangeShowTemp}
        />
      )}
      {showRecorder && (
        <AudioRecorderPopup
          innerRef={recorderPopoverRef}
          onSelectAudio={onSelectAudioFiles}
          showRecorder={showRecorder}
        />
      )}
    </div>
  );
};

export default StartButtons;
