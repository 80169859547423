import React from 'react'
import { useParams } from 'react-router';
 import NumberAdd from "./addNumber";
 import { useLocation } from 'react-router-dom';

const EditNumber = () => {
    const {id} = useParams();
    const location = useLocation();
    const { numberData } = location.state;
    // const editUserState = true; 
     return (<NumberAdd editUserId={id} numberData={numberData}/>)
}
export default EditNumber