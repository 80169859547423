import React, { useEffect, useState,useRef } from 'react'
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { Card} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useRedux, useProfile } from "../../hooks";
import { Anchor } from 'antd';
// Import File
import ConnectButton from './connectPopup';
import DynamicButton from './dynamicButton';
import SettingPopupButton from './settingPopup';
import { integrationList, IntegrationsList, Integration} from '../../data/integrations';
import { availbleOnReqInt } from '../../data/availableOnRequest';
import { saveIntegration, getIntegrations, removeIntegraation, updateIntegration,changeTab, updateSettings, clearSettingsState, getClientsList, channelCreate } from "../../redux/actions";
import { IntegrationWrapper } from './IntegrationWrapper.style';
import chromeWebStore from "../../assets/images/chrome-web-store.png";
import { addMixpanelEvent } from "../../helpers/mixpanel";
import { TABS } from '../../constants';
import WebhookConnectPopup from './webhookConnectPopup';
import { showSuccessNotification } from '../../helpers/notifications';
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const Index = () => {
    const [integrationsData, setIntegrationsData] = useState<IntegrationsList>(integrationList);
    const [availableIntegration, setAvailableIntegration] = useState<IntegrationsList>(availbleOnReqInt);
    const { dispatch, useAppSelector } = useRedux();
    const navigate = useNavigate();
    const { userProfile } = useProfile();
    const [isLoading, setIsLoading] = useState(false);
    const [btnName, setBtnName] = useState("Integrated");
    const location = useLocation();
    const settingButtonRef = useRef<HTMLButtonElement>(null);
    const webhookButtonRef = useRef<HTMLButtonElement>(null);
    const {
        loading,
        isIntegration,
        integrationsList,
        slackIntegrationsList,
        removeIntegraationData,
        userData,
        isSettingsUpdated
    } = useAppSelector(state => ({
        loading: state.Integrations.loading,
        isIntegration: state.Integrations.isIntegration,
        integrationsList: state.Integrations.integrations,
        slackIntegrationsList: state.Integrations.slackIntegrations,
        removeIntegraationData: state.Integrations.removeIntegraation,
        userData: state.Login.user,
        isSettingsUpdated: state.Settings.isSettingsUpdated,
    }));
    const [userDetails, setUserDetails] = useState(userData)
    useEffect(() => {
        setUserDetails(userData)
    }, [userData])
    
    const paramsUrl = window?.location?.pathname
    const dynamicClass = paramsUrl === '/setting/integrations' ? 'settingTabComponent' : 'settingTabComponent1';
    const handleIntegration = async (data: any) => {
        dispatch(saveIntegration(data, (response: any) => {
            if (response.success) {
                addMixpanelEvent("Integrations", { distinct_id: userProfile?.userId, integrationName: response.integrationName })
                navigate("/integrations");
                monday.execute('valueCreatedForUser')
                setTimeout(() => {
                    const parentId = userProfile && userProfile?.parentId ? userProfile?.parentId : userProfile?.userId
                    dispatch(getIntegrations(parentId));
                }, 4000);
                setTimeout(() => {
                    if (settingButtonRef?.current && (response.integrationName).toLowerCase() !== "webhook") {
                        settingButtonRef.current.click();
                    }
                    // else if (webhookButtonRef?.current && (response.integrationName).toLowerCase() == "webhook") {
                    //     webhookButtonRef.current.click();
                    // }
                }, 5000);
            }
        }));
    };

    useEffect(() => {
        if(userProfile){
            const parentId = userProfile && userProfile?.parentId && userProfile?.parentId._id? userProfile?.parentId._id:userProfile?.parentId ? userProfile?.parentId : userProfile?.userId
            setUserDetails(userData)
            dispatch(getIntegrations(parentId));
            // dispatch(changeTab(TABS.INTEGRATIONS))
        }
    }, [userProfile])
    useEffect(() => {
        if (location.search) {
            setIsLoading(true)
            const queryString = location.search;
            const urlParams = new URLSearchParams(queryString);
            const json = Object.fromEntries(urlParams.entries());
            if (json.code) {
                handleIntegration(json);
            }
        }
    }, [location.search]);

    const onConnectClick = (name: string,data?:any, modifiedInputValue?: any) => {
        let userid = userProfile?.parentId && userProfile?.parentId?._id ? userProfile?.parentId?._id : userProfile?.parentId ? userProfile?.parentId : userProfile?.userId;
        let authUrl: string = "";
        if (name == "hubspot") {
            authUrl =
                'https://app.hubspot.com/oauth/authorize' +
                `?client_id=${encodeURIComponent(process.env.REACT_APP_HUBSPOT_CLIENT_ID ?? "")}` +
                `&scope=${encodeURIComponent(process.env.REACT_APP_HUBSPOT_SCOPES ?? "")}` +
                `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
                `&state=hubspot-${userid}`;
        }
        else if (name == "monday") {
            authUrl =
            'https://auth.monday.com/oauth2/authorize' +
            `?&client_id=${encodeURIComponent(process.env.REACT_APP_MONDAY_CLIENT_ID ?? "")}` +
            `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
            `&state=monday-${userid}`;
        }else if (name == "bitrix") {
            authUrl =modifiedInputValue +
            `/oauth/authorize?client_id=${encodeURIComponent(process.env.REACT_APP_BITRIX_CLIENT_ID ?? "")}` +
            `&response_type=code` +
            `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
            `&state=bitrix-${userid}`;
        } else if (name == "zoho") {
            authUrl =
                'https://accounts.zoho.com/oauth/v2/auth' +
                `?client_id=${encodeURIComponent(process.env.REACT_APP_ZOHO_CLIENT_ID ?? "")}` +
                `&scope=${encodeURIComponent(process.env.REACT_APP_ZOHO_SCOPES ?? "")}` +
                `&response_type=code&access_type=offline` +
                `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
                `&state=zoho-${userid}`;
        } else if (name == "pipedrive") {
            authUrl =
                'https://oauth.pipedrive.com/oauth/authorize' +
                `?client_id=${encodeURIComponent(process.env.REACT_APP_PIPEDRIVE_CLIENT_ID ?? "")}` +
                `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
                `&state=pipedrive-${userid}`;
        }else if (name == "salesforce") {
            authUrl = modifiedInputValue +
            `/services/oauth2/authorize?response_type=code` +
            `&client_id=${encodeURIComponent(process.env.REACT_APP_SALESFORCE_CLIENT_ID ?? "")}` +
            `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
            `&state=salesforce-${userid}`+`-${modifiedInputValue}`;
        } else if (name == "slack") {
            authUrl =
                'https://slack.com/oauth/v2/authorize' +
                `?client_id=${encodeURIComponent(process.env.REACT_APP_SLACK_CLIENT_ID ?? "")}` +
                `&scope=${encodeURIComponent(process.env.REACT_APP_SLACK_SCOPES ?? "")}` +
                `&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_FRONT_URL ?? ""}/integrations`)}` +
                `&state=slack-${userid}`;
        }else if(name == "webhook"){
            handleIntegration(data)
        }
        if(authUrl !== "")window.open(authUrl, "_self");
    }

    const integrationExist = (name: string) => {
        let integrationchecked: any;
        integrationchecked = integrationsList?.findIndex((item: any) => item.name == name)
        return integrationchecked != -1;
    }
    const removeIntegration = (integrationName: string) => {
        const userId = userProfile?.parentId && userProfile?.parentId?._id ? userProfile?.parentId?._id : userProfile?.parentId ? userProfile?.parentId : userProfile?.userId
        dispatch(removeIntegraation(userId, { userId, integrationName }));
    }

const availableOnRequestClick = (data:any) => {
    const payLoadData= {
        integrationName: data.name
    }
    dispatch(channelCreate(payLoadData))
}
    const integrationButton = (data: any) => {
        if(data.status == "request"){
            return (<Button onClick={() => availableOnRequestClick(data)}>Contact Us</Button> )
        }else if (data.name == "webhook"){
            return (<WebhookConnectPopup webhookIntegrationSave={onConnectClick}/>)
        }else if(data.name == "chromeExtension"){
            return (<Button tag={"a"} target='_blank' href="https://chrome.google.com/webstore/detail/controlhippo-whatsapp-cha/pkbaicalbphfonnjnomnlfdepbpocdfn" className='btn btn-primary'>Add to chrome</Button>)
        }else {
            return (<ConnectButton integrationData={data} onConnectClick={onConnectClick} />)
        }
    }

    const updateIntegrations = (name: string,data?:any) => {
        let parentId = userProfile?.parentId && userProfile?.parentId?._id ? userProfile?.parentId?._id : userProfile?.parentId ? userProfile?.parentId : userProfile?.userId
        dispatch(updateIntegration(name,data,parentId))
    }

    const onChangeData = (field: string, value: any) => {
        if(field == "privacy"){
            let privacy = userDetails.privacy
            privacy = {
                ...privacy,
                ...value
            };
            dispatch(updateSettings(field, privacy));
        }
    };
    useEffect(() => {
        if(isSettingsUpdated){
          showSuccessNotification({message:"Setting successfully updated"})
          dispatch(clearSettingsState());
          dispatch(getClientsList());
        }    
      }, [isSettingsUpdated]);
    const integrationElement = (data: Integration, key: string | number) => {
        return (
            <Col key={key} md={4} lg={3} xl={2} sm={12} className='integrationCardMain'>
                <Card className="mb-0 mt-1 integration_card">
                    <Card.Body className="pt-1 pb-3">
                        {data.status === "integrate" && data.name !== "chromeExtension" && (
                            <div>
                                <Link
                                    className="setUpGuide"
                                    id={`setUpGuide-${key}`}
                                    to={data?.setUpGuide}
                                    target="_blank"
                                >
                                    <i className="bx bxs-info-circle"></i>
                                </Link>
                                <UncontrolledTooltip
                                    className="setUpGuide1"
                                    target={`setUpGuide-${key}`}
                                    placement="right"
                                >
                                    Setup guide
                                </UncontrolledTooltip>
                            </div>
                        )}
                        {/* {data.name == "slack" && integrationExist(data.name) && <SettingPopupButton slackData={integrationsList.filter((integration: any) => integration.name == data.name)[0]} onConnectClick={onConnectClick} />} */}
                        { integrationExist(data.name) && <SettingPopupButton buttonRef={settingButtonRef} userDetails={userDetails} integrationName={data.name} integrationData ={integrationsList.filter((integration: any) => integration.name == data.name)[0]} onChangeSettings={onChangeData} changeIntegrationSetting={updateIntegrations} onConnectClick={onConnectClick} />}
                        {data.name == "webhook" && integrationExist(data.name) && <WebhookConnectPopup buttonRef={webhookButtonRef} isEdited={true} webhookIntegrationSave={updateIntegrations} data={integrationsList.filter((integration: any) => integration.name == data.name)[0]}/>}
                        <div className="text-center mt-3">
                            <img src={data.iconSrc} alt="" height={64} width={64} />
                        </div>
                        <div className="text-center mt-3">
                            <h5 className="conversation-name my-3">{data.displayName}</h5>
                            <div className="divForIntegrationBtnCtnr">
                                {integrationExist(data.name) ?
                                    <DynamicButton
                                        name={data.name}
                                        removeIntegration={removeIntegration}
                                    />
                                    :integrationButton(data)
                                }
                            </div>
                        </div>
                    </Card.Body>
                </Card >
            </Col>)
    }

    const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;
    return (
        // <React.Fragment>
        <IntegrationWrapper className={`user-chat user-chat-show ${dynamicClass}`}>
            <Card className='p-0 integrationMainCardCntnr mb-0'>
                <Card.Header className='px-4 py-3'>
                    <h2 className="mb-1 font-size-18 text-dark">Integrations</h2>
                    <p className='mb-0 page_discription'>
                        Unlock Your Data's Potential: Integrate, Optimize, and Thrive with Powerful Apps & Integrations!
                        {/* <a href='' className='ms-1 text-decoration-underline' target='_blank'>Need Help ?</a> */}
                    </p>
                </Card.Header>
                <Card.Body className='px-4 py-3'>
                    <div className="integration_main">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <Row>
                                    {Object.values(integrationsData).map((data, key) => integrationElement(data, key))}
                                </Row>
                                <Row className='mt-1'>
                                <h5 className="conversation-name mt-3 mb-2 font-size-18">Other Integrations</h5>
                                    {Object.values(availableIntegration).map((data, key) => integrationElement(data, key))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </IntegrationWrapper>
    )
}

export default Index