import { ContactsActionTypes } from "./types";

// common success
export const contactsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContactsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const contactsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: ContactsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContacts = (filters: any , callback:(response:any)=>void) => ({
  type: ContactsActionTypes.GET_CONTACTS,
  payload: filters,
  callback
});

export const importContacts = (data: any,clientId:string) => ({
  type: ContactsActionTypes.IMPORT_CONTACTS,
  payload: {data,clientId},
});
export const inviteContact = (data: any,clientId:string) => ({
  type: ContactsActionTypes.INVITE_CONTACT,
  payload: {data,clientId},
});



export const resetContacts = (flag: string, value: any) => ({
  type: ContactsActionTypes.RESET_CONTACTS,
  payload: { flag, value },
});
