import styled from 'styled-components';
import ArrowDown from '../../../assets/images/icons/arrow_down.svg'

const UserProfileDetails = styled.div`
  .userProfileDesc{
    @media (max-width:991.98){
      height:calc(100vh - 192px) !important;
    }
    .simplebar-content{
      @media (max-width:991.98px){
        height:100% !important;
      }
    }
  }
  .lableProf{
    margin-bottom: 3px;
    display:block !important;
    color:#63696F !important;
  }
  .align_colon_content{
    // min-width: 116px;
    margin-bottom: 0px;
    margin-right: 8px;
    display: flex;
    // justify-content: space-between !important;
    font-size:12px !important;
    color:#63696F !important;
    #synchronizeLink{
      i{
        color:#686868 !important;
      }
      &:hover{
        i{
          color:#0029c7 !important;
        }
      }
    }
  }
  .synchronizeToCRMCls:checked{
    background-color:#0029c7 !important;
  }
  .userProfileImgMain{
    // border: 1px solid #e8e8e8 !important;
    margin-top:10px;
    .userProfileNameTab{
      background-color: white;
      // border-top: 1px solid #e8e8e8 !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .userProfilePhoneNumber {
    font-size: 13px;
    color: #000000;
    line-height: 19.5px;
    font-weight: 500;
    padding-bottom: 0.5rem !important;
  }
  .profileEditBtn{
    border:1px solid #bccaff;
  }
  .inputBorder{
    border: 1px solid #EAEAF1 !important;
    border-radius: 4px !important;
    color: #000 !important;
  }
  .profileAssignChatDrpDwn{  
      .dropdown-toggle{
        position: relative !important;
        padding: 6px 18px 6px 10px !important;
        min-height: 33px !important;
        max-width: 100% !important;
        width: 100% !important;
        text-align: left !important;
        font-size: 13px !important;
        border-color: #0029C7 !important;
        color: #0029C7 !important;
        background-color: #fff !important;
        &:after{
          position: absolute !important;
          content: "";
          top: 7px;
          right: 1px;
          background-image: url(${ArrowDown});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center !important;
          display: block;
          height: 18px !important;
          width: 18px !important;
          filter: invert(60%) sepia(162%) saturate(3022%) hue-rotate(206deg) brightness(70%) contrast(95%) !important;
        }
      }
      .unAssinedUsrSelected.dropdown-toggle{
        color: #000 !important;
        border-color: #EAEAF1 !important;
        &:after{
          filter: invert(61%) sepia(5%) saturate(7%) hue-rotate(2deg) brightness(94%) contrast(94%) !important;
        }
      }
      .dropdown-menu {
        max-height: 142px !important;
        max-width: 100% !important;
        overflow-y: auto !important;
        padding: 0 !important;
        .dropdown-item{
          &:hover{
            background-color: #EBEFFF !important;
          }
        }
      }
      .dropdown-menu.show {
        transform: translate3d(2px, 35px, 0px) !important;
        @media (max-width:480px){
          transform: translate3d(-24px, 35px, 0px) !important;
        }
      }
  } 
  .profileLableDropDwn{
    .css-3w2yfm-ValueContainer{
      padding-right: 0px !important;
      max-height: 100px !important;
      overflow-y: auto;
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        outline: unset!important;
        border-radius: 14px;
      }
      &::-webkit-scrollbar-track {
          background: #f1f1f1!important;
      }
      &::-webkit-scrollbar {
        width: 0.4em;
      }
    }
    .css-wsp0cs-MultiValueGeneric {
      font-size: 70% !important;
      max-width: 58px !important;
    }
    .css-1xc3v61-indicatorContainer{
      padding: 4px !important;
    }
    .css-13cymwt-control{
      border-color: #EAEAF1 !important;
    }
    .css-1hb7zxy-IndicatorsContainer{
      >div:first-child{
        display: none !important;
      }
    }
    .css-1xc3v61-indicatorContainer{
      svg{
        height: 16px !important;
        width: 16px !important;
      }
    }
    .css-1jqq78o-placeholder{
      font-size: 13px !important;
      line-height: normal !important;
      text-align: left !important;
    }
    .css-1nmdiq5-menu{
      top: 31px !important;
      .css-1n6sfyn-MenuList{
        padding:0 !important;
        max-height:154px !important;
        &::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
          outline: unset!important;
          border-radius: 14px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1!important;
        }
        &::-webkit-scrollbar {
          width: 0.4em;
        }
      }
      .css-1n6sfyn-MenuList > div{
        font-size: 13px !important;
        text-align: left !important;
        padding: 6px 10px !important;
      }
    }
  }
  .addParticipantsBtn{
    font-size: 12px !important;
    &:hover{
      color:#fff !important;
      border-color: #797c8c !important;
      background-color: #797c8c !important;
    }
  }
  .demo-loadmore-list{
    .ant-list-item-meta-title{
      font-size: 12px !important;
    }
    .ant-badge-status-text{
      font-size: 12px !important;
    }
  }
`;
export { UserProfileDetails };