import React, { useEffect, useState } from 'react';
import { useRedux, useProfile } from '../../hooks';
import { Card, Dropdown, Table } from 'react-bootstrap';
import CustomPagination from '../../components/Pagination';
import DateRangePickerComponent from '../../components/DateRangePicker';
import { getReportChannelList, getReports } from '../../redux/actions';
import whatsappLogo from "../../assets/images/whatsapp_logo.png";
import whatsappBusinessLogo from "../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../assets/images/channelListIcon/telegram_icon.png";
import messengerLogo from "../../assets/images/messenger_1.jpg";
import instagramLogo from "../../assets/images/Instagram_icon.webp";
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style';
import { checkPlanAndFeature } from '../../helpers/common.service';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

const Index = () => {
    const { dispatch, useAppSelector } = useRedux();
    const [reportsDetails, setReportsDetails] = useState<any[]>([]);
    const [clients, setClients] = useState<[any]>([{}])
    const { userProfile } = useProfile();

    const {
        reportsData,
        reportList
    } = useAppSelector((state: any) => ({
        reportList: state.Chats.reportList,
        userData: state.Login.user,
        reportsData: state.Reports.reportsData,
    }));
    const [selectedValue, setSelectedValue] = useState<any>();

    useEffect(() => {
        setClients(reportList)
        dispatch(getReportChannelList("dropdown"))
    }, [])

    useEffect(() => {
      if(reportList?.length > 0) {
        setSelectedValue(reportList[0]?.clientId)
      }
    }, [reportList])
    
    useEffect(() => {
        setClients(reportList)
    }, [reportList])
    const initialDate = new Date();
    initialDate.setHours(0, 0, 0);
    const initialEndDate = new Date();
    // initialEndDate.setHours(23, 59, 59);

    const [selectedRange, setSelectedRange] = useState<{
        startDate: Date;
        endDate: Date;
    }>({
        startDate: initialDate,
        endDate: initialEndDate,
    });
    const [hasCheckedPlanAndFeature, setHasCheckedPlanAndFeature] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (checkPlanAndFeature({ featureName: "reports", planDetails: userProfile?.planDetails, featureDisplayName: "Reports" })) {
            setHasCheckedPlanAndFeature(true);
        }
    }, [currentPage]);


    useEffect(() => {
        if (hasCheckedPlanAndFeature) {
            if (selectedValue !== "All Numbers") {
                const clientId = selectedValue
                dispatch(getReports({ type: "Analitycs", selectedRange, page: currentPage, clientId: clientId && clientId }));
            } else {
                if (reportList?.length === 1) {
                    dispatch(getReports({ type: "Analitycs", selectedRange, page: currentPage, clientId: userProfile?.clientId }));
                } else {
                    dispatch(getReports({ type: "Analitycs", selectedRange, page: currentPage, clientId: "All Numbers" }));
                }
            }
        }
    }, [currentPage, selectedRange, selectedValue, hasCheckedPlanAndFeature])
    
    useEffect(() => {
        if (reportsData?.success && hasCheckedPlanAndFeature) {
            setReportsDetails(reportsData.data);
        }
    }, [reportsData, userProfile, hasCheckedPlanAndFeature]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const itemsOnPage = reportsDetails.slice(startIndex, endIndex);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleDateRangeChange = (ranges: any) => {
        let [startDate, endDate] = ranges
        if (startDate && endDate) {
            startDate = moment(new Date(startDate)).format()
            endDate = moment(new Date(endDate)).format()
            setSelectedRange({
                startDate: startDate,
                endDate: endDate,
            });
        }
    };

    const handleInputChange = (value: any) => {
        setSelectedValue(value);
    };
    const getName = (reportList: any, selectedValue: string) => {
        let listName = reportList?.findIndex((item: any) => (item.clientId === selectedValue))
        let data;
        if (listName !== -1) {
            data = (
                <>
                    <div className='d-flex align-items-center'>
                        <span className='me-1 mb-1'>
                            {reportList[listName]?.type === "whatsapp" ? 
                                <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                                :reportList[listName]?.type === "business_whatsapp" ? <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' />
                                : reportList[listName]?.type === "telegram" && <img src={telegramLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                            }
                        </span>
                        <span className="text-truncate wpLinkedUserName me-1">
                            {reportList[listName]?.pushname}
                        </span>
                        <span className="text-muted wpLinkedUserNumber">
                            ( +{reportList[listName]?.me?.user} )
                        </span>
                    </div>
                </>
            )
        }else{
            data = (
                <>
                    <div className='d-flex align-items-center'>
                        <span className="text-truncate wpLinkedUserName me-1">
                            {selectedValue}
                        </span>
                    </div>
                </>
            )
        }
        return data
    }
    
    return (
        <TableListingPagesWrapper>
            <div className='user-chat overflow-hidden w-100 tableListingMain h-100'>
                {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb justify-content-between">
                    <h5 className="mb-0">Reports</h5>
                    <div className='d-flex align-items-center gap-2'>
                        <Dropdown className='reportUserDropdown' onSelect={handleInputChange}>
                            <Dropdown.Toggle variant="success" className='text-truncate bg-white w-100 text-dark' id="dropdown-basic">
                                {getName(clients, selectedValue)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                <span className='dotactive'></span> 
                                <Dropdown.Item key={"Selcet"} eventKey={"All Numbers"} className={"custom-option"}> All Numbers </Dropdown.Item>
                                {(clients || []).map((item: any) => (
                                    <Dropdown.Item key={item.clientId} eventKey={item.clientId}  className={"custom-option"}>
                                        <div className='d-flex align-items-center'>
                                            <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                                {!item?.isWhatsappBusiness ?
                                                    <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                                                    : <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' />
                                                }
                                            </span>
                                            <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline " : "dropItemUserNameOffline"} me-1`}>
                                                {item?.pushname}
                                            </span>

                                            <span className="text-muted dropItemUserNumber">
                                                ( +{item.me?.user} )
                                            </span>
                                        </div>
                                    </Dropdown.Item>
                                ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="ctrlhDatePicker">
                            <DateRangePickerComponent onChange={handleDateRangeChange} />
                            <i className='datePickerIcon'></i>
                        </div>
                    </div>
                </Card> */}
                <div className='userListCardMain h-100'>
                    <Card className='userListCard reportListCard h-100'>
                    <Card.Header>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <h2 className="mb-1">Reports (Beta)</h2>
                                <p className='mb-0'>Access Comprehensive User-Specific Analytical Data Here.</p>
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                <Dropdown className='reportUserDropdown' onSelect={handleInputChange}>
                                    <Dropdown.Toggle variant="success" className='text-truncate bg-white w-100 text-dark' id="dropdown-basic">
                                        {getName(clients, selectedValue)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="w-100">
                                        {/* <span className='dotactive'></span> */}
                                        <Dropdown.Item key={"Selcet"} eventKey={"All Numbers"} className={"custom-option"}> All Numbers </Dropdown.Item>
                                        {(clients || []).map((item: any) => (
                                            <Dropdown.Item key={item.clientId} eventKey={item.clientId}  className={"custom-option"}>
                                                <div className='d-flex align-items-center'>
                                                    <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                                        {item?.type === "whatsapp" ? (
                                                            <img src={whatsappLogo} width={16} height={16} alt="controllhippo Whatsapp Logo" />
                                                        ) : item?.type === "business_whatsapp" ? (
                                                            <img src={whatsappBusinessLogo} width={16} height={16} alt="controllhippo Whatsapp Business Logo" />
                                                        ) : item?.type === "telegram" ? (
                                                            <img src={telegramLogo} width={16} height={16} alt="controllhippo Telegram Logo" />
                                                        ) : item?.type === "messenger" ? (
                                                            <img src={messengerLogo} width={16} height={16} alt="controllhippo Messenger Logo" />
                                                        ) : item?.type === "instagram" ? (
                                                            <img src={instagramLogo} width={16} height={16} alt="controllhippo Instagram Logo" />
                                                        ) : null}
                                                    </span>
                                                    <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline " : "dropItemUserNameOffline"} me-1`}>
                                                        {item?.pushname}
                                                    </span>

                                                    <span className="text-muted dropItemUserNumber">
                                                        ( +{item.me?.user} )
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                        ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="ctrlhDatePicker">
                                    <DateRangePickerComponent onChange={handleDateRangeChange} ranges={selectedRange}/>
                                    {/* <i className='datePickerIcon'></i> */}
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <div className='h-100'>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#</th>
                                            <th className='text-left'>User Name</th>
                                            <th className='text-left'>Email</th>
                                            <th className='text-center d-lg-flex align-items-center gap-2'>First Avg. Response Time
                                                    {"    "}
                                                    <i className="bx bxs-info-circle" id='avgResponseTime'></i>
                                                    <UncontrolledTooltip
                                                        className="setUpGuide1"
                                                        target={`avgResponseTime`}
                                                        placement="right"
                                                    >
                                                        Track how long it takes users to first respond to new chats on average. 
                                                    </UncontrolledTooltip>
                                                {/* <div className=''>
                                                </div> */}
                                            </th>
                                            <th className='text-center'>Count of New Chats</th>
                                            <th className='text-center'>Total Message Sent</th>
                                            {/* <th>Total Message Recived</th> */}
                                            <th className='text-center'>Total Assign Chats</th>
                                            {/* <th>Total Chats Resolved</th> */}
                                            <th className='text-center'>Open Chats</th>
                                            <th className='text-center'>Resolved Chats</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(itemsOnPage || []).map((item: any, index: number) => (
                                            <tr className='text-left' key={index}>
                                                <td className='text-center'>{index + 1 + startIndex}</td>
                                                <td className='text-left'>{item.fullName}</td>
                                                <td className='text-left'>{item.email}</td>
                                                <td className='text-center'>{item.averageChatResponseTime}</td>
                                                <td className='text-center'>{item.newChats}</td>
                                                <td className='text-center'>{item.totalMessagesSent}</td>
                                                {/* <td>{item.totalMessagesReceived}</td> */}
                                                <td className='text-center'>{item.totalAssignedChats}</td>
                                                {/* <td>{item.totalChatsResolved}</td> */}
                                                <td className='text-center'>{item.totalOpenChats}</td>
                                                <td className='text-center'>{item.totalResolve}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="m-2">
                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(reportsDetails.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                    className={'pagination-rounded justify-content-end'}
                                />
                            </div>
                        </div>
                    </Card.Body>
                    </Card>
                </div>
            </div>
        </TableListingPagesWrapper>
    );
};

export default Index;
