import slackIcon from "../assets/images/slack.png";
import webhooksIcon  from "../assets/images/webhooks.svg";

import zendeskIcon from "../assets/images/availableOnReqInt/Zendesk.svg"
import MondayIcon from "../assets/images/availableOnReqInt/Monday.svg"
import googleSheetIcon from "../assets/images/availableOnReqInt/google_sheets.svg"
import shopifyIcon from "../assets/images/availableOnReqInt/Shopify.svg"
import wooCommerceIcon from "../assets/images/availableOnReqInt/Woo_Commerce.svg"

interface Channel {
  displayName: string;
  name: string;
  category: string;
  assignNumber: string[];
  status: string;
  type: string;
  setting: boolean;
  assignSetting: boolean;
  iconSrc: string;
  setUpGuide: string;
  getHelp: string;
  popupData: {
    logo: string;
  };
  btnText: string;
  showSettingModal: boolean;
  showDetailModal: boolean;
  showNewSettingModal: boolean;
  iconValue: string;
  isPopular: boolean;
}
interface AvailbleOnReqInt {
  [key: string]: Channel;
}
export type { AvailbleOnReqInt, Channel };

let availbleOnReqInt: AvailbleOnReqInt = {
  "zendesk": {
    "displayName": "Zendesk",
    "name": "zendesk",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "request",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": zendeskIcon,
    "setUpGuide": "https://drive.google.com/file/d/1H-Ma1h64REJZpAIF3Lcub1ers6n65VwY/view?usp=sharing",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "googleSheet": {
    "displayName": "Google Sheet",
    "name": "googleSheet",
    "category": "Marketing Automation",
    "assignNumber": [],
    "status": "request",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": googleSheetIcon,
    "setUpGuide": "https://drive.google.com/file/d/1ReAcj_UHNOhEkhcK4XJxlb3xqLAxssto/view?usp=sharing",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
      "logo": "hubspotpopup"
    },
    "btnText": "Integrated",
    "showSettingModal": false,
    "showDetailModal": false,
    "showNewSettingModal": false,
    "iconValue": "check_circle",
    "isPopular": true
  },
  "shopify": {
    "displayName": "Shopify",
    "name": "shopify",
    "category" : "Communication",
    "assignNumber": [],
    "status": "request",
    "type": "native",
    "setting": true,
    "assignSetting": true,
    "iconSrc": shopifyIcon,
    "setUpGuide": "https://drive.google.com/file/d/1lW8LdMX1k5wLfAqvF8usC9GuntsEqV7B/view?usp=sharing",
    "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
    "popupData": {
        "logo": slackIcon
    },
    "btnText" : "Integrated",
    "showSettingModal": false,
    "showDetailModal" : false,
    "showNewSettingModal" : false,
    "iconValue" : "check_circle",
    "isPopular" : true
},"wooCommerce": {
  "displayName": "Woo Commerce",
  "name": "wooCommerce",
  "category" : "Communication",
  "assignNumber": [],
  "status": "request",
  "type": "other",
  "setting": true,
  "assignSetting": true,
  "iconSrc": wooCommerceIcon,
  "setUpGuide": "https://drive.google.com/file/d/1lW8LdMX1k5wLfAqvF8usC9GuntsEqV7B/view?usp=sharing",
  "getHelp": "https://calendly.com/callhippo/callhippo-product-demo?utm_source=demobar",
  "popupData": {
      "logo": webhooksIcon
  },
  "btnText" : "Integrated",
  "showSettingModal": false,
  "showDetailModal" : false,
  "showNewSettingModal" : false,
  "iconValue" : "check_circle",
  "isPopular" : true
}
};

export { availbleOnReqInt };
