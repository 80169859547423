import React, { Component } from 'react';
import { UserOutlined, LockOutlined, CreditCardOutlined, BellOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link, Route, Navigate, useLocation, Routes } from 'react-router-dom';
import InnerAppLayout from '../../layouts/InnerLayout';
import IntegrationsPage from "../../pages/Integrations/index";
import Users from "../../pages/Users/userList";
import Numbers from "../../pages/Numbers/index";
import AccountSetting from '../../pages/AccountSetting';
import PlanBilling from '../../pages/PlanBilling';
import PersonalInformation from '../../pages/Profile/PersonalInformation';
import ChangePassword from '../../pages/Profile/ChangePassword';
import { UserProfileComponent } from "../../pages/Profile/UserProfileComponent.style";

import multiple_users from '../../assets/images/icons/multiple_users.svg'; 
import channelIcon from '../../assets/images/icons/qr_code_scaning.svg';
import integrationIcon from '../../assets/images/icons/integration_icon.svg';
import accountSetting from '../../assets/images/icons/account_setting_icon.svg';
import planBilling from '../../assets/images/icons/plan_billing_icon.svg';
import personalInfoIcon from '../../assets/images/icons/personal_info.svg';
import changPasswordIcon from '../../assets/images/icons/change_password.svg';


interface MenuItemProps {
  icon: React.ReactNode;
  path: string;
  label: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, path, label }) => {
  return (
    <>
      {icon}
      <span>{label}</span>
      <Link to={`/setting/${path}`} />
    </>
  );
};

const SettingOption: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname.split('/');
  const currentPath = locationPath[locationPath.length - 1];

  return (
    <div className='sidebarMenuAnchor'>
    <Menu
      mode="inline"
      selectedKeys={[currentPath]}
      items={[
        {
          key: 'yourpreferences',
          label: <div className='nonClickableText'>Your Preferences</div>,
          disabled: true,
        },
        {
          key: 'personal-information',
          label: <MenuItem label="Personal Info" 
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={personalInfoIcon} width="14" height="14" /></span>}
          path="personal-information" />
        },
        {
          key: 'change-password',
          label: <MenuItem label="Change Password"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={changPasswordIcon} width="16" height="16" /></span>}
          path="change-password" />
        },
        {
          key: 'accountmanagement',
          label: <div className='nonClickableText'>Account Management</div>,
          disabled: true,
        },
        {
          key: 'user-list',
          label: <MenuItem label="Users"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={multiple_users} width="18" height="18" /></span>}
          path="user-list" />
        },
        {
          key: 'numbers',
          label: <MenuItem label="Channels"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={channelIcon} width="18" height="18" /></span>}
          path="numbers" />
        },
        {
          key: 'integrations',
          label: <MenuItem label="Integrations"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={integrationIcon} width="18" height="18" /></span>}
          path="integrations" />
        },
        {
          key: 'workspacesetting',
          label: <div className='nonClickableText'>Workspace Setting</div>,
          disabled: true,
        },
        {
          key: 'account-setting',
          label: <MenuItem label="Account Setting"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={accountSetting} width="18" height="18" /></span>}
          path="account-setting" />
        },
        {
          key: 'plan-billing',
          label: <MenuItem label="Plan and billing"
          icon={<span className="moduleSidebarSpan"><img className="multiple-user-icon" src={planBilling} width="16" height="16" /></span>}
          path="plan-billing" />
        },
      ]}
    />
    </div>
  );
};


const SettingContent: React.FC = () => {
  return (
    <Routes>
      <Route path="personal-information" element={<PersonalInformation />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="integrations" element={<IntegrationsPage />} />
      <Route path="user-list" element={<Users />} />
      <Route path="numbers" element={<Numbers />} />
      <Route path="account-setting" element={<AccountSetting />} />
      <Route path="plan-billing" element={<PlanBilling />} />
      <Route path="*" element={<Navigate to="personal-information" replace />} />
    </Routes>
  );
};

export class Setting extends Component {
  render() {
    return (
      <UserProfileComponent>
      <InnerAppLayout
        sideContentWidth={320}
        sideContent={<SettingOption />}
        mainContent={<SettingContent />}
      />
      </UserProfileComponent>
    );
  }
}

export default Setting;