import React from "react"
import { checkEstimateCost } from "../redux/actions"
import { showErrorNotification } from "./notifications";
interface UpdateObjectType {
    subscription_items: { item_price_id: string; quantity: number }[];
    eventType:string;
    data:any,
    itemName?:string;
}
const checkEstimate = ({ item, eventType, name, userDetails, dispatch, checkedAddonItems }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        const userId = userDetails?.userId ? userDetails?.userId :userDetails?._id 
        let data: UpdateObjectType = {
            eventType: eventType,
            data: {},
            subscription_items: [],
        }
        let itemName;
        if (checkedAddonItems) {
            checkedAddonItems.map((item: any) => {
                let period = userDetails?.chargebeeDetails?.billing_period_unit == "month" ? "Monthly" : "Yearly";
                let units = userDetails?.chargebeeDetails?.billing_period_unit?.toUpperCase();
                let priceId = `${item.name}-${userDetails?.chargebeeDetails?.currency_code?.toUpperCase()}-${period}`;
                const updateObject = {
                    item_price_id: priceId,
                    quantity: 1,
                };
                data.subscription_items.push(updateObject);
            })
        } if (item == "addon" && name) {
            const addons = userDetails?.allAddons;
            const addon: any = (addons || []).find((item: any) => item.name.includes(name));
            const price_currency = userDetails?.chargebeeDetails?.currency_code;
            var billing_period_unit = userDetails?.chargebeeDetails?.billing_period_unit == "month" ? "Monthly" : "Yearly";
            if (userDetails?.chargebeeDetails?.plan_name?.includes("3-months")) {
                billing_period_unit = 'Every-3-months'
            } else if (userDetails?.chargebeeDetails?.plan_name?.includes("6-months")) {
                billing_period_unit = 'Every-6-months'
            }
            itemName = `${addon?.name}-${price_currency?.toUpperCase()}-${billing_period_unit}`;
            data.data = {
                item_price_id: itemName,
                quantity: 1
            }
            data.itemName = item
        } else if (item == "plan" && name) {
            const plans = userDetails?.allPlans;
            const plan: any = (plans || []).find((item: any) => item.name.includes(name.split("-")[0]));
            const price_currency = userDetails?.chargebeeDetails?.currency_code;
            var billing_period_unit = userDetails?.chargebeeDetails?.billing_period_unit == "month" ? "Monthly" : "Yearly";
            if (userDetails?.chargebeeDetails?.plan_name?.includes("3-months")) {
                billing_period_unit = 'Every-3-months'
            } else if (userDetails.chargebeeDetails.plan_name.includes("6-months")) {
                billing_period_unit = 'Every-6-months'
            }
            itemName = `${plan.name}-${price_currency.toUpperCase()}-${billing_period_unit}`;
            itemName = name;
            data.data = {
                item_price_id: itemName,
                quantity: 1
            }
            data.itemName = item
        }
        dispatch(checkEstimateCost(data, userId, async (response: any) => {
            response.data = data
            if (response?.success) {
                if (response?.result) {
                    const { next_invoice_estimate, invoice_estimate } = response?.result
                    if (invoice_estimate && response?.result) {
                        const { total, currency_code } = invoice_estimate ? invoice_estimate : null
                        const currency_sign = userDetails?.planDetails && (userDetails?.planDetails?.price_currency).find(async (item: any) => item?.currency.toLowerCase() == currency_code.toLowerCase())?.currency_sign
                        const proratedCharge = total / 100
                        const text = `<p>The charge to add this new addition is ${currency_sign}${proratedCharge}. Please confirm the payment to proceed.</p>`;
                        const result: any = {
                            "success": true,
                            message: text,
                            currency_sign,
                            proratedCharge
                        }
                        return await resolve(result)
                    } else if (next_invoice_estimate) {
                        const { total,currency_code } = next_invoice_estimate ? next_invoice_estimate : null

                        const currency_sign = userDetails?.planDetails && (userDetails?.planDetails?.price_currency).find(async (item: any) => item?.currency.toLowerCase() == currency_code.toLowerCase())?.currency_sign
                        const text = `<p>The charge to add this new addition is ${currency_sign}${0}. Please confirm the payment to proceed.</p>`;
                        const result: any = {
                            "success": true,
                            message: text,
                            currency_sign,
                            proratedCharge :0
                        }
                        return await resolve(result)
                    }
                }
                const result: any = {
                    "success": true,
                    currency_sign:"$",
                    proratedCharge :0
                }
                return await resolve(result)
            } else {
                if(response?.message)showErrorNotification(response)
                const result: any = {
                    "success": false,
                    currency_sign:"$",
                    proratedCharge :0
                }
                await resolve(result)
            }
        }))
    })
}

const quantityCheck = async(userDetails:any,from:string | undefined) => {
    return new Promise<void|any>(async(resolve, reject) => {
        try {
            let addonName = from === "WABA" ? "waba" : "scan_whatsapp_number"
            const chargebeeDetails = userDetails?.chargebeeDetails;
            const addon = await (chargebeeDetails?.addons || []).find((item:any) => (item?.item_price_id.includes(addonName) && item.item_type == "addon"))
            let addonQuantity = addon?.quantity ? addon?.quantity : 0;
            let subscriptionQuantity = chargebeeDetails?.subscription_quantity ? chargebeeDetails?.subscription_quantity : 0;
            let scanNumber = await (userDetails?.numberDetails||[]).filter((item:any)=>((addonName=="waba" && item.type === "business_whatsapp")||(addonName !=="waba" && item.type !== "business_whatsapp")));
            const totalQuantity = subscriptionQuantity + addonQuantity
            scanNumber = scanNumber?.length ? scanNumber?.length :0
            const quantityEsist =  totalQuantity > scanNumber
            resolve({totalQuantity:totalQuantity,scanNumber,quantityEsist})
        } catch (error) {
            console.log(`Error inside of checkEstimate.ts:quantityCheck function :`, error)
            resolve(null)
        }
    })
}
export { checkEstimate,quantityCheck };