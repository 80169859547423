import moment from "moment-timezone";
import { Days } from "./TimeZone";
const commonService :any =  {}
commonService.preparedDateAndTime = async ({ schedualDetails, scheduleType,timezone }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        const {enabled,interval,startDate,startTime ,day} = schedualDetails
        // const combinedDateTime = `${startDate} ${moment.utc(startTime,"HH:mm").tz(timezone)}`;
        // const combinedMoment = moment(combinedDateTime, 'DD/MM/YYYY HH:mm').utc();
        // const combinedMomentWithTimezone = combinedMoment.clone().tz(timezone);

        const timeWithUtc =  moment.utc(startTime,"HH:mm")
        const timeWithTimezone =  timeWithUtc.tz(timezone)
        const diffDays = moment(moment(timeWithUtc).format("DD/MM/YYYY"),"DD/MM/YYYY").diff(moment(moment(timeWithTimezone).format("DD/MM/YYYY"),"DD/MM/YYYY"), 'days');
        if (scheduleType == "intervals") {
            const findIndex = await Days.findIndex((item) => item.value == day)
            const date = moment.utc(startDate,"DD/MM/YYYY")
            if (diffDays == 1) {
                schedualDetails.startDate = date.set("date", date.get('date') - 1).format("DD/MM/YYYY")
            } else if (diffDays == -1) {
                schedualDetails.startDate = date.set("date", date.get('date') + 1).format("DD/MM/YYYY")
            }

            if (schedualDetails.interval == "weekly" && schedualDetails.day) {
                if (diffDays && findIndex == 0) {
                    schedualDetails.day = Days[1].value
                } else if (diffDays && findIndex != 0) {
                    if (diffDays == 1) {
                        const newIndex = findIndex - 1
                        schedualDetails.day = Days[newIndex]?.value
                    } else if (diffDays == -1) {
                        const newIndex = findIndex + 1
                        schedualDetails.day = Days[newIndex]?.value
                    }
                }
            }
        }
        schedualDetails.startTime = timeWithTimezone.format("HH:mm")
        return resolve(schedualDetails)
    })
}

const prepareGroupText = (text: string | undefined, mentionedIds: string[] | undefined, participants: any[]) => {
    return new Promise<void | any>(async (resolve, reject) => {
        try {
            let newText;
            if (mentionedIds?.length) {
                for (let index = 0; index < mentionedIds.length; index++) {
                  const element :any= mentionedIds[index];
                  const chatId  = element._serialized ? element._serialized : element; 
                  const mentionedData = await  (participants || []).find((item: any) => item.id.chatId === chatId);
                  if (mentionedData) {
                    const firstName = mentionedData.id.firstName;
                    const displayPhoneNumber = mentionedData.displayPhoneNumber;
                    newText =await (text || "").replace(new RegExp(`@${displayPhoneNumber.replace('@', '')}`, 'g'), `@${firstName ? firstName : displayPhoneNumber }`);
                  } else {
                      newText = text;
                  }
                }
            }else {
                newText = text;
            }
            return resolve(newText);
        } catch (error) {
            resolve(text)
        }
    })
}
export {
    prepareGroupText
}