import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Pagination, Card, Popconfirm, Modal } from 'antd';
import { Button, UncontrolledTooltip,Row,Col } from "reactstrap";
import type { ColumnsType } from 'antd/es/table';
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style';
import { DeleteOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";

import { useRedux, useProfile } from "../../hooks/index";
import { templateCreate, getTemplateList, templateDelete, getClientsList, getCampaignDetails, getCampaignList, campaignDelete } from '../../redux/actions';
import moment from 'moment-timezone';

import {
  useLinkClickHandler,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { checkPlanAndFeature } from '../../helpers/common.service';
// import editUser from './editUser';

interface Campaign {
  id?: number;
  fullName: string;
  email: string;
  userActive: boolean;
  Status: string;
  Availability: string;
}

const NoCampDataImg ="https://images.controlhippo.com/svg/nodata_campaign_excited.svg"

const Campaign: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const { dispatch, useAppSelector } = useRedux();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [authToken, setAuthToken] = useState();
  const { userProfile } = useProfile();
  const [campaigns, setCampaigns] = useState<[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  var time_zone = userProfile?.timezone ? userProfile?.timezone : process.env.DEFAULT_TIMEZONE

  const showModal = (__: any, data: any) => {
    setIsModalOpen(true);
    setDeleteId(__)
  };

  const handleOk = (deleteData: any) => {
    if (deleteData.parentId) {
      const deleteDataFunc = (deleteData: any) => {
        const payLoadData = {
          campaignId : deleteData?._id,
        }
        dispatch(campaignDelete(payLoadData))
      };
      deleteDataFunc(deleteData)
      setIsModalOpen(false);
    };
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    dispatch(getCampaignList());
  }, []);

  const { 
    getUserListData, 
    userDeleteSuccess, 
    getTemplateLists, 
    clientList, 
    whatsappUnverifiedList, 
    campaign_delete,
    campaignList,
    userDetails
  } = useAppSelector((state) => {
    return {
      getUserListData: state?.GetuserList?.profileDetails,
      userDeleteSuccess: state?.DeleteUser?.userDeleteSuccess,
      getTemplateLists: state?.Templates?.getTemplateList,
      campaign_delete: state?.Campaign?.campaign_delete,
      clientList: state.Chats?.clientList,
      whatsappUnverifiedList: state.Chats?.whatsappUnverifiedList,
      campaignList: state.Campaign?.campaignList,
      userDetails: state.Login?.User,
    };
  });
  const timeZone = userDetails?.timezone ? userDetails?.timezone : userProfile?.timezone;
  useEffect(() => {
    // setCampaigns(campaignList)
    dispatch(getClientsList());
}, []);

useEffect(() => {
  setCampaigns(campaignList)
}, [campaignList])

useEffect(() => {
  if(campaign_delete?.success){
    setTimeout(() => {
      dispatch(getCampaignList());
    }, 1000);
  }
}, [campaign_delete])

  
  
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    pageSize && setPageSize(pageSize);
  };

  const handleSelectionChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: campaigns?.length,
    onChange: handlePageChange,
    showSizeChanger: true,
    onShowSizeChange: handlePageChange,
  };
  let navigate = useNavigate();

  const CustomToast = () => (
    <div>
      Please configure your WhatsApp number first.{' '}
      <Link to="/welcome">Click Here</Link>
    </div>
  );

  const userAddHandleClick = () => {
    if (clientList?.length > 0 || whatsappUnverifiedList?.length > 0) {
      if(checkPlanAndFeature({featureName : "quick_reply_template", planDetails:userProfile?.planDetails ,featureDisplayName: "Quick Replies"})){
        navigate("/campaign-create")
      }
    } else {
      toast.info(<CustomToast />);
    }
  }

  // Slice the data based on the current page and page size
  const slicedData = campaigns?.length && campaigns?.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  );

  const columns: ColumnsType<Campaign> = [
    {
      title: 'Name',
      // align :"center",
      dataIndex: 'name',
    },
    {
      title: 'Interval',
      align :"center",
      render: (item: any) => {
        const scheduleType = item.scheduleType
        const interval = item.schedualDetails.interval
        const data :any = {
          now : "Once",
          intervals : "Send At Intervals",
          scheduled_date_time :"Once",
          daily :'Daily' , weekly:'Weekly', monthly:'Monthly'
        } 
        return <span>{item.scheduleType == "intervals" ? data[`${interval}`] :data[`${scheduleType}`]}</span>;
      },
    },
    {
      title: 'Schedule',
      align :"center",
      render: (item: any) => {
        const scheduleType = item?.scheduleType
        const interval = item?.schedualDetails?.interval
        const day = item?.schedualDetails?.orignalDay
        const startDate = item?.schedualDetails?.orignalDate
        const startTime = item?.schedualDetails?.orignalTime
        const data :any = {
          'monday' :'Monday' , 'tuesday':'Tuesday', 'wednesday' : 'Wednesday', 'thursday':'Thursday', 'friday':'Friday', 'saturday':'Saturday', 'sunday':'Sunday'
        } 
        const value = scheduleType == "intervals" && interval == "weekly" ? `${data[`${day}`]} ${startTime}` : scheduleType == "intervals" && interval == "daily" ? startTime:(startDate && startTime ) &&`${startDate} ${startTime}`; 
        return <span>{value}</span>;
      },
    },
    {
      title: 'Number',
      align :"center",
      dataIndex: 'fromNumber',
    },
    {
      title: 'Recipient',
      align :"center",
      dataIndex: 'contactCount',
      render: (item: any) => {
        return <span>{item} Contacts</span>;
      },
    },
    {
      title: 'Status',
      align :"center",
      // dataIndex: 'status',
      render: (__: any, index) => {
        return (<>
        <div className="d-flex align-items-center gap-1 font-size-14 text-dark lh-sm">
          {__.status}
          {__.status === "paused" ? <i className="bx bxs-info-circle font-size-16 lh-sm" id={`campaignStatus-${__._id}`}></i> :""} 
        </div>
        {__.status === "paused" ? <UncontrolledTooltip target={`campaignStatus-${__._id}`} placement="right">{__?.reason}</UncontrolledTooltip>:""}
        </>)
      }
    },
   
    {
      title: 'Action',
      align :"center",
      render: (__: any, index) => {
        if (__.parentId && userProfile?.userId !== __._id) {
          return (
            <>
              <div className='d-flex btn_spacing_ui text-center justify-content-center'>
                <div>
                  <Link
                    color="secondary"
                    id={`campaignView-${__._id}`}
                    className='btn btn-outline-secondary btnEdit d-flex align-items-center justify-content-center me-2'
                    to={`/campaign/${__._id}`}
                    state={{ itemIndex: index }}
                  >
                    <i className='mdi mdi-eye'></i>
                  </Link>
                  <UncontrolledTooltip target={`campaignView-${__._id}`} placement="right">View Campaign</UncontrolledTooltip>
                </div>
                <div>
                  {clientList.length > 0 ?
                      (__.status == "in-progress" || __.status == "completed" )? 
                      <button id={`campaignEdit-${__._id}`}
                      className='btn btn-outline-secondary btnEdit d-flex align-items-center justify-content-center me-2' 
                      disabled={(__.status == "in-progress" || __.status == "completed")}
                    >
                      <i className='mdi mdi-pencil'></i>
                    </button>
                      : <Link
                      color="primary"
                      id={`campaignEdit-${__._id}`}
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      to={`/campaign-edit/${__._id}`}
                      state={{ itemIndex: index }}
                    >
                      <i className='mdi mdi-pencil'></i>
                    </Link>
                   : (
                    <button
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      onClick={() =>{
                        toast.info(<CustomToast />);
                      }
                      }
                    >
                      <i className='mdi mdi-pencil'></i>
                    </button>
                  )}

                  {clientList.length > 0 && (
                    <UncontrolledTooltip target={`campaignEdit-${__._id}`} placement="left">Campaign Edit</UncontrolledTooltip>
                  )}
                </div>
                <div>
                  <Button color="danger" id={`userDelete-${__._id}`} outline type="button" onClick={() => showModal(__, __._id)} className="btn btn-outline-danger btnDanger d-flex align-items-center justify-content-center  me-2">
                    <i className='mdi mdi-delete-outline'></i>
                  </Button>
                  <UncontrolledTooltip target={`userDelete-${__._id}`} placement="right">Campaign Delete</UncontrolledTooltip>
                </div>
              </div>
            </>
          );
        }
      },
    }
  ];

  return (
    <TableListingPagesWrapper>
      <div className='tableListingMain user-chat overflow-hidden user-chat-show h-100'>
        {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
          <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Templates</h5>
              <div>
              {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && 
              <Button className='py-1 px-2 btn btn-primary font-size-13' onClick={userAddHandleClick} type="button"> Create Template </Button>
              }
              </div>
          </div>
        </Card> */}
        <div className='userListCardMain h-100'>
          <Card 
          className='userListCard h-100'
          title={
            <div className='d-flex flex-column py-2'>
                <h2 className='mb-1'>Campaign</h2>
                <p className='mb-0 page_discription'>
                Streamlining Your Outreach Efforts with Efficient Bulk Messaging Capabilities.
                <a href='https://support.controlhippo.com/en/support/solutions/articles/1060000078335-how-to-create-a-campaign-in-controlhippo-' className='ms-1 text-decoration-underline' target='_blank'>Need Help?</a>
                </p>
            </div>
        }
          extra={<Button className='btn btn-primary font-size-13 tableCreateBtn' onClick={userAddHandleClick} type="button">Create Campaign</Button>}>
            <Table
              //rowSelection={rowSelection}
              columns={columns}
              dataSource={(slicedData || []).map((item: any, index: number) => ({ ...item, key: index }))}
              pagination={false}
              style={{ width: '100%' }}
              locale={{ emptyText: <div className="noTableDataImgWrapper w-50 m-auto py-5 mt-2 mb-2">
                    <img src={NoCampDataImg} className="noTableDataImg rounded-4" />
                    <h5 className='mb-1 lh-sm mt-4 font-size-16 text-dark'>Create Your First Campaign </h5>
                    <p className='font-size-13 mb-0' style={{color:"#2c2c2c"}}>
                    Ready to launch your first campaign? Click <a className='text-decoration-underline font-size-13 text-primary' onClick={userAddHandleClick} >'Create Campaign'</a> now to unleash the full 
                    potential of our powerful campaign tools!</p>
              </div> }} 
            />
            <div className='tablePaginationPos'>
              <Pagination {...paginationConfig} />
            </div>
          </Card>
        </div>
      </div>
      {/* Delete Campaign Modal */}
      <Modal className='centerPopupModel' title="Campaign Delete" open={isModalOpen} onOk={() => handleOk(deleteId)} onCancel={handleCancel}>
        <p><b>Are you sure you want to delete this Campaign ? </b></p>
      </Modal>
    </TableListingPagesWrapper>
  );
};

export default Campaign;
