import React from 'react'
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Instagram from "./Instagram";
const ReConnectInstagram = () => {
    const {id} = useParams();
    const location = useLocation();
    const { numberData } = location.state;
    // const editUserState = true; 
     return (<Instagram editNumber={id} numberData={numberData}/>)
}

export default ReConnectInstagram