import { toast } from "react-toastify";

const showSuccessNotification = (message: string | any) => {
    message = message.message ? message.message : message?.data?.message ? message?.data?.message  : message;
    setTimeout(() => {
      
    }, 2000); // Delay execution for 1 second (1000 milliseconds)
    toast.success(message, { autoClose: 4000 });
};

const showErrorNotification = (error: any) => {
  error = error?.message ? error.message : error;
  toast.error(error, { autoClose: 4000 });
};

const showWarningNotification = (message: any) => {
  message = message.message ? message.message : message;
  toast.warn(message, { autoClose: 4000 });
};

const showInfoNotification = (message: any) => {
  message = message.message ? message.message : message;
  toast.info(message, { autoClose: 4000 });
};

export { showSuccessNotification, showErrorNotification, showWarningNotification, showInfoNotification };
