import React, { useEffect, useState } from 'react';
import { Form, Input, message, Space, Checkbox, Row, Col, notification } from 'antd';
import { Button, Card } from "reactstrap";
import { useForm } from 'antd/lib/form/Form';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userAdd, userUpdate, getClientsList, getUserDetails, changeTab, getUserList, editWhatsappNumber, allocatedUserList } from '../../redux/actions';
import { useRedux, useProfile } from "../../hooks";
import { UserAddFormWrapper } from '../UserAddFormWrapper.style';
import { addMixpanelEvent } from "../../helpers/mixpanel";
import { TABS } from '../../constants';
interface FormValues {
  allocatedNumber: any
  number: any;
  whatsappName: any
}
interface UserAddProps {
  editUserId: string | undefined | boolean,
  editUserState?: any,
  numberData: any
}
const NumberAdd = ({ editUserId, numberData }: UserAddProps) => {
  const data = numberData
  const [form] = useForm<FormValues>();
  const [chatbotScript, setChatbotScript] = useState<any>();
  const { dispatch, useAppSelector } = useRedux();
  const {
    isInvited,
    userDetails,
    isUpdateUser,
    getUserListData,
    mappingData,
    editWhatsappNumberSuccess } = useAppSelector((state) => {
      return {
        isInvited: state.User?.isInvited,
        userDetails: state.User?.userDetails,
        isUpdateUser: state.User?.isUpdateUser,
        editWhatsappNumberSuccess: state.Chats?.editWhatsappNumberSuccess,
        getUserListData: state?.User?.profileDetails,
        mappingData: state?.User?.mappingData,

      };
    });

  useEffect(() => {
    if (editWhatsappNumberSuccess) {
      navigate("/setting/numbers")
    }
  }, [editWhatsappNumberSuccess])

  const [userData, setUserData] = useState({})
  const [userListData, setUserListData] = useState<any>()

  const [allocatedNumberList, setAllocatedNumberList] = useState<any>([])


  useEffect(() => {
    dispatch(getUserList());
    dispatch(allocatedUserList(data?.clientId))
    if (numberData?.type == "chatbot") {
      const chatScript = `<script src="${process.env.REACT_APP_CHATBOT_PREFIX}/chatbot.js?API_KEY=${numberData.clientId}"></script>`
      setChatbotScript(chatScript)
    }
  }, []);

  useEffect(() => {
    if (getUserListData?.length > 0) {
      setUserListData(getUserListData)
    }
  }, [getUserListData])


  const [clients, setClients] = useState<any>()
  const [valid, setValid] = useState(true);
  const { userProfile } = useProfile()
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/setting/numbers")
  }

  useEffect(() => {
    dispatch(changeTab(TABS.NUMBER))
    dispatch(getClientsList("allocation"))
  }, [])


  useEffect(() => {
    if (mappingData?.length > 0 && editUserId) {
      //   setUserData(userDetails)
      const clientIdArray = mappingData ? mappingData.map((item: any, index: any) => item.userId) : mappingData[0].userId;
      const fetchedData = {
        email: userDetails.email,
        allocatedNumber: clientIdArray
      };
      form.setFieldsValue(fetchedData);
      // setAllocatedNumberList([getUserListData[0]?._id])

      setAllocatedNumberList(clientIdArray)
    }
  }, [mappingData])

  useEffect(() => {
    if (isInvited.success) {
      navigateTo()
    } else {
      setValid(true)
    }
  }, [isInvited])

  useEffect(() => {
    if (isUpdateUser) {
      navigateTo()
    }
  }, [isUpdateUser])

  const onFinish = (values: FormValues) => {
    values.allocatedNumber.splice(0, 1)
    const payloadData = {
      whatsappName: values?.whatsappName,
      userIds: allocatedNumberList,
      allocatedNumber: data?.clientId && [data.clientId]
    }
    dispatch(editWhatsappNumber(payloadData));
  };
  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const validateCheckboxGroup = (_: any, value: string[], callback: (error?: string) => void) => {
    return new Promise<string>((resolve: any, reject: any) => {
      if (!value || value.length === 0) {
        reject('Please select at least one number.');
      } else {
        resolve();
      }
    });
  };

  const allocateDeallocateNumber = (id: string) => {
    // Clone the allocatedNumberList to avoid modifying the state directly.
    const updatedAllocatedNumberList = [...allocatedNumberList];
    if (updatedAllocatedNumberList.includes(id)) {
      // If the ID is already in the list, remove it.
      const indexNo: number = updatedAllocatedNumberList.indexOf(id);
      if (indexNo > -1) {
        updatedAllocatedNumberList.splice(indexNo, 1);
      }
    } else {
      // If the ID is not in the list, add it.
      updatedAllocatedNumberList.push(id);
    }
    // Update the state with the modified list.
    setAllocatedNumberList(updatedAllocatedNumberList);
    form.setFieldsValue({ allocatedNumber: updatedAllocatedNumberList });
  }
  // const allocateDeallocateNumber = (id: string) => {
  //   // allocatedNumberList = form.getFieldValue("allocatedNumber")
  //   if (allocatedNumberList?.includes(id)) {

  //     const indexNo: number = allocatedNumberList.indexOf(id)
  //     if (indexNo > -1) {
  //       allocatedNumberList.splice(indexNo, 1);
  //     }
  //   } else {
  //     setAllocatedNumberList(id)
  //     allocatedNumberList.push(id)
  //   }
  //   form.setFieldsValue({ allocatedNumber: allocatedNumberList });
  //   setAllocatedNumberList(allocatedNumberList)
  // }

  const goToMainPage = () => {
    window.history.back();
  }

  const handleCopy = () => {
    const textToCopy = document.getElementById('chatbotScript')?.innerText;
    if (typeof textToCopy === 'string') {
      navigator.clipboard.writeText(textToCopy)
        .then(() => notification.success({ message: 'Copied to clipboard!', duration: 1.2 }))
        .catch(err => {
          console.error('Failed to copy:', err);
          notification.error({ message: 'Failed to copy!' });
        });
    }
  };

  return (
    <>
      <UserAddFormWrapper className='user-chat user-chat-show pb-4'>
        <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
          <div className="justify-content-between d-flex align-items-center w-100">
            <h5 className="mb-0">Numbers</h5>
            <div className="d-flex align-items-center mt-1">
              {/* <span className="d-flex align-items-center channeleBack font-size-13 text-primary" onClick={goToMainPage}> Users</span> */}
              {/* <i className='bx bx-chevron-right font-size-20 ln-normal text-muted'></i> */}
              {/* <span className="text-muted font-size-13">{editUserState ? "Edit" : "Create" } User</span> */}
            </div>
          </div>
        </Card>
        <div className='d-lg-flex align-items-center justify-content-center userAddFormContainer'>
          <div className="layout-wrapper d-lg-flex MainEmailForm">
            <div className='userAddFormMain'>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='EmailFormAdd'
                initialValues={{ allocatedNumber: [userListData?.length && userListData[0]?._id], number: data?.me?.user, whatsappName: data?.pushname }}
                requiredMark={false}
              >
                <Form.Item
                  name="number"
                  label="Number"
                >
                  <Input defaultValue={data?.me?.user} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="whatsappName"
                  label="Display Name"
                >
                  <Input defaultValue={data?.pushname} />
                </Form.Item>
                <Form.Item
                  className='mb-3'
                  name="allocatedNumber"
                  label="Allocate User"
                // rules={[
                //   { required: true, validator: validateCheckboxGroup },
                // ]}
                >
                  {userListData && userListData.length ?
                    <Checkbox.Group style={{ width: '100%' }} >
                      <div className='allocateNumber_main'>
                        <div className='row'>
                          {userListData.map((item: any, index: number) => {
                            let userId: string = item._id;
                            let allocated = form.getFieldValue("allocatedNumber").includes(userId)
                            return (
                              <div key={index} className='col-12 col-md-6 col-lg-3' onClick={(e) => {
                                e.preventDefault()
                                allocateDeallocateNumber(userId)
                              }}>
                                <div className={`allocate_number_div_main ${allocated ? "allocate_active" : " "}`}>
                                  <div className='row'>
                                    <div className='col-3 d-flex align-items-center justify-content-center pe-0'>
                                      <i className="bx bxs-user"></i>
                                      <Checkbox className='allocate_mun_checkbox d-none' value={item._id}>
                                      </Checkbox>
                                    </div>
                                    <div className='col-9'>
                                      <p className='country_heading'>{`(${item.fullName ? item.fullName : item.email})`}</p>
                                      <div className='phone_number_main'><p>{`${item.email}`}</p></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Checkbox.Group> : ""}
                </Form.Item>
                {
                  <>{chatbotScript && 
                    <>
                    <b>Chatbot Script</b>
                    <p className="mt-1 w-75"><strong>Note: </strong>To enable the chatbot on your website, insert the script just before the closing {'</body>'} tag in your HTML file.</p>
                    <div id="chatbotScript" className="mt-3 mb-3 w-75 d-flex justify-content-between align-items-center p-3 border bg-success Chat-bot-clipboard">  <span className="flex-grow-1  fs-6 ">{chatbotScript}</span> <i className="ri-file-copy-line fs-3 lh-sm ml-2 cursor-pointer" onClick={handleCopy}></i></div>
                    </>}
                  </>
                }

                <Form.Item style={{ textAlign: 'center' }}>
                  <Space>
                    <Button disabled={!valid} color='primary' type="submit">
                      Submit
                    </Button>
                    <Button color='danger' outline onClick={() => navigateTo()}>
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </UserAddFormWrapper>
    </>
  );
};

export default NumberAdd;
