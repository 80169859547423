import { UpdateTourVisitedActionTypes } from "./types";

// common success
export const updateTourVisitedApiResponse = (actionType: string, data: any) => ({
  type: UpdateTourVisitedActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const updateTourVisitedApiResponseError = (actionType: string, error: string) => ({
  type: UpdateTourVisitedActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateTourVisited = (data:any) => {
  return {
    type: UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED,
    payload: data,
  };  
};

