import React, { useState, useEffect, useRef, RefObject } from 'react';
import { useNavigate } from 'react-router';
import { Button, ListGroup, ListGroupItem, Input, Form, FormGroup, Label } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { Popover, Tooltip } from 'antd';
import { checkPlanAndFeature } from '../../../../helpers/common.service';
import { useProfile, useRedux } from "../../../../hooks";
import { getTemplateList } from '../../../../redux/actions';
interface TemplatePopupTypes {
  templateList: any;
  onChange?: (value: string) => void;
  onChangeShowTemp?: () => void;
  templateSend: (data: any) => void;
  showTemplates: boolean;
  clientId?: string | null;
}

interface FormValue {
  [key: string]: string; // Define the form fields with their names and types here
}

const TemplatePopup = ({ onChangeShowTemp, templateSend, templateList, showTemplates, clientId }: TemplatePopupTypes) => {
  const { control, getValues, reset, formState: { errors } } = useForm<FormValue>();
  const navigate = useNavigate();
  const [showDynamicBox, setShowDynamicBox] = useState(false);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [dynamicArray, setDynamicArray] = useState<string[]>([]);
  const [tempDynamicValues, setTempDynamicValues] = useState<{ [key: number]: string }>({});
    const [selectedTemplate, setSelectedTemplate] = useState<any>({})
  const [dynamicName, setDynamicName] = useState<any>()
  const [tempName, setTempName] = useState<string>(''); // Store tempName
  const [tempFormat, setTempFormat] = useState<string>(''); // Store tempFormat
  const [templateType, setTemplateType] = useState<string>(''); // Store tempFormat
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [displayPlaceholders, setDisplayPlaceholders] = useState<string[]>([]);
  const dynamicInputRef = useRef<HTMLInputElement | null>(null); // Create a ref for the dynamic input
  const popoverRef = useRef<HTMLDivElement>(null); // Add type assertion for the ref
  const { userProfile } = useProfile();
  const [userDetails, setUserDetails] = useState(userProfile)
  const [searchInputValue, setSearchInputValue] = useState("");
  const [templates, setTemplates] = useState<[any] | []>([])
  const { dispatch, useAppSelector } = useRedux();
  const inputRef :any= useRef(null);
  const {
    userData,
    clientList,
    dropdownSelectedClientId
  } = useAppSelector(state => ({
    userData: state.Login.user,
    clientList: state?.Chats?.clientList,
    dropdownSelectedClientId: state?.Chats?.clientId,
  }));

  useEffect(() => {
    setUserDetails(userData)
    if (templateList?.length) setTemplates(templateList.filter((item: any) => item.status === "APPROVED"))
  }, [])
  useEffect(() => {
    setUserDetails(userData)
  }, [userData])


  useEffect(() => {
    clientId = dropdownSelectedClientId == "all_numbers" || clientId != null ? clientId : dropdownSelectedClientId
    if (clientId && clientList.length) {
      let clientIndex = clientList.findIndex((item: any) => item.clientId == clientId)
      if (clientIndex == -1) {
        console.log("Client id not find from client List ::: ");
        clientIndex = 0
      }
      if (clientIndex !== -1) {
        const clientDetails = clientList[clientIndex]
        if (templateList?.length && clientDetails?.type == "business_whatsapp") {
          const newTemplateList = templateList.filter((item: any) => (item.status === "APPROVED" && item.clientId == clientDetails.clientId))
          setTemplates(newTemplateList)
        } else {
          const newTemplateList = templateList?.length && (templateList || []).filter((item: any) => (item.status === "APPROVED"))
          setTemplates(newTemplateList)
        }
      }
    }
  }, [clientId, clientList, templateList])

  const handleRef = (element:any) => {
    inputRef.current = element;
    if (showTemplates && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleTemplateClick = (tempName: string, tempFormat: string, template: any) => {
    console.log("🚀 ~ handleTemplateClick ~ tempName:", tempName)
    console.log("🚀 ~ handleTemplateClick ~ template:", template)
    const tempPlaceholders = template?.tempFormat?.match(/{{\d+}}/g) || [];
    const tempDisplayPlaceholders = template?.dynamicValues;
    setPlaceholders(tempPlaceholders);
    setDisplayPlaceholders(tempDisplayPlaceholders);
    setInputValues(new Array(tempPlaceholders?.length).fill(''));
    setSelectedTemplate(template)
    setTempName(template?.tempName);
    setTempFormat(tempFormat);
    setTemplateType(template?.tempType)
    const updatedValue = {
      tempName: tempName,
      tempFormat: tempFormat
    }
    if (true) {
      setShowDynamicBox(true);
    } else {
      templateSend(template);
    }
  };

  const createTemplateHandleClick = () => {
    if (checkPlanAndFeature({ featureName: "quick_reply_template", planDetails: userDetails?.planDetails, featureDisplayName: "Quick Replies" })) {
      navigate("/template-Create")
    }
  };

  const templateFormCancel = () => {
    setShowDynamicBox(false)
    reset()
  }

  const preSubmitTemplate = (data: any) =>{
    // templateSend(data);
    if (typeof onChangeShowTemp == 'function' && onChangeShowTemp) onChangeShowTemp()
  }
  const templateSubmit = (values: any) => {
    const formData = getValues();
    const dynamicValues = Object.values(formData);
    let formattedTempFormatCopy = tempFormat;

    // (dynamicValues || []).forEach((value: any, index: number) => {
    //   const placeholder = `{{${index + 1}}}`;
    //   formattedTempFormatCopy = formattedTempFormatCopy.replace(placeholder, value);
    // });
    const updatedValue = {
      tempName: tempName,
      tempFormat: formattedTempFormatCopy,
      dynamicValues: dynamicValues
    };
    selectedTemplate.formattedTempFormatCopy = fillTemplate(tempFormat, inputValues)
    selectedTemplate.dynamicValues = dynamicArray
    const allUndefined = values?.dynamicValues?.every((value: any) => value === "undefined");
    const result = !allUndefined; 
    values.dynamicValues = dynamicArray
    templateSend(result ? selectedTemplate : values);
    setDynamicArray([])
    setTempDynamicValues([])
    reset();
    setPlaceholders([])
    setInputValues([])
    setSelectedTemplate({})
    setTempName('')
    setTempFormat('')
    setShowDynamicBox(false);
    dispatch(getTemplateList());
    
  };

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setSearchInputValue(inputValue);
    const filtered = templateList.filter((item : any) =>
      item.status === "APPROVED" && item.tempName.toLowerCase().includes(inputValue.toLowerCase())
    );
    setTemplates(filtered);
  };

  const handlePlaceholderChange = (e: any, index: any) => {
    setInputValues(prevState => ({
      ...prevState,
      [index + 1]: e?.target?.value
    }));
    setTempDynamicValues(prevState => {
      const newTempDynamicValues = {
        ...prevState,
        [index]: e?.target?.value
      };
      setDynamicArray(Object.values(newTempDynamicValues));
      return newTempDynamicValues;
    });

    setDynamicName(e?.target?.value);
  };
  

  const handleOpenChange = () => {
    setTimeout(() => {
      if (typeof onChangeShowTemp == 'function' && onChangeShowTemp) onChangeShowTemp()
    }, 300);
  }

  const templatePopupAppend = (triggerNode: HTMLElement): HTMLElement => {
    return document.querySelector('.chatInputInnerButtondiv')!;
  };


  const fillTemplate = (template: any, values: any) => {
    let filledTemplate = template;
    
    Object.keys(values).forEach(key => {
      const placeholder = `{{${key}}}`;
      if(values[key] && values[key].length){
        filledTemplate = filledTemplate.replace(placeholder, values[key]);
      }
    });
    
    return filledTemplate;
  };

  return (
    <div className='popoverdivmain' ref={popoverRef}>
      {!showDynamicBox ?
        <Popover
          placement="top"
          trigger="click"
          open={showTemplates}
          arrow={false}
          overlayClassName='TemplatesPopover'
          // getPopupContainer={(trigger: any) => trigger.parentNode}
          getPopupContainer={templatePopupAppend}
          onOpenChange={handleOpenChange}
          content={
            <div className='RV_changewidthPopover'>

              <div className="py-2 ps-2 text-center create-template d-flex gap-2 align-items-center">
                <div className="position-relative w-100">
                  <i className='bx bx-search-alt search-widget-icon'></i>
                  <Input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search template ..."
                    id="search-options"
                    onChange={(event) => handleInputChange(event)}
                    innerRef={handleRef} 
                    autoFocus
                  />
                  <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
                </div>
                <Tooltip placement="right" title="Create Template">
                  <Button
                    id='create-template'
                    color="primary"
                    outline
                    className='d-flex circle_status_icon align-items-center justify-content-center m-0'
                    onClick={createTemplateHandleClick}
                    type="button">
                    <i className='bx bx-plus align-middle font-size-20'></i>
                  </Button>
                </Tooltip>
              </div>

              <ListGroup className='TempletList'>
                {templates?.length ? templates?.map((data: any, index: any) => {
                  return (
                    <ListGroupItem
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        return handleTemplateClick(data.tempNam0e, data.tempFormat, data)
                      }}
                    >
                      <div className='d-flex align-items-center gap-3'>
                        <div className='d-flex align-items-center border justify-content-center templateIconCir'>
                          {data?.tempType === "text" ? <span className='templateIcon text-file-icon'></span>
                            : (data?.tempType === "image" ? <span className='templateIcon image-file-icon'></span>
                              : (data?.tempType === "video" ? <span className='templateIcon video-file-icon'></span>
                                : <span className='templateIcon text-file-icon'></span>))}
                        </div>
                        <div>
                          <h4>{data?.tempName}</h4>
                          <p className='tempalteDesc'>{data?.tempFormat}</p>
                        </div>
                      </div>
                      <div className='listTemplateIcon'>
                        <span className="previewTemplateIcon">
                          <Button
                            type="submit"
                            className="btn btn-lg chat-send waves-effect waves-light"
                            id=""
                            onClick={(e) => {
                              e.preventDefault();
                              return handleTemplateClick(data.tempName, data.tempFormat, data)
                            }}
                          >
                            <i className="bx bxs-show align-middle"></i>
                          </Button>
                        </span>
                        <span className="sendTemplateIcon">
                          <Button
                            type="submit"
                            className="btn btn-lg chat-send waves-effect waves-light"
                            id=""
                            onClick={(e) =>{
                              e.preventDefault();
                              return handleTemplateClick(data.tempNam0e, data.tempFormat, data)
                            }
                          }
                          >
                            <i className="bx bxs-send align-middle"></i>
                          </Button>
                        </span>
                      </div>
                    </ListGroupItem>
                  );
                }) : <> <div className='d-flex align-items-center gap-3 m-2 p-2'>
                  <div>
                    <h6 className='text-nuted'>No Template Found</h6>
                  </div>
                </div></>}
              </ListGroup>

            </div>
          }
        />
        :
        <Popover
          placement="top"
          overlayClassName='TemplateFormPopover'
          // getPopupContainer={(trigger: any) => trigger.parentNode}
          getPopupContainer={templatePopupAppend}
          onOpenChange={handleOpenChange}
          trigger={'click'}
          arrow={false}
          open={!showTemplates || showDynamicBox}
          content={
            


            <div className='p-4'>
              {showDynamicBox &&

              <div className='d-flex align-items-center gap-3 mb-2 templateDetailInner'>
                <div className='d-flex align-items-center border justify-content-center templateIconCir'>
                {templateType === "text" ? (
                <span className='templateIcon text-file-icon'></span>
                ) : templateType === "image" ? (
                  <span className='templateIcon image-file-icon'></span>
                ) : templateType === "video" ? (
                  <span className='templateIcon video-file-icon'></span>
                ) : (
                <span className='templateIcon'>
                  <span className='templateIcon text-file-icon'></span>
                </span>
                )}
                </div>
                <div>
                <h4 className='mb-0'>{tempName}</h4>
                </div>
              </div>

              }

              {placeholders?.length > 0 && (
              <Form className='templateInputForm mb-3' autoComplete='off' >
                {placeholders.map((placeholder, index) => (
                  <FormGroup key={index}>
                    <Label for={`input-${index}`}>{placeholder}</Label>
                    <Controller
                      name={`inputPlaceholder${index}`} // Use a unique name for each field
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          id={`input-${index}`}
                          placeholder={displayPlaceholders[index] !== "undefined" && displayPlaceholders[index] !== undefined
                            ? "E.g "+displayPlaceholders[index]
                            : ""}
                            onChange={(e)=>{handlePlaceholderChange(e,index)}}
                        />
                      )}
                    />
                  </FormGroup>
                ))}
              </Form>
              )}
              {/* <div className='d-flex align-items-center gap-1 my-3'>
                <i className='bx bxs-show font-size-14'></i>
                <h6 className='mb-0 font-size-13'>Preview</h6>
              </div> */}
              <div className='templatePreviewTextBlock mb-3'>
              
              <p className='templatePreviewText font-size-13'>{fillTemplate(tempFormat, inputValues)}</p>
              </div>
              <div className='text-center'>
                <Button className='btn-sm me-2 font-size-12'
                  type="submit"
                  color="primary"
                  onClick={templateSubmit}
                >
                  Send
                </Button>

                <Button className='btn-sm font-size-12' color="secondary" onClick={templateFormCancel} >Cancel</Button>
              </div>
            </div>
          }
        />
      }

      {/* ) : <DynamicFieldPopup isOpen={showDynamicBox} placeholders={placeholders} showDynamicBox={changeShowDynamicBox} formSubmit={templateSubmit} />} */}
    </div>
  );
};

export default TemplatePopup;
