import React, { useState, useEffect,useRef } from 'react'
import { Button, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { Popover,Tooltip } from 'antd';
import { useProfile } from '../../../hooks';

interface GroupMemberListProps {
    isOpen: boolean;
    text: string | null;
    chatUserDetails: any
    clientDetails: any;
    onSelectParticipant: (data: any) => void;
    onChangeShowGroupList: (value: boolean) => void;
}
const GroupMemberList = ({ isOpen, chatUserDetails,clientDetails,onChangeShowGroupList, onSelectParticipant, text }: GroupMemberListProps) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const {userProfile} = useProfile()
    const [showPopOver, setShowPopOver] = useState<boolean>(false)
    const [participantList, setParticipantList] = useState<any[]>([]);
    useEffect(() => {
        setShowPopOver(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (chatUserDetails?.participants) {
            setParticipantList(chatUserDetails.participants)
        }
    }, [chatUserDetails])
   
    const findLastMentionAfterWord = (text : string )=> {
        const mentionRegex = /@(\d+)/g;
        let lastMentionAfterWord = null;
        let match;
        while ((match = mentionRegex.exec(text)) !== null) {
            lastMentionAfterWord = match[1]; // Capture the digits following @
        }
        return lastMentionAfterWord;
    }
    // useEffect(() => {
    //     const inputValue = text?.length?text : "";
    //     const lastValue = findLastMentionAfterWord(inputValue)
    //     if(lastValue){
    //         const filteredParticipent: [any] = filterContacts(chatUserDetails.participants, lastValue)
    //         if (filteredParticipent.length) {
    //             setParticipantList(filteredParticipent)
    //         }
    //     }
    // }, [text])
    
    
    const filterContacts = (contacts: any, searchValue: string ) => {
        const regex = new RegExp((searchValue).toLowerCase(), 'i');
        return contacts.filter((contact: any) =>
            regex.test((contact.displayPhoneNumber)?.toLowerCase()) || regex.test((contact.id.firstName)?.toLowerCase())
        );
    }
    const handleParticipantClick = (data: any, index: Number) => {
        setShowPopOver(!isOpen)
        onSelectParticipant(data)
    }

    const handleInputChange = async (event: any) => {
        event.preventDefault()
        const inputValue = event.target.value;
        const filteredParticipent: [any] = await filterContacts(chatUserDetails.participants, inputValue)
        setParticipantList(filteredParticipent)
    };
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setShowPopOver(false);
      }
    };
  
    const handlePopupOpenChange =()=>{ 
        setTimeout(() => {
            if(typeof onChangeShowGroupList == 'function' && onChangeShowGroupList)onChangeShowGroupList(false)
        }, 300); 
    }
    return (
        <div className='popoverdivmain' ref={popoverRef}>
            <Popover 
            onOpenChange={handlePopupOpenChange} 
            placement="top" 
            overlayClassName='groupListPopover' 
            open={showPopOver} 
            trigger="click"
            getPopupContainer={(trigger:any) => trigger.parentNode}
            arrow={false}
            content={
                <div className='RV_changewidthPopover'>
                    <ListGroup className='TempletList'>
                        {participantList?.length ? participantList?.map((data: any, index: any) => {
                            if(clientDetails?.whatsappId !== data._id)
                            return (
                                <ListGroupItem
                                    key={index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        return handleParticipantClick(data, index)
                                    }}
                                >
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='d-flex align-items-center border justify-content-center templateIconCir'>
                                            <i className="ri-whatsapp-line templateIcon"></i>
                                        </div>
                                        <div>
                                            <h4>{data?.id?.firstName ? data?.id?.firstName : "+" + data?.displayPhoneNumber}</h4>
                                            <span className=''>+{data?.displayPhoneNumber}</span>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            );
                        }) : <> <div className='d-flex align-items-center gap-3 m-2 p-2'>
                            <div>
                                <h6 className='text-nuted'>No participant</h6>
                            </div>
                        </div></>}
                    </ListGroup>
                    <div className="m-1 py-2 ps-2 text-center create-template d-flex gap-2 align-items-center">
                        <div className="position-relative w-100">
                            <i className='bx bx-search-alt search-widget-icon'></i>
                            <Input
                                type="text"
                                className="form-control search-input"
                                placeholder="Search member..."
                                id="search-options"
                                onChange={handleInputChange}
                                autoFocus
                            />
                            <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
                        </div>
                    </div>
                </div>
            }
            />
        </div>
    )
}

export default GroupMemberList