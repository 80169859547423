import React, { useEffect, useState } from "react";
import {
  Alert,
  Row,
  Col,
  Form,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { addMixpanelEvent } from "../../helpers/mixpanel";
import ReCAPTCHA from "react-google-recaptcha";

import ControlHippoBlueLogo from "../../assets/images/auth-images/ControlHippo_blue_logo.svg";

//Social Media Imports
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';

// router
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// api
import { setAuthorization } from "../../api/apiCore";

// hooks
import { useProfile, useRedux } from "../../hooks/index";
import { loginUser, setClientId, changeTab, postFcmToken, googleSignIn } from "../../redux/actions";
// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import { TABS } from "../../constants";
import { showErrorNotification, showSuccessNotification } from "../../helpers/notifications";



const recaptchaRef: any = React.createRef();
const recaptcha_site_key: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

interface LoginProps { }
const Login = (props: LoginProps) => {
  //const recaptchaRef : any = React.useRef();
  const { dispatch, useAppSelector } = useRedux();
  const {
    isUserLogin,
    error,
    loginLoading,
    isUserLogout,
    userData,
    changeEmailResponse,
    changepasswordError,
    updatePasswordMessage
  } = useAppSelector(state => {
    return {
      isUserLogin: state.Login.isUserLogin,
      userData: state.Login.user,
      error: state.Login.error,
      loginLoading: state.Login.loading,
      isUserLogout: state.Login.isUserLogout,
      changeEmailResponse: state?.ChangeEmail?.changeEmailResponse?.message,
      changepasswordError: state?.ChangeEmail?.changepasswordError?.data?.error,
      updatePasswordMessage: state?.updatePassword?.authData?.message

    };
  });

  const navigate = useNavigate();
  const { userProfile } = useProfile();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState();
  const [displayError, setDisplayError] = useState();
  const [valid, setValid] = useState(false);
  useEffect(() => {
    const url =
      location.state && location.state.from
        ? location.state.from.pathname
        : "/";
    setRedirectUrl(url);
  }, [location]);
  const urlError = location.state?.error;
  useEffect(() => {
   if(urlError){
    setDisplayError(urlError);
   }
  }, [urlError])

  
  // useEffect(() => {
  //   setTimeout(() => {
  //     if(userProfile?.authToken){
  //       navigate('/dashboard')
  //      }
  //   }, 1000);
  // }, [])
  
  useEffect(() => {
    if (isUserLogin && !loginLoading && !isUserLogout) {
      const setToken = setAuthorization(userData.authToken);
      if (userData && userData.clientId) {
        dispatch(setClientId(userData.clientId))
        dispatch(changeTab(TABS.CHAT))
        // dispatch(setClientId(JSON.stringify(userData.clientId)))
        navigate("/dashboard");

      }
      else if (isUserLogin && userData.isOnboardingPending) {
        navigate("/update-profile");
      } else if (isUserLogin && userData && userData.authToken && !userData.clientId) {
        const path = '/welcome';
        const queryParams: any = {
          addNumber: true
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const fullPath = `${path}?${queryString}`;
        navigate(fullPath);
      }
    } else {
      navigate("/auth-login")

    }
  }, [isUserLogin, navigate, loginLoading, isUserLogout, redirectUrl, userData]);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .transform((value) => value.trim()) // Trim any whitespace
        .email("This value should be a valid email.")
        .required("Please enter an email."),
      password: yup.string().required("Please Enter Password."),
    })
  );

  useEffect(() => {
    setValid(true)
  }, [])


  const defaultValues: any = {
    email: "",
    password: "",
  };

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = async (values: object | any) => {
    const token = await recaptchaRef.current.executeAsync();
    const updatedValues = {
      ...values,
      recaptcha: token
    };
    addMixpanelEvent("Clicked On SignIn", { email: values.email })
    setValid(false)
    dispatch(loginUser(updatedValues));
    setValid(true)
  };

  // if (userProfile && !loading) {
  //   return <Navigate to={{ pathname: redirectUrl }} />;
  // }

  const signIn = (res: any, type: "google" | "facebook") => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      // dispatch(socialLogin(postData, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        token: res.authToken,
      };
      // dispatch(socialLogin(postData, type));
    }
  };

  const googlesigninresponseSuccess = (response: any) => {
    dispatch(googleSignIn(response))
  };
  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col className="pt-0 pb-0">
          <div className="row mb-4">
            <div className="col-md-12 pt-3 pt-md-4">
              <div className="headtitle mb-4">
                <h3 className="headTitleHeading">Sign <span className="headTitleHeadingColored">In</span></h3>
              </div>
              {(changeEmailResponse || updatePasswordMessage) && <Alert color="success">{changeEmailResponse ? changeEmailResponse : updatePasswordMessage}</Alert>}
              {(displayError || error || changepasswordError) && <Alert color="danger">{error ? error :displayError ? displayError : changepasswordError}</Alert>}
              <Form
                onSubmit={handleSubmit(onSubmitForm)}
                className="position-relative registerSignForm"
              >
                {loginLoading && <Loader />}

                <div className="mb-3">
                  <FormInput
                    type="text"
                    name="email"
                    register={register}
                    errors={errors}
                    control={control}
                    labelClassName="form-label"
                    placeholder="Enter Email"
                    className="form-control inputField"
                  />
                </div>

                <div className="mb-3 inputPassMainDiv">
                  <FormInput
                    type="password"
                    name="password"
                    register={register}
                    errors={errors}
                    control={control}
                    labelClassName="form-label"
                    className="form-control pe-5 inputField"
                    placeholder="Enter Password"
                  />
                </div>

                <div className="form-check form-check-info font-size-16 pt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <Label
                    className="form-check-label font-size-14"
                    htmlFor="remember-check"
                  >
                    Remember me
                  </Label>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="col-12 col-md-6 text-center">
                    <Button disabled={!valid} data-sitekey={recaptcha_site_key} color="primary" className="w-100 inputFieldButton font-size-16" type="submit">
                      Sign in
                    </Button>
                </div> 
                  <div className="col-12 col-md-6 text-center forgotPassDiv">
                    <a href="auth-recoverpw" className="text-dark text-decoration-underline">Forgot password?</a>
                  </div>
                </div>
                <div className="cnhlogin__divider"><span className="chlogin_dividertext">OR</span></div>
                <div className="googleWithSignBtn w-75 m-auto d-flex align-items-center justify-content-center">
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ''}>
                  <GoogleLogin
                    size="large"
                    theme="filled_blue"
                    shape="rectangular"
                    onSuccess={credentialResponse => googlesigninresponseSuccess(credentialResponse)}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                  </GoogleOAuthProvider>
                </div>

       
                <div className="pt-2">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={recaptcha_site_key}
                  />
                </div>
              </Form>
            </div>
            
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Login;
