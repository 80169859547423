import { TemplateCreateActionTypes } from "./types";

// common success
export const postTemplateCreateApiResponse = (actionType: string, data: any) => ({
  type: TemplateCreateActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const postTemplateCreateApiResponseError = (actionType: string, error: string) => ({
  type: TemplateCreateActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const templateCreate = (data:any) => {
  return {
    type: TemplateCreateActionTypes.POST_TEMPLATE_CREATE,
    payload: data,
  };
};
export const templateUpdate = (data:any) => {
  return {
    type: TemplateCreateActionTypes.POST_TEMPLATE_UPDATE,
    payload: data,
  };
};

export const templateDelete = (data:any) => {
  return {
    type: TemplateCreateActionTypes.POST_TEMPLATE_DELETE,
    payload: data,
  };
};

export const getTemplateList = (data ?: any) => {
  return {
    type: TemplateCreateActionTypes.GET_TEMPLATE_LIST,
    payload:{data}
  };
};

export const getTemplateDetails= (id:string) => {
  return {
    type: TemplateCreateActionTypes.GET_TEMPLATE_DETAILS,
    payload:id
  };
};

