import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { UpdatePasswordActionTypes } from "./types";
import {
  updatePasswordApiResponse,
  UpdatePasswordApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postUpdatePassword,
  changePassword as changePasswordApi,
} from "../../../api/index";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

const fireBaseBackend: any = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* updatePassword({ payload: data }: any) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postUpdatePassword,data );
      const tempResponse : any = response
      if(tempResponse.success){
      yield put(
        updatePasswordApiResponse(
          UpdatePasswordActionTypes.UPDATE_PASSWORD,
          response
        )
      )
      // yield call(showSuccessNotification,"Password Updated Successfully");
    }else{
      yield put(
        UpdatePasswordApiResponseError(
          UpdatePasswordActionTypes.UPDATE_PASSWORD,
          tempResponse.error.error
        )
      );
      // yield call(showErrorNotification, tempResponse.error.error);
    }
    }
  } catch (error: any) {
    showErrorNotification(error?.data?.error)
    yield put(
      UpdatePasswordApiResponseError(
        UpdatePasswordActionTypes.UPDATE_PASSWORD,
        error
      )
    );
  }
}

// function* changePassword({ payload: newPassword }: any) {
//   try {
//     yield call(changePasswordApi, newPassword);
//     yield put(
//       updatePasswordApiResponse(
//         UpdatePasswordActionTypes.CHANGE_PASSWORD,
//         "Your Password is Changed"
//       )
//     );
//   } catch (error: any) {
//     yield put(
//       UpdatePasswordApiResponseError(
//         UpdatePasswordActionTypes.CHANGE_PASSWORD,
//         error
//       )
//     );
//   }
// }

export function* watchUserUpdatePassword() {
  yield takeEvery(UpdatePasswordActionTypes.UPDATE_PASSWORD, updatePassword);
}

// export function* watchUserChangePassword() {
//   yield takeEvery(UpdatePasswordActionTypes.CHANGE_PASSWORD, changePassword);
// }

function* updatePasswordSaga() {
  yield all([fork(watchUserUpdatePassword)]);
}

export default updatePasswordSaga;
