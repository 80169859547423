export interface CountryCode {
    id: number;
    code: string;
    shortname: string;
};
const countryCodeOptions: CountryCode[] = [
    { id: 1, code: '91', shortname: 'IN' },
    { id: 2, code: '1', shortname: 'US' },
    { id: 3, code: '44', shortname: 'GB' },
    { id: 4, code: '1', shortname: 'CA' },
    { id: 5, code: '20', shortname: 'EG' },
    { id: 6, code: '27', shortname: 'ZA' },
    { id: 7, code: '30', shortname: 'GR' },
    { id: 8, code: '31', shortname: 'NL' },
    { id: 9, code: '32', shortname: 'BE' },
    { id: 10, code: '33', shortname: 'FR' },
    { id: 11, code: '34', shortname: 'ES' },
    { id: 12, code: '36', shortname: 'HU' },
    { id: 13, code: '38', shortname: 'UA' },
    { id: 14, code: '39', shortname: 'IT' },
    { id: 15, code: '40', shortname: 'RO' },
    { id: 16, code: '41', shortname: 'CH' },
    { id: 17, code: '43', shortname: 'AT' },
    { id: 19, code: '45', shortname: 'DK' },
    { id: 20, code: '46', shortname: 'SE' },
    { id: 21, code: '47', shortname: 'NO' },
    { id: 22, code: '48', shortname: 'PL' },
    { id: 23, code: '49', shortname: 'DE' },
    { id: 24, code: '51', shortname: 'PE' },
    { id: 25, code: '52', shortname: 'MX' },
    { id: 26, code: '53', shortname: 'CU' },
    { id: 27, code: '54', shortname: 'AR' },
    { id: 28, code: '55', shortname: 'BR' },
    { id: 29, code: '56', shortname: 'CL' },
    { id: 30, code: '57', shortname: 'CO' },
    { id: 31, code: '58', shortname: 'VE' },
    { id: 32, code: '60', shortname: 'MY' },
    { id: 33, code: '61', shortname: 'AU' },
    { id: 34, code: '62', shortname: 'ID' },
    { id: 35, code: '63', shortname: 'PH' },
    { id: 36, code: '64', shortname: 'NZ' },
    { id: 37, code: '65', shortname: 'SG' },
    { id: 38, code: '66', shortname: 'TH' },
    { id: 39, code: '81', shortname: 'JP' },
    { id: 40, code: '82', shortname: 'KR' },
    { id: 41, code: '84', shortname: 'VN' },
    { id: 42, code: '86', shortname: 'CN' },
    { id: 43, code: '90', shortname: 'TR' },
    { id: 45, code: '92', shortname: 'PK' },
    { id: 46, code: '93', shortname: 'AF' },
    { id: 47, code: '94', shortname: 'LK' },
    { id: 48, code: '95', shortname: 'MM' },
    { id: 49, code: '98', shortname: 'IR' },
    { id: 50, code: '211', shortname: 'SS' },
    { id: 51, code: '212', shortname: 'MA' },
    { id: 52, code: '213', shortname: 'DZ' },
    { id: 53, code: '216', shortname: 'TN' },
    { id: 54, code: '218', shortname: 'LY' },
    { id: 55, code: '220', shortname: 'GM' },
    { id: 56, code: '221', shortname: 'SN' },
    { id: 57, code: '222', shortname: 'MR' },
    { id: 58, code: '223', shortname: 'ML' },
    { id: 59, code: '224', shortname: 'GN' },
    { id: 60, code: '225', shortname: 'CI' },
    { id: 61, code: '226', shortname: 'BF' },
    { id: 62, code: '227', shortname: 'NE' },
    { id: 63, code: '228', shortname: 'TG' },
    { id: 64, code: '229', shortname: 'BJ' },
    { id: 65, code: '230', shortname: 'MU' },
    { id: 66, code: '231', shortname: 'LR' },
    { id: 67, code: '232', shortname: 'SL' },
    { id: 68, code: '233', shortname: 'GH' },
    { id: 69, code: '234', shortname: 'NG' },
    { id: 70, code: '235', shortname: 'TD' },
    { id: 71, code: '236', shortname: 'CF' },
    { id: 72, code: '237', shortname: 'CM' },
    { id: 73, code: '238', shortname: 'CV' },
    { id: 74, code: '239', shortname: 'ST' },
    { id: 75, code: '240', shortname: 'GQ' },
    { id: 76, code: '241', shortname: 'GA' },
    { id: 77, code: '242', shortname: 'CG' },
    { id: 78, code: '243', shortname: 'CD' },
    { id: 79, code: '244', shortname: 'AO' },
    { id: 80, code: '245', shortname: 'GW' },
    { id: 81, code: '246', shortname: 'IO' },
    { id: 82, code: '247', shortname: 'AC' },
    { id: 83, code: '248', shortname: 'SC' },
    { id: 84, code: '249', shortname: 'SD' },
    { id: 85, code: '250', shortname: 'RW' },
    { id: 86, code: '251', shortname: 'ET' },
    { id: 87, code: '252', shortname: 'SO' },
    { id: 88, code: '253', shortname: 'DJ' },
    { id: 89, code: '254', shortname: 'KE' },
    { id: 90, code: '255', shortname: 'TZ' },
    { id: 91, code: '256', shortname: 'UG' },
    { id: 92, code: '257', shortname: 'BI' },
    { id: 93, code: '258', shortname: 'MZ' },
    { id: 94, code: '260', shortname: 'ZM' },
    { id: 95, code: '261', shortname: 'MG' },
    { id: 96, code: '262', shortname: 'RE' },
    { id: 97, code: '263', shortname: 'ZW' },
    { id: 98, code: '264', shortname: 'NA' },
    { id: 99, code: '265', shortname: 'MW' },
    { id: 100, code: '266', shortname: 'LS' },
    { id: 101, code: '267', shortname: 'BW' },
    { id: 102, code: '268', shortname: 'SZ' },
    { id: 103, code: '269', shortname: 'KM' },
    { id: 104, code: '290', shortname: 'SH' },
    { id: 105, code: '291', shortname: 'ER' },
    { id: 106, code: '297', shortname: 'AW' },
    { id: 107, code: '298', shortname: 'FO' },
    { id: 108, code: '299', shortname: 'GL' },
    { id: 109, code: '350', shortname: 'GI' },
    { id: 110, code: '351', shortname: 'PT' },
    { id: 111, code: '352', shortname: 'LU' },
    { id: 112, code: '353', shortname: 'IE' },
    { id: 113, code: '354', shortname: 'IS' },
    { id: 114, code: '355', shortname: 'AL' },
    { id: 115, code: '356', shortname: 'MT' },
    { id: 116, code: '357', shortname: 'CY' },
    { id: 117, code: '358', shortname: 'FI' },
    { id: 118, code: '359', shortname: 'BG' },
    { id: 119, code: '370', shortname: 'LT' },
    { id: 120, code: '371', shortname: 'LV' },
    { id: 121, code: '372', shortname: 'EE' },
    { id: 122, code: '373', shortname: 'MD' },
    { id: 123, code: '374', shortname: 'AM' },
    { id: 124, code: '375', shortname: 'BY' },
    { id: 125, code: '376', shortname: 'AD' },
    { id: 126, code: '377', shortname: 'MC' },
    { id: 127, code: '378', shortname: 'SM' },
    { id: 128, code: '380', shortname: 'UA' },
    { id: 129, code: '381', shortname: 'RS' },
    { id: 130, code: '382', shortname: 'ME' },
    { id: 131, code: '383', shortname: 'XK' },
    { id: 132, code: '385', shortname: 'HR' },
    { id: 133, code: '386', shortname: 'SI' },
    { id: 134, code: '387', shortname: 'BA' },
    { id: 135, code: '389', shortname: 'MK' },
    { id: 136, code: '421', shortname: 'SK' },
    { id: 137, code: '423', shortname: 'LI' },
    { id: 139, code: '500', shortname: 'FK' },
    { id: 140, code: '501', shortname: 'BZ' },
    { id: 141, code: '502', shortname: 'GT' },
    { id: 142, code: '503', shortname: 'SV' },
    { id: 143, code: '504', shortname: 'HN' },
    { id: 144, code: '505', shortname: 'NI' },
    { id: 145, code: '506', shortname: 'CR' },
    { id: 146, code: '507', shortname: 'PA' },
    { id: 147, code: '508', shortname: 'PM' },
    { id: 148, code: '509', shortname: 'HT' },
    { id: 149, code: '590', shortname: 'GP' },
    { id: 150, code: '591', shortname: 'BO' },
    { id: 151, code: '592', shortname: 'GY' },
    { id: 152, code: '593', shortname: 'EC' },
    { id: 153, code: '594', shortname: 'GF' },
    { id: 154, code: '595', shortname: 'PY' },
    { id: 155, code: '596', shortname: 'MQ' },
    { id: 156, code: '597', shortname: 'SR' },
    { id: 157, code: '598', shortname: 'UY' },
    { id: 158, code: '599', shortname: 'CW' },
    { id: 159, code: '670', shortname: 'TL' },
    { id: 160, code: '672', shortname: 'NF' },
    { id: 161, code: '673', shortname: 'BN' },
    { id: 162, code: '674', shortname: 'NR' },
    { id: 163, code: '675', shortname: 'PG' },
    { id: 164, code: '676', shortname: 'TO' },
    { id: 165, code: '677', shortname: 'SB' },
    { id: 166, code: '678', shortname: 'VU' },
    { id: 167, code: '679', shortname: 'FJ' },
    { id: 168, code: '680', shortname: 'PW' },
    { id: 169, code: '681', shortname: 'WF' },
    { id: 170, code: '682', shortname: 'CK' },
    { id: 171, code: '683', shortname: 'NU' },
    { id: 172, code: '684', shortname: 'AS' },
    { id: 173, code: '686', shortname: 'KI' },
    { id: 174, code: '687', shortname: 'NC' },
    { id: 175, code: '688', shortname: 'TV' },
    { id: 176, code: '689', shortname: 'PF' },
    { id: 177, code: '690', shortname: 'TK' },
    { id: 178, code: '691', shortname: 'FM' },
    { id: 179, code: '692', shortname: 'MH' },
    { id: 181, code: '850', shortname: 'KP' },
    { id: 182, code: '852', shortname: 'HK' },
    { id: 183, code: '853', shortname: 'MO' },
    { id: 184, code: '855', shortname: 'KH' },
    { id: 185, code: '856', shortname: 'LA' },
    { id: 186, code: '880', shortname: 'BD' },
    { id: 187, code: '886', shortname: 'TW' },
    { id: 188, code: '960', shortname: 'MV' },
    { id: 189, code: '961', shortname: 'LB' },
    { id: 190, code: '962', shortname: 'JO' },
    { id: 191, code: '963', shortname: 'SY' },
    { id: 192, code: '964', shortname: 'IQ' },
    { id: 193, code: '965', shortname: 'KW' },
    { id: 194, code: '966', shortname: 'SA' },
    { id: 195, code: '967', shortname: 'YE' },
    { id: 196, code: '968', shortname: 'OM' },
    { id: 197, code: '970', shortname: 'PS' },
    { id: 198, code: '971', shortname: 'AE' },
    { id: 199, code: '972', shortname: 'IL' },
    { id: 200, code: '973', shortname: 'BH' },
    { id: 201, code: '974', shortname: 'QA' },
    { id: 202, code: '975', shortname: 'BT' },
    { id: 203, code: '976', shortname: 'MN' },
    { id: 204, code: '977', shortname: 'NP' },
    { id: 205, code: '993', shortname: 'TM' },
    { id: 206, code: '994', shortname: 'AZ' },
    { id: 207, code: '995', shortname: 'GE' },
    { id: 208, code: '996', shortname: 'KG' },
    { id: 209, code: '997', shortname: 'KZ' },
    { id: 210, code: '998', shortname: 'UZ' },
];


export {
    countryCodeOptions
};