// types
import { UserListActionTypes, ProfileState } from "./types";

export const INIT_STATE: ProfileState = {
  profileDetails: [],
  isProfileFetched: false,
  getProfileLoading: false,
  isInvited:false,
  isUpdateUser:false,
  userDetails:{}
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UserListActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserListActionTypes.GET_USER_LIST:
          return {
            ...state,
            profileDetails: action.payload.data.data,
            isProfileFetched: true,
            getProfileLoading: false,
          };
        case UserListActionTypes.GET_ALLOCATED_USER_LIST:
          return {
            ...state,
            mappingData: action.payload.data.mappingData,
            profileDetails: action.payload.data.data,
            isProfileFetched: true,
            getProfileLoading: false,
          };
        case UserListActionTypes.POST_USER_ADD:
          return {
            ...state,
            isInvited:action.payload.data,
          };
        case UserListActionTypes.GET_USER_DETAILS:
          return {
            ...state,
            userDetails:action.payload.data
          };
        case UserListActionTypes.USER_UPDATE:
          return {
            ...state,
            isUpdateUser:action.payload.data.success
          };
          case UserListActionTypes.DELETE_USER:
            return {
              ...state,
              userDeleteSuccess: action.payload.data.success,
              userDetails: action.payload.data.data,
              isProfileFetched: true,
              getProfileLoading: false,
            };
        default:
          return state;
      }

    case UserListActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserListActionTypes.GET_USER_LIST:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            isInvited:false
          };
        case UserListActionTypes.GET_ALLOCATED_USER_LIST:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            isInvited:false
          };
        case UserListActionTypes.POST_USER_ADD:
          return {
            ...state,
            isInvited:false
          };
        case UserListActionTypes.GET_USER_DETAILS:
          return {
            ...state,
            userDetails:{}
          };
        case UserListActionTypes.USER_UPDATE:
          return {
            ...state,
            isUpdateUser:false
          };
        case UserListActionTypes.DELETE_USER:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            userDeleteSuccess:false
          };

        default:
          return state;
      }

    case UserListActionTypes.GET_USER_LIST: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        isInvited:false,
        isUpdateUser:false,
      };
    }
    case UserListActionTypes.GET_ALLOCATED_USER_LIST: {
      return {
        ...state,
        getProfileLoading: true,
        isProfileFetched: false,
        isInvited:false,
        isUpdateUser:false,
      };
    }

    case UserListActionTypes.POST_USER_ADD: {
      return {
        ...state,
      };
    }
    case UserListActionTypes.GET_USER_DETAILS:
      return {
        ...state,
        userDetails:{}
      };
      case UserListActionTypes.DELETE_USER: {
        return {
          ...state,
          getProfileLoading: true,
          isProfileFetched: false,
          userDeleteSuccess:false
        };
      }
    default:
      return state;
  }
};

export default Profile;
