// types
import { UpdateTourVisitedActionTypes, ProfileState } from "./types";

export const INIT_STATE: ProfileState = {
  profileDetails: {},
};

const Profile = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UpdateTourVisitedActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED:
          return {
            ...state,
           updateTourVisited : action.payload.data,
          };
        default:
          return { ...state };
      }

    case UpdateTourVisitedActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED:
          return {
            ...state,
           
          };

        default:
          return { ...state };
      }

    case UpdateTourVisitedActionTypes.POST_UPDATE_TOUR_VISITED: {
      return {
        ...state,
       
      };
    }

    default:
      return { ...state };
  }
};

export default Profile;
