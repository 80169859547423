import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const templateCreate = (data:any) => {
  return api.createWithFile(url.TEMPLATE_CREATE, data);
};
const templateUpdate = (data:any) => {
  return api.create(url.TEMPLATE_UPDATE, data);
};

const templateDelete = (data:any) => {
  return api.update(url.TEMPLATE_DELETE, data);
};

const getTemplateList = (data ?:any) => {
  if(data){
    return api.get(`${url.GET_TEMPLATE_LIST}?from=${data}`);
  }else {
    return api.get(url.GET_TEMPLATE_LIST);

  }
};

const getTemplateDetails = (id:string) => {
  return api.get(url.GET_TEMPLATE_DETAILS+"/"+id);
};
export { templateCreate, getTemplateList, getTemplateDetails, templateUpdate, templateDelete };
