import React,{useState,useEffect} from 'react'
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style'
import { Button, Card, Pagination, Table } from 'antd'
import { useProfile, useRedux } from '../../hooks'
import { getContacts, importContacts } from '../../redux/actions'
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dropdown } from 'react-bootstrap'
import whatsappLogo from "../../assets/images/whatsapp_logo.png";
import whatsappBusinessLogo from "../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../assets/images/channelListIcon/telegram_icon.png";
const  Contacts = () => {
    const { userProfile } = useProfile()
    const { dispatch,useAppSelector } = useRedux()
    const { userData ,clientList} = useAppSelector((state) => {
        return {
            userData : state.Login.user,
            clientList: state?.Chats?.clientList,

        };
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const handlePageChange = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);
    };
    var mergedData: any
    
    useEffect(() => {
        dispatch(getContacts({ clientId: userProfile?.clientId, skip: 0, limit: pageSize, key: "All" }, (response: any) => {
            console.log("🚀 ~ dispatch ~ response ::::::::")
        }))
        setSelectedValue(userProfile?.clientId)
    },[])

    const [importFileModal, setImportFileModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    const [selectedValue, setSelectedValue] = useState('');
    const toggle = () => setImportFileModal(!importFileModal);
    const handleFileUpload = (e:any) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        if (selectedFile && selectedValue) {
            const clientId =  selectedValue
            const data = {
                attachments : [selectedFile],
                clientId : clientId
            }
            dispatch(importContacts(data,clientId))
        }
        toggle();
    };
    

    const handleInputChange = (value: any) => {
        setSelectedValue(value);
    };
    const getName = (clientList:any,selectedValue:string) => {
        let listName = clientList?.findIndex((item: any) =>(item.clientId == selectedValue))
        if(listName !== -1){
            return (
                <>
                    <div className='d-flex align-items-center'>
                        <span className='me-1 mb-1'>
                            {clientList[listName].type == "whatsapp" ? 
                            <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                            :clientList[listName].type == "business_whatsapp" ? <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo'/>:clientList[listName].type == "telegram" && <img src={telegramLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo'/>}
                            
                        </span>
                        <span className="text-truncate wpLinkedUserName me-1">
                            {clientList[listName]?.pushname}
                        </span>
                        <span className="text-muted wpLinkedUserNumber">
                            ( +{clientList[listName]?.me?.user} )
                        </span>
                    </div>
                </>
                )
        }
    }
    const paginationConfig = {
        current: currentPage,
        pageSize: pageSize,
        total: mergedData?.length,
        onChange: handlePageChange,
        showSizeChanger: true,
        onShowSizeChange: handlePageChange,
    };
    const slicedData = mergedData?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
    );
    const columns :any =[]
    // const columns: ColumnsType<UserList> = [
    //     {
    //         title: 'Name',
    //         dataIndex: 'pushname',
    //     },
    //     {
    //         title: 'Phone Number',
    //         dataIndex: 'me',
    //         render: (me: any) => {
    //             return <span>{me ? me.user : "-"}</span>;
    //         },
    //     }, {
    //         title: 'Channel Type',
    //         dataIndex: 'type',
    //         render: (type: any) => {
    //             console.log("🚀 ~ file: index.tsx:240 ~ type:", type)
    //             return <span>{type?`${type.charAt(0).toUpperCase() + type.slice(1)}`  : "-"}</span>;
    //         },
    //     },
    //     {
    //         title: 'Status',
    //         dataIndex: 'isSessionSaved',
    //         render: (isSessionSaved: boolean, __: any) => {
    //             if (__?.isWhatsappBusiness) {
    //                 return __?.whatsappVerificationStatus;
    //             } else {
    //                 return <span className={`${(isSessionSaved || __.isReady) ? "statusOnline" : "statusOffline"}`}>{(isSessionSaved || __.isReady) ? "Online" : "Offline"}</span>;
    //             }

    //         },
    //     },
    //     {
    //         title: 'Login',
    //         dataIndex: 'sessionSavedOn',
    //         render: (sessionSavedOn: any) => {
    //             const formattedTime = sessionSavedOn && moment(sessionSavedOn)?.tz(time_zone)?.format('DD-MM-YYYY HH:mm');
    //             return <span>{formattedTime}</span>;
    //         },
    //     },
    //     {
    //         title: 'Logout',
    //         dataIndex: 'sessionRemovedOn',
    //         render: (sessionRemovedOn: any, __: any) => {
    //             if (!__.isSessionSaved && !__.isReady) {
    //                 const formattedTime = sessionRemovedOn && moment(sessionRemovedOn)?.tz(time_zone)?.format('DD-MM-YYYY HH:mm');
    //                 return <span>{formattedTime}</span>;
    //             }
    //         },
    //     },
    //     {
    //         title: 'Owner',
    //         dataIndex: 'numberOwner',
    //         render: (numberOwner: any , __: any) => {
    //             return (<span>{__?.userId ? __.userId.fullName || __?.userId?._id?.fullName :  "-"}</span>);
    //         },
    //     },

    //     {
    //         title: 'Action',
    //         // render: (__: any, getUserListData: any, index: any) => {
    //         //     return (
    //         //         <>
    //         //             <div className='d-flex'>

    //         //                 {(!getUserListData?.isSessionSaved && !__.isReady && !__.isWhatsappBusiness)  && (__?.userId._id == userProfile.userId) && __?.type == "whatsapp" && <>
    //         //                     <Button id={`rescanButton-${__._id}`}
    //         //                         onClick={() => handleRescan(__)}
    //         //                         className="btn btn-outline-secondary scanBtn d-flex align-items-center justify-content-center p-2 me-2"
    //         //                     >
    //         //                         <i className='mdi mdi-qrcode-scan'></i>
    //         //                     </Button>
    //         //                     <UncontrolledTooltip target={`rescanButton-${__._id}`} placement="bottom">
    //         //                         Rescan
    //         //                     </UncontrolledTooltip>
    //         //                 </>
    //         //                 }

    //         //                 {(__?.isWhatsappBusiness) &&
    //         //                     <>
    //         //                         <Button id={`embededLink-${__._id}`}
    //         //                             onClick={() => handleEmbededUrl(__)}
    //         //                             className="btn btn-outline-secondary scanBtn d-flex align-items-center justify-content-center me-2 p-2"
    //         //                         >
    //         //                             <i className='bx bx-link-alt'></i>
    //         //                         </Button>
    //         //                         <UncontrolledTooltip target={`embededLink-${__._id}`} placement="bottom">
    //         //                             Registration Link
    //         //                         </UncontrolledTooltip>
    //         //                     </>
    //         //                 }


    //         //                 {/* {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && <><Button id={`logoutButton-${__._id}`} onClick={() => showModal(__, __._id,"disconnect")} className="btn btn-outline-warning scanBtn d-flex align-items-center justify-content-center me-2 p-2">
    //         //                 <i className='mdi mdi-power-standby'></i>
    //         //             </Button>
    //         //             <UncontrolledTooltip target={`logoutButton-${__._id}`} placement="bottom">
    //         //                 Disconnect
    //         //             </UncontrolledTooltip>
    //         //             </>} */}

    //         //                 {(__?.userId?._id == userProfile.userId) &&
    //         //                     <>
    //         //                         <Link type="button" color="primary" id={`numberEdit-${__._id}`} className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2 btn-sm' to={`/number-edit/${__._id}`} state={{ itemIndex: index , numberData: __}}>
    //         //                             <i className='mdi mdi-pencil font-size-14 lh-sm'></i>
    //         //                         </Link>
    //         //                         <UncontrolledTooltip target={`numberEdit-${__._id}`} placement="left">
    //         //                             Allocated User Update
    //         //                         </UncontrolledTooltip>
    //         //                     </>
    //         //                 }


    //         //                 {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && !__.isWhatsappBusiness && <><Button id={`deleteButton-${__._id}`} onClick={() => showModal(__, __._id, "delete")} className="btn btn-outline-danger btnDanger d-flex align-items-center justify-content-center p-2">
    //         //                     <i className='mdi mdi-delete-outline'></i>
    //         //                 </Button>
    //         //                     <UncontrolledTooltip target={`deleteButton-${__._id}`} placement="right">
    //         //                         Delete
    //         //                     </UncontrolledTooltip>
    //         //                 </>}



    //         //             </div>
    //         //             <Modal title={modalTitle} open={isModalOpen} onOk={modalTitle == "Privacy" ?  () => handleOk1(privacyId) :  () => handleOk(deleteId)} onCancel={handleCancel} okButtonProps={{ disabled: okClicked }}>
    //         //                 <p>{modalBodyText}</p>
    //         //             </Modal>
    //         //             {isOpenAddContact && (
    //         //                 <InviteContactModal
    //         //                     isOpen={isOpenAddContact}
    //         //                     onClose={closeAddContactModal}
    //         //                     onInvite={onInviteContact}
    //         //                     editModal={true}
    //         //                     editNumberDetails={editNumberDetails}
    //         //                 />
    //         //             )}
    //         //         </>
    //         //     );
    //         // },
    //     }
    // ];
    return (
        <>
            <TableListingPagesWrapper>
                <div className='tableListingMain user-chat overflow-hidden user-chat-show'>
                    {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">WhatsApp Numbers</h5>
                        <div>
                            { <Button className='py-1 px-2 btn btn-primary font-size-13 addWpBtn' onClick={userAddHandleClick} type="primary">Add WhatsApp</Button>}
                        </div>
                    </div>
                </Card> */}
                    <div className='userListCardMain p-3'>
                        <Card
                            className='userListCard'
                            title={
                                <div className='d-flex flex-column py-2'>
                                    <h2 className='mb-1'>Contacts</h2>
                                    <p className='mb-0'>
                                        You can directly import contact from here.
                                        <a href='https://controlhippo-whatsapp-web-sessions.s3.ap-south-1.amazonaws.com/A_front_end/import_contact_controlhippo.csv' className='ms-1 text-decoration-underline' target='_blank'>Sample file</a>
                                        {/* <a href='https://support.controlhippo.com/support/solutions/articles/1060000057395-how-to-allocate-numbers-to-the-users-in-controlhippo-' className='ms-1 text-decoration-underline' target='_blank'>Need Help ?</a> */}

                                    </p>
                                </div>
                            }
                            extra={<><Button className='py-1 px-2 btn btn-primary'type='primary' onClick={toggle}><i className='bx bx-upload'></i>&ensp; Import Contact</Button> </>}>
                            <Table
                                //rowSelection={rowSelection}
                                columns={columns}
                                dataSource={(slicedData || []).map((item: any, index: number) => ({ ...item, key: index }))}
                                pagination={false}
                                style={{ width: '100%' }}                                     
                            />
                            <div className='tablePaginationPos'>
                                <Pagination {...paginationConfig} />
                            </div>
                        </Card>
                    </div>
                </div>
            </TableListingPagesWrapper>

            <Modal isOpen={importFileModal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Upload File</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="fileInput">Select Number</Label>
                            <div className="d-flex align-center w-100 userNewDropdownMain">
                                <Dropdown className='userNewDropdown w-100' onSelect={handleInputChange}>
                                    <Dropdown.Toggle variant="success" className='text-truncate bg-white w-100' id="dropdown-basic">
                                        {getName(clientList, selectedValue)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="w-100">
                                        {/* <span className='dotactive'></span> */}
                                        {clientList?.map((item: any) => (
                                            <Dropdown.Item key={item.clientId} eventKey={item.clientId} className={"custom-option"}>
                                                <div className='d-flex align-items-center'>
                                                    <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                                        {item.type == "telegram" ? <img src={telegramLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' /> : item.type == "whatsapp" ?
                                                            <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                                                            : item.type == "business_whatsapp" && <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' />
                                                        }
                                                    </span>
                                                    <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline " : "dropItemUserNameOffline"} me-1`}>
                                                        {item?.pushname}
                                                    </span>

                                                    <span className="text-muted dropItemUserNumber">
                                                        ( +{item.me?.user} )
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                        ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="fileInput">Choose File</Label>
                            <Input type="file" name="file" id="fileInput" onChange={handleFileUpload} />
                        </FormGroup>
                        <FormGroup>
                            <div className='text-center'>
                                <Button className="btn btn-primary" disabled={!selectedFile || !selectedValue} type='primary' htmlType="submit" >
                                    <i className='bx bx-upload' > </i> <span> &ensp; Upload</span>
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Contacts