//auth
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_JWT_LOGIN = "/auth/Login";
export const POST_JWT_LOGIN_WITH_GOOGLE = "/auth/googlesignin";
export const POST_FCM_TOEKN = "/auth/fcmtoken";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/auth/forgot-pwd";
export const POST_CHNAGE_PASSWORD = "/auth/changePassword";
export const POST_CONFIRM_DATA = "/auth/confirmaccount";
export const POST_UPDATE_PASSWORD = "/auth/update-pwd";
export const POST_UPDATE_SUBUSER_PASSWORD = "/auth/user/register";
export const SOCIAL_LOGIN = "/social-login";
export const LOGOUT = "/auth/logout";
export const CANCEL_ACCOUNT = "/auth/cancelAccount";
export const JWT_REGISTER = "/auth/signup";
export const UPDATE_PROFILE = "/auth/user";
export const POST_FAKE_REGISTER = "/post-fake-register";

export const USER_CHANGE_PASSWORD = "auth/reset-pwd";
export const USER_CHANGE_EMAIL = "auth/change-email";

// profile & settings
export const GET_PROFILE_DETAILS = "/user/user-profile";
export const GET_USER_SETTINGS = "/user-settings";
export const UPDATE_SETTINGS = "/user/update-user-settings";
export const UPDATE_USER_PROFILE = "/user/update-profile";
export const UPDATE_TOUR_VISITED = "/user/update-tour-visited";

// contacts
export const GET_CONTACTS = "/user-contacts";
export const INVITE_CONTACT = "/chats/addContact";
export const IMPORT_CONTACTS = "/chats/importContact";
export const CHANGE_PRIVACY_SETTING_URL = "/chats/change-privacy-setting";
export const USER_ADD = "/auth/user/add";
export const GET_CLIENT_DETAILS = "/whatsapp/getClientDetails";

// calls
export const GET_CALLS_LIST = "/calls-list";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_FAVOURITES = "/get-favourites";
export const GET_DIRECT_MESSAGES = "/chats/getChatList";
export const GET_CHANNELS = "/get-channles";
export const ADD_CONTACTS = "/add-contact";
export const CREATE_CHANNEL = "/whatsapp/createGroup";
export const ADD_PARTICIPANTS = "/whatsapp/addParticipants";
export const REMOVE_PARTICIPANTS = "/whatsapp/removeParticipants";
export const SYNCING_STOP_TO_CRM = "/chats/syncingStop";
export const MAKE_AS_ADMIN = "/whatsapp/promoteParticipants";
export const DISMISS_AS_ADMIN = "/whatsapp/demoteParticipants";
export const GET_CHAT_USER_DETAILS = "/chats/getChatUserDetails";
export const CHANGE_ASSIGN_USER = "/chats/changeAssignUser";
export const DOWNLOAD_CHAT = "/chats/downloadChat";
export const GET_CHAT_USER_CONVERSATIONS = "/chats/getChatUserConversations";
export const SEND_MESSAGE = "/whatsapp/sendMessage";
export const SEND_TELEGRAM_MESSAGE = "/telegram/sendMessage";
export const SEND_CHATBOT_MESSAGE = "/chatbot/sendMessage";
export const SEND_MESSENGER_MESSAGE = "/sendMessage";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/whatsapp/deleteMessage";
export const WHATSAPP = "/whatsapp";

export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";

export const GET_CLIENT_LIST = "/chats/clients"; // Get Client List
export const GET_CHANNEL_LIST = "/chats/clients"; // Get Channel List
export const GET_TEMPCLIENT_LIST = "/chats/clients"; // Get Template List
export const GET_REPORT_LIST = "/chats/clients"; // Get Repost  List
export const GET_CAMPAIGN_LIST = "/chats/clients"; // Get Campaign  List
export const GET_ALLOCATION_LIST = "/chats/clients"; // Get Allocation List
export const GET_DOWNLOAD_DATA = "/chats/download"; 
export const CHATS = "/chats"; 
// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";

// QR-Code
export const GET_QR_CODE = "/whatsapp/createSession";
export const CONNECT_WHATSAPP_BUSINESS = "/whatsappbusiness/connectWhatsAppBusiness";

// Integrations
export const SAVE_INTEGRATION = "/integration/oauth/callback"; // save integrations
export const GET_INTEGRATIONS = "/integration"; // get integrations
export const REMOVE_INTEGRATIONS = "/integration"; // remove integrations
export const REMOVE_SLACK_CHANNEL = "/integration/slack/removeChannel"; // remove integrations
export const ADD_BOARD_ID = "/integration/addBoardId"; // remove integrations
export const CHECK_URL = "/integration/checkUrl"; // checkUrl
export const UPDATE_INTEGRATION = "/integration/update"; // checkUrl

// User
export const GET_USER_LIST_URL = "/auth/users";
export const GET_ALLOCATED_USER_LIST_URL = "/auth/user/userAllocation";
export const DELETE_USER = "/auth/user/delete/";
export const CREATE_USER = "/chats/userCreate";
export const SEND_SEEN_API_URL = "/chats/sendSeen";
export const EDIT_WHATSAPP_NUMBER = "/auth/user/userAllocation";
export const GET_USER_DETAILS_URL = "/auth/user";
export const USER_UPDATE = "/auth/user/allocatedNumberUpdate";

// Number
export const DELETE_NUMBER = "/auth/number/"
export const CREATE_CHANEL = "/auth/createChannel"


// Plan & Billing
export const BILLING_PLANS = "/planbilling"
export const CHARGEBEE = "/chargebee"

//Business
export const TEMPLATE_CREATE = "/whatsappbusiness/templateCreate";
export const TEMPLATE_UPDATE = "/whatsappbusiness/templateUpdate";
export const TEMPLATE_DELETE = "/whatsappbusiness/templateDelete";
export const GET_TEMPLATE_LIST = "/whatsappbusiness/getTemplateList";
export const GET_TEMPLATE_DETAILS = "/whatsappbusiness/getTemplateDetails";

//Campaign
export const CAMPAIGN = "/campaign";

// Reports
export const REPORTS = "/report";

//Labels
export const LABEL_SAVE_URL = "/chats/labelUpdate"
export const UPDATE_ACCOUNT_SETTING_URL = "/user/update-account-setting"

// Telegram
export const  CONNECT_TELEGRAM = "/telegram"

//Messenger
export const  CONNECT_MESSENGER = "/messenger"
export const  CONNECT_INSTAGRAM = "/instagram"
export const  CONNECT_CHATBOT = "/chatbot"
export const  GET_OLD_CLIENT_ID_URL = "/getOldClientId"
export const GET_MESSENGER_PAGE_LIST_URL = "/messenger/getPageList"
export const UPDATE_CLIENT_DETAILS_URL = "/updateClientDetails"