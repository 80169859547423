import { AuthConfirmDataActionTypes } from "./types";

// common success
export const confirmUserDataApiResponse = (
  actionType: string,
  data: any
) => ({
  type: AuthConfirmDataActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const confirmUserDataApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AuthConfirmDataActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const userConfirmData = (data: any) => {
  return {
    type: AuthConfirmDataActionTypes.CONFIRM_DATA,
    payload: data,
  };
};

