import React, { useEffect, useState } from "react";
import { TABS } from "../../../constants/index";
import { Button, Col, Row, Spinner, UncontrolledTooltip,Form,Label,Input } from "reactstrap";
import Select from "react-select";
import { Card} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import Privacy from "../../Dashboard/Settings/Privacy";
import { setClientId, getQrCode, resetClientProcess, getClientsList, changeTab, connectWhatsAppBusiness, updateSettings, clearSettingsState, channelCreate  , connectTelegram, systemLogoutUser} from '../../../redux/actions';
import ProgressBarComponent from "../../../components/ProgressBar";
import ControllhippoLogo from '../../../assets/images/ControlHippo-blue.svg';
import UpGradePlanPopup from "../../PlanBilling/UpGradePlanPopup";
import AddonsPopup from "../../PlanBilling/AddonsPopup";
import ChannelModal from "../../../components/channelModal";
import { QrCodeWrapper } from '../QrCodeWrapper.style';
// import scss
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications"
import EstimatePopup from "../../../components/EstimatePopup";
import { checkEstimate } from "../../../helpers/checkEstimate";
import { checkPlanAndFeature } from "../../../helpers/common.service";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
import { skipPartiallyEmittedExpressions } from "typescript";
const countryData = require('country-data');
interface IndexProps { }
interface AddonsTypes {
  name:string;
  isActive:boolean;
}
interface UserDetailsTypes {
  email?: string;
  userId?: string;
  clientId?: string;
  chargebeeDetails?: any;
  planDetails?: any;
  fullName?: string;
  phoneNumber?: string;
  userActive?: boolean,
  allAddons?:[AddonsTypes]
  country_code?: string;
}

const BusinessWhatsappApi = (props: IndexProps) => {
  // const [ChannelList, setChannelList] = useState<ChannelList>(channelList);
  const navigate = useNavigate();
  const authUserData: any | null = useState(localStorage.getItem("authData"));
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [inputText, setInputText] = useState('');
  const [newClientId, setNewClientId] = useState("");
  const [userIdDetails, setUserIdDetails] = useState<string | boolean>(false);
  const [titleText, setTitleText] = useState({ text: "", part: 1, progress: 0 });
  const [qrCode, setQrCode] = useState('');
  // const randomNum = generateRandomNumber(4);
  const [curruntStep, setCurruntStep] = useState<number>(0)
  const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
  const [channelModalOpen, setChannelModalOpen] = useState(false)
  const [channelData, setChannelData] = useState("")
  const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
  // const showModalToggle = () => setSelectModal(!selectModal);
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const rescanNumberParam = searchParams.get('rescan');
  const {
    isClientIdDetails,
    clientAuthenticated,
    clientReady,
    chatSyncingStart,
    userData,
    getQrCodeDetails,
    clientsPayload,
    connectWhatsappBusiness,
    settingsData,
    userDetails1,
    isSettingsUpdated,
    tokenModelDetails,
    isUserLogin
  } = useAppSelector(state => ({
    isClientIdDetails: state.QrCode.isClientIdDetails,
    clientAuthenticated: state.QrCode.clientAuthenticated,
    clientReady: state.QrCode.clientReady,
    chatSyncingStart: state.QrCode.chatSyncingStart,
    getQrCodeDetails: state.QrCode.getQrCodeDetails,
    connectWhatsappBusiness: state.QrCode.connectWhatsappBusiness,
    userId: state.confirmAccount.authData?.userId,
    userData: state.Login.user,
    clientList: state.Chats.clientList,
    clientsPayload: state.Chats.clientsPayload,
    settingsData: state.Settings.settings,
    tokenModelDetails: state.Settings.tokenModelDetails,
    userDetails1: state.Login.user,
    isSettingsUpdated: state.Settings.isSettingsUpdated,
    isUserLogout: state.Login.isUserLogout,
    isUserLogin: state.Login.isUserLogin

  }));
  const [settings, setSettings] = useState(settingsData);
  const [conformationPopupData, setConformationPopupData] = useState<{isOpen:boolean,message?:string,from?:any,type?:String}>({isOpen:false})
 
  useEffect(() => {
    setSettings({
      ...settings,
      basicDetails: userDetails1,
      privacy: userDetails1.privacy
    })
  }, [userDetails1])



  useEffect(() => {
    if (isSettingsUpdated) {
      showSuccessNotification({ message: "Setting successfully updated." })
      dispatch(clearSettingsState());
      dispatch(getClientsList());
    }
  }, [isSettingsUpdated]);


  useEffect(() => {
    const data: any = JSON.parse(authUserData[0]);
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const addNumberParam = searchParams.get('addNumber');
    if ((userProfile && isUserLogin &&!userProfile?.authToken) || (isUserLogin && data && !data.authToken)) {
      dispatch(systemLogoutUser())
      navigate("/auth-login");
    } else if (isUserLogin && userProfile &&userProfile?.clientId && userProfile?.isSessionSaved == "true" && !addNumberParam) {
      dispatch(changeTab(TABS.CHAT));
      localStorage.setItem("selectedChatFilter", "All");
      navigate("/dashboard");
    } else if (isUserLogin && userProfile && userProfile?.clientId && userProfile?.isSessionSaved == "false") {
      dispatch(changeTab(TABS.CHANNELS));
      navigate("/welcome");
    }
  }, [userProfile,isUserLogin])

  useEffect(() => {
    if (userData?.chargebeeDetails) setChargebeeDetails(userData?.chargebeeDetails)
    setUserDetails(userData)
  }, [userData])

  useEffect(() => {
    setUserDetails(userData)
    setChargebeeDetails(userData.chargebeeDetails)
  }, [userData])


  useEffect(() => {
    if (connectWhatsappBusiness?.success) {
        setLoading(false)
        if (connectWhatsappBusiness?.link?.embeddedSignupURL) {
          const redirectUrl = connectWhatsappBusiness?.link?.embeddedSignupURL
          window.open(redirectUrl, '_blank');
        }
    }
  }, [connectWhatsappBusiness])

  useEffect(() => {
    if (!getQrCodeDetails.success && getQrCodeDetails.paymentFail) {
      setQrCode("");
      setShow(false)
      setLoading(false);
    }
  }, [getQrCodeDetails])


  const onConnectWhatsAppBusiness = async (event:any,response:any) => {
    setLoading(true)
    const objData = {
      item: "addon",
      eventType: "update_subscription_for_items",
      name: "waba",
      userDetails,
      dispatch
    }
    if(response.skipEstimate){
      onConfirm({from : "waba"})
      return
    }
    let checkData: any = await checkEstimate(objData)
    if (checkData?.success) {
      if (checkData?.message) {
        setConformationPopupData({
          isOpen: true,
          message: checkData?.message,
          from: "waba"
        })
      } else {
        onConfirm({from : "waba"})
      }
    }
  }
  useEffect(() => {
    setQrCode('')
    setShow(false)
    dispatch(getClientsList())
  }, [])

  
  useEffect(() => {
    if (clientsPayload?.success) {
      updateAuthData(clientsPayload.authData)
    }
  }, [clientsPayload])

  function generateRandomNumber(minLength: number): number {
    const min = Math.pow(10, minLength - 1);
    const max = Math.pow(10, minLength) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    var clientID = newClientId ? newClientId : inputText
    if (isClientIdDetails.success && (isClientIdDetails.clientId == clientID)) {      
      setTitleText({ text: "Scan In WhatsAPP", part: 1, progress: 0 })
      setQrCode(isClientIdDetails.url) // QR Code generate
      setLoading(false)
      setShow(true)
    }
  }, [isClientIdDetails]);

  useEffect(() => {
    if (clientAuthenticated) {
      addMixpanelEvent("Whatsapp authenticated", { distinct_id: userProfile?.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 30 });
    }

  }, [clientAuthenticated])

  useEffect(() => {
    const clientID = inputText
    if (clientReady.success && (clientReady.clientId === clientID || clientReady.clientId === newClientId)) {
      dispatch(setClientId(clientReady.clientId))
      addMixpanelEvent("Scan successfully", { distinct_id: userProfile?.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 60 });
      setQrCode("")
      setShow(false)
    }
  }, [clientReady])

  useEffect(() => {
    if (chatSyncingStart) {
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 98 });
      dispatch(resetClientProcess())

      setTimeout(() => {
        navigate("/dashboard");
        localStorage.setItem("selectedChatFilter", "All");
        dispatch(changeTab(TABS.CHAT));
        setTitleText({ text: "Enter client ID", part: 1, progress: 0 })
      }, 1000);
    }

  }, [chatSyncingStart])

  const onCancel = ()=>{
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : ""
    })
    if(loading)setLoading(false)
    if(show)setShow(false)
  }
  const onConfirm = (data:any) => {
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : "",
      type:""
    })
   if(data.from == "waba"){
      let newClientId = `${userProfile?.userId + "_" + generateRandomNumber(4)}`;
      if(checkPlanAndFeature({featureName:"w_business_api",planDetails: userProfile?.planDetails,featureDisplayName:"Business Whatsapp API "})){
        dispatch(connectWhatsAppBusiness(newClientId))
      }
    }
  }
  const checkButton = () => {
    const addons = chargebeeDetails?.addons
    let wabaAddons: any | undefined;
    if (addons && addons.length) {
      wabaAddons = addons.find((element: any) => element.item_price_id.includes("waba"));
    }
    if ((userDetails?.chargebeeDetails?.status == "active" && wabaAddons && wabaAddons?.quantity > 0) || (userDetails?.chargebeeDetails?.card_status == "valid" && (userDetails?.chargebeeDetails?.status == "active" || userDetails?.chargebeeDetails?.status == "in_trial" || !wabaAddons))) {
      return (
        <Button onClick={(event)=>onConnectWhatsAppBusiness(event,{skipEstimate:false})} disabled={loading} className="text-center btn btn-primary py-2 px-5">
          Connect
        </Button>)
    } else if (userDetails?.chargebeeDetails?.card_status == "valid" && (userDetails?.chargebeeDetails?.status == "active" || userDetails?.chargebeeDetails?.status == "in_trial" || !wabaAddons)) {
      return (
        <AddonsPopup className="text-center btn btn-primary py-2 px-5 " lable="Connect" addonName={"waba"} />
      )
    } else {
      return (
        <UpGradePlanPopup className="text-center btn btn-primary py-2 px-5 " lable="Connect" from={"WABA"} />
      )
    }
  }

  const onChangeData = (field: string, value: any) => {
    let privacy = settings.privacy
    const payloadData = {
      clientId : newClientId ? newClientId : inputText,
      userId : userDetails?.userId ? userDetails?.userId : userProfile?.userId,
      // numberId : data._id,
      value : value
  }

    privacy = {
      ...privacy,
      ...value
    };
    dispatch(updateSettings(field, privacy, payloadData));
  };


  const handleChannelPopup = (data: any) => {
    setChannelModalOpen(true)
    setChannelData(data)
  }


  const channelConnect = (data: any) => {
    dispatch(channelCreate(data))
  }

  const closeChannelModal = () => {
    setChannelModalOpen(false);
  };


  const handleBackButton = () => {
    dispatch(changeTab(TABS.CHANNELS))
    navigate("/welcome")
  }
  const buttonClicked = (response: any) => {
    if (response.success) {
      const syntheticEvent = {} as React.FormEvent<HTMLFormElement>;
      return onConnectWhatsAppBusiness(syntheticEvent,response)
    }
  }
  return (
    <>
      <QrCodeWrapper className="QrCodeWrapper">
      <div className="chat-welcome-section user-chat user-chat-show d-flex align-item-center h-100">
        <Row className={`w-100 m-0 ${curruntStep === 1 || curruntStep === 2 || curruntStep === 4 ? "wpQrScannerMainrow" : ""}`}>
          <Col md={12} className="p-0">
            {/* <div> */}
            {titleText.part == 2 && <h4 className="text-center mb-4">{titleText.text}</h4>}
            {titleText.part == 1 &&
                <Card className={`${curruntStep == 0 ? "whenCurruntStepZero" : ""} p-3 wpQrScanCardMain h-100`}>
                  <div className="mb-lg-4 mb-2 w-100 d-flex align-items-center gap-2">
                    {/* For whatsapp name title start */}
                    <div className="ms-1 float-left">
                      <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                    </div>
                    <div className="qrCodePageCardTitleMain">
                        <>
                            <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                                <b>WhatsApp Business API {rescanNumberParam && `[ +${rescanNumberParam} ] `}</b>
                                <a id="whatsppbusinesscard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/support/solutions/articles/1060000057242-how-to-integrate-whatsapp-business-api-in-controlhippo-" target="_blank">
                                    <i className="bx bxs-info-circle font-size-17 lh-sm"></i>
                                </a>
                            </h2>
                            <UncontrolledTooltip
                                target={`whatsppbusinesscard`}
                                placement="right"
                            >
                                Click here to know more..
                            </UncontrolledTooltip>
                        </>
                    </div>

                    {/* For whatsapp name title end */}
                  </div>
                  {/* <hr className="border border-primary border-2 opacity-50" /> */}
                  {<Row className="align-items-center qrmainrow wpBusinnessMainRow">
                    <Col md={12} lg={7} xl={8} className="ps-4">
                      <div className="qrGenerateSteps mb-4">
                        <h5 className="text-start mb-3">Step to connect WhatsApp Business API </h5>
                        <div className="alert alert-info mb-2 font-size-13" role="alert">Note: Your new Number should not be linked to any WhatsApp Account</div>
                        <ol className="text-start mb-0 d-flex flex-column gap-2">
                          <li>Access to your business's Meta Business manager if you have one</li>
                          <li>Manage WABA Phone Numbers. <a className="link-opacity-100-hover" href="https://developers.facebook.com/docs/whatsapp/guides/phone-number/">WhatsApp requirements</a></li>
                          <li>The WhatsApp display name you want to use, which fits <a className="link-opacity-100-hover" href="https://developers.facebook.com/docs/whatsapp/guides/display-name/#guidelines">WhatsApp’s guideline</a></li>
                        </ol>
                      </div>
                      <div className="qrGenerateSteps iostext">
                        <h5 className="text-start mb-2">What else you should know?</h5>
                        <ol className="text-start mb-0 d-flex flex-column gap-2">
                          <li>Once you have completed this process and get your number live, you’ll be able to use your WhatsApp number.</li>
                          <li>We recommend that you <a href="https://api.support.vonage.com/hc/en-us/articles/4404807738132-What-is-Facebook-Business-Verification-and-How-to-Complete-It"> verify your Business Manager</a> with Meta to make full use of your WhatsApp number.</li>
                        </ol>
                      </div>
                    </Col>
                    <Col md={12} lg={5} xl={4}>
                      <div className="text-center">
                        <div className="d-flex align-items-center row g-4 justify-content-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <img src={`https://images.controlhippo.com/svg/whatsapp-business-chnl.svg`} height={140} width={140} alt="..." />
                          </div>
                          <h4 color="primary" className="font-size-17"><a href="#" >WhatsApp Business</a></h4>
                          <div className="d-flex align-items-center flex-column mt-2">
                          <ChargebeeCheck userDetails={userProfile? userProfile: userDetails1} buttonText={"Connect"} onConfirm={buttonClicked} from="WABA"/>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>}

                  {(curruntStep == 1 || curruntStep == 2) &&
                    <div className="privacyDivQr mt-4">
                      <Privacy privacy={tokenModelDetails} onChangeSettings={onChangeData} qrCodePage={true} />
                    </div>
                  }
                </Card>
            }
            {titleText.part == 2 &&
              <div className="text-center mb-4">
                <div className="card text-center mb-4" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h5 className="card-title">QR Code</h5>
                    {qrCode && (
                      <img src={qrCode} className="card-img-top" alt="..." />
                      // <QRCode className="card-img-top" value={qrCode} />
                    )}
                  </div>
                </div>
              </div>
            }
            {/* </div> */}
            {titleText.part == 3 && <>
              <div className="pb-4 text-center">
                <img src={ControllhippoLogo} height={100} width={250} alt="Controllhippo Logo" />
                <h4 className="mt-3 text-muted readyConnectionTxt">{titleText.text}</h4>
              </div>
              {/* <Loader/> */}
              <div className="d-flex justify-content-center resync mb-5">
                <ProgressBarComponent className={"progressBar"} animated={true} value={titleText.progress} label={false} variant={'primary'} striped={false} visuallyHidden={true} />
              </div>
            </>}
          </Col>
        </Row>
      </div>
      </QrCodeWrapper>

      {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm}/>}
    </>
  );
};

export default BusinessWhatsappApi;
