export enum CampaignActionTypes {
    API_RESPONSE_SUCCESS = "@@campaign/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@campaign/API_RESPONSE_ERROR",
  
    CAMPAIGN_CREATE = "@@campaign/CAMPAIGN_CREATE",
    CAMPAIGN_UPDATE = "@@campaign/CAMPAIGN_UPDATE",
    CAMPAIGN_DELETE = "@@campaign/CAMPAIGN_DELETE",
    CAMPAIGN_LIST = "@@campaign/CAMPAIGN_LIST",
    CAMPAIGN_DETAILS = "@@campaign/CAMPAIGN_DETAILS",

  }
  export interface CampaignState {
    profileDetails: object;
    getCampaignDetails:any;
    campaignList: [];
    loading : boolean;
  }
  
  