import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Checkbox,notification } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

// hooks
import { useRedux, useProfile } from "../../../hooks/index";
import Loader from "../../../../src/components/Loader";

// components
import AudioCallModal from "../../../components/AudioCallModal";
import VideoCallModal from "../../../components/VideoCallModal";
import AddPinnedTabModal from "../../../components/AddPinnedTabModal";
import InviteContactModal from '../../../components/InviteContactModal';
import CustomSelect from "../../../components/CreatableSelect";
// interface
import { PinTypes } from "../../../data/chat";
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';

// actions
import { changeSelectedChat, changeAssignUser } from "../../../redux/actions";

// constants
import { STATUS_TYPES } from "../../../constants";
import { checkPlanAndFeature } from "../../../helpers/common.service";
interface ProfileImageProps {
  chatUserDetails: any;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
  isChannel: boolean;
}
const ProfileImage = ({
  chatUserDetails,
  onCloseConversation,
  onOpenUserDetails,
  isChannel,
}: ProfileImageProps) => {

  function stringsMatch(str1: any, str2: any) {
    if (str1 == null || str2 == null) {
      return false;
    }
    if (str1 !== str2) {
      return str1 === `+${str2}`
    }
    return str1 === str2;
  }
  const phoneNumber = "+" + chatUserDetails?.user;
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const formatPhoneNumber = (phoneNumber: PhoneNumber): string => {
    const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    return formattedNumber.replace(/ /g, ' '); // Add space after each digit
  };
  let decodedNumber: string | null = null;
  let number: PhoneNumber | undefined;
    if (phoneNumber && !chatUserDetails?.isGroup) {
      try {
        number = phoneNumberUtil.parse(phoneNumber);
        decodedNumber = formatPhoneNumber(number);
      } catch (e) {
        console.error('Error parsing phone number:1', phoneNumber);
        decodedNumber = phoneNumber
      }
    }
  
  
  const fullName = !isChannel 
  ? 
  chatUserDetails && chatUserDetails.verifiedName? chatUserDetails.verifiedName : chatUserDetails?.firstName ? chatUserDetails.firstName : chatUserDetails?.user ? `+${chatUserDetails.user}`:""
  : 
  chatUserDetails.name;
  const isStringMatch = stringsMatch(fullName, chatUserDetails?.user)
  const isGroup = chatUserDetails?.isGroup
  const shortName = !isChannel
  ? chatUserDetails && chatUserDetails.pushname
  ? `${chatUserDetails.pushname.charAt(
    0
    )}`
    : "-"
    : "#";

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  const isOnline =
  chatUserDetails && chatUserDetails.status && chatUserDetails.status === STATUS_TYPES.ACTIVE;

  const members = (chatUserDetails && chatUserDetails.members || []).length;
  
  const replaceImage = (error:any) => {
    error.target.src = "https://controlhippo.s3.ca-central-1.amazonaws.com/logo/image+(26).png"
  }

  const handleCopy = () => {
    const textToCopy = document.getElementById('userPhoneNB')?.innerText;
    if (typeof textToCopy === 'string') {
      navigator.clipboard.writeText(textToCopy)
        .then(() => notification.success({ message: 'Copied to clipboard!', duration: 2 }))
        .catch(err => {
          console.error('Failed to copy:', err);
          notification.error({ message: 'Failed to copy!' });
        });
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-lg-2">
        <Link
          to="#"
          onClick={onCloseConversation}
          className="user-chat-remove text-muted font-size-24 py-2 px-1"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div>
      <div className="flex-grow-1 overflow-hidden">
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "flex-shrink-0",
              "chat-user-img",
              "align-self-center",
              "me-lg-2",
              "me-2",
              "ms-0",
              { online: isOnline }
            )}
          >
            {chatUserDetails && chatUserDetails.profilePic ? (
              <>
                <img
                  src={chatUserDetails && chatUserDetails.profilePic}
                  className="rounded-circle avatar-sm"
                  alt=""
                  onError={replaceImage}
                />
                <span
                  className={classnames(
                    {
                      "bg-success":
                        chatUserDetails.status === STATUS_TYPES.ACTIVE,
                    },
                    {
                      "bg-warning":
                        chatUserDetails.status === STATUS_TYPES.AWAY,
                    },
                    {
                      "bg-danger":
                        chatUserDetails.status === STATUS_TYPES.DO_NOT_DISTURB,
                    }
                  )}
                ></span>
              </>
            ) : (
              <div className="avatar-sm align-self-center">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username"> {shortName !== undefined ? shortName : ''}</span>
                  {/* <span className="user-status"></span> */}
                </span>
              </div>
            )}
          </div>
          <div className="flex-grow-1 overflow-hidden">
            {isStringMatch ? <h6 className="text-truncate mb-0 font-size-14">
              <Link
                to="#"
                onClick={onOpenUserDetails}
                className="user-profile-show text-reset"
              >
                {decodedNumber ? decodedNumber : fullName}
              </Link>
            </h6> :
              <>{isGroup ?  <h6 className="text-truncate mb-0 font-size-14">
              <Link
                to="#"
                onClick={onOpenUserDetails}
                className="user-profile-show text-reset"
              >
                {fullName}
              </Link>
            </h6> : <><h6 className="text-truncate mb-0 font-size-14">
                  <Link
                    to="#"
                    onClick={onOpenUserDetails}
                    className="user-profile-show text-reset"
                  >
                    {fullName}
                  </Link>
                </h6>
                <div className="chatNumberMain d-flex align-items-center gap-1">
                  <h6 className="text-truncate mb-0 font-size-11 lh-sm" style={{ color: "grey" }}>
                    <Link
                      to="#"
                      onClick={onOpenUserDetails}
                      className="user-profile-show text-reset"
                      id="userPhoneNB"
                    >
                      {decodedNumber ? decodedNumber : phoneNumber}
                    </Link>
                  </h6>
                  <i className="ri-file-copy-line font-size-11 lh-sm" onClick={handleCopy}></i>
                </div>
                </>}
              </>
            }
            <p className="text-truncate text-muted mb-0">
              {isChannel ? (
                <small>{members} Members</small>
              ) : (
                <small>{chatUserDetails &&  chatUserDetails.status}</small>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        color="none"
        className="btn nav-btn dropdown-toggle"
        type="button"
      >
        <i className="bx bx-search"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
        <div className="search-box p-2">
          <Input type="text" className="form-control" placeholder="Search.." />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
interface MoreProps {
  onOpenAudio: () => void;
  onOpenVideo: () => void;
  onDelete: () => void;
  isArchive: boolean;
  onToggleArchive: () => void;
}
const More = ({
  onOpenAudio,
  onOpenVideo,
  onDelete,
  isArchive,
  onToggleArchive,
}: MoreProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="btn nav-btn" type="button">
        <i className="bx bx-dots-vertical-rounded"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
          to="#"
        >
          View Profile <i className="bx bx-user text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
          onClick={onOpenAudio}
        >
          Audio <i className="bx bxs-phone-call text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center d-lg-none"
          to="#"
          onClick={onOpenVideo}
        >
          Video <i className="bx bx-video text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onToggleArchive}
        >
          {isArchive ? (
            <>
              Un-Archive <i className="bx bx-archive-out text-muted"></i>
            </>
          ) : (
            <>
              Archive <i className="bx bx-archive text-muted"></i>
            </>
          )}
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
        >
          Muted <i className="bx bx-microphone-off text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex justify-content-between align-items-center"
          to="#"
          onClick={onDelete}
        >
          Delete <i className="bx bx-trash text-muted"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

interface PinnedAlertProps {
  onOpenPinnedTab: () => void;
}
const PinnedAlert = ({ onOpenPinnedTab }: PinnedAlertProps) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  return (
    <Alert
      color="warning"
      isOpen={visible}
      toggle={onDismiss}
      className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
      role="alert"
    >
      <div className="d-flex align-items-start bookmark-tabs">
        <div className="tab-list-link">
          <Link to="#" className="tab-links" onClick={onOpenPinnedTab}>
            <i className="ri-pushpin-fill align-middle me-1"></i> 10 Pinned
          </Link>
        </div>
        <div id="add-bookmark">
          <Link to="#" className="tab-links border-0 px-3">
            <i className="ri-add-fill align-middle"></i>
          </Link>
        </div>
        <UncontrolledTooltip target="add-bookmark" placement="bottom">
          Add Bookmark
        </UncontrolledTooltip>
      </div>
    </Alert>
  );
};

interface User {
  _id: string;
  email: string;
  fullName: string;
  assingedUserName: string;
  // Add other properties as needed
}

interface UserHeadProps {
  chatUserDetails: any;
  pinnedTabs: Array<PinTypes>;
  onOpenUserDetails: () => void;
  onDelete: () => void;
  isChannel: boolean;
  onToggleArchive: () => void;
  onUpdateUserDetails: (eventType:string, data:any) => void;
  labels?:[]
}
const UserHead = ({
  chatUserDetails,
  pinnedTabs,
  onOpenUserDetails,
  onDelete,
  isChannel,
  onToggleArchive,
  labels,
  onUpdateUserDetails
}: UserHeadProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const [editNumberDetails, setEditNumberDetails] = useState("");
  const [userLists, setUserLists] = useState<User[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // Use User | null as the type
  const { userProfile } = useProfile();

  const { createdUser, userData, assignUserList, chatUserDetails1, chatUserConversations } = useAppSelector(state => ({
    createdUser: state.Chats.createdUser,
    assignUserList: state.Chats.assignUserList,
    chatUserDetails1: state.Chats.chatUserDetails,
    userData: state.Login.user,
    chatUserConversations: state.Chats.chatUserConversations,
  }));

  useEffect(() => {
   if(createdUser?.isNameMissing){
    setIsOpenAddContact(true)
    setEditNumberDetails(createdUser)
   }
  }, [createdUser])

  useEffect(() => {

    if(chatUserDetails1?.labels &&  chatUserDetails1?.labels.length){
      var labels = JSON.stringify(chatUserDetails1.labels).replaceAll("\"_id\":", "\"value\":")
      labels = labels.replaceAll("\"displayName\":", "\"label\":")
      setLabelValues(JSON.parse(labels))
    }else{
      setLabelValues([])
    }
    if(chatUserDetails1?.assingedUserName){
      setSelectedUser(chatUserDetails1)
    }else{
      setSelectedUser(null)
    }
  }, [chatUserDetails1])
  

  useEffect(() => {
    if(assignUserList){
      setUserLists(assignUserList)
    }
  }, [assignUserList])
  

  const closeAddContactModal = () => {
    setIsOpenAddContact(false);
  };
  
  /*
    video call modal
    */
  const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  const onOpenVideo = () => {
    setIsOpenVideoModal(true);
  };
  const onCloseVideo = () => {
    setIsOpenVideoModal(false);
  };

  /*
        audio call modal
    */
  const [isOpenAudioModal, setIsOpenAudioModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(50);

  const onOpenAudio = () => {
    setIsOpenAudioModal(true);
  };
  const onCloseAudio = () => {
    setIsOpenAudioModal(false);
  };

  /*
  pinned tab modal
  */
  const [isOpenPinnedTabModal, setIsOpenPinnedTabModal] =
    useState<boolean>(false);
  const onOpenPinnedTab = () => {
    setIsOpenPinnedTabModal(true);
  };
  const onClosePinnedTab = () => {
    setIsOpenPinnedTabModal(false);
  };

  /*
  mobile menu chat conversation close
  */
  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  const onInviteContact = () => {
  };

  const toggleDropdown = () => {
    if(checkPlanAndFeature({featureName:"assign_chats", planDetails:userProfile?.planDetails,featureDisplayName:"Assign Chat"}))
    setDropdownOpen(!dropdownOpen);
  };

  const handleUserSelect = (userId1: any) => {
    setLoading(true)
    const selectedUser = userLists.find((profile: any) => profile._id === userId1);
    if (selectedUser) {
      setSelectedUser(selectedUser);
      const payLoadData = {
        userId: selectedUser._id,
        chatId: chatUserDetails?.chatId,
        clientId: chatUserDetails?.clientId,
        email: selectedUser.email,
        fullName: selectedUser.fullName,
        type : "banner",
        event : "Assign-User"
      };
      dispatch(changeAssignUser(payLoadData));
      // dispatch(getChatUserConversations(chatUserDetails.chatId,chatUserDetails.clientId));//unnecessory call
      setLoading(false)
    }else{
      const payLoadData = {
        chatId: chatUserDetails?.chatId,
        clientId: chatUserDetails?.clientId,
        type : "banner",
        event : "Assign-User"
      };
      dispatch(changeAssignUser(payLoadData));
      // dispatch(getChatUserConversations(chatUserDetails.chatId,chatUserDetails.clientId));//unnecessory call
      setLoading(false)
    }
  };


  const updateChatUser = (eventType:string,payload:any) =>{
    const chatId  = chatUserDetails.chatId
    const id  = chatUserDetails._id
    // const eventType : string  = "MarkAsUnRead" 
    onUpdateUserDetails(eventType,payload)
  }
  const [labelValues, setLabelValues] = useState<any>(null);
  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setLabelValues(newValue);
    onUpdateUserDetails("Label",newValue)
  };
  return (
    <div className="user-chat-topbar">
      <Row className="align-items-center">
        <Col xs={7} md={6}>
          <ProfileImage
            chatUserDetails={chatUserDetails}
            onCloseConversation={onCloseConversation}
            onOpenUserDetails={onOpenUserDetails}
            isChannel={isChannel}
          />
        </Col>
        <Col xs={5} md={6} className="text-end d-flex align-items-center justify-content-end ps-0 pe-md-0">
          <div className="mx-2 lableDropDwn d-none">
            <CustomSelect
            value={labelValues}
            onChange={handleSelectChange}
            options={(labels|| [])}
            isMulti={true}
          />
          </div>
          <div className="d-none align-items-center gap-1">
            <span className="assignTolbl text-nowrap">Assign to :</span>
            <Dropdown className="assignChatDrpDwn d-flex align-items-center" loading={loading} isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle className={`${selectedUser? 'assinedUsrSelected': 'unAssinedUsrSelected'} text-truncate`} caret>{selectedUser ? selectedUser.assingedUserName : "Unassigned"}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleUserSelect("")} >Unassign</DropdownItem>
                {userLists?.map((user: any) => {
                  return (
                    <>
                      <DropdownItem
                        key={user._id}
                        onClick={() => handleUserSelect(user._id)}
                        className="text-truncate"
                      >
                        {user?.fullName}
                      </DropdownItem>
                    </>
                  )
                })}
              </DropdownMenu>
              {/* {selectedUser?.assingedUserName && <Loader />} */}
            </Dropdown>
          </div>
          <div className="d-flex align-items-center gap-1  mx-1 mx-lg-2 me-lg-2">
              <Checkbox className="resolveChecknbox" checked={chatUserDetails?.markResolve} onChange={(e) =>{
                  if(checkPlanAndFeature({featureName :"resolve_chat", planDetails:userProfile?.planDetails,featureDisplayName:"Resolve Chat"}))
                  updateChatUser("MarkResolve",{markResolve : e.target.checked})
                }}/>
              <span className="assignTolbl text-nowrap lh-sm">Resolve</span>
          </div>
          <div className="d-flex align-items-center gap-1  mx-1 mx-lg-2 me-lg-3">
              <Checkbox className="resolveChecknbox" checked={false} onChange={(e) =>updateChatUser("MarkAsUnRead",{markAsUnRead : e.target.checked})}/>
              <span className="assignTolbl text-nowrap lh-sm">Unread</span>
          </div>
          <ul className="list-inline user-chat-nav text-end mb-0">

            <li className="list-inline-item d-none d-lg-inline-block me-lg-2 ms-0">
              <button
                onClick={onOpenUserDetails}
                type="button"
                className="btn nav-btn user-profile-show showProfileBtn"
              >
                <i className='bx bx-info-circle'></i>
              </button>
            </li>

            {/* <li className="list-inline-item">
              <More
                onOpenAudio={onOpenAudio}
                onOpenVideo={onOpenVideo}
                onDelete={onDelete}
                isArchive={false}
                onToggleArchive={onToggleArchive}
              />
            </li> */}
          </ul>
        </Col>
      </Row>
      {/* <PinnedAlert onOpenPinnedTab={onOpenPinnedTab} /> */}
      {isOpenAudioModal && (
        <AudioCallModal
          isOpen={isOpenAudioModal}
          onClose={onCloseAudio}
          user={chatUserDetails}
        />
      )}
      {isOpenVideoModal && (
        <VideoCallModal
          isOpen={isOpenVideoModal}
          onClose={onCloseVideo}
          user={chatUserDetails}
        />
      )}
      {isOpenPinnedTabModal && (
        <AddPinnedTabModal
          isOpen={isOpenPinnedTabModal}
          onClose={onClosePinnedTab}
          pinnedTabs={pinnedTabs}
        />
      )}
      {createdUser?.isNameMissing && (
        <InviteContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onInvite={onInviteContact}
          editContactName={true}
          editNumberDetails={editNumberDetails}
        />
      )}   
    </div>
  );
};

export default UserHead;
