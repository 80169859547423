import { CampaignActionTypes } from "./types";

// common success
export const campaignApiResponseSuccess = (actionType: string, data: any) => ({
  type: CampaignActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const campaignApiResponseError = (actionType: string, error: string) => ({
  type: CampaignActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const campaignCreate = (data:any,callback?: (response:any)=>void) => {
  return {
    type: CampaignActionTypes.CAMPAIGN_CREATE,
    payload: data,
    callback
  };
};
export const campaignUpdate = (data:any,callback?:(response:any)=>void) => {
  return {
    type: CampaignActionTypes.CAMPAIGN_UPDATE,
    payload: data,
    callback
  };
};

export const campaignDelete = (data:any,callback?:(response:any)=>void) => {
  return {
    type: CampaignActionTypes.CAMPAIGN_DELETE,
    payload: data,
    callback
  };
};

export const getCampaignList = () => {
  return {
    type: CampaignActionTypes.CAMPAIGN_LIST,
  };
};

export const getCampaignDetails= (id:string,callback?:(response :any)=>void) => {
  return {
    type: CampaignActionTypes.CAMPAIGN_DETAILS,
    payload:id,
    callback
  };
};

