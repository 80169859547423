import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row, Spinner, UncontrolledTooltip,Form,Label,Input } from "reactstrap";
import { Card,Alert} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useProfile, useRedux } from "../../hooks/index";
import { channelList } from '../../data/channelList';
import { getClientsList, clearSettingsState, channelCreate  , systemLogoutUser, changeTab} from '../../redux/actions';
import ChannelModal from "../../components/channelModal";
import { QrCodeWrapper } from './QrCodeWrapper.style';
// import scss
import { showErrorNotification, showSuccessNotification } from "../../helpers/notifications"
import { TABS } from "../../constants";

interface IndexProps { }
interface AddonsTypes {
  name:string;
  isActive:boolean;
}
interface UserDetailsTypes {
  email?: string;
  userId?: string;
  clientId?: string;
  chargebeeDetails?: any;
  planDetails?: any;
  fullName?: string;
  phoneNumber?: string;
  userActive?: boolean,
  allAddons?:[AddonsTypes]
  country_code?: string;
}

const Index = (props: IndexProps) => {
  // const [ChannelList, setChannelList] = useState<ChannelList>(channelList);
  const navigate = useNavigate();
  const authUserData: any | null = useState(localStorage.getItem("authData"));
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  // const randomNum = generateRandomNumber(4);
  const [channelModalOpen, setChannelModalOpen] = useState(false)
  const [channelData, setChannelData] = useState("")
  // const showModalToggle = () => setSelectModal(!selectModal);
  const {
    settingsData,
    userDetails1,
    isSettingsUpdated,
    channelCreateSuccess,
    isUserLogin
  } = useAppSelector(state => ({
    channelCreateSuccess: state.QrCode.channelCreateSuccess,
    settingsData: state.Settings.settings,
    userDetails1: state.Login.user,
    isSettingsUpdated: state.Settings.isSettingsUpdated,
    isUserLogin: state.Login.isUserLogin

  }));
  const [settings, setSettings] = useState(settingsData);

  useEffect(() => {
    setSettings({
      ...settings,
      basicDetails: userDetails1,
      privacy: userDetails1.privacy
    })
  }, [userDetails1])



  useEffect(() => {
    if (isSettingsUpdated) {
      showSuccessNotification({ message: "Setting successfully updated." })
      dispatch(clearSettingsState());
      dispatch(getClientsList());
    }
  }, [isSettingsUpdated]);


  useEffect(() => {
    const data: any = JSON.parse(authUserData[0]);
    if ((userProfile && isUserLogin &&!userProfile?.authToken) || (isUserLogin && data && !data.authToken)) {
      dispatch(systemLogoutUser())
      navigate("/auth-login");
    } 
  }, [userProfile,isUserLogin])

  const handleChannelPopup = (data: any) => {
    setChannelModalOpen(true)
    setChannelData(data)
  }


  const channelConnect = (data: any) => {
    dispatch(channelCreate(data))
  }

  const closeChannelModal = () => {
    setChannelModalOpen(false);
  };

  useEffect(() => {
    if (channelCreateSuccess) {
      setChannelModalOpen(false);
    }
  }, [channelCreateSuccess])

  const handleBackButton = () => {
    dispatch(changeTab(TABS.CHAT))
    navigate("/dashboard")
  }

  return (
    <>
      <QrCodeWrapper className="QrCodeWrapper">
      <div className="chat-welcome-section user-chat user-chat-show d-flex align-item-center h-100">
        <Row className={`w-100 m-0 ${ "wpQrScannerMainrow"}`}>
          <Col md={12} className="p-0">
            <Card className={`${"whenCurruntStepZero" } p-3 wpQrScanCardMain h-100`}>
              {<>
                <div className="d-flex align-items-center gap-1 mb-2">
                  <div className="ms-1 float-left">
                    <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                  </div>
                  <h2 className="font-size-18 text-dark text-center mb-0">Channels</h2></div>
                <Row className="step0">
                  {Object.values(channelList).filter(data => data.status === "integrated").sort((a:any, b:any) => a.priority - b.priority).map((data: any, key: any) => {
                    if(data.status == "integrated"){
                      return (<Col lg={4} md={6} className={`px-3 py-2 d-flex flex-wrap ${data?.badge ? "badgeblock" : ""} ${data?.badgeiconColor ? data.badgeiconColor : ""}`}>
                        {data?.badge ? <span className="badgeBlockInnr"> <img src={data?.badgeiconSrc} /> {data?.badge}</span>:""}
                        <Card className="wpQrScanCard p-3">
                          <div className="wpQrScanCardBody">
                            <div className="d-flex wpQrScanCardBodyTxt align-items-center">
                              <img src={data.iconSrc} className="img_radius_8" alt="integrattion - controlhippo" height={40} width={40} />
                              <div className="subTiltewrap">
                                <h4 className="d-flex flex-column gap-1 mb-0 mt-3 text-dark">{data.displayName}</h4>
                                {data?.subDisplayName ? <span className="font-size-11 fw-normal">{data?.subDisplayName}</span>:""}
                              </div>
                            </div>
                            <p className="wpQrScanCardNote mt-1">{data?.note}</p>
                            <a href={data.url} className="border connectButtonWA channleConnectBtn d-flex justify-content-center gap-1 align-items-center p-2 btn-primary"><i className='bx bxs-plug font-size-15'></i>{data.btnText}</a>
                          </div>
                        </Card>
                      </Col>)
                      }
                  })}
                  {Object.values(channelList).map((data: any, key: any) => {
                    if(data.status == "integrate"){
                    return (<Col lg={4} md={6} className={`px-3 py-2 d-flex flex-wrap ${data?.badge ? "badgeblock" : ""} ${data?.badgeiconColor ? data.badgeiconColor : ""}`}>
                      {data?.badge ? <span className="badgeBlockInnr"> <img src={data?.badgeiconSrc} /> {data?.badge}</span>:""}
                      <Card className="wpQrScanCard p-3">
                        <div className="wpQrScanCardBody">
                          <div className="d-flex wpQrScanCardBodyTxt align-items-center">
                            <img src={data.iconSrc} className="img_radius_8" alt="integrattion - controlhippo" height={40} width={40} />
                            <div className="subTiltewrap">
                              <h4 className="conversation-name mt-3 font-size-11">{data.displayName}</h4>
                            </div>
                          </div>
                          <p className="wpQrScanCardNote mt-1">{data?.note}</p>
                          <Button onClick={() => handleChannelPopup(data)} className="border connectButtonWA channleConnectBtn d-flex justify-content-center gap-1 align-items-center p-2 btn-primary"><i className='bx bxs-plug font-size-15'></i>{data.btnText}</Button>
                        </div>
                      </Card>
                    </Col>)}
                  })}
                </Row></>}
            </Card>
          </Col>
        </Row>
      </div>
      </QrCodeWrapper>

      {channelModalOpen && (
        <ChannelModal
          isOpen={channelModalOpen}
          onClose={closeChannelModal}
          channelData={channelData}
          channelConnect={channelConnect}
        />
      )}
    </>
  );
};

export default Index;
