import { UpdatePasswordActionTypes, AuthConfirmDataState } from "./types";

export const INIT_STATE: AuthConfirmDataState = {
  authData: null,
  confirmaccountError: null,
};

const updatePassword = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UpdatePasswordActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UpdatePasswordActionTypes.UPDATE_PASSWORD:
          return {
            ...state,
            authData: action.payload.data.data,
            successMsg:action.payload.data.data.success,
            emailSended: true,
            loading: false,
          };
        default:
          return { ...state };
      }

    case UpdatePasswordActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UpdatePasswordActionTypes.UPDATE_PASSWORD:
          return {
            ...state,
            confirmaccountError: action.payload.error,
            emailSended: false,
            loading: false, 
          };
        default:
          return { ...state };
      }

    case UpdatePasswordActionTypes.UPDATE_PASSWORD: {
      return {
        ...state,
        authData: null,
        confirmaccountError: null,
        emailSended: false,
        loading: true,
      };
    }
    default:
      return { ...state };
  }
};

export default updatePassword;
