// types
import { ChatsActionTypes, ChatsState } from "./types";
export const INIT_STATE: ChatsState = {
  favourites: [],
  directMessages: [],
  channels: [],
  selectedChat: null,
  draftSelectedChatId: null,
  chatUserDetails: {},
  chatUserConversations: [],
  assignUserList: [],
  isOpenUserDetails: false,
  channelDetails: {},
  archiveContacts: [],
  clientId: "",
  clientDisconnected: false,
  isChatUsersSyncing: false,
  skipChatUserList:0,
  createdUser:{},
  getConversationsCreatedAt:false,
  clientList:[],
  loading:false,
  getNextConversation:true,
  unReadBadge:{},
  downloadFileData:{},
  syncingChatStopToCrm:false,
  getUserConversationsLoading:false,
  editWhatsappNumberSuccess:false,
  editConatctNameSuccess:false,
  labels:[],
  selectedFilter:"",
  selectedLabelValue:false,
  eventType : null,
  oldMessageSyncing:false,
  warmupConfirmationModal:false,
  channelList: [],
  reportList: [],
  campaignLists: [],
  allocationList: [],
  tempClientlist: [],
  mediaSendComplete: false,
  searchText:null,
};
const Chats = (state = INIT_STATE, action: any) => {
  let authData: any = localStorage.getItem("authData")
  authData = JSON.parse(authData)
  switch (action.type) {
    case ChatsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatsActionTypes.UPDATE_CLIENT_SESSION:
          const data = action.payload.data
          return {
            ...state,
            clientDisconnected: !data.isClientActive,
            clientName: data.pushname,
            clienPhoneNumber: data.me.user
          };
        case ChatsActionTypes.CREATE_USER:
          let userData = action.payload.data
          // const tempClientId = action?.payload?.data?.data?.clients?.filter((data: any)=> data.clientId == state.chatUserDetails?.clientId ? state.chatUserDetails?.clientId : state?.clientId)
          let chatExist:any = state.directMessages?.findIndex((item)=> item?.chatId === userData?.data?.chatId)
          let chatList;
          if (chatExist !== -1 && !userData?.data?.dndStart) {
            chatList = state.directMessages
            const itemToShift = chatList[chatExist];
            itemToShift.firstName = userData?.data?.firstName
            chatList.splice(chatExist, 1);
            chatList.unshift(itemToShift);
            chatList = chatList
          } else {
            if (userData.success) {
              if (userData?.data?.dndStart) {
                chatList = state.directMessages
              }
              else {
                chatList = [action.payload.data.data, ...state.directMessages]
              }
            }
          }
          return {
            ...state,
            directMessages: action.payload.data.success ? chatList :state.directMessages,
            createdUser: action.payload.data.success ? action.payload.data.data:{},
            getProfileLoading: false,
          };
        case ChatsActionTypes.SYNC_CHAT_USER:
          const extsitingChatUsers = state?.directMessages
          const newChatuser = action?.payload?.data?.chatUser
          const index = extsitingChatUsers?.findIndex( e => e?.chatId === newChatuser?.chatId)
          let filteredData = index === -1 ? [newChatuser] : []
          const isChatUsersSyncingPending = action?.payload?.data?.isChatUsersSyncingPending
          if(filteredData?.length && authData?.accountDeatils && authData?.accountDeatils?.secureChats && authData?.accountDeatils?.onlyAssignUser){
            if(filteredData[0].assingedUser == undefined && authData.parentId){
              filteredData.shift();
            }
          }
          return {
            ...state,
            directMessages: [ ...state.directMessages, ...filteredData ],
            isChatUsersSyncing: isChatUsersSyncingPending
          }

        case ChatsActionTypes.SYNC_CHAT_START:
          return {
            ...state,
            isChatUsersSyncing: true
          }
        case ChatsActionTypes.SEND_SEEN_API:
          return {
            ...state,
          }

        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            favourites: action.payload.data,
            isFavouritesFetched: true,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          var chatListData = action.payload.data.result
          var chatListCount = action.payload.data.count
          var directMessagesArray: any = []
          if (action.payload.data.skip == 0) {
            directMessagesArray = [...chatListData]
          } else {
            directMessagesArray = [...state.directMessages, ...chatListData]
          }

          return {
            ...state,
            directMessages: directMessagesArray,
            isDirectMessagesFetched: true,
            getDirectMessagesLoading: false,
            isContactsAdded: false,
            skipChatUserList: action.payload.data,
            assignUserList: action.payload.data.userList,
            chatListCount: chatListCount,
            responseKey: action.payload.data.responseKey,
            oldMessageSyncComplete:{}

          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            channels: action.payload.data,
            isChannelsFetched: true,
            getChannelsLoading: false,
            isChannelCreated: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: true,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case ChatsActionTypes.CHANGE_PRIVACY_SETTING:
          let newClientListData = action.payload.data.data
          let clientList1 : any = state.clientList
          const clientListIndex = clientList1?.findIndex((e:any) => e._id === newClientListData?._id);
          if(clientListIndex !== -1){
            clientList1[clientListIndex] = newClientListData
          }
          return {
            ...state,
             clientList : [...clientList1],
            isChannelCreated: true,
            createChannelLoading: false,
            privacyChangeSuccess:  action.payload.data.success
          };
        case ChatsActionTypes.MAKE_AS_ADMIN:
          return {
            ...state,
            isMakeAsAdmin: true,
            createChannelLoading: false,
            chatUserDetails : action.payload.data.chatUserDetails
          };
        case ChatsActionTypes.DISMISS_AS_ADMIN:
          return {
            ...state,
            isDissmissAsAdmin: true,
            createChannelLoading: false,
            chatUserDetails : action.payload.data.chatUserDetails
          };
        case ChatsActionTypes.ADD_PARTICIPANTS:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
            chatUserDetails : action.payload.data.chatUserDetails
          };
        case ChatsActionTypes.REMOVE_PARTICIPANTS:
          return {
            ...state,
            isRemoveParticipants: true,
            createChannelLoading: false,
            chatUserDetails : action.payload.data.chatUserDetails
          };
        case ChatsActionTypes.SYNCING_STOP_TO_CRM:
          return {
            ...state,
            chatUserDetails: action.payload.data,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
            oldMessageSyncComplete:{},
            selectedChat: action?.payload?.data?.data?.chatId,
            draftSelectedChatId: action?.payload?.data?.data?.chatId
          };
        case ChatsActionTypes.GET_LABELS:
          return {
            ...state,
            labels: action.payload.data.labels
          };
        case ChatsActionTypes.DOWNLOAD_CHAT:
          return {
            ...state,
            labels: action.payload.data.labels,
            downloadChatResponse: action.payload.data
          };
        case ChatsActionTypes.CHANGE_ASSIGN_USER:
          return {
            ...state,
            chatUserDetails: action.payload.data.chatUserDetails,
          };
          case ChatsActionTypes.UPDATE_CHAT_USER_DETAILS:
          var chatListData: any = state.directMessages
          var chatUserDeta = action.payload.data.response
          const indexOfChatDetails = chatListData?.findIndex((e: any) => e._id === chatUserDeta._id)
          if(indexOfChatDetails !== -1){
            chatListData[indexOfChatDetails] = chatUserDeta 
          }
          return {
            ...state,
            loading:false,
            directMessages: [...chatListData],
            selectedChat: action.payload.data.eventType == "MarkAsUnRead" ? null : state.selectedChat ,
            chatUserDetails: chatUserDeta
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          const conversationlist = (action.payload.data.result).reverse();
          return {
            ...state,
            chatUserConversations: [...conversationlist, ...state.chatUserConversations],
            getUserConversationsLoading: false,
            isUserMessageSent: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
            oldMessageSyncing: false,
            getNextConversation : action.payload.data.hasNext
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            onSendResponse: action?.payload?.data,
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE:
            return {
              ...state,
              isUserMessageSent: true,
            };
        case ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.UPDATE_NUMBER_ALLOCATION:
          return {
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.NEW_MESSAGES_RECEIVED:
          const {messageDisplay,assignedUserId,newDirectMessages,chatUserData,stateChatUserConversations,payload} = action?.payload?.data
          if (messageDisplay || !assignedUserId) {
            console.log("message_create ~ payload: 11111",Math.floor(new Date().getTime() / 1000))
            return {
              ...state,
              receivedMessage: payload,
              directMessages: newDirectMessages,
              chatUserDetails: { ...state.chatUserDetails, ...chatUserData },
              // clientId: action.payload.data.clientId,
              chatUserConversations: stateChatUserConversations
            }
          } else {
            return {
              ...state
            }
          }
        case ChatsActionTypes.OLD_MESSAGE_SYNC_COMPLETE:
          return {
            ...state,
            oldMessageSyncComplete: action.payload.data,
            oldMessageSyncing: false,
          }
        case ChatsActionTypes.MESSAGE_SEND_COMPLETE:
          return {
            ...state,
            mediaSendComplete: false
          }
        case ChatsActionTypes.OLD_MESSAGE_SYNC_START:
          return {
            ...state,
            oldMessageSyncing: true,
          }
        case ChatsActionTypes.MESSAGE_UNREAD:
          return {
            ...state,
            unReadBadge: action.payload.data,
          }
        case ChatsActionTypes.RECEIVE_MESSAGE:
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            chatUserConversations: action.payload.data,
          };
          case ChatsActionTypes.READ_MESSAGE:
            let chatConversations: any = state.chatUserConversations;
            const updatedChat = action.payload.data?.data;
            
            if (updatedChat?.type !== "instagram") {
                const indexOfMessages = chatConversations?.length && chatConversations.findIndex((item: any) => item?.conversationId == updatedChat?.conversationId);
                
                if (indexOfMessages !== -1 && chatConversations?.length && chatConversations[indexOfMessages]) {
                    chatConversations[indexOfMessages].ack = updatedChat?.ack;
                }
            } else {
                chatConversations = chatConversations.map((conversation: any) => ({
                    ...conversation,
                    ack: 3
                }));
            }
            
            return {
                ...state,
                chatUserConversations: chatConversations
            };
        
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: true,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          const messageId = action.payload.data.messageId
          const option = action.payload.data.option
          const chatId = action.payload.data.chatId
          let chatUserArray = state.directMessages
          const chatListIndex = chatUserArray.findIndex((item:any)=>item?.chatId == chatId)
          let chatUserDetails;
          if(chatListIndex !== -1){
            chatUserDetails = chatUserArray[chatListIndex]
            if( chatUserDetails?.lastMessageId && (chatUserDetails.lastMessageId == messageId)){
              chatUserDetails.lastMessageText = `You deleted this message`
            }
          }
          let chatConversationData:any= state.chatUserConversations 
          var foundIndex = chatConversationData.findIndex((x:any) => x.conversationId == messageId);
          if(foundIndex !== -1){
            if(option == "DeleteForMe"){
              chatConversationData.splice(foundIndex,1)
            }else if(option == "DeleteForEveryOne"){
              chatConversationData[foundIndex].isDelete_everyone = true
            }
          }
          return {
            ...state,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            loading:false,
            chatUserDetails: { ...action.payload.data.data, isChannel: true },
            chatUserConversations: chatConversationData,
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: true,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            archiveContacts: action.payload.data,
            isArchiveContactFetched: true,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: true,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.GET_CLIENTS_LIST:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: false,
            privacyChangeSuccess: false,
            clientList : action?.payload?.data?.data,
            whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
        case ChatsActionTypes.GET_CHANNEL_LIST:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: false,
            privacyChangeSuccess: false,
            channelList: action.payload.data.data,
            whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
          case ChatsActionTypes.GET_TEMP_LIST:
            return {
              ...state,
              loading: false,
              editWhatsappNumberSuccess: false,
              privacyChangeSuccess: false,
              tempClientlist: action.payload.data.data,
              whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
              clientsPayload : action.payload.data
            };
        case ChatsActionTypes.GET_REPORT_LIST:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: false,
            privacyChangeSuccess: false,
            reportList: action.payload.data.data,
            whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
        case ChatsActionTypes.GET_CAMPAIGN_LIST:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: false,
            privacyChangeSuccess: false,
            campaignLists: action.payload.data.data,
            whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
        case ChatsActionTypes.GET_ALLOCATION_LIST:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: false,
            privacyChangeSuccess: false,
            allocationList: action.payload.data.data,
            whatsappUnverifiedList:action?.payload?.data?.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
        case ChatsActionTypes.EDIT_WHATSAPP_NUMBER:
          return {
            ...state,
            loading: false,
            editWhatsappNumberSuccess: action.payload.data.success,
            whatsappUnverifiedList:action.payload.data.whatsappUnverifiedList,
            clientsPayload : action.payload.data
          };
          
        case ChatsActionTypes.CLIENT_DISCONNECTED:
          
          const clientId = action.payload.data.clientId
          let clientList :any = state.clientList
          
          clientList = clientList.map((data:any)=> {
            if(data.clientId === clientId){
              data.isSessionSaved = false
              data.isReady = false
            }
            return data
          })
          
          return {
            ...state,
            clientList : clientList
          };
        case ChatsActionTypes.GET_DOWNLOAD_DATA:
          return {
            ...state,
            loading: false,
            downloadFileData: { success: true, data: action.payload.data }
          };
        case ChatsActionTypes.WARMUP_CONFIRMATION:
          return {
            ...state,
            loading: false,
            warmupConfirmationModal: false
          };
        case ChatsActionTypes.UPDATE_CHAT_USER_LIST:
          console.log("chat.reducer : ChatsActionTypes.UPDATE_CHAT_USER_LIST",action.payload.data);
          const payloadData :any = action.payload.data
          return {
            ...state,
            ...payloadData
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_CLIENTS_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.GET_CHANNEL_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
          case ChatsActionTypes.GET_TEMP_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.GET_REPORT_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.GET_CAMPAIGN_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.GET_ALLOCATION_LIST:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.EDIT_WHATSAPP_NUMBER:

          return {
            ...state,
            loading:false,
            clientList: []
          };
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            isChannelsFetched: false,
            getChannelsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: false,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.CHANGE_PRIVACY_SETTING:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.MAKE_AS_ADMIN:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.DISMISS_AS_ADMIN:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.ADD_PARTICIPANTS:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.REMOVE_PARTICIPANTS:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.SYNCING_STOP_TO_CRM:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_LABELS:
          return {
            ...state,
            labels: {}
          };
        case ChatsActionTypes.DOWNLOAD_CHAT:
          return {
            ...state,
            labels: {}
          };
        case ChatsActionTypes.UPDATE_CHAT_USER_DETAILS:
          return {
            ...state,
            loading:false,
          };
        case ChatsActionTypes.CHANGE_ASSIGN_USER:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: [],
            getUserConversationsLoading: false,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
          case ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
          
        case ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            loading:false,
            isMessageDeleted: false,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: false,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: false,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            isArchiveContactFetched: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.CREATE_USER:
          return {
            ...state,
            getProfileLoading: false,
          };
        case ChatsActionTypes.SEND_SEEN_API:
          return {
            ...state,
            getProfileLoading: false,
          };
        case ChatsActionTypes.GET_DOWNLOAD_DATA:
          return {
            ...state,
            loading: false,
            downloadFileData: {}
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.UPDATE_CLIENT_CONNECTION: {
      return {
        ...state,
        clientDisconnected: !action.payload.isConnected
      }
    }

    case ChatsActionTypes.CREATE_USER: {
      return {
        ...state,
        getProfileLoading: true,
      };
    }
    case ChatsActionTypes.SEND_SEEN_API: {
      return {
        ...state,
        getProfileLoading: true,
      };
    }

    case ChatsActionTypes.GET_FAVOURITES: {
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    }
    case ChatsActionTypes.GET_DIRECT_MESSAGES:
      let skipChat = state.skipChatUserList;
      if (state.skipChatUserList.skip == 0) {
        skipChat.hasNext = false;
      }
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
        skipChatUserList :skipChat,
        selectedFilter: action.payload.key,
        selectedLabelValue: action.payload.value,
        searchText :action.payload.search ? action.payload.search : false 
        // selectedChat: null,
      };
    case ChatsActionTypes.GET_CHANNELS:
      return {
        ...state,
        isChannelsFetched: false,
        getChannelsLoading: true,
      };
    case ChatsActionTypes.ADD_CONTACTS:
      return {
        ...state,
        isContactsAdded: false,
        addContactsLoading: true,
      };
    case ChatsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.CHANGE_PRIVACY_SETTING:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.MAKE_AS_ADMIN:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.DISMISS_AS_ADMIN:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.ADD_PARTICIPANTS:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.REMOVE_PARTICIPANTS:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.SYNCING_STOP_TO_CRM:
        return {
          ...state,
          selectedChat: action.payload,
        };
    case ChatsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case ChatsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
        chatUserConversations:[]
      };
    case ChatsActionTypes.CHANGE_ASSIGN_USER:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
        // chatUserConversations:[]
      };
    case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      let chatConversation : any | null = []
      let getConversationsCreatedAt : boolean = false
      if(action?.payload?.createdAt){
        chatConversation = [...state.chatUserConversations] ;
        getConversationsCreatedAt = true;
      }
      return {
        ...state,
        chatUserConversations : chatConversation,
        getUserConversationsLoading: true,
        isUserMessageSent: false,
        getConversationsCreatedAt : getConversationsCreatedAt,
        downloadFileData: {},
        createdUser:{},
      };
    case ChatsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case ChatsActionTypes.ON_SEND_MESSAGE:
      let mediaSendComplete
      if(action?.payload?.data?.type !== "text"){
        mediaSendComplete = true
      }
      return {
        ...state,
        isUserMessageSent: false,
        mediaSendComplete: mediaSendComplete,
      };
    case ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE:
      return {
        ...state,
        isUserMessageSent: false,
      };
      case ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE:
      return {
        ...state,
        isUserMessageSent: false,
      };
      
    case ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE:
      return {
        ...state,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        loading: true,
      };
    case ChatsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case ChatsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
      return {
        ...state,
        isFavouriteContactToggled: false,
      };
    case ChatsActionTypes.GET_ARCHIVE_CONTACT:
      return {
        ...state,
        isArchiveContactFetched: false,
      };
    case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
      return {
        ...state,
        isContactArchiveToggled: false,
      };
    case ChatsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    case ChatsActionTypes.GET_DOWNLOAD_DATA:
      return {
        ...state,
        loading: true,
        downloadFileData: {}
      };
    case ChatsActionTypes.SET_CLIENT_ID:
      authData = localStorage.getItem("authData");
      if (authData) {
        try {
          authData = JSON.parse(authData);
        } catch (e) {
          console.error("Failed to parse authData:", e);
          authData = {};
        }
      } else {
        authData = {};
      }
      authData.clientId = authData != null && action?.payload?.length && typeof action?.payload === "string" ? action.payload : JSON.parse(action?.payload);
      authData != null && localStorage.setItem("authData", JSON.stringify(authData));
      return {
        ...state,
        clientId: authData?.clientId,
        directMessages: [],
        chatUserConversations: [],
        selectedChat: null,
        draftSelectedChatId: null,
      };
      case ChatsActionTypes.CLEAR_CHAT_REDUCER_DATA:
        return {
          ...state,
          receivedMessage : {},
          unReadBadge : {},
          // selectedChat : null
          draftSelectedChatId : null
        };
      case ChatsActionTypes.REMOVE_SELECTED_CHAT:
        return {
          ...state,
          receivedMessage : {},
          unReadBadge : {},
          selectedChat : null
        };
      case ChatsActionTypes.CLEAR_CLIENTID_REDUCER_DATA:
        return {
          ...state,
          clientId: null
        };
    case ChatsActionTypes.CLEAR_DOWNLOAD_DATA:
      return {
        ...state,
        downloadFileData: {}
      }
    case ChatsActionTypes.GET_LABELS:
      return {
        ...state,
        labels: {}
      };
    case ChatsActionTypes.DOWNLOAD_CHAT:
      return {
        ...state,
        labels: {},
        downloadChatResponse: false
      };
    case ChatsActionTypes.UPDATE_CHAT_USER_DETAILS:
      return {
        ...state,
        loading:true
      };
    case ChatsActionTypes.GET_CLIENTS_LIST : 
      return {
        ...state,
        loading:true,
        eventType : action.payload
      };
    case ChatsActionTypes.GET_CHANNEL_LIST:
      return {
        ...state,
        loading: true,
        eventType: action.payload
      };
      case ChatsActionTypes.GET_TEMP_LIST:
      return {
        ...state,
        loading: true,
        eventType: action.payload
      };
    case ChatsActionTypes.GET_REPORT_LIST:
      return {
        ...state,
        loading: true,
        eventType: action.payload
      };
    case ChatsActionTypes.GET_CAMPAIGN_LIST:
      return {
        ...state,
        loading: true,
        eventType: action.payload
      };
    case ChatsActionTypes.GET_ALLOCATION_LIST:
      return {
        ...state,
        loading: true,
        eventType: action.payload
      };
    case ChatsActionTypes.WARMUP_CONFIRMATION_MODAL:
      return {
        ...state,
        warmupConfirmationModal: action.payload.value
      };
    default:
      return { ...state };
  }
};

export default Chats;