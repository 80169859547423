export enum changeEmailActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/forgetPass/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/forgetPass/API_RESPONSE_ERROR",
  
    CHANGE_USER_EMAIL = "@@auth/forgetPass/CHANGE_USER_EMAIL",
  }
  export interface AuthForgetPassState {
    changeEmailResponse:any;
    forgetSuccessMsg: any;
    forgetError: any;
  }
  