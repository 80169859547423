import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form, Button } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// router
import { Link, Navigate } from "react-router-dom";

// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { addMixpanelEvent } from "../.../../../helpers/mixpanel";

// hooks
import { useProfile } from "../../hooks";

//actions
import { userForgetPassword } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
const recaptchaRef: any = React.createRef();
const recaptcha_site_key: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

interface RecoverPasswordProps {}
const RecoverPassword = (props: RecoverPasswordProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [successDisplayMessage, setSuccessDisplayMessage] = useState();
  const [errorDisplayMessage, setErrorDisplayMessage] = useState();

  const {forgetPassLoading, forgetSuccessMsg } = useAppSelector(
    state => {
      return {
        forgetPassLoading: state.ForgetPassword.loading,
        forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
      };
    }
  );
  
  useEffect(() => {
    if(forgetSuccessMsg?.success){
      setSuccessDisplayMessage(forgetSuccessMsg?.message)
    }else{
      setErrorDisplayMessage(forgetSuccessMsg?.message)
    }
  }, [forgetSuccessMsg])
  

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .transform((value) => value.trim()) // Trim any whitespace
        .email("This value should be a valid email.")
        .required("Please enter an email."),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = async (values: object) => {
    const token = await recaptchaRef.current.executeAsync();
    const updatedValues = {
      ...values,
      recaptcha: token
    };
    // addMixpanelEvent("Clicked On Forgot Password",{distinct_id:values.email})
    dispatch(userForgetPassword(updatedValues));
  };

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col className="col-12">
          <div className="py-md-5 py-4">
            <div className="headtitle mb-4">
              <h3 className="headTitleHeading mb-1">Reset <span className="headTitleHeadingColored">Password</span></h3>
              <span className="text-muted font-size-14">Reset Password for your Controlhippo account</span>
            </div>
            {(errorDisplayMessage || successDisplayMessage) && (<Alert color={errorDisplayMessage ? "danger" : "success"}>{errorDisplayMessage || successDisplayMessage}</Alert>)}
            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {forgetPassLoading && <Loader />}
              <div className="mb-1">
                <FormInput
                  type="text"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Email"
                  className="form-control inputField"
                />
              </div>
              <div className="pt-3">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={recaptcha_site_key}
                />
              </div>
              <div className="text-center mt-3">
                <Button color="primary" className="w-100 btn btn-primary authButtons font-size-16" type="submit">
                Reset Password 
                </Button>
              </div>
            </Form>
            <div className="mt-4 text-center text-muted">
              <p>
                Remember It ?{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                  {" "}
                  Login
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default RecoverPassword;
