import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import React, { useEffect, useState } from "react";
import {Card, UncontrolledTooltip,Row,Col, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { changeTab, connectMessenger, getClientsList, getMessengerPageList, getOldClientId, setClientId, updateClientDeatils } from '../../../redux/actions';
import {  Select,Button } from 'antd';
import { checkPlanAndFeature } from "../../../helpers/common.service";
import { checkEstimate } from "../../../helpers/checkEstimate";
import EstimatePopup from "../../../components/EstimatePopup";
import { TABS } from "../../../constants";
import { FacebookFilled } from '@ant-design/icons';
import { showErrorNotification } from "../../../helpers/notifications";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
import { QrCodeWrapper } from '../QrCodeWrapper.style';

const { Option } = Select;
declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}
interface AddonsTypes {
    name: string;
    isActive: boolean;
}

interface IndexProps {
    editNumber?:string|Number|any;
    numberData:any|null
   }

interface UserDetailsTypes {
    email?: string;
    userId?: string;
    clientId?: string;
    chargebeeDetails?: any;
    planDetails?: any;
    fullName?: string;
    phoneNumber?: string;
    userActive?: boolean,
    allAddons?: [AddonsTypes]
    country_code?: string;
}

const Index = (props: IndexProps) => {
    const { userProfile } = useProfile(); // Move this hook inside the component
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();
    const [rescanNumberParam, setRescanNumberParam] = useState("");
    const [pageListData, setPageListData] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState();
    const [tempClientId, setTempClientId] = useState("");
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [loading, setLoading] = useState(false);
    const [conformationPopupData, setConformationPopupData] = useState<{ isOpen: boolean, message?: string, from?: any, type?: String }>({ isOpen: false })
    const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
    const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
    const [show, setShow] = useState(false);
    const [pageResponse, setPageResponse] = useState<any>("");
    const [authAccesstoken, setAuthAccesstoken] = useState<any>("");
    const initialMessengerHandleState = { step: 1, btnText: "Authentication With Facebbok" };
    const [messengerHandle, setMessengerHandle] = useState(initialMessengerHandleState)
    const reconnectClientId = props?.numberData?.clientId

    const { clientList, userData } = useAppSelector((state) => {
        return {
            clientList: state.Chats?.clientList,
            userData: state.Login.user,

        };
    });


    useEffect(() => {
        setUserDetails(userData)
        if (userData?.chargebeeDetails) setChargebeeDetails(userData.chargebeeDetails)
    }, [userData])

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });
        };

        (function (d, s, id) {
            var js: HTMLScriptElement, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement; js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        const checkFbInit = setInterval(() => {
            if (window.FB) {
                clearInterval(checkFbInit);
            }
        }, 100);

        return () => {
            clearInterval(checkFbInit);
        };
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const rescanNumber = searchParams.get('rescan');
        setRescanNumberParam(rescanNumber || "");
        dispatch(getClientsList());
    }, []);

    const handleFacebookConnect = async (e:any, response: any) => {
        // e.preventDefault()
        const objData = {
            item: "addon",
            eventType: "update_subscription_for_items",
            name: "scan_whatsapp_number",
            userDetails,
            dispatch
        }
        if((!userDetails?.chargebeeDetails) || ( response &&  response.skipEstimate) || (props?.numberData)){
            onConfirm({ from: "scan_whatsapp_number", type: "messenger" })
            return
        }

        let checkData: any
        checkData = (messengerHandle.step == 1) ? await checkEstimate(objData) : { success: true, message: "Are you sure you want to rescan whatsapp number?" }
        if (checkData?.success) {
            if (checkData?.message) {
                setConformationPopupData({
                    isOpen: true,
                    message: checkData?.message,
                    from: "scan_whatsapp_number",
                    type: "messenger"
                })
            } else {
                onConfirm({ from: "scan_whatsapp_number", type: "messenger" })
            }
        } else {
            return (false)
        }
    };


    // useEffect(() => {
    //     const clientId = userProfile?.clientId
    //     setLoading(true)
    //     dispatch(getMessengerPageList(clientId, (response: any) => {
    //         setPageListData(response?.pageList)
    //         setLoading(false)
    //     }))
    // }, [userProfile?.clientId])

    const onConfirm = (data: any) => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: "",
            type: ""
        })

        if (userProfile) {
            const payloadData = {
                reconnectClientId: reconnectClientId && reconnectClientId,
                type: "messenger"
            }
            dispatch(getOldClientId({payloadData}, (response: any) => {
                if (response?.success) {
                    const clientId = response?.data?.clientId ? response?.data?.clientId : `${userProfile?.parentId ? userProfile?.parentId : userProfile?.userId}_${Math.floor(100000 + Math.random() * 900000)}`;
                    window.FB.login(function (response: any) {
                        if (response?.authResponse) {
                            setAuthAccesstoken(response?.authResponse?.accessToken)
                            setMessengerHandle({ step: 2, btnText: "Connect with Facebook Page" })
                            response.authResponse.userId = userProfile?.userId;
                            setTempClientId(clientId);
                            response.authResponse.clientId = clientId;
                            dispatch(connectMessenger(response?.authResponse, (response: any) => {
                                console.log("Response:", response);
                                if (response.success) {
                                    setPageListData(response?.pageList)
                                    setPageResponse(response)
                                    const data = {
                                        clientId: clientId
                                    };
                                    updateAuthData(data);
                                }
                            }));
                        } else {
                            showErrorNotification('You have cancelled login or did not fully authorize.');
                        }
                    }, { scope: 'email, pages_messaging, pages_messaging_subscriptions, pages_show_list, business_management' });
                }
            }));

        }
    }

    const submitPageData = async (e: any) => {
            const selectedOption = pageListData.find((option: any) => option.id === selectedOptionId);
            selectedOption.clientId = tempClientId
            selectedOption.channel = "messenger"
            dispatch(updateClientDeatils(selectedOption, (response: any) => {
                if(response?.success){
                    navigate("/dashboard")
                    localStorage.setItem("selectedChatFilter", "All");
                    dispatch(changeTab(TABS.CHAT));
                    dispatch(setClientId(tempClientId));

                }else{
                    showErrorNotification(response?.message)
                }
                setLoading(false)
            }))
            dispatch(getClientsList());
    }

    const handleChange = async (value: any) => {
        const selectedOption = pageListData.find((option: any) => option.id === value);
        setSelectedValue(selectedOption?.name)
        setSelectedOptionId(value)
    };

    const onCancel = () => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: ""
        })
        if (loading) setLoading(false)
        if (show) setShow(false)
    }
    const handleBackButton = () => {
        navigate(-1);
    }

    const buttonClicked = (response: any) => {
        if (response.success) {
          const syntheticEvent = {} as React.FormEvent<HTMLFormElement>;
          return handleFacebookConnect(syntheticEvent,response)
        }
      }
    return (
        <>
        <QrCodeWrapper className="QrCodeWrapper">
            <Card className="qrCodePageCardTitleMain p-3 w-100 h-100 mb-0">
                <div className="d-flex gap-2">
                    <div className="ms-1 float-left">
                        <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                    </div>
                    <div>
                        <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                            <b> Connect Facebook Messenger {rescanNumberParam && `[ +${rescanNumberParam} ] `}</b>
                            <a id="whatsppcard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/en/support/solutions/articles/1060000081687-how-to-integrate-facebook-messenger-page-with-controlhippo-" target="_blank">
                                <i className="bx bxs-info-circle font-size-20 lh-sm"></i>
                            </a>
                            <UncontrolledTooltip
                                target={`whatsppcard`}
                                placement="right"
                            >
                                Click here to know more..
                            </UncontrolledTooltip>
                        </h2>
                        <p className="font-size-12 text-muted">Controlhippo now integrates with Facebook Messenger, allowing your team to manage customer queries directly within the platform.</p>
                    </div>
                </div>
                <Row className="qrmainrow mt-2">
                    <Col md={12} lg={6} xl={7} className="contetCol">
                        <div className="qrGenerateSteps">
                            <h5 className="text-start">Use Your Messenger In ControlHippo</h5>
                            <ol className="text-start d-flex flex-column gap-3 mt-3">
                                <li>Click "Connect with Facebook" to choose your Facebook account</li>
                                <li>Grant permissions, and select a page to connect.</li>
                                <li>Then, you're ready to go!</li>
                            </ol>
                        </div>
                    {pageListData?.length ?
                        <div className="d-flex flex-column mt-4 w-75">
                            <Label>We have found Facebook page(s) managed by you.</Label>
                            <Select
                                // disabled={loading}
                                value={selectedValue}
                                onChange={handleChange}
                                className="w-100"
                                style={{height:46}}
                                placeholder="Select Facebook Page"
                            >
                                {pageListData?.map((option: any) => (
                                    <Option key={option.id} value={option.id}>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={option?.picture?.data?.url ? option?.picture?.data?.url :`https://images.controlhippo.com/svg/messenger_small.svg`} height={26} width={26} className="rounded"/>
                                            <div>
                                                <b>{option.name}</b>
                                                <br></br>
                                                {option.category}
                                            </div>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                            <Button 
                            htmlType="submit"
                            className="btn btn-primary d-flex align-items-center justify-content-center h-100 wpBtnPrimary mt-3 m-auto"
                            style={{width:120}}
                            disabled={!selectedOptionId && messengerHandle?.step == 2}
                            onClick={submitPageData} 
                            >Connect</Button>
                        </div> : ""
                    }
                        {
                            (pageListData?.length < 1 && messengerHandle?.step == 2 && pageResponse?.success) &&
                            <div className="qrGenerateSteps">
                                <div className="alert alert-info mb-3 py-2 d-flex" style={{ width: "fit-content" }} role="alert">We couldn't find a Facebook page associated with your account. Please ensure your account is linked to an active Facebook page to continue.</div>
                            </div>
                        }
                    {/* <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} /> */}
                    </Col>
                    <Col md={12} lg={6} xl={5} className="d-flex flex-column align-items-center">
                        <img height={120} width={120}  src={`https://images.controlhippo.com/svg/messenger_small.svg`} className="img_radius_8" alt="Whatsapp Logo - Controlhippo" />
                        <h4 className="lh-sm mb-0 mt-4 font-size-17">Messenger</h4>
                        {messengerHandle?.step == 1 && !authAccesstoken &&
                            <div className="mt-3"> <ChargebeeCheck numberData={props.numberData} userDetails={userProfile ? userProfile : userData} buttonText={"Connect with Facebook"} onConfirm={buttonClicked} from="whatsapp" /></div>
                        }
                    </Col>
                </Row>
                <div className="stickyBottomLine">Please refer to our <a href="https://support.controlhippo.com/en/support/solutions/articles/1060000081687-how-to-integrate-facebook-messenger-page-with-controlhippo-" target="_blank">support article </a>to gain a better understanding of Messenger integration.</div>
            </Card>
            {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm} />}
        
        </QrCodeWrapper>
        </>
    );
};

export default Index;
