import { changeEmailActionTypes, AuthForgetPassState } from "./types";

export const INIT_STATE: AuthForgetPassState = {
  changeEmailResponse:{},
  forgetSuccessMsg: null,
  forgetError: null,
};

const ChangeEmail = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case changeEmailActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case changeEmailActionTypes.CHANGE_USER_EMAIL: {
          return {
            ...state,
            changeEmailResponse: action.payload.data,
            passwordChanged: true,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case changeEmailActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {      
        case changeEmailActionTypes.CHANGE_USER_EMAIL: {
          return {
            ...state,
            changepasswordError: action.payload.error,
            passwordChanged: false,
            loading: true,
          };
        }
        default:
          return { ...state };
      }
    case changeEmailActionTypes.CHANGE_USER_EMAIL: {
      return {
        ...state,
        passwordChanged: false,
        loading: true,
      };
    }

    default:
      return { ...state };
  }
};

export default ChangeEmail;
