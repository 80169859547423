import React, { useEffect, useState } from "react";
import { TABS } from "../../../constants/index";
import { Button, Col, Row, Spinner, UncontrolledTooltip,Form,Label,Input } from "reactstrap";
import Select from "react-select";
import { Card} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import { setClientId, getQrCode, resetClientProcess, getClientsList, changeTab, connectWhatsAppBusiness, clearSettingsState, channelCreate  , connectTelegram, systemLogoutUser, updateSettings} from '../../../redux/actions';
import ProgressBarComponent from "../../../components/ProgressBar";
import ControllhippoLogo from '../../../assets/images/ControlHippo-blue.svg';
import { QrCodeWrapper } from '../QrCodeWrapper.style';
// import scss
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications"
import EstimatePopup from "../../../components/EstimatePopup";
import { checkEstimate } from "../../../helpers/checkEstimate";
import ChannelModal from "../../../components/channelModal";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
import Privacy from "../../Dashboard/Settings/Privacy";
const countryData = require('country-data');
interface IndexProps {
  editNumber?:string|Number|any;
  numberData:any|null
 }
interface AddonsTypes {
  name:string;
  isActive:boolean;
}
interface UserDetailsTypes {
  email?: string;
  userId?: string;
  clientId?: string;
  chargebeeDetails?: any;
  planDetails?: any;
  fullName?: string;
  phoneNumber?: string;
  userActive?: boolean,
  allAddons?:[AddonsTypes]
  country_code?: string;
}
interface DataTypes {
  phoneNumber: string | null;
  code: string | null;
  countryCode: string | null;
}
const initialTelegramDataState = {
  phoneNumber: null,
  code: null,
  countryCode: null,
};

const Index = (props: IndexProps) => {
  const initialTelegramHandleState = { step: 1, btnText: "Send OTP" };
  const [telegramData, setTelegramData] = useState<DataTypes>(initialTelegramDataState);
  const [telegramHandle, setTelegramHandle] = useState(initialTelegramHandleState)
  // const [ChannelList, setChannelList] = useState<ChannelList>(channelList);
  const navigate = useNavigate();
  const authUserData: any | null = useState(localStorage.getItem("authData"));
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [inputText, setInputText] = useState('');
  const [newClientId, setNewClientId] = useState("");
  const [userIdDetails, setUserIdDetails] = useState<string | boolean>(false);
  const [titleText, setTitleText] = useState({ text: "", part: 1, progress: 0 });
  const [qrCode, setQrCode] = useState('');
  // const randomNum = generateRandomNumber(4);
  const [curruntStep, setCurruntStep] = useState<number>(0)
  const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
  const [channelModalOpen, setChannelModalOpen] = useState(false)
  const [channelData, setChannelData] = useState("")
  const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
  const [clients, setClients] = useState<any|[]>()
  const [defaultCountryCode, setSefaultCountryCode] = useState("")
  // const showModalToggle = () => setSelectModal(!selectModal);
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const {
    isClientIdDetails,
    clientAuthenticated,
    clientReady,
    chatSyncingStart,
    userData,
    clientList,
    getQrCodeDetails,
    clientsPayload,
    connectWhatsappBusiness,
    tokenModelDetails,
    settingsData,
    userDetails1,
    isSettingsUpdated,
    channelCreateSuccess,
    isUserLogin
  } = useAppSelector(state => ({
    isClientIdDetails: state.QrCode.isClientIdDetails,
    clientAuthenticated: state.QrCode.clientAuthenticated,
    clientReady: state.QrCode.clientReady,
    chatSyncingStart: state.QrCode.chatSyncingStart,
    getQrCodeDetails: state.QrCode.getQrCodeDetails,
    channelCreateSuccess: state.QrCode.channelCreateSuccess,
    connectWhatsappBusiness: state.QrCode.connectWhatsappBusiness,
    tokenModelDetails: state.Settings.tokenModelDetails,
    userData: state.Login.user,
    clientList: state.Chats.clientList,
    clientsPayload: state.Chats.clientsPayload,
    settingsData: state.Settings.settings,
    userDetails1: state.Login.user,
    isSettingsUpdated: state.Settings.isSettingsUpdated,
    isUserLogin: state.Login.isUserLogin

  }));
  const location = useLocation();
  const [settings, setSettings] = useState(settingsData);
  const [conformationPopupData, setConformationPopupData] = useState<{isOpen:boolean,message?:string,from?:any,type?:String}>({isOpen:false})
  let privacyValue = settings?.privacy?.isDisablePersonalChatSync
  const countryCodes = countryData.callingCountries.all.map((country: any) => {
    const dialCode: string = country.countryCallingCodes[0].replace('+', '');
    const label: string = `+${dialCode} (${country.alpha2})`;
    return { value: dialCode, label };
  });

  useEffect(() => {
    setSettings({
      ...settings,
      basicDetails: userDetails1,
      privacy: userDetails1.privacy
    })
  }, [userDetails1])



  useEffect(() => {
    if (isSettingsUpdated) {
      showSuccessNotification({ message: "Setting successfully updated." })
      dispatch(clearSettingsState());
      // dispatch(getClientsList());
    }
  }, [isSettingsUpdated]);


  useEffect(() => {
    const data: any = JSON.parse(authUserData[0]);
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const addNumberParam = searchParams.get('addNumber');
    const clientDetails = (clients||[]).map((item:any) => item.clientId === userProfile?.clientId)
    if ((userProfile && isUserLogin &&!userProfile?.authToken) || (isUserLogin && data && !data.authToken)) {
      dispatch(systemLogoutUser())
      navigate("/auth-login");
    } else if (isUserLogin && userProfile &&userProfile?.clientId && clientDetails.isSessionSaved == "true" && !addNumberParam) {
      dispatch(changeTab(TABS.CHAT));
      localStorage.setItem("selectedChatFilter", "All");
      navigate("/dashboard");
    } else if (isUserLogin && userProfile && userProfile?.clientId && clientDetails.isSessionSaved == "false") {
      dispatch(changeTab(TABS.CHANNELS));
      navigate("/welcome");
    }
  }, [userProfile,isUserLogin])

  useEffect(() => {
    setUserDetails(userData)
    setChargebeeDetails(userData.chargebeeDetails)
  }, [userData])


  useEffect(() => {
    if (!getQrCodeDetails.success && getQrCodeDetails.paymentFail) {
      setQrCode("");
      setShow(false)
      setLoading(false);
    }
  }, [getQrCodeDetails])

  const onConnectWhatsAppBusiness = async () => {
    setLoading(true)
    const objData = {
      item: "addon",
      eventType: "update_subscription_for_items",
      name: "waba",
      userDetails,
      dispatch
    }
    let checkData: any = await checkEstimate(objData)
    if (checkData?.success) {
      if (checkData?.message) {
        setConformationPopupData({
          isOpen: true,
          message: checkData?.message,
          from: "waba"
        })
      } else {
        onConfirm({from : "waba"})
      }
    }
  }
  useEffect(() => {
    setQrCode('')
    setShow(false)
    dispatch(getClientsList())
  }, [])

  useEffect(() => {
    if (props.numberData) {
      const numberData = props.numberData
      setInputText(`${numberData.clientId}`)
      setNewClientId(`${numberData.clientId}`)
    } else {
      const newUserId = userProfile?.userId
      const randomNum = generateRandomNumber(4)
      setInputText(`${newUserId + "_" + randomNum}`)
      setNewClientId(`${newUserId + "_" + randomNum}`)
    }
    dispatch(getClientsList("dropdown"))
  }, []);

  useEffect(() => {
    setClients(clientList)
    let defaultCountryCodeNumber;
    if(userDetails?.country_code) defaultCountryCodeNumber =  countryCodes.find((item: any) => (item.value).toString() == (userDetails?.country_code ? userDetails?.country_code : "").toString())
    if(defaultCountryCodeNumber){
      setSefaultCountryCode(defaultCountryCodeNumber.value)
      setTelegramData({
        ...telegramData,
        countryCode:telegramData?.countryCode ? telegramData.countryCode : defaultCountryCodeNumber? defaultCountryCodeNumber.value :"-",
      });
    }
    if (props.editNumber && clientList.length > 0) {
      let findClientObject = clientList.find((data: any) => data?.clientId === props.numberData.clientId)
      setNewClientId(`${findClientObject.clientId}`)
      setInputText(`${findClientObject.clientId}`)
      const countryCoddeNumber  = props?.numberData?.me?.user.toString().substring(0, 2);
      let result = parseInt(countryCoddeNumber);
      let modifiedTelegramDataData: DataTypes = { ...telegramData };
      modifiedTelegramDataData["phoneNumber"] = props?.numberData?.me?.user.substring(2);
      modifiedTelegramDataData["countryCode"] = result.toString();
      setTelegramData(modifiedTelegramDataData);
    }
    if (userIdDetails) {
      // setLoading(true)
      const clientId = newClientId ? newClientId : inputText
      addMixpanelEvent("Generate QR Code Button", { distinct_id: userProfile?.userId })
    }
  }, [clientList])
  useEffect(() => {
    if (clientsPayload?.success) {
      updateAuthData(clientsPayload.authData)
    }
  }, [clientsPayload])

  function generateRandomNumber(minLength: number): number {
    const min = Math.pow(10, minLength - 1);
    const max = Math.pow(10, minLength) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    var clientID = newClientId ? newClientId : inputText
    if (isClientIdDetails.success && (isClientIdDetails.clientId == clientID)) {      
      setTitleText({ text: "Scan In WhatsAPP", part: 1, progress: 0 })
      setQrCode(isClientIdDetails.url) // QR Code generate
      setLoading(false)
      setShow(true)
    }
  }, [isClientIdDetails]);

  useEffect(() => {
    if (clientAuthenticated) {
      addMixpanelEvent("Whatsapp authenticated", { distinct_id: userProfile.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 30 });
    }

  }, [clientAuthenticated])

  useEffect(() => {
    const clientID = inputText
    if (clientReady.success && (clientReady.clientId === clientID || clientReady.clientId === newClientId)) {
      dispatch(setClientId(clientReady.clientId))
      addMixpanelEvent("Scan successfully", { distinct_id: userProfile?.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 60 });
      setQrCode("")
      setShow(false)
    }
  }, [clientReady])

  useEffect(() => {
    if (chatSyncingStart) {
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 98 });
      dispatch(resetClientProcess())

      setTimeout(() => {
        navigate("/dashboard");
        dispatch(changeTab(TABS.CHAT));
        localStorage.setItem("selectedChatFilter", "All");
        setTitleText({ text: "Enter client ID", part: 1, progress: 0 })
      }, 1000);
    }

  }, [chatSyncingStart])

  const onCancel = ()=>{
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : ""
    })
    if(loading)setLoading(false)
    if(show)setShow(false)
  }
  const onConfirm = (data: any) => {
    setConformationPopupData({
      isOpen: false,
      message: "",
      from: "",
      type: ""
    })
    const newUserId = userDetails?.userId ? userDetails?.userId : userProfile?.userId
    const clientId = newClientId ? newClientId : inputText
    const privacyValue = tokenModelDetails?.isDisablePersonalChatSync
    if (data.from == "scan_whatsapp_number" && data.type == "telegram") {
      dispatch(connectTelegram({ clientId, userId: newUserId, ...telegramData, privacyValue }, (response: any) => {
        if (response.success) {
          showSuccessNotification(response)
          if (telegramHandle.step == 1) {
            setLoading(false)
            setTelegramHandle({ step: 2, btnText: "Submit" })
          } else if (telegramHandle.step == 2) {
            setLoading(false)
            setTelegramHandle({ step: 1, btnText: "Send OTP" })
            setTelegramHandle(initialTelegramHandleState)
            // dispatch(changeTab(TABS.CHAT))
            // navigate("/dashboard")
          }
        } else {
          showErrorNotification(response.error)
          setLoading(false)
          setTelegramHandle({ step: 1, btnText: "Send OTP" })
          setTelegramData(initialTelegramDataState)
          // setTelegramHandle(initialTelegramHandleState)
        }
      }))
    }
  }

  const handleBackButton = () => {
    dispatch(changeTab(TABS.CHANNELS))
    navigate("/welcome")
  }


  const onChangeTelegramData = (field: "phoneNumber" | "code" | "countryCode", value: string) => {
    let modifiedData: DataTypes = { ...telegramData };
    modifiedData[field] = value !== "" ? value : null;
    setTelegramData(modifiedData);
  };
  const handleCountryCodeChange = (selectedOption: any) => {
    let modifiedData: DataTypes = { ...telegramData };
    modifiedData["countryCode"] = selectedOption.value
    setTelegramData(modifiedData);
  };

  const submitTelegram =  async(event: React.FormEvent<HTMLFormElement>,response?:any)=> {
    if(event) event.preventDefault();
    const newUserId = userDetails?.userId ? userDetails?.userId : userProfile?.userId
    const clientId = inputText
    if(!loading){
      setLoading(true)
      if((telegramHandle.step == 2) || !userDetails?.chargebeeDetails || props.editNumber || (response && response?.skipEstimate)){
        onConfirm({from : "scan_whatsapp_number",type : "telegram"})
        return 
      }
      const objData = {
        item: "addon",
        eventType: "update_subscription_for_items",
        name: "scan_whatsapp_number",
        userDetails,
        dispatch
      }
      let checkData: any
      checkData = (telegramHandle.step == 1 ) && !props.editNumber  ? await checkEstimate(objData):props.editNumber  ? {success : true, message:"Are you sure you want to rescan whatsapp number?"}:{}
      if (checkData?.success) {
        if(checkData?.message){
          if(!props.editNumber ){
            setConformationPopupData({
              isOpen:true,
              message:checkData?.message,
              from : "scan_whatsapp_number",
              type : "telegram"
            })
          }
        }else{
          onConfirm({from : "scan_whatsapp_number",type : "telegram"})
        }
      }else{
        return (false)
      }
    } 
  }
  const checkDisablity = () =>{
    if(loading){
      return true
    }
    if(telegramHandle.step == 2 && (telegramData.code == null || telegramData.code.length <= 0)){
      return true
    }
    if(telegramHandle.step == 1 && (telegramData.countryCode == null || telegramData.countryCode.length <= 0)){
      return true
    }
    if(telegramHandle.step == 1 && (telegramData.phoneNumber == null || telegramData.phoneNumber.length <= 0)){
      return true
    }
    return false
  }

  const buttonClicked = (response: any) => {
    if (response.success) {
      const syntheticEvent = {
        preventDefault: () => {} // Mocking preventDefault method
      } as React.FormEvent<HTMLFormElement>;
      return submitTelegram(syntheticEvent,response)
    }
  }

  const getCountryCode = () =>{
    let result:any; 
    if(props.editNumber && props?.numberData){
      const countryCoddeNumber  = props?.numberData?.me?.user.toString().substring(0, 2);
      result = countryCoddeNumber ? parseInt(countryCoddeNumber) : "91";
    }else {
      result = userDetails?.country_code ? userDetails?.country_code : "91"
    }
    return countryCodes.find((item: any) => (item.value).toString() == (result).toString())
  }

  const onChangeData = (field: string, value: any) => {
    let privacy = settings.privacy
    const payloadData = {
      clientId : newClientId ? newClientId : inputText,
      userId : userDetails?.userId ? userDetails?.userId : userProfile?.userId,
      // numberId : data._id,
      value : value
  }

    privacy = {
      ...privacy,
      ...value
    };
    dispatch(updateSettings(field, privacy, payloadData));
  };

  return (
    <>
      <QrCodeWrapper className="QrCodeWrapper">
      <div className="chat-welcome-section user-chat user-chat-show d-flex align-item-center h-100">
        <Row className={`w-100 m-0 ${"wpQrScannerMainrow"}`}>
          <Col md={12} className={`p-0 ${titleText.part == 3 ? "align-content-center" :""}`}>
            {/* <div> */}
            {titleText.part == 2 && <h4 className="text-center mb-4">{titleText.text}</h4>}
            {titleText.part == 1 &&
                <Card className={`${"whenCurruntStepZero"} p-3 wpQrScanCardMain h-100`}>
                  <div className="mb-lg-4 mb-2 w-100 d-flex align-items-center gap-2">
                    <div className="ms-1 float-left">
                      <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                    </div>
                    {/* For whatsapp name title start */}
                    <div className="qrCodePageCardTitleMain">
                      <>
                        <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                          <b>Telegram {props.editNumber && `[ +${props?.numberData?.me?.user} ] `}</b>
                          <a id="whatsppcard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/support/solutions/articles/1060000075483-how-to-integrate-telegram-channel-with-controlhippo-" target="_blank">
                            <i className="bx bxs-info-circle font-size-20 lh-sm"></i>
                          </a>
                        </h2> 
                        <UncontrolledTooltip
                          target={`whatsppcard`}
                          placement="right"
                        >
                          Click here to know more..
                        </UncontrolledTooltip>
                      </>
                    </div>

                    {/* For whatsapp name title end */}
                  </div>
                  {<div className="wpQrCodeFirstTab">
                    <Row className="align-items-center qrmainrow">
                      <Col md={12} lg={6} className="ps-4">
                        <div className="qrGenerateSteps telgramSteps">
                          <h5 className="text-start">Use Telegram on your computer </h5>
                          <ol className="text-start d-flex flex-column gap-3 mt-3">
                            <li>Enter your Phone Number by which you have created the Telegram Account. </li>
                            <li>Click on the Send OTP button to received a authentication Code. </li>
                            <li>Enter the OTP which you have received on your Telegram App.</li>
                            <li>Now click on the Submit button. </li>
                          </ol>
                        </div>
                        {/* <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} /> */}
                      </Col>
                      <Col md={12} lg={6}>
                        <div className="telegramFormCardMain d-flex align-items-center justify-content-start flex-column">
                          <Card className="rounded-2 p-4 telegramFormCard">
                            <Form onSubmit={submitTelegram}>
                              <div className="row mb-3">
                                <div className="col-12">
                                  <Label htmlFor="AddContactModalemail-input" className="form-label mb-1">Phone Number</Label>
                                </div>
                                <div className="input-group">
                                  <div className="input-group-prepend updtProfContryCodeDropDwn updtProfContryCodeDropDwn telegrampDropDown">
                                    <Select
                                      options={countryCodes}
                                      defaultValue={getCountryCode}
                                      // value={telegramData.countryCode?telegramData.countryCode:defaultCountryCode}
                                      classNamePrefix="select"
                                      className="input-group-text"
                                      onChange={handleCountryCodeChange}
                                      isDisabled={telegramHandle.step == 2 || loading}
                                    // menuIsOpen={true}
                                    />
                                    {/* <SelectCountryCode /> */}
                                  </div>
                                  <Input
                                    type="text"
                                    className="formInputField"
                                    id="AddContactModalemail-input"
                                    placeholder="Enter Phone Number"
                                    value={telegramData?.phoneNumber ? telegramData?.phoneNumber : ""}
                                    pattern="[0-9]*"
                                    disabled={telegramHandle.step == 2 || loading}
                                    onChange={(e: any) => {
                                      onChangeTelegramData("phoneNumber", e.target.value.replace(/\D/g, ''));
                                    }}
                                  />
                                </div>
                              </div>
                              {telegramHandle.step == 2 && <div className="mb-3">
                                <Label htmlFor="AddContactModalname-input" className="form-label mb-1">
                                  Code
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control formInputField"
                                  id="AddContactModalname-input"
                                  placeholder="Enter Code"
                                  value={telegramData?.code ? telegramData?.code : ""}
                                  // pattern="[0-9]*"
                                  onChange={(e: any) => {
                                    onChangeTelegramData("code", e.target.value);
                                  }}
                                />
                              </div>}
                              {/* <Button type="submit" disabled={loading} className="btn btn-primary">{telegramHandle.btnText}</Button> */}
                              {/* { telegramHandle.step == 1 && <div className="text-center">
                                <Button
                                  color="primary"
                                  disabled={checkDisablity()}
                                  type="submit"
                                >
                                  {loading && <Spinner size="sm">
                                    Loading...
                                  </Spinner>}
                                  <span>
                                    {' '}{!loading ? telegramHandle.btnText : "Loading..."}
                                  </span>
                                </Button>
                              </div>} */}
                              {<div className={`d-flex align-items-center justify-content-center ${checkDisablity()? "is_disabled" :""}`}> <ChargebeeCheck numberData={props.numberData} userDetails={userProfile?userProfile:userDetails1} buttonText={`${telegramHandle.step == 1 ? "Send OTP" :"Verify OTP"}`} onConfirm={buttonClicked} from="whatsapp"/></div>}
                            </Form>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  }
                   {
                    <div className="privacyDivQr mt-4">
                      <Privacy privacy={tokenModelDetails} onChangeSettings={onChangeData} qrCodePage={true} />
                    </div>
                  }
                  {/* <hr className="border border-primary border-2 opacity-50" /> */}
                </Card>
            }
            {titleText.part == 2 &&
              <div className="text-center mb-4">
                <div className="card text-center mb-4" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h5 className="card-title">QR Code</h5>
                    {qrCode && (
                      <img src={qrCode} className="card-img-top" alt="..." />
                      // <QRCode className="card-img-top" value={qrCode} />
                    )}
                  </div>
                </div>
              </div>
            }
            {/* </div> */}
            {titleText.part == 3 && <>
              <div className="pb-4 text-center">
                <img src={ControllhippoLogo} height={100} width={250} alt="Controllhippo Logo" />
                <h4 className="mt-3 text-muted readyConnectionTxt">{titleText.text}</h4>
              </div>
              {/* <Loader/> */}
              <div className="d-flex justify-content-center resync mb-5" >
                <ProgressBarComponent className={"progressBar"} animated={true} value={titleText.progress} label={false} variant={'primary'} striped={false} visuallyHidden={true} />
              </div>
            </>}
          </Col>
        </Row>
      </div>
      </QrCodeWrapper>
      {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm}/>}
    </>
  );
};

export default Index;
