import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input } from 'reactstrap';
import { List, Avatar, Checkbox as AntdCheckbox } from 'antd';


//utils
import { DivideByKeyResultTypes } from "../utils";

// interfaaces
import { ContactTypes } from "../data/contacts";
import { useContacts, useRedux, useProfile } from "../hooks";
import {
  CreateChannelPostData, createChannel, addParticipants, getChatUserDetails, getDirectMessages
} from "../redux/actions";
import avatar1 from "../../src/assets/images/user_theme.svg";
import AppSimpleBar from "../../src/components/AppSimpleBar";

interface DataTypes {
  id: any;
  channelName: string;
  description: string;
}

type Option = {
  value: string; // or the appropriate type for the value property
  label: string;
};

interface ListItem {
  firstName: string;
  label: string;
  // Add other properties if needed
}

interface ContactItemProps {
  contact: ContactTypes;
  selected: boolean;
  onSelectContact: (id: string | number, selected: boolean) => void;
}
const ContactItem = ({
  contact,
  selected,
  onSelectContact,
}: ContactItemProps) => {
  const fullName = `${contact.firstName} ${contact.lastName}`;
  const onCheck = (checked: boolean) => {
    onSelectContact(contact.id, checked);
  };

  return (
    <li>
      <div className="form-check">
        <Input
          type="checkbox"
          className="form-check-input"
          id={`contact-${contact.id}`}
          onChange={(e: any) => {
            onCheck(e.target.checked)
          }}
          value={fullName}
        />
        <Label className="form-check-label" htmlFor={`contact-${contact.id}`}>
          {fullName}
        </Label>
      </div>
    </li>
  );
};

interface CharacterItemProps {
  letterContacts: DivideByKeyResultTypes;
  index: number;
  totalContacts: number;
  selectedContacts: Array<number | string>;
  onSelectContact: (id: string | number, selected: boolean) => void;
}

const CharacterItem = ({
  letterContacts,
  index,
  totalContacts,
  selectedContacts,
  onSelectContact,
}: CharacterItemProps) => {
  return (
    <div>
      <div className="contact-list-title">{letterContacts.letter}</div>

      <ul
        className={classnames("list-unstyled", "contact-list", {
          "mb-0": index + 1 === totalContacts,
        })}
      >
        {(letterContacts.data || []).map((contact: any, key: number) => {
          const selected: boolean = selectedContacts.includes(contact.id);
          return (
            <ContactItem
              contact={contact}
              key={key}
              selected={selected}
              onSelectContact={onSelectContact}
            />
          );
        })}
      </ul>
    </div>
  );
};
interface AddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateChannel: (params: CreateChannelPostData) => void;
  diffModal: any,
  groupId: any,
  onSend?: any,
  chatUserDetails?: any,
  getAllChatList?: any;
}
const AddGroupModal = ({
  isOpen,
  onClose,
  onCreateChannel,
  diffModal,
  groupId,
  onSend,
  chatUserDetails,
  getAllChatList
}: AddGroupModalProps) => {
  /*
    collapse handeling
    */
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const [loadings, setLoadings] = useState([false, false, false]);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState({ channelName: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedItems, setCheckedItems] = useState<Option["value"][]>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [groupName, setGroupName] = useState<String | null>(null);
  const [selectedContacts, setSelectedContacts] = useState<Array<string | number>>([]);
  const ref = useRef<any>();
  const [chatUserList, setChatuserList] = useState([]);
  const [limit] = useState(50);
  const [searchInputValue, setSearchInputValue] = useState("");
  const localStorageChatFilter = localStorage.getItem("selectedChatFilter")
  const [selctedFilter, setSelctedFilter] = useState<any>(localStorageChatFilter);
  const [modal, setModal] = useState(isOpen);
  const toggle = () => {
    if(modal){
      onClose()
    }
    setModal(false)
  };
  useEffect(() => {
    if (selectedValues !== null) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [selectedValues])

  const {
    isChatUsersSyncing,
    clientList,
    directMessages,
    getChatList1,
    client_id
  } = useAppSelector((state) => {
    return {
      getChatList1: state.Chats.skipChatUserList,
      isChatUsersSyncing: state.Chats.isChatUsersSyncing,
      directMessages: state.Chats.directMessages,
      clientList: state.Chats.clientList,
      client_id: state.Chats.clientId
    };
  });
  const [clientId, setClientId] = useState<string>(client_id);
  useEffect(() => {
    client_id ?setClientId(client_id) : setClientId(userProfile?.clientId) 
  }, [client_id])
  useEffect(() => {
    dispatch(getDirectMessages(userProfile?.clientId, 0, 100, "All", null))
  }, [isOpen])

  useEffect(() => {
    if (directMessages?.length > 0) {
      setChatuserList(directMessages)
    }
  }, [directMessages])

  const allResults = directMessages || [];
  const options = allResults
    .filter((data: any) => data.isGroup !== true)
    .map((data: any) => {
      return {
        value: data?.chatId,
        label: data?.firstName,
        profilePic: data?.profilePic
      };
    });

  const onScrollChange = (data: any) => {
    if (data) {
      if (getChatList1.hasNext) {
        dispatch(getDirectMessages(userProfile?.clientId, getChatList1.skip + 1, 50, "All", 0))
      }
    }
  }


  const handleScroll = () => {
    const scrollElement = ref.current.getScrollElement();
    const tolerance = 3; // Adjust tolerance as needed
    const scrollTop = scrollElement.scrollTop;
    const scrollHeight = scrollElement.scrollHeight;
    const clientHeight = scrollElement.clientHeight;
    const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= tolerance;
    if (bottom) {
      if (chatUserList.length > 0) {
        onScrollChange(true);
      }
    }
  };
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
      ref.current.getScrollElement().addEventListener("scroll", handleScroll); // Remove parentheses here
    }
    return () => {
      if (ref.current) {
        ref.current.getScrollElement().removeEventListener("scroll", handleScroll); // Remove parentheses here
      }
    };
  }, [chatUserList]);

  useEffect(() => {
    if (directMessages?.length > 0) {
      setChatuserList(directMessages)
    }
  }, [directMessages])


  useEffect(() => {
    if (
      selectedContacts.length === 0
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [selectedContacts, data]);

  const onDataChange = (fieldName: any, value: any) => {
    setGroupName(value)
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  // const clientId = userProfile?.clientId

  const handleCheckboxChange = (item: Option) => {
    const updatedCheckedItems: Option["value"][] = checkedItems.includes(item.value)
    ? checkedItems.filter((checkedItem) => checkedItem !== item.value)
    : [...checkedItems, item.value];
    const aaa = (options.filter((option: Option) => updatedCheckedItems.includes(option.value)));
    const uniqueValues = new Set();
    const finalSelectedArray = aaa.filter((item: any) => {
      if (!uniqueValues.has(item.value)) {
        uniqueValues.add(item.value);
        return true;
      }
      return false;
    });
    setSelectedValues(finalSelectedArray);
    setCheckedItems(updatedCheckedItems);
  };

  const handleSubmit = async (index: any) => {
    const uniqueValues = new Set();

    const selectedValuesArray = selectedValues.filter((data: any) => {
      if (!uniqueValues.has(data.value)) {
        uniqueValues.add(data.value);
        return true;
      }
      return false
    });
    const selectedFinalArray = selectedValuesArray.map((data: any) => data.value)
    const selectedNameArray = selectedValuesArray.map((data: any) => data.label);
    const payLoad = {
      groupArray: selectedFinalArray,
      groupName: groupName,
      clientId: clientId !== "all_numbers" ? clientId : clientList[0]?.clientId
    }
    const payLoad1 = {
      participantChatId: selectedValuesArray,
      groupId: groupId,
      clientId: clientId !== "all_numbers" ? clientId : clientList[0]?.clientId
    }

    const payLoad2 = {
      selectedChatIds: selectedFinalArray,
      selctedName: selectedNameArray,
      clientId: clientId !== "all_numbers" ? clientId : clientList[0]?.clientId,
      type: "vCard"
    }
    if (diffModal == "participatns") {
      setValid(false)
      dispatch(addParticipants(payLoad1))
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = true;
        return newLoadings;
      });
      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = false;
          return newLoadings;
        });
        // dispatch(getChatUserDetails(groupId, clientId));
        onClose();
      }, 6500);
    } else if (diffModal == "contact") {
      setValid(false)
      onSend(payLoad2)
      dispatch(getDirectMessages(userProfile?.clientId, 0, 50, selctedFilter, 0))
      onClose()
    } else {
      setValid(false)
      dispatch(createChannel(payLoad));
    }
    toggle()
  };


  function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number) {
    let timeoutID: number | undefined;
  
    const debounced = (...args: Args) => {
      clearTimeout(timeoutID);
      timeoutID = window.setTimeout(() => fn(...args), delay);
    };
  
    return debounced;
  }


  const searchUsers = () => {
    var li, a, i, txtValue: any;
    const inputValue: any = document.getElementById("searchInModal1");
    setSearchInputValue(inputValue)
    if(clientId !== "all_numbers"){
      dispatch(getDirectMessages(clientId, 0, 50, "All", 0, inputValue.value))
    }else{
      dispatch(getDirectMessages("all_numbers", 0, 50, "All", 0, inputValue.value))
    }
  };
  const debouncedSearch = debounce(searchUsers, 700);


  return (
    <Modal isOpen={modal} toggle={toggle} tabIndex={-1} centered scrollable id="addgroup-exampleModal" className="create_group_modal add_participant_modal" role="dialog">
      <ModalHeader className="modal-title-custom" toggle={toggle}>
        {diffModal == "participatns" ? "Add Participants" : diffModal == "contact" ? "Send contacts" : "Create New Group"}
      </ModalHeader>

      <ModalBody className="pb-3 pt-2 px-3">
        <Form>
          {diffModal == "createGroup" &&
            <div className="mb-3">
              {/* <Label htmlFor="addgroupname-input" className="form-label">
                Group Name
              </Label> */}
              <Input
                type="text"
                className="form-control"
                id="addgroupname-input"
                placeholder="Enter Group Name"
                value={data.channelName || ''}
                onChange={(e) => onDataChange('channelName', e.target.value)}
                rules={[{ required: true, message: 'Please input Group Name' }]}
              />
            </div>
          }
          <Input
          onKeyUp={debouncedSearch}
          id="searchInModal1"
          type="text"
          className="form-control bg-light border-0 pe-0"
          placeholder="Search here.."
          autoFocus
        />
          <div className="input-group-prepend">
            <AppSimpleBar className={`chat-room-list chatRoomListMain addGroupModalBar`} scrollableNodeProps={ref} scrollRef={ref}>

              <List
                id="contact_group_list"
                className="contact_group_list"
                dataSource={options.filter((item: ListItem) =>
                  item?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  item?.label?.toLowerCase().includes(searchQuery.toLowerCase())
                )}
                renderItem={(item: any) => {
                  return (

                    <List.Item>
                      <AntdCheckbox onChange={() => (diffModal == "createGroup" || diffModal == "contact" || diffModal == 'participatns') ? handleCheckboxChange(item) : null}
                        checked={checkedItems.includes(item.value)}>
                      </AntdCheckbox>
                      <List.Item.Meta
                        avatar={<Avatar src={item?.profilePic ? item?.profilePic : avatar1} />}
                        title={
                          <div className="create_group_lable">
                            {item?.firstName ? item.firstName : item?.label}
                          </div>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </AppSimpleBar>
          </div>
        </Form>
        <div className="d-flex justify-content-end mt-3">
              <Button color="link" type="button" onClick={toggle}>
                Close
              </Button>
              {diffModal == "participatns" ?
                <Button
                  color="primary"
                  disabled={selectedValues.length === 0 || !valid}
                  onClick={() => handleSubmit(1)}
                >
                  Add Participants
                </Button>
                : diffModal == "contact" ?
                  <Button
                    color="primary"
                    disabled={selectedValues?.length === 0 || !valid}
                    onClick={() => handleSubmit(1)}
                  >
                    Send
                  </Button>
                  :
                  <Button
                    color="primary"
                    disabled={!selectedValues.length || !groupName?.length}
                    onClick={() => handleSubmit(1)}
                  >
                    Create Group
                  </Button>
              }
            </div>
      </ModalBody>



      {/* {diffModal == "participatns" ?
          <Button
            color="primary"
            disabled={selectedValues.length === 0 || !valid}
            onClick={() => handleSubmit(1)}
          >
            Add Participants
          </Button>
          : diffModal == "contact" ?
            <Button
              color="primary"
              disabled={selectedValues.length === 0 || !valid}
              onClick={() => handleSubmit(1)}
            >
              Send
            </Button>
            :
            <Button
              color="primary"
              disabled={!groupName || selectedValues.length === 0 || !valid}
              onClick={() => handleSubmit(1)}
            >
              Create Groups
            </Button>
        } */}


    </Modal>
  );
};

export default AddGroupModal;
