import React, { useState, useEffect, useRef } from 'react'
import moment from "moment";
import Leftbar from '../Dashboard/Leftbar'
import Welcome from '../Dashboard/ConversationUser/Welcome'
import { Form, Input, message, Space, Checkbox, Row, Col, Select, Radio, Popover, Card, Anchor, Button } from 'antd';
import { updateAuthData, useProfile, useRedux } from '../../hooks';
import { getUserDetails, getCustomerPortal, getInvoice, invoiceDownload, clearCustomerPortal, getProfileDetails, addCredit, systemLogoutUser, cancelAccount, getInvoicePaymentUrl, connectSocket, updateUserProfile, changePassword } from '../../redux/actions';
import { showErrorNotification, showSuccessNotification } from '../../helpers/notifications';
import { useNavigate } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';
import { TimeZone } from "../../../../client/src/data";
import { countryCodeOptions } from "../../../../client/src/data";
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordChecklist from "react-password-checklist";
import { toast } from "react-toastify";

const countryData = require('country-data');

interface Option {
  name: string;
  credit: number;
}

const PersonalInformation = () => {
  const { userProfile } = useProfile()
  const { dispatch, useAppSelector } = useRedux()
  const navigate = useNavigate();
  const topRef = React.useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLDivElement>(null);
  const [newInputEmail, setNewInputEmail] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [password, setPassword] = useState("");
  const [isValidationPopupOpen, setValidationPopupOpen] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();


  const handlePasswordChange = (value: any) => {
    setPassword(value)
    setValidationPopupOpen(false)
  }

  const handleValidation = (rules: any) => {
    setValidationPopupOpen(rules);
  };
  useEffect(() => {
    if (userProfile?.userId) dispatch(getProfileDetails(userProfile?.userId));
  }, []);


  const {
    profileData,
    profileDetails,
    changePwdSuccessMsg,
    changepasswordError,
    changepasswordSuccess,
  } = useAppSelector(state => {
    return {
      profileData: state.Login.user,
      profileDetails: state.Profile.profileDetails,
      changePwdSuccessMsg: state.changePassword.changePwdSuccessMsg?.message?.message,
      changepasswordError: state.changePassword.changePwdErrorMsg?.error?.error,
      changepasswordSuccess: state.changePassword.changepasswordSuccess,
    };
  });

  useEffect(() => {
    if (profileDetails && Object.keys(profileDetails).length > 0) {
      form.setFieldsValue({
        fullName: profileDetails.fullName || '',
        phoneNumber: profileDetails.phoneNumber || '',
        companyName: profileDetails.companyName || '',
        email: profileDetails.email || '',
        timezone: profileDetails.timezone || '',
        country_code: profileDetails.country_code || ''
      });
    }
  }, [profileDetails]);

  useEffect(() => {
    if (changePwdSuccessMsg || changepasswordError) {
      if (changePwdSuccessMsg) {
        toast.success(changePwdSuccessMsg);
        const toastDismissTimer = setTimeout(() => {
          toast.dismiss();
          navigate("/welcome");
        }, 2000);
        return () => clearTimeout(toastDismissTimer);
      } else {
        toast.error(changepasswordError);
      }
    }
  }, [changePwdSuccessMsg, changepasswordError]);

  const uniqueCountryCodeOptions = countryCodeOptions.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.code === current.code);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const countryCodes = uniqueCountryCodeOptions.map((country: any) => {
    const dialCode: string = country.code.replace('+', '');
    const label: string = `+${dialCode} (${country.shortname})`;
    return { value: dialCode, label };
  });
  
  const onFinish = (values: any) => {
    values.userId = userProfile?.userId;
    dispatch(updateUserProfile(values));
    setNewInputEmail(false)
    setIsModified(!isModified);
  };

  const onFinishPwd = (values: any) => {
    console.log("onSubmitForm ~ values:")
    const data = {
      oldPassword: values.oldPassword,
      password: values.newPassword,
    };
    if(data && isValidationPopupOpen){
      dispatch(changePassword(data));
    }
  }
  const handleEmailChange = () => {
    setNewInputEmail(true)
  }
  const handleValuesChange = () => {
    setIsModified(true);
  };

  return (
    <>
        <div className="UserProfMain">
          <div id="sectionWrapper">
            <section
              id="personalInfo"
              ref={topRef}
              className="mb-3"
            >
              <Card className="mb-0 userProfCardMain"
                title={
                  <div className='text-start'>
                    <h2 className='mb-0 lh-sm'>Personal Information</h2>
                    <p className='text-muted font-size-14 fw-normal mb-0 cardDesc text-wrap'>Your personal dashboard for viewing and editing your details.</p>
                  </div>
                }
                bordered={false}
              >
                <Form
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  // initialValues={initialFormValues}
                  // onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="EmailFormAdd addtemplet_form personalInfoForm"
                  requiredMark={false}
                  onValuesChange={handleValuesChange}
                >
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Name" name="fullName" rules={[{ required: true, message: 'Please enter your name' }]}>
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Company Name" name="companyName" rules={[{ required: true, message: 'Please enter your company name' }]}>
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Phone Number" name="phoneNumber" rules={[
                        { required: true, message: 'Please enter your phone number' },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Please enter only numbers for the phone number',
                        },
                      ]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Time Zone" name="timezone" rules={[{ required: true, message: 'Please select your timezone' }]}>
                        <Select>
                          {TimeZone.map((timezone) => (
                            <Select.Option key={timezone} value={timezone}>
                              {timezone}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>

                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row" ref={emailInputRef}>
                      <Form.Item
                        label={<div className='d-flex align-items-center gap-1'>
                          <span>Email</span>
                          <div>
                            <Button
                              className='emailEditButton'
                              icon={<i className='bx bxs-pencil' />}
                              onClick={handleEmailChange}
                            />
                          </div>
                        </div>}
                        name="email" rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
                        <Input readOnly />
                      </Form.Item>
                      {newInputEmail &&
                        <Form.Item
                          name="newEmail"
                          label="New Email"
                          className='profileFormFeild'
                          rules={[{ type: 'email', message: 'Please enter a valid email' }]}
                        >
                          <Input />
                        </Form.Item>
                      }
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item
                        label={<div className='d-flex align-items-center gap-1'>
                          <span>Default Country Code</span>
                          <div>
                            <Popover content={`If no country code in CRM contact, system adds default & starts conversation.`} placement="right">
                              <InfoCircleFilled className='font-size-13' />
                            </Popover>
                          </div>
                        </div>}
                        name="country_code"
                        rules={[{ required: true, message: 'Please select your country code' }]}
                      >
                        <Select
                          showSearch
                          style={{ "width": "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) => ((option?.label)?.toString().toUpperCase() ?? '').includes(input.toUpperCase())}
                          filterSort={(optionA, optionB) =>
                            ((optionA?.label)?.toString() ?? '').toLowerCase().localeCompare(((optionB?.label)?.toString() ?? '').toLowerCase())
                          }
                          options={countryCodes}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18} className="gutter-row">
                      <Form.Item className='d-flex align-items-center justify-content-center mb-0' >
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={!isModified}
                          className='btn-primary profileSubmitButton m-auto'
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </section>
          </div>
        </div>
    </>
  );
}

export default PersonalInformation