import mixpanel from 'mixpanel-browser';
import config from "../config";
import moment from "moment";

if (config.MIXPANEL_KEY) mixpanel.init(config.MIXPANEL_KEY);
const addMixpanelEvent = (eventName: string,property:any) => {
    let userData:any = localStorage.getItem("authData")
    userData = JSON.parse(userData)
    if(userData && Object.keys(userData).length){
        const timestamp = moment(userData?.createdAt);
        const currentTime = moment(config.MIXPANEL_NEW_CHANGES_DATE);
        if(!userData?.createdAt || timestamp.isBefore(currentTime)){
            property.distinct_id = userData?.email
        }
    }
    if (config.MIXPANEL_KEY && property?.distinct_id){
        mixpanel.identify(property.distinct_id);
        property.LastSeenAt = new Date()
        if(eventName == "Integrations"){
            property.Integrations = property.integrationName
            property.LastIntegrationAt = new Date()
            delete property.integrationName
        }
        mixpanel.people.set(property);   
        if(eventName == "LastMessageSentAt")mixpanel.people.increment({email:property.email,messageSent:1});
        if(eventName !== "LastMessageSentAt" && eventName !== "LastSeenAt" && eventName !== "Integrations")mixpanel.track(eventName, property);  
    }
};

export { addMixpanelEvent };
