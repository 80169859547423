import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";
import { Form, Input, message, Space, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useRedux, useProfile } from "../hooks/index";

const { Option } = Select;

interface InviteContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    channelConnect?: any;
    channelData?: any;

}
const ChannelModal = ({
    isOpen,
    onClose,
    channelData,
    channelConnect
}: InviteContactModalProps) => {
    const [form] = useForm<any>();
    const { dispatch, useAppSelector } = useRedux();
    const { userProfile } = useProfile()
    const [inputvalue, setInputvalue] = useState("");

    const {
        createdUser, editWhatsappNumberSuccess, editChatUserDetails
    } = useAppSelector((state) => {
        return {
            createdUser: state.Chats.createdUser,
            editWhatsappNumberSuccess: state.Chats.editWhatsappNumberSuccess,
            editChatUserDetails: state.Chats.editChatUserDetails
        };
    });

    const onFinish = (data: any) => {
        const payloadData = {
            selectuseCase : data.useCase,
            useCaseReason : data.useCaseReason,
            channelName : channelData?.displayName
        }
        if(payloadData){
            channelConnect(payloadData)
        }
    }

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const handleCancel = () => {
        onClose();
    }

    const onFormatInputChange = (event: any) => {
        setInputvalue(event.target.value)
    }
    return (
        <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
            <ModalHeader className="modal-title-custom" toggle={onClose}>
                {channelData?.displayName}
                {/* <div>
                    Please tell us your use case, so that we can offer you the best!
                </div> */}
            </ModalHeader>
            <ModalBody>
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className='EmailFormAdd addtemplet_form'
                    requiredMark={false}
                >

                    <Form.Item
                        name="useCase"
                        className='temp_category_element'
                        rules={[{ required: true, message: 'Please select your use case' }]}
                    >
                        <Select placeholder="Select your use case" className='temp_category_dropdown'>
                            <Option value="Sales and Services">Sales and Services</Option>
                            <Option value="Marketing and Promotions">Marketing and Promotions</Option>
                            <Option value="Customer Support">Customer Support</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="useCaseReason"
                        //   label="Template Format"
                        rules={[{ required: true, message: 'Please input your use case' }]}
                    >
                        <Input.TextArea
                            onChange={(e: any) => onFormatInputChange(e)}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            // showCount
                            // maxLength={1024}
                            placeholder="Let us know how can we help you, Please mention your use case."
                        />
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'center' }}>
                        <Space>
                            <Button className='submitbuttonTemp' color='primary' type="submit">
                                Submit
                            </Button>
                            <Button className='canclebtnTemp' color='danger' outline onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                        </Space>
                    </Form.Item>


                </Form>
            </ModalBody>

        </Modal>
    );
};

export default ChannelModal;
