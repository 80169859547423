import { UpdateSubUserPwdActionTypes, AuthConfirmDataState } from "./types";

export const INIT_STATE: AuthConfirmDataState = {
  authData: null,
  confirmaccountError: null,
  userSuccess: null,
};

const updateSunbUserPassword = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UpdateSubUserPwdActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD:
          return {
            ...state,
            authData: action.payload.data.data,
            userSuccess:action.payload.data.success,
            emailSended: true,
            loading: false,
          };
        // case UpdateSubUserPwdActionTypes.CHANGE_PASSWORD: {
        //   return {
        //     ...state,
        //     passwordChanged: true,
        //     loading: false,
        //   };
        // }
        default:
          return { ...state };
      }

    case UpdateSubUserPwdActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD:
          return {
            ...state,
            confirmaccountError: action.payload.error,
            emailSended: false,
            loading: false, 
          };
        // case UpdateSubUserPwdActionTypes.CHANGE_PASSWORD: {
        //   return {
        //     ...state,
        //     changepasswordError: action.payload.error,
        //     passwordChanged: false,
        //     loading: true,
        //   };
        // }

        default:
          return { ...state };
      }

    case UpdateSubUserPwdActionTypes.UPDATE_SUB_USER_PWD: {
      return {
        ...state,
        authData: null,
        confirmaccountError: null,
        emailSended: false,
        loading: true,
      };
    }
    // case UpdateSubUserPwdActionTypes.CHANGE_PASSWORD: {
    //   return {
    //     ...state,
    //     passwordChanged: false,
    //     loading: true,
    //   };
    // }

    default:
      return { ...state };
  }
};

export default updateSunbUserPassword;
