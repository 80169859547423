
import React,{ useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useRedux } from '../hooks';
import { clearError } from '../redux/actions';
import UpGradePlanPopup from '../pages/PlanBilling/UpGradePlanPopup';

interface userDataType {
    chargebeeDetails:any
}
interface EstimatePopupTypes {
    type?: string;
    isOpen?: boolean;
    showUpgreadButton: boolean;
    message?: string;
    onConfirm?: (valye:any) => void;
    onCancel: (value:boolean) => void;
    data?: any;
}

const EstimatePopup = ({data,isOpen,showUpgreadButton, onConfirm, onCancel }: EstimatePopupTypes) => {    
    const {type,  message} = data
    const navigate = useNavigate();
    const { dispatch } = useRedux();
    const [modal, setModal] = useState(isOpen);

    
    const toggle = () => {
        setModal(!modal);
        if(modal)onCancel(true);
    }

    const handleConfirm = () => {
        if(onConfirm)onConfirm(data)
        toggle();
    };

    const handleCancel = () => {
        toggle();
    };
    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            {/* <ModalHeader className='justify-content-end' toggle={toggle} close={true}>
                <div >
                    <Button close    onClick={toggle} />
                </div>
            </ModalHeader> */}
            <ModalBody className='my-1'>
                <div dangerouslySetInnerHTML={{ __html: message || '' }} />
            </ModalBody>
            <ModalFooter className='d-flex justify-content-end'>
                <Button type="button" outline color="link" className="btn btn-outline-primary" onClick={handleCancel}>Close</Button>
                <Button type="button" className="btn btn-primary" onClick={handleConfirm}>Yes</Button>
            </ModalFooter>
        </Modal>
    );
};

export default EstimatePopup ;