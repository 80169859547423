import slackIcon from "../assets/images/slack.png";
import webhooksIcon  from "../assets/images/webhook s.svg";
import callHippo  from "../assets/images/callhippo_sm.svg";
import twillioLogo  from "../assets/images/twilio.svg";
const URL = process.env.REACT_APP_FRONT_URL
interface Channel {
  displayName: string;
  subDisplayName?: string;
  name: string;
  category: string;
  status: string;
  type: string;
  iconSrc: string;
  url?: string;
  btnText: string;
  iconValue: string;
  isPopular: boolean;
  priority?: Number;
  note: string;
  badge?: string;
  badgeiconSrc?: string;
  badgeiconColor?: string;
}
interface ChannelList {
  [key: string]: Channel;
}
export type { ChannelList, Channel };

let channelList: ChannelList = {
  "whatsapp": {
    "displayName": "WhatsApp",
    "subDisplayName": "(Mobile App)",
    "name": "whatsapp",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/whatsapp-chnl.svg",
    "url": `${URL}/channels/whatsapp`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    "priority": 1,
    "note": "To begin instant messaging, simply scan the QR code using your WhatsApp mobile app. There's no requirement for a Facebook Manager Account.",
    "badge": "Popular",
    "badgeiconSrc": "https://images.controlhippo.com/svg/star_rate.svg",
    "badgeiconColor": 'orange'
  },
  "telegram": {
    "displayName": "Telegram",
    "subDisplayName": "(Mobile App)",
    "name": "telegram",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/telegram-chnl.svg",
    "url":`${URL}/channels/telegram`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    "priority": 4,
    "note":"We require the phone number linked to your Telegram account for authentication purposes.",
    "badge": "Popular",
    "badgeiconSrc": "https://images.controlhippo.com/svg/star_rate.svg",
    "badgeiconColor": 'orange'
  },
  "business_whatsapp_api": {
    "displayName": "WhatsApp Business API",
    "subDisplayName": "(Official Platform API)",
    "name": "business_whatsapp_api",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/whatsapp-api-chnl.svg",
    "url": `${URL}/channels/business-whatsapp-api`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    // "priority": 3,
    "note": "Before you begin using it, please note that we require your official, verified Facebook Business Manager page for WhatsApp Business authentication.",
  },
  "chatbot": {
    "displayName": "ChatBot",
    "name": "chatbot",
    "category" : "Communication",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/chatbot_icon.svg",
    "url":`${URL}/channels/chatbot`,
    "btnText" : "Connect",
    "iconValue" : "check_circle",
    "isPopular" : true,
    "note": "Easy communication with basic chat feature. User-friendly interface, security, and customization for seamless interaction.",
    "badge": "New",
    "badgeiconSrc": "https://images.controlhippo.com/svg/new_badge_icon.svg",
    "badgeiconColor": 'green'
  },
 
  "messenger": {
    "displayName": "Messenger",
    "name": "messenger",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/messenger_small.svg",
    "url":`${URL}/channels/messenger`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    "priority": 5,
    "note": "Before you begin using it, please note that we require your official, verified Facebook Business Manager page for Messenger authentication.",
    "badge": "New",
    "badgeiconSrc": "https://images.controlhippo.com/svg/new_badge_icon.svg",
    "badgeiconColor": 'green'
  },
  "instagram": {
    "displayName": "Instagram",
    "name": "instagram",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/instagram-int.svg",
    "url":`${URL}/channels/instagram`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    "note": "Before you begin using it, please note that we require your official, verified Facebook Business Manager page for Instagram authentication.",
    "badge": "New",
    "badgeiconSrc": "https://images.controlhippo.com/svg/new_badge_icon.svg",
    "badgeiconColor": 'green'
  },
  "business_whatsapp": {
    "displayName": "WhatsApp Business",
    "subDisplayName": "(Mobile App)",
    "name": "business_whatsapp",
    "category": "Marketing Automation",
    "status": "integrated",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/whatsapp-business-chnl.svg",
    "url": `${URL}/channels/whatsapp`,
    "btnText": "Connect",
    "iconValue": "check_circle",
    "isPopular": true,
    "priority": 2,
    "note": "To start instant messaging on WhatsApp Business, just scan the QR code using your WhatsApp Business mobile app. No Facebook Manager Account is needed."
  },
 
"sms": {
    "displayName": "Twilio SMS",
    "name": "email",
    "category" : "Communication",
    "status": "integrate",
    "type": "other",
    "iconSrc": twillioLogo,
    "btnText" : "On Request",
    "iconValue" : "check_circle",
    "isPopular" : true,
    "note": "SMS provides businesses with reliable global text messaging for customers."
  },"callhippo": {
    "displayName": "CallHippo",
    "name": "email",
    "category" : "Communication",
    "status": "integrate",
    "type": "other",
    "iconSrc": "https://images.controlhippo.com/svg/callhippo_sm.svg",
    "btnText" : "On Request",
    "iconValue" : "check_circle",
    "isPopular" : true,
    "note": "Enhance your global reach with our telephony system and virtual numbers, ensuring seamless communication with customers in every corner of the world."
  },
  "email": {
    "displayName": "Email",
    "name": "email",
    "category" : "Communication",
    "status": "integrate",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/email-int.svg",
    "btnText" : "On Request",
    "url":`${URL}/channels/email`,
    "iconValue" : "check_circle",
    "isPopular" : true,
    "priority": 6,
    "note": "Email remains a fundamental tool for digital communication, allowing individuals and businesses to exchange messages, files, and documents instantly."
  },
  "viber": {
    "displayName": "Viber",
    "name": "viber",
    "category" : "Communication",
    "status": "integrate",
    "type": "native",
    "iconSrc": "https://images.controlhippo.com/svg/viber-int.svg",
    "btnText" : "On Request",
    "iconValue" : "check_circle",
    "isPopular" : true,
    "note": "Viber is a global messaging and calling app with text, voice, and video features, including group chats, stickers, and device synchronization."
},"wechat": {
  "displayName": "We Chat",
  "name": "wechat",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/we_chat-int.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "WeChat offers messaging, social media, payment services, allowing users to connect, share, shop, and access various services within a single platform."
},"line": {
  "displayName": "Line",
  "name": "line",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/line_icon.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "Line stands out as a versatile platform for global communication, offering an array of features such as text, voice, and video messaging."
},"x": {
  "displayName": "X",
  "name": "x",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/twitter_icon.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "X lets you share thoughts globally with short messages called tweets. It's popular for discussions, news, and connecting people."
},"google_business_message": {
  "displayName": "Google Business Message",
  "name": "google_business_message",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/business_messages_icon_new.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "Business Messages lets you chat with brands on Google Search, Maps, Ads, and their own channels."
},"outlook": {
  "displayName": "Outlook",
  "name": "outlook",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/outlook_background_icon.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "Outlook helps manage emails, schedules, and tasks smoothly. Works well with Office, adds collaboration tools, and customizable options."
},"yahoo": {
  "displayName": "Yahoo",
  "name": "yahoo",
  "category" : "Communication",
  "status": "integrate",
  "type": "other",
  "iconSrc": "https://images.controlhippo.com/svg/yahoo_icon.svg",
  "btnText" : "On Request",
  "iconValue" : "check_circle",
  "isPopular" : true,
  "note": "Yahoo Mail makes it easy to manage emails with a simple layout. It offers lots of storage, strong security, and personalized settings"
}
};

export { channelList};
