import styled from 'styled-components';

const MyProfileComponent = styled.div`
  .accordion-body{
    padding:10px 20px!important;
  }
  .profileFormMain{
    // .profileFormInputsContainer{
    //   border-top: 1px solid #eee;
    //   max-height: calc(100vh - 227px);
    //   overflow-y: auto;
    //   &::-webkit-scrollbar-thumb {
    //     background-color: #c1c1c1;
    //     outline: unset!important;
    //     border-radius: 14px;
    //   }
    //   &::-webkit-scrollbar-track {
    //       background: #f1f1f1!important;
    //   }
    //   &::-webkit-scrollbar {
    //     width: 0.4em;
    //   }
    // }
    .profileImgContainer{
      display: flex;
      justify-content: center;
      padding: 8px;
      img{
        border-radius: 50%;
        overflow: hidden;
        background-color: #f6f4ff;
      }
    }
    .profileFormFeild{
      padding: 0px;
      margin-bottom: 10px;
      label{
        height:22px !important;
      }
    }
    .profileEmailContainer{
      .profileFormFeild{
        margin-right: 4px;
        width: 500px;
        .profileEmailAddNewBtn{
          margin-left: 5px;
          width: 20px;
          height: 20px;
          border-radius: 4px !important;
          padding: 0px 12px !important;
          &:hover,&:focus{
            background-color:#0029c7 !important;
            border-color: #0029c7 !important;
            i{
              color:white !important;
            }
          }
        }
      }
    }
    .userProfileSubmitContainer{
      .ant-form-item-row{
        justify-content: center;
        .ant-form-item-control{
          margin-left:0 !important;
        }
        .ant-col{
          align-items: center;
        }
      }
      .profileSubmitButton:disabled{
        &:hover,&:focus{
          cursor: not-allowed !important;
          border-color: #d9d9d9  !important;
          color: rgba(0, 0, 0, 0.25)  !important;
          background-color: #0000000a  !important;
          box-shadow: none  !important;
        }
      }
    }
  }
`;

export { MyProfileComponent };