import { PlanBillingActionTypes } from "./types";

// common success
export const planBillingApiResponseSuccess = (actionType: string, data: any) => ({
    type: PlanBillingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const planBillingApiResponseError = (
    actionType: string,
    error: string
) => ({
    type: PlanBillingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCustomerPortal = (user: string,callback?:(result:any)=>void) => {
    return {
        type: PlanBillingActionTypes.GET_CUSTOMER_PORTAL,
        payload: { user },
        callback
    };
};

export const cancelAccount = (payLoad: any,callback?:(result:any)=>void) => {
    return {
      type: PlanBillingActionTypes.CANCEL_ACCOUNT,
      payload: payLoad ,
      callback
    };
  };

export const clearCustomerPortal = () =>{
    return {
        type: PlanBillingActionTypes.CLEAR_CUSTOMER_PORTAL,
    };
}

export const getInvoice = (status: any) => {
    return {
        type: PlanBillingActionTypes.GET_INVOICES,
        payload: { status },
    }
};

export const invoiceDownload = (id:string) =>{
    return {
        type: PlanBillingActionTypes.INVOICE_DOWNLOAD,
        payload: { id },
    }
}

export const getPlans = () => ({
    type: PlanBillingActionTypes.GET_PLANS,
})

export const changeSubscription = (data:any , parentId: string,callback?:(result:any)=>void) => ({
    type: PlanBillingActionTypes.CHANGE_SUBSCRIPTION,
    payload : {data, parentId},
    callback
})
export const chargebeeProccess = (data:any , parentId: string,callback?: (result:any) => void) => ({
    type: PlanBillingActionTypes.CHARGEBEE_PROCESS,
    payload : {data, parentId},
    callback
})

export const clearStateData = () => ({
    type: PlanBillingActionTypes.CLEAR_STATE_DATA,
})

export const checkEstimateCost = (data:any,id:string,callback?: (result:any) => void) => ({
    type : PlanBillingActionTypes.CHECK_ESTIMATE_COST,
    payload: {data,id},
    callback
})

export const addCredit = (data:any,callback: (result:any) => void) => ({
    type : PlanBillingActionTypes.ADD_CREDIT,
    payload: {data},
    callback
})
export const getInvoicePaymentUrl = (id:string,callback: (result:any) => void) => ({
    type : PlanBillingActionTypes.GET_INVOICE_PAYMENT_URL,
    payload: id,
    callback
})