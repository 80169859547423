// Socket Actions (socketActions.ts)
import { Action } from 'redux';
import { WebsocketActionTypes } from "./types";

export interface connectSocketAction extends Action<'SOCKET_CONNECT'> {
  payload: {
    url: String;
    userId: String;
    parentId: String;
    browserUid: String;
  };
}

export interface disconnectSocketAction extends Action<'SOCKET_DISCONNECT'> {}

export const connectSocket = (data: any): connectSocketAction =>{ 
  return ({
  type: WebsocketActionTypes.SOCKET_CONNECT,
  payload: {
    url:data.url,
    userId: data.userId,
    parentId: data.parentId,
    browserUid: data.browserUid,
  },
})};


export const socketResponseError = (
  actionType: string,
  error: string,
  data?:any
) => ({
  type: WebsocketActionTypes.SOCKET_RESPONSE_ERROR,
  payload: { actionType, error,data },
});

export const clearError = () => ({
  type: WebsocketActionTypes.CLEAR_SOCKET_RESPONSE_ERROR,
  payload:null
})