import { PlanBillingActionTypes, PlanBillingState } from "./types";

export const INIT_STATE: PlanBillingState = {
    error: "",
    loading: false,
    invoices: [],
    plans:[],
};

const PlanBilling = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case PlanBillingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PlanBillingActionTypes.GET_CUSTOMER_PORTAL:
                    const sessionPortal = action.payload.data
                    return { 
                        ...state, 
                        loading: false,
                        sessionPortal   
                    };
                case PlanBillingActionTypes.GET_INVOICES:
                    return { 
                        ...state, 
                        loading: false,
                        invoices:action.payload.data.invoices
                    };
                case PlanBillingActionTypes.INVOICE_DOWNLOAD:
                    return { 
                        ...state, 
                        loading: false,
                        downloadInvoice:action.payload.data.data
                    };
                case PlanBillingActionTypes.GET_PLANS: 
                    return {
                        ...state,
                        loading: false,
                        plans : action.payload.data.plans
                    }
                case PlanBillingActionTypes.CHANGE_SUBSCRIPTION:
                    return {
                        ...state,
                        loading: false,
                        changeSubscription: action.payload.data
                    }
                case PlanBillingActionTypes.CHARGEBEE_PROCESS:
                    return {
                        ...state,
                        loading: true,
                        chargebeeProccess:action.payload.data                
                    }
                case PlanBillingActionTypes.ADD_CREDIT:
                    return {
                        ...state,
                        loading: false,
                    }
                default:
                    return { ...state };
            };
        case PlanBillingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PlanBillingActionTypes.GET_CUSTOMER_PORTAL:

                    return { 
                        ...state, 
                        loading: false
                    };
                case PlanBillingActionTypes.GET_INVOICES:
                    return { 
                        ...state, 
                        loading: false,
                        invoices:[]
                    };
                case PlanBillingActionTypes.INVOICE_DOWNLOAD:
                    return { 
                        ...state, 
                        loading: false,
                        downloadInvoice:{}
                    };
                case PlanBillingActionTypes.GET_PLANS: 
                    return {
                        ...state,
                        loading: false,
                        plans : []
                    }
                case PlanBillingActionTypes.CHANGE_SUBSCRIPTION:
                    return {
                        ...state,
                        loading: false,
                        changeSubscription: {}
                    }
                case PlanBillingActionTypes.CHARGEBEE_PROCESS:
                    return {
                        ...state,
                        loading: true,
                        chargebeeProccess:{}                
                    }
                case PlanBillingActionTypes.ADD_CREDIT:
                    return {
                        ...state,
                        loading: false,            
                    }
                default:
                    return { ...state };
            }
        case PlanBillingActionTypes.GET_CUSTOMER_PORTAL:
            return { 
                ...state, 
                loading: true
            };
        case PlanBillingActionTypes.CLEAR_CUSTOMER_PORTAL:
            return { 
                ...state, 
                loading: false,
                sessionPortal: {}
            };
        case PlanBillingActionTypes.GET_INVOICES:
            return { 
                ...state, 
                loading: true,
                invoices:[]
            };
        case PlanBillingActionTypes.INVOICE_DOWNLOAD:
            return { 
                ...state, 
                loading: true,
                downloadInvoice:{}
            };
        case PlanBillingActionTypes.GET_PLANS: 
            return {
                ...state,
                loading: true,
                plans : []
            }
        case PlanBillingActionTypes.CHANGE_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
                changeSubscription: {}
            }
        case PlanBillingActionTypes.CHARGEBEE_PROCESS:
            return {
                ...state,
                loading: true,                
            }
        case PlanBillingActionTypes.CLEAR_STATE_DATA:
            return {
                ...state,  
                changeSubscription:{},
                chargebeeProccess:{}
            }
        case PlanBillingActionTypes.ADD_CREDIT:
            return {
                ...state,  
                loading:true
            }
        default:
            return { ...state };
    }
}

export default PlanBilling