export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",
  CLEAR_CHAT_REDUCER_DATA = "@@chats/CLEAR_CHAT_REDUCER_DATA",
  REMOVE_SELECTED_CHAT = "@@chats/REMOVE_SELECTED_CHAT",
  CLEAR_CLIENTID_REDUCER_DATA = "@@chats/CLEAR_CLIENTID_REDUCER_DATA",
  GET_CHANNELS = "@@chats/GET_CHANNELS",

  ADD_CONTACTS = "@@chats/ADD_CONTACTS",
  CREATE_CHANNEL = "@@chats/CREATE_CHANNEL",
  CHANGE_PRIVACY_SETTING = "@@chats/CHANGE_PRIVACY_SETTING",
  MAKE_AS_ADMIN = "@@chats/MAKE_AS_ADMIN",
  DISMISS_AS_ADMIN = "@@chats/DISMISS_AS_ADMIN",
  ADD_PARTICIPANTS = "@@chats/ADD_PARTICIPANTS",
  REMOVE_PARTICIPANTS = "@@chats/REMOVE_PARTICIPANTS",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  SYNCING_STOP_TO_CRM = "@@chats/SYNCING_STOP_TO_CRM",
  GET_CHAT_USER_DETAILS = "@@chats/GET_CHAT_USER_DETAILS",
  GET_LABELS = "@@chats/GET_LABELS",
  UPDATE_CHAT_USER_DETAILS = "@@chats/UPDATE_CHAT_USER_DETAILS",
  CHANGE_ASSIGN_USER = "@@chats/CHANGE_ASSIGN_USER",
  DOWNLOAD_CHAT = "@@chats/DOWNLOAD_CHAT",
  RESOLVE_CHAT = "@@chats/RESOLVE_CHAT",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",

  // MESSAGE
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  GET_CHANNEL_DETAILS = "@@chats/GET_CHANNEL_DETAILS",
  TOGGLE_FAVOURITE_CONTACT = "@@chats/TOGGLE_FAVOURITE_CONTACT",
  GET_ARCHIVE_CONTACT = "@@chats/GET_ARCHIVE_CONTACT",
  TOGGLE_ARCHIVE_CONTACT = "@@chats/TOGGLE_ARCHIVE_CONTACT",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",
  REPLY_IMAGE = "@@chats/REPLY_IMAGE",
  NEW_MESSAGES_RECEIVED = "@@chats/NEW_MESSAGES_RECEIVED",
  OLD_MESSAGE_SYNC_COMPLETE = "@@chats/OLD_MESSAGE_SYNC_COMPLETE",
  MESSAGE_SEND_COMPLETE = "@@chats/MESSAGE_SEND_COMPLETE",
  OLD_MESSAGE_SYNC_START = "@@chats/OLD_MESSAGE_SYNC_START",
  UPDATE_NUMBER_ALLOCATION = "@@chats/UPDATE_NUMBER_ALLOCATION",
  SET_CLIENT_ID = "@@chats/SET_CLIENT_ID",
  UPDATE_CONVERSATION = "@@chats/UPDATE_CONVERSATION",
  UPDATE_CLIENT_SESSION = "@@chats/UPDATE_CLIENT_SESSION",
  SYNC_CHAT_USER = "@@chats/SYNC_CHAT_USER",
  SYNC_CHAT_START = "@@chats/SYNC_CHAT_START",
  UPDATE_CLIENT_CONNECTION = "@@chats/UPDATE_CLIENT_CONNECTION",
  CREATE_USER = "@@chats/CREATE_USER",
  CREATE_TELEGRAM_USER = "@@chats/CREATE_TELEGRAM_USER",
  SEND_SEEN_API = "@@chats/SEND_SEEN_API",
  GET_CLIENTS_LIST = "@@chats/GET_CLIENTS_LIST",
  GET_CHANNEL_LIST = "@@chats/GET_CHANNEL_LIST",
  GET_TEMP_LIST = "@@chats/GET_TEMP_LIST",
  GET_REPORT_LIST = "@@chats/GET_REPORT_LIST",
  GET_CAMPAIGN_LIST = "@@chats/GET_CAMPAIGN_LIST",
  GET_ALLOCATION_LIST = "@@chats/GET_ALLOCATION_LIST",
  EDIT_WHATSAPP_NUMBER = "@@chats/EDIT_WHATSAPP_NUMBER",
  CLIENT_DISCONNECTED = "@@chats/CLIENT_DISCONNECTED",
  MESSAGE_UNREAD = "@@chats/MESSAGE_UNREAD",
  GET_DOWNLOAD_DATA = "@@chats/GET_DOWNLOAD_DATA",
  CLEAR_DOWNLOAD_DATA = "@@chats/CLEAR_DOWNLOAD_DATA",
  EDIT_CONATCT_NAME = "@@chats/EDIT_CONATCT_NAME",
  ON_SEND_TELEGRAM_MESSAGE = "@@chats/ON_SEND_TELEGRAM_MESSAGE",
  ON_SEND_CHATBOT_MESSAGE = "@@chats/ON_SEND_CHATBOT_MESSAGE",
  ON_SEND_MESSENGER_MESSAGE = "@@chats/ON_SEND_MESSENGER_MESSAGE",
  WARMUP_CONFIRMATION_MODAL = "@@chats/WARMUP_CONFIRMATION_MODAL",
  WARMUP_CONFIRMATION = "@@chats/WARMUP_CONFIRMATION",
  UPDATE_CHAT_USER_LIST = "@@chats/UPDATE_CHAT_USER_LIST",
  FORWARD_MESSAGE_DONE = "@@chats/FORWARD_MESSAGE_DONE",
  SYNC_CIPHERTEXT_MESSAGE = "@@chats/SYNC_CIPHERTEXT_MESSAGE"
}
export interface ChatsState {
  favourites: Array<any>;
  directMessages: Array<any>;
  channels: Array<any>;
  selectedChat: string | number | null;
  chatUserDetails: object|any;
  chatUserConversations: [];
  assignUserList: [],
  isOpenUserDetails: boolean;
  channelDetails: object;
  archiveContacts: Array<any>;
  clientId:string | number ;
  clientDisconnected: Boolean;
  isChatUsersSyncing: Boolean;
  skipChatUserList: any;
  createdUser: any;
  getConversationsCreatedAt:boolean;
  clientList:[];
  loading: Boolean;
  getNextConversation: Boolean;
  unReadBadge: any;
  downloadFileData: {};
  syncingChatStopToCrm:boolean;
  getUserConversationsLoading:boolean,
  editWhatsappNumberSuccess:boolean,
  editConatctNameSuccess:boolean,
  labels:[],
  selectedFilter: string,
  selectedLabelValue: string|boolean|undefined, 
  eventType : string | null,
  oldMessageSyncing: boolean,
  warmupConfirmationModal: boolean,
  channelList: any,
  reportList: any,
  campaignLists: any,
  allocationList: any,
  mediaSendComplete: any,
  tempClientlist: any
  searchText: any,
  draftSelectedChatId: any
}
