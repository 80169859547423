const TimeZone = [
    "Africa/Abidjan",
    "Africa/Cairo",
    "Africa/Casablanca",
    "America/Argentina/Buenos_Aires",
    "America/Bogota",
    "America/Jamaica",
    "America/Los_Angeles",
    "America/Mexico_City",
    "America/New_York",
    "America/Vancouver",
    "Arctic/Longyearbyen",
    "Asia/Calcutta",
    "Asia/Colombo",
    "Asia/Dhaka",
    "Asia/Hong_Kong",
    "Asia/Istanbul",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kolkata",
    "Asia/Kuwait",
    "Asia/Manila",
    "Asia/Nicosia",
    "Asia/Phnom_Penh",
    "Asia/Seoul",
    "Asia/Singapore",
    "Asia/Tashkent",
    "Asia/Tokyo",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Canberra",
    "Australia/Perth",
    "Australia/Sydney",
    "EET",
    "Europe/Amsterdam",
    "Europe/Kaliningrad",
    "Europe/London",
    "Europe/Paris",
    "Pacific/Apia",
    "Pacific/Midway",
    "UTC"
]
const Days = [
    {
        value: 'sunday',
        label: 'Sunday ',
        key: 0
    },
    {
        value: 'monday',
        label: 'Monday',
        key: 1
    },
    {
        value: 'tuesday',
        label: 'Tuesday',
        key: 2
    },
    {
        value: 'wednesday',
        label: 'Wednesday',
        key: 3
    },
    {
        value: 'thursday',
        label: 'Thursday',
        key: 4
    },
    {
        value: 'friday',
        label: 'Friday ',
        key: 5
    },
    {
        value: 'saturday',
        label: 'Saturday ',
        key: 6
    }
]
export {
    TimeZone,Days
};