import { SubUserSetPwdActionTypes } from "./types";

// common success
export const SubUserSetPwdApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: SubUserSetPwdActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const SubUserSetPwdApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: SubUserSetPwdActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setSubUserPwd = (data: any) => {
  return {
    type: SubUserSetPwdActionTypes.SET_SUBUSER_PWD,
    payload: data,
  };
};
