import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form } from "reactstrap";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
// import cookie from "react-cookies";
import { isValidPhoneNumber } from "libphonenumber-js";


import { useRedux } from "../../hooks/index";
import { useParams,Navigate,useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { updateSubUserPwd,setSubUserPwd } from "../../redux/actions";

import { useProfile } from "../../hooks";
import axios from "axios";

import config from "../../config";


// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import { addMixpanelEvent } from "../../helpers/mixpanel";

// images
//import avatar1 from "../../assets/images/users/avatar-1.jpg";
let now = new Date();

interface AuthData {
  email: string;
  authToken: string;
}

interface ConfirmAccountProps { }
const SubUserSetPassword : React.FC<ConfirmAccountProps> = (props) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [loading, setLoading] = useState(false);
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  const userCountry = "in";
  const navigate = useNavigate();
  const {userProfile} = useProfile();
  const storedData = localStorage.getItem("authData");
const authData: AuthData | null = storedData ? JSON.parse(storedData) : null;
const authToken1 = authData?.authToken ?? '';
// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Authorization'] = authToken1;
  const {authToken, subUserDetails, userSuccess,userData} = useAppSelector(
    (state) => {      
      return {
        userData: state?.UpdateSubUserPwd?.authData,
        authToken: state?.UpdateSubUserPwd?.authData?.authToken,
        userSuccess: state?.UpdateSubUserPwd?.userSuccess,
        subUserDetails: state?.SetSubUserPwd?.subUserDetails
      };
    }
  );

  useEffect(() => {
   if(userSuccess){
    localStorage.setItem("authData",JSON.stringify(userData))
    navigate("/welcome")
   }
  }, [userSuccess])
  
  const resolver = yupResolver(
    yup.object().shape({
      password: yup.string().required("Please Enter Password."),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;
  const { hash, i, c } = useParams();
  useEffect(() => {
    setLoading(true)
    let data = {
      hash: hash,
      i: i,
      c: c,
      userLoginTime: now,
    };
    dispatch(setSubUserPwd(data));
    setLoading(false)
  }, [dispatch, hash, i, c]);
  
  useEffect(() => {
   if(authToken){
    setLoading(false)
    navigate("/setting/user-list",{
      state: {
        authToken
      }
    });
   }
  }, [authToken]);

  const onSubmitForm = (values: any) => { 
  let data = {
    fullName:values.fullName,
    password:values.password,
    hash: hash,
    i: i,
    c: c,
    userLoginTime: now
    };
  addMixpanelEvent("Subuser Registration", { distinct_id: subUserDetails.userId, "$email": subUserDetails.email, "$name" : data.fullName,"$ParentEmail": subUserDetails.parentEmail})
  dispatch(updateSubUserPwd(data));
};

  return (
  
    <NonAuthLayoutWrapper>
        
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-8">
          <div className="py-md-5 py-4">
            <AuthHeader title="Welcome ! Start Your Journey Here " />
           
            {/* {changepasswordError && changepasswordError ? (
              <Alert color="danger">{changepasswordError}</Alert>
            ) : null}
            {passwordChanged ? (
              <Alert color="success">Your Password is changed</Alert>
            ) : null} */}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {/* {changePassLoading && <Loader />} */}

              <div className="mb-3">
                <FormInput
                  label="FullName"
                  type="text"
                  name="fullName"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter UserName"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={false}
                />
              </div>

             
              <div className="mb-3">
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Set Password"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={false}
                />
              </div>
              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-primary w-100" type="submit">
                      Save
                    </button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-light w-100" type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    
    </NonAuthLayoutWrapper>
  );
};

export default SubUserSetPassword;


