import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const getUserList = () => {
  return api.get(url.GET_USER_LIST_URL);
};
const getAllocatedUserList = (clientId:any) => {
  return api.get(url.GET_ALLOCATED_USER_LIST_URL+"/"+clientId);
};
const getUserDetails = (id:string) => {
  return api.get(url.GET_USER_DETAILS_URL+"/"+id);
};

const userAdd = (data:any) => {
  return api.create(url.USER_ADD, data);
};
const updateUserDetails = (id:string,data:any) => {
  return api.update(url.USER_UPDATE+"/"+id, data);
};

const userDelete = (userId:any) => {
  return api.update(url.DELETE_USER+userId);
};

export {
    getUserList,
    userAdd,
    getUserDetails,
    updateUserDetails,
    userDelete,
    getAllocatedUserList
};
