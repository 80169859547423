import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();


// postForgetPwd
const postFakeForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// postForgetPwd
const postJwtForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

const postConfirmData = (data: any) => {
  return (api.create(url.POST_CONFIRM_DATA+"/"+data.hash, data))
}

const postUpdatePassword = (data: any) => {
  return (api.create(url.POST_UPDATE_PASSWORD+"/"+data.hash, data))
}
const postUpdateSubUserPassword = (data: any) => {
  return (api.create(url.POST_UPDATE_SUBUSER_PASSWORD+"/"+data.hash, data))
}

const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

const postJwtLogin = (data: any) => api.create(url.POST_JWT_LOGIN, data);
const postJwtLoginWithGoogle = (data: any) => {
  return (
    api.create(url.POST_JWT_LOGIN_WITH_GOOGLE, data?.payload)
  )
}  
const postFcmToken = (data: any) => api.create(url.POST_FCM_TOEKN, data);

// Register Method
const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data);
};

// Register Method
const postJwtRegister = (data: any) => {
  return api.create(url.JWT_REGISTER, data);
};

const postUpdateprofileData = (data: any,userId:string) => {
  return api.create(url.UPDATE_PROFILE + "/" + userId + "/setting/edit", data);
};
const changePassword = (data: any) => {
  return api.create(url.POST_CHNAGE_PASSWORD, data);
};

const setSubUserPwd = (data: any) => {
  return api.create(url.USER_CHANGE_PASSWORD+"/"+data.hash, data);
};
const postChangeEmail = (data: any) => {
  return api.create(url.USER_CHANGE_EMAIL+"/"+data.hash, data);
};

// postSocialLogin
const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

// Logout
const logout = () => api.create(url.LOGOUT)


export {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postConfirmData,
  postUpdatePassword,
  postFakeLogin,
  postJwtLogin,
  postJwtLoginWithGoogle,
  postFakeRegister,
  postJwtRegister,
  postUpdateprofileData,
  changePassword,
  postSocialLogin,
  setSubUserPwd,
  postUpdateSubUserPassword,
  postChangeEmail,
  postFcmToken,
  logout,
};
