import React, { useEffect, useState } from "react";
import { Card, Button, Radio, Input, Form, Checkbox } from "antd";
import { redirect, useNavigate } from "react-router-dom";
import { AccountCancleWrapper } from "./AccountCancleWrapper.style";
import ControllhippoLogoSM from '../../assets/images/controllhippo_samll_logo.svg';
import { showSuccessNotification } from "../../helpers/notifications";
import { useSelector, useDispatch } from "react-redux";
import { cancelAccount, getPlans, logoutUser, systemLogoutUser } from '../../redux/actions';
import { useProfile, useRedux } from "../../hooks";

const { TextArea } = Input;
const discountImg = 'https://images.controlhippo.com/svg/discount_img.svg';

const AccountCancle = () => {
  const navigate = useNavigate();
  const { userProfile } = useProfile();
  const { dispatch, useAppSelector } = useRedux();
  const [currentCard, setCurrentCard] = useState(1);
  const [activeCard, setActiveCard] = useState(1);
  const [inputText, setInputText] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("Financial Constraints");
  const [selectedMessageReasonState, setSelectedMessageReasonState] = useState("Please share your product Financial Constraints issue .");
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const {
    handleSubmitButtonSuccess
  } = useAppSelector((state) => {
    return {
      handleSubmitButtonSuccess: state.PlanBilling.handleSubmitButtonSuccess,
      editWhatsappNumberSuccess: state.Chats.editWhatsappNumberSuccess,
      editChatUserDetails: state.Chats.editChatUserDetails
    };
  });

  const cancelOptions = [
    { value: 1, title: "Financial Constraints", reasonmessage:"Please share your product financial constraints issue.", description: "Due to budget considerations, you have opted to discontinue your subscription." },
    { value: 2, title: "Product Dissatisfaction", reasonmessage:"Please share your product dissatifaction Issue.", description: "You have encountered issues with the product's performance or functionality, prompting their decision to cancel." },
    { value: 3, title: "Feature Limitation", reasonmessage:"Please share your product feature limitation issue.", description: "The absence of specific features or functionalities in ControlHippo led you to cancel the subscription." },
    { value: 4, title: "Unnecessary for Business Needs", reasonmessage:"Please share your product needs for business.", description: "You have determined that ControlHippo no longer aligns with your business requirements, prompting the cancellation." },
    { value: 5, title: "Other", reasonmessage:"Please share your product other issue.", description: "If none of the reasons listed fit, please share your specific concern for canceling. Your feedback helps us improve." }
  ];

  const renderCancelReasons = () => (
    <Radio.Group className="cancleReasonsDiv d-flex align-items-center justify-content-center flex-wrap gap-4 m-auto" onChange={onChange} value={activeCard}>
      {cancelOptions.map(option => (
        <Radio key={option.value} value={option.value}>
          <Card className="cancleReasonsCard d-flex flex-wrap">
            <h6 className="text-dark font-size-18">{option.title}</h6>
            <p className="mb-2 font-size-14 fw-normal optionsDesc">{option.description}</p>
          </Card>
        </Radio>
      ))}
    </Radio.Group>
  );


  const handleNextButton = () => {
    if (activeCard == 1) {
      setCurrentCard(2);
    } else {
      setCurrentCard(3);
    }
  }
  const handleProcCancle = () => {
    if (activeCard == 1) {
      setCurrentCard(currentCard + 2);
    } else {
      setCurrentCard(currentCard + 1)
    }
  }

  const onChange = (e: any) => {
    const selectedOption = cancelOptions.find(option => option.value === e.target.value);
    const selectedMessageReason = cancelOptions.find(option => option.value === e.target.value);
    if (selectedOption) {
      setSelectedTitle(selectedOption.title)
    }
    if(selectedMessageReason){
      setSelectedMessageReasonState(selectedMessageReason.reasonmessage)
    }
    setActiveCard(e.target.value);
  }
  
  const handleBackButton = () => {
    console.log("ACTIVE-CARD",activeCard, "CURRENT-CARD",currentCard)
    setCurrentCard(currentCard - 1);
    if(activeCard == 1 && currentCard == 4){
      setCurrentCard(2);
    }
    if (currentCard === 1) {
      navigate(-1);
    }
    if (activeCard !== 1 && currentCard === 3) {
      setCurrentCard(1);
    }
  };

  const handleSubmitButton = (event: any) => {
    const payLoadData = {
      event: event,
      title: selectedTitle,
      inputText: inputText,
      checkBoxValue: checkBoxValue
    }

    if (event == "handleClaim") {
      payLoadData.title = `${selectedTitle} / Claim for discount`;
    }
    dispatch(cancelAccount(payLoadData, async (response: any) => {
      if (response?.success) {
        if (response.event == "handleCancelAccount") {
          navigate("/dashboard");
        }
        if (response.event === "handleClaim") {
          showSuccessNotification(response.message);
          navigate("/");
        }
        if (response.event === "handleSchedule") {
          window.open("https://calendly.com/d/cp47-6pp-86q", "_blank");
        }
      }
    }));
  }

  const handleTextChange = (e: any) => {
    setInputText(e.target.value)
  }

  const onCheckBoxChange = (e: any) => {
    setCheckBoxValue(e.target.checked)
  }
  const renderCardContent = () => {
    switch (currentCard) {
      case 1:
        return renderCancelReasons();
      case 2:
        return (
          <div className="text-center">
            <p className="font-size-16 cardTopdesc">We understand your concerns and value your feedback. Before proceeding with the cancellation, could you please give us the opportunity to address your issues? We're committed to improving your experience and would like to extend a special offer for your consideration.</p>
            <Card className="exclusiveOfferCard m-auto text-center p-3">
              <img src={discountImg} alt="Discount Image - ControlHippo" width={126} height={94} />
              <h5 className="text-dark mt-3 font-size-24 fw-semibold">20% Discount on your subscription</h5>
              <p className="mb-2 text-break font-size-15 fw-normal">As a token of our appreciation, ControlHippo is thrilled to offer you an exclusive 20% discount on your subscription amount. Claim it now and experience the benefits of our platform with even greater savings!</p>
              <Button onClick={() => { handleSubmitButton("handleClaim") }} color="primary" className="btn btn-primary d-flex align-items-center justify-content-center m-auto mt-3 mb-2">Claim Now!</Button>
              <p className="mb-0">*Terms & Condition Apply</p>
            </Card>
          </div>
        );
      case 3:
        return (
          <div className="text-center">
            <p className="cardTopdesc font-size-16">We're disappointed to see your account cancellation. Would you be open to a discussion with our representative to potentially address any concerns before making a final decision?</p>
            <Button onClick={() => { handleSubmitButton("handleSchedule") }} color="primary" className="btn btn-primary d-flex align-items-center justify-content-center m-auto my-3">Schedule call</Button>
          </div>
        );
      case 4:
        return (
          <div className="cancleLastCard mx-auto">
            <div className="text-center">
              <img src={ControllhippoLogoSM} alt="Logo - ControlHippo" width={70} height={70} />
              <p className="mt-3 font-size-16 cardTopdesc">Please note that upon cancellation, your account and all associated data will be permanently removed and cannot be restored.</p>
            </div>
            <Form layout="vertical" className="cancleAcForm">
              <Form.Item className="mb-2" label={selectedMessageReasonState}>
                <TextArea onChange={(e) => { handleTextChange(e) }} rows={6} />
              </Form.Item>
              <Form.Item label="" name="disabled" valuePropName="checked">
                <Checkbox onChange={(e) => onCheckBoxChange(e)} className="agreeCheckBox fw-noraml">I agree and readed the above statements.</Checkbox>
              </Form.Item>
            </Form>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="w-100">
      <AccountCancleWrapper className="pb-4">
        <Card
          className="mb-0 AccountCancleMainCard"
          title={
            <div className="d-flex align-items-center gap-2">
              <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={() => { handleBackButton() }}>
                <i className="bx bx-chevron-left font-size-24"></i>
              </Button>
              <h2 className="font-size-20 text-dark fw-fw-semibold lh-sm mb-0">Cancellation Request</h2>
            </div>
          }
        >
          <div className="AccountCancleCardContainer mx-auto pb-3">
            <div className="text-center font-size-18 text-dark mb-4">
              <h5 className="font-size-18 text-dark">
                {currentCard === 1 ? "We're sorry to see you go! Could you please share your reason for cancellation?"
                  : currentCard === 2 ? 'Consider Our Assistance: Exclusive Offer Inside'
                    : 'Give Us a Chance: Discussing Your Cancellation'}</h5>
            </div>
            {renderCardContent()}
          </div>
          <div className={`cancleAcActions d-flex align-items-center gap-2 mt-3 ${currentCard !== 4 ? "justify-content-end" : "justify-content-center"}`}>
            <Button color="secondary" onClick={() => navigate(-1)}>Cancel</Button>
            {currentCard !== 4 ?
              currentCard === 1 ?
                <Button color="secondary" className="btn-outline-secondary d-flex align-items-center justify-content-center" onClick={handleNextButton}>Next</Button>
                :
                <Button color="primary" className="d-flex align-items-center justify-content-center btn btn-outline-primary" onClick={handleProcCancle}>Proceed Cancellation</Button>
              :
                <Button disabled={inputText.length <= 0 || !checkBoxValue} onClick={() => { handleSubmitButton("handleCancelAccount") }} color="primary" className={`d-flex align-items-center justify-content-center btn btn-outline-secondary cancleButtonActive`}>Cancel Account</Button>
            }
          </div>
        </Card>
      </AccountCancleWrapper>
    </div>
  );
};

export default AccountCancle;
