import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getProfileDetails = (payLoad:any) => {
  return api.get(url.GET_PROFILE_DETAILS+"/"+payLoad.userId);
};

const getSettings = () => {
  return api.get(url.GET_USER_SETTINGS);
};
const updateSettings = (field: string, value: any, payloadData : any) => {
  return api.update(url.UPDATE_SETTINGS, {
    field: field,
    value: value,
    payloadData: payloadData
  });
};

export { getProfileDetails, getSettings, updateSettings };
