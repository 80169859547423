import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation,Link } from 'react-router-dom';
import { NonAuthLayoutComponent } from "./NonAuthLayoutComponent.style";

// images
import authImage from "../assets/images/auth-img-new.svg";
import ContrrollHippoWhite from "../assets/images/controllhippo_samll_logo_white_bg.svg";
import ControlHippoBlueLogo from "../assets/images/auth-images/ControlHippo_blue_logo.svg";
import LeenaParikhImage from "../assets/images/auth-images/leen_parikh_img2.png";

import WhiteHatJrLogo from "../assets/images/auth-images/whitehat.svg";
import CallHippoLogo from "../assets/images/auth-images/callhippo.svg";
import DialerHqLogo from "../assets/images/auth-images/dialerHQ.svg";
import ShopifyLogo from "../assets/images/auth-images/shopify.svg";

interface NonAuthLayoutWrapperProps {
  children: any;
}

const NonAuthLayoutWrapper = (props: NonAuthLayoutWrapperProps) => {
  const location = useLocation();
  const isAuthLoginPage = location.pathname === '/auth-login';
  const isAuthRegisterPage = location.pathname === '/auth-register';
  return (
    <NonAuthLayoutComponent>
      <div className="auth-bg"> 
        <Container fluid className="p-0 containerlogin-main-wr">
          {/*<Row className=" g-0">
            <Col xl={3} lg={4}>
              <div className="p-4 pb-0 p-lg-5 pb-lg-0 auth-logo-section">
                <div className="text-white-50">
                  <h3>
                    <Link to="/" className="text-white d-flex align-items-center">
                      <img src={ContrrollHippoWhite} className="me-2" alt="Controllhippo Logo" height={30} width={30}/>
                      ControlHippo
                    </Link>
                  </h3>
                  <p className="font-size-16">
                  WhatsApp management solution for multiple numbers
                  </p>
                </div>
                <div className="mt-auto">
                  <img src={authImage} alt="auth" className="auth-img" height="auto" width={1320} />
                </div>
              </div>
            </Col>

            <Col xl={9} lg={8}>
              <div className="authentication-page-content">
                <div className="d-flex flex-column h-100 px-4 pt-4">
                  {props.children}

                  <Row className="">
                    <Col xl={12}>
                      <div className="text-center text-muted p-4">
                        <p className="mb-0">
                          &copy; {new Date().getFullYear()} Controlhippo. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          CallHippo
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
  </Row>*/}
        <Row  className="authWrpr m-0 pt-5">
          <Col lg={6} className="authLeftBlock">
            <div className="authLeftBlockBody text-center">
              <img className="mb-2" height="294" width="292" src={LeenaParikhImage} alt="Leena Parikh - Controlhippo" />
              <p className="mb-2 font-size-17 authAuthorDesc">We got amazing help from the ControlHippo team. They're really quick to respond, know a lot, and do everything they can to help with our questions. They really care about making customers happy, and that's what makes them special.</p>
              <div className="authAuthorName font-size-14">
                <strong className="font-size-16 me-1 authAuthorBold">Boban Jagodic</strong> Sales Assistant, Technobell Technology.
              </div>
              {/* <div className="authAuthorName font-size-14">
                <strong className="font-size-16 me-1 authAuthorBold"><a href="https://www.linkedin.com/in/leena-parikh-29b161171/overlay/about-this-profile/?_l=en_US" target="_blank">Leena Parikh</a></strong> Director of Entigrity
              </div> */}
              <div className="authLeftTrustedWrpr">
                <h5 className="authLeftTrustedTitle font-size-16">Trusted By 5000+ Campanies</h5>
                <div className="d-flex align-items-center justify-content-evenly mt-1">
                    <img src={DialerHqLogo}  height={21} width={110} alt="DialerHq - ControlHippo" />
                    <img src={CallHippoLogo}  height={18} width={98} alt="CallHippo - ControlHippo" />
                    <img src={ShopifyLogo}  height={26} width={94} alt="Shopify - ControlHippo" />
                    <img src={WhiteHatJrLogo}  height={22} width={84} alt="White Hat Jr - ControlHippo" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12} className="authRightBlock p-0 pt-3 pt-md-0">
            <div className="authentication-page-content m-0">
              <div className={`signupLoginHeader m-0 row pt-lg-3 mb-4`}>
                <div className="signupLoginInnrHeader col-md-12 p-0 align-items-center d-flex justify-content-between">
                  <img src={ControlHippoBlueLogo} alt="ControlHippo" />
                  {isAuthRegisterPage &&
                    <p className="mb-0">
                    Already have an account ?{" "}
                      <Link
                        to="/auth-login"
                        className="fw-medium text-decoration-underline"
                      >
                        {" "}
                        Login
                      </Link>
                    </p>
                  }
                  {isAuthLoginPage &&
                     <p className="mb-0">
                     Don't have an account ?{" "}
                     <a className="fw-medium text-decoration-underline" href={process.env.REACT_APP_REGISTER_LINK}>Register</a>
                   </p>
                  }
                </div>  
              </div>
              {props.children}
              <Row> 
                <Col xl={12}>
                  <div className={`loginSignFootBottom text-center text-muted ${isAuthRegisterPage?'p-4' : 'p-2'} `}> 
                    <p className="mb-0">
                      &copy; {new Date().getFullYear()} Controlhippo. Crafted with  {" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      CallHippo
                    </p>
                  </div>
                </Col>
              </Row>
              </div>
            </Col>
        </Row>
        </Container>
      </div>
    </NonAuthLayoutComponent>
  );
};

export default NonAuthLayoutWrapper;
