import React, { useEffect, useState } from "react";
import { Switch } from "antd";

// interface
import { PrivacyTypes } from "../../../data/settings";

// constants
import { DISPLAY_TYPES } from "../../../constants/index";

// components
import DisplaySelect from "./DisplaySelect";
import { checkPlanAndFeature } from "../../../helpers/common.service";
import { useProfile } from "../../../hooks";
interface PrivacyProps {
  privacy: PrivacyTypes;
  onChangeSettings: (field: string, value: any) => void;
  qrCodePage?: boolean;
}
const Privacy = ({ privacy, onChangeSettings, qrCodePage }: PrivacyProps) => {
  const [data, setData] = useState<PrivacyTypes>({
    isDisablePersonalChatSync:false,
    // displayprofilePhoto: "selected",
    // displayLastSeen: true,
    // displayStatus: DISPLAY_TYPES.EVERYONE,
    // readReceipts: true,
    // displayGroups: DISPLAY_TYPES.EVERYONE,
  });
  const { userProfile } = useProfile();

  useEffect(() => {
    if (privacy) {
      setData(privacy);
    }
  }, [privacy]);

  const onChangeData = (
    field:
      | "isDisablePersonalChatSync"
      | "displayprofilePhoto"
      | "displayLastSeen"
      | "displayStatus"
      | "readReceipts"
      | "displayGroups",
    value: string | boolean
  ) => {
    let modifiedData: any = { ...data };
    modifiedData[field] = value;
    setData(modifiedData);
    onChangeSettings("privacy", modifiedData);
  };

  return (
    <div className="accordion-body">
      <div className="list-group list-group-flush">
        {/* <li className="list-group-item py-3 px-0 pt-0">
          <DisplaySelect
            value={data.displayprofilePhoto}
            label="Profile photo"
            onChange={(value: string) => {
              onChangeData("displayprofilePhoto", value);
            }}
          />
        </li> */}
        {/* <li className="list-group-item py-3 px-0">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="font-size-13 mb-0 text-truncate">Last seen</h5>
            </div>
            <div className="flex-shrink-0 ms-2">
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="privacy-lastseenSwitch"
                  checked={data.displayLastSeen === true}
                  onChange={(e: any) => {
                    onChangeData("displayLastSeen", e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="privacy-lastseenSwitch"
                ></label>
              </div>
            </div>
          </div>
        </li> */}
        <div className="list-group-item">
          <div className="d-flex align-items-center justify-content-between pb-3 mobileview_div_main">
            <div className="d-flex align-items-start gap-2">
              <div className="lockCheckCir d-flex align-items-center justify-content-center">
                <i className="lockCheckIcon"></i>
              </div>
              <div className="text-start">
                <h5 className="font-size-18 text-dark mb-0 text-truncate">{!qrCodePage ? "Personal chats pause" :"ControlHippo Privacy Mode"}</h5>
                <p className="font-size-12 mb-0">
                  Your privacy is our utmost priority; we safeguard your information with utmost care and respect.
                  {/* <a href="https://support.controlhippo.com/support/solutions/articles/1060000057643-what-does-the-privacy-feature-do-in-controlhippo-" target="_blank" rel="noopener noreferrer" className="font-size-12 ms-1 text-primary text-decoration-underline">Know more</a> */}
                </p>
              </div>
            </div>
            <div className="flex-shrink-0 toggle_btn_main ms-2">
              {/* <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="privacy-lastseenSwitch"
                  defaultChecked={false}
                  checked={data.isDisablePersonalChatSync === true}
                  onChange={(e: any) => {
                    if(checkPlanAndFeature({featureName:"privacy",planDetails: userProfile?.planDetails,featureDisplayName: "Privacy"}))
                    onChangeData("isDisablePersonalChatSync", e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="privacy-lastseenSwitch"
                ></label>
              </div> */}
              <Switch
                  className="ch_toggle_switch" 
                  defaultChecked={false}
                  checked={data.isDisablePersonalChatSync === true}
                  onChange={(checked: boolean) => {
                    if(checkPlanAndFeature({featureName:"privacy",planDetails: userProfile?.planDetails,featureDisplayName: "Privacy"}))
                    onChangeData("isDisablePersonalChatSync", checked);
                  }}
              />
            </div>
          </div>
          <input type="checkbox" id="toggle" className="hidden-checkbox"/>
          <label htmlFor="toggle" className="toggle-button drop_arrow_main"><span className="dropdown_arrow"></span></label>
          <div className="content">
            <h5 className="font-size-16">What Does the Privacy Feature Do in ControlHippo?</h5>
            <p className="font-size-13 mb-2">Activating Privacy in ControlHippo ensures your personal chats stay private and disables syncing with CRMs for added safety. When activated, only CRM contacts are displayed, and messages sync only with these contacts.</p>
            <p className="font-size-13 fw-bold text-muted mb-2">[Note: If the privacy option is enabled without having an integration then Controlhippo will not sync any contact from your WhatsApp.]</p>
            <ul className="ps-3 d-flex flex-column gap-1">
              <li className="font-size-13">New contacts created in ControlHippo will be synced with CRM platforms.</li>
              {/* <li>When using the ControlHippo extension to send a message, the contact information will automatically synchronize between CRM and ControlHippo platforms.</li> */}
              <li className="font-size-13">You will find the privacy mode toggle on the “Numbers Page” in ControlHippo for enabling it for individual numbers. </li>
            </ul>
          </div>

        </div>
        {/* <li className="list-group-item py-3 px-0">
          <DisplaySelect
            value={data.displayStatus}
            onChange={(value: string) => {
              onChangeData("displayStatus", value);
            }}
            label="Status"
          />
          displayStatus
        </li> */}
        {/* <li className="list-group-item py-3 px-0">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="font-size-13 mb-0 text-truncate">Read receipts</h5>
            </div>
            <div className="flex-shrink-0 ms-2">
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="privacy-readreceiptSwitch"
                  checked={data.readReceipts === true}
                  onChange={(e: any) => {
                    onChangeData("readReceipts", e.target.checked);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="privacy-readreceiptSwitch"
                ></label>
              </div>
            </div>
          </div>
        </li> */}
        {/* <li className="list-group-item py-3 px-0 pb-0">
          <DisplaySelect
            value={data.displayGroups}
            onChange={(value: string) => {
              onChangeData("displayGroups", value);
            }}
            label="Groups"
          />
        </li> */}
      </div>
    </div>
  );
};

export default Privacy;
