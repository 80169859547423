import { IntegrationsActionTypes } from "./types";

// common success
export const integrationsApiResponseSuccess = (actionType: string, data: any) => ({
    type: IntegrationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const integrationsApiResponseError = (
    actionType: string,
    error: string
) => ({
    type: IntegrationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Save integration
export const saveIntegration = (data:any,callback ?:(value:any)=>void) => {
    return ({
        type: IntegrationsActionTypes.SAVE_INTEGRATIONS,
        payload: data,
        callback
    })
};
// Get Integration
export const getIntegrations = (id:string) => {
    return ({
        type: IntegrationsActionTypes.GET_INTEGRATIONS,
        payload:id
    })
};
export const removeIntegraation = (id:string,data:any) => {
    return ({
        type: IntegrationsActionTypes.REMOVE_INTEGRATIONS,
        payload:{id,data}
    })
};
export const removeSlackChannel = (data:any) => {
    return ({
        type: IntegrationsActionTypes.REMOVE_SLACK_CHANNEL,
        payload:{data}
    })
};
export const addBoardId = (data:any,callback?:(response:any)=>void) => {
    return ({
        type: IntegrationsActionTypes.ADD_BOARD_ID,
        payload:{data},
        callback
    })
};

// Check URL
export const checkUrl = (url:string) => {
    return ({
        type: IntegrationsActionTypes.CHECK_URL,
        payload:{url}
    })
};

export const updateIntegration = (integrationName:string,data:any,userId:string,callback?:(response:any)=>void) => {
    return ({
        type: IntegrationsActionTypes.UPDATE_INTEGRATION,
        payload:{integrationName,data,userId},
        callback
    })
}

export const stateClear = () => {
    return ({
        type: IntegrationsActionTypes.CLEAR_STATE,
    })
}