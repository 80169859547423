export enum UpdatePasswordActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/updatePassword/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/updatePassword/API_RESPONSE_ERROR",
  
    UPDATE_PASSWORD = "@@auth/updatePassword/UPDATE_PASSWORD",
    // CHANGE_PASSWORD = "@@auth/confirmData/CHANGE_PASSWORD",
  }
  export interface AuthConfirmDataState {
    authData: any;
    confirmaccountError: any;
  }
  