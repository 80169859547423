export enum TemplateCreateActionTypes {
    API_RESPONSE_SUCCESS = "@@profile/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@profile/API_RESPONSE_ERROR",
  
    POST_TEMPLATE_CREATE = "@@profile/POST_TEMPLATE_CREATE",
    POST_TEMPLATE_UPDATE = "@@profile/POST_TEMPLATE_UPDATE",
    POST_TEMPLATE_DELETE = "@@profile/POST_TEMPLATE_DELETE",
    GET_TEMPLATE_LIST = "@@profile/GET_TEMPLATE_LIST",
    GET_TEMPLATE_DETAILS = "@@profile/GET_TEMPLATE_DETAILS",

  }
  export interface ProfileState {
    profileDetails: object;
    templateCreateSuccess: boolean;
    getTemplateDetails:any,
    loading:boolean,
  }
  