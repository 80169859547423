import { message } from "antd";
import { showErrorNotification, showSuccessNotification } from "./notifications";
import UpGradePlanPopup, { showUpGradePlanPopup } from "../pages/PlanBilling/UpGradePlanPopup";
import { clearCustomerPortal, getCustomerPortal,changeSubscription } from "../redux/actions";
import config from "../config";
interface CheckPlanAndFeatureTypes {
    featureName: string | any, 
    planDetails: any, 
    featureDisplayName : string | any
}
const checkPlanAndFeature = ({featureName ,planDetails,featureDisplayName}:CheckPlanAndFeatureTypes) => {
    if (featureName && planDetails) {
        if (planDetails?.features.hasOwnProperty(featureName)) {
            const isFeatureEnabled = planDetails?.features[featureName];
            if (isFeatureEnabled) {
                return true
            } else {
                showErrorNotification(`${featureDisplayName} is currently unavailable with your current ${planDetails.displayName} plan. For assistance with upgrading your plan, kindly contact support@controlhippo.com.`)
                return false
            }
        } else {
            console.log(`${featureDisplayName} is currently unavailable with your current ${planDetails.displayName}. For assistance with upgrading your plan, kindly contact support@controlhippo.com.`);
            return false
        }
    }
};


const openChargebeePortal = ({ parentId,section ,dispatch, newPlanDetails, isCancelled}: any) => {
    return new Promise<void | any>((resolve, reject) => {
        try {
            dispatch(getCustomerPortal(parentId,(response:any)=>{
                if(response.success){
                    const sessionPortal = response
                    const sectionAgain = section
                    let sessionPortalData = sessionPortal.sessionPortal
                    const Chargebee = window.Chargebee;
                    const cbInstance = Chargebee.init({
                        site: config.CHARGEBEE_SITE_KEY,
                        isItemsModel: true,
                    })
                    // const cbInstance = Chargebee.getInstance();
                    const cbPortalInstance = cbInstance.createChargebeePortal();
                    // var chargebeePortalInstance = cbInstance.createChargebeePortal();
                    cbInstance.setPortalSession(function () {
                        return new Promise(function (resolve, reject) {
                            var sampleResponse = {
                                "id": sessionPortalData.id,
                                "token": sessionPortalData.token,
                                "access_url": sessionPortalData.access_url,
                                "status": sessionPortalData.status,
                                "created_at": sessionPortalData.created_at,
                                "expires_at": sessionPortalData.expires_at,
                                "object": sessionPortalData.object,
                                "customer_id": sessionPortalData.customer_id
                            };
                            resolve(sampleResponse);
                        });
                    });
                    if (sectionAgain === "updateCreditCard") {
                        // setLoading(false)
                        console.log("section ::: updateCreditCard")
                        cbPortalInstance.open({
                            close: () => {
                                console.log("section ::: updateCreditCard :::: close");
                                cbInstance.logout();
                                cbInstance.closeAll();
                                window.location.reload();
                            },
                            paymentSourceAdd: () => {
                                console.log("section ::: updateCreditCard :::: paymentSourceAdd");
                                cbInstance.logout();
                                cbInstance.closeAll();
                                dispatch(clearCustomerPortal())
                                if(isCancelled){
                                    dispatch(changeSubscription({ eventType: "reactivate", updateObject:newPlanDetails },parentId))
                                }
                                resolve({success:true ,message : "paymentSourceAdd"})
                                // changeTrial()
                                // if(onConfirmation && typeof onConfirmation == "function" )onConfirmation({success:true,message:"Please Add Second Number"})
                                // toggle()
                                // setTimeout(() => {
                                // }, 3000);
                                // window.location.reload();
                            },
                            paymentSourceUpdate: () => {
                                console.log("section ::: updateCreditCard :::: paymentSourceUpdate");
                                cbInstance.logout();
                                cbInstance.closeAll();
                                dispatch(clearCustomerPortal())
                                if(isCancelled){
                                    dispatch(changeSubscription({ eventType: "reactivate", updateObject:newPlanDetails },parentId))
                                }
                                resolve({success:true ,message : "paymentSourceUpdate"})

                                // changeTrial()
                                // setTimeout(() => {
                                //     dispatch(getProfileDetails(userDetails?.userId))
                                // }, 3000);
                                // window.location.reload();
                                // toggle()
                            },
                        }, {
                            sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES
                        })
                        
                    }
                    else if (sectionAgain === "AddbillingAddress") {
                        console.log("section ::: AddbillingAddress")
                        cbPortalInstance.open({
                            close: () => {
                                cbInstance.logout();
                                cbInstance.closeAll();
                                window.location.reload();
                            },
                        }, {
                            sectionType: Chargebee.getPortalSections().ADDRESS
                        })
                    }
                }else {
                    resolve({success:false ,message : "paymentSourceUpdate"})

                }
            }))
        } catch (error) {
            console.error("error inside common.service:chargbeeCheck function:",error);
            resolve(false)
        }
    })
}
export { checkPlanAndFeature ,openChargebeePortal};
