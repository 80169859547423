import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Spinner } from 'reactstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { useProfile, useContacts, useRedux } from "../../hooks";
import { InfoCircleOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
type DynamicButtonProps = {
    name: string;
    removeIntegration: (name: string) => void;
};

const DynamicButton = ({ name,removeIntegration }: DynamicButtonProps) => {
    const [btnName, setBtnName] = useState("Integrated");
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch, useAppSelector } = useRedux();
    const toggle = () => setModal(!modal);
    const {
        loading,
        removeIntegraation,
    } = useAppSelector(state => ({
        loading: state.Integrations.loading,
        removeIntegraation: state.Integrations.removeIntegraation,
    }));

    const closeBtn = (
        <Button className="btn btn-light" onClick={()=>toggle}  size="lg" color="link">
            <i className='bx bx-x' color='primary'></i>
        </Button>
    );
    const handleMouseEnter = () => {
        setBtnName("Remove")
    }
    const handleMouseLeave = () => {
        setBtnName("Integrated")
    }
    const deleteIntegration = (integrationName: string) => {
        setIsLoading(true);
        toggle();
        removeIntegration(integrationName);
    }
    return (<>
        <Button
            className={`btn ${btnName == "Integrated" ? "btn-success" : "btn-danger"}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => toggle()}>
            {btnName}
        </Button>

        <Modal isOpen={modal} toggle={toggle} centered >
            <ModalBody className='p-4 pb-0'> 
                <div className='d-flex align-items-start gap-3'>
                    <InfoCircleOutlined className='font-size-20 text-warning fw-bold' />
                    <div>
                        <h6 className='mb-1 font-size-18'>Are you sure?</h6>
                        <p className='font-size-14 mb-2'>Are you certain you wish to remove this integration?</p>
                    </div>
                </div> 
            </ModalBody>
            <ModalFooter className='p-4' style={{borderTop:0}}>
                <Button color="secondary" outline={true} className='btn-sm px-3 py-2 font-size-14' onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" type="button" className="btn-primary btn-sm px-3 py-2 font-size-14" onClick={()=>deleteIntegration(name)}>
                    {loading && <><Spinner size="sm"/>{'   '}</>}
                    Yes
                </Button>
            </ModalFooter>
        </Modal>
    </>);

};

export default DynamicButton;