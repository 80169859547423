import React from "react";

// interface
import { MessagesTypes } from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessagesTypes;
  fullName: string;
  onClick?: any;
}
function RepliedMessage({ isFromMe, message, fullName, onClick }: RepliedMessageProps) {
  return (
    <div className="">
      <div className="replymessage-block mb-2 d-flex align-items-start">
        <div className="flex-grow-1">
          <div className="font-size-13">{message?.quotedParticipant}</div>
          {message?.replyOf?.type == "image" && (
            <img src={`data:image/png;base64,${message.replyOf?.text}`} alt="" onClick={() => { onClick(message.quotedStanzaID) }} style={{ cursor: 'pointer' }} />
          )}
          {message?.replyOf?.type == "chat" && (
            <p className="mb-0 text-wrap text-break font-size-12" onClick={() => { onClick(message.quotedStanzaID) }} style={{ cursor: 'pointer' }}>{message.replyOf?.text}</p>
          )}
          {message?.replyOf?.type == "document" && (
            <p className="mb-0 text-wrap text-break font-size-12" onClick={() => { onClick(message.quotedStanzaID) }} style={{ cursor: 'pointer' }}>{message.replyOf?.text}</p>
          )}

          {(message.replyOf?.attachments) && (
            <p className="mb-0">
              {message.replyOf?.attachments &&
                !message.replyOf?.image &&
                `${message.replyOf?.attachments.length} Files`}
              {message.replyOf?.image &&
                !message.replyOf?.attachments &&
                `${message.replyOf?.image.length} Images`}
              {message.replyOf?.image &&
                message.replyOf?.attachments &&
                `${message.replyOf?.attachments.length} Files & ${message.replyOf?.image.length} Images`}
            </p>
          )}

          {(message.replyOf?.newimage) && (
            <div className="ctext-wrap">
              <div className="message-img mb-0">
                <div className="message-img-list">
                  <div>
                    <a className="popup-img d-inline-block" href="/dashboard">
                      <img src={message.replyOf?.newimage[0].downloadLink} alt="" className="rounded border" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
}

export default RepliedMessage;
