import React from 'react'
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Messenger from "./Messenger";
const ReConnectMessenger = () => {
    const {id} = useParams();
    const location = useLocation();
    const { numberData } = location.state;
    // const editUserState = true; 
     return (<Messenger editNumber={id} numberData={numberData}/>)
}

export default ReConnectMessenger