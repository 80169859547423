import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Pagination, Card, Popconfirm, Modal, Tooltip } from 'antd';
import { Button, UncontrolledTooltip,Row,Col } from "reactstrap";
import type { ColumnsType } from 'antd/es/table';
import { TableListingPagesWrapper } from '../TableListingPagesWrapper.style';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';


import { DeleteOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";

import { useRedux, useProfile } from "../../hooks/index";
import { templateCreate, getTemplateList, templateDelete, getClientsList } from '../../redux/actions';
import moment from 'moment-timezone';

import {
  useLinkClickHandler,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { checkPlanAndFeature } from '../../helpers/common.service';
// import editUser from './editUser';

interface Template {
  id?: number;
  fullName: string;
  email: string;
  userActive: boolean;
  Status: string;
  Availability: string;
}

const NoTemplateDataImg ="https://images.controlhippo.com/svg/nodata_template _natural.svg"

const Template: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const { dispatch, useAppSelector } = useRedux();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [authToken, setAuthToken] = useState();
  const { userProfile } = useProfile();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  var time_zone = userProfile?.timezone ? userProfile?.timezone : process.env.DEFAULT_TIMEZONE

  const showModal = (__: any, data: any) => {
    setIsModalOpen(true);
    setDeleteId(__)
  };

  const handleOk = (deleteData: any) => {
    if (deleteData.parentId) {
      const deleteDataFunc = (deleteData: any) => {
        const payLoadData = {
          templateId : deleteData?._id,
          tempName : deleteData?.tempName,
          clientId : deleteData?.clientId,
          parentId : deleteData?.parentId
        }
        dispatch(templateDelete(payLoadData))
      };
      deleteDataFunc(deleteData)
      setIsModalOpen(false);
    };
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    dispatch(getTemplateList("templateList"));
  }, []);

  const { getUserListData, userDeleteSuccess, getTemplateLists, clientList, whatsappUnverifiedList, templateDeleteSuccess } = useAppSelector((state) => {
    return {
      getUserListData: state?.GetuserList?.profileDetails,
      userDeleteSuccess: state?.DeleteUser?.userDeleteSuccess,
      getTemplateLists: state?.Templates?.getTemplateList,
      templateDeleteSuccess: state?.Templates?.templateDeleteSuccess,
      clientList: state.Chats?.clientList,
      whatsappUnverifiedList: state.Chats?.whatsappUnverifiedList,
    };
  });
  useEffect(() => {
    dispatch(getClientsList());
}, []);

useEffect(() => {
  if(templateDeleteSuccess){
    dispatch(getTemplateList());
  }
}, [templateDeleteSuccess])

  
  
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    pageSize && setPageSize(pageSize);
  };

  const handleSelectionChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: getTemplateLists?.length,
    onChange: handlePageChange,
    showSizeChanger: true,
    onShowSizeChange: handlePageChange,
  };
  let navigate = useNavigate();

  const CustomToast = () => (
    <div>
      Please configure your WhatsApp number first.{' '}
      <Link to="/welcome">Click Here</Link>
    </div>
  );

  const userAddHandleClick = () => {
    if (clientList?.length > 0 || whatsappUnverifiedList?.length > 0) {
      if(checkPlanAndFeature({featureName:"quick_reply_template", planDetails:userProfile?.planDetails,featureDisplayName:"Quick Replies"})){
        navigate("/template-Create")
      }
    } else {
      toast.info(<CustomToast />);
    }
  }

  // Slice the data based on the current page and page size
  const slicedData = getTemplateLists && getTemplateLists?.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  );

  const columns: ColumnsType<Template> = [
    {
      title: 'Template Name',
      dataIndex: 'tempName',
    },
    {
      title: 'Phone Number',
      // dataIndex: 'selectedNumber',
      render: (___: any) => {
        const excludedTypes = ["whatsapp", "telegram", "business_whatsapp"];
        return <span>{!excludedTypes.includes(___?.clientInfo?.type)? ___?.clientInfo?.pushname : formattedNumber(___.selectedNumber)}</span>;
    },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (createdAt: any) => {
        const formattedTime = moment(createdAt)?.tz(time_zone)?.format('DD-MM-YYYY HH:mm');
        return <span>{formattedTime}</span>;
      },
    },
    {
      title: 'Action',
      render: (__: any, index) => {
        if (__.parentId && userProfile?.userId !== __._id) {
          return (
            <>
              <div className='d-flex btn_spacing_ui'>
              <div>
                  {clientList.length > 0 ? (
                    <Link
                      color="primary"
                      id={`templateView-${__._id}`}
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      to={`/template-view/${__._id}`}
                      state={{ itemIndex: index }}
                    >
                      <i className='mdi mdi-eye'></i>
                    </Link>
                  ) : (
                    <button
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      onClick={() =>{
                        toast.info(<CustomToast />);
                      }
                      }
                    >
                      <i className='mdi mdi-pencil'></i>
                    </button>
                  )}

                  {clientList.length > 0 && (
                    <UncontrolledTooltip target={`templateView-${__._id}`} placement="left">
                      Template View
                    </UncontrolledTooltip>
                  )}
                </div>
                {/* <div>
                  {clientList.length > 0 ? (
                    <Link
                      color="primary"
                      id={`templateEdit-${__._id}`}
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      to={`/template-edit/${__._id}`}
                      state={{ itemIndex: index }}
                    >
                      <i className='mdi mdi-pencil'></i>
                    </Link>
                  ) : (
                    <button
                      className='btn btn-outline-primary btnEdit d-flex align-items-center justify-content-center me-2'
                      onClick={() =>{
                        toast.info(<CustomToast />);
                      }
                      }
                    >
                      <i className='mdi mdi-pencil'></i>
                    </button>
                  )}

                  {clientList.length > 0 && (
                    <UncontrolledTooltip target={`templateEdit-${__._id}`} placement="left">
                      Template Edit
                    </UncontrolledTooltip>
                  )}
                </div> */}
                <div>
                  <Tooltip placement="rightTop" title="Template Delete">
                    <Button color="danger" id={`userDelete-${__._id}`} outline type="button" onClick={() => showModal(__, __._id)} className="btn btn-outline-danger btnDanger d-flex align-items-center justify-content-center">
                      <i className='mdi mdi-delete-outline'></i>
                    </Button>
                  </Tooltip>
                </div>
                <Modal className='centerPopupModel' title="Template Delete" open={isModalOpen} onOk={() => handleOk(deleteId)} onCancel={handleCancel}>
                  <p><b>Are you sure you want to delete this Template ? </b></p>
                </Modal>
              </div>
            </>
          );
        }
      },
    }
  ];

  return (
    <TableListingPagesWrapper>
      <div className='tableListingMain user-chat overflow-hidden user-chat-show h-100'>
        {/* <Card className="pt-3 pb-1 px-lg-4 px-3 topBreadcrumb">
          <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Templates</h5>
              <div>
              {(!userProfile.parentId || (userProfile.parentId == userProfile.userId)) && 
              <Button className='py-1 px-2 btn btn-primary font-size-13' onClick={userAddHandleClick} type="button"> Create Template </Button>
              }
              </div>
          </div>
        </Card> */}
        <div className='userListCardMain h-100'>
          <Card 
          className='userListCard h-100'
          title={
            <div className='d-flex flex-column py-2'>
                <h2 className='mb-1'>Templates</h2>
                <p className='mb-0 page_discription'>
                Create Your Canned Messages Here for Quick Response Messaging and Streamlined Communication.
                <a href='https://support.controlhippo.com/support/solutions/articles/1060000057410-how-to-create-template-in-controlhippo-' className='ms-1 text-decoration-underline' target='_blank'>Need Help?</a>
                </p>
            </div>
        }
          extra={<Button className='py-1 px-2 btn btn-primary font-size-13 tableCreateBtn' onClick={userAddHandleClick} type="button">Create Template</Button>}>
            <Table
              //rowSelection={rowSelection}
              columns={columns}
              dataSource={(slicedData || []).map((item: any, index: number) => ({ ...item, key: index }))}
              pagination={false}
              style={{ width: '100%' }}
              locale={{ emptyText: <div className="noTableDataImgWrapper w-50 m-auto py-5 mt-2 mb-2">
                    <img src={NoTemplateDataImg} className="noTableDataImg rounded-4" />
                    <h5 className='mb-1 lh-sm mt-4 font-size-18 text-dark'>Create Your First Template </h5>
                    <p className='font-size-14 mb-0' style={{color:"#2c2c2c"}}>You're one step away from customizing your messaging experience! Click <a className='text-decoration-underline font-size-13 text-primary' onClick={userAddHandleClick} >'Add 
                  Template'</a> now to create your first custom message template.</p>
              </div> }} 
            />
            <div className='tablePaginationPos'>
              <Pagination {...paginationConfig} />
            </div>
          </Card>
        </div>
      </div>
    </TableListingPagesWrapper>
  );
};



const formattedNumber = (phoneNumber: any): any => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const formatPhoneNumber = (countryPhoneNumber: PhoneNumber): string => {
    const formattedNumber = phoneNumberUtil.format(countryPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
    return formattedNumber.replace(/ /g, ' ');
  };
  let decodedNumber: string | null = null;
  let number: PhoneNumber | undefined;
  let countryPhoneNumber = "+" + phoneNumber
  if (countryPhoneNumber) {
    try {
      const phoneRegex = /^\+?[0-9]{1,3}[- ]?\(?[0-9]{3}\)?[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
      const isPhoneNumber = phoneRegex.test(countryPhoneNumber);
      if (isPhoneNumber) {
        number = phoneNumberUtil.parse(countryPhoneNumber);
        decodedNumber = formatPhoneNumber(number);
        return decodedNumber
      }
    } catch (e) {
      console.log("error in formatPhoneNumber", e)
    }
  }
}


export default Template;
