import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { useProfile, useRedux } from '../../../hooks';
import { getDirectMessages, updateClientSession } from '../../../redux/actions';

interface FilterSideMenuProps {
  open: boolean;
  onClose: () => void;
  onLabelClick: () => void; // New prop for opening label drawer
}

export const FilterSideMenu: React.FC<FilterSideMenuProps> = ({ open, onClose, onLabelClick }) => {
  const { dispatch, useAppSelector } = useRedux();
  const localStorageChatFilter = localStorage.getItem("selectedChatFilter")
  const localStoragelabelChatId = localStorage.getItem("labelChatId")
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selctedFilter, setSelctedFilter] = useState<any>(localStorageChatFilter ? localStorageChatFilter: "All");
  const [limit] = useState(50);
  const [isResponseSuccess, setResponseSuccess] = useState(true);
  const [labelItemValue, setLabelItemValue] = useState<any>(localStoragelabelChatId);
  const { userProfile } = useProfile();

  let {
    clientId,
  } = useAppSelector(state => {
    return ({
    clientId: state.Chats.clientId,
  })});

  const handleFilterTab = async (filterType: any) => {
    try {
      if(clientId !== "all_numbers"){
        await dispatch(getDirectMessages(clientId, 0, limit, filterType, labelItemValue));
        setResponseSuccess(true);
      }else{
        if(!searchInputValue)
        await dispatch(getDirectMessages("all_numbers", 0, 50, filterType, localStoragelabelChatId))
        setResponseSuccess(true);
      }
    } catch (error) {
      setResponseSuccess(false);
    }
      localStorage.setItem("selectedChatFilter", filterType);
    setSelctedFilter(filterType);
    onClose()
  };

  return (
    <Drawer title="Filters" onClose={onClose} open={open} width={251} zIndex={9999} className='FilterDrawerBlock'>
      <ul>
        <li><a href='#' className='allChat' onClick={()=>{handleFilterTab("All")}}>All</a></li>
        <li><a href='#' className='myChat' onClick={()=>{handleFilterTab("My Chats")}}>My Chats</a></li>
        <li><a href='#' className='unresolvedChat' onClick={()=>{handleFilterTab("Unresolved Chats")}}>Unresolved Chats</a></li>
        <li><a href='#' className='unassignedChat'onClick={()=>{handleFilterTab("Unassigned Chats")}}>Unassigned Chats</a></li>
        <li><a href='#' className='unreadChat' onClick={()=>{handleFilterTab("Unread Chats")}}>Unread Chats</a></li>
        <li><a href='#' className='resolvedChat' onClick={()=>{handleFilterTab("Resolved Chats")}}>Resolved Chats</a></li>
        <li><a href='#' className='dndChat' onClick={()=>{handleFilterTab("DND Chats")}}>DND Chats</a></li>
        <li><a href='#' className='mentionChats' onClick={()=>{handleFilterTab("Mention Chats")}}>Mention Chats
        {userProfile?.isMention &&
                          <div className="liveClDot mgright5">
                          <span className="liveClOuterDot">
                          <span className="liveClInnerDot"></span>
                          </span>
                          </div>
                        }
          </a></li>
        <li><a href='#' className='labelsChat' onClick={onLabelClick}>Labels</a></li>
      </ul>
    </Drawer>
  );
};
