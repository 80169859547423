import React, { useEffect, useRef } from "react";
import "./assets/scss/theme.scss";
import { useSelector, useDispatch } from "react-redux";
import { changelayoutMode } from "./redux/actions";
import Routes from "./routes";
import { ProductFruits } from 'react-product-fruits';
// import { loadProgressBar } from 'axios-progress-bar';
// import 'axios-progress-bar/dist/nprogress.css';
import { useNavigate } from "react-router";

// loadProgressBar({ showSpinner: false });

const App: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  const user: any = localStorage.getItem("authData");
  const userData = JSON.parse(user)
  const userInfo: any = {
    username: userData?.email,
    email: userData?.email,
    firstname: userData?.firstName || userData?.fullName,
    lastname: userData?.lastName || "",
    signUpAt: userData?.createdAt,
    role: userData?.parentId ? "Subuser" : "Admin",
    props:
      { planName: userData?.chargebeeDetails?.plan_name, planStatus: userData?.chargebeeDetails?.status },
  };

  
  // Dark/Light Mode
  useEffect(() => {
    var getLayoutMode = localStorage.getItem("layoutMode");
    if (getLayoutMode) {
      dispatch(changelayoutMode(getLayoutMode));
    } else {
      dispatch(changelayoutMode(layoutMode));
    }
  }, [layoutMode, dispatch]);
  return (
    <>
      <Routes />
      {userInfo?.username && <ProductFruits workspaceCode="NP9WBSnvxanjMAMQ" language="en" user={userInfo} />}
    </>
  );
};

export default App;
