import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {showSuccessNotification, showErrorNotification } from "../../../helpers/notifications";
// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
  clearDownloadDataSet,
  updateChatUserDetails,
  getLabels,
  onSendTelegramMessage,
  getDirectMessages,
  changeAssignUser,
  onSendMessngerMessage,
  forwardMessageClearReducer,
  onSendChatBotMessage
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";
import SendForwardMessage from "./ChatInputSection/sendForwardMessage";

// interface
import { MessagesTypes,ConversationTypes,conversationOnlyTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs,fileTypes } from "../../../data/index";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
interface IndexProps {
  isChannel: boolean;
  // clientId : string| number;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  // const[ conversation, setConversation ] = useState< null | conversationOnlyTypes | undefined >();
  const[ conversation, setConversation ]  = useState<MessagesTypes[]>([]);
  const [forwardData,setForwardData] = useState<any>(false)
  const [forwardDataArray,setForwardDataArray] = useState<any>()
  const [selectedForwardDataState,setSelectedForwardDataState] = useState<any>()
  const [clients, setClients] = useState<[any]>()
  const [dropdownSelectedClientId, setDropdownSelectedClientId] = useState<any>(null)
  const localStorageChatFilter = localStorage.getItem("selectedChatFilter")
  const [selctedFilter, setSelctedFilter] = useState<any>(localStorageChatFilter);
  const [forwardComplete, setForwardComplete] = useState<any>(false);
  const [sendData, setSendData] = useState<any>("");

  const {
    chatUserDetails,
    mediaSendComplete,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    clientId,
    selectedChat,
    getUserConversationsLoading,
    getNextConversation,
    downloadFileData,
    loading,
    labels,
    clientList
  } = useAppSelector(state => {
    return ({
      chatUserDetails: state.Chats.chatUserDetails,
      mediaSendComplete: state.Chats.mediaSendComplete,
      chatUserConversations: state.Chats.chatUserConversations,
      isUserMessageSent: state.Chats.isUserMessageSent,
      isMessageDeleted: state.Chats.isMessageDeleted,
      isMessageForwarded: state.Chats.isMessageForwarded,
      isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
      isImageDeleted: state.Chats.isImageDeleted,
      clientId: state.Chats.clientId,
      clientList: state.Chats.clientList,
      selectedChat : state.Chats.selectedChat,
      getUserConversationsLoading : state.Chats.getUserConversationsLoading,
      getNextConversation : state.Chats.getNextConversation,
      loading: state.Chats.loading,
      downloadFileData: state.Chats.downloadFileData,
      labels: state.Chats.labels,
    })
  });

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };


  useEffect(() => {
    if(selectedChat){
      setForwardData(false)
    }
  }, [selectedChat])
  
  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
  null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  useEffect(() => {
    setClients(clientList)
  }, [])
  
  useEffect(() => {
    setClients(clientList)
  }, [clientList])
  
  useEffect(() => {
    if (mediaSendComplete) {
      toast.loading('Processing send message...', {
        position: "bottom-left"
      });
    } else {
      toast.dismiss();
      dispatch(forwardMessageClearReducer());
    }
  }, [mediaSendComplete]);

  const onSend = (data: any) => {
    let params: any = {
      template: data?.template ? JSON.stringify(data.template) : "",
      message: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      mentionList: data?.mentionList ? data.mentionList : "",
      newimage: data.newimage && data.newimage,
      audios: data.audios && data.audios,
      attachments: data.attachments && data.attachments,
      serializedId: data?.serializedId ? data.serializedId : null,
      chatId: chatUserDetails?.chatId,
      chatIds: (data?.type == "vCard" || data?.type == "forwardMsg") ? data?.selectedChatIds : null,
      // type:  data?.image ? "image" : data.attachments  ? "attachments" : data?.audios ? "audios" : data.text ? "text" : data.type == "vCard" ? "vCard" : data?.type,
      forwardDataArray: data?.forwardDataArray,
      meta: {
        receiver: chatUserDetails?.user,
        sender: userProfile && userProfile?.uid,
      },
    };
    if (data?.image) {
      params["type"] = "image"
    } else if (data.attachments) {
      params["type"] = "attachments"
    } else if (data.audios) {
      params["type"] = "audios"
    } else if (data.text) {
      params["type"] = "text"
    } else {
      params["type"] = data.type
    }
    // if(clientList.filter((item:any)=>item.clientId == clientId)[0].isWhatsappBusiness){
    // params["chatUserDetails"] = JSON.stringify(chatUserDetails)
    // }
    if (params?.forwardDataArray?.length > 5) {
      showErrorNotification("You can only forward 5 messages at a time.")
      return
    }
    let clientDetails: any
    setSendData(data)
    if (data?.clientId) {

      clientDetails = (data?.filterClientLists ? data?.filterClientLists : clients || []).find((item: any) => {
        return item.clientId == data.clientId
        })
      // if(chatUserDetails.clientId == "all_numbers" && !clientDetails){
      //   clientDetails = clients?.length && clients[0]
      // }
      if(clientDetails == undefined)clientDetails = data?.filterClientLists?.length ? data?.filterClientLists[0] : clients?.length && clients[0]
    } else {
      clientDetails = (clients || []).find((item: any) => {
        return item.clientId == clientId
      })

      if (clientDetails == undefined) clientDetails = clients?.length && clients[0]
    }
    if (replyData && replyData !== null) {
      params["replyOf"] = replyData?.type !== "location" ? (replyData?.text || replyData?.fileName) : replyData?.location?.description;
      params["replyId"] = replyData?.id?._serialized;
    }
    if (clientDetails.type == "business_whatsapp") {
      if (data?.template?.tempType) {
        params["type"] = data?.template.tempType
      }
    }

    if (data.tab == "message" || !data.tab || data?.tab != "notes") {
      if (clientDetails?.type == "whatsapp" || clientDetails?.type == "business_whatsapp") {
        if (data.type == "forwardMsg" && clientDetails.type == "business_whatsapp") {
          setTimeout(() => {
            showErrorNotification("Message forwarding unavailable for WhatsApp Business number.")
          }, 1500);
          return
        }
        if(params?.type != undefined){
          dispatch(onSendMessage(params, clientDetails.clientId));
        }
        setTimeout(() => {
          setForwardData(false)
          setForwardDataArray(null)
          setSelectedForwardDataState(!forwardData)
        }, 2000);
      } else if (clientDetails?.type == "telegram") {
        if (data.type == "forwardMsg") {
          setTimeout(() => {
            showErrorNotification("Message forwarding unavailable for Telegram number.")
          }, 1500);
        } else if (params.type == "image" || params.type == "attachments" || params.type == "vCard" || params.type == "audios") {
          setTimeout(() => {
            showErrorNotification("The Share Multi-media option is presently unavailable in Telegram.")
          }, 1500);
        } else {
          params['clientDetails'] = JSON.stringify(clientDetails)
          dispatch(onSendTelegramMessage(params, clientDetails.clientId));
        }
      }
      else if (clientDetails?.type == "messenger" || clientDetails?.type == "instagram") {
        if (data.type == "forwardMsg") {
          setTimeout(() => {
            showErrorNotification("Message forwarding unavailable for Messenger.")
          }, 1500);
        } else if (params.type == "image" || params.type == "attachments" || params.type == "vCard" || params.type == "audios") {
          setTimeout(() => {
            showErrorNotification(`The Share Multi-media option is presently unavailable in ${clientDetails?.type}.`)
          }, 1500);
        } else {
          params['clientDetails'] = JSON.stringify(clientDetails)
          params['clientType'] = clientDetails.type
          dispatch(onSendMessngerMessage(params, clientDetails.clientId));
        }
      } else if (clientDetails?.type == "chatbot") {
        if (data.type == "forwardMsg") {
          setTimeout(() => {
            showErrorNotification("Message forwarding unavailable for ChatBot number.")
          }, 1500);
        } else if (params.type == "attachments" || params.type == "vCard" || params.type == "audios") {
          setTimeout(() => {
            showErrorNotification("The Share Multi-media option is presently unavailable in ChatBot.")
          }, 1500);
        } else {
          params['clientDetails'] = JSON.stringify(clientDetails)
          dispatch(onSendChatBotMessage(params, clientDetails.clientId));
        }
      }
      addMixpanelEvent("LastMessageSentAt", { distinct_id: userProfile?.userId, email: userProfile?.email, LastMessageSentAt: new Date })
    } else if (data.tab == "notes") {
      const payLoadData = {
        userId: userProfile?.userId,
        chatId: chatUserDetails?.chatId,
        clientId: clientDetails.clientId,
        message: data.text && data.text,
        mentionedIds : data.mensionUsers ? data.mensionUsers:false,
        type: "internal-note",
        event: "Internal-Note"
      };
      dispatch(changeAssignUser(payLoadData));
    }
    if (!isChannel) {
      // setTimeout(() => {
      //   dispatch(receiveMessage(chatUserDetails?.chatId));
      // }, 1000);
      // setTimeout(() => {
      //   dispatch(readMessage(chatUserDetails?.chatId));
      // }, 1500);
      // setTimeout(() => {
      //   dispatch(receiveMessageFromUser(chatUserDetails?.chatId));
      // }, 2000);
    }
    setReplyData(null);
  };

  useEffect(() => {
    dispatch(getLabels());
  }, [])
  
  // useEffect(() => {
  //   if(chatUserConversations.length){
  //     setConversation(chatUserConversations)

  //   }
  // },[chatUserConversations])
  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      const chatId = chatUserDetails?.chatId
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  ]);

  useEffect(() => {
    if (downloadFileData && downloadFileData?.success && downloadFileData.data && !downloadFileData.data.downLoadUrl) {
      if (downloadFileData?.data?.type !== "image/jpeg" || downloadFileData?.data?.reponseType?.isLocalDownload) {
        const data: any = downloadFileData?.data
        const randomString = `Controlhippo_${new Date().getTime()}`;
        const fileName = data.fileName ? data.fileName : randomString + "." + data?.type?.split('/')[1];
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data.downLoadUrl ? data.downLoadUrl : url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        console.log(link)
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
      setTimeout(() => {
        dispatch(clearDownloadDataSet())
      }, 100);
    } else if (downloadFileData && downloadFileData.success && downloadFileData.success == false) {
      showErrorNotification("File not exist")
    }
  }, [downloadFileData]) 
  
  const onDeleteMessage = (messageId: string | number,option:string,clientId:string) => {
    dispatch(deleteMessage({_id:chatUserDetails._id,chatId:chatUserDetails.chatId,clientId:clientId}, messageId,option));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  const onScrollChange = (data:boolean,createdAt:any) => {
    if(data && chatUserConversations.length && !getUserConversationsLoading && getNextConversation){
      dispatch(getChatUserConversations(selectedChat,chatUserConversations[0]?.clientId,chatUserConversations[0]?.timestamp))
    }
  }

 

  const handleForward = (selectedValues: any, selectedForwardData: any) => {
    if(selectedValues)
    setForwardDataArray(selectedValues)
    setSelectedForwardDataState(selectedForwardData)
  }

  const onClickForwardBtn = (data: any) => {
    setForwardData(data)
  }
  const onUpdateUserDetails = (eventType:string,data:any)=> {
    const id = chatUserDetails?._id
    const chatId = chatUserDetails?.chatId
    const lastMessage = chatUserConversations?.length ? chatUserConversations[chatUserConversations?.length -1] : {} 
    // let clientId = false
    let newClientId = false
    if(eventType == "MarkAsUnRead" || eventType == "MarkResolve"){
      newClientId = clientId !== "all_numbers" ? clientId: lastMessage?.clientId ? lastMessage?.clientId : chatUserDetails?.clientId;
    }
    dispatch(updateChatUserDetails({id,clientId:newClientId,chatId,eventType,data}))
  }

  const handleClose = () => {
    dispatch(getDirectMessages(userProfile?.clientId, 0, 50,selctedFilter, 0))
    setForwardData(false)
    setForwardDataArray(null)
    setSelectedForwardDataState(!forwardData)
  }

  const setSelectedClientId = (value:any) =>{
    setDropdownSelectedClientId(value)
  }
  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        labels={(labels||[])}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={false}
        onToggleArchive={onToggleArchive}
        onUpdateUserDetails= {onUpdateUserDetails}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={false}
        onScrollChange={onScrollChange}
        onSend={onSend}
        handleForward={handleForward}
        onClickForwardBtn={onClickForwardBtn}
        handleClose={selectedForwardDataState}
      />
      {!forwardData?
      <ChatInputSection
        lastChatUserConversations={chatUserConversations[chatUserConversations?.length - 1]}
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
        clientList={clientList}
        clientId={clientId}
        setSelectedClientId={setSelectedClientId}
      />
 :
      <SendForwardMessage 
      forwardDataArray={forwardDataArray}
      chatUserDetails={chatUserDetails}
      onSend={onSend}
      handleClose={handleClose}
      dropdownSelectedClientId = {dropdownSelectedClientId}
      />
}
    </>
  );
};

export default Index;
