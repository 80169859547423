import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { Button, TabContent, TabPane } from "reactstrap";
import { useProfile, useRedux } from "../../hooks/index";
import { TABS } from "../../constants/index";
import Profile from "./Profile/index";
import Chats from "./Chats/index";
import Settings from "./Settings/index";
import UserList from "../Users/userList";
import Integrations from "../Integrations/index";
import chromeLogo from '../../assets/images/chrome_extension_logo.svg';

interface LeftbarProps { }
const Leftbar = ({clientList}: any) => {
  // global store
  const { useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const [userDetails, setUserDetails] = useState(userProfile)
  const { activeTab, userData } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
    userData:state.Login.user,
  }));
  type Client = {
    clientId: number;
  };
  useEffect(() => {
    setUserDetails(userData)
  }, [userData])
  
  const [isOpen, setIsOpen] = useState(true);
  const [notify, setNotify] = useState<boolean>(false)
  var tempChromeExtension = localStorage.getItem("isChromeExtensionHide")
   tempChromeExtension = tempChromeExtension !== null && JSON.parse(tempChromeExtension);
   const [storedData, setStoredData] = useState(true)
  useEffect(() => {
    if(!storedData){
    }
  }, [storedData])
  
var clientIdIndex = clientList?.findIndex((e: Client) => e.clientId === userData.clientId);
  useEffect(() => {
    const cookie = Cookies.get('noticeShow');
    const chargebeeDetails = userDetails?.chargebeeDetails
    if (cookie !== "false" &&  cookie !== undefined ) {
      setNotify(true)
    }
    else {
      setNotify(false)
    }
  }, [userDetails])
  
  const handleCancel = () => {
    setStoredData(false)
    localStorage.setItem("isChromeExtensionHide", JSON.stringify(true));
  }

  return (
    <>
      {/* start chat-leftsidebar */}
      {/* <div className="chat-leftsidebar padtop55"> */}
      <div className="chat-leftsidebar">
      {true && <div className={`download_chrome_extension_main bg-primary chrmExtensNoteTop d-flex justify-content-center`}> 
          <a href="https://support.controlhippo.com/en/support/solutions/articles/1060000077908-how-to-use-controlhippo-chrome-extension-" target="_blank" className="text-light font-size-11 text-decoration-underline">Want to know how controlhippo extension works ?</a>
            </div>}
        <TabContent activeTab={activeTab}>
          
           {/* {!notify && <div style={{display:tempChromeExtension ? "none" : "flex"}} className={`download_chrome_extension_main bg-primary`}> <div className="d-flex">
                <img className="" src={chromeLogo} alt="Chrome Logo" />
                <p className="mb-0">Download Chrome Extension. <b><u><a href="https://chrome.google.com/webstore/detail/controlhippo-whatsapp-cha/pkbaicalbphfonnjnomnlfdepbpocdfn" target="_blank" rel="noopener noreferrer">Click Here</a></u></b></p>
              </div>
              <Button onClick={handleCancel} className="btn-close" aria-label="Close"></Button>
            </div>} */}

          {/* Start Profile tab-pane */}
          <TabPane
            tabId={TABS.USERS_PROFILE}
            role="tabpanel"
          >
            <Profile />
          </TabPane>

          <TabPane
            tabId={TABS.CHAT} 
            role="tabpanel"
            className={`${clientList[clientIdIndex]?.isReady ? 'mngHgtForChrmExtens' : 'mngHgtForChrmExtensWnLgout'} ${clientList?.isDisablePersonalChatSync ? 'mngHgtForPrivacyNotice': ''} userChatTabPanMain`}
          >
            <Chats />
          </TabPane>
          <TabPane
            tabId={TABS.USER}
            role="tabpanel"
          >
            <UserList />
          </TabPane>

          {/* <TabPane
            tabId={TABS.INTEGRATIONS}
            role="tabpanel"
          >
            <Integrations />
          </TabPane> */}

          {/* <TabPane
            tabId={TABS.CONTACTS}
            role="tabpanel"
          >
            <Contacts />
          </TabPane> */}

          {/* <TabPane
            tabId={TABS.CALLS}
            role="tabpanel"
          >
            <Calls />
          </TabPane> */}

          {/* <TabPane
            tabId={TABS.BOOKMARK}
            role="tabpanel"
          >
            <Bookmark />
          </TabPane> */}


          <TabPane
            tabId={TABS.SETTINGS}
            role="tabpanel"
            className={`${userDetails?.privacy?.isDisablePersonalChatSync ? 'hgtProfilePrivacyOn': ''}`}
          >
            <Settings />
          </TabPane>
        </TabContent>
        {clientList?.[clientIdIndex]?.isDisablePersonalChatSync && userProfile?.planDetails?.features["privacy"] && <div className={`download_chrome_extension_main bg-primary privacyNotice d-flex`}> <div className="d-flex justify-content-center">
                <i className="mdi mdi-shield-lock"></i>
                <p className="mb-0 d-flex align-items-center gap-2 lh-sm">
                  Privacy mode ON 
                  <u>
                    <a href="https://support.controlhippo.com/support/solutions/articles/1060000057643-what-does-the-privacy-feature-do-in-controlhippo-" target="_blank" rel="noopener noreferrer" className="font-size-12">Know more</a>
                  </u>
                </p>
              </div>
            </div>}
      </div>
    </>
  );
};

export default Leftbar;
