import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import React, { useEffect, useState } from "react";
import { Card, UncontrolledTooltip, Row, Col, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { changeTab, getClientsList, getOldClientId, setClientId, updateClientDeatils, connectInstagram } from '../../../redux/actions';
import { Select, Button, Modal } from 'antd';
import { checkPlanAndFeature } from "../../../helpers/common.service";
import { checkEstimate } from "../../../helpers/checkEstimate";
import EstimatePopup from "../../../components/EstimatePopup";
import { TABS } from "../../../constants";
import { FacebookFilled } from '@ant-design/icons';
import { showErrorNotification } from "../../../helpers/notifications";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
import { log } from "console";
import { QrCodeWrapper } from '../QrCodeWrapper.style';
import instagramGif from '../../../assets/images/instagram_gif.gif';

const { Option } = Select;
declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: any;
    }
}
interface AddonsTypes {
    name: string;
    isActive: boolean;
}

interface IndexProps {
    editNumber?: string | Number | any;
    numberData: any | null
}

interface UserDetailsTypes {
    email?: string;
    userId?: string;
    clientId?: string;
    chargebeeDetails?: any;
    planDetails?: any;
    fullName?: string;
    phoneNumber?: string;
    userActive?: boolean,
    allAddons?: [AddonsTypes]
    country_code?: string;
}

const Index = (props: IndexProps) => {
    const { userProfile } = useProfile(); // Move this hook inside the component
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();
    const [rescanNumberParam, setRescanNumberParam] = useState("");
    const [pageListData, setPageListData] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tempClientId, setTempClientId] = useState("");
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [loading, setLoading] = useState(false);
    const [conformationPopupData, setConformationPopupData] = useState<{ isOpen: boolean, message?: string, from?: any, type?: String }>({ isOpen: false })
    const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
    const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
    const [show, setShow] = useState(false);
    const [pageResponse, setPageResponse] = useState<any>("");
    const [authAccesstoken, setAuthAccesstoken] = useState<any>("");
    const initialMessengerHandleState = { step: 1, btnText: "Authentication With Facebbok" };
    const [messengerHandle, setMessengerHandle] = useState(initialMessengerHandleState)
    const reconnectClientId = props?.numberData?.clientId

    const { clientList, userData } = useAppSelector((state) => {
        return {
            clientList: state.Chats?.clientList,
            userData: state.Login.user,

        };
    });


    useEffect(() => {
        setUserDetails(userData)
        if (userData?.chargebeeDetails) setChargebeeDetails(userData.chargebeeDetails)
    }, [userData])

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });
        };

        (function (d, s, id) {
            var js: HTMLScriptElement, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s) as HTMLScriptElement; js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        const checkFbInit = setInterval(() => {
            if (window.FB) {
                clearInterval(checkFbInit);
            }
        }, 100);

        return () => {
            clearInterval(checkFbInit);
        };
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const rescanNumber = searchParams.get('rescan');
        setRescanNumberParam(rescanNumber || "");
        dispatch(getClientsList());
    }, []);

    const handleFacebookConnect = async (e: any, response: any) => {
        // e.preventDefault()
        const objData = {
            item: "addon",
            eventType: "update_subscription_for_items",
            name: "scan_whatsapp_number",
            userDetails,
            dispatch
        }
        if ((!userDetails?.chargebeeDetails) || (response && response.skipEstimate) || (props?.numberData)) {
            onConfirm({ from: "scan_whatsapp_number", type: "messenger" })
            return
        }

        let checkData: any
        checkData = (messengerHandle.step == 1) ? await checkEstimate(objData) : { success: true, message: "Are you sure you want to rescan whatsapp number?" }
        if (checkData?.success) {
            if (checkData?.message) {
                setConformationPopupData({
                    isOpen: true,
                    message: checkData?.message,
                    from: "scan_whatsapp_number",
                    type: "messenger"
                })
            } else {
                onConfirm({ from: "scan_whatsapp_number", type: "messenger" })
            }
        } else {
            return (false)
        }
    };

    const onConfirm = (data: any) => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: "",
            type: ""
        })

        if (userProfile) {
            const payloadData = {
                reconnectClientId: reconnectClientId && reconnectClientId,
                type: "instagram"
            }
            dispatch(getOldClientId({ payloadData }, (response: any) => {
                if (response?.success) {
                    const clientId = response?.data?.clientId ? response?.data?.clientId : `${userProfile?.parentId ? userProfile?.parentId : userProfile?.userId}_${Math.floor(100000 + Math.random() * 900000)}`;
                    window.FB.login(function (response: any) {
                        if (response?.authResponse) {
                            setAuthAccesstoken(response?.authResponse?.accessToken)
                            setMessengerHandle({ step: 2, btnText: "Connect with Instagram Page" })
                            response.authResponse.userId = userProfile?.userId;
                            setTempClientId(clientId);
                            response.authResponse.clientId = clientId;
                            dispatch(connectInstagram(response?.authResponse, (response: any) => {
                                console.log("Response:", response);
                                if (response.success) {
                                    setPageListData(response?.pageList)
                                    setPageResponse(response)
                                    const data = {
                                        clientId: clientId
                                    };
                                    updateAuthData(data);
                                }
                            }));
                        } else {
                            showErrorNotification('You have cancelled login or did not fully authorize.');
                        }
                    }, { scope: "instagram_basic,instagram_manage_insights,pages_show_list,business_management,pages_messaging,instagram_manage_messages, pages_manage_metadata, pages_read_engagement" });
                }
            }));

        }
    }
    const submitPageData = async (e: any) => {
        const selectedOption = pageListData.find((option: any) => option.id === selectedOptionId);
        selectedOption.clientId = tempClientId
        selectedOption.channel = "instagram"
        dispatch(updateClientDeatils(selectedOption, (response: any) => {
            if (response?.success) {
                navigate("/dashboard")
                localStorage.setItem("selectedChatFilter", "All");
                dispatch(changeTab(TABS.CHAT));
                dispatch(setClientId(tempClientId));

            } else {
                showErrorNotification(response?.message)
            }
            setLoading(false)
        }))
        dispatch(getClientsList());
    }

    const handleChange = async (value: any) => {
        const selectedOption = pageListData.find((option: any) => option.id === value);
        setSelectedValue(selectedOption?.name)
        setSelectedOptionId(value)
    };

    const onCancel = () => {
        setConformationPopupData({
            isOpen: false,
            message: "",
            from: ""
        })
        if (loading) setLoading(false)
        if (show) setShow(false)
    }
    const handleBackButton = () => {
        navigate(-1);
    }

    const buttonClicked = (response: any) => {
        if (response.success) {
            const syntheticEvent = {} as React.FormEvent<HTMLFormElement>;
            return handleFacebookConnect(syntheticEvent, response)
        }
    }

    const handleSetupClick = () => {
        setIsModalVisible(true)
    }

    const handleClose = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <QrCodeWrapper className="QrCodeWrapper">
                <Card className="qrCodePageCardTitleMain p-3 w-100 h-100 mb-0">
                    <div className="d-flex gap-2">
                        <div className="ms-1 float-left">
                            <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                        </div>
                        <div>
                            <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                                <b> Connect Instagram {rescanNumberParam && `[ +${rescanNumberParam} ] `}</b>
                                <a id="whatsppcard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/en/support/solutions/articles/1060000081946-how-to-integrate-instagram-with-controlhippo-" target="_blank">
                                    <i className="bx bxs-info-circle font-size-17 lh-sm"></i>
                                </a>
                                <UncontrolledTooltip
                                    target={`whatsppcard`}
                                    placement="right"
                                >
                                    Click here to know more..
                                </UncontrolledTooltip>
                            </h2>
                            <p className="font-size-13 text-muted">Controlhippo now integrates with Instagram, allowing your team to manage customer queries directly within the platform.</p>
                        </div>
                    </div>
                    <Row className="qrmainrow mt-2">
                        <Col md={12} lg={6} xl={7} className="contetCol">
                            <div className="qrGenerateSteps">
                                <div className="mb-3 py-2 d-flex fw-bold text-secondary font-size-14" style={{ width: "fit-content" }}>[ Note : To integrate Instagram, you must have a Business Instagram account connected with Facebook. ]</div>
                                <h5 className="text-start">Use Your Instagram In ControlHippo</h5>
                                <ol className="text-start d-flex flex-column gap-3 mt-3">
                                    <li>Click "Connect with Facebook" to choose your Facebook account</li>
                                    <li>Grant permissions, and select a page to connect.</li>
                                    <li>Make sure your Instagram business account is connected to your Facebook business page. You should have admin access to both accounts.</li>
                                    <li>Ensure message control is enabled in your Instagram account. <a style={{ color: 'blue', cursor: "pointer" }} onClick={handleSetupClick}>Start Setup</a></li>
                                    <li>Then, you're ready to go!</li>
                                </ol>

                            </div>
                            {/* <Modal
                                visible={isModalVisible}
                                onCancel={handleClose}
                                footer={null} // No default footer buttons
                                style={{ position: 'fixed', bottom: 0, right: 0, margin: 0, padding: 0 }}
                                bodyStyle={{ padding: '10px !important' }} // Set padding to 10px
                                width={300} // Adjust the width of the modal
                            >
                                <img src={instagramGif} alt="Loading" style={{ width: '100%', height: "100%" }} />
                            </Modal> */}
                            <div
                                className={`modal fade ${isModalVisible ? 'show' : ''}`}
                                id="myModal"
                                tabIndex={-1}
                                aria-labelledby="myModalLabel"
                                aria-hidden={!isModalVisible}
                                style={{
                                    display: isModalVisible ? 'block' : 'none',
                                    position: 'fixed',
                                    bottom: 0,
                                    right: 0,
                                    margin: 0,
                                    padding: 0,
                                    zIndex: 1050, // Ensure modal is on top
                                }}
                                onClick={handleClose} // Close modal when clicking outside content
                            >
                                <div className="modal-dialog modal-dialog-end modal-dialog-side">
                                    <div className="modal-content" style={{ padding: '5px', width: '300px' }}>
                                        <div className="modal-header-1" style={{position:'absolute', top:0, right:0}}>
                                            <button
                                                type="button"
                                                className="custom-close-button"
                                                aria-label="Close"
                                                onClick={handleClose}
                                            >
                                                X
                                            </button>
                                        </div>
                                        <div className="modal-body" style={{ padding: '0' }}>
                                            <img src={instagramGif} alt="Loading" style={{ width: '100%', height: '100%' }} />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {pageListData?.length ?
                                <div className="d-flex flex-column mt-4 w-75">
                                    <Label>We have found Instagram page(s) managed by you.</Label>
                                    <Select
                                        // disabled={loading}
                                        value={selectedValue}
                                        onChange={handleChange}
                                        className="w-100"
                                        style={{ height: 46 }}
                                        placeholder="Select Instagram Page"
                                        optionLabelProp="label"
                                    >
                                        {pageListData?.map((option: any) => (
                                            <Option key={option.id} value={option.id} label={option.username}>
                                                <div className="d-flex align-items-center gap-2">
                                                    <img src={option?.profile_picture_url ? option?.profile_picture_url : `https://images.controlhippo.com/svg/instagram-int.svg`} height={26} width={26} className="rounded" />
                                                    <div className="instagramProfileDetails">
                                                        <div className="instagramUserName"><b>{option.username}</b></div>
                                                        <div className="instagramUserCategory">{option.category}</div>
                                                    </div>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                    <Button
                                        htmlType="submit"
                                        className="btn btn-primary d-flex align-items-center justify-content-center h-100 wpBtnPrimary mt-3 m-auto"
                                        style={{ width: 120 }}
                                        disabled={!selectedOptionId && messengerHandle?.step == 2}
                                        onClick={submitPageData}
                                    >Connect</Button>
                                </div> : ""
                            }
                            {
                                (pageListData?.length < 1 && messengerHandle?.step == 2 && pageResponse?.success) &&
                                <div className="qrGenerateSteps">
                                    <div className="alert alert-info mb-3 py-2 d-flex" style={{ width: "fit-content" }} role="alert">We couldn't find a Instagram page associated with your account. Please ensure your account is linked to an active Instagram page to continue.</div>
                                </div>
                            }
                            {/* <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} /> */}
                        </Col>
                        <Col md={12} lg={6} xl={5} className="d-flex flex-column align-items-center">
                            <img height={120} width={120} src={`https://images.controlhippo.com/svg/instagram-int.svg`} className="img_radius_8 rounded-4" alt="Whatsapp Logo - Controlhippo" />
                            <h4 className="lh-sm mb-0 mt-4 font-size-17">Instagram</h4>

                            {messengerHandle?.step == 1 && !authAccesstoken &&
                                <div className="mt-3"> <ChargebeeCheck numberData={props.numberData} userDetails={userProfile ? userProfile : userData} buttonText={"Connect with Facebook"} onConfirm={buttonClicked} from="whatsapp" /></div>
                            }
                        </Col>
                    </Row>
                    <div className="stickyBottomLine">Please refer to our <a href="https://support.controlhippo.com/en/support/solutions/articles/1060000081946-how-to-integrate-instagram-with-controlhippo-" target="_blank">support article </a>to gain a better understanding of Instagram integration.</div>
                </Card>
                {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm} />}
            </QrCodeWrapper>
        </>
    );
};

export default Index;
