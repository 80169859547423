import styled from 'styled-components';
import bgImage from '../../assets/images/bg-pattern/pattern-05.png'
import subscriptiondetailwhite from '../../assets/images//icons/subscriptiondetailwhite.svg'

const AccountSettingWrapper = styled.div`
    .labelTagBoxWrapper {
        gap: 8px;
        flex-wrap: wrap;
    }
    .labelTagBox {
        display: flex;
        padding: 5px 14px;
        border: 1px solid #ddd;
        font-size: 15px;
        gap: 5px;
        align-items: center;
        border-radius: 50px;
        position: relative;
    }
    .TagBoxInnerDiv {
        padding: 15px 15px !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
        margin-top: 10px;
        border-radius: 10px;
    }
    .inputLabelAddDiv {
        gap: 6px;
    }
    .addButton, .addButton:active {
        font-size: 14px;
        background-color: #0029c7;
        border-radius: 50px;
        color: #fff;
        padding: 5px 12px;
        border-color: #0029c7;
        line-height: normal;
        vertical-align: text-top;
        border-radius: 6px;
    }
    .addButton i {
        vertical-align: middle;
        margin-bottom: 1px;
    }
    .labelBoxBtn {
        padding: 0px;
        width: 17px;
        height: 17px;
        border-radius: 50px;
    }
    .labelBoxEditBtn, .labelBoxEditBtn:active {
        background-color: #0029c7;
        border-color: #0029c7;
    }
    .labelBoxSaveBtn, .labelBoxSaveBtn:active {
        background-color: #bb2124;
        border-color: #bb2124;
    }
    .inputLabelAddDiv button {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        padding: 0px;
    }
    .inputSaveBtn, .inputSaveBtn:active {
        background-color: #0029c7;
        border-color: #0029c7;
    }
    .inputCloseBtn, .inputCloseBtn:active {
        background-color: #bb2124;
        border-color: #bb2124;
    }
    .labelBoxBtn i, .inputSaveBtn i {
        font-size: 10px;
        line-height: normal;
        display: block;    
    }
    .labelTagBox span {
        padding-right: 4px;
    }
    .addLabelText {
        font-size: 14px;
        font-weight: 500;
        padding-right: 6px;
    }
    .moduleSidebarSpan{
        line-height:normal;
        i.bx.bx-dollar {
            margin-top: -2px;
        }
    } 
    .labelTagBox input {
        border: 1px solid #ddd;
        font-size: 12px;
        color: #495057;
        outline: none;
    }
    .inputCloseBtn i {
        display: block;
    }
    .addButtondiv {
        width: 110px;
        text-align: right;
        margin: 0 !important;
    }
    @media only screen and (max-width: 979px) {
        .addButtondiv {
            width: 140px;
        }
    }
    @media only screen and (max-width: 767px) {
        .addButtondiv {
            width: 170px;
        }
    }
    @media only screen and (max-width: 649px) {
        .addButtondiv {
            width: 100%;
            text-align: left;
        }
        .desAddContainer {
            flex-direction: column;
            gap: 8px;
        }
    }
    .sidebarMenuAnchor{
        .ant-anchor-link:hover{
            .accessModuleIcon{
                filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(0%) hue-rotate(200deg) brightness(103%) contrast(104%) !important;
            }
        }
        .ant-anchor-link .ant-anchor-link-title {
            padding: 10px 16px !important;
        }
    }  
    .accessModuleIcon{
        background-image: url('https://images.controlhippo.com/svg/access_module_icon-new.svg');
        height:20px;
        width:20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
    }
    .descColor{
        color:#848484 !important;
    }
`;
export { AccountSettingWrapper };