export enum IntegrationsActionTypes {
    API_RESPONSE_SUCCESS = "@@integrations/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@integrations/API_RESPONSE_ERROR",
    SAVE_INTEGRATIONS = "@@integrations/SAVE_INTEGRATIONS",
    GET_INTEGRATIONS = "@@integrations/GET_INTEGRATIONS",
    REMOVE_INTEGRATIONS = "@@integrations/REMOVE_INTEGRATIONS",
    UPDATE_INTEGRATION = "@@integrations/UPDATE_INTEGRATION",
    REMOVE_SLACK_CHANNEL = "@@integrations/REMOVE_SLACK_CHANNEL",
    ADD_BOARD_ID = "@@integrations/ADD_BOARD_ID",
    CHECK_URL = "@@integrations/CHECK_URL",
    CLEAR_STATE = "@@integrations/CLEAR_STATE",
}
export interface IntegrationsState {
    error: string;
    loading: boolean;
    isIntegration:any;
    integrations:any;
    removeIntegraation:any;
}
