import React, { useEffect, useState, useRef } from "react";
import { Form } from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';


// components
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";
import { ChatInputSectionWrapper } from './ChatInputSectionWrapper.style';
import {Tabs ,Select } from 'antd';
import type { TabsProps } from 'antd';
// interface
import { MessagesTypes } from "../../../../data/messages";
import { useRedux } from "../../../../hooks"

import whatsappLogo from "../../../../assets/images/whatsapp_logo.png";
import whatsappBusinessLogo from "../../../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../../../assets/images/channelListIcon/telegram_icon.png";
import instagramLogo from "../../../../assets/images/Instagram_icon.webp";
import messengerLogo from "../../../../assets/images/messenger_1.jpg"

import { checkPlanAndFeature } from "../../../../helpers/common.service";
import { allocatedUserList } from "../../../../redux/actions";
interface IndexProps {
  onSend: (data: any) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
  clientList: any;
  clientId: string;
  lastChatUserConversations:null | MessagesTypes | undefined;
  setSelectedClientId?:(data : any)=>void;
}
const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
  clientList,
  clientId,
  lastChatUserConversations,
  setSelectedClientId,
}: IndexProps) => {
  const [selectedClientValue, setSelectedClientValue] = useState<string | null>(null);
  const [tabType, setTabType] = useState("message")
  const [userList, setUserList] = useState<any[]>([]);
  const [filterClientLists, setFilterClientLists] = useState();
  const { dispatch,useAppSelector } = useRedux()
  const { selectedChat,userDetails,mappingData } = useAppSelector(state => {
    return ({
      selectedChat: state.Chats.selectedChat,
      userDetails : state.Login.user,
      mappingData : state.User.mappingData
    })})
    

  useEffect(() => {
    setTabType("message")
    setText('')
    onClearMedia()
  }, [selectedChat])
  
  const moreMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const users = mappingData?.length && (mappingData || []).map((item:any)=>item.userDetails)
    setUserList(users)
  }, [mappingData])
  

  /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSetSelectedClientId = (value:string) => {
    if(typeof setSelectedClientId == "function" )setSelectedClientId(value)
    setSelectedClientValue(value)
  }

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('MyDatabase', 3);
  
      request.onerror = (event: any) => {
        console.error('Database error:', event.target.errorCode);
        reject(event.target.errorCode);
      };
  
      request.onsuccess = (event: any) => {
        resolve(event.target.result);
      };
  
      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('DraftText')) {
          db.createObjectStore('DraftText', { keyPath: 'chatId' });
        }
      };
    });
  };

  const removeChatData = async (chatId: any) => {
    try {
      const db: any = await openDatabase();
  
      return new Promise((resolve, reject) => {
        const transaction = db?.transaction(['DraftText'], 'readwrite');
        const objectStore = transaction?.objectStore('DraftText');
        const request = objectStore?.delete(chatId);
        request.onsuccess = () => {
          console.log(`Chat data with chatId ${chatId} has been removed.`);
          resolve(true);
        };
  
        request.onerror = (event: any) => {
          console.error('Delete error:', event.target.errorCode);
          reject(event.target.errorCode);
        };
      });
    } catch (error) {
      console.error('Database error:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (moreMenuRef.current &&
          !moreMenuRef.current.contains(event.target as Node))
      ) {
        // setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    let filteredClientList
    let client_id
    if (chatUserDetails?.clientType !== "instagram" && chatUserDetails?.clientType !== "messenger") {
      filteredClientList = clientList.filter((item: any) => item.type !== "instagram" && item.type !== "messenger")
      setFilterClientLists(filteredClientList)
      client_id = lastChatUserConversations?.clientId ? lastChatUserConversations?.clientId : filteredClientList[0]?.clientId
    } else {
      if (chatUserDetails?.clientType == "messenger") {
        filteredClientList = clientList.filter((item: any) => item.type == "messenger")
      }
      if (chatUserDetails?.clientType == "instagram") {
        filteredClientList = clientList.filter((item: any) => item.type == "instagram")
      }
      setFilterClientLists(filteredClientList)
      client_id = lastChatUserConversations?.clientId ? lastChatUserConversations?.clientId : clientList[0]?.clientId
    }
    if (clientId == "all_numbers") onSetSelectedClientId(client_id)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(lastChatUserConversations?.clientId){
      let filteredClientList
      let client_id
      if(chatUserDetails?.clientType !== "instagram" && chatUserDetails?.clientType !== "messenger"){
        filteredClientList = clientList.filter((item: any) => item.type !== "instagram" && item.type !== "messenger")
        setFilterClientLists(filteredClientList)
        client_id = lastChatUserConversations?.clientId ? lastChatUserConversations?.clientId : filteredClientList[0]?.clientId
      }else{
        if (chatUserDetails?.clientType == "messenger") {
          filteredClientList = clientList.filter((item: any) => item.type == "messenger")
        }
        if (chatUserDetails?.clientType == "instagram") {
          filteredClientList = clientList.filter((item: any) => item.type == "instagram")
        }
        setFilterClientLists(filteredClientList)
        client_id = lastChatUserConversations?.clientId ? lastChatUserConversations?.clientId : clientList[0]?.clientId
        }
      if(clientId == "all_numbers" )onSetSelectedClientId(client_id)
    }
  }, [clientId,lastChatUserConversations])
  
  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<null | string>(null);
  const [selectedEmoji, setSelectedEmoji] = useState<null | string>(null);
  const [mentionList, setMentionList] = useState<string[] | []>();
  const [mensionUsers, setMensionUsers] = useState<string[]>([]);
  const onChangeText = (value: string,mentionList?:any) => {
    setText(value);
  };
  const onEmojiSelect  = (value : string | null) => {
    setSelectedEmoji(value)
  }
  /*
  template input
  */
  const [template, setTemplate] = useState<any>(null);

  /*
  images
  */
  const [images, setImages] = useState<Array<any> | null | undefined>(null);
  const onSelectImages = (images: Array<any>) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>(null);
  const onSelectFiles = (files: Array<any> | null) => {
    setFiles(files);
  };
  const [audios, setAudios] = useState<Array<any> | null | undefined>(null);
  const onSelectAudio = (audios: Array<any> | null) => {
    setAudios(audios);
  };

  useEffect(() => {
    if ((text?.replace(/(\r\n|\n|\r)/gm, "").length || images?.length || files?.length ||audios?.length)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files,audios]);

  const templateSendFunc = async (data:any) => {
    // setDisabled(true);
    if(data?.clientId){
      setTemplate(data)
    }
  }

  const onSubmit = (value?: any) => {
    removeChatData(selectedChat)
    setIsOpen(false);
    let data: any = {};
    if (text || text?.replace(/(\r\n|\n|\r)/gm, "").length || value?.clientId || template?.clientId) {
      data["text"] = text ? text : value?.formattedTempFormatCopy ? value?.formattedTempFormatCopy : template?.formattedTempFormatCopy;
      // data["text"] = text.replace(/\r?\n/g, '\n');
    }
    if((value?.tempName && value?.tempFormat) || template){
      data["template"] = value ? value : template;
    }
    if (images && images.length) {
      // const imgs = (images || []).map((i: any, key: number) => {
      //   const src = URL.createObjectURL(i);
      //   return {
      //     id: key + 1,
      //     downloadLink: src,
      //   };
      // });
      // data["image"] = imgs;
      const updatedFiles = images.map((file) => file.file);
      data["image"] = updatedFiles 
    }

    if (files && files.length) {
      // const fs = (files || []).map((f: any, key: number) => {
      //   const src = URL.createObjectURL(f);
      //   return {
      //     id: key + 1,
      //     name: f.name,
      //     downloadLink: src,
      //     desc: f.size,
      //   };
      // });
      // data["attachments"] = fs;
      const updatedFiles = files.map((file) => file.file);
      data["attachments"] = updatedFiles
    }
    if (audios && audios.length) {
      // const fs = (audios || []).map((a: any, key: number) => {
      //   const src = URL.createObjectURL(a);
      //   return {
      //     id: key + 1,
      //     name: a.name,
      //     downloadLink: src,
      //     desc: a.size,
      //   };
      // });
      // data["audios"] = fs;
      const updatedFiles = audios.map((file) => file.file);
      data["audios"] =  updatedFiles
    }
    if (mentionList && mentionList.length) {
      let newText = text
      for (let index = 0; index < mentionList.length; index++) {
        const element = mentionList[index];
        const mentionedData = (chatUserDetails.participants || []).find((item: any) => item.id.chatId === element);
        if (mentionedData) {
          const displayPhoneNumber = mentionedData.displayPhoneNumber;
          const findData = mentionedData.id.firstName ? mentionedData.id.firstName : displayPhoneNumber;
          newText = (newText || "").replace(new RegExp(`@${findData.replace('@', '')}`, 'g'), `@${displayPhoneNumber}`);
        }
      }
      data["text"] = newText;
      data["mentionList"] = mentionList
    }
    if (mensionUsers && mensionUsers.length) {
      let newText = text
      // for (let index = 0; index < mentionList.length; index++) {
      //   const element = mentionList[index];
      //   const mentionedData = (chatUserDetails.participants || []).find((item: any) => item.id.chatId === element);
      //   if (mentionedData) {
      //     const displayPhoneNumber = mentionedData.displayPhoneNumber;
      //     const findData = mentionedData.id.firstName ? mentionedData.id.firstName : displayPhoneNumber;
      //     newText = (newText || "").replace(new RegExp(`@${findData.replace('@', '')}`, 'g'), `@${displayPhoneNumber}`);
      //   }
      // }
      data["text"] = newText;
      data["mensionUsers"] = mensionUsers
    }
    if(selectedClientValue)data["clientId"] =  selectedClientValue
    if(filterClientLists)data["filterClientLists"] = filterClientLists
    if(tabType) data["tab"]= tabType 
    setText("");
    setImages(null);
    setFiles(null);
    setAudios(null);
    setTemplate(null);
    setMentionList([]);
    setMensionUsers([]);
    onSend(data);
    // setDisabled(false);
    const chatConservationAddClass = document.querySelector('.chat-conversation') as HTMLElement;
    if(chatConservationAddClass)chatConservationAddClass.classList.remove('expandBelowEditor');
  };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
    setAudios(null);
    setTemplate(null);
    setMentionList([]);
    setMensionUsers([]);
  };

  const getName = (clientList: any, selectedClientValue: string) => {
    const clientType = chatUserDetails?.clientType
    let filteredClientList = clientList;

    // If the clientType is not "instagram" or "messenger", filter out those types from the clientList
    if (clientType !== "instagram" && clientType !== "messenger") {
      filteredClientList = clientList.filter((item: any) => item.type !== "instagram" && item.type !== "messenger");
    }

    let listName;
    if (filteredClientList.length <= 1) {
      listName = 0;
    } else {
      listName = filteredClientList?.findIndex((item: any) => item.clientId === selectedClientValue);
    }

    if (listName === -1) {
      listName = 0;
    }

    if (listName !== -1 && filteredClientList.length > 0) {
      return (
        <>
          <div className='d-flex align-items-center'>
            <span className='me-1 mb-1'>
              {filteredClientList[listName].type === "whatsapp" ? (
                <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
              ) : filteredClientList[listName].type === "business_whatsapp" ? (
                <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' />
              ) : filteredClientList[listName].type === "telegram" ? (
                <img src={telegramLogo} width={16} height={16} alt='controllhippo Telegram Logo' />
              ) : filteredClientList[listName].type === "instagram" ? (
                <img src={instagramLogo} width={16} height={16} alt='controllhippo Instagram Logo' />
              ) : filteredClientList[listName].type === "messenger" ? (
                <img src={messengerLogo} width={16} height={16} alt='controllhippo Messenger Logo' />
              ) : null}
            </span>
            <span className="text-truncate wpLinkedUserName me-1">
              {filteredClientList[listName]?.pushname} (+{filteredClientList[listName]?.me?.user})
            </span>
            {/* <span className="text-muted text-truncate wpLinkedUserNumber">
              (+{filteredClientList[listName]?.me?.user})
            </span> */}
          </div>
        </>
      );
    }
  };
  

const handleInputChange = (value: any) => {
  if(value === "all_numbers"){
    onSetSelectedClientId(value);
      // dispatch(setClientId("all_numbers"))
      // dispatch(getDirectMessages(value, 0, 50, "all_numbers", localStoragelabelChatId))
  }else{
    onSetSelectedClientId(value);
      // if (value !== client_id) {
          // dispatch(setClientId(value))
          // dispatch(getDirectMessages(value, 0, 50, localStorageChatFilter, localStoragelabelChatId))
          // dispatch(getClientsList("dropdown"))
      // }
  }
     
};

const handleTabChange = (key :string) => {
  let lastUsedClientId: any = "";
  if (lastChatUserConversations && lastChatUserConversations?.clientId) {
    lastUsedClientId = lastChatUserConversations.clientId;
  }
  const newClientId: string = clientId !== "all_numbers" ? clientId : lastUsedClientId ? lastUsedClientId : selectedClientValue? selectedClientValue : chatUserDetails && chatUserDetails?.clientId ? chatUserDetails.clientId : "";
  if (key == "notes") {
    if (checkPlanAndFeature({ featureName: "private_note", planDetails: userDetails?.planDetails, featureDisplayName: "Private Note" }))
    dispatch(allocatedUserList(clientId = newClientId, (response: any) => {
      const users = response?.mappingData?.length && (response.mappingData || []).map((item: any) => item.userDetails)
      setUserList(users)
      return setTabType(key);
    }))
  } else {
    setTabType(key)
  }

};
const onSelectParticipant = (data:any,type?:string) => {
    setMentionList(data)
} 
const onSelectMensionUsers = (data:any,type?:string) => {
  setMensionUsers(data)
}

const items: TabsProps['items'] = [
  {
    key: 'message',
    label: 'Message',
  },
  {
    key: 'notes',
    label: 'Note',
  }
];

  return (
    <ChatInputSectionWrapper>
      <div className="chat-input-section pt-0">
        <Form
          id="chatinput-form"
          onSubmit={(e: any) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="row g-0 align-items-center chatSendInputMain">
            <Tabs
              defaultActiveKey="message"
              id="justify-tab-example"
              className="mb-3"
              activeKey={tabType}
              items={items}
              onChange={handleTabChange}
            />
            {<div className="chatInputInner">
              <div className="d-flex justify-content-between align-items-end m-0 w-100 chatInputInnerButton">
                <div className="chatInputInnerButtondiv">
                  {tabType === 'message' && <StartButtons
                    onToggle={onToggle}
                    text={text}
                    onSelectAudio={onSelectAudio}
                    onSelectFiles={onSelectFiles}
                    onChange={onChangeText}
                    onEmojiSelect={onEmojiSelect}
                    templateSend={templateSendFunc}
                    clientId={selectedClientValue}
                    onSubmit={onSubmit}
                  />}
                </div>
                <EndButtons onSubmit={onSubmit} disabled={disabled} />
              </div>
              <div className="row align-items-center">
                <div className="inpuSectionMain">
                  <InputSection
                    type={tabType}
                    selectedFiles={files}
                    selectedImages={images}
                    selectedAudios={audios}
                    placeholder={tabType === "message" ? "Type message here or / for quick reply..." : "Write a notes here for your team reference"}
                    value={text}
                    selectedEmoji={selectedEmoji}
                    onEmojiSelect={onEmojiSelect}
                    onChange={onChangeText}
                    onSubmit={onSubmit}
                    template={template}
                    templateSend={templateSendFunc}
                    clientId={selectedClientValue}
                    onSelectImages={onSelectImages}
                    onSelectFiles={onSelectFiles}
                    onSelectAudio={onSelectAudio}
                    mentionList={mentionList}
                    onChangeMentionList={onSelectParticipant}
                    userList={userList}
                    onChangeUserList={onSelectMensionUsers}
                    chatUserDetails={chatUserDetails}
                  />
                </div>
              </div>
            </div>}
            
            {clientId == "all_numbers" && selectedClientValue &&<div className="scanNbListInput msgSendUserDropdownMain">
              <Dropdown drop="up-centered" className='userNewDropdown w-100' onSelect={handleInputChange}>
                <Dropdown.Toggle variant="success" className='text-truncate bg-white' id="dropdown-basic">
                  {getName(clientList, selectedClientValue)}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {/* <span className='dotactive'></span> */}
                  {(chatUserDetails?.clientType == "instagram" || chatUserDetails?.clientType == "messenger") ? (
                    clientList?.filter((item: any) => (item.type === chatUserDetails.clientType && item.clientId == chatUserDetails?.clientId)).map((item: any) =>{ 
                      return (
                      <Dropdown.Item key={item.clientId} eventKey={item.clientId} className="custom-option">
                        <div className='d-flex align-items-center'>
                          <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline" : "dropItemUserOffline"}`}>
                            {item.type == "messenger" ? (
                              <img src={messengerLogo} width={16} height={16} alt='controllhippo Messenger Logo' />
                            ) : item.type == "instagram" ? (
                              <img src={instagramLogo} width={16} height={16} alt='controllhippo Instagram Logo' />
                            ) : null}
                          </span>
                          <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline" : "dropItemUserNameOffline"} me-1`}>
                            {item?.pushname}
                          </span>
                          <span className="text-muted dropItemUserNumber text-truncate">
                            (+{item.me?.user})
                          </span>
                        </div>
                      </Dropdown.Item>
                    )})
                  ) : (
                    clientList?.filter((item: any) => item.type !== "instagram" && item.type !== "messenger").map((item: any) => (
                      <Dropdown.Item key={item.clientId} eventKey={item.clientId} className="custom-option">
                        <div className='d-flex align-items-center'>
                          <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline" : "dropItemUserOffline"}`}>
                            {item.type === "telegram" ? (
                              <img src={telegramLogo} width={16} height={16} alt='controllhippo Telegram Logo' />
                            ) : item.type === "whatsapp" ? (
                              <img src={whatsappLogo} width={16} height={16} alt='controllhippo Whatsapp Logo' />
                            ) : item.type === "business_whatsapp" ? (
                              <img src={whatsappBusinessLogo} width={16} height={16} alt='controllhippo Whatsapp Business Logo' />
                            ) : null}
                          </span>
                          <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline" : "dropItemUserNameOffline"} me-1`}>
                            {item?.pushname} (+{item.me?.user})
                          </span>
                          {/* <span className="text-muted dropItemUserNumber">
                            (+{item.me?.user})
                          </span> */}
                        </div>
                      </Dropdown.Item>
                    ))
                  )}
                  {/* {clientList?.length > 1 &&
                    <Dropdown.Item key={"All Numbers"} eventKey={"all_numbers"} className={"custom-option"} >
                      <div className='d-flex align-items-center'>
                        <span className={`text-truncate me-1`}>
                          All Numbers
                        </span>
                      </div>
                    </Dropdown.Item>
                  } */}
                </Dropdown.Menu>
              </Dropdown>
            </div>}
          </div>
        </Form>
        {/* {(images && images.length) || (files && files.length) || (audios && audios.length) ? (
          <Alert
            isOpen={true}
            toggle={onClearMedia}
            color="secondary"
            className="alert-dismiss-custom rounded-pill font-size-12 mb-1 selected-media"
            closeClassName="selected-media-close"
          >
            <p className="me-2 mb-0">
              {images && !files && !audios && ` You have selected ${images.length} images`}
              {files && !images && !audios && ` You have selected ${files.length} files`}
              {audios && !images && !files && ` You have selected ${audios.length} audios`}
              {files && images && audios &&
                ` You have selected ${files.length} files & ${images.length} images. &  ${audios.length} audios.`}
              { files && images && !audios && 
                ` You have selected ${files.length} files & ${images.length} images.`}
              { !files && images && audios && 
                ` You have selected ${images.length} images & ${audios.length} audios.`}
              { !images &&  files && audios && 
                ` You have selected ${files.length} files & ${audios.length} audios.`}
            </p>
          </Alert>
        ) : null} */}
        <div ref={moreMenuRef}>
        <MoreMenu
          isOpen={isOpen}
          onSelectImages={onSelectImages}
          onSelectFiles={onSelectFiles}
          onSelectAudio={onSelectAudio}
          onToggle={onToggle}
          chatUserDetails={chatUserDetails}
          onSend={onSend}
        />
        </div>
    
        <Reply
          reply={replyData}
          onSetReplyData={onSetReplyData}
          chatUserDetails={chatUserDetails}
        />
      </div>
    </ChatInputSectionWrapper>
  );
};

export default Index;
