export enum SubUserSetPwdActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/forgetPass/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/forgetPass/API_RESPONSE_ERROR",
  
    SET_SUBUSER_PWD = "@@auth/forgetPass/SET_SUBUSER_PWD",
  }
  export interface AuthForgetPassState {
    forgetSuccessMsg: any;
    forgetError: any;
    subUserDetails: {};
  }
  