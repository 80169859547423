import { UserTypes } from "../../../data/chat";
import ChatUser from "./ChatUser";
import NoChatUserIcon from '../../../assets/images/no_chat_user_icon.svg';
interface DirectMessagesProps {
  users: any;
  openAddContact: any;
  selectedChat: string | number;
  onSelectChat: any;
  clientId :string | number;
  isChatUsersSyncing: Boolean;
  clientDisconnected: Boolean;
  isDisabled?:boolean;
  // onScrollGetChatList?: any;
}
const DirectMessages = ({
  users,
  openAddContact,
  selectedChat,
  onSelectChat,
  clientId,
  isChatUsersSyncing,
  clientDisconnected,
  isDisabled,
  // onScrollGetChatList
}: DirectMessagesProps) => {
  /*
    add contacts
    */

  return (
    <>
      <div id="chat-message-list" className="chat-message-list chatMsgListMain">
        <ul className="list-unstyled chat-list chat-user-list">
          {users?.length? (users || []).map((user: any, key: number) =>{ 
            return (
            <ChatUser
              user={user}
              key={key}
              clientId={clientId} 
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
              clientDisconnected={clientDisconnected}
              isDisabled={isDisabled}
            />
          )}) : (
            <div className="no-chat-user">
              <img src={NoChatUserIcon} alt="No Chat User Icon" width={159} height={127} />
              <div className="no-chat-title">No Chats...</div>
              <p>Discover new connections through our chat Box.</p>
            </div>
          )}

        </ul>
      </div>
    </>
  );
};

export default DirectMessages;
