/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Grid, Drawer, Card } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SideMenu from "../Default/SideMenu";

import { changelayoutMode, getPlans } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useRedux, useConversationUserType, useProfile } from "../../hooks/index";

const { useBreakpoint } = Grid;

interface MainContentProps {
  hasPageHeader?: boolean;
  gutter?: boolean;
}

interface InnerAppLayoutProps {
  mainContent: React.ReactNode;
  sideContent?: React.ReactNode;
  pageHeader?: boolean;
  sideContentWidth?: number;
  border?: boolean;
  sideContentGutter?: boolean;
}

export const InnerAppLayout: React.FC<InnerAppLayoutProps> = (props) => {
  const { mainContent, sideContent, pageHeader, sideContentGutter = true } = props;
  const { dispatch, useAppSelector } = useRedux();
  const [visible, setVisible] = useState(false);

  const close = () => {
    setVisible(false);
  };

  const openSideContentMobile = () => {
    setVisible(true);
  };

  const onChangeLayoutMode = (value: any) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value));
    }
  }

  return (
    
    <>
        {sideContent}
        {mainContent}
      </>
    
  );
};

export default InnerAppLayout;