import { ChatsActionTypes } from "./types";

// common success
export const chatsApiResponseSuccess = (actionType: string, data: any) => {
  return ({
    type: ChatsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  })
};
// common error
export const chatsApiResponseError = (actionType: string, error: string) => ({
  type: ChatsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getFavourites = () => ({
  type: ChatsActionTypes.GET_FAVOURITES,
});

export const getDirectMessages = (clientId: string, skip: number, limit: number, key : any, value : any, search ?: any) => {
  return ({
    type: ChatsActionTypes.GET_DIRECT_MESSAGES,
    payload: {clientId,skip,limit,key,value,search},
  })
};

export const clearReducerDataAction = () => {
  return ({
    type: ChatsActionTypes.CLEAR_CHAT_REDUCER_DATA,
  })
};
export const removeSelectedChat = () => {
  return ({
    type: ChatsActionTypes.REMOVE_SELECTED_CHAT,
  })
};
export const clearClientIdFromReducer = () => {
  return ({
    type: ChatsActionTypes.CLEAR_CLIENTID_REDUCER_DATA,
  })
};

export const getChannels = () => ({
  type: ChatsActionTypes.GET_CHANNELS,
});

export const addContacts = (contacts: Array<string | number>) => ({
  type: ChatsActionTypes.ADD_CONTACTS,
  payload: contacts,
});

export interface CreateChannelPostData {
  id: any;
  name: string;
  members: Array<string | number>;
  description?: string;
}
export const createChannel = (channelData: any) => ({
  type: ChatsActionTypes.CREATE_CHANNEL,
  payload: channelData,
});

export const updatePrivacySetting = (payloadData:any) => ({
  type: ChatsActionTypes.CHANGE_PRIVACY_SETTING,
  payload: payloadData,
});

export const makeAsAdmin = (channelData: any) => {
  return {
    type: ChatsActionTypes.MAKE_AS_ADMIN,
    payload: channelData,
  };
};
export const dismissAsAdmin = (channelData: any) => {
  return {
    type: ChatsActionTypes.DISMISS_AS_ADMIN,
    payload: channelData,
  };
};
export const removeParticipants = (channelData: any) => {
  return {
    type: ChatsActionTypes.REMOVE_PARTICIPANTS,
    payload: channelData,
  };
};

export const addParticipants = (channelData: any) => {  
  return {
    type: ChatsActionTypes.ADD_PARTICIPANTS,
    payload: channelData,
  };
};

export const changeSelectedChat = (selectedChat: string | number | null) => ({
  type: ChatsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});


export const syncingStopToCrm = (chatId: any) => {
  return{
  type: ChatsActionTypes.SYNCING_STOP_TO_CRM,
  payload: chatId,
}}

export const getChatUserDetails = (selectedChat: string | number | null, clientId?: string | number | null) => {
  return ({
    type: ChatsActionTypes.GET_CHAT_USER_DETAILS,
    payload: { selectedChat, clientId },
  })
};

export const getLabels = (clientId?:string | undefined) => {
  return({
    type: ChatsActionTypes.GET_LABELS,
    payload:  clientId ,
  });
};

export const changeAssignUser = (payLoadData:any) => {
  return ({
    type: ChatsActionTypes.CHANGE_ASSIGN_USER,
    payload: payLoadData,
  })
};
export const downloadChat = (payLoadData:any,clientId:String,authToken:String, timeZone:String) => {
  return ({
    type: ChatsActionTypes.DOWNLOAD_CHAT,
    payload: {payLoadData,clientId,authToken,timeZone},
  })
};

export const getChatUserConversations = (
  selectedChat: string | number | null,
  clientId?: string | number | null,
  createdAt? :Date | string
) => {
  return({
  type: ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
  payload: {selectedChat,clientId,createdAt},
})};

export const updateConverSation = (data: any) => {
  return({
  type: ChatsActionTypes.UPDATE_CONVERSATION,
  payload: data,
})};

export const toggleUserDetailsTab = (value: boolean) => ({
  type: ChatsActionTypes.TOGGLE_USER_DETAILS_TAB,
  payload: value,
});

export const onSendMessage = (data: any,clientId:any) => ({
  type: ChatsActionTypes.ON_SEND_MESSAGE,
  payload: {data,clientId}});
export const forwardMessageClearReducer = () =>{
  return ({
    type: ChatsActionTypes.FORWARD_MESSAGE_DONE,

  })
}

export const receiveMessage = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE,
  payload: id,
});

export const readMessage = (payload: any) => ({
  type: ChatsActionTypes.READ_MESSAGE,
  payload: payload,
});

export const receiveMessageFromUser = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
  payload: id,
});

export const deleteMessage = (
  chatUserDetails: any,
  messageId: number | string,
  option: string,
) => ({
  type: ChatsActionTypes.DELETE_MESSAGE,
  payload: { chatUserDetails, messageId,option },
});

export const forwardMessage = (data: object) => ({
  type: ChatsActionTypes.FORWARD_MESSAGE,
  payload: data,
});

export const deleteUserMessages = (userId: number | string) => ({
  type: ChatsActionTypes.DELETE_USER_MESSAGES,
  payload: userId,
});

export const getChannelDetails = (id: number | string) => ({
  type: ChatsActionTypes.GET_CHANNEL_DETAILS,
  payload: id,
});

export const toggleFavouriteContact = (id: number | string) => ({
  type: ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
  payload: id,
});

export const getArchiveContact = () => ({
  type: ChatsActionTypes.GET_ARCHIVE_CONTACT,
});

export const toggleArchiveContact = (id: number | string) => ({
  type: ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
  payload: id,
});

export const readConversation = (id: number | string) => ({
  type: ChatsActionTypes.READ_CONVERSATION,
  payload: id,
});

export const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => ({
  type: ChatsActionTypes.DELETE_IMAGE,
  payload: { userId, messageId, imageId },
});

export const receiveMessageNew = (payload: string | number | object | null) => {
  return ({
    type: ChatsActionTypes.NEW_MESSAGES_RECEIVED,
    payload: payload,
  })
}

export const setClientId = ((clientId : string)=>{
  return ({
    type: ChatsActionTypes.SET_CLIENT_ID,
    payload: clientId,
  })
})

export const updateClientSession = ((clientId : string)=>{
  return ({
    type: ChatsActionTypes.UPDATE_CLIENT_SESSION,
    payload: clientId,
  })
})

export const updateClientConnected = (( payload: object | null )=>{
  return ({
    type: ChatsActionTypes.UPDATE_CLIENT_CONNECTION,
    payload: payload,
  })
})

export const createUser = (data:any,callback:(response:any)=>void) => {
  return {
    type: ChatsActionTypes.CREATE_USER,
    payload:{data},
    callback
  };
};
export const createTelegramUser = (data:any,callback:(response:any)=>void) => {
  return {
    type: ChatsActionTypes.CREATE_TELEGRAM_USER,
    payload:{data},
    callback
  };
};
export const sendSeenApi = (chatId  :any , clientId : any) => {
  return {
    type: ChatsActionTypes.SEND_SEEN_API,
    data:{chatId,clientId}
  };
};

export const editWhatsappNumber = (payLoadData:any) => {
  return {
    type: ChatsActionTypes.EDIT_WHATSAPP_NUMBER,
    payload: payLoadData
  };
};

export const getClientsList = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_CLIENTS_LIST,
    payload : type
  });
};
export const getChannelList = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_CHANNEL_LIST,
    payload : type
  });
};
export const getTemplateClientList = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_TEMP_LIST,
    payload : type
  });
};
export const getReportChannelList = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_REPORT_LIST,
    payload : type
  });
};
export const getCampaignLists = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_CAMPAIGN_LIST,
    payload : type
  });
};
export const getAllocationList = (type?:any) => {
  return({
    type: ChatsActionTypes.GET_ALLOCATION_LIST,
    payload : type
  });
};
export const downloadFile = (conversationId:string, chatId:string, clietId:string, reponseType?: any) => {
  return({
    type: ChatsActionTypes.GET_DOWNLOAD_DATA,
    payload:{conversationId, chatId, clietId, reponseType}
  });
};
export const clearDownloadDataSet = () => {
  return({
    type: ChatsActionTypes.CLEAR_DOWNLOAD_DATA,
  });
};
export const updateChatUserDetails = ({id,clientId,chatId,eventType,data, chatName}:any,callback?:(response:any)=>void) => {
  return({
    type: ChatsActionTypes.UPDATE_CHAT_USER_DETAILS,
    payload:{id,clientId,chatId,eventType,data, chatName},
    callback
  });
}

export const syncCipherTextMessage = (data: any, clientId: any,callback?:(value:any)=>void) => {
  return({
    type: ChatsActionTypes.SYNC_CIPHERTEXT_MESSAGE,
    payload: { data, clientId },
    callback
  })};

export const onSendTelegramMessage = (data: any, clientId: any) => {
return({
  type: ChatsActionTypes.ON_SEND_TELEGRAM_MESSAGE,
  payload: { data, clientId }
})};

export const onSendChatBotMessage = (data: any, clientId: any) => {
  return({
    type: ChatsActionTypes.ON_SEND_CHATBOT_MESSAGE,
    payload: { data, clientId }
  })};

export const onSendMessngerMessage = (data: any, clientId: any) => {
return({
  type: ChatsActionTypes.ON_SEND_MESSENGER_MESSAGE,
  payload: { data, clientId }
})};

export const warmupConfirmationModalOpen = (value: any) => {
  return({
    type: ChatsActionTypes.WARMUP_CONFIRMATION_MODAL,
    payload: { value }
  })};
export const warmupLogicConfirm = (clientId: any) => {
  return({
    type: ChatsActionTypes.WARMUP_CONFIRMATION,
    payload: { clientId }
  })};