import React, { useState, useEffect, useRef } from 'react'
import moment from "moment";
import Leftbar from '../Dashboard/Leftbar'
import Welcome from '../Dashboard/ConversationUser/Welcome'
import { Form, Input, message, Space, Checkbox, Row, Col, Select, Radio, Popover, Card, Anchor, Button } from 'antd';
import { updateAuthData, useProfile, useRedux } from '../../hooks';
import { getUserDetails, getCustomerPortal, getInvoice, invoiceDownload, clearCustomerPortal, getProfileDetails, addCredit, systemLogoutUser, cancelAccount, getInvoicePaymentUrl, connectSocket, updateUserProfile, changePassword } from '../../redux/actions';
import { UserProfileComponent } from "./UserProfileComponent.style";
import { showErrorNotification, showSuccessNotification } from '../../helpers/notifications';
import { useNavigate } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';
import { TimeZone } from "../../../../client/src/data";
import { countryCodeOptions } from "../../../../client/src/data";
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordChecklist from "react-password-checklist";
import { toast } from "react-toastify";

import multiple_users from '../../assets/images/icons/multiple_users.svg'; 
import channelIcon from '../../assets/images/icons/qr_code_scaning.svg';
import integrationIcon from '../../assets/images/icons/integration_icon.svg';
import accountSetting from '../../assets/images/icons/account_setting_icon.svg';
import planBilling from '../../assets/images/icons/plan_billing_icon.svg';
import personalInfoIcon from '../../assets/images/icons/personal_info.svg';
import changPasswordIcon from '../../assets/images/icons/change_password.svg';

const countryData = require('country-data');

interface Option {
  name: string;
  credit: number;
}

const UserProfile = () => {
  const { userProfile } = useProfile()
  const { dispatch, useAppSelector } = useRedux()
  const navigate = useNavigate();
  const topRef = React.useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLDivElement>(null);
  const [newInputEmail, setNewInputEmail] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [password, setPassword] = useState("");
  const [isValidationPopupOpen, setValidationPopupOpen] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();


  const handlePasswordChange = (value: any) => {
    setPassword(value)
    setValidationPopupOpen(false)
  }

  const handleValidation = (rules: any) => {
    setValidationPopupOpen(rules);
  };
  useEffect(() => {
    if (userProfile?.userId) dispatch(getProfileDetails(userProfile?.userId));
  }, []);


  const {
    profileData,
    profileDetails,
    changePwdSuccessMsg,
    changepasswordError,
    changepasswordSuccess,
  } = useAppSelector(state => {
    return {
      profileData: state.Login.user,
      profileDetails: state.Profile.profileDetails,
      changePwdSuccessMsg: state.changePassword.changePwdSuccessMsg?.message?.message,
      changepasswordError: state.changePassword.changePwdErrorMsg?.error?.error,
      changepasswordSuccess: state.changePassword.changepasswordSuccess,
    };
  });

  useEffect(() => {
    if (profileDetails && Object.keys(profileDetails).length > 0) {
      form.setFieldsValue({
        fullName: profileDetails.fullName || '',
        phoneNumber: profileDetails.phoneNumber || '',
        companyName: profileDetails.companyName || '',
        email: profileDetails.email || '',
        timezone: profileDetails.timezone || '',
        country_code: profileDetails.country_code || ''
      });
    }
  }, [profileDetails]);

  useEffect(() => {
    if (changePwdSuccessMsg || changepasswordError) {
      if (changePwdSuccessMsg) {
        toast.success(changePwdSuccessMsg);
        const toastDismissTimer = setTimeout(() => {
          toast.dismiss();
          navigate("/welcome");
        }, 2000);
        return () => clearTimeout(toastDismissTimer);
      } else {
        toast.error(changepasswordError);
      }
    }
  }, [changePwdSuccessMsg, changepasswordError]);

  const uniqueCountryCodeOptions = countryCodeOptions.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.code === current.code);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const countryCodes = uniqueCountryCodeOptions.map((country: any) => {
    const dialCode: string = country.code.replace('+', '');
    const label: string = `+${dialCode} (${country.shortname})`;
    return { value: dialCode, label };
  });
  
  const onFinish = (values: any) => {
    values.userId = userProfile?.userId;
    dispatch(updateUserProfile(values));
    setNewInputEmail(false)
    setIsModified(!isModified);
  };

  const onFinishPwd = (values: any) => {
    console.log("onSubmitForm ~ values:")
    const data = {
      oldPassword: values.oldPassword,
      password: values.newPassword,
    };
    if(data && isValidationPopupOpen){
      dispatch(changePassword(data));
    }
  }
  const handleEmailChange = () => {
    setNewInputEmail(true)
  }
  const handleValuesChange = () => {
    setIsModified(true);
  };

  const getContainer = () => document.getElementById('mainctntr') as HTMLElement | Window;
  return (
    <>
      <UserProfileComponent>
        <div className="UserProfMain">
          <div>
            <Anchor
              className="sidebarMenuAnchor"
              targetOffset={20}
              getContainer={getContainer}
              items={[
                {
                  key: "yourPreferences",
                  href: "#",
                  title: (
                    <div className="nonClickableText">
                      Your Preferences
                    </div>
                  ),
                },
                {
                  key: "personalInfo",
                  href: "#personalInfo",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={personalInfoIcon} width={18} height={18} /> Personal Info
                    </span>
                  ),
                },
                {
                  key: "changePassword",
                  href: "#changePassword",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={changPasswordIcon} width={18} height={18} /> Change Password
                    </span>
                  ),
                },
                {
                  key: "accountManagement",
                  href: "#",
                  title: (
                    <div className="nonClickableText">
                      Account Management
                    </div>
                  ),
                },
                {
                  key: "usersPage",
                  href: "/user-list",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={multiple_users} width={20} height={20} /> Users
                    </span>
                  ),
                },
                {
                  key: "channelsPage",
                  href: "/numbers",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={channelIcon} width={20} height={20} /> Channels 
                    </span>
                  ),
                },
                {
                  key: "integrationPage",
                  href: "/integrations",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={integrationIcon} width={20} height={20} /> Integration 
                    </span>
                  ),
                },
                {
                  key: "workspaceSetting",
                  href: "#",
                  title: (
                    <div className="nonClickableText">
                      Workspace Setting
                    </div>
                  ),
                },
                {
                  key: "accountSetting",
                  href: "/integrations",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={accountSetting} width={20} height={20} /> Account Setting
                    </span>
                  ),
                },
                {
                  key: "planBilling",
                  href: "/integrations",
                  title: (
                    <span className="moduleSidebarSpan">
                      <img className="multiple-user-icon" src={planBilling} width={20} height={20} /> Plan and billing
                    </span>
                  ),
                },
              ]}
            />
          </div>
          <div id="sectionWrapper">
            <div className="d-none align-items-center gap-2 mx-1 mx-lg-4 mt-4 UserProfBack">
              <Button
                className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="bx bx-chevron-left font-size-24"></i>
              </Button>
              <h2 className="font-size-20 text-dark text-center mb-0">
                User Profile{" "}
              </h2>
            </div>
            <section
              id="personalInfo"
              ref={topRef}
              className="ms-3 mb-3"
            >
              <Card className="mb-0 userProfCardMain"
                title={
                  <div className='text-start'>
                    <h2 className='mb-0 lh-sm'>Personal Information</h2>
                    <p className='text-muted font-size-14 fw-normal mb-0 cardDesc text-wrap'>Your personal dashboard for viewing and editing your details.</p>
                  </div>
                }
                bordered={false}
              >
                <Form
                  form={form}
                  name="nest-messages"
                  onFinish={onFinish}
                  // initialValues={initialFormValues}
                  // onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="EmailFormAdd addtemplet_form personalInfoForm"
                  requiredMark={false}
                  onValuesChange={handleValuesChange}
                >
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Name" name="fullName" rules={[{ required: true, message: 'Please enter your name' }]}>
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Company Name" name="companyName" rules={[{ required: true, message: 'Please enter your company name' }]}>
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Phone Number" name="phoneNumber" rules={[
                        { required: true, message: 'Please enter your phone number' },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Please enter only numbers for the phone number',
                        },
                      ]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item label="Time Zone" name="timezone" rules={[{ required: true, message: 'Please select your timezone' }]}>
                        <Select>
                          {TimeZone.map((timezone) => (
                            <Select.Option key={timezone} value={timezone}>
                              {timezone}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>

                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row" ref={emailInputRef}>
                      <Form.Item
                        label={<div className='d-flex align-items-center gap-1'>
                          <span>Email</span>
                          <div>
                            <Button
                              className='emailEditButton'
                              icon={<i className='bx bxs-pencil' />}
                              onClick={handleEmailChange}
                            />
                          </div>
                        </div>}
                        name="email" rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
                        <Input readOnly />
                      </Form.Item>
                      {newInputEmail &&
                        <Form.Item
                          name="newEmail"
                          label="New Email"
                          className='profileFormFeild'
                          rules={[{ type: 'email', message: 'Please enter a valid email' }]}
                        >
                          <Input />
                        </Form.Item>
                      }
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                      <Form.Item
                        label={<div className='d-flex align-items-center gap-1'>
                          <span>Default Country Code</span>
                          <div>
                            <Popover content={`If no country code in CRM contact, system adds default & starts conversation.`} placement="right">
                              <InfoCircleFilled className='font-size-13' />
                            </Popover>
                          </div>
                        </div>}
                        name="country_code"
                        rules={[{ required: true, message: 'Please select your country code' }]}
                      >
                        <Select
                          showSearch
                          style={{ "width": "100%" }}
                          optionFilterProp="children"
                          filterOption={(input, option) => ((option?.label)?.toString().toUpperCase() ?? '').includes(input.toUpperCase())}
                          filterSort={(optionA, optionB) =>
                            ((optionA?.label)?.toString() ?? '').toLowerCase().localeCompare(((optionB?.label)?.toString() ?? '').toLowerCase())
                          }
                          options={countryCodes}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18} className="gutter-row">
                      <Form.Item className='d-flex align-items-center justify-content-center mb-0' >
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={!isModified}
                          className='btn-primary profileSubmitButton m-auto'
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </section>
            <section id="changePassword" className="ms-3">
              <Card className="mb-0 userProfCardMain changePassCard" title="Change Password" bordered={false}>
                <Form
                  name="basic"
                  form={form1}
                  onFinish={onFinishPwd}
                  // onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="EmailFormAdd addtemplet_form"
                  requiredMark={false}
                // disabled={viewTempltState}
                >
                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item label="Enter Old Password" name="oldPassword" rules={[{ required: true, message: 'Please enter current password' }]}>
                      <Input.Password
                        className="temp_name_input"
                        placeholder="Enter Old Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item label="Enter New Password" name="newPassword" rules={[{ required: true, message: 'Please enter new password' }]}>
                      <Input.Password
                        // type={showPassword ? "text" : "password"}
                        className="temp_name_input"
                        placeholder="Enter New Password"
                        onChange={(e) => handlePasswordChange(e.target.value)}
                      />
                    </Form.Item>
                    <div className="passwordChecklist">
                  {password.length && !isValidationPopupOpen ? (
                    <PasswordChecklist
                      rules={["minLength", "maxLength", "specialChar", "number", "capital" ]}
                      minLength={8}
                      maxLength={22}
                      value={password}
                      messages={{
                        minLength: "Minimum 8 Characters",
                        maxLength: "Maximum 22 Characters",
                        specialChar: "Contain at least 1 Special Character",
                        number: "Contain at least 1 number",
                        capital: "Contain at least 1 Uppercase Letter",
                      }}
                      onChange={(rules) => handleValidation(rules)}
                    />
                  ):""}
                </div>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={9} xl={9} className="gutter-row">
                    <Form.Item className='d-flex align-items-center justify-content-center mb-0' >
                      <Button
                        type="primary"
                        htmlType="submit"
                        // disabled={!isModified}
                        className='btn-primary profileSubmitButton m-auto'
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </section>
          </div>
        </div>
      </UserProfileComponent>
    </>
  );
}

export default UserProfile