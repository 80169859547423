import { UpdateUserProfileActionTypes } from "./types";

// common success
export const updateUserProfileApiResponse = (actionType: string, data: any) => ({
  type: UpdateUserProfileActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const updateUserProfileApiResponseError = (actionType: string, error: string) => ({
  type: UpdateUserProfileActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const updateUserProfile = (data:any) => {
  return {
    type: UpdateUserProfileActionTypes.POST_USER_PROFILE_UPDATE,
    payload: data,
  };  
};

