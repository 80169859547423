import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Input, Form, UncontrolledTooltip } from "reactstrap";
import { useRedux,useProfile, updateAuthData } from "../../../hooks/index";
import { setClientId,getDirectMessages,getClientsList, clearClientIdFromReducer, clearReducerDataAction } from "../../../redux/actions";
import Dropdown from 'react-bootstrap/Dropdown';
import { addMixpanelEvent } from "../../../helpers/mixpanel";
import whatsappLogo from "../../../assets/images/whatsapp_logo.png";
import messengerLogo from "../../../assets/images/messenger_1.jpg";
import instagramLogo from "../../../assets/images/Instagram_icon.webp";
import whatsappBusinessLogo from "../../../assets/images/whatsapp_business_logo.png";
import telegramLogo from "../../../assets/images/channelListIcon/telegram_icon.png";
interface MultipleNumberFilterProps {
    clientId: string;
    setClientConnection: (value: Boolean) => void;
    handleClientChange?: any;
}

function MultipleNumberFilter({ clientId, setClientConnection, handleClientChange }: MultipleNumberFilterProps) {
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();
    const {userProfile} = useProfile()
    const localStorageChatFilter = localStorage.getItem("selectedChatFilter");
    const localStoragelabelChatId = localStorage.getItem("labelChatId");
    const { clientList, client_id,
        isChatUsersSyncing,
        loading,
        skipChatUserList,
        clientsPayload
    } = useAppSelector(state => {
        return ({
            clientList: state?.Chats?.clientList,
            clientsPayload: state?.Chats?.clientsPayload,
            client_id: state.Chats.clientId,
            isChatUsersSyncing: state.Chats.isChatUsersSyncing,
            loading: state.Chats.loading,
            skipChatUserList: state.Chats.skipChatUserList,
        })
    })
    const [totalUnReadMessage, setTotalUnReadMessage] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            const authDataString = localStorage.getItem("authData");
            const authData = authDataString ? JSON.parse(authDataString) : null;
            if (authData) {
                if (authData && !authData?.clientId) {
                    const clientId = clientList[0]?.clientId || '';
                    const data = {
                        clientId: clientId
                    };
                    updateAuthData(data);
                    if(clientId){
                        dispatch(setClientId(clientId));
                        await dispatch(getDirectMessages(clientId, 0, 50, localStorageChatFilter ? localStorageChatFilter : "All", localStoragelabelChatId));
                    }
                }
            }
            else {
                if (authData && authData.clientId) {
                    delete authData.clientId;
                    localStorage.setItem("authData", JSON.stringify(authData));
                }
                dispatch(setClientId(""));
            }
        };

        fetchData();

    }, [clientList]);

    useEffect(() => {
        if(clientsPayload && clientsPayload?.totalUnreadMessage)setTotalUnReadMessage(clientsPayload.totalUnreadMessage)
    }, [clientsPayload])
    // useEffect(() => {
    //     if (clientList.length == 0) {
    //         if (authData && authData.clientId) {
    //             delete authData.clientId;
    //             localStorage.setItem("authData", JSON.stringify(authData));
    //         }
    //         dispatch(setClientId(""));
    //     }
    // }, [clientList])
    

    

    useEffect(() => {
        setSelectedValue(clientId)
        if(clientsPayload && clientsPayload?.totalUnreadMessage)setTotalUnReadMessage(clientsPayload.totalUnreadMessage)
    }, [])

    const [selectedValue, setSelectedValue] = useState('');

    const handleInputChange = (value: any) => {
        handleClientChange()
        if(value === "all_numbers"){
            setSelectedValue(value);
            if (!loading) dispatch(setClientId("all_numbers"))
            // dispatch(clearReducerDataAction())
            dispatch(getDirectMessages(value, 0, 50, localStorageChatFilter ? localStorageChatFilter : "All", localStoragelabelChatId))
        }else{
            if (value !== client_id) {
                setSelectedValue(value);
                if (!loading) dispatch(setClientId(value))
                dispatch(getDirectMessages(value, 0, 50, localStorageChatFilter ? localStorageChatFilter : "All", localStoragelabelChatId))
                dispatch(getClientsList("dropdown"))
            }
        }
           
    };

    // useEffect(() => {
    //     if(!client_id && clientList[0]?.clientId && selectedValue == ""){
    //         setSelectedValue(clientList[0]?.clientId)
    //         if (!loading) dispatch(setClientId(clientList[0]?.clientId))
    //         dispatch(getDirectMessages(clientList[0]?.clientId, 0, 50, "unresolveChat", null))
    //     }
    // }, [clientList])
    

    useEffect(() => {
        if (client_id) {
            setSelectedValue(client_id)
        }
    }, [client_id])

    const getName = (clientList:any,selectedValue:string) => {
        let listName
        if(clientList.length <= 1){
             listName = 0
        }else{
            listName = clientList?.findIndex((item: any) =>(item.clientId == selectedValue))
        }
        if(listName !== -1 && clientList.length > 0){
            return (
                <>
                    <div className='d-flex align-items-center'>
                        <span className='me-1 mb-1'>
                            {clientList[listName].type === "whatsapp" ? (
                                <img src={whatsappLogo} width={16} height={16} alt='WhatsApp Logo' />
                            ) : clientList[listName].type === "business_whatsapp" ? (
                                <img src={whatsappBusinessLogo} width={16} height={16} alt='WhatsApp Business Logo' />
                            ) : clientList[listName].type === "telegram" ? (
                                <img src={telegramLogo} width={16} height={16} alt='Telegram Logo' />
                            ) : clientList[listName].type === "messenger" ? (
                                <img src={messengerLogo} width={16} height={16} alt='Messenger Logo' />
                            ) : clientList[listName].type === "instagram" ? (
                                <img src={instagramLogo} width={16} height={16} alt='Instagram Logo' />
                            ) : clientList[listName].type === "chatbot" && (
                                <img src={"https://images.controlhippo.com/svg/chatbot_icon.svg"} width={16} height={16} alt='Chatbot Logo' />
                            )}

                            
                        </span>
                        <span className="text-truncate wpLinkedUserName me-1">
                            {clientList[listName]?.pushname}{" "}
                            {clientList[listName]?.type !== "messenger" && clientList[listName]?.type !== "instagram" && clientList[listName]?.type !== "chatbot" && (
                                <>+{clientList[listName]?.me?.user}</>
                            )}
                        </span>

                        {/* {
                            (clientList[listName].type !== "messenger" && clientList[listName].type !== "instagram") &&
                            <span className="text-muted wpLinkedUserNumber">
                                ( +{clientList[listName]?.me?.user} )
                            </span>
                        } */}
                        
                        {clientList[listName].unReadCount >= 1 &&  <span className="d-flex badge badge-pill text-bg-danger rounded-pill p-1 ms-2 unreadMsgDRpDwnTop">
                            <span className='fs-30'>{clientList[listName].unReadCount >= 99? `${99}+`:clientList[listName].unReadCount}</span>
                        </span>}
                    </div>
                </>
                )
        }else{
            return(
                <>
                    <div className='d-flex align-items-center'>
                        <span className='me-1 mb-1'>All Numbers </span>
                        {totalUnReadMessage >= 1 &&  <span className="d-flex badge badge-pill text-bg-danger rounded-pill p-1 ms-2 unreadMsgDRpDwnTop">
                            <span className='fs-30'>{totalUnReadMessage >= 99? `${99}+`:totalUnReadMessage}</span>
                        </span>}
                    </div>

                </>
            )
        }
    }
    return (
        <>
            {client_id &&
                <div className="d-flex align-center w-100 userNewDropdownMain">
                    <Dropdown className='userNewDropdown w-100' onSelect={handleInputChange}>
                        <Dropdown.Toggle variant="success" className='text-truncate bg-white w-100' id="dropdown-basic">
                            {getName(clientList, selectedValue)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                            {/* <span className='dotactive'></span> */}
                            {clientList?.map((item: any,index:Number) => (<>
                                <Dropdown.Item  key={`dropdown-${index}`} eventKey={item.clientId} className={"custom-option"}>
                                    <div className='d-flex align-items-center'>
                                        <span className={`mx-2 ${item.isSessionSaved || item.isReady ? "dropItemUserOnline " : "dropItemUserOffline"}`}>
                                            {item.type === "telegram" ? (
                                                <img src={telegramLogo} width={16} height={16} alt='Telegram Logo' />
                                            ) : item.type === "whatsapp" ? (
                                                <img src={whatsappLogo} width={16} height={16} alt='WhatsApp Logo' />
                                            ) : item.type === "business_whatsapp" ? (
                                                <img src={whatsappBusinessLogo} width={16} height={16} alt='WhatsApp Business Logo' />
                                            ) : item.type === "messenger" ? (
                                                <img src={messengerLogo} width={16} height={16} alt='Messenger Logo' />
                                            ) : item.type === "instagram" ? (
                                                <img src={instagramLogo} width={16} height={16} alt='Instagram Logo' />
                                            ) : item.type === "chatbot" &&(
                                                <img src={"https://images.controlhippo.com/svg/chatbot_icon.svg"} width={16} height={16} alt='Chatbot Logo' />
                                            )}
                                        </span>
                                        <span className={`text-truncate dropItemUserName ${item.isSessionSaved || item.isReady ? "dropItemUserNameOnline " : "dropItemUserNameOffline"} me-1`}>
                                            {item?.pushname} {item.type !== "messenger" && item.type !== "instagram" && item.type !== "chatbot" && item.me?.user }
                                        </span>
                                        {/* {item.type !== "messenger" && item.type !== "instagram" &&
                                        <span className="text-muted dropItemUserNumber">
                                            ( +{item.me?.user} )
                                        </span>
                                        } */}
                                        {item.unReadCount && item.unReadCount >= 1 ? <span className="d-flex badge badge-pill text-bg-danger rounded-pill p-1 ms-2 unreadMsgDRpDwnTop">
                                            <span className='fs-30'>{item.unReadCount >= 99? `${99}+`:item.unReadCount}</span>
                                        </span>:""}
                                    </div>
                                </Dropdown.Item>
                                 </>
                            ))
                            }
                            {clientList?.length > 1 &&
                                <Dropdown.Item  key={"All Numbers"} eventKey={"all_numbers"} className={"custom-option"} >
                                    <div className='d-flex align-items-center'>
                                        <span className={`text-truncate dropItemUserName me-1`}>
                                            All Numbers
                                        </span>
                                        {totalUnReadMessage >= 1 ? <span className="d-flex badge badge-pill text-bg-danger rounded-pill p-1 ms-2 unreadMsgDRpDwnTop">
                                            <span className='fs-30'>{ totalUnReadMessage >= 99? `${99}+`:totalUnReadMessage}</span>
                                        </span> : ""}
                                    </div>
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
        </>
    )
}
export default MultipleNumberFilter