import React, { useState, useEffect, useRef, useLayoutEffect, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import Quill from 'quill';
import "quill/dist/quill.core.css";
import 'quill/dist/quill.snow.css';

// images
import imagePlaceholder from '../../../../assets/images/users/profile-placeholder.png';
import excelIcon from '../../../../assets/images/sheets.png';
import pdfIcon from '../../../../assets/images/pdf.png';
import mp4Icon from '../../../../assets/images/mp4_icon.png';
import mp3Icon from '../../../../assets/images/mp3_icon.png';
import docsIcon from '../../../../assets/images/docs_icon.png';
import pptIcon from '../../../../assets/images/ppt_icon.png';
import zipIcon from '../../../../assets/images/zip_icon.png';
import pngIcon from '../../../../assets/images/PNG.svg';
import jpgIcon from '../../../../assets/images/JPG.svg';
import jpegIcon from '../../../../assets/images/JPEG.svg';
import webmIcon from '../../../../assets/images/Webm.svg';
import userImageIcn from '../../../../assets/images/user_profile.svg';
import { ClientDetailsProps } from '../../../../data';
import TemplatePopup from './TemplatePopup';
import GroupMemberList from '../GroupMemberList';
import UserMemberList from '../UserMemberList';
import ReactAudioPlayer from 'react-audio-player';
import { showErrorNotification } from '../../../../helpers/notifications';
import { useRedux } from '../../../../hooks';

const fileIcons: Record<string, string> = {
  'application/pdf': pdfIcon,
  'video/mp4': mp4Icon,
  'video/x-matroska': mp4Icon,
  'audio/mpeg': mp3Icon,
  'audio/ogg; codecs=opus': mp3Icon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
  'application/vnd.ms-excel': excelIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docsIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptIcon,
  'image/jpeg': jpegIcon,
  'image/jpg': jpgIcon,
  'image/png': pngIcon,
  'application/zip': zipIcon,
  'video/webm': webmIcon,
};

interface FileValue {
  url: string;
  type: string;
  name: string;
}
interface QuillEditorProps {
  clientDetails: ClientDetailsProps | undefined;
  onChange: (value: string, chatId?: any) => void;
  value: null | string;
  placeholder: string;
  selectedEmoji: null | string;
  tabType: string;
  selectedFiles: any;
  selectedImages: any;
  selectedAudios: any;
  onSelectImages: (images: Array<any>) => void;
  onSelectFiles: (files: Array<any>) => void;
  onSelectAudio: (audios: Array<any>) => void;
  clientId?: string | null;
  templateList: any;
  selectedTemplate: any;
  templateSend: (data: any) => void;
  chatUserDetails: any
  mentionList?: string[] | [];
  onChangeMentionList?: (mentionList: any, type?: string) => void;
  userList: any[] | null | undefined;
  mentionUserList?: string[] | [] | undefined;
  onChangeUserList?: (mentionUserList: any, type?: string) => void;
  onSubmit: () => void;
  onEmojiSelect: (value: string | null) => void;
  selectedChat?: any;
  draftSelectedChatId?: any;
}

function FileBlot(value: FileValue, type?: string, id?: any, callback?: (response: any) => void | undefined): HTMLDivElement {
  const node = document.createElement('div') as HTMLDivElement;

  const fileIcon = document.createElement('img');

  const isImage = type !== "attchments" ? ['image/png', 'image/jpg', 'image/jpeg'].includes(value.type) : false;
  const isAudio = type == "attchments" ? ['audio/ogg; codecs=opus'].includes(value.type) : false;

  if (isImage) {
    fileIcon.src = value.url;
  } else if (!isImage && !isAudio) {
    fileIcon.src = fileIcons[value.type] ? fileIcons[value.type] : fileIcons["application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  }

  fileIcon.style.width = '60px';
  fileIcon.style.height = 'auto';

  const iconContainer = document.createElement('div');
  iconContainer.classList.add('iconblockcontain');
  iconContainer.setAttribute('data-name', value.name);
  iconContainer.setAttribute('data-type', value.type);
  if (id) iconContainer.setAttribute("data-id", id);
  if (!isAudio) iconContainer?.appendChild(fileIcon);

  const infoContainer = document.createElement('div');
  infoContainer.classList.add('informblockcontain');

  if (!isImage && !isAudio) {
    const fileName = document.createElement('span');
    fileName.innerText = value.name;
    fileName.setAttribute('data-name', value.name);
    fileName.setAttribute('data-type', value.type);
    if (id) fileName.setAttribute("data-id", id);

    fileName.style.display = 'block';
    fileName.style.marginTop = '5px';

    const fileType = document.createElement('span');
    fileType.innerText = value.type;
    fileType.style.display = 'block';
    fileType.style.marginTop = '2px';

    infoContainer?.appendChild(fileName);
    infoContainer?.appendChild(fileType);
  }

  const mainContainer = document.createElement('div');
  mainContainer.classList.add('anotherFileBlock');
  if (!isAudio) mainContainer?.appendChild(iconContainer);
  if (!isImage && !isAudio) {
    mainContainer?.appendChild(infoContainer);
  }
  if (!isImage && isAudio) {
    const audioContainer = document.createElement('div');
    audioContainer.setAttribute('data-name', value.name);
    audioContainer.setAttribute('data-type', value.type);
    if (id) audioContainer.setAttribute("data-id", id);
    audioContainer.classList.add('audiofile-block');
    const audioPlayer = <div className='audioBlockStyle'><ReactAudioPlayer src={value.url} controls style={{ width: '250px', height: '30px' }} /></div>;
    ReactDOM.render(audioPlayer, audioContainer);
    const audioElement = audioContainer.querySelector('audio');
    if (audioElement) {
      audioElement.removeAttribute('title');
    }
    mainContainer?.appendChild(audioContainer);
  }

  node?.appendChild(mainContainer);

  const deleteButton = document.createElement('span');
  deleteButton.classList.add('closebtn-quill-file');
  deleteButton.innerHTML = '✖';
  if (id) deleteButton.setAttribute("data-id", id);

  deleteButton.addEventListener('click', () => {
    node.remove();
    if (callback) callback(id)
    const editor = document.querySelector('.ql-editor');
    if (editor && !editor.querySelector('.image-wrapper img')) {
      editor.classList.remove('imageWithContain');
    }
  });

  node?.appendChild(deleteButton);
  node.style.position = 'relative';
  node.style.display = 'inline-block';
  node.style.margin = '5px';

  return node;
}

const Embed: any = Quill.import('blots/embed');
const createMentionBlot = () => {
  class MentionBlot extends Embed {
    static blotName = 'mention';
    static tagName = 'span';

    static create(value: any) {
      let node = super.create();
      node.classList.add('mantionTag');

      node.setAttribute('data-id', value.id);
      node.setAttribute('data-name', value.name);
      // node.setAttribute('style', 'background: #cfe2ff; color: #084298;');
      node.innerText = `@${value.name}`;
      return node;
    }

    static value(node: any) {
      return {
        id: node.getAttribute('data-id'),
        name: node.getAttribute('data-name'),
      };
    }
  }
  return MentionBlot;
};
const QuillEditor = forwardRef<Quill | null, QuillEditorProps>(({ placeholder, clientDetails, onSubmit, onChange, onEmojiSelect, value, selectedEmoji, selectedFiles, selectedImages, selectedAudios, onSelectImages, onSelectFiles, onSelectAudio, templateList, selectedTemplate, templateSend, clientId, chatUserDetails, tabType, userList, onChangeUserList, mentionList, mentionUserList, onChangeMentionList, selectedChat, draftSelectedChatId }: QuillEditorProps, ref) => {

  const type = clientDetails?.type
  const [quill, setQuill] = useState<Quill | null>(null);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);
  const [lastCursorIndex, setLastCursorIndex] = useState<any>();
  const [selectedDraftChatId, setSelectedDraftChatId] = useState<any>(draftSelectedChatId);
  // const isGroup =  chatUserDetails && chatUserDetails?.isGroup ? true : false;
  const quillRef = useRef<HTMLDivElement | null>(null);
  const galleryRef = useRef<HTMLDivElement | null>(null);
  const audiFileType = ["audio/ac3","audio/aac","audio/ogg","audio/wav"];
  const removeFiles = (id: any) => {
    if (id) {
      if ((selectedImages || []).findIndex((item: any) => item.id === id) !== -1) {
        const images: any = (selectedImages || []).filter((item: any) => item.id !== id);
        onSelectImages(images.length ? images : null)
      } else if ((selectedAudios || []).findIndex((item: any) => item.id === id) !== -1) {
        const files: any = (selectedAudios || []).filter((item: any) => item.id !== id);
        onSelectAudio(files.length ? files : null)
      } else if ((selectedFiles || []).findIndex((item: any) => item.id === id) !== -1) {
        const files: any = (selectedFiles || []).filter((item: any) => item.id !== id);
        onSelectFiles(files.length ? files : null)
      }
    }
  }
  const { dispatch,useAppSelector } = useRedux()

  const {userDetails,mappingData } = useAppSelector(state => {
    return ({
      selectedChat: state.Chats.selectedChat,
      userDetails : state.Login.user,
      mappingData : state.User.mappingData
    })})

    useEffect(() => {
      if(draftSelectedChatId){
        setSelectedDraftChatId(draftSelectedChatId?.length && draftSelectedChatId)
      }
    }, [draftSelectedChatId])
    
  const handleFiles = (files: FileList, type?: string) => {
    if (galleryRef.current) {
      const fileArray = Array.from(files);
      const filteredFileArray = fileArray.filter((fileWrapper: any) => {
        const file = fileWrapper.file;

        if (file instanceof File) {
          if (file.size > 40 * 1024 * 1024) {
            showErrorNotification(`File '${file.name}' exceeds 40MB limit. Please choose a smaller file.`);
            return false;
          }
        }
        return true;
      });

      filteredFileArray.forEach(async (fileWrapper: any) => {
        const id: any = fileWrapper?.id ? fileWrapper?.id : false;
        const file = fileWrapper.file; // Extract the actual File object again here

        if (file instanceof File) { // Ensure file is a File object
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              const url = e.target.result as string;
              const existingNode = galleryRef.current?.querySelector(`[data-id="${id}"]`);
              if (existingNode) {
                console.log(`File with id '${id}' already exists in the gallery. Skipping.`);
                return; // Skip adding the file to the gallery
              }
  
              const node = FileBlot({ url, type: file.type, name: file.name }, type, id, removeFiles);
              galleryRef.current?.appendChild(node);
              if (['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
                const editorElement = document.querySelector('.ql-editor');
                if (editorElement) {
                  editorElement.classList.add('imageWithContain');
                }
              }
            }
          };
          reader.readAsDataURL(file);
        } else {
          console.error('The provided fileWrapper does not contain a valid File object.');
        }
      });
    }
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && (quill?.getText().trim()?.length || mentionList?.length || mentionUserList?.length || selectedFiles?.length || selectedImages?.length || selectedAudios?.length)) {
      event.preventDefault();
      console.log('Enter pressed (no new line)');
      return onSubmit()
    }
    if (event.cancelable && event.key === "Escape") {
      event.preventDefault();
      if (showGroupList) {
        onChangeShowGroupList(false)
      }
      if (showTemplates) {
        setShowTemplates(false)
        templateSend(null)
      }
    }

    // if ((event.ctrlKey || event.metaKey) && event.key === 'v' && quill) {
    //   event.preventDefault(); // Prevent default paste behavior
  
    //   // Simulate paste using native API
    //   navigator.clipboard.readText()
    //     .then(text => {
    //       document.execCommand('insertText', false, text);
    //     })
    //     .catch(err => {
    //       console.error('Failed to paste:', err);
    //     });
  
    //   // Simulate Ctrl+Shift+V after a delay
    //   setTimeout(() => {
    //     const ctrlShiftVEvent = new KeyboardEvent('keydown', {
    //       key: 'v',
    //       code: 'KeyV',
    //       shiftKey: true,
    //       ctrlKey: true, // or metaKey for macOS
    //     });
    //     quill?.root.dispatchEvent(ctrlShiftVEvent);
    //   }, 10);
    // }
  };

  const option: any = {
    theme: 'snow',
    placeholder: placeholder,
    modules: {
      toolbar: [
        [{ 'font': [] }],
        ['bold', 'italic', 'underline'],
        // [{ 'color': [] }, { 'background': ['#D6DEFF', 'skyblue'] }],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['emoji'],
      ],
    },
  }
  if (type !== "email") {
    option["modules"]["toolbar"] = false
  }
  useEffect(()=>{
    if (quillRef.current) {
      const Inline = Quill.import('blots/block/embed');

      (FileBlot as any).blotName = 'file';
      (FileBlot as any).tagName = 'div';

      const MentionBlot: any = createMentionBlot();
      Quill.register(MentionBlot,true);
      Quill.register(FileBlot as any);
      const editor = new Quill(quillRef.current, option);
      const keyboard = editor.keyboard
      delete keyboard.bindings["Enter"]  
      keyboard.addBinding({ key: 'Enter', shiftKey: true }, function (range: any, context: any) {
        editor.insertText(range.index, '\n');
        editor.setSelection(range.index + 1);
        return false
      });

      keyboard.addBinding({ key: '/' }, function (range: any, context: any) {
        setShowTemplates(true);
        return false
      });


      // const listEventListner = document.getElementById('chat-message-list') as HTMLElement;
      // if (listEventListner) {
      //   listEventListner.addEventListener('click', () => {
      //     editor.focus();
      //   });
      // }
      // const observer = new MutationObserver(() => {
      //   const navEventListeners = document.querySelectorAll('.chatSendInputMain .ant-tabs-nav') as NodeListOf<HTMLElement>;
      
      //   if (navEventListeners.length > 0) {
      //     navEventListeners.forEach((element) => {
      //       element.addEventListener('click', () => {
      //         editor.root.innerHTML = ''; // Set the editor content to an empty string
      //         editor.setSelection(lastCursorIndex);
      //         editor.focus();
      //       });
      //     });
      //   }  
      // });
      
      // // Start observing the parent element for changes
      // const parentElement = document.querySelector('.chatSendInputMain');
      // if (parentElement) {
      //   observer.observe(parentElement, { childList: true, subtree: true });
      // }
      
      // // Optionally, you can disconnect the observer when you no longer need it
      // // observer.disconnect();


      

      editor.root.addEventListener('drop', (e: DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer?.files;
        if (files) {
          const attachments = []
          const images = []
          const audios = []
          for (let index = 0; index < files.length; index++) {
            const element = files[index];
            if (element?.type?.includes("image")) {
              images.push({
                id: Math.random().toString(36).substring(2, 15),
                file: element,
              })
            } else if (element?.type?.includes("audio") && !audiFileType.includes(element.type)) {
              audios.push({
                id: Math.random().toString(36).substring(2, 15),
                file: element,
              })
            } else {
              attachments.push({
                id: Math.random().toString(36).substring(2, 15),
                file: element,
              })
            }
          }
          if (images?.length) onSelectImages(images)
          if (audios?.length) onSelectAudio(audios);
          if (attachments?.length) onSelectFiles(attachments)
          // handleFiles(files);
        }
      });
      // handleFocus()

      if(tabType){
        const lengthOfText = editor.getLength()
        if(lengthOfText)editor.setSelection(lengthOfText)
      }
      setQuill(editor);
      return () => {
        editor.off('text-change');
        editor.off('editor-change');
        if (quillRef.current) {
          // quillRef.current.removeEventListener('paste', () => { });
          quillRef.current.removeEventListener('drop', () => { });
        }
      };
    }
  },[tabType])

  const handleFocus = () => {
    // Ensure focus is set on both quillRef and quill instance (if available)
    quillRef?.current?.focus();
    quill && quill.focus();
  };

  useEffect(() => {
    if (quillRef.current && quill) {
      // quillRef.current.focus();
      const keyboard = quill.keyboard;
      const range = quill.getSelection();
      if (range && range?.index) quill.setSelection(range.index, range.length);
      else quill.setSelection(0);
      // setQuill(quill);

      quill.on('text-change', () => {
        const mentions: any = [];
        const textContent: any = getTextFromEditor(quillRef.current?.querySelector('.ql-editor'), mentions);
        // if(textContent?.text?.length > 0) onChange(textContent.text.trim(), draftSelectedChatId);
        onChange(textContent.text.trim(), draftSelectedChatId);
        if (tabType === "message" && onChangeMentionList && typeof onChangeMentionList === "function") {
          onChangeMentionList(mentions)
        } else if (tabType === "notes" && onChangeUserList && typeof onChangeUserList === "function") {
          onChangeUserList(mentions)
        }
      });

      quill.on('selection-change', (range, oldRange, source) => {
        if (range) {
          setLastCursorIndex(range.index)
        }else if(oldRange){
          setLastCursorIndex(oldRange.index)
        }
      });
      

      // quill.on("editor-change",( eventName, ...args)=>{

      //   if (eventName === 'text-change') {
      //     // args[0] will be delta
      //     const mentions: any = [];
      //     const textContent: any = getTextFromEditor(quillRef.current?.querySelector('.ql-editor'), mentions);
      //     onChange(textContent.text.trim(), draftSelectedChatId);
      //     // onChange(quill.getText().trim());
      //     if (tabType === "message" && onChangeMentionList && typeof onChangeMentionList === "function") {
      //       onChangeMentionList(mentions)
      //     } else if (tabType === "notes" && onChangeUserList && typeof onChangeUserList === "function") {
      //       onChangeUserList(mentions)
      //     }
      //   } else if (eventName === 'selection-change') {
      //     // args[0] will be old range
      //     const range:any = args[0];  
      //     if (range) {
      //       setLastCursorIndex(range.index)
      //     }
      //   }
      // })

      const toolbar = quill.getModule('toolbar') as any;
      if (toolbar && typeof toolbar.addHandler === 'function') {
        toolbar.addHandler('image', () => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          // input.setAttribute("max")
          input.setAttribute('multiple', 'multiple');
          input.click();

          input.onchange = () => {
            const files = input.files;
            if (files) {
              handleFiles(files);
            }
          };
        });
      }

      // keyboard.addBinding({ key: '/' }, function (range: any, context: any) {
      //   setShowTemplates(true);
      //   return false
      // });
      
      if ((tabType === "message" && chatUserDetails?.isGroup) || (tabType === "notes")) {
        if (quill?.keyboard?.bindings && !keyboard.bindings["@"]) {
          keyboard.addBinding({ key: "@", shiftKey: true }, (range: any, context: any) => {
            setShowGroupList(true);
            return false
          });
        }
      } else {
        if (quill?.keyboard?.bindings && keyboard.bindings["@"]) {
          delete quill.keyboard.bindings["@"]
        }
      }


      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta) => {
        const Delta = Quill.import('delta');
        const innerText = node.innerText || node.textContent;
        if (innerText) {
          const lines = innerText.split('\n');
          const newDelta = lines.reduce((acc: any, line: any) => {
            return acc.insert(line).insert('\n');
          }, new Delta());
          return newDelta;
          // return new Delta().insert(innerText);
        }
        return delta;
      });

      // quillRef.current.addEventListener( 'paste', () => { });
      return () => {
        quill.off('text-change');
        if (quillRef.current) {
          // handleFocus()
          // quillRef.current.removeEventListener('paste', () => { });
          // quillRef.current.removeEventListener('drop', () => { });
        }
      };
    }
  }, [quillRef,quill,chatUserDetails?.isGroup, selectedDraftChatId]);
  useEffect(() => {
    if (quillRef.current && quill && selectedChat !== chatUserDetails?.chatId) {
      handleFocus();
    }
  }, [chatUserDetails, type,quillRef,quill]);

  const getTextFromEditor = (element: HTMLElement | null | undefined, mentions: any): any => {
    let text = '';
    if (element) {
      const nodes = Array.from(element.childNodes); // Convert NodeList to array
      for (let node of nodes) {
        if (node.nodeType === Node.TEXT_NODE) {
          text += node.textContent || '';
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = (node as HTMLElement).tagName.toLowerCase();
          if (tagName === 'span') {
            text += (node as HTMLElement).textContent || '';
            const spanElement = node as HTMLElement
            const chatId = spanElement.getAttribute('data-id')
            if (chatId) mentions.push(chatId)
            } else if(tagName === 'p'){
            const result = getTextFromEditor(node as HTMLElement, mentions);
            // text += result.text
            if(text) text += "\n";
            text += result.text ? result.text : "";
          }else {
            const result = getTextFromEditor(node as HTMLElement, mentions);
            // text += result.text
            if(text) text += "\n";
            text += result.text ? result.text : "";
            
            // if(result.text)
            // text += "\n";
          }
        }
      }
    }
    return ({ text, mentions });
  };
  useEffect(() => {
    if (quill) {
      if (!value) {
        quill.clipboard.dangerouslyPasteHTML("");
        quill.setText("");
        // templateSend(null)
        if (tabType === "message" && onChangeMentionList && typeof onChangeMentionList === "function"){
          onChangeMentionList([])
        } else if (tabType === "notes" && onChangeUserList && typeof onChangeUserList === "function"){ 
          onChangeUserList([])
        }
        quill?.focus()
      } 

      if (value?.length) {
        delete quill.keyboard.bindings["/"]
      } else {
        if (quill?.keyboard?.bindings && !quill?.keyboard.bindings["/"]) {
          quill.keyboard.addBinding({ key: '/' }, function (range: any, context: any) {
            setShowTemplates(true);
          });
        }
      }
    }
  }, [value, quill]);

  useLayoutEffect(() => {
    let concatenatedArray: any = [];
    if (selectedFiles?.length) {
      concatenatedArray = concatenatedArray.concat(selectedFiles);
    }
    if (selectedImages?.length) {
      concatenatedArray = concatenatedArray.concat(selectedImages);
    }
    if (selectedAudios?.length) {
      concatenatedArray = concatenatedArray.concat(selectedAudios);
    }
    handleFiles(concatenatedArray)
  }, [selectedFiles,selectedImages,selectedAudios, quill])

  useLayoutEffect(() => {
    if (quill) {
      if (selectedFiles?.length) {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          // element.parentElement?.removeChild(element);
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
        // handleFiles(selectedFiles, "attchments")
      } else {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
      }
    }
  }, [selectedFiles, quill]);
  useLayoutEffect(() => {
    if (quill) {
      if (selectedAudios?.length) {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          // element.parentElement?.removeChild(element);
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
        // handleFiles(selectedAudios)
      } else {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
      }
    }
  }, [selectedAudios, quill]);

  useLayoutEffect(() => {
    if (quill) {
      if (selectedImages?.length) {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
        // handleFiles(selectedImages)
      } else {
        const editorImages: NodeListOf<Element> = document.querySelectorAll('.anotherFileBlock');
        editorImages.forEach((element: Element) => {
          const parentElement: HTMLElement | null = element.parentElement
          if (parentElement) parentElement.remove();
        });
      }
    }
  }, [selectedImages, quill]);

  useEffect(() => {
    if (selectedEmoji && quill) {
      const range = quill.getSelection();
      const index = range?.index ? range?.index : lastCursorIndex ? lastCursorIndex : null
      if (index) {
        quill.insertText(index, selectedEmoji);
        quill.setSelection(index + selectedEmoji.length);
      } else if(lastCursorIndex === 0 ){
        quill.insertText(0, selectedEmoji);
      }else {
        quill.insertText(quill.getLength() - 1, selectedEmoji);
      }
      onEmojiSelect(null)
    }
  }, [selectedEmoji])

  const onChangeShowGroupList = (value: any) => {
    setShowGroupList(value)
    if (!value && quill) {
      const range = quill.getSelection();
      const index = range?.index ? range?.index : lastCursorIndex ? lastCursorIndex : null
      if (index) {
        quill.insertText(index, "@");
        quill.setSelection(index + 1);
      } else {
        quill.insertText(quill.getLength() - 1, "@");
      }
    }
  }

  const onSelectParticipant = (data: any) => {
    const chatId = data.id.chatId ? data.id.chatId : data.id._serialized
    let mentions: string[] | [];
    if (mentionList?.length) {
      mentions = [...mentionList, chatId];
    } else {
      mentions = [chatId];
    }
    if (onChangeMentionList && typeof onChangeMentionList === "function") onChangeMentionList(mentions)
    const textLength  = quill?.getLength()
    const newValue = `${!textLength && mentionList?.length? "&nbsp":""}${data?.id.firstName ? data?.id.firstName : data?.displayPhoneNumber} `
    const mentionData = { id: chatId, name: newValue };
    if (data && quill) {
      const range = quill.getSelection();
      const index = range?.index ? range?.index : lastCursorIndex ? lastCursorIndex : null
      if (index) {
        quill.insertEmbed(index, 'mention', mentionData, 'user');
        quill.setSelection(index + newValue.length);
      } else if(lastCursorIndex === 0 ){
        quill.insertEmbed(0, 'mention', mentionData, 'user');
      } else {
        quill.insertEmbed(quill.getText().length, 'mention', mentionData, 'user');
      }
      const length = quill.getLength();
      quill.setSelection(length, length, Quill.sources.SILENT);
      quill.focus();
    }
    setShowGroupList(!showGroupList)
  }
  const onSelectUserParticipant = (data: any) => {
    const _id = data._id
    let mentions: string[] | [];

    if (mentionUserList?.length) {
      mentions = [...mentionUserList, _id];
    } else {
      mentions = [_id];
    }
    if (onChangeUserList && typeof onChangeUserList == "function") onChangeUserList(mentions)
    const textLength  = quill?.getLength()
    const newValue = `${!textLength || mentionUserList?.length ? " ":""}${data?.fullName} `
    const mentionData = { id: _id, name: newValue };
    if (data && quill) {
      const range = quill.getSelection();
      const index = range?.index ? range?.index : lastCursorIndex ? lastCursorIndex : null
      if (index) {
        quill.insertEmbed(index, 'mention', mentionData, 'user');
        quill.setSelection(index + newValue.length);
      } else if(lastCursorIndex === 0 ){
        quill.insertEmbed(0, 'mention', mentionData, 'user');
      } else {
        quill.insertEmbed(quill.getText().length, 'mention', mentionData, 'user');
      }
      const length = quill.getLength();
      quill.setSelection(length, length, Quill.sources.SILENT);
      quill.focus();
    }
    setShowGroupList(!showGroupList)
  }
  useEffect(() => {
    if (selectedTemplate) {
      const text = selectedTemplate?.formattedTempFormatCopy ? selectedTemplate?.formattedTempFormatCopy : selectedTemplate?.tempFormat
      if (text && quill) {
        const range = quill.getSelection();
        if (range) {
          quill.insertText(range.index, text);
        } else {
          quill.insertText(quill.getLength() - 1, text);
          quill.setSelection(text.length);
        }
        const length = quill.getLength();
        quill.setSelection(length, length, Quill.sources.SILENT);
        quill.focus();
      }
    }
  }, [selectedTemplate])

  const onValidateTemplate = (value: any) => {
    setShowTemplates(false)
    const text = value?.formattedTempFormatCopy ? value?.formattedTempFormatCopy : value?.tempFormat
    onChange(text);
    templateSend(value);
  }

  const onChangeShowTemp = () => {
    setShowTemplates(!showTemplates);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  
    const clipboard = e.clipboardData;
    const text = clipboard.getData('text/plain');
 
    if (!clipboard) return;
    const items = clipboard.items;
    if (!items) return;
    let fileCount = 0;
    const attachments = [];
    const images = [];
    const audios = [];
    let exceededFileCountAlertShown = false;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const id = Math.random().toString(36).substring(2, 15)
      if (item.kind === 'file') {
        const file: any = item.getAsFile();
        if (file.size > 40 * 1024 * 1024) {
          showErrorNotification({ message: "File size exceeds the 40 MB limit. Please choose a smaller file." });
          continue;
        } else {
          if (file.type.includes('image')) {
            images.push({ id: id, file });
          }else if (file.type.includes('audio') && !audiFileType.includes(file.type)) {
            audios.push({ id: id, file });
          } else {
            attachments.push({ id: id, file });
          }
          fileCount++;
          if (fileCount > 10 && !exceededFileCountAlertShown) {
            e.preventDefault();
            // alert('Maximum 10 files allowed. Remaining files skipped.');
            showErrorNotification({ message: "Maximum 10 files allowed. Remaining files skipped." });
            exceededFileCountAlertShown = true;
            break;
          }
        }
      }
    }
    if (images?.length) {
      console.log("🚀 ~ handlePaste ~ images?.length:", images?.length)
      onSelectImages(images);
    }
    if (audios?.length) {
      onSelectAudio(audios);
    }
    if (attachments?.length) {
      onSelectFiles(attachments);
    }
  };


  // Paddles
  const leftPaddle: HTMLDivElement | null = document.querySelector('.left-paddle');
  const rightPaddle: HTMLDivElement | null = document.querySelector('.right-paddle');

  // Get items dimensions
  const items: NodeListOf<HTMLDivElement> = document.querySelectorAll('.image-wrapper div');
  const itemsLength: number = items.length;
  const itemSize: number = items.length > 0 ? items[0].offsetWidth + parseInt(window.getComputedStyle(items[0]).marginRight, 10) : 0;

  // Paddle triggering point margin
  const paddleMargin: number = 20;

  // Get wrapper width
  const getMenuWrapperSize = (): number | null => {
    const element = document.querySelector('.image-wrapper-main-block');
    return element instanceof HTMLElement ? element.offsetWidth : null;
  };

  let menuWrapperSize: number | null = getMenuWrapperSize();

  // Responsive behavior for wrapper size
  window.addEventListener('resize', () => {
    menuWrapperSize = getMenuWrapperSize();
    updatePaddlesVisibility();
  });

  // Get total width of all menu items
  const getMenuSize = (): number => {
    return itemsLength * itemSize;
  };

  let menuSize: number = getMenuSize();

  // Get how much of menu is invisible
  const getMenuInvisibleSize = (): number => {
    return menuWrapperSize !== null ? menuSize - menuWrapperSize : 0;
  };

  let menuInvisibleSize: number = getMenuInvisibleSize();

  // Get current scroll position
  const getMenuPosition = (): number => {
    const element = document.querySelector('.image-wrapper');
    return element ? element.scrollLeft : 0;
  };

  const getWidth = (): number => {
    const element = document.querySelector('.image-wrapper');
    return element ? element.scrollWidth : 0;
  };

  const getClientWidth = (): number => {
    const element = document.querySelector('.image-wrapper');
    return element ? element.clientWidth : 0;
  };
  // Update menu invisible size and position on scroll
  const imageWrapper = document.querySelector('.image-wrapper');
  imageWrapper?.addEventListener('scroll', () => {
    updatePaddlesVisibility();
  });
  // Update paddles visibility based on scroll position and menu size
  const updatePaddlesVisibility = () => {
  menuInvisibleSize = getMenuInvisibleSize();
  const menuPosition: number = getMenuPosition();
  const scrollWidth: number = getWidth();
  const clientWidth: number = getClientWidth();

  const roundedValue = Math.round(menuPosition);

  const getOffset = roundedValue + clientWidth;

    if(menuPosition == 0 && scrollWidth == clientWidth){
      rightPaddle?.classList.add('hidden');
      leftPaddle?.classList.add('hidden');
    }else if (menuPosition <= 0) {
      console.log("if");
      leftPaddle?.classList.add('hidden');
      rightPaddle?.classList.remove('hidden');
    } else if (getOffset >= scrollWidth) {
      console.log("elseif");
      rightPaddle?.classList.add('hidden');
      leftPaddle?.classList.remove('hidden');
    } else {
      rightPaddle?.classList.remove('hidden');
      leftPaddle?.classList.remove('hidden');
    }
  };

  updatePaddlesVisibility();

  // Scroll to the left by one item
  rightPaddle?.addEventListener('click', () => {
    const newPosition = getMenuPosition() + itemSize;
    imageWrapper?.scrollTo({ left: newPosition, behavior: 'smooth' });
  });

  // Scroll to the right by one item
  leftPaddle?.addEventListener('click', () => {
    const newPosition = getMenuPosition() - itemSize;
    imageWrapper?.scrollTo({ left: newPosition, behavior: 'smooth' });
  });
  
  return (
    <div className="quill-editor">
      <div ref={quillRef} onKeyDown={handleKeyDown} onPaste={handlePaste} />
      
      <div className='image-wrapper-main-block'>
        <div ref={galleryRef} className='image-wrapper' />
        <div className="paddles-arrow">
          <div className="left-paddle paddle hidden" />
          <div className="right-paddle paddle hidden" />
        </div>
      </div>
      {tabType === "message" && chatUserDetails?.isGroup ? <GroupMemberList clientDetails={clientDetails} onChangeShowGroupList={onChangeShowGroupList} isOpen={showGroupList} text={value} chatUserDetails={chatUserDetails} onSelectParticipant={onSelectParticipant} /> : ""}
      {tabType === "notes" ? <UserMemberList onChangeShowGroupList={onChangeShowGroupList} isOpen={showGroupList} text={value} userList={userList} onSelectParticipant={onSelectUserParticipant} /> : ""}

      <TemplatePopup
        showTemplates={showTemplates}
        templateSend={onValidateTemplate}
        templateList={templateList}
        clientId={clientId}
        onChangeShowTemp={onChangeShowTemp}
      />
    </div>
  );
});

export default QuillEditor; 