import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { UpdateProfileDataTypes } from "./types";
import {
  updateProfileDataResponseSuccess,
  updateProfileDataResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postFakeRegister, postUpdateprofileData } from "../../../api/index";
import { authLoginApiResponseSuccess } from "../login/actions";
import { AuthLoginActionTypes } from "../login/types";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* updateProfileData({ payload: { data,userId } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH) {
    //   const response: Promise<any> = yield call(postUpdateprofileData, data);
    const response: Promise<any> = yield  call(postUpdateprofileData, data,userId);

      yield put(
        updateProfileDataResponseSuccess(
          UpdateProfileDataTypes.UPDATE_PROFILE_DATA,
          response
        )
      );
      yield put(authLoginApiResponseSuccess(AuthLoginActionTypes.USERDATA_UPDATE, response));
    } 
  } catch (error: any) {
    yield put(
      updateProfileDataResponseError(UpdateProfileDataTypes.UPDATE_PROFILE_DATA, error)
    );
  }
}

export function* watchUpadteProfile() {
  yield takeEvery(UpdateProfileDataTypes.UPDATE_PROFILE_DATA, updateProfileData);
}

function* updateProfileSaga() {
  yield all([fork(watchUpadteProfile)]);
}

export default updateProfileSaga;
