const newMessageRecived = async (currentChatStateData: any, payload: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { clientId, data, chatUserDetails } = payload
            let authData: any = localStorage.getItem("authData")
            authData = JSON.parse(authData)
            const receivedMessageData = data;
            const contactDetails = chatUserDetails
            const selectedChatId = currentChatStateData.selectedChat
            let assignedUserId
            let messageDisplay
            if (authData.accountDeatils?.secureChats) {
                assignedUserId = receivedMessageData?.assingedUser;
                const userId = authData.userId
                messageDisplay = (assignedUserId === userId || !authData?.parentId || authData?.parentId == userId);
            }
            let directMessages = currentChatStateData.directMessages
            let clients = currentChatStateData.clientList
            let chatUserData: any = currentChatStateData.chatUserDetails;
            let stateChatUserConversations: any = currentChatStateData.chatUserConversations
            let selectedFilter = currentChatStateData.selectedFilter
            let searchText = currentChatStateData.searchText
            let selectedLabelValue = currentChatStateData.selectedLabelValue
            let clientIdData: string = currentChatStateData.clientId ? currentChatStateData.clientId : authData.clientId
            // const clientDetails = clients.find((item: any) => item.clientId == clientId)
            const clientDetailIndex = clients.findIndex((item: any) => item.clientId == clientId)
            const clientDetails = clientDetailIndex !== -1 ? clients[clientDetailIndex] : undefined
            let newDirectMessages: any;
            if (clientDetails == undefined) payload = {}
            if (receivedMessageData && (clientId == clientIdData) || (clientIdData == "all_numbers" && clientDetails)||  (receivedMessageData.type == "banner" && receivedMessageData.chatId === selectedChatId)) {
                newDirectMessages = await checkAfterGenerateNewChatList({ directMessages, searchText, selectedFilter, authData, contactDetails, chatId: receivedMessageData?.chatId,eventType:"message_create", receivedMessageData: receivedMessageData, selectedLabelValue ,selectedClientId:clientIdData, clientDetails});
                
                if (
                    (!receivedMessageData.fromMe && chatUserData && chatUserData.chatId == selectedChatId && (receivedMessageData.from == selectedChatId)) || 
                    
                    (receivedMessageData.fromMe && (receivedMessageData.to == selectedChatId))
                ) {
                    console.log('enter in conversion condition:::selectedChatId ', selectedChatId);
                    stateChatUserConversations = [...currentChatStateData.chatUserConversations, receivedMessageData]
                }
                if (receivedMessageData.fromMe && chatUserData && chatUserData.chatId && (receivedMessageData.from == chatUserData.chatId)) {
                    chatUserData.lastMessageCount = chatUserData?.lastMessageCount ? chatUserData?.lastMessageCount + 1 : 1
                }
            } else {
                newDirectMessages = [...directMessages]
            }
            const responseData: any = {
                payload,
                messageDisplay,
                assignedUserId,
                newDirectMessages,
                chatUserData: chatUserData,
                stateChatUserConversations
            }
            resolve(responseData)
        } catch (error) {
            console.error("Error inside chat.service :newMessageRecived function :", error);
            reject({ success: false }); // Reject with error object
        }
    });
};

const chatUserValidate = async (currentChatStateData: any, payload: any) => {
    return new Promise<void | any>(async (resolve, reject) => {
        try {
            let authData: any = localStorage.getItem("authData")
            authData = JSON.parse(authData)
            const { chatUserDetails, eventType, clientId,isChatUsersSyncingPending,parentId,userId } = payload
            let chatId = chatUserDetails.chatId
            let directMessages = currentChatStateData.directMessages
            let contactDetails: any = false;
            let selectedFilter = currentChatStateData.selectedFilter
            let selectedLabelValue = currentChatStateData.selectedLabelValue
            let oldChatUserDetails = currentChatStateData.chatUserDetails
            let searchText = currentChatStateData.searchText
            if (chatId == oldChatUserDetails.chatId) {
                contactDetails = chatUserDetails;
            }
            let clients = currentChatStateData.clientList
            let clientIdData: string = currentChatStateData.clientId ? currentChatStateData.clientId : authData.clientId
            // const clientDetails = clients.find((item: any) => item.clientId == clientId)
            const clientDetailIndex = clients.findIndex((item: any) => item.clientId == clientId)
            const clientDetails = clientDetailIndex !== -1 ? clients[clientDetailIndex] : undefined
            let newDirectMessages: any;
            if (clientDetails == undefined) payload = {}
            const response: any = await checkAfterGenerateNewChatList({ directMessages, searchText, selectedFilter, authData, contactDetails: chatUserDetails, chatId, selectedLabelValue, eventType, selectedClientId:clientIdData, clientId ,clientDetails});
            let data: any = {}
            if (contactDetails) data["chatUserDetails"] = contactDetails
            if (response?.length) data["directMessages"] = response
            if (chatId == oldChatUserDetails.chatId && (contactDetails.markResolve && eventType == "MarkResolve" || contactDetails.dndStart && eventType == "DND")) data["selectedChat"] = null
            if (isChatUsersSyncingPending) data["isChatUsersSyncingPending"] = isChatUsersSyncingPending
            resolve(data)
        } catch (error) {
            console.error("Error inside chat.service :chatUserValidate function :", error);
            reject({ success: false });
        }
    })
}

const checkAfterGenerateNewChatList = ({ directMessages, searchText, selectedFilter, authData, contactDetails, chatId, from, receivedMessageData, selectedLabelValue, eventType,selectedClientId,clientId,clientDetails }: any) => {
    return new Promise<void | any>(async (resolve, reject) => {
        try {
            const index4 = await (directMessages || []).findIndex((e: any) => e?.chatId === chatId)
            let response: any;
            if(searchText){
                response = await searchListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails,selectedLabelValue },searchText)
            } else {
                switch (selectedFilter) {
                    case "All":
                        response = await allNumberListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "My Chats":
                        response = await myChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "Unresolved Chats":
                        response = await unResolveChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "Unassigned Chats":
                        response = await unAssignChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "Unread Chats":
                        response = await unReadChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, receivedMessageData, clientDetails })
                        break;
                    case "Resolved Chats":
                        response = await resolveChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "DND Chats":
                        response = await dndChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedClientId, clientId, clientDetails })
                        break;
                    case "Label Chats":
                        response = await labelChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedLabelValue, selectedClientId, clientId, clientDetails })
                        break;
                    case "Mention Chats":
                        response = await mentionChatsChatListUpdate({ directMessages, authData, selectedFilter, contactDetails, chatId, index: index4, eventType, selectedLabelValue, selectedClientId, clientId, clientDetails })
                        break;
                }
            }
            resolve(response)
        } catch (error) {
            console.error("Error inside chat.service.js:checkAfterGenerateNewChatList function", error);
            resolve({ success: false })
        }
    })
}
export { newMessageRecived, chatUserValidate, checkAfterGenerateNewChatList };

const searchListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId, clientDetails, selectedFilter, selectedLabelValue }: any, searchText: string) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            const regex = new RegExp(searchText, 'i'); // 'i' flag for case-insensitive search
            const isInFirstName = regex.test(contactDetails.firstName);
            const isInUser = regex.test(contactDetails.user);
            const isInVerifyName = regex.test(contactDetails.verifyName);

            if (authData.parentId && index === -1 && contactDetails && (isInFirstName || isInUser || isInVerifyName)) {
                const response = await checkAfterGenerateNewChatList({ directMessages, selectedFilter, authData, contactDetails, chatId, selectedLabelValue, eventType, selectedClientId, clientId, clientDetails });
                resolve(response)
            } else {
                newDirectMessages = [...directMessages];
                resolve(newDirectMessages)
            }
        } catch (error) {
            console.log("Error inside chat.service.ts:searchListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const allNumberListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId && index === -1 && contactDetails) {
                const checked: any = await conditionCheck({ authData, contactDetails });
                if (checked && !contactDetails.dndStart) {
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && !contactDetails.dndStart) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }

            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                const markResolve = contactDetails.markResolve;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages, selectedClientId, clientId })
                        break;
                    case "Assign-User":
                        // const isAssignedUser = contactDetails.assingedUser === authData.userId ? true : contactDetails.assingedUser == undefined ? true : false;
                        const isAssignedUser = contactDetails.assingedUser === authData.userId && contactDetails.assingedUser !== undefined ? true : false;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages, selectedClientId, clientId })
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages, selectedClientId, clientId })
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "MarkResolve":
                        if (markResolve && index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "unRead":
                        if (!contactDetails.meta.unRead) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages, selectedClientId, clientId })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages)
        } catch (error) {
            console.log("Error inside chat.service.ts:allNumberListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const myChatListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId,clientDetails}: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (index === -1 && contactDetails && contactDetails.assingedUser === authData.userId) {
                if(!contactDetails?.dndStart){
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                }else {
                    newDirectMessages = [...directMessages];
                }
            } else {
                newDirectMessages = [...directMessages];
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                const markResolve = contactDetails.markResolve;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                if (!isAssignedUser) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                if (!isAssignedUser) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkResolve":
                        if (markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages)
        } catch (error) {
            console.log("Error inside chat.service.ts:myChatListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const unResolveChatListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId ,clientDetails}: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId && index === -1 && contactDetails && (!contactDetails?.markResolve || contactDetails?.markResolve == undefined)) {
                const isAssignedUser = contactDetails.assingedUser === authData.userId;
                const checked: any = await conditionCheck({ authData, contactDetails })
                if (checked) {
                    if (!contactDetails?.dndStart) {
                        newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId ,clientDetails});
                    } else {
                        newDirectMessages = [...directMessages];
                    }
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && !contactDetails?.dndStart && (!contactDetails?.markResolve || contactDetails?.markResolve === undefined)) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                const markResolve = contactDetails.markResolve
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser && markResolve) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            newDirectMessages = [...directMessages];
                            if (!isAssignedUser && contactDetails.assingedUser !== undefined) {
                                if (markResolve) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                                if (markResolve) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            }
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "MarkResolve":
                        if (markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails ))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "updateChatUserUnReadMessage":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "Reset":
                        if (markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages);
        } catch (error) {
            console.log("Error inside chat.service.ts:unResolveChatListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const unAssignChatListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId && index === -1 && contactDetails && contactDetails.assingedUser === undefined) {
                if (!contactDetails.dndStart && ((authData.accountDeatils.secureChats && !authData.accountDeatils.onlyAssignUser) || (!authData.accountDeatils.secureChats && !authData.accountDeatils.onlyAssignUser))) {
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && contactDetails.assingedUser === undefined && !contactDetails.dndStart) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }            
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser !== undefined;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            if (isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                                if (isAssignedUser) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                if (isAssignedUser) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "updateChatUserUnReadMessage":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails ))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages);
        } catch (error) {
            console.log("Error inside chat.service.ts:unAssignChatListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const unReadChatListUpdate = ({ directMessages, authData, contactDetails, chatId, index, eventType, selectedClientId, clientId,receivedMessageData,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId) {
                if (index === -1 && contactDetails) {
                    const checked: any = await conditionCheck({ authData, contactDetails })
                    if (checked) {
                        if (!contactDetails.dndStart &&( contactDetails.meta.unRead || (!contactDetails.meta.unRead && eventType === "message_create" && receivedMessageData?.type !== "banner"))) {
                            newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                    } else {
                        newDirectMessages = [...directMessages];
                    }
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else {
                if (index === -1 && contactDetails && ( contactDetails.meta.unRead ||  ( !contactDetails.meta.unRead &&  eventType === "message_create" && receivedMessageData?.type !== "banner"))) {
                    if (!contactDetails?.dndStart) {
                        newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                    } else {
                        newDirectMessages = [...directMessages];
                    }
                }else {
                    newDirectMessages = [...directMessages];
                }
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                const unRead = contactDetails.meta.unRead;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!unRead) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            newDirectMessages = [...directMessages];
                            if (!isAssignedUser && contactDetails.assingedUser !== undefined) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                                if (!unRead) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                if (!unRead) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (!unRead) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }

            resolve(newDirectMessages);
        } catch (error) {
            console.log("Error inside chat.service.ts:unReadChatListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const resolveChatListUpdate = ({ directMessages, authData, selectedFilter, contactDetails, chatId, index, eventType, selectedClientId, clientId,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId && index === -1 && contactDetails && contactDetails.markResolve) {
                const checked: any = await conditionCheck({ authData, contactDetails })
                if (checked) {
                    if (!contactDetails?.dndStart) {
                        newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                    } else {
                        newDirectMessages = [...directMessages];
                    }
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && !contactDetails.dndStart && contactDetails && contactDetails.markResolve) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                const markResolve = contactDetails.markResolve;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        console.log(`AR ~ file: chat.service.ts:590 ~ returnnewPromise<void> ~ unRead:`, markResolve)
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            newDirectMessages = [...directMessages];
                            if (!isAssignedUser && contactDetails.assingedUser !== undefined) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                if (!markResolve) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkResolve":
                        if (!markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")&& clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "updateChatUserUnReadMessage":
                        if (!markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (!markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "unRead":
                        if (!contactDetails.meta.unRead) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "Reset":
                        if (!markResolve) {
                            newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages);
        } catch (error) {
            console.error("Error inside chat.service.ts:resolveChatListUpdate function:", error);
            resolve(directMessages)
        }
    })
}

const dndChatListUpdate = ({ directMessages, authData, selectedFilter, contactDetails, chatId, index, eventType, selectedClientId, clientId,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (authData.parentId && index === -1 && contactDetails && contactDetails.dndStart) {
                const checked: any = await conditionCheck({ authData, contactDetails })
                if (checked) {
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && contactDetails.dndStart) {
                if (directMessages == undefined) {
                    newDirectMessages = []
                    newDirectMessages.push(contactDetails)
                } else {
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                }
            } else {
                newDirectMessages = [...directMessages];
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            newDirectMessages = [...directMessages];
                            if (!isAssignedUser && contactDetails.assingedUser !== undefined) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                // if (!isAssignedUser || ) {
                                //     newDirectMessages = await removeChatUserDetails({index,directMessages})
                                // }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers" ) && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "updateChatUserUnReadMessage":
                        if (index !== -1 && ((selectedClientId ===  clientId )|| ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages);
        } catch (error) {
            console.error("Error inside chat.service.ts:resolveChatListUpdate function:", error);
            resolve(directMessages)
        }
    })
}

const labelChatListUpdate = ({ directMessages, authData, selectedFilter, contactDetails, chatId, index, selectedLabelValue, eventType, selectedClientId, clientId,clientDetails }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            const labelExist = contactDetails?.labels?.some((item: any) => item._id === selectedLabelValue)
            if (authData.parentId && index === -1 && contactDetails && labelExist) {
                const isAssignedUser = contactDetails.assingedUser === authData.userId;
                const checked: any = await conditionCheck({ authData, contactDetails })
                if (checked) {
                    if (!contactDetails?.dndStart) {
                        newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                    } else {
                        newDirectMessages = [...directMessages];
                    }
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && !contactDetails.dndStart && labelExist) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }
            if (index >= 0 && index < directMessages.length) {
                const secureChats = authData?.accountDeatils?.secureChats;
                const onlyAssignUser = authData?.accountDeatils?.onlyAssignUser;
                switch (eventType) {
                    case "DND":
                        newDirectMessages = await removeChatUserDetails({ index, directMessages })
                        break;
                    case "Assign-User":
                        const isAssignedUser = contactDetails.assingedUser === authData.userId;
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!isAssignedUser) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            directMessages[index].assingedUserName = contactDetails.assingedUserName;
                            newDirectMessages = [...directMessages];
                            if (!isAssignedUser && contactDetails.assingedUser !== undefined) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            } else {
                                directMessages[index].assingedUserName = contactDetails.assingedUserName;
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "Label":
                        directMessages[index].assingedUserName = contactDetails.assingedUserName;
                        newDirectMessages = [...directMessages];
                        if (authData.parentId && secureChats && onlyAssignUser) {
                            if (!labelExist) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else if (authData.parentId && secureChats && !onlyAssignUser) {
                            if (!labelExist) {
                                newDirectMessages = await removeChatUserDetails({ index, directMessages })
                            }
                        } else {
                            if (authData.parentId) {
                                if (!labelExist) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                            } else {
                                if (!labelExist) {
                                    newDirectMessages = await removeChatUserDetails({ index, directMessages })
                                }
                                newDirectMessages = [...directMessages];
                            }
                        }
                        break;
                    case "MarkAsUnRead":
                        if (index !== -1 && ((selectedClientId ===  clientId )|| ((selectedClientId == "all_numbers") && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    case "resetChatUserUnReadMessages":
                        if (index !== -1 && (selectedClientId ===  clientId || ((selectedClientId == "all_numbers")  && clientDetails))) {
                            newDirectMessages = directMessages[index] = contactDetails
                        } else {
                            newDirectMessages = [...directMessages];
                        }
                        break;
                    default:
                        newDirectMessages = [...directMessages];
                        break;
                }
            }
            resolve(newDirectMessages);
        } catch (error) {
            console.error("Error inside chat.service.ts:resolveChatListUpdate function:", error);
            resolve(directMessages)
        }
    })
}

const mentionChatsChatListUpdate = ({ directMessages, authData, selectedFilter, contactDetails, chatId, index, eventType, selectedClientId, clientId ,clientDetails}: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            const mentionExist = contactDetails?.mentionUsers?.some((item: any) => item.userId === authData.userId && item.clientId === clientId )
            if (authData.parentId && index === -1 && contactDetails && !contactDetails.dndStart) {
                const checked: any = await conditionCheck({ authData, contactDetails });
                if (checked && mentionExist) {
                    newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
                } else {
                    newDirectMessages = [...directMessages];
                }
            } else if (!authData.parentId && index === -1 && contactDetails && !contactDetails.dndStart && mentionExist) {
                newDirectMessages = await addChatUserDetails({ contactDetails, directMessages, selectedClientId, clientId,clientDetails });
            } else {
                newDirectMessages = [...directMessages];
            }
            resolve(newDirectMessages)
        } catch (error) {
            console.log("Error inside chat.service.ts:mentionChatsChatListUpdate function :", error)
            resolve(directMessages)
        }
    })
}

const addChatUserDetails = ({ contactDetails, directMessages, selectedClientId ,clientId,clientDetails }: any) => {
    return new Promise<void | any>((resolve, reject) => {
        try {
            let newDirectMessages: any;
            if (directMessages == undefined) {
                newDirectMessages = []
                // if( selectedClientId ===  clientId|| selectedClientId == "all_numbers"){
                if((clientId == selectedClientId) || (selectedClientId == "all_numbers" && clientDetails)){
                    newDirectMessages.push(contactDetails)
                }
            } else {
                // if( selectedClientId ===  clientId|| selectedClientId == "all_numbers"){
                if((clientId == selectedClientId) || (selectedClientId == "all_numbers" && clientDetails)){

                    newDirectMessages = [contactDetails, ...directMessages];
                }else {
                    newDirectMessages = [...directMessages];
                }
            }
            resolve(newDirectMessages)
        } catch (error) {
            console.error("Error inside chat.service : addChatUserDetails function", error);
            resolve(directMessages)
        }
    })
}

const conditionCheck = ({ contactDetails, authData }: any) => {
    return new Promise<void | any>((resolve, reject) => {
        try {            
            const isAssignedUser = contactDetails.assingedUser === authData.userId;
            if ((authData.accountDeatils.secureChats && ((authData.accountDeatils.onlyAssignUser && contactDetails.assingedUser === authData.userId) || (!authData.accountDeatils.onlyAssignUser && (isAssignedUser || contactDetails.assingedUser === undefined)))) || !authData.accountDeatils.secureChats) {
                resolve(true)
            } else resolve(false)
        } catch (error) {
            console.error("Error inside chat.service : conditionCheck function", error);
            resolve(false)            
        }
    })
}

const removeChatUserDetails = ({ index, directMessages }: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let newDirectMessages: any;
            await directMessages.splice(index, 1);
            newDirectMessages = directMessages
            resolve(newDirectMessages)
        } catch (error) {
            console.error("Error inside chat.service : removeChatUserDetails function", error);
            resolve(directMessages)
        }
    })
}