import { ChangePwdActionTypes, AuthForgetPassState } from "./types";

export const INIT_STATE: AuthForgetPassState = {
  loading:false,
  changePwdSuccessMsg: "",
  changePwdErrorMsg: "",
};

const ChangePassword = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ChangePwdActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChangePwdActionTypes.CHANGE_USER_PASSWORD:
          return {
            ...state,
            loading:false,
            changePwdSuccessMsg: action.payload.data,
            changePwdErrorMsg: action.payload.data,
            changepasswordSuccess: action.payload.data.success,
          };
        default:
          return { ...state };
      }
      case ChangePwdActionTypes.API_RESPONSE_ERROR:
        switch (action.payload.actionType) {      
          case ChangePwdActionTypes.CHANGE_USER_PASSWORD: {
            return {
              ...state,
              changepasswordError: action.payload.error,
              passwordChanged: false,
              loading: true,
              changepasswordSuccess: true,
            };
          }
          default:
            return { ...state };
        }


    case ChangePwdActionTypes.CHANGE_USER_PASSWORD: {
      return {
        ...state,
        loading: true,
        changePwdSuccessMsg: "",
        changePwdErrorMsg: "",
        changepasswordSuccess: true
      };
    }
    default:
      return { ...state };
  }
};

export default ChangePassword;
