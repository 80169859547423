import React, { useEffect, useState,useRef } from "react";
import { Button, Form, Input,UncontrolledTooltip } from "reactstrap";
import { ChatIndex } from '../Chats/ChatIndex.style';
import { toast } from "react-toastify";
import MultipleNumberFilter from "../Chats/multipleNumberFilter";
import { useRedux,useProfile } from "../../../hooks/index";
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications";
import  profile3  from "../../../../src/assets/images/users/avatar-3.jpg";
import  profile2 from "../../../../src/assets/images/users/avatar-2.jpg";
import  profile1  from "../../../../src/assets/images/users/avatar-1.jpg";
import  profile10  from "../../../../src/assets/images/users/avatar-10.jpg";

import {
  inviteContact,
  resetContacts,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  changeSelectedChat,
  getChatUserDetails,
  getChatUserConversations,
  getChannelDetails,
  updateClientSession,
  CreateChannelPostData,
  createUser
} from "../../../redux/actions";

import AppSimpleBar from "../../../components/AppSimpleBar";
import AddGroupModal from "../../../components/AddGroupModal";
import InviteContactModal from "../../../components/InviteContactModal";
import ContactModal from "../../../components/ContactModal";
import  AddButton from "../../../components/AddButton";
import DirectMessages from "../Chats/DirectMessages";
import { CHATS_TABS } from "../../../constants";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
interface IndexProps {}
const DummyDashboard = (props: IndexProps) => {
  const { dispatch, useAppSelector } = useRedux();
//   const [chatUserList , setChatuserList] = useState([]);

const chatUserList = [
    {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    "profilePic": profile1,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Alacia Ciara",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  },
    {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    "profilePic": profile2,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Geo lienn",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  },
    {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    "profilePic": profile3,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Nancy willi",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  },
  {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    "profilePic": profile10,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Emma Stone",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  },
  {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    // "profilePic": profile10,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Company Group",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  },
  {
    "_id": "64d0caf64b09b69c0c814446",
    "chatId": "120363165134975206@g.us",
    "clientId": "64c0f7a4b4ba58501c8d9275_6461",
    // "profilePic": profile10,
    "__v": 0,
    "createdAt": "2023-08-07T10:44:06.631Z",
    "firstName": "Family Group 2",
    "id": "120363165134975206@g.us",
    "isBlocked": false,
    "isGroup": true,
    "lastName": "",
    "markResolve": false,
    "meta": {
        "unRead": 0
    },
    "name": "5555555555",
    "parentId": "64c0f7a4b4ba58501c8d9275",
    "pushname": "120363165134975206",
    "server": "g.us",
    "shortName": "",
    "timestamp": 1691405039,
    "updatedAt": "2023-08-08T07:02:28.376Z",
    "user": "120363165134975206",
    "groupMetadata": {
        "id": {
            "server": "g.us",
            "user": "120363165134975206",
            "_serialized": "120363165134975206@g.us"
        },
        "creation": 1691405039,
        "owner": {
            "server": "c.us",
            "user": "919157130961",
            "_serialized": "919157130961@c.us"
        },
        "subject": "5555555555",
        "subjectTime": 1691405039,
        "restrict": false,
        "announce": false,
        "noFrequentlyForwarded": false,
        "ephemeralDuration": 0,
        "membershipApprovalMode": false,
        "size": 3,
        "support": false,
        "suspended": false,
        "terminated": false,
        "uniqueShortNameMap": {},
        "isLidAddressingMode": false,
        "isParentGroup": false,
        "isParentGroupClosed": false,
        "defaultSubgroup": false,
        "allowNonAdminSubGroupCreation": false,
        "incognito": false,
        "participants": [
            {
                "id": {
                    "server": "c.us",
                    "user": "919106411523",
                    "_serialized": "919106411523@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919157130961",
                    "_serialized": "919157130961@c.us"
                },
                "isAdmin": true,
                "isSuperAdmin": true
            },
            {
                "id": {
                    "server": "c.us",
                    "user": "919937603907",
                    "_serialized": "919937603907@c.us"
                },
                "isAdmin": false,
                "isSuperAdmin": false
            }
        ],
        "pendingParticipants": [],
        "pastParticipants": [],
        "membershipApprovalRequests": [],
        "subgroupSuggestions": []
    },
    "isMuted": false,
    "muteExpiration": 0,
    "pinned": false,
    "unreadCount": 0,
    "lastMessageAt": "2023-08-07T10:44:12.261Z"
  }]
  const [limit] = useState(50);
  const {userProfile} = useProfile();
  const [clientDisconnected, setClientDisconnected] = useState(false)
  
  const {
    isContactInvited,
    directMessages,
    createdUser,
    isChannelCreated,
    selectedChat,
    clientId,
    receivedMessage,
    // clientDisconnected,
    clientList,
    isChatUsersSyncing,
    getChatList,
    errorMsg,
    unReadBadge
  } = useAppSelector(state => {
    return ({
    isContactInvited: state.Contacts.isContactInvited,
    directMessages: state.Chats.directMessages,
    createdUser:state.Chats.createdUser,
    errorMsg:state.Chats.errorMsg,
    getChatList: state.Chats.skipChatUserList,
    isChannelCreated: state.Chats.isChannelCreated,
    selectedChat: state.Chats.selectedChat,
    receivedMessage: state.Chats.receivedMessage,
    clientId: state.Chats.clientId,
    // clientDisconnected: state.Chats.clientDisconnected,
    clientList: state.Chats.clientList,
    isChatUsersSyncing: state.Chats.isChatUsersSyncing,
    clientName: state.Chats.clientName,
    // clienPhoneNumber: state.Chats.clienPhoneNumber,
    unReadBadge: state.Chats.unReadBadge,
  })});


  useEffect(() => {
    if(createdUser?.clientId){
    dispatch(getChatUserDetails(createdUser.id,createdUser.clientId));
    dispatch(getChatUserConversations(createdUser.id,createdUser.clientId));
    dispatch(changeSelectedChat(createdUser.id));
    }else{
      // toast.error(errorMsg)  
    }
  }, [createdUser])

//   useEffect(() => {
//     if (userProfile?.clientId) {
//       if(!directMessages.length)dispatch(getDirectMessages(userProfile.clientId, 0, limit))
//       dispatch(updateClientSession(userProfile.clientId));
//     }
//   }, []);

  useEffect(() => {
    let clientlistData: any;
    if (clientList && userProfile?.clientId) {
      clientlistData = clientList.find((item: any) => item.clientId === userProfile?.clientId);
    }
    if (clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)) {
      setClientDisconnected(false)
    } else if (clientlistData) {
      setClientDisconnected(true)
    }
  }, [clientList, userProfile?.clientId])

  useEffect(()=>{
    if(getChatList.hasNext)onScrollGetChatList(true)

  },[getChatList])

  
  useEffect(() => {
    if (directMessages) {
      //setChatuserList(directMessages)
    }
  }, [dispatch, directMessages]);

  useEffect(() => {
    if (receivedMessage && (receivedMessage.clientId == userProfile?.clientId) ) {
      // dispatch(getQrCode(inputText));
      console.log(`receivedMessage called`)

      // return
      const chatExist = receivedMessage.data && receivedMessage.data.chatId && directMessages?.findIndex((item :any) =>( item.chatId && (item.chatId === receivedMessage.data.chatId)));
      if (chatExist !== -1  && receivedMessage.data.chatId && (receivedMessage.clientId == userProfile?.clientId)) {
        const itemToShift = directMessages[chatExist];
        directMessages.splice(chatExist, 1); // Remove the item from its current position
        directMessages.unshift(itemToShift); // Add the item to the first position

        if ( (selectedChat !== receivedMessage.data.chatId) && !receivedMessage.data.fromMe) {
          const selectChatExist =directMessages?.findIndex((item :any) =>( item.chatId && (item.chatId === receivedMessage.data.chatId)));
          if(selectChatExist !== -1 ){  
            if (!directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 1 };
            } else {
              directMessages[selectChatExist].meta.unRead = directMessages[selectChatExist].meta.unRead ? directMessages[selectChatExist].meta.unRead + 1 : 1;
            }
          }
        } else  if(receivedMessage.data.fromMe){
          const selectChatExist = directMessages?.findIndex((item :any) => item.chatId === receivedMessage.data.chatId);
          if(selectChatExist !== -1 ){
            if (directMessages[selectChatExist].meta) {
              directMessages[selectChatExist].meta = { unRead: 0 };
            }
          }
        }
        //setChatuserList(directMessages)
      }
    }
  }, [receivedMessage]);
  useEffect(() => {
    if (unReadBadge) {
      console.log(`receivedMessage called`)
      const chatExist = unReadBadge.data && unReadBadge.data.chatId&& directMessages?.findIndex((item :any) =>( item.chatId && (item.chatId === unReadBadge.data.chatId)));
      if (chatExist !== -1  && unReadBadge.data && unReadBadge.data.chatId && unReadBadge.data.chatId && (unReadBadge.clientId == userProfile?.clientId)) {
        if ( (selectedChat !== unReadBadge.data.chatId) && !unReadBadge.data.fromMe) {
          const selectChatExist = directMessages?.findIndex((item :any) =>( item.chatId && (item.chatId === unReadBadge.data.chatId)));
          if(selectChatExist !== -1 ){
            if (directMessages[selectChatExist].meta && directMessages[selectChatExist].meta.unRead ) {
              directMessages[selectChatExist].meta.unRead = 0;
            }
          }
        } 
        //setChatuserList(directMessages)
      }
    }
  }, [unReadBadge]);

  /*
  invite contact modal handeling
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data: any,clientId:string) => {

    dispatch(createUser({chatId:data.countryCode+data.phoneNumber+"@c.us",clientId:clientId,data},(response:any)=>{
      if(!response?.success){
        showErrorNotification(response.message)
      }
    }))
    // dispatch(inviteContact(data,clientId));
  };
  useEffect(() => {
    if (isContactInvited) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetContacts("isContactInvited", false));
      }, 1000);
    }
  }, [dispatch, isContactInvited]);

  /*
  contact add handeling
  */
  const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  const openAddContactModal = () => {
    setIsOpenAddContact(true);
  };
  const closeAddContactModal = () => {
    setIsOpenAddContact(false);
  };
  const onAddContact = (contacts: Array<string | number>) => {
    dispatch(addContacts(contacts));
  };
  // useEffect(() => {
  //   if (isContactsAdded) {
  //     setIsOpenAddContact(false);
  //     dispatch(getDirectMessages(clientId));
  //   }
  // }, [dispatch, isContactsAdded,clientId]);

  /*
  channel creation handeling
  */
  const [isOpenCreateChannel, setIsOpenCreateChannel] =
    useState<boolean>(false);
  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };
  const closeCreateChannelModal = () => {
    setIsOpenCreateChannel(false);
  };
  const onCreateChannel = (channelData: CreateChannelPostData) => {
    dispatch(createChannel(channelData));
  };
  useEffect(() => {
    if (isChannelCreated) {
      setIsOpenCreateChannel(false);
      dispatch(getChannels());
    }
  }, [dispatch, isChannelCreated]);

  /*
  select chat handeling :
    get conversations
    get chat user details
  */

  const onSelectChat = (id: string | number,clientId: string | number, isChannel?: boolean) => {
    clientId = clientId ?clientId: userProfile?.clientId
    if (isChannel) {
      dispatch(getChannelDetails(id));
    } else {
      dispatch(getChatUserDetails(id,clientId));
    }
    // dispatch(readConversation(id));
    if (id !== selectedChat) {
      dispatch(changeSelectedChat(id));
      dispatch(getChatUserConversations(id, clientId));
    }
    addMixpanelEvent("LastSeenAt",{distinct_id:userProfile?.userId,LastSeenAt:new Date})
  const selectChatExist = directMessages.length && directMessages?.findIndex((item :any) => item.chatId === id);
    if(selectChatExist !== -1 ){
    //   if (directMessages[selectChatExist].meta) {
    //     directMessages[selectChatExist].meta = { unRead: 0 };
    //   }
    }
   // setChatuserList(directMessages)
  };

  /*
  tab handeling
  */
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const onChangeTab = (tab: CHATS_TABS) => {
    setActive(tab);
  };

  /*
  archive contacts
  */
  // useEffect(() => {
  //   dispatch(getArchiveContact());
  // }, [dispatch]);
  // useEffect(() => {
  //   if (isContactArchiveToggled) {
  //     // dispatch(getArchiveContact());
  //     // dispatch(getFavourites());
  //     // dispatch(getDirectMessages(clientId));
  //     // dispatch(getChannels());
  //     dispatch(getChatUserDetails(chatUserDetails.id));
  //   }
  // }, [dispatch, isContactArchiveToggled,clientId, chatUserDetails.id]);

  //serach recent user
  const searchUsers = () => {
    var li, a, i, txtValue: any;
    const inputValue: any = document.getElementById("serachChatUser");
    const filter: any = inputValue.value.toUpperCase();
    const ul: any = document.querySelector(".chat-room-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const onScrollGetChatList = (value:any) => {
    // if(!isChatUsersSyncing)dispatch(getDirectMessages(clientId,getChatList.skip+1,limit,"unresolveChat", null))
  }
  const chatListRef = useRef(null);
  const setClientConnection= (value :any) => {
    setClientDisconnected(value)
  }
  // const setClientPhone= (value :any) => {
    //   setclientPhoneNumber(value)
    // }
    
    useEffect(()=>{
      if(clientList && clientId){
        let clientlistData:any =  clientList.find((item :any) => item.clientId === clientId);
        if(clientlistData && (clientlistData.isReady || clientlistData.isSessionSaved)){ 
          setClientDisconnected(false)
        }else if (clientlistData){
          setClientDisconnected(true)
        }
      }
    },[clientList,clientId])
  
    useEffect(()=>{
      if(createdUser && createdUser?.clientId){
        if(isOpen){
          closeModal()
          setTimeout(() => {
            showSuccessNotification({message:"Contact creation successful."})
          }, 1000);
        }
      }
    },[createdUser])
  return (
    <>
      <ChatIndex>
        <div className="">
        <div className="mobStickyChatSidebar">
        <div className="px-4 pt-md-4 pt-3">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1  d-flex">
              <h4>Chats</h4>
            </div>
            <div className="flex-shrink-0">
              <div id="add-contact" className="mb-lg-3 mb-2">
              {/* Button trigger modal */}
              <AddButton onClick={openModal} />
              </div>
              <UncontrolledTooltip target="add-contact" placement="left">
                Create Chat
              </UncontrolledTooltip>
            </div>
          </div>
          <Form>
            <div className="input-group mb-3 align-center">
              <Input
                onKeyUp={searchUsers}
                id="serachChatUser"
                type="text"
                className="form-control bg-light border-0 pe-0"
                placeholder="Search here.."
              />
              <Button color="light" type="button" id="searchbtn-addon">
                <i className="bx bx-search align-middle"></i>
              </Button>
              </div>
          </Form>
          {/* <MultipleNumberFilter setClientConnection={setClientConnection} setClientPhone={setClientPhone} clientId={userProfile.clientId}/> */}
        </div>
        {/* .p-4 */}
        {clientId ? <>
        { clientDisconnected ?
          <div className="px-3 d-flex align-items-center logged_out_main">
            <div className="d-flex align-items-center justify-content-center circle_status_icon">
                <a href="/welcome" id="welcomeLink">
                  <i className='bx bx-plus'></i>
                  <UncontrolledTooltip placement="right" target={"welcomeLink"}
                    autohide={true}
                    flip={true}
                  >
                    <span>Rescan WhatsApp</span>
                  </UncontrolledTooltip>
                </a>
            </div>
            <div className="width_connection_main">
              <span className="connection_status ms-2">Connection Status: </span> 
              <a className="online_status colorRed"> Logged Out</a>
              {/* <div className="d-flex align-items-center justify-content-between"><a href="/welcome" className="logged_out_text">{clientPhoneNumber}</a></div> */}
              <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId}/>
            </div>
          </div>
        :
          <div className="px-2 d-flex align-items-center logged_out_main logged_in_main">
            <div className="d-flex align-items-center justify-content-center circle_status_icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22" style={{fill: '#0029c7'}}><path d="m320-80 40-280H160l360-520h80l-40 320h240L400-80h-80Z"/></svg>
            </div>
            <div className="width_connection_main">
              <span className="connection_status">Connection Status:</span>
              <a className="online_status"> Online</a>
              {/* <div className="d-flex align-items-center justify-content-between"><a href="/welcome" className="logged_in_text">{clientPhoneNumber}</a></div> */}
              <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId}/>
            </div>
          </div>
        }
        </> : <> <div className="px-2 d-flex align-items-center logged_out_main logged_in_main">
          <div className="d-flex align-items-center justify-content-center">
            <MultipleNumberFilter setClientConnection={setClientConnection} clientId={userProfile?.clientId} />
          </div>
          <div className="d-flex align-items-center justify-content-between"><span>&nbsp;&nbsp;&nbsp;&nbsp;Add Channel</span></div>
        </div></>}
        </div>
        <AppSimpleBar className="chat-room-list chatRoomListMain chatTourVisit">
        {/* <AppSimpleBar style={{ maxHeight: 700 }} className="chat-room-list" scrollRef={chatListRef} onScrollGetEvent={onScrollGetChatList}> */}
          {/* Start chat-message-list */}
          {active === CHATS_TABS.DEFAULT && (
            <>
              {/* favourite */}
              {/* <Favourites
                users={favourites}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              /> */}

              {/* direct messages */}
              <DirectMessages
                users={chatUserList}
                clientId = {clientId}
                openAddContact={openAddContactModal}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
                isChatUsersSyncing={isChatUsersSyncing}
                clientDisconnected={clientDisconnected}
                // onScrollGetChatList={onScrollGetChatList}
              />

              {/* channels list */}
              {/* <Chanels
                channels={channels}
                openCreateChannel={openCreateChannelModal}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              /> */}
              {/* <h5 className="text-center mb-2">
                <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                >
                  Archived Contacts{" "}
                  <i className="bx bxs-archive-in align-middle" />
                </Link>
              </h5> */}
            </>
          )}
          {/* {active === CHATS_TABS.ARCHIVE && (
            <>
              <Archive
                archiveContacts={archiveContacts}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
              <h5 className="text-center mb-2">
                <Link
                  to="#"
                  className="mb-3 px-4 mt-4 font-size-11 text-primary"
                  onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                >
                  Chats <i className="bx bxs-archive-out align-middle" />
                </Link>
              </h5>
            </>
          )} */}

          {/* End chat-message-list */}
        </AppSimpleBar>
        </div>
      </ChatIndex>
      {/* add group Modal */}
      {isOpenCreateChannel && (
        <AddGroupModal
          isOpen={isOpenCreateChannel}
          onClose={closeCreateChannelModal}
          onCreateChannel={onCreateChannel}
          diffModal=""
          groupId= ""
        />
      )}

      {/* add contact modal */}
      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={onInviteContact}
        />
      )}

      {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={onAddContact}
        />
      )}
    </>
  );
};

export default DummyDashboard;
