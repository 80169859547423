import React, { useState, useEffect } from "react";
import { List, Skeleton, Avatar, Popover, Button, Badge, Switch, Space } from 'antd';
import {UncontrolledTooltip } from "reactstrap";

import AddGroupModal from "../../../components/AddGroupModal";
import { CreateChannelPostData, createUser, makeAsAdmin, dismissAsAdmin, removeParticipants, getChatUserDetails, syncingStopToCrm, getLabels, downloadChat } from "../../../redux/actions";
import { useRedux, useProfile } from "../../../hooks/index";
import avatar1 from "../../../../src/assets/images/user_theme.svg";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import {  changeAssignUser, getChatUserConversations, updateChatUserDetails } from "../../../redux/actions";
import CustomSelect from "../../../components/CreatableSelect";
import { checkPlanAndFeature } from "../../../helpers/common.service";
import UserProfile from "../Settings/UserProfile";
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';

const isNumeric = /^\d+$/;
interface BasicDetailsProps {
  chatUserDetails: any;
}

interface User {
  _id: string;
  email: string;
  fullName: string;
  assingedUserName: string;
  // Add other properties as needed
}

const count = 10;
const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat,picture&noinfo`;
const BasicDetails = ({ chatUserDetails }: BasicDetailsProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isOpenCreateChannel, setIsOpenCreateChannel] = useState<boolean>(false);
  const [userLists, setUserLists] = useState<User[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // Use User | null as the type
  const [labelValues, setLabelValues] = useState<any>(null);
  const { userProfile } = useProfile();
  const [membersList, setmembersList] = useState<[any]|[]>([])
  // const { assignUserList, chatUserDetails1, labels,clientId } = useAppSelector(state => ({
  const { assignUserList, chatUserDetails1, labels,clientId ,getChatUserConversations } = useAppSelector(state => ({
    assignUserList: state.Chats.assignUserList,
    chatUserDetails1: state.Chats.chatUserDetails,
    getChatUserConversations: state.Chats.getChatUserConversations,
    labels: state.Chats.labels,
    clientId: state.Chats.clientId,
  }));
  useEffect(() => {
    if(chatUserDetails?.participants?.length){
      const members = chatUserDetails?.participants?.map((data: any) => {
        return {
          groupId: chatUserDetails?.chatId,
          clientId: chatUserDetails?.clientId,
          participantId: data.id.chatId,
          isAdmin: data.isAdmin,
          isSuperAdmin: data.isSuperAdmin,
          profilePic: data.id.profilePic,
          firstName: data.id.firstName,
          verifiedName: data.id.verifiedName,
          phoneNumber: data.id.user,
          displayPhoneNumber: data.displayPhoneNumber
        };
      });
      setmembersList(members)
    }
  }, [])
  
  useEffect(() => {
    if (assignUserList) {
      setUserLists(assignUserList)
    }
  }, [assignUserList])

  useEffect(() => {
    if (chatUserDetails1?.assingedUserName) {
      setSelectedUser(chatUserDetails1)
    } else {
      setSelectedUser(null)
    }
    if(chatUserDetails1?.participants?.length){
      const members = chatUserDetails1?.participants?.map((data: any) => {
        return {
          groupId: chatUserDetails1?.chatId,
          clientId: chatUserDetails1?.clientId,
          participantId: data.id.chatId,
          isAdmin: data.isAdmin,
          isSuperAdmin: data.isSuperAdmin,
          profilePic: data.id.profilePic,
          firstName: data.id.firstName,
          verifiedName: data.id.verifiedName,
          phoneNumber: data.id.user,
          displayPhoneNumber: data.displayPhoneNumber
        };
      });
      setmembersList(members)
    }
  }, [chatUserDetails1])

  useEffect(() => {

    if(chatUserDetails1?.labels &&  chatUserDetails1?.labels.length){
      var labels = JSON.stringify(chatUserDetails1.labels).replaceAll("\"_id\":", "\"value\":")
      labels = labels.replaceAll("\"displayName\":", "\"label\":")
      setLabelValues(JSON.parse(labels))
    }else{
      setLabelValues([])
    }
    if(chatUserDetails1?.assingedUserName){
      setSelectedUser(chatUserDetails1)
    }else{
      setSelectedUser(null)
    }
  }, [chatUserDetails1])

  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };

  const closeCreateChannelModal = () => {
    setIsOpenCreateChannel(false);
  };

  const onCreateChannel = (channelData: CreateChannelPostData) => {
    // dispatch(createChannel(channelData));
  };
  const handleMakeGroupAdmin = (item: any) => {
    const payLoad = {
      groupId: item.groupId,
      participantChatId: [item.participantId],
      clientId: item.clientId
    }
    if (!item.isAdmin) {
      dispatch(makeAsAdmin(payLoad))
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 6500);
    } else {
      dispatch(dismissAsAdmin(payLoad))
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 6500);
    }
  };

  const handleRemoveFromGroup = (item: any) => {
    const payLoad = {
      groupId: item.groupId,
      participantChatId: [item.participantId],
      clientId: item.clientId
    }
    if (!item.isAdmin) {
      dispatch(removeParticipants(payLoad))
      setLoading1(true)
      setTimeout(() => {
        setLoading1(false)
      }, 6500);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handleParticipantsClick = () => {
    openCreateChannelModal();
    setCurrentPage(1); // Reset to the first page when the Participants button is clicked
  };

  const toggleDropdown = () => {
    if(checkPlanAndFeature({featureName:"assign_chats", planDetails:userProfile?.planDetails,featureDisplayName : "Assign Chat"}))
    setDropdownOpen(!dropdownOpen);
  };

  const handleUserSelect = (userId1: any) => {
    setLoading(true)
    const selectedUser = userLists.find((profile: any) => profile._id === userId1);
    const client = clientId === "all_numbers" ? getChatUserConversations?.length ? getChatUserConversations[getChatUserConversations.length-1].clientId : chatUserDetails.clientId  : clientId
    if (selectedUser) {
      setSelectedUser(selectedUser);
      const payLoadData = {
        userId: selectedUser._id,
        chatId: chatUserDetails?.chatId,
        clientId: client,
        email: selectedUser.email,
        fullName: selectedUser.fullName,
        type : "banner",
        event : "Assign-User"
      };
      dispatch(changeAssignUser(payLoadData));
      setLoading(false)
    } else {
      const payLoadData = {
        chatId: chatUserDetails?.chatId,
        clientId: client,
        type : "banner",
        event : "Assign-User"
      };
      dispatch(changeAssignUser(payLoadData));
      setLoading(false)
    }
  };

  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setLabelValues(newValue);
    onUpdateUserDetails("Label",newValue)
  };

  const onUpdateUserDetails = (eventType:string,data:any)=> {
    let id = chatUserDetails?._id
    const client = clientId !== "all_numbers" ? clientId : getChatUserConversations ?getChatUserConversations[getChatUserConversations.length - 1].clientId : chatUserDetails?.clientId
    const chatId = chatUserDetails?.chatId
    const chatName = chatUserDetails?.firstName
    dispatch(updateChatUserDetails({id,clientId:client,chatId,eventType,data, chatName}))
    if(eventType == "Label")dispatch(getLabels())
  }

  useEffect(() => {
    dispatch(getLabels());
    // dispatch(getChatUserDetails(chatUserDetails?.chatId, chatUserDetails?.clientId));
  }, [])

  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const formatPhoneNumber = (phoneNumber: PhoneNumber): string => {
    const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    return formattedNumber.replace(/ /g, ' '); // Add space after each digit
  };
  const phoneNumber = "+" + chatUserDetails?.user;
  let decodedNumber: string | null = null;
  let number: PhoneNumber | undefined;
    if (phoneNumber && !chatUserDetails?.isGroup) {
      try {
        number = phoneNumberUtil.parse(phoneNumber);
        decodedNumber = formatPhoneNumber(number);
      } catch (e) {
        console.error('Error parsing phone number:2', phoneNumber);
        decodedNumber = phoneNumber
      }
    }
  
    const getFromttedNumber = (number: any) => {
      const tempNumber = "+"+number
      if (tempNumber) {
        try {
          number = phoneNumberUtil.parse(tempNumber);
          decodedNumber = formatPhoneNumber(number);
        } catch (e) {
          console.error('Error parsing phone number:4', phoneNumber);
          decodedNumber = phoneNumber
        }
        return decodedNumber
      }
    }
  
  return (
    <>
    {!chatUserDetails?.isGroup &&
    <> 
      {/* <div className="mb-3">
        <span className="lableProf font-size-12">Email :</span>
        <div className="py-1 px-2 inputBorder">
          <span className="font-size-12 align_colon_content_span"> example.com </span>
        </div>
      </div> */}
    </>}
    <div className="mb-3">
        <span className="assignTolbl text-nowrap font-size-12 lableProf">Assign To :</span>
        <Dropdown className="profileAssignChatDrpDwn d-flex align-items-center w-100" loading={loading} isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle className={`${selectedUser ? 'assinedUsrSelected' : 'unAssinedUsrSelected'} text-truncate`} caret>{selectedUser ? selectedUser.assingedUserName : "Unassigned"}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleUserSelect("")} >Unassign</DropdownItem>
            {userLists?.map((user: any) => {
              return (
                <>
                  <DropdownItem
                    key={user._id}
                    onClick={() => handleUserSelect(user._id)}
                    className="text-truncate"
                  >
                    {user?.fullName}
                  </DropdownItem>
                </>
              )
            })}
          </DropdownMenu>
          {/* {selectedUser?.assingedUserName && <Loader />} */}
        </Dropdown>
      </div>
      <div className="profileLableDropDwn mb-3">
          <div className="">
            <span className="fw-normal lableProf font-size-12">Labels :</span>
            <div className="w-100">
              <CustomSelect
              value={labelValues}
              onChange={handleSelectChange}
              options={(labels?.length ? labels : [])}
              isMulti={true}
            />
            </div>
          </div>
      </div>

      {chatUserDetails?.crmEmail &&
        <div className="mb-3">
          <span className="lableProf font-size-12">CRM-Email :</span>
          <div className="py-1 px-2 inputBorder">
            <span className="font-size-12 align_colon_content_span"> {chatUserDetails?.crmEmail} </span>
          </div>
        </div>
      }

      {chatUserDetails?.crmCompanyName &&
        <div className="mb-3">
          <span className="lableProf font-size-12">CRM-CompanyName :</span>
          <div className="py-1 px-2 inputBorder">
            <span className="font-size-12 align_colon_content_span"> {chatUserDetails?.crmCompanyName} </span>
          </div>
        </div>
      }

    <div className=" d-flex align-items-center gap-2 mb-3">
        {/* <div className="form-check form-switch p-0">
          <input
            type="checkbox"
            className="form-check-input synchronizeToCRMCls"
            id="synchronizeToCRM"
            checked={!chatUserDetails?.syncingChatStopToCrm}
            onChange={(e: any) => {
              handleSwitchChange(e.target.checked);
            }}
          />
          <label
            className="form-check-label"
            htmlFor="synchronizeToCRM"
          ></label>
        </div> */}
        <Switch
          className="ch_toggle_switch" 
          checked={!chatUserDetails?.syncingChatStopToCrm}
          onChange={(checked: boolean) => {
            onUpdateUserDetails("SyncingChatStopToCrm",{syncingChatStopToCrm:!checked})
          }}
        />
        <p className="align_colon_content font-size-12 d-flex align-items-center gap-1 lh-sm">
          Sync To CRM 
          <a id="synchronizeLink" href="https://support.controlhippo.com/support/solutions/articles/1060000057560-how-to-stop-individual-chat-sync-with-crms-" target="_blank">
            <i className="bx bxs-info-circle font-size-16 lh-sm"></i>  
          </a>
          <UncontrolledTooltip
              target={`synchronizeLink`}
              placement="right"
          >
              Click here for know more..
          </UncontrolledTooltip></p>
        
    </div>
    <div className=" d-flex align-items-center gap-2 mb-3">
      <Switch
        className="ch_toggle_switch" 
        checked={chatUserDetails?.dndStart == undefined ? false : chatUserDetails?.dndStart}
        onChange={(checked: boolean) => {
          if(checkPlanAndFeature({featureName :"dnd", planDetails:userProfile?.planDetails,featureDisplayName:"DND"}))
          onUpdateUserDetails("DND",{dndStart:checked})
        }} />
      <p className="align_colon_content font-size-12 d-flex align-items-center gap-1 lh-sm">
        <div className="d-flex align-items-center gap-1 font-size-12">
          DND
          <a id="dndFeature">
            <i className="bx bxs-info-circle font-size-15 lh-sm"></i>  
          </a>
        </div>
        <UncontrolledTooltip
            target={`dndFeature`}
            placement="right"
        >
            Do Not Disturb 
        </UncontrolledTooltip></p>
    </div>
      
      {chatUserDetails?.isGroup &&
        <div>
          <Button className="p-2 font-size-14 d-flex justify-content-center align-items-center mt-3 mb-2 addParticipantsBtn btn btn-outline-secondary gap-1" onClick={handleParticipantsClick}>
            <i className="bx bx-plus-circle font-size-15 lh-sm"></i>
            Add Participants
          </Button>
          Members - {membersList?.length}
          
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={membersList}
            renderItem={(item: any) => {
              const popoverContent = (
                <div className='popover_main_div'>
                  <Button className="mb-1" loading={loading} onClick={() => handleMakeGroupAdmin(item)}>{!item.isAdmin ? "Make as Group Admin" : "Dismiss as Admin"}</Button>
                  {!item?.isAdmin && <Button loading={loading1} onClick={() => handleRemoveFromGroup(item)}>Remove from Group</Button>}
                </div>
              );
              return (
                <>
                  <List.Item className="py-2">
                    <Skeleton avatar title={false} loading={item.loading} active>
                      <List.Item.Meta
                        avatar={<Avatar src={item?.profilePic ? item?.profilePic : avatar1} />}
                        title={
                          <div>
                            {item.verifiedName ? item.verifiedName : item.firstName ? item.firstName : item.phoneNumber}
                            {item?.isAdmin && (
                              <Badge color="#87d068" text="Group Admin" style={{ marginLeft: 8 }} />
                            )}
                            <br/>
                            {(!isNumeric.test(item?.firstName)) && getFromttedNumber(item.displayPhoneNumber)}
                          </div>
                        }
                      />
                      <div className="custom_popover_main_div">
                        <div className="popover-wrapper">
                          <Popover content={popoverContent} trigger="click" overlayClassName="custom_popover" getPopupContainer={(trigger:any) => trigger.parentNode} >
                            {!item.isSuperAdmin && <div className="ellipsis-button three_dots_main"></div>}
                          </Popover>
                        </div>
                      </div>
                    </Skeleton>
                  </List.Item>
                </>
              );

            }}
          />
          {isOpenCreateChannel && (
            <AddGroupModal
              isOpen={isOpenCreateChannel}
              onClose={closeCreateChannelModal}
              onCreateChannel={onCreateChannel}
              diffModal={"participatns"}
              groupId={chatUserDetails?.chatId}
            />
          )}
        </div>
      }
      
    </>
  );
};
export default BasicDetails;
