import React from "react";
import { Button, Col, Row } from "reactstrap";
import ControllhippoSmallLogo from '../../../assets/images/controllhippo_samll_logo.svg';

const Welcome = () => {
  return (
    <React.Fragment>
      {/* <div className="chat-welcome-section padtop55"> */}
      <div className="chat-welcome-section ">
        <Row className="w-100 align-items-center justify-content-center">
          <Col xxl={6} md={7} className="chat-welcome-inner">
            <div className="p-4 text-center">
              {/* <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div> */}
              <img src={ControllhippoSmallLogo} height={70} width={70} alt="Controllhippo Logo"/>
              <h4 className="mt-3 font-size-16">Welcome to ControlHippo</h4>
              <p className="text-muted font-size-13">
                Add multiple users and numbers to create a shared whatsapp inbox. Integrate your CRM to sync whatsapp with CRM.
              {/* Enhance Your Customer Communication with a User-Friendly Messaging Platform Designed for Modern Businesses. Enjoy smoother and more efficient interactions with your customers, taking your communication to new heights. */}
              </p>
              <a href="https://controlhippo.productlane.com/roadmap" target="_blank" className="btn btn-primary w-lg font-size-13">
                ControlHippo's Roadmap
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
