export enum AuthLoginActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/login/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/login/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/login/LOGIN_USER",
  LOGIN_WITH_GOOGLE = "@@auth/login/LOGIN_WITH_GOOGLE",
  LOGOUT_USER = "@@auth/login/LOGOUT_USER",
  SYSTEM_LOGOUT_USER = "@@auth/login/SYSTEM_LOGOUT_USER",
  SOCIAL_LOGIN = "@@auth/login/SOCIAL_LOGIN",
  SET_USER_DATA = "@@auth/login/SET_USER_DATA",
  POST_FCM_TOEKN = "@@auth/login/POST_FCM_TOEKN",
  USERDATA_UPDATE = "@@auth/login/USERDATA_UPDATE",
}
export interface AuthLoginState {
  error: string;
  loading: boolean;
  user:any;
  isUserLogin: boolean,
  isUserLogout: boolean,
}
