export enum PlanBillingActionTypes {
  API_RESPONSE_SUCCESS = "@@planBilling/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@planBilling/API_RESPONSE_ERROR",
  GET_CUSTOMER_PORTAL = "@@planBilling/charebee/GET_CUSTOMER_PORTAL",
  CLEAR_CUSTOMER_PORTAL = "@@planBilling/charebee/CLEAR_CUSTOMER_PORTAL",
  GET_INVOICES = "@@planBilling/GET_INVOICES",
  INVOICE_DOWNLOAD = "@@planBilling/INVOICE_DOWNLOAD",
  GET_PLANS = "@@planBilling/GET_PLANS",
  CANCEL_ACCOUNT = "@@planBilling/CANCEL_ACCOUNT",
  CHANGE_SUBSCRIPTION = "@@planBilling/CHANGE_SUBSCRIPTION",
  CHARGEBEE_PROCESS = "@@planBilling/CHARGEBEE_PROCESS",
  CLEAR_STATE_DATA = "@@planBilling/CLEAR_STATE_DATA",
  CHECK_ESTIMATE_COST = "@@planBilling/CHECK_ESTIMATE_COST",
  ADD_CREDIT = "@@planBilling/ADD_CREDIT",
  GET_INVOICE_PAYMENT_URL = "@@planBilling/GET_INVOICE_PAYMENT_URL",
}
export interface PlanBillingState {
  error: string;
  loading: boolean;
  invoices: [];
  plans:[]
}
