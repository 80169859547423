import React, { useRef, useState } from 'react';
import { Drawer, Input } from 'antd';
import { useRedux } from '../../../hooks';
import { getDirectMessages } from '../../../redux/actions';

interface LabelSideMenuProps {
  open: boolean;
  onClose: () => void;
  onBackToFilterClick: () => void;
  labels: any;
  clientId: string;
}

export const LabelSideMenu: React.FC<LabelSideMenuProps> = ({ open, onClose, onBackToFilterClick, labels, clientId }) => {
  const { dispatch, useAppSelector } = useRedux();
  const [limit] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');

  const handleDropdownItemClick = (item: any) => {
    dispatch(getDirectMessages(clientId, 0, limit, "Label Chats", item.value));
    localStorage.setItem("labelChatId", item.value);
    localStorage.setItem("selectedChatFilter", "Label Chats");
    onClose();
    setSearchQuery("")
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredLabels = labels?.length && labels?.filter((label: any) =>
    label?.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Drawer
        title="Labels"
        onClose={onClose}
        open={open}
        width={251}
        zIndex={9999}
        className='FilterDrawerBlock LabelDrawerBlock'
      >
        <div className='labelBackFilter' onClick={onBackToFilterClick}>Back To Filter</div>
        <div className="input-group align-center labelSearchMain">
          <Input
            id="labelSearch"
            type="text"
            className="form-control bg-light border-0 pe-0"
            placeholder="Search Labels"
            autoFocus
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <ul>
          {filteredLabels?.length ? filteredLabels.map((item: any) => (
            <li key={item.value} onClick={() => handleDropdownItemClick(item)}>
              <a className='labelName text-truncate'>
                {item.label}
              </a>
            </li>
          )) : ""}
        </ul>
      </Drawer>
    </>
  );
};
