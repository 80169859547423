import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/firebase-messaging-sw.js')
//       .then((registration) => {
//         console.log('Service worker registered:', registration);
//       })
//       .catch((error) => {
//         console.error('Service worker registration failed:', error);
//       });
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={configureStore({})}>
    <BrowserRouter>
        <App /> 
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
