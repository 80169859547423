import React, { useEffect, useState } from "react";
import { TABS } from "../../../constants/index";
import { Button, Col, Row, Spinner, UncontrolledTooltip,Form,Label,Input, Alert } from "reactstrap";
import Select from "react-select";
import { Card} from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { addMixpanelEvent } from "../../../helpers/mixpanel";
import { updateAuthData, useProfile, useRedux } from "../../../hooks/index";
import { channelList } from '../../../data/channelList';
import Privacy from "../../Dashboard/Settings/Privacy";
import { setClientId, getQrCode, resetClientProcess, getClientsList, changeTab, connectWhatsAppBusiness, updateSettings, clearSettingsState, channelCreate  ,checkEstimateCost, connectSocket, connectTelegram, systemLogoutUser} from '../../../redux/actions';
import ProgressBarComponent from "../../../components/ProgressBar";
import ControllhippoLogo from '../../../assets/images/ControlHippo-blue.svg';
import UpGradePlanPopup from "../../PlanBilling/UpGradePlanPopup";
import AddonsPopup from "../../PlanBilling/AddonsPopup";
import ChannelModal from "../../../components/channelModal";
import { QrCodeWrapper } from '../QrCodeWrapper.style';
// import scss
import { showErrorNotification, showSuccessNotification } from "../../../helpers/notifications"
import EstimatePopup from "../../../components/EstimatePopup";
import { checkEstimate } from "../../../helpers/checkEstimate";
import { checkPlanAndFeature } from "../../../helpers/common.service";
import EditNumber from "../../Numbers/editNumber";
import ChargebeeCheck from "../../../components/ChargebeeCheck";
const countryData = require('country-data');
interface IndexProps { 
  editNumber?:any;
  numberData?:any
}
interface AddonsTypes {
  name:string;
  isActive:boolean;
}
interface UserDetailsTypes {
  email?: string;
  userId?: string;
  clientId?: string;
  chargebeeDetails?: any;
  planDetails?: any;
  fullName?: string;
  phoneNumber?: string;
  userActive?: boolean,
  allAddons?:[AddonsTypes]
  country_code?: string;
}

const Index = (props: IndexProps) => {
  // const [ChannelList, setChannelList] = useState<ChannelList>(channelList);
  const navigate = useNavigate();
  const authUserData: any | null = useState(localStorage.getItem("authData"));
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [inputText, setInputText] = useState('');
  const [newClientId, setNewClientId] = useState("");
  const [titleText, setTitleText] = useState({ text: "", part: 1, progress: 0 });
  const [qrCode, setQrCode] = useState<string|undefined>();
  // const randomNum = generateRandomNumber(4);
  const [curruntStep, setCurruntStep] = useState<number>(0)
  const [userDetails, setUserDetails] = useState<UserDetailsTypes>(userProfile)
  const [chargebeeDetails, setChargebeeDetails] = useState<any>({})
  // const showModalToggle = () => setSelectModal(!selectModal);
  const [clients, setClients] = useState<any|[]>()
  const url = new URL(window.location.href);
  const {
    isClientIdDetails,
    clientAuthenticated,
    clientReady,
    chatSyncingStart,
    userId,
    userData,
    clientList,
    getQrCodeDetails,
    clientsPayload,
    connectWhatsappBusiness,
    settingsData,
    userDetails1,
    isSettingsUpdated,
    tokenModelDetails,
    isUserLogout,
    isUserLogin
  } = useAppSelector(state => ({
    isClientIdDetails: state.QrCode.isClientIdDetails,
    clientAuthenticated: state.QrCode.clientAuthenticated,
    clientReady: state.QrCode.clientReady,
    chatSyncingStart: state.QrCode.chatSyncingStart,
    getQrCodeDetails: state.QrCode.getQrCodeDetails,
    connectWhatsappBusiness: state.QrCode.connectWhatsappBusiness,
    userId: state.confirmAccount.authData?.userId,
    userData: state.Login.user,
    clientList: state.Chats.clientList,
    clientsPayload: state.Chats.clientsPayload,
    settingsData: state.Settings.settings,
    tokenModelDetails: state.Settings.tokenModelDetails,
    userDetails1: state.Login.user,
    isSettingsUpdated: state.Settings.isSettingsUpdated,
    isUserLogout: state.Login.isUserLogout,
    isUserLogin: state.Login.isUserLogin

  }));

  useEffect(() => {
    setClients(clientList)
  }, [clientList])
  
  const location = useLocation();
  const [settings, setSettings] = useState(settingsData);
  const [conformationPopupData, setConformationPopupData] = useState<{isOpen:boolean,message?:string,from?:any,type?:String}>({isOpen:false})
  let privacyValue = settings?.privacy?.isDisablePersonalChatSync
  const countryCodes = countryData.callingCountries.all.map((country: any) => {
    const dialCode: string = country.countryCallingCodes[0].replace('+', '');
    const label: string = `+${dialCode} (${country.alpha2})`;
    return { value: dialCode, label };
  });

  useEffect(() => {
    setSettings({
      ...settings,
      basicDetails: userDetails1,
      privacy: userDetails1.privacy
    })
  }, [userDetails1])



  useEffect(() => {
    if (isSettingsUpdated) {
      showSuccessNotification({ message: "Setting successfully updated." })
      dispatch(clearSettingsState());
      dispatch(getClientsList());
    }
  }, [isSettingsUpdated]);


  useEffect(() => {
    const data: any = JSON.parse(authUserData[0]);
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const addNumberParam = searchParams.get('addNumber');
    const clientDetails = (clients||[]).map((item:any) => item.clientId === userProfile?.clientId)
    if ((userProfile && isUserLogin &&!userProfile?.authToken) || (isUserLogin && data && !data.authToken)) {
      dispatch(systemLogoutUser())
      navigate("/auth-login");
    } else if (isUserLogin && userProfile &&userProfile?.clientId && clientDetails.isSessionSaved == "true" && !addNumberParam) {
      dispatch(changeTab(TABS.CHAT));
      localStorage.setItem("selectedChatFilter", "All");
      navigate("/dashboard");
    } else if (isUserLogin && userProfile && userProfile?.clientId && clientDetails.isSessionSaved == "false") {
      dispatch(changeTab(TABS.CHANNELS));
      navigate("/welcome");
    }
  }, [userProfile,isUserLogin])

  useEffect(() => {

    // if (userData?.isOnboardingPending) {
    //   navigate("/update-profile")
    // }
    if (userData?.chargebeeDetails) setChargebeeDetails(userData?.chargebeeDetails)
    setUserDetails(userData)
  }, [userData])

  useEffect(() => {
    setUserDetails(userData)
    setChargebeeDetails(userData.chargebeeDetails)
  }, [userData])


  useEffect(() => {
    if (connectWhatsappBusiness?.success) {
        setLoading(false)
        if (connectWhatsappBusiness?.link?.embeddedSignupURL) {
          const redirectUrl = connectWhatsappBusiness?.link?.embeddedSignupURL
          window.open(redirectUrl, '_blank');
        }
    }
  }, [connectWhatsappBusiness])

  useEffect(() => {
    if (!getQrCodeDetails.success && getQrCodeDetails.paymentFail) {
      setQrCode("");
      setShow(false)
      setLoading(false);
    }
  }, [getQrCodeDetails])

  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>,response?:any) => {
    // event&&event.preventDefault();
    if (!qrCode && !loading) {
      setLoading(true)
      setShow(true)
      const clientId = newClientId ? newClientId : inputText
      addMixpanelEvent("Generate QR Code Button", { distinct_id: userProfile?.userId })
      if (tokenModelDetails) {
        const token = tokenModelDetails?.isDisablePersonalChatSync
        const result = await connectButton(curruntStep,"whatsapp",token,response)
        if((!result && !props.editNumber) && (userProfile?.chargebeeDetails?.customer_id) ){
          // setLoading(false)
          setShow(false)
        }
      } else {
        const result = await connectButton(curruntStep,"whatsapp","",response)
        if((!result && !props.editNumber) && (userProfile?.chargebeeDetails?.customer_id) ){
          // setLoading(false)
          setShow(false)
        }
        return 
      }
    }else if(qrCode && !loading){
      setShow(true)
      // setQrCode(qrCode)
    } else if(!qrCode && loading) {      

      setShow(true)
      setLoading(true)
    }
  };

  useEffect(() => {
    setQrCode('')
    setShow(false)
    // dispatch(getClientsList())
  }, [])

  useEffect(() => {
    if (props.numberData) {
      const numberData = props.numberData
      setInputText(`${numberData.clientId}`)
      setNewClientId(`${numberData.clientId}`)
    } else {
      const newUserId = userProfile?.userId
      const randomNum = generateRandomNumber(4)
      setInputText(`${newUserId + "_" + randomNum}`)
      setNewClientId(`${newUserId + "_" + randomNum}`)
    }
    dispatch(getClientsList("dropdown"))
  }, []);

  useEffect(() => {
    if (clientsPayload?.success) {
      updateAuthData(clientsPayload.authData)
    }
  }, [clientsPayload])

  function generateRandomNumber(minLength: number): number {
    const min = Math.pow(10, minLength - 1);
    const max = Math.pow(10, minLength) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    var clientID = newClientId ? newClientId : inputText
    if (isClientIdDetails.success && (isClientIdDetails.clientId == clientID)) {      
      setTitleText({ text: "Scan In WhatsAPP", part: 1, progress: 0 })
      setQrCode(isClientIdDetails.url) // QR Code generate
      if(loading)setShow(true)
      setLoading(false)
    }
  }, [isClientIdDetails]);

  useEffect(() => {
    if (clientAuthenticated) {
      addMixpanelEvent("Whatsapp authenticated", { distinct_id: userProfile?.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 30 });
    }

  }, [clientAuthenticated])

  useEffect(() => {
    const clientID = inputText
    if (clientReady.success && (clientReady.clientId === clientID || clientReady.clientId === newClientId)) {
      dispatch(setClientId(clientReady.clientId))
      addMixpanelEvent("Scan successfully", { distinct_id: userProfile?.userId })
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 60 });
      setQrCode("")
      setShow(false)
    }
  }, [clientReady])

  useEffect(() => {
    if (chatSyncingStart) {
      setTitleText({ text: "ControlHippo is in the process of authenticating and loading your chats.", part: 3, progress: 98 });
      dispatch(resetClientProcess())

      setTimeout(() => {
      localStorage.setItem("selectedChatFilter", "All");
        navigate("/dashboard");
        dispatch(changeTab(TABS.CHAT));
        setTitleText({ text: "Enter client ID", part: 1, progress: 0 })
      }, 1000);
    }

  }, [chatSyncingStart])
  const connectButton = async(value: number,type:String,token?:string | any,response?:any) => {
      const clientId = newClientId ? newClientId : inputText
      if(!userDetails?.chargebeeDetails || props?.editNumber){
        dispatch(getQrCode(clientId,false));
        return 
      }
      const objData = {
        item: "addon",
        eventType: "update_subscription_for_items",
        name: "scan_whatsapp_number",
        userDetails,
        dispatch
      }
      let checkData: any
      if(response.skipEstimate){
        onConfirm({from : "scan_whatsapp_number",type})
        return 
      }
      checkData = !props?.editNumber ? await checkEstimate(objData):props.editNumber ? {success : true, message:"Are you sure you want to rescan whatsapp number?"}:{}
      if (checkData?.success) {
        if(checkData?.message){
          if(!props.editNumber){
            setConformationPopupData({
              isOpen:true,
              message:checkData?.message,
              from : "scan_whatsapp_number",
              type
            })
          }
        }else{
          onConfirm({from : "scan_whatsapp_number",type})
        }
      }else{
        return (false)
      }
  }
  const onCancel = ()=>{
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : ""
    })
    if(loading)setLoading(false)
    if(show)setShow(false)
  }
  const onConfirm = (data:any) => {
    setConformationPopupData({
      isOpen:false,
      message:"",
      from : "",
      type:""
    })
    const clientId = newClientId ? newClientId : inputText
    if(data.from == "scan_whatsapp_number" && data.type == "whatsapp"){
      if(checkPlanAndFeature({featureName:"w_qr_scan",planDetails: userProfile?.planDetails,featureDisplayName:"QR Scan"})){
        if(tokenModelDetails?.isDisablePersonalChatSync){
          if (curruntStep === 1|| curruntStep === 0) dispatch(getQrCode(clientId, tokenModelDetails?.isDisablePersonalChatSync));
        }else {
          if (curruntStep === 1 || curruntStep === 0) dispatch(getQrCode(clientId,false));
        }
      }
    }
  }

  const onChangeData = (field: string, value: any) => {
    let privacy = settings.privacy
    const payloadData = {
      clientId : newClientId ? newClientId : inputText,
      userId : userDetails?.userId ? userDetails?.userId : userProfile?.userId,
      // numberId : data._id,
      value : value
  }

    privacy = {
      ...privacy,
      ...value
    };
    dispatch(updateSettings(field, privacy, payloadData));
  };


  const handleBackButton = () => {
    dispatch(changeTab(TABS.CHANNELS))
    navigate("/welcome")
  }

  const buttonClicked = (response: any) => {
    if (response.success) {
      const syntheticEvent = {} as React.FormEvent<HTMLFormElement>;
      return handleSubmit(syntheticEvent,response)
    }
  }

  return (
    <>
      <QrCodeWrapper className="QrCodeWrapper">
      <div className="chat-welcome-section user-chat user-chat-show d-flex align-item-center h-100">
        <Row className={`w-100 m-0 ${"wpQrScannerMainrow"}`}>
          <Col md={12} className={`p-0 ${titleText.part == 3 ? "align-content-center" :""}`}>
            {/* <div> */}
            {titleText.part == 2 && <h4 className="text-center mb-4">{titleText.text}</h4>}
            {titleText.part == 1 &&
                <Card className={`${"whenCurruntStepZero"} p-3 wpQrScanCardMain h-100`}>
                  <div className="mb-lg-4 mb-2 w-100 d-flex align-items-center gap-2">
                    {<>
                      <div className="ms-1 float-left">
                        <Button className="btn btn-sm d-flex align-items-center justify-content-center pageBackBtn p-1" onClick={handleBackButton}><i className='bx bx-chevron-left font-size-24'></i></Button>
                      </div>
                    </>}
                    {/* For whatsapp name title start */}
                    <div className="qrCodePageCardTitleMain">
                      <>
                        <h2 className="d-flex align-items-center gap-1 font-size-17 text-dark text-center mb-0 lh-sm">
                          <b>WhatsApp {props.editNumber && `[ +${props?.numberData?.me?.user} ] `}</b>
                          <a id="whatsppcard" className="lh-sm d-flex align-items-center justify-content-center wpCardInfoLink" href="https://support.controlhippo.com/support/solutions/articles/1060000057243-how-to-integrate-whatsapp-in-controlhippo-" target="_blank">
                            <i className="bx bxs-info-circle font-size-18 lh-sm"></i>
                          </a>
                        </h2>
                        <UncontrolledTooltip
                          target={`whatsppcard`}
                          placement="right"
                        >
                          Click here to know more..
                        </UncontrolledTooltip>
                      </>
                    </div>

                    {/* For whatsapp name title end */}
                  </div>
                  {<div className="wpQrCodeFirstTab">
                    <Row className="align-items-center qrmainrow">
                      <Col md={12} lg={6} xl={7} className="ps-4">
                        <div className="qrGenerateSteps">
                          <h5 className="text-start">Use Your WhatsApp In ControlHippo</h5>
                          <ol className="text-start d-flex flex-column gap-3 mt-3">
                            <li>Open WhatsApp on your phone</li>
                            <li>Tap Menu <span className="ctrlhSettingOption">⋮</span> or Setting <span className="ctrlhSettingOption">⚙️</span> and select Linked Devices</li>
                            <li>Tap on Link a device.</li>
                            <li>Point your phone to this screen to capture the QR code</li>
                          </ol>
                        </div>
                        {/* <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} /> */}
                      </Col>
                      <Col md={12} lg={6} xl={5}>
                        <div className="text-center d-flex align-items-center flex-column">
                          <form className="row g-3 justify-content-center" onSubmit={handleSubmit}>
                            <div className="d-flex align-items-center justify-content-center qrCodeImagMain">
                              {/* {loading ? <>
                                <Spinner
                                  style={{
                                    height: '4rem',
                                    width: '4rem'
                                  }}
                                  color="primary"
                                  type="border"
                                >
                                  Loading...
                                </Spinner>

                              </> :
                                qrCode && show ?
                                  <img src={qrCode} height={264} width={264} alt="..." />
                                  // <QRCode className="card-img-top" value={qrCode} />
                                  : userDetails?.chargebeeDetails?.status == "cancelled" ?
                                    <div className="dummyQrCode d-flex align-items-center justify-content-center">
                                      <UpGradePlanPopup className="text-center btn btn-primary" lable="Generate QR Code" />
                                    </div>
                                    :
                                    <div className="dummyQrCode d-flex align-items-center justify-content-center">
                                      <Button type="submit" disabled={loading} className="text-center btn btn-primary">
                                        Generate QR Code
                                      </Button>
                                    </div>
                              } */}{loading ? <>
                                <div>
                                  <Spinner
                                    style={{
                                      height: '4rem',
                                      width: '4rem'
                                    }}
                                    color="primary"
                                    type="border"
                                  >
                                    Loading...
                                  </Spinner>
                                  <p className="text-dark mt-5 qrLoadingText">QR code loading. Please wait (1-2 mins).</p>
                                </div>
                              </> :
                                qrCode && show ?
                                <div className="d-flex flex-column align-items-center">
                                <Alert className="mb-3 py-2 px-3 barcodeTopBanner font-size-13" key={'primary'} variant={'primary'}>
                                After scanning the QR code, please wait for a minute, we will redirect you.
                                </Alert>
                                <img src={qrCode} height={264} width={264} alt="..." />
                              </div>
                                  // <QRCode className="card-img-top" value={qrCode} />
                                  : <div className="dummyQrCode d-flex align-items-center justify-content-center"> <ChargebeeCheck numberData={props.numberData} userDetails={userProfile?userProfile:userDetails1} buttonText={"Generate QR Code"} onConfirm={buttonClicked} from="whatsapp"/></div>
                              }
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  }

                  {
                    <div className="privacyDivQr mt-4">
                      <Privacy privacy={tokenModelDetails} onChangeSettings={onChangeData} qrCodePage={true} />
                    </div>
                  }
                  {
                    <Row className="mt-4 videoTutorialRow">
                      <Col span={24}>
                        <div className="text-center p-3 videoTutorialTitle">
                          <h3 className="mb-0">Tutorial</h3>
                        </div>
                        <div className="pt-4 px-4 text-center tutorialVideoDiv">
                          <h5 className="mb-3">How to Scan QR to add WhatsApp Account</h5>
                          <video width="548" height="308" poster="https://images.controlhippo.com/images/How-to-link-controllhippo-from-android_letest.png" controls>
                            <source src="https://images.controlhippo.com/video/How_to_connect_Controlhippo_with_WhatsApp_min_new.mp4" type="video/mp4" />
                          </video>
                        </div>
                      </Col>
                    </Row>
                  }
                </Card>
            }
            {titleText.part == 2 &&
              <div className="text-center mb-4">
                <div className="card text-center mb-4" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h5 className="card-title">QR Code</h5>
                    {qrCode && (
                      <img src={qrCode} className="card-img-top" alt="..." />
                      // <QRCode className="card-img-top" value={qrCode} />
                    )}
                  </div>
                </div>
              </div>
            }
            {/* </div> */}
            {titleText.part == 3 && <>
              <div className="pb-4 text-center">
                <img src={ControllhippoLogo} height={100} width={250} alt="Controllhippo Logo" />
                <h4 className="mt-3 text-muted readyConnectionTxt">{titleText.text}</h4>
              </div>
              {/* <Loader/> */}
              <div className="d-flex justify-content-center resync mb-5" >
                <ProgressBarComponent className={"progressBar"} animated={true} value={titleText.progress} label={false} variant={'primary'} striped={false} visuallyHidden={true} />
              </div>
            </>}
          </Col>
        </Row>
      </div>
      </QrCodeWrapper>

      {conformationPopupData.isOpen && <EstimatePopup isOpen={conformationPopupData.isOpen} data={conformationPopupData} showUpgreadButton={false} onCancel={onCancel} onConfirm={onConfirm}/>}
    </>
  );
};

export default Index;
