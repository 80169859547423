import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { changeEmailActionTypes } from "./types";
import {
  changeEmailApiResponseSuccess,
  changeEmailApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postChangeEmail as postApiChangeEmail,
} from "../../../api/index";
import { showErrorNotification } from "../../../helpers/notifications";

const fireBaseBackend: any = getFirebaseBackend();

function* postChangeEmail({ payload: data }: any) {
    try {
    const response: Promise<any> = yield call(postApiChangeEmail, data);
    yield put(
      changeEmailApiResponseSuccess(
        changeEmailActionTypes.CHANGE_USER_EMAIL,
        response
      )
    );
  } catch (error: any) {
    showErrorNotification(error?.data?.error)
    yield put(
      changeEmailApiResponseError(
        changeEmailActionTypes.CHANGE_USER_EMAIL,
        error
      )
    );
  }
}

export function* watchSetSubUserPwd() {
  yield takeEvery(changeEmailActionTypes.CHANGE_USER_EMAIL, postChangeEmail);
}

function* setSubUserPwdSaga() {
  yield all([fork(watchSetSubUserPwd)]);
}

export default setSubUserPwdSaga;
