import React, { useState, useEffect } from 'react';
import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';

interface DateRangePickerProps {
    onChange: (ranges: any) => void;
    ranges: { startDate: string|Date|any; endDate: string|Date|any };
}

const DateRangePicker = ({ onChange, ranges }: DateRangePickerProps) => {
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY HH:mm';
    const startDate = dayjs(ranges?.startDate);
    const endDate = dayjs(ranges?.endDate);
    const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>([startDate, endDate]);
    const handleChange = (update: [Dayjs, Dayjs] | null | any) => {
      const [start, end] = update;
      onChange(update);
      setDates(update);
    };
    const disabledDate = (current: Dayjs | null): boolean => {
        if (!current) return false;
        const now = dayjs();
        const ninetyDaysAgo = now.subtract(90, 'days').startOf('day');
        return current > now.endOf('day') || current < ninetyDaysAgo;
    };

    const range = (start: number, end: number): number[] => {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledRangeTime = (
        current: Dayjs | null,
        type: 'start' | 'end' | undefined
    ) => {
        if (!current) return {};
        const now = dayjs();
        if (type === 'start' && current.isSame(now, 'day')) {
            return {
                disabledHours: () => range(now.hour() + 1, 24),
                disabledMinutes: () => range(now.minute() + 1, 60),
                disabledSeconds: () => range(now.second() + 1, 60),
            };
        } else if (type === 'end' && current.isSame(now, 'day')) {
            return {
                disabledHours: () => range(now.hour() + 1, 24),
                disabledMinutes: () => range(now.minute() + 1, 60),
                disabledSeconds: () => range(now.second() + 1, 60),
            };
        }
        return {};
    };
    return (
        <Space direction="vertical" size={12}>
            <RangePicker
                allowClear={false}
                autoFocus={true}
                disabledDate={disabledDate}
                disabledTime={disabledRangeTime}
                renderExtraFooter={() => 'The report filter is restricted to a 7-day time period.'}
                showTime={{ format: 'HH:mm' }}
                format={dateFormat}
                size={"middle"}
                onChange={handleChange}
                defaultValue={[startDate, endDate]}
            />
        </Space>
    );
};

export default DateRangePicker;
