import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Tooltip,Popover } from 'antd';


// interface
import { UserTypes } from "../../../data/chat";
import { STATUS_TYPES } from "../../../constants";
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';
interface ChatUserProps {
  user: any;
  selectedChat: string | number;
  clientId: string | number;
  clientDisconnected?: Boolean;
  onSelectChat: (id: number | string, clientId: number | string) => void;
  isDisabled?: boolean;
}
const ChatUser = ({ user, selectedChat, onSelectChat, clientId, clientDisconnected, isDisabled }: ChatUserProps) => {
let lastMsgIcon
  switch (user?.lastMessageType) {
    case "document":
      lastMsgIcon = <span className='bxs-file-icon'></span>
      break;
    case "image":
      lastMsgIcon = <span className='bxs-camera-icon'></span>
      break;
    case "video":
      lastMsgIcon = <span className='bxs-video-icon'></span>
      break;
    case "audio":
      lastMsgIcon = <span className='bxs-music-icon'></span>
      break;
    case "ptt":
      lastMsgIcon = <span className='bxs-music-icon'></span>
      break;
    case "internal-note":
      lastMsgIcon = <span className='bxs-edit-alt-icon'></span>
      break;
    default:
      break;
  }
  const fullName = user?.firstName ? user.firstName : user?.verifiedName ? user?.verifiedName :  user?.pushName;
  const assingedUserName = user?.assingedUserName ? user.assingedUserName : ""
  const dndStart = user?.dndStart
  let lastMsgText
  if(user.lastMessageText || user.lastMessageType){
  lastMsgText = user?.lastMessageText ? user?.lastMessageText : user?.lastMessageType;
  }
  let assignType
  if(user.assignType == "manual" && user.assingedUser == user._id){
    assignType = user?.assignType
  }

  const labels = user?.labels?.length && (user.labels).map((item:{displayName:string}) =>item.displayName)
  const [displayLabels, setDisplayLabels] = useState<[string]|null>(labels)
  let isGroup = user.isGroup
  function formatDate(date: any) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  function formatDateWithTime(date: any) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${ampm}`;
  }
  const phoneNumber = "+" + fullName;
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const formatPhoneNumber = (phoneNumber: PhoneNumber): string => {
    const formattedNumber = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
    return formattedNumber.replace(/ /g, ' '); // Add space after each digit
  };
  let decodedNumber: string | null = null;
  let number: PhoneNumber | undefined;
  if (phoneNumber && !isGroup) {
    try {
      const phoneRegex = /^\+?[0-9]{1,3}[- ]?\(?[0-9]{3}\)?[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
      const isPhoneNumber = phoneRegex.test(phoneNumber);
      if (isPhoneNumber) {
        number = phoneNumberUtil.parse(phoneNumber);
        decodedNumber = formatPhoneNumber(number);
      }
    } catch (e) {
      console.log("error in formatPhoneNumber", e)
      decodedNumber = phoneNumber
    }
  }
  let displayTime;
  if (user.lastMessageAt) {
    const lastMsgTime = user.lastMessageAt;
    const lastMsgDate: any = new Date(lastMsgTime);
    const currentDate: any = new Date();
    const timeDifference = currentDate - lastMsgDate;
    const isYesterday = (currentDate.getDate() - lastMsgDate.getDate() === 1) && (currentDate.getMonth() === lastMsgDate.getMonth()) && (currentDate.getFullYear() === lastMsgDate.getFullYear());
    if (timeDifference < 86400000 && !isYesterday) {
      displayTime = formatDateWithTime(lastMsgDate);
    } else if (isYesterday || timeDifference < 172800000) {
      displayTime = 'Yesterday';
    } else {
      displayTime = formatDate(lastMsgDate);
    }
  }


  const shortName = `${user.profilePic && user.profilePic}`;
  const shortName1 = user.firstName && user?.firstName.charAt(0)
  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;
  const unRead = user.meta && user.meta.unRead;

  const isSelectedChat: boolean = selectedChat && selectedChat === user.chatId ? true : false;
  const onClick = () => {
    if(user.chatId !== selectedChat || user.clientId == clientId){
      console.log("isDisabled:::", isDisabled)
    if (!isDisabled) {
      onSelectChat(user.chatId, clientId);
    }
  }
  };

  const replaceImage = (error: any) => {
    error.target.src = "https://controlhippo.s3.ca-central-1.amazonaws.com/logo/image+(26).png"
  }

  const getLabelsResult = (labels:any)=>{
    let newArray :any= [];
    if (labels?.length > 3) {
      newArray = labels.slice(0, 3);
      newArray.push(` and ${labels.length - 3} more`);
    } else {
      newArray = labels;
    }
    return newArray ;
  }

  const formatLabels = (labels:any) => {
    const result = getLabelsResult(labels);
    return result?.length && result.map((item:string, index:Number) => {
      if (index === result.length - 1) {
        return <div className="text-truncate" style={{width:200}} >{item}</div>;
      } else {
        return <div className="text-truncate" style={{width:200}}>{item},</div>;
      }
    });
  };
  const content = (
    <div>
      {formatLabels(labels)}
    </div>
  );

  const formattedLastMsgText = lastMsgText?.replace(/\n/g, '<br>');
  return (
    <>
      <li className={classnames({ active: isSelectedChat, isDisabled: isDisabled })} aria-disabled={isDisabled} onClick={onClick}>
        <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
          <div className="d-flex gap-2 align-self-start">
            <div
              className={classnames(
                "chat-user-img",
                "align-self-center",
                "ms-0",
                { online: isOnline }
              )}
            >
              {clientDisconnected ? (

                <div className="avatar-sm align-self-center">
                  <span
                    className={classnames(
                      "avatar-title",
                      "rounded-circle",
                      "text-uppercase",
                      "text-white",
                      colors[color]
                    )}
                  >
                    <span className="username font-size-10"> {shortName1 !== undefined ? shortName1 : ''}</span>
                    {/* <span className="user-status"></span> */}
                  </span>
                </div>


              ) :
                user.profilePic ? (
                  <>
                    <img
                      src={user.profilePic}
                      className="rounded-circle avatar-xs"
                      alt=""
                      onError={replaceImage}
                    />
                    {/* {isOnline && <span className="user-status"></span>} */}
                  </>
                ) : (
                  <div className="avatar-sm align-self-center">
                  <span
                    className={classnames(
                      "avatar-title",
                      "rounded-circle",
                      "text-uppercase",
                      "text-white",
                      colors[color]
                    )}
                  >
                    <span className="username font-size-10"> {shortName1 !== undefined ? shortName1 : ''}</span>
                    {/* <span className="user-status"></span> */}
                  </span>
                </div>
                )}
            </div>
            <div className="overflow-hidden w-100 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                <div className="d-flex align-items-center chatListLableMain">
                  <Tooltip title={decodedNumber ? decodedNumber :fullName} placement="bottom">
                    <p className="text-truncate mb-0 font-size-13 chatUsrName">{decodedNumber ? decodedNumber :fullName}</p>
                  </Tooltip>            
                  {assingedUserName && <><small className="d-flex align-items-center">
                    <span className="bx_chevron_right font-size-16"></span>
                    
                      <span className="text-truncate chatAssinedUsrName font-size-12">{assingedUserName}</span>
                  </small>
                  </>}
                </div>
                <div className="d-flex align-items-center gap-1">
                  {unRead && unRead !== 0 ? (
                    <div>
                      <span className="badge badge-soft-dark rounded ms-2 d-flex align-items-center justify-content-center">
                        {unRead}
                      </span>
                    </div>
                  ) : null}
                  <p className="text-truncate mb-0">
                    {user?.syncingChatStopToCrm && (
                      <Tooltip title="Pause sync to CRM" placement="top">
                        <i className="mdi mdi-sync-off mdi-18px"></i>
                      </Tooltip>
                    )}
                  </p>
                  {/* <span className="unReadDotChat"></span> */}
                  {
                    dndStart && <>
                    <i className='bx bxs-bell-off'></i>
                    </>
                  }
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between w-100">
              <Tooltip overlayClassName="msgTooltipContent" title={<span dangerouslySetInnerHTML={{ __html: formattedLastMsgText }} />} placement="right">
                <p className="mb-0 font-size-11 d-flex align-items-center gap-1 lastMsgText">
                  {lastMsgIcon} <span className="text-truncate font-size-11">{lastMsgText ? lastMsgText : ""}</span>
                </p>
              </Tooltip>
                <div className="d-flex align-items-center gap-1">
                  {labels?.length > 0 &&
                    <Popover
                      overlayClassName="labelListPopover"
                      content={content}>
                      <img src={`https://images.controlhippo.com/svg/${ labels?.length > 1 ? 'chat_lable_group' : 'chat_label'}.svg`} width={14} height={labels?.length > 1? 12 : 14 } alt="Label Chat - ControlHippo" />
                    </Popover>
                  }
                  <p className="text-truncate mb-0 font-size-9 text-muted">{displayTime ? displayTime : ""}</p>
                  {assignType && <p>{assignType}</p>}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
};

export default ChatUser;
