export enum WebsocketActionTypes {
    SOCKET_CONNECT = "SOCKET_CONNECT",
    CONNECTED = "CONNECTED",
    DISCONNECTED = "DISCONNECTED",
    SESSION_RESYNCING = "SESSION_RESYNCING",
    SESSION_RESYNCING_COMPLETED = "SESSION_RESYNCING_COMPLETED",
    SOCKET_RESPONSE_ERROR = "SOCKET_RESPONSE_ERROR",
    NOTICE_ERROR = "NOTICE_ERROR",
    CLEAR_SOCKET_RESPONSE_ERROR = "CLEAR_SOCKET_RESPONSE_ERROR"
}
export interface WebsocketState {
    connected: Boolean;
    isSessionResyncing: Boolean;
    errorResponse:{}
}
