import React, { useState, useEffect, useRef } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useReactMediaRecorder } from 'react-media-recorder';
import { ReactMic } from 'react-mic';
import moment from 'moment';
import ReactAudioPlayer from 'react-audio-player';
import { useRedux } from '../../../../hooks';

interface AudioRecorderPopupTypes {
    showRecorder: boolean;
    onSelectAudio: (files: Array<any> | null) => void;
    innerRef : any
}

const AudioRecorderPopup = ({ showRecorder, onSelectAudio,innerRef }: AudioRecorderPopupTypes) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    const { dispatch, useAppSelector } = useRedux();

    const [recording, setRecording] = useState(false);
    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
    } = useReactMediaRecorder({});


    const { onSendResponse } = useAppSelector((state) => {
        return {
            onSendResponse: state.Chats.onSendResponse
        };
    });

    useEffect(() => {
        if (!onSendResponse?.success) {
            handleClosePopover()
        }
    }, [onSendResponse])
        
    useEffect(() => {
        setPopoverOpen(showRecorder);
        setTimeout(() => {
            if (showRecorder && !recording) handleStartRecording();
        }, 1000);
    }, [showRecorder]);

    const handleStartRecording = () => {
        startRecording();
        setRecording(true);
    };

    const handleStopRecording = () => {
        // let voicediv = document.querySelector('.voiceRecorderPopover') as HTMLElement;
        // let mainSendMsgTextArea = document.querySelector('.textbox_container') as HTMLElement;
        // if (voicediv && mainSendMsgTextArea) {
        //     mainSendMsgTextArea.appendChild(voicediv);
        // }  
        stopRecording();
        setRecording(false);
        setPopoverOpen(false);
    };

    useEffect(() => {
        const msgSendBtn = document.getElementById('msgSendBtn') as HTMLElement;
        let voicePopoverCloseBtn = document.getElementById('#playerBtnClose') as HTMLElement;
        let selectedMediaClose = document.getElementsByClassName('selected-media-close');
        if(innerRef.current && mediaBlobUrl && selectedMediaClose.length > 0)selectedMediaClose[0].classList.add("d-none");
        const handleClickOutside = (event: MouseEvent) => {
            let voicediv = document.querySelector('.voiceRecorderPopover') as HTMLElement;
            let mainSendMsgTextArea = document.querySelector('.textbox_container') as HTMLElement;
            if ((innerRef.current && mediaBlobUrl) && (mainSendMsgTextArea.contains?.(voicediv))) {
                mainSendMsgTextArea.removeChild(voicediv);
            }
            if (mainSendMsgTextArea.contains?.(voicediv)) {
                voicePopoverCloseBtn.addEventListener('click', handleClosePopover)
            }
            setRecording(false);
            if (msgSendBtn && !msgSendBtn.contains?.(event.target as Node)) {
                onSelectAudio(null);
            }
        };
        msgSendBtn.addEventListener('click', handleClickOutside);
        return () => {
            msgSendBtn.removeEventListener('click', handleClickOutside);
        };
    }, [onSelectAudio, mediaBlobUrl]);
    useEffect(() => {
        if (status === 'stopped') {
            handleSend();
        }
    }, [status]);

    const handleSend = async () => {
        if (mediaBlobUrl) {
            try {
                const blob = await fetch(mediaBlobUrl).then((response) => response.blob());
                const audioFile = new File([blob], `ControlHippo ${moment().format("YYYY-MM-DD")} at ${moment().format("hh.mm.ss A")}.ogg`, { type: 'audio/ogg; codecs=opus' });
                const id = Math.random().toString(36).substring(2, 15);
                const files = [{id,file:audioFile}];
                onSelectAudio(files);
            } catch (error) {
                console.error('Error fetching the Blob data:', error);
            }
        } else {
            console.error('No recorded audio to send.');
        }
    };

    const handleClosePopover = () => {
        let voicediv = document.querySelector('.voiceRecorderPopover') as HTMLElement;
        let mainSendMsgTextArea = document.querySelector('.textbox_container') as HTMLElement;
        if (((voicediv) && (mainSendMsgTextArea.contains?.(voicediv)))) {
            mainSendMsgTextArea.removeChild(voicediv);
            onSelectAudio(null);
        }
        setPopoverOpen(false);
    };

    const voicePopoverContainer = document.getElementsByClassName('chat-input-section');
    
    return (
        <div>
            <Popover
                className='m-1 p-1 voiceRecorderPopover'
                placement="bottom"
                isOpen={popoverOpen}
                innerRef={innerRef}
                target="voice-recorder"
                toggle={toggle}
                getPopupContainer={() => voicePopoverContainer}
            >
                {/* <PopoverHeader>
                    <Button
                        close
                        aria-label="Close"
                        style={{ position: 'absolute', top: '0', right: '0' }}
                        onClick={handleClosePopover}
                    />
                </PopoverHeader> */}
                <PopoverBody>
                    {recording && <div className="text-center font-size-14 text-dark mt-1">Record audio</div>}
                    <div className='coloseBtnDivCtntr'>
                        <Button
                            close
                            id='playerBtnClose'
                            aria-label="Close"
                            onClick={handleClosePopover}
                        />
                    </div>
                    <div className='text-center'>
                        {!mediaBlobUrl && (
                            <ReactMic
                                record={recording}
                                visualSetting="sinewave"
                                onStop={handleStopRecording}
                                width={225}
                                height={30}
                            />
                        )}
                        {!mediaBlobUrl && status === 'recording' && (
                            <>
                                <Button
                                    id='stop-button'
                                    className="btn rounded-circle btn-sm bg-primary text-light"
                                    outline={true}
                                    disabled={status === 'recording' ? false : true}
                                    // color={status === 'recording' ? 'danger' : 'primary'}
                                    color='light'
                                    onClick={recording ? handleStopRecording : handleStartRecording}
                                >
                                    {/* {status === 'recording' && <i className="mdi mdi-stop"></i>} */}
                                    {status === 'recording' && <i className="mdi mdi-check font-size-16"></i>}
                                </Button>
                            </>
                        )}
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};

export default AudioRecorderPopup;
