import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const campaignCreate = (data: any) => {
  return api.createWithFile(url.CAMPAIGN+"/addCampaign", data);
};
const campaignUpdate = (data: any) => {
  return api.createWithFile(url.CAMPAIGN+"/updateCampaign/"+data.campaignId, data);
};

const campaignDelete = (data: any) => {
  return api.update(url.CAMPAIGN+"/deleteCampaign/"+data.campaignId, data);
};

const campaignList = () => {
  return api.get(url.CAMPAIGN+"/campaigns");
};

const campaignDetails = (id: string) => {
  return api.get(url.CAMPAIGN + "/" + id);
};
export {
  campaignCreate,
  campaignList,
  campaignDetails,
  campaignUpdate,
  campaignDelete
};
