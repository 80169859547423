export enum QrCodeActionTypes {
  API_RESPONSE_SUCCESS = "@@qrCode/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@qrCode/API_RESPONSE_ERROR",
  SOCKET_CONNECT_SUCCESS = "@@qrCode/SOCKET_CONNECT_SUCCESS",
  GET_QR_CODE = "@@qrCode/GET_QR_CODE",
  GET_CLIENT_ID_DETAILS = "@@qrCode/GET_CLIENT_ID_DETAILS",
  DESTROYED_QR_CODE = "@@qrCode/DESTROYED_QR_CODE",
  CLIENT_AUTHENTICATED = "@@qrCode/CLIENT_AUTHENTICATED",
  QR_CODE_READY = "@@qrCode/QR_CODE_READY",
  CHAT_SYNCING_START = "@@qrCode/CHAT_SYNCING_START",
  RESET_CLIENT_PROCESS = "@@qrCode/RESET_CLIENT_PROCESS",
  SET_AUTHDATA = "@@qrCode/SET_AUTHDATA",
  STORE_CLIENT_ID = "@@qrCode/STORE_CLIENT_ID",
  CONNET_WHATSAPP_BUSINESS = "@@qrCode/CONNET_WHATSAPP_BUSINESS",
  CLEAR_CHATSYNCING_REDUCER = "@@qrCode/CLEAR_CHATSYNCING_REDUCER",
  DELETE_NUMBER = "@@qrCode/DELETE_NUMBER",
  CHANNEL_CREATE = "@@qrCode/CHANNEL_CREATE",
  TELEGRAM_CONNECT = "@@qrCode/TELEGRAM_CONNECT",
  GET_MESSENGER_PAGE_LIST = "@@qrCode/GET_MESSENGER_PAGE_LIST",
  UPDATE_CLIENT_DETILS = "@@qrCode/UPDATE_CLIENT_DETILS",
  MESSENGER_CONNECT = "@@qrCode/MESSENGER_CONNECT",
  INSTRGRAM_CONNECT = "@@qrCode/INSTRGRAM_CONNECT",
  CHATBOT_CONNECT = "@@qrCode/CHATBOT_CONNECT",
  GET_OLD_CLIENT_ID = "@@qrCode/GET_OLD_CLIENT_ID",
}
export interface GetQrCodeState {
  error: string;
  loading: boolean;
  isSocketConnected:boolean;
  getQrCodeDetails:object;
  isClientIdDetailsFetched:boolean;
  isClientIdDetails:object,
  clientReady: object
  chatSyncingStart: boolean,
  clientAuthenticated: boolean
  clientIdList: any,
  numberDeleteSuccess: boolean;
}
