import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { AuthConfirmDataActionTypes } from "./types";
import {
  confirmUserDataApiResponse,
  confirmUserDataApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postConfirmData,
  changePassword as changePasswordApi,
} from "../../../api/index";

const fireBaseBackend: any = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* confirmAccount({ payload: data }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postConfirmData,data );
      yield put(
        confirmUserDataApiResponse(
          AuthConfirmDataActionTypes.CONFIRM_DATA,
          response
        )
      );
    }
  } catch (error: any) {
    yield put(
      confirmUserDataApiResponseError(
        AuthConfirmDataActionTypes.CONFIRM_DATA,
        error
      )
    );
  }
}

export function* watchUserConfirmAccount() {
  yield takeEvery(AuthConfirmDataActionTypes.CONFIRM_DATA, confirmAccount);
}

function* confirmAccountSaga() {
  yield all([fork(watchUserConfirmAccount)]);
}

export default confirmAccountSaga;
