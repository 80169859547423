import { QrCodeActionTypes, GetQrCodeState } from "./types";

export const INIT_STATE: GetQrCodeState = {
  error: "",
  loading: false,
  getQrCodeDetails: {},
  isSocketConnected: false,
  isClientIdDetailsFetched: false,
  isClientIdDetails: {},
  clientReady: {},
  chatSyncingStart: false,
  clientAuthenticated: false,
  clientIdList:"",
  numberDeleteSuccess:false
};

const getQrCode = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case QrCodeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case QrCodeActionTypes.GET_QR_CODE: {
          return {
            ...state,
            getQrCodeDetails: { ...action.payload.data },
            isClientIdDetailsFetched: true,
            loading: false,
          };
        }
        case QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS: {
          return {
            ...state,
            connectWhatsappBusiness: action.payload.data,
            isClientIdDetailsFetched: true,
            loading: false,
          };
        }
        case QrCodeActionTypes.CLEAR_CHATSYNCING_REDUCER: {
          return {
            ...state,
            chatSyncingStart: false,
            clientAuthenticated: false,
            clientReady: {}
          };
        }

        case QrCodeActionTypes.CLIENT_AUTHENTICATED:
          return {
            ...state,
            clientAuthenticated: true
          };

        case QrCodeActionTypes.QR_CODE_READY:
          return {
            ...state,
            loading: false,
            clientReady: { ...action.payload.data, success: true },
          };

        case QrCodeActionTypes.CHAT_SYNCING_START:
          return {
            ...state,
            chatSyncingStart: true
          }

        case QrCodeActionTypes.DELETE_NUMBER:
          return {
            ...state,
            numberDeleteSuccess: action.payload.data.success,
            userDetails: action.payload.data.data,
            isProfileFetched: true,
            getProfileLoading: false,
          };
        case QrCodeActionTypes.CHANNEL_CREATE:
          return {
            ...state,
            channelCreateSuccess: action.payload.data.success,
          };
       
        default:
          return { ...state };
      }

    case QrCodeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case QrCodeActionTypes.GET_QR_CODE: {
          return {
            ...state,
            isClientIdDetailsFetched: false,
            loading: false,
          };
        }
        case QrCodeActionTypes.CLIENT_AUTHENTICATED: {          
          return {
            ...state,
            chatSyncingStart: true
          };
        }
        case QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS: {
          return {
            ...state,
            isClientIdDetailsFetched: false,
            loading: false,
          };
        }
        case QrCodeActionTypes.GET_CLIENT_ID_DETAILS: {
          return {
            ...state,
            loading: true,
          };
        }
        case QrCodeActionTypes.DELETE_NUMBER:
          return {
            ...state,
            isProfileFetched: false,
            getProfileLoading: false,
            numberDeleteSuccess:false
          };
        case QrCodeActionTypes.CHANNEL_CREATE:
          return {
            ...state,
            channelCreateSuccess:false,
          };
        
        default:
          return { ...state };
      }

    case QrCodeActionTypes.GET_QR_CODE: {
      return {
        ...state,
        isClientIdDetailsFetched: false,
        loading: true,
      };
    }
    case QrCodeActionTypes.CONNET_WHATSAPP_BUSINESS: {
      return {
        ...state,
        isClientIdDetailsFetched: false,
        loading: true,
      };
    }
    case QrCodeActionTypes.CLEAR_CHATSYNCING_REDUCER: {
      return {
        ...state,
        chatSyncingStart: false,
        clientAuthenticated: false,
        clientReady: {}
      };
    }
    case QrCodeActionTypes.SOCKET_CONNECT_SUCCESS: {
      return {
        ...state,
        isSocketConnected: true,
        loading: true,
      };
    }
    case QrCodeActionTypes.GET_CLIENT_ID_DETAILS: {
      return {
        ...state,
        loading: false,
        isClientIdDetails: { ...action.payload, success: true },
      };
    }
    case QrCodeActionTypes.DESTROYED_QR_CODE: {
      return {
        ...state,
        loading: false,
        isClientIdDetails: { ...action.payload, success: true },
      };
    }
    case QrCodeActionTypes.SET_AUTHDATA: {
      return {
        ...state,
        loading: false,
        getChatList: { ...action.payload, success: true },
      };
    }
    case QrCodeActionTypes.STORE_CLIENT_ID: {
      return {
        ...state,
        clientIdList: action.payload ,
      };
    }
    
    case QrCodeActionTypes.RESET_CLIENT_PROCESS:
      return {
        ...state,
        chatSyncingStart: false,
        loading: false,
        clientAuthenticated: false,
        clientReady: {}
      }
      case QrCodeActionTypes.DELETE_NUMBER: {
        return {
          ...state,
          getProfileLoading: true,
          isProfileFetched: false,
          numberDeleteSuccess:false
        };
      }
      case QrCodeActionTypes.CHANNEL_CREATE: {
        return {
          ...state,
          channelCreateSuccess:false
        };
      }
     
    default:
      return { ...state };
  }
};

export default getQrCode;
