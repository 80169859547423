import React, { useEffect, useRef, useState } from "react";
import { useRedux } from "../../../../hooks";
import { Input, Label } from "reactstrap";
import { getChatUserDetails, getTemplateList } from "../../../../redux/actions";
import QuillEditor from '../../../Dashboard/ConversationUser/ChatInputSection/quillTextEditor';
import { ClientDetailsProps } from "../../../../data";
import Quill from "quill";

interface InputSectionProps {
  type:string
  value: null | string;
  selectedEmoji: null | string;
  mentionList?: string[] | [];
  mentionUserList?: null | string[] | [] | undefined;
  userList?: null | any[] | [];
  onChange: (value:string) => void;
  onEmojiSelect: (value: string|null) => void;
  onChangeMentionList?: (mentionList:any,type?:string) => void;
  onChangeUserList?: (mentionUserList:any,type?:string) => void;
  onSubmit: () => void;
  templateSend : any;
  template ?: any;
  clientId ?: string | null;
  placeholder: string ;
  selectedFiles?: any ;
  selectedImages?: any ;
  selectedAudios?: any ;
  onSelectImages?: (images: Array<any>) => void;
  onSelectFiles?: (files: Array<any>) => void;
  onSelectAudio?: (audios: Array<any>) => void;
  chatUserDetails?: any;
}

const InputSection = ({ type, value, selectedEmoji, onChange, onEmojiSelect, onSubmit,template, templateSend, clientId, placeholder, mentionList, mentionUserList, userList, onChangeMentionList, onChangeUserList, selectedFiles, selectedImages, selectedAudios, onSelectImages, onSelectFiles, onSelectAudio, chatUserDetails }: InputSectionProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [onfocusState, setOnfocusState] = useState(false); 
  const [showGroupList, setShowGroupList] = useState(false); 
  const [clientDetails, setClientDetails] = useState<ClientDetailsProps>(); 
  const quillRef = useRef<Quill | null>(null);
  const [draftText, setDraftText] = useState<any>();
  const [selectedChatId, setSelectedChatId] = useState();

  const { templateList,clientList ,client_id, selectedChat, draftSelectedChatId} = useAppSelector((state) => {
    return {
      selectedChat: state.Chats.selectedChat,
      draftSelectedChatId: state.Chats.draftSelectedChatId,
      client_id: state.Chats.clientId,
      clientList: state.Chats.clientList,
      templateList: state.Templates.getTemplateList,
    };
  });
  useEffect(() => {
    dispatch(getTemplateList());
  }, []);

  useEffect(() => {
    if(chatUserDetails?.chatId){
      setSelectedChatId(chatUserDetails?.chatId);
    }
  }, [chatUserDetails?.chatId])
  

  useEffect(() => {
    if(clientList.length){
      const newClientId  = client_id !== "all_numbers" ? client_id :clientId ? clientId : clientList[0].clientId
      const clientDetails = clientList.find((item:any) =>item.clientId === newClientId)
      setClientDetails(clientDetails)
    }
  }, [clientList])

  const templateSends =async (value:any) => {
    // onChange(value?.formattedTempFormatCopy ? value?.formattedTempFormatCopy:value?.tempFormat);
    templateSend(value);
    setOnfocusState(true)
  }

  useEffect(() => {
    if(onfocusState)setTimeout(() => { inputRef?.current?.focus(); }, 1000);
  }, [onfocusState])
   
  const onSelectParticipant = (data:any) => {
    if(onChangeMentionList && typeof onChangeMentionList == "function" )onChangeMentionList(data)
    setShowGroupList(!showGroupList)
    // inputRef.current?.focus()
  } 
  const onSelectUserParticipant = (data:any) => {
    if(onChangeUserList && typeof onChangeUserList == "function" )onChangeUserList(data)
    setShowGroupList(!showGroupList)
    inputRef.current?.focus()
  } 

  const onChangeShowGroupList = (value : any) =>{
    setShowGroupList(value)
  }


  type VisibleInputType = 'cc' | 'bcc' | null;
  const [visibleInput, setVisibleInput] = useState<VisibleInputType>(null);
  const toggleCC = () => {
    setVisibleInput((prev) => (prev === 'cc' ? null : 'cc'));
  };
  const toggleBCC = () => {
    setVisibleInput((prev) => (prev === 'bcc' ? null : 'bcc'));
  };

  const onChangeImages = (data :any) =>{
    if(onSelectImages && typeof onSelectImages === "function")
      onSelectImages(data)
  }
  const onChangeFiles = (data :any) =>{
    if(onSelectFiles && typeof onSelectFiles === "function")
      onSelectFiles(data)
  }
  const onChangeAudios = (data :any) =>{
    if(onSelectAudio && typeof onSelectAudio === "function")
      onSelectAudio(data)
  }
  const onSubmitData = ()=>{
    // removeChatData(selectedChat)
    return onSubmit();
  }

  useEffect(() => {
    const fetchDraftText = async () => {
      if (!chatUserDetails?.chatId) {
        console.warn('chatUserDetails or chatId is undefined');
        setDraftText(null);
        return;
      }
      try {
        const db: any = await openDatabase();
        const transaction = db.transaction(['DraftText'], 'readwrite');
        const objectStore = transaction.objectStore('DraftText');
  
        const request = objectStore.get(chatUserDetails.chatId);
        request.onsuccess = (event: any) => {
          const result = event.target.result;
          if (result?.chatId == chatUserDetails.chatId) {
            setDraftText(result);
          } else {
            const now = Date.now();
            objectStore.add({ chatId: chatUserDetails.chatId, text: "", createdDate: now });
          }
        };
  
        request.onerror = (event: any) => {
          console.error('Error fetching draft text:', event);
        };
      } catch (error) {
        console.error('Error opening database:', error);
      }
    };
  
    fetchDraftText();
  }, [chatUserDetails?.chatId]);
  
  const openDatabase = (version = 3) => {
    return new Promise((resolve, reject) => {
      const dbRequest = indexedDB.open('MyDatabase', version);
  
      dbRequest.onupgradeneeded = function (event: any) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('Media')) {
          db.createObjectStore('Media', { autoIncrement: true });
        }
        if (!db.objectStoreNames.contains('DraftText')) {
          db.createObjectStore('DraftText', { keyPath: 'chatId' });
        }
      };
  
      dbRequest.onsuccess = function (event: any) {
        resolve(event.target.result);
      };
  
      dbRequest.onerror = function (event) {
        reject(event);
      };
    });
  };
  
  const handleChat = async (value?: any, chatId?: any) => {
    var textValue: String
    if(value?.length == 1){
      textValue = ""
    }else{
      textValue = value
    }
    const dbRequest = indexedDB.open('MyDatabase', 3);
    let db: IDBDatabase;
  
    dbRequest.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('DraftText')) {
        db.createObjectStore('DraftText', { keyPath: 'chatId' });
      }
    };
  
    dbRequest.onsuccess = (event: any) => {
      db = event.target.result;
      const transaction = db.transaction(['DraftText'], 'readwrite');
      const objectStore = transaction.objectStore('DraftText');
  
      if (chatId) {
        const getRequest = objectStore.get(chatId);
  
        getRequest.onsuccess = function (event: any) {
          const result = getRequest.result;
          const now = Date.now();
          const TTL = 3 * 60 * 60 * 1000; // 3 Hours in milliseconds
  
          if (result) {
            if (now - result.createdDate > TTL) {
              const deleteRequest = objectStore.delete(chatId);
              deleteRequest.onsuccess = () => {
                console.log('Expired draft text deleted successfully.');
              };
              deleteRequest.onerror = (event: any) => {
                console.error('Error deleting expired draft text:', event);
              };
            } else {
              const putRequest = objectStore.put({
                chatId: chatId,
                text: textValue,
                createdDate: now,
              });
              putRequest.onsuccess = () => {
                console.log('Draft text updated successfully.');
              };
              putRequest.onerror = (event: any) => {
                console.error('Error updating draft text:', event);
              };
            }
          } else {
            const addRequest = objectStore.add({
              chatId: chatId,
              text: textValue,
              createdDate: now,
            });
            addRequest.onsuccess = () => {
              console.log('Draft text saved successfully.');
            };
            addRequest.onerror = (event: any) => {
              console.error('Error saving draft text:', event);
            };
          }
        };
  
        getRequest.onerror = (event: any) => {
          console.error('Error fetching draft text:', event);
        };
      } else {
        console.warn('chatId is undefined');
      }
  
      const cleanupExpiredEntries = () => {
        const cleanupTransaction = db.transaction(['DraftText'], 'readwrite');
        const cleanupObjectStore = cleanupTransaction.objectStore('DraftText');
        const cleanupRequest = cleanupObjectStore.openCursor();
  
        cleanupRequest.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            const now = Date.now();
            const TTL = 5 * 60 * 1000; // 5 minutes in milliseconds
  
            if (now - cursor.value.createdDate > TTL) {
              const deleteRequest = cursor.delete();
              deleteRequest.onsuccess = () => {
                console.log('Expired draft text deleted during cleanup.');
              };
              deleteRequest.onerror = (event: any) => {
                console.error('Error deleting expired draft text during cleanup:', event);
              };
            }
            cursor.continue();
          }
        };
  
        cleanupRequest.onerror = (event: any) => {
          console.error('Error during cleanup:', event);
        };
  
        setTimeout(cleanupExpiredEntries, 5 * 60 * 1000);
      };
  
      cleanupExpiredEntries();
    };
  
    dbRequest.onerror = (event) => {
      console.error('Error opening database:', event);
    };
  };

  useEffect(() => {
    if(draftText?.text?.length && chatUserDetails?.chatId == draftText?.chatId){
      onEmojiSelect(draftText?.text);
    }
  }, [draftText])
  
  const onChangeData = async (textValue: any, chatId?: any) => {
    onChange(textValue);
    setDraftText(null);
    if(chatId){
        handleChat(textValue, selectedChatId != undefined && selectedChatId);
    }
  };
  return (
    <div className="position-relative textbox_container">

      {clientDetails?.type === "email" ?<> <div className="subjectInputBlock">
          <Label className="form-label">Subject:</Label>
          <Input placeholder="callhippo.com forwarding confirmation - Receive Mail from katerose@callhippo.com" />
          <div className="toggleEmailBlock">
            <div className="ccClickable" onClick={toggleCC}>CC</div>
            <div className="bccClickable" onClick={toggleBCC}>BCC</div>
          </div>
      </div>
      {visibleInput === 'cc' && (
        <div className="subjectInputBlock ccInputBlock">
          <Label className="form-label">CC:</Label>
          <Input placeholder="Add CC" />
        </div>
      )}
      {visibleInput === 'bcc' && (
        <div className="subjectInputBlock bccInputBlock">
          <Label className="form-label">BCC:</Label>
          <Input placeholder="Add BCC" />
        </div>
      )}</>:" "}

      <QuillEditor
        ref={quillRef}
        placeholder={type === "message" ? "Start with '/' to select a Canned Response":"Use '@' to mention people"}
        clientDetails={clientDetails}
        onChange={onChangeData}
        onEmojiSelect={onEmojiSelect}
        value={value}
        selectedEmoji={selectedEmoji}
        selectedFiles={selectedFiles}
        selectedImages={selectedImages}
        selectedAudios={selectedAudios}
        onSelectImages={onChangeImages}
        onSelectFiles={onChangeFiles}
        onSelectAudio={onChangeAudios}
        selectedTemplate={template} 
        templateSend={templateSends} 
        templateList={templateList} 
        clientId={clientId}
        chatUserDetails={chatUserDetails}
        tabType={type}
        userList={userList}
        mentionList={mentionList}
        onChangeMentionList={onSelectParticipant}
        mentionUserList={mentionUserList?mentionUserList :[]}
        onChangeUserList={onSelectUserParticipant}   
        onSubmit={onSubmitData}   
        selectedChat={selectedChat}
        draftSelectedChatId={draftSelectedChatId}
      />
    </div>
  );
};

export default InputSection;
