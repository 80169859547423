import React, { useEffect, useState } from "react";
// import cookie from "react-cookies";

import { useProfile, useRedux } from "../../hooks/index";
import { useParams,Navigate,useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { updateSubUserPwd,postChangeEmail } from "../../redux/actions";
import { addMixpanelEvent } from "../../helpers/mixpanel";
import {showSuccessNotification } from "../../helpers/notifications";
import Loader from "../../components/Loader";

let now = new Date();

interface ConfirmAccountProps { }
const ChangeEmail : React.FC<ConfirmAccountProps> = (props) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

  const {changeEmailResponse } = useAppSelector(
    (state) => {
      return {
        changeEmailResponse: state?.ChangeEmail?.changeEmailResponse
      };
    }
  );

  const resolver = yupResolver(
    yup.object().shape({
      password: yup.string().required("Please Enter Password."),
    })
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;
  const { hash, i, c } = useParams();
  useEffect(() => {
    setLoading(true)
    let data = {
      hash: hash,
      i: i,
      c: c,
      userLoginTime: now,
    };
    dispatch(postChangeEmail(data));
    setLoading(false)
  }, [dispatch, hash, i, c]);
  useEffect(() => {
    if (changeEmailResponse?.success) {
      setLoading(true)
      const userData:any = changeEmailResponse.userData
      showSuccessNotification(changeEmailResponse)
      addMixpanelEvent("Email Changed", { distinct_id: userData.userId, "$email": userData.email})
      setTimeout(() => {
        navigate("/");
      }, 1000);
      setLoading(false)
    }else{
        navigate("/auth-login")
    }
  }, [changeEmailResponse]);

  return (
   <> 
   </>
  );
};

export default ChangeEmail;


