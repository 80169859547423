import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { AuthLoginActionTypes } from "./types";
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getFirebaseBackend,
  setLoggeedInUser,
} from "../../../helpers/firebase_helper";
import {
  postJwtLogin,
  postJwtLoginWithGoogle,
  postFcmToken,
  logout as logoutApi,
} from "../../../api/index";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user } }: any) {
  try {
     if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
        recaptcha:user.recaptcha
      });
      setLoggeedInUser(response);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
    } 
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}
function* loginWithGoogle( payload: any) {
  try {
     if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postJwtLoginWithGoogle, payload);
      setLoggeedInUser(response);
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_WITH_GOOGLE, response)
      );
    } 
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_WITH_GOOGLE, error)
    );
  }
}
function* postFcmTokenApi(data: any) {
  try {
     if (process.env.REACT_APP_DEFAULTAUTH) {
      const response: Promise<any> = yield call(postFcmToken,data.data.data);
    } 
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.POST_FCM_TOEKN, error)
    );
  }
}

function* logoutUser() {
  try {
    const response: Promise<any> = yield call(logoutApi);
    window.location.reload();
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, true)
      );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
    );
  }
}

function* systemLogoutUser(data: any) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("authData");
    localStorage.removeItem("clientId");
    localStorage.removeItem("layoutMode");
    localStorage.removeItem("selectedTab");
    localStorage.removeItem("isChromeExtensionHide");
    if(!data?.payload?.buttonLogout){
      window.location.reload();
    }
      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.SYSTEM_LOGOUT_USER, true)
      );
    const response: Promise<any> = yield call(logoutApi);
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.SYSTEM_LOGOUT_USER, error)
      );
  }
}


function* loginSaga() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, loginUser);
  yield takeEvery(AuthLoginActionTypes.LOGIN_WITH_GOOGLE, loginWithGoogle);
  yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, logoutUser);
  yield takeEvery(AuthLoginActionTypes.SYSTEM_LOGOUT_USER, systemLogoutUser);
  yield takeEvery(AuthLoginActionTypes.POST_FCM_TOEKN, postFcmTokenApi);
  // yield takeLatest(AuthLoginActionTypes.SOCIAL_LOGIN, socialLogin);
}

export default loginSaga;
